
import { IReservationsFilters } from '@share/common-types';

export type Filters = IReservationsFilters;

export interface IFilterField<T> {
  value: T;
  value2?: T;
  condition?: InputFilterOption;
}

export enum InputFilterType {
  Group,
  Text,
  IntegerNumber,
  DecimalNumber,
  Percentage,
  Currency,
  Dropdown,
  Date,
  DateTime,
  Boolean,
  Custom
}

export type InputFilterOption = 
  InputStringFilterOption | 
  InputDropdowmFilterOption |
  InputCommonFilterOption;

export enum InputStringFilterOption {
  Equal = 'equal',
  NotEqual = 'notEqual',
  Contains = 'contains',
  StartWith = 'startWith',
  EndWith = 'endWith'
}

export enum InputDropdowmFilterOption {
  Equal = 'equal',
  NotEqual = 'notEqual'
}

export enum InputCommonFilterOption {
  Equal = 'equal',
  GreaterThan = 'greaterThan',
  LowerThan = 'lowerThan',
  Between = 'between'
}

export const InputFilterOptions = {
  [InputFilterType.Text]: InputStringFilterOption,
  [InputFilterType.Dropdown]: InputDropdowmFilterOption,
  [InputFilterType.Boolean]: InputDropdowmFilterOption,
  [InputFilterType.IntegerNumber]: InputCommonFilterOption,
  [InputFilterType.DecimalNumber]: InputCommonFilterOption,
  [InputFilterType.Currency]: InputCommonFilterOption,
  [InputFilterType.Percentage]: InputCommonFilterOption,
  [InputFilterType.Date]: InputCommonFilterOption,
  [InputFilterType.DateTime]: InputCommonFilterOption,
  [InputFilterType.Group]: InputCommonFilterOption,
  [InputFilterType.Custom]: InputCommonFilterOption
}
