import React, { Component } from 'react';

import { FormattedMessage } from 'react-intl';

import HotAirBallons from '@assets/images/hot-air-balloons.png';

import './style.scss';

export class NewMembers extends Component {
  render(): React.ReactNode {
    return (
      <div className="new-members" style={{ backgroundImage: `linear-gradient(rgba(32, 39, 77, 0.3), rgba(32, 39, 77, 0.3)), url(${HotAirBallons})` }}>
        <div className="content">
          <p className="h4">
            <FormattedMessage id={'new.members.message.1'} />
          </p>
          <p className="h4">
            <FormattedMessage id={'new.members.message.2'} values={{ strong: (message: string) => (<strong>{message}</strong>) }} />
          </p>
          <p className="h4">
            <FormattedMessage id={'new.members.message.3'} />
          </p>
        </div>
      </div>
    );
  }
}
