import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Form, Input, notification, Spin } from 'antd';
import { FormInstance } from 'antd/lib/form';

import { IPersonalDetailsState, updatePassword } from '@store/slices';
import { ErrorSmallSvg, SuccessSmallSvg } from '@assets';
import { BlueButton } from '@share/components';
import { RootState } from '@share/utils';

import './style.scss';

interface IMapStateToProps {
  personalDetailsStore: IPersonalDetailsState;
}

interface IMapDispatchToProps {
  updatePassword: (data: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {}

interface IState {
  password: string;
  passwordVerify: string;
}

const MAX_LENGTH = 25;
const MIN_LENGTH = 6;

class SecurityManageComponent extends React.Component<IProps, IState> {
  formRef = React.createRef<FormInstance>();
  state: IState = {
    password: '',
    passwordVerify: '',
  };

  onChangeNewPassword = (e: { target: HTMLInputElement }): void => {
    this.setState({ password: e.target.value });
  };

  onChangeReEnterNewPassword = (e: { target: HTMLInputElement }): void => {
    this.setState({ passwordVerify: e.target.value });
  };

  onSaveChanges = () => {
    const { passwordVerify, password } = this.state;

    this.formRef.current.setFieldsValue({ password: password });
    this.formRef.current.setFieldsValue({ passwordVerify: passwordVerify });

    const formPassword = this.formRef.current.validateFields();

    Promise.all([formPassword]).then(() => {
      this.props.updatePassword(password);
    });
  };

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { personalDetailsStore, intl } = this.props;
    const { updatePasswordSuccess, updatePasswordLoading } = personalDetailsStore;

    if (
      prevProps.personalDetailsStore.updatePasswordSuccess !== updatePasswordSuccess &&
      !updatePasswordLoading
    ) {
      if (updatePasswordSuccess.isSuccess) {
        notification['success']({
          message: intl.formatMessage({ id: 'password.has.been.updated' }),
          icon: <SuccessSmallSvg />,
          className: 'all-bookings__notification success',
        });
      } else {
        notification['error']({
          message: updatePasswordSuccess.message,
          icon: <ErrorSmallSvg />,
          className: 'all-bookings__notification error',
        });
      }
    }
  }

  render(): React.ReactNode {
    const { password, passwordVerify } = this.state;
    const { intl, personalDetailsStore } = this.props;

    return (
      <div className="security-manage">
        <Spin spinning={personalDetailsStore.updatePasswordLoading}>
          <div className="security-manage__wrapper">
            <p className="security-manage__title">
              <FormattedMessage id="security" />
            </p>
            <div className="security-manage__form-wrapper">
              <Form name="security-manage" ref={this.formRef}>
                <p className="security-manage__block-title">
                  <FormattedMessage id="manage.password" />
                </p>
                <Form.Item
                  name="password"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'please.input.your.password' }),
                    },
                    {
                      min: MIN_LENGTH,
                      message: intl.formatMessage(
                        { id: 'error.length.of.password' },
                        { charLength: password.length },
                      ),
                    },
                  ]}
                  hasFeedback
                >
                  <div className="security-manage__name-input-wrapper">
                    <span className="security-manage__input-label">
                      <FormattedMessage id="new.password" />
                    </span>
                    <div className="security-manage__input">
                      <Input.Password
                        placeholder={intl.formatMessage({ id: 'enter.new.password' })}
                        value={password}
                        maxLength={MAX_LENGTH}
                        onChange={(e) => {
                          this.onChangeNewPassword(e);
                        }}
                      />
                    </div>
                  </div>
                </Form.Item>
                <Form.Item
                  name="passwordVerify"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'please.input.your.password' }),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            intl.formatMessage({
                              id: 'the.two.passwords.that.you.entered.do.not.match',
                            }),
                          ),
                        );
                      },
                    }),
                  ]}
                  dependencies={['password']}
                >
                  <div className="security-manage__name-input-wrapper">
                    <span className="security-manage__input-label">
                      <FormattedMessage id="confirm.new.password" />
                    </span>
                    <div className="security-manage__input">
                      <Input.Password
                        placeholder={intl.formatMessage({ id: 're.enter.new.password' })}
                        value={passwordVerify}
                        maxLength={MAX_LENGTH}
                        onChange={(e) => {
                          this.onChangeReEnterNewPassword(e);
                        }}
                      />
                    </div>
                  </div>
                </Form.Item>
              </Form>
            </div>
            <div className="security-manage__btn-wrapper">
              <BlueButton onClick={this.onSaveChanges}>
                <FormattedMessage id="save.changes" />
              </BlueButton>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    personalDetailsStore: state.personalDetailsStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  updatePassword,
};

export const SecurityManage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(SecurityManageComponent));
