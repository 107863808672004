import { ICurrentUser } from '@common-types';

export enum UserAccountEnum {
  PersonalInfo = 'Personal info',
  Security = 'Security',
}

export interface IPersonalDetails extends ICurrentUser {
  namePrefix?: string;
  userName?: string;
}

export interface IUpdatePersonalDetails {
  isSuccess: boolean;
  message: string;
}
