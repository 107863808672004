import React from 'react';
import isBefore from 'date-fns/isBefore';
import moment from 'moment';

import { connect } from 'react-redux';
import { Action } from 'redux';
import { Button } from 'antd';
import { ThunkDispatch } from 'redux-thunk';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { isEmpty } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { v4 } from 'uuid';
import { Base64 } from 'js-base64';

import {
  carsActions,
  carsDatesActions,
  carsDriverActions,
  CarsErrorTypes,
  carsFiltersActions,
  carsLocationsActions,
  GetCars,
  ICarLocationsState,
  ICarsDatesState,
  ICarsDriverState,
  ICarsFiltersState,
  ICarsState,
  IHotelsState,
  IMenuState,
} from '@share/store/slices';
import { CanImpersonate, CarsMaxDaysRange, getDateCheckInOut, GetDisableNewTabOpen, GetStyles, isCarsDataValid, IsImpersonating, RootState } from '@share/utils';
import { AccessToken, BlueButton, Impersonator, SearchSelection } from '@share/components';
import { getTimeout, UrlUtils } from '@share/utils';
import {
  CARS_FILTERS_LABEL,
  DEFAULT_PAGE_NUMBER,
  REFERENCE_NUMBER_LABEL,
  AGENCY_NUMBER_LABEL,
  Routes,
  ACCESS_TOKEN_LABEL,
  CARS_SELECTION_TYPE,
  CARS_SESSION_KEY_LABEL,
  CARS_DROPOFF_LOCATION_LABEL,
  CARS_DROPOFF_LOCATION_OBJECT_LABEL,
  CARS_PICKUP_LOCATION_LABEL,
  CARS_PICKUP_LOCATION_OBJECT_LABEL,
  CARS_DATES_LABEL,
  CARS_DRIVER_LABEL,
  CARS_FILTERS_QUICK_LABEL
} from '@share/constants';
import {
  IAccount,
  IBounds,
  ILocation,
  ISessionKey,
} from '@share/common-types';
import { SearchTypeEnum } from '@share/common-types';
import { ILoginState } from '@share/store/slices';
import { Responsive, LoginType } from '@share/utils';

import { SearchSvg, UpdateSvg } from '@share/assets';

import CarRental from '@share/assets/images/car_rental.jpg';
import CarsMain from '@share/assets/images/cars-main.jpg';

import { CarsSearchInfo } from './cars-search-info';
import { CarsLocationsPicker } from './cars-locations-picker';
import { CarsDatePicker } from './cars-date-picker';
import { CarsDriver } from './cars-driver';

import './style.scss';
import { CarsSearchFilters } from './cars-filters';
import { UNDEFINED_VALUE } from '@constants';

interface IMapStateToProps {
  hotelsStore: IHotelsState;

  carsLocationsStore: ICarLocationsState;
  carsDatesStore: ICarsDatesState;
  carsStore: ICarsState;
  carsDriverStore: ICarsDriverState;

  loginStore: ILoginState;
  menuStore: IMenuState;
}

interface IMapDispatchToProps {
  validateLocation: (error: string) => void;
  validateDates: (error: string, errorType: CarsErrorTypes) => void;
  validateDriver: (error: string, errorType: CarsErrorTypes) => void;

  setSelectPickUpLocationLabel: (label: string) => void;
  setSelectPickUpLocation: (location: ILocation) => void;
  setSelectDropOffLocationLabel: (label: string) => void;
  setSelectDropOffLocation: (location: ILocation) => void;

  setDates: (dates: { startDate: string; endDate: string; }) => void;
  setTimes: (dates: { startDateTime: string; endDateTime: string; }) => void;

  setSelectedDriverAge: (age: string) => void;
  setSelectedDriverCountry: (country: string) => void;
  
  setSimpleTypeQuick: (values: string[]) => void;
  setRateDistanceQuick: (values: string[]) => void;
  setRentalCompanyQuick: (values: string[]) => void;
  setFuelQuick: (values: string[]) => void;

  setIsCollapse: (isCollapse: boolean) => void;

  getCars: () => void;

  setIsWidget: (isWidget: boolean) => void;
  setIsSearch: () => void;
  setPageNumber: () => void;
  setSessionKey: (sessionKey: ISessionKey) => void;
  setBounds: (bounds: IBounds) => void;
  setFilters: (filters: ICarsFiltersState) => void;
  resetFilters: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps {
  isWidget?: boolean;
  isExperiences?: boolean;
  forceVertical?: boolean;

  title?: string;
  message?: string;
  hideTitle?: boolean;
  hideMessage?: boolean;
  hideSearchSelection?: boolean;
  widgetBackgroundColor?: string;
  widgetOriginalType?: string;
  borderRadius?: string;
  
  excludeCondosSelection?: boolean;
  excludeHotelsSelection?: boolean;
  excludeVacationsSelection?: boolean;
  disableLocationSearch?: boolean;

  onExperience?: () => void;

  onWidgetSelectionChange?: (value: string) => void;
}

const zero = 0;

interface IState {
  displayAdvanced: boolean;
}

class CarsSearchComponent extends React.Component<IProps, IState> {
  state: IState = { displayAdvanced: false };

  componentDidMount() {
    const {
      isWidget,
      carsStore,
      setDates,
      setTimes,
      setSelectPickUpLocation,
      setSelectPickUpLocationLabel,
      setSelectDropOffLocation,
      setSelectDropOffLocationLabel,
      setSessionKey,
      setFilters,
      setIsWidget,
      setSelectedDriverAge,
      setSelectedDriverCountry,
      setSimpleTypeQuick,
      setRateDistanceQuick,
      setRentalCompanyQuick,
      setFuelQuick,
    } = this.props;

    if (isWidget) {
      setIsWidget(isWidget);
    }

    const values = UrlUtils.getValues();

    if (values[CARS_FILTERS_LABEL]) {
      setFilters(values[CARS_FILTERS_LABEL] as ICarsFiltersState);
    }

    const pickUpValue = values[CARS_PICKUP_LOCATION_OBJECT_LABEL];
    const isPickUp = pickUpValue && Object.keys(pickUpValue).length > 0;
    if (isPickUp) {
      setSelectPickUpLocation(pickUpValue as ILocation);
    }

    if (values[CARS_PICKUP_LOCATION_LABEL] && isPickUp) {
      setSelectPickUpLocationLabel(values[CARS_PICKUP_LOCATION_LABEL] as string);
    }

    const dropOffValue = values[CARS_DROPOFF_LOCATION_OBJECT_LABEL];
    const isDropOff = dropOffValue && Object.keys(dropOffValue).length > 0;
    if (isDropOff) {
      setSelectDropOffLocation(dropOffValue as ILocation);
    }

    if (values[CARS_DROPOFF_LOCATION_LABEL] && isDropOff) {
      setSelectDropOffLocationLabel(values[CARS_DROPOFF_LOCATION_LABEL] as string);
    }

    if (values[CARS_DATES_LABEL]) {
      const dates: { startDate: string; startDateTime: string; endDate: string; endDateTime: string; } = values[CARS_DATES_LABEL] as {
        startDate: string;
        startDateTime: string;
        endDate: string;
        endDateTime: string;
      };
      const today = new Date();
      today.setHours(zero, zero, zero, zero);

      setDates({ startDate: dates?.startDate, endDate: dates?.endDate });
      setTimes({ startDateTime: dates?.startDateTime, endDateTime: dates?.endDateTime });

      if (dates?.startDate && isBefore(getDateCheckInOut(dates?.startDate), today)) {
        setDates({ endDate: UNDEFINED_VALUE, startDate: UNDEFINED_VALUE });
      }
    }
    
    if (values[CARS_DRIVER_LABEL]) {
      const driver: { driverAge: string; driverCountry: string; } = values[CARS_DRIVER_LABEL] as { driverAge: string; driverCountry: string; };

      setSelectedDriverAge(driver?.driverAge);
      setSelectedDriverCountry(driver?.driverCountry);
    }

    if (values[CARS_FILTERS_QUICK_LABEL]) {
      const filters: { simpleTypeQuick: string[]; rateDistanceQuick: string[]; rentalCompanyQuick: string[]; fuelQuick: string[]; } = values[CARS_FILTERS_QUICK_LABEL] as { simpleTypeQuick: string[]; rateDistanceQuick: string[]; rentalCompanyQuick: string[]; fuelQuick: string[]; };

      setSimpleTypeQuick(filters?.simpleTypeQuick);
      setRateDistanceQuick(filters?.rateDistanceQuick);
      setRentalCompanyQuick(filters?.rentalCompanyQuick);
      setFuelQuick(filters?.fuelQuick);
    }
  
    const session: ISessionKey = values[CARS_SESSION_KEY_LABEL] as ISessionKey;

    if (session && session.expireDate && getTimeout(session.expireDate) > zero) {
      setSessionKey(session);
    }

    setTimeout(() => {
      if (!carsStore.cars && session) {
        this.onSearch();
      } else {
        const { history, loginStore } = this.props;
        
        const isDisableHomes = loginStore?.account?.isDisableHomes;
        const disableHomesRedirect = loginStore?.account?.disableHomesRedirect;
    
        if (isDisableHomes && !isWidget) {
          if (isEmpty(disableHomesRedirect)) {
            history.push(Routes.NotAuthorized);
          } else {
            window.location.replace(disableHomesRedirect as string);
          }
        }

      }
    }, 200);
  }

  onSearch = ({ isInit = true } = {}) => {
    const {
      isWidget,
      isExperiences,
      hotelsStore,
      loginStore,

      carsLocationsStore,
      carsDatesStore,
      carsDriverStore,

      intl,

      setIsSearch,
      getCars,
      setPageNumber,
      resetFilters,
      onExperience,
      validateLocation,
      validateDates,
      validateDriver,
      setIsCollapse,
    } = this.props;

    const { isDatesValid, isLocationValid, isValid, isDatesMaxError, isTimesError, isDriverCountryError } = isCarsDataValid(carsLocationsStore, carsDatesStore, carsDriverStore);

    if (!isLocationValid) {
      validateLocation(intl.formatMessage({ id: 'cars.location.error' }));
    }

    if (!isDatesValid) {
      validateDates(
        isDatesMaxError ? 
          intl.formatMessage({ id: 'cars.maximum.date.range.search.custom' }, { number: CarsMaxDaysRange }) :
          intl.formatMessage({ id: isTimesError ? 'cars.times.error' : 'dates.error' }),
        isDatesMaxError ? CarsErrorTypes.Date : isTimesError ? CarsErrorTypes.Time : CarsErrorTypes.Date
      );
    }

    if (isDriverCountryError) {
      validateDriver(intl.formatMessage({ id: 'cars.driver.country.error' }), CarsErrorTypes.DriverCountry);
    }

    if (isValid) {

      if (isWidget) {

        const { startDate, startDateTime, endDate, endDateTime } = carsDatesStore;
        const { pickUp, dropOff } = carsLocationsStore;
        const { driverAge, driverCountry } = carsDriverStore;
        const { selectedLocation, selectedLocationLabel } = pickUp;
        
        const selectedLocationDropOff = dropOff?.selectedLocation;
        const selectedLocationLabelDropoff = dropOff?.selectedLocationLabel;

        const pickUpLocationParams = `${CARS_PICKUP_LOCATION_OBJECT_LABEL}=${Base64.encode(JSON.stringify(selectedLocation))}`;
        const pickUpLocationLabelParams = UrlUtils.getUrl(CARS_PICKUP_LOCATION_LABEL, selectedLocationLabel);

        const dropOffLocationParams = `${CARS_DROPOFF_LOCATION_OBJECT_LABEL}=${Base64.encode(JSON.stringify(selectedLocationDropOff))}`;
        const dropOffLocationLabelParams = UrlUtils.getUrl(CARS_DROPOFF_LOCATION_LABEL, selectedLocationLabelDropoff);

        const sessionParams = UrlUtils.getUrl(CARS_SESSION_KEY_LABEL, {
          value: v4(),
          isInvalid: true,
          expireDate: moment().format('yyyy-MM-DDTHH:mm:ss.SSSZ')
        } as ISessionKey);
        
        const datesParams = UrlUtils.getUrl(CARS_DATES_LABEL, { startDate, startDateTime, endDate, endDateTime });
        const driverParams = UrlUtils.getUrl(CARS_DRIVER_LABEL, { driverAge, driverCountry });

        const accessToken = loginStore.account?.accessTokenDb;
        const hasPublicAccessToken = loginStore.account?.hasPublicAccessToken;
        const hasPublicValidToken = loginStore.account?.type !== LoginType.Public || !hasPublicAccessToken || !isEmpty(accessToken);

        const baseAddressSite = loginStore.account?.baseAddressSite;
        const accountName = loginStore.account?.name;

        const disableNewTabOpen = GetDisableNewTabOpen(this.props.loginStore.account, this.props.menuStore.items);

        const path = !isEmpty(baseAddressSite) ? baseAddressSite : import.meta.env.VITE_WIDGET_REDIRECT;
        const referenceNumberParam = !isEmpty(hotelsStore.referenceNumber) ? `${REFERENCE_NUMBER_LABEL}=${hotelsStore.referenceNumber}` : '';
        const agencyNumberParam = !isEmpty(hotelsStore.agencyNumber) ? `${AGENCY_NUMBER_LABEL}=${hotelsStore.agencyNumber}` : '';
        const tokenParam = hasPublicValidToken ? `${ACCESS_TOKEN_LABEL}=${accessToken}` : '';

        const url = `${path}${accountName}${Routes.CarsSearch}?${tokenParam}&${pickUpLocationParams}&${pickUpLocationLabelParams}&${dropOffLocationParams}&${dropOffLocationLabelParams}&${datesParams}&${sessionParams}&${referenceNumberParam}&${agencyNumberParam}&${driverParams}`;

        if (disableNewTabOpen) {
          window.location.replace(url);
        } else {
          window.open(url, '_blank');
        }
      } else {
        const { pickUp } = carsLocationsStore;
        const { selectedLocation } = pickUp;
    
        if (selectedLocation) {
          setPageNumber();
          setIsSearch();
          setIsCollapse(true);
    
          if (!isInit) {
            resetFilters();
            UrlUtils.setUrl(CARS_FILTERS_LABEL, null);
          }
    
          if (isWidget) {
    
          } else if (isExperiences) {
            if (onExperience) {
              onExperience();
            }
          } else {
            getCars();

            const contentWrapper = document.querySelector('.condo-result-wrapper__content');
    
            if (contentWrapper) {
              contentWrapper.scroll(zero, zero);
              window.scrollTo(zero, zero);
            }
          }
        }  
      }
    }

  };

  onToggle = () => {
    const { carsStore, setIsCollapse } = this.props;
    const { isCollapse } = carsStore;
    
    setIsCollapse(!isCollapse);
  };

  render(): React.ReactNode {
    const { 
      isWidget,
      isExperiences,
      loginStore,
      carsLocationsStore,
      carsDatesStore,
      carsDriverStore,
      carsStore,

      hideTitle,
      title,
      hideMessage,
      message,
      hideSearchSelection,
      widgetBackgroundColor,
      excludeCondosSelection,
      excludeHotelsSelection,
      excludeVacationsSelection,
      widgetOriginalType,
      forceVertical,
      borderRadius,
      disableLocationSearch,
    } = this.props;
    const { isSearch, cars, isCollapse } = carsStore;
    const { displayAdvanced } = this.state;

    const { account } = loginStore;

    const searchTitle = account?.carsSearchTitle;
    const searchMessage = account?.carsSearchMessage;

    const searchTitleFinal = isWidget && !isEmpty(title) ? title : searchTitle;
    const searchMessageFinal = isWidget && !isEmpty(message) ? message : searchMessage;

    const hasPublicAccessToken = account?.hasPublicAccessToken;
    const backgroundImage = account?.carsBackgroundImage;
    const isRSITemplate = account?.isRSITemplate;  
    const hasPublicValidToken = account?.type !== LoginType.Public || !hasPublicAccessToken || !isEmpty(loginStore.accessToken);

    const { styleAccount, styleMain, withImage } = GetStyles(account as IAccount, backgroundImage, borderRadius as string, CarRental, CarsMain, isSearch, isExperiences as boolean, isWidget as boolean, widgetBackgroundColor as string, cars?.length);

    const values = UrlUtils.getValues();

    const isAfterSearchLoad =
      (!isWidget && !isExperiences) && ((isSearch && !(!!carsLocationsStore.error || !!carsDatesStore.error)) || !!cars?.length);

    const datesError = carsDatesStore?.error;
    const locationError = carsLocationsStore?.error;
    const driverError = carsDriverStore?.error;
  
    const showImpersonate = CanImpersonate(account as IAccount) && !IsImpersonating() && !isAfterSearchLoad && !account?.disableImpersonate;

    return (
      <div
        className={`cars-search${(isWidget || isExperiences) ? ' widget' : ''}${(!isRSITemplate && !cars) ? ' travcoding-template-main' : ''}${(isSearch && !(isWidget || isExperiences)) ? ' after-search' : ''}${isCollapse ? ' toggle-cars-search-info' : ''}${displayAdvanced ? ' advanced-displayed' : ''}`}
        style={styleMain}
      >
        <div className={`search-wrapper__image ${withImage ? 'with-image' : ''} ${isWidget ? 'widget' : ''}`} style={styleAccount}></div>

        <div className={`cars-search__wrapper ${backgroundImage? 'background-image' : ''} ${(!isRSITemplate && !cars) ? 'travcoding-template' : ''} ${(isWidget || isExperiences) ? 'widget' : ''}`}>
          {!isExperiences ? (
            <>
              {(!isWidget || (isWidget && !hideTitle)) ? (
                <h1 className={`cars-search__header secondary-font ${isWidget ? 'widget' : ''} ${(isWidget && hideMessage)? 'widget-hide' : ''}`}>
                  {isEmpty(searchTitleFinal) ? (<FormattedMessage id="cars.header" />) : searchTitleFinal}
                </h1>) : null}

              {(!isWidget || (isWidget && !hideMessage)) ? (
                <div className={`cars-search__header-small ${(!isRSITemplate && !cars) ? 'travcoding-template' : ''} ${isWidget ? 'widget' : ''} ${(isWidget && hideTitle) ? 'widget-hide' : ''}`}>
                  {isEmpty(searchMessageFinal) ? (<FormattedMessage id="cars.header.small" />) : searchMessageFinal}
                </div>) : null}
            </>) : null}

          <div className={`cars-search__search-section ${(isWidget || isExperiences) ? 'widget' : ''}`}>
            {((hasPublicValidToken || isWidget) && !isExperiences && !isCollapse && isEmpty(values) && !cars && !hideSearchSelection) ? (
              <SearchSelection 
                type={CARS_SELECTION_TYPE}
                isWidget={isWidget}
                widgetOriginalType={widgetOriginalType}
                onWidgetSelectionChange={this.props.onWidgetSelectionChange}
                excludeCondosSelection={excludeCondosSelection}
                excludeHotelsSelection={excludeHotelsSelection}
                excludeVacationsSelection={excludeVacationsSelection}
              />) : null}

            {(hasPublicValidToken || isWidget) && (
              <>
                <div className={`cars-search__search-top ${forceVertical? 'vertical' : ''} ${(isWidget || isExperiences) ? 'widget' : ''}`}>
                  <CarsLocationsPicker isExperiences={isExperiences} disable={disableLocationSearch} />
                </div>
                
                <div className={`cars-search__search-top ${forceVertical? 'vertical' : ''} ${(isWidget || isExperiences) ? 'widget' : ''}`} style={{ marginTop: '15px' }}>
                  <Responsive>
                    <CarsDatePicker isUpdateSearch={isAfterSearchLoad} />
                  </Responsive>

                  <CarsDriver isExperiences={isExperiences as boolean} disable={disableLocationSearch as boolean} />

                  <BlueButton onClick={() => this.onSearch({ isInit: false })} style={{ height: '60px' }}>
                    {isSearch ? (<UpdateSvg />) : (<SearchSvg />)}
                    <FormattedMessage id={isSearch ? 'update.search.your.hotel' : 'cars.find.your.car'} />
                  </BlueButton>
                </div>

                <div className={`cars-search__search-top cars-filters-search-main ${forceVertical? 'vertical' : ''} ${(isWidget || isExperiences) ? 'widget' : ''}`} style={{ marginTop: '15px', justifyContent: displayAdvanced ? 'space-between' : 'flex-end' }}>
                  {displayAdvanced ? (<CarsSearchFilters />) : null}

                  <span className="cars-search__filters" onClick={() => this.setState({ displayAdvanced: !displayAdvanced })}>
                    <FormattedMessage id={!displayAdvanced ? 'cars.display.advanced' : 'cars.hide.advanced'} />
                  </span>
                </div>

                {(locationError || datesError || driverError) ? (
                  <div className={`cars-search__search-top ${forceVertical? 'vertical' : ''} ${(isWidget || isExperiences) ? 'widget' : ''}`} style={{ marginTop: isAfterSearchLoad ? '0px' : '10px' }}>
                    <div className="cars-locations-picker__error">
                      {`${locationError ? locationError : ''}${(locationError && datesError) ? ' / ' : ''}${datesError ? datesError : ''}${((locationError && driverError) || (datesError && driverError)) ? ' / ' : ''}${driverError ? driverError : ''}`}
                    </div>
                  </div>) : null}



              </>)}
            {(!hasPublicValidToken && !isWidget) && (<AccessToken />)} 

            {showImpersonate && 
              <>
                {account?.forceImpersonate ? (
                  <div className="row pt-2 result-wrapper__style-home-search-container" style={{ flexFlow: 'row-reverse' }}>
                    <div className="col-12" style={{alignSelf: 'center', fontSize: '16px', fontWeight: 'bold', textAlign: 'center', color: 'red' }}><FormattedMessage id="please.impersonate.continue" /></div>
                  </div>) : null}
                <div className="row pt-2 result-wrapper__style-home-search-container" style={{ flexFlow: 'row-reverse' }}>
                  <div className="col-lg-4" style={{alignSelf: 'center'}}><Impersonator isCars /></div>
                </div>
              </>}
         
          </div>

          {isCollapse && <CarsSearchInfo onToggle={this.onToggle} />}

          {isSearch && !isCollapse && !isWidget && (
            <Button
              className="cars-search__toggle-cars-search-info"
              onClick={this.onToggle}
              type="text"
            >
              <FormattedMessage id="cancel" />
            </Button>)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    hotelsStore: state.hotelsStore,

    carsLocationsStore: state.carsLocationsStore,
    carsDatesStore: state.carsDatesStore,
    carsStore: state.carsStore,
    carsDriverStore: state.carsDriverStore,

    loginStore: state.loginStore,
    menuStore: state.navigationMenuStore,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  validateLocation: (error: string) => {
    dispatch(carsLocationsActions.setError(error));
  },
  setSelectPickUpLocationLabel: (label: string) => {
    dispatch(carsLocationsActions.setSelectPickUpLocationLabel(label));
  },
  setSelectPickUpLocation: (location: ILocation) => {
    dispatch(carsLocationsActions.setSelectPickUpLocation(location));
  },
  setSelectDropOffLocationLabel: (label: string) => {
    dispatch(carsLocationsActions.setSelectDropOffLocationLabel(label));
  },
  setSelectDropOffLocation: (location: ILocation) => {
    dispatch(carsLocationsActions.setSelectDropOffLocation(location));
  },


  validateDates: (error: string, errorType: CarsErrorTypes) => {
    dispatch(carsDatesActions.setError(error));
    dispatch(carsDatesActions.setErrorType(errorType));
  },
  setDates: (dates: { startDate: string; endDate: string; }) => {
    dispatch(carsDatesActions.setDatesSelected(dates));
  },
  setTimes: (dates: { startDateTime: string; endDateTime: string; }) => {
    dispatch(carsDatesActions.setTimesSelected(dates));
  },
  
  validateDriver: (error: string, errorType: CarsErrorTypes) => {
    dispatch(carsDriverActions.setError(error));
    dispatch(carsDriverActions.setErrorType(errorType));
  },
  setSelectedDriverAge: (age: string) => {
    dispatch(carsDriverActions.setDriverAgeSelected(age));
  },
  setSelectedDriverCountry: (country: string) => {
    dispatch(carsDriverActions.setDriverCountrySelected(country));
  },

  setSimpleTypeQuick: (values: string[]) => {
    dispatch(carsFiltersActions.setSimpleTypeQuick(values));
  },
  setRateDistanceQuick: (values: string[]) => {
    dispatch(carsFiltersActions.setRateDistanceQuick(values));
  },
  setRentalCompanyQuick: (values: string[]) => {
    dispatch(carsFiltersActions.setRentalCompanyQuick(values));
  },
  setFuelQuick: (values: string[]) => {
    dispatch(carsFiltersActions.setFuelQuick(values));
  },

  setIsSearch: () => {
    dispatch(carsActions.setIsSearch(true));
  },
  setSessionKey: (sessionKey: ISessionKey) => {
    dispatch(carsActions.setCarsSessionKey(sessionKey));
  },
  setPageNumber: () => {
    dispatch(carsActions.setPageNumber(DEFAULT_PAGE_NUMBER));
  },
  setBounds: (bounds: IBounds) => {
    dispatch(carsActions.setBounds(bounds));
  },
  setIsWidget: (isWidget: boolean) => {
    dispatch(carsActions.setIsWidget(isWidget));
  },

  setIsCollapse: (isCollapse: boolean) => {
    dispatch(carsActions.setIsCollapse(isCollapse));
  },

  getCars: () => {
    dispatch(GetCars(SearchTypeEnum.NewSearch));
  },


  setFilters: (filters: ICarsFiltersState) => {
    dispatch(carsFiltersActions.setFilters(filters));
  },
  resetFilters: () => {
    dispatch(carsFiltersActions.resetFilters(true));
  },

});

export const CarsSearch = connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(CarsSearchComponent)));
