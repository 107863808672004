import React from 'react';

import { FormInstance } from 'antd/lib/form';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

import { ILoginState } from '@share/store/slices';
import { getCarsDetails, ICarsDetailsState, ICarsReviewBookState, resetCreditCard, setSelectedCarsReviewClientCash, setSelectedCarsReviewClientCashStr } from '@store/slices';
import { getCarIdFromPath, RootState } from '@share/utils';
import { UrlUtils } from '@share/utils';
import { CARS_SESSION_KEY_LABEL, DEAL_HOME_LABEL, DEALID_LABEL, QUOTE_LABEL } from '@share/constants';
import { BookingErrorsEnum, ISessionKey } from '@share/common-types';
import { AvailabilityStatusModal, GeneralMessage, MessageType, SkeletonReviewBook } from '@components';
import { CustomErrorPage } from '@share/components';

import { CarsDetailsReturn } from '../cars-details-return';
import { CarsDetailsServerError } from '../cars-details-server-error';
import { CarsPriceDetails } from '../cars-price-details';
import { CarsBookingConfirmed } from '../cars-booking-confirmed';
import { CarsDetails } from '../cars-details/component';

import './style.scss';
import { CarsLocations } from '../cars-location';

interface IMapStateToProps {
  carsDetailsStore: ICarsDetailsState;
  carsReviewBookStore: ICarsReviewBookState;

  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getCarsDetails: (numberId: string, sessionKey: ISessionKey | undefined, dealId: number, quote: string) => void;
  setSelectedCarsReviewClientCash: (clientCash: number) => void;
  setSelectedCarsReviewClientCashStr: (clientCash: string) => void;
  resetCreditCard: () => void;
}

interface IParams {
  id: string;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps<IParams> {}

interface IState {  
  dealId: number;
  backHomeType: string;

  isCheckout: boolean;
}

class CarsDetailsWrapperComponent extends React.Component<IProps, IState> {
  state: IState = {
    dealId: null,
    backHomeType: null,
    isCheckout: false,
  };

  overviewRef: React.RefObject<HTMLDivElement> = React.createRef();
  paymentRef: React.RefObject<HTMLDivElement> = React.createRef();
  driverRef: React.RefObject<HTMLDivElement> = React.createRef();
  emailConfirmationRef: React.RefObject<HTMLDivElement> = React.createRef();
  reserveRef: React.RefObject<HTMLDivElement> = React.createRef();
  locationRef: React.RefObject<HTMLDivElement> = React.createRef();
  policiesRef: React.RefObject<HTMLDivElement> = React.createRef();
  extrasRef: React.RefObject<HTMLDivElement> = React.createRef();

  paymentFormRef = React.createRef<FormInstance>();
  driverFormRef = React.createRef<FormInstance>();
  emailFormRef = React.createRef<FormInstance>();

  
  componentDidMount() {
    this.getDetails();
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { user } = this.props.loginStore;
    if (!prevProps.loginStore.user && !!user) {
      this.getDetails();
    }
  }

  getDetails = () => {
    const values = UrlUtils.getValues();

    if (values[DEAL_HOME_LABEL]) {
      this.setState({ backHomeType: values[DEAL_HOME_LABEL] as string });
    }

    const dealId = values[DEALID_LABEL] ? parseInt(values[DEALID_LABEL] as string) : null;

    this.setState({ dealId });

    this.getCarsDetails(dealId);
  }

  getCarsDetails = (dealId: number) => {
    const { account, user } = this.props.loginStore;

    if (account && user) {
      const values = UrlUtils.getValues();      
      const carId = getCarIdFromPath(this.props.history);
      let quote;
      if (values[QUOTE_LABEL]) {
        quote = (values[QUOTE_LABEL] as string);
      }
      this.props.getCarsDetails(carId, values[CARS_SESSION_KEY_LABEL] as ISessionKey, dealId, quote);
    }
  }

  
  renderBack = (isMobile?: boolean) => {
    const { dealId, backHomeType, isCheckout } = this.state;

    return (
      <CarsDetailsReturn
        isCheckout={isCheckout}
        isMobile={isMobile}
        dealId={dealId}
        backHomeType={backHomeType}
        onBack={() => this.setState({ isCheckout:  false }, () => {
          this.props.setSelectedCarsReviewClientCash(null);
          this.props.setSelectedCarsReviewClientCashStr('');
          this.props.resetCreditCard();
        })}
      />
    );
  }

  render(): React.ReactNode {
    const { carsDetailsStore, carsReviewBookStore } = this.props;
    const { isCheckout, dealId, backHomeType } = this.state;
    const { car, loading, isServerError, error, selectedExtras } = carsDetailsStore;
    const { bookingComplete, errorBooking } = carsReviewBookStore;
        
    return (
      <div className={`hotel-details-wrapper cars-details-wrapper ${bookingComplete ? 'booking-completed' : ''}`}>
        {isServerError ? (
          <CarsDetailsServerError />) : (
          <>
            {this.renderBack()}

            {(loading) ? <SkeletonReviewBook skipRightTop /> : null}

            {(!loading && car && isEmpty(error)) ? (
              <div className="hotel-details-wrapper__wrapper">
                {this.renderBack(true)}

                {!bookingComplete && !isEmpty(errorBooking) && (<GeneralMessage type={MessageType.Failure} message={errorBooking}></GeneralMessage>)}

                <div className="cars-details-wrapper__content">
                  <div className="cars-details-wrapper__content-left">
                    <CarsDetails 
                      overviewRef={this.overviewRef}
                      paymentRef={this.paymentRef}
                      driverRef={this.driverRef}
                      emailConfirmationRef={this.emailConfirmationRef}
                      reserveRef={this.reserveRef}
                      locationRef={this.locationRef}
                      policiesRef={this.policiesRef}
                      extrasRef={this.extrasRef}

                      paymentFormRef={this.paymentFormRef}
                      driverFormRef={this.driverFormRef}
                      emailFormRef={this.emailFormRef}

                      isCheckout={isCheckout}
                      dealId={dealId}
                      backHomeType={backHomeType}
                    />

                    <CarsBookingConfirmed />
                  </div>

                  <div className="cars-details-wrapper__content-right">
                    {(isCheckout && !bookingComplete) ? (
                      <div className="cars-details-wrapper__price-container" style={{ marginBottom: '15px' }}>
                        <CarsLocations car={car} isCheckout />
                      </div>) : null}

                    <CarsPriceDetails
                      car={car}
                      hideReserve={isCheckout}
                      selectedExtras={selectedExtras}
                      onReserve={() => this.setState({ isCheckout: true })}
                      isCheckout={isCheckout}
                    />
                  </div>
                </div>
              </div>) : null}

            {(!loading && !isEmpty(error)) ? (
                BookingErrorsEnum.RoomsUnavailable === error ?
                    <AvailabilityStatusModal /> :
                    <CustomErrorPage message={error} isCars />) : null}
          </>)}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    carsDetailsStore: state.carsDetailsStore,
    loginStore: state.loginStore,
    carsReviewBookStore: state.carsReviewBookStore,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  getCarsDetails: (numberId: string, sessionKey: ISessionKey | undefined, dealId: number, quote: string) => {
    dispatch(getCarsDetails(numberId, sessionKey, dealId, quote));
  },
  setSelectedCarsReviewClientCash: (number: number) => {
    dispatch(setSelectedCarsReviewClientCash(number));
  },
  setSelectedCarsReviewClientCashStr: (number: string) => {
    dispatch(setSelectedCarsReviewClientCashStr(number));
  },
  resetCreditCard: () => {
    dispatch(resetCreditCard());
  },
});

export const CarsDetailsWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CarsDetailsWrapperComponent));

