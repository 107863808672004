import React from 'react';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Checkbox } from 'antd';
import { ThunkDispatch } from 'redux-thunk';
import { get } from 'lodash';
import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';
import { Action } from '@reduxjs/toolkit';

import { Currency } from '@components';
import { ICarExta } from '@share/common-types';
import { RootState, renderNumber } from '@share/utils';
import { ICarsDetailsState, setSelectedExtras } from '@store/slices';

import './style.scss';

interface IMapStateToProps {
  carsDetailsStore: ICarsDetailsState;
}

interface IMapDispatchToProps {
  setSelectedExtras: (selectedExtras: ICarExta[]) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {
  extrasRef: React.RefObject<HTMLDivElement>;

  hideTitle?: boolean;
  hideMessage?: boolean;
  
  viewOnly?: boolean;
  display?: boolean;
}

class CarsExtrasComponent extends React.Component<IProps> {

  onChangeActive = (extra: ICarExta, e: { target: CheckboxChangeEventTarget }): void => {
    const { carsDetailsStore } = this.props;
    const { selectedExtras } = carsDetailsStore;

    let selectedExtrasNew: ICarExta[];
    if (e.target.checked) {
      selectedExtrasNew = [...selectedExtras, extra];
    } else {
      selectedExtrasNew = [...selectedExtras.filter(e => e?.equipment?.description !== extra?.equipment?.description)];
    }

    this.props.setSelectedExtras(selectedExtrasNew);
  };

  renderItem = (extra: ICarExta, index: number) => {
    const { carsDetailsStore, viewOnly } = this.props;
    const { selectedExtras } = carsDetailsStore;

    const extraDb = get(selectedExtras.filter(e => e?.equipment?.description === extra?.equipment?.description), '[0]', null);
    const checked = extraDb?.equipment?.description === extra?.equipment?.description;

    if (viewOnly && !checked) {
      return null;
    }

    return (
      <div className="cars-extras__item" key={`car-item-${index}`}>
        <div className="cars-extras__item-message">
          {!viewOnly ? (
            <><Checkbox checked={checked} onChange={(e) => this.onChangeActive(extra, e)} /><label>{extra?.equipment?.description}</label></>) : (
            <label>{extra?.equipment?.description}</label>)}
        </div>
        <div className="cars-extras__item-input">
          <label><Currency currency={extra?.charge?.currencyCode} />{renderNumber(extra?.charge?.amount, 2)}</label>
          <label><FormattedMessage id="per.day" /></label>
        </div>
      </div>
    );
  }

  render(): React.ReactNode {
    const { carsDetailsStore, extrasRef, display, viewOnly, hideTitle, hideMessage } = this.props;
    const { car, selectedExtras } = carsDetailsStore;
    
    const extras = car?.pricedEquips;

    if (!extras?.length || !display || (viewOnly && !selectedExtras?.length)) {
      return null;
    }

    return (
      <div className="hotel-info">
        <div ref={extrasRef} />

        <div className="hotel-info__item border-top">
          <div className="hotel-info__item-content">
            {!hideTitle ? (
              <h4 className="hotel-info__title">
                <FormattedMessage id={!viewOnly ? 'cars.extras' : 'cars.selected_extras'} />
              </h4>) : null}

            {!hideMessage ? (
              <label className="cars-extras__message">
                <FormattedMessage id="cars.extras.message" />
              </label>) : null}

            {extras.map(this.renderItem)}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    carsDetailsStore: state.carsDetailsStore,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  setSelectedExtras: (selectedExtras: ICarExta[]) => {
    dispatch(setSelectedExtras(selectedExtras));
  },
});

export const CarsExtras = connect(mapStateToProps, mapDispatchToProps)(CarsExtrasComponent);

