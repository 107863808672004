import React from 'react';
import { Skeleton } from 'antd';
import './style.scss';

interface IProps {}

const NUMBER = 1;
const NUMBER_OF_BLOCKS = [NUMBER, NUMBER, NUMBER, NUMBER];

export class SkeletonGetaways extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <div className="skeleton-getaways">
        <Skeleton
          className="skeleton-getaways__title-skeleton"
          active
          title={false}
          paragraph={{ rows: 1 }}
        />
        <div className="skeleton-getaways__wrapper">
          {NUMBER_OF_BLOCKS.map((item, index) => (
            <div key={`${index}-${item}`} className="skeleton-getaways__block">
              <Skeleton
                className="skeleton-getaways__img-skeleton"
                active
                title={false}
                paragraph={{ rows: 1 }}
              />
              <div className="skeleton-getaways__text-wrapper">
                <Skeleton
                  className="skeleton-getaways__text-skeleton"
                  active
                  title={false}
                  paragraph={{ rows: 1 }}
                />
                <Skeleton
                  className="skeleton-getaways__text-skeleton"
                  active
                  title={false}
                  paragraph={{ rows: 3 }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
