import React, { RefObject, useMemo, useState } from 'react';
import moment from 'moment';
import ReactGA from 'react-ga4';

import { connect } from 'react-redux';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { ExpiredSessionModal, ModalMessage, ThreeDSModal } from '@components';

import { Checkbox, Spin } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BlueButton } from '@share/components';
import { GetWalletNumber, IsImpersonatingStore, RootState, UrlUtils, getPreferenceFromAccount, getToken, renderNumber, LoginType } from '@share/utils';
import { GetPreferenceValue, MERCHANT_CHARGE_DESCRIPTION_FIELD_KEY, WalletPriceType, getWalletPrices } from '@utils';
import { ICarsState, ILoginState, IMarginatorState } from '@share/store/slices';
import { ICarsDetailsState, ICarsReviewBookState, getCarsBookPackageWithCard } from '@store/slices';
import { isEmpty } from 'lodash';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';

import { CancellationPolicies } from '../cars-cancellation-policies';
import { FormInstance } from 'antd/lib/form';
import { IBookPackageWithCard, IFormErrors, ScrollBehaviorEnum } from '@common-types';
import { C_PP, C_TERMS, DATE_FORMAT_FILTER, LIFE_STYLE_NAME_PARAM, SESSION_KEY_TOKEN_NAME_PARAM, USER_BASE_URL } from '@share/constants';
import { LOGOUT_FIELD_KEY } from '@share/common-types';

import './style.scss';

const ZERO = 0;

interface IMapStateToProps {
  marginatorStore: IMarginatorState;
  loginStore: ILoginState;

  carsStore: ICarsState;
  carsDetailsStore: ICarsDetailsState;
  carsReviewBookStore: ICarsReviewBookState;
}

interface IMapDispatchToProps {
  getBookPackageWithCard: (bookWithCard: IBookPackageWithCard) => void;
}


interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  paymentFormRef: RefObject<FormInstance>;
  driverFormRef: RefObject<FormInstance>;
  emailFormRef: RefObject<FormInstance>;
}

function CarsDisclaimersComponent(props: IProps) {    
  const [showNotRefundableMessage, setShowNotRefundableMessage] = useState(false);
  const [isClientCashNotRefundable, setIsClientCashNotRefundable] = useState(false);
  const [isAccept, setIsAccept] = useState(false);
  const [isClientCash, setIsClientCash] = useState(false);
  const [isAcceptBookingRemarks, setIsAcceptBookingRemarks] = useState(false);
  const [isAcceptCancellationPolicy, setIsAcceptCancellationPolicy] = useState(false);
  const [isAcceptAgeRestriction, setIsAcceptAgeRestriction] = useState(false);
  const [isAcceptMajorCreditCard, setIsAcceptMajorCreditCard] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  
  const { loginStore, carsStore, marginatorStore, carsDetailsStore, carsReviewBookStore, paymentFormRef, driverFormRef, emailFormRef, intl, getBookPackageWithCard } = props;
  const { account, userWallet, userWalletData } = loginStore;
  const { value } = marginatorStore;
  const { selectedCarsSearchClientCash } = carsStore;
  const { loading, car } = carsDetailsStore;
  const { selectedCarsReviewClientCash, loadingBooking, isSoldOutError } = carsReviewBookStore;

  const currency = car?.currency;
  const payNowPrice = car?.payNowPrice;
  const maxWalletClientCash = car?.maxWalletClientCash;

  const hasClientCash = account?.hasClientCash;
  const clientCashPercentage = account?.clientCashPercentage;
  const isLogged = !!userWallet;

  const marginatorPrice = payNowPrice / (1 - (value / 100));

  const walletPrices = getWalletPrices(account, userWalletData, selectedCarsSearchClientCash, marginatorPrice, maxWalletClientCash, WalletPriceType.Book);

  const displayWalletSavings = account?.walletWalletSavings && (walletPrices?.priceSavings > 0 || walletPrices?.maxClientCashAmountAllow > 0) && !!walletPrices?.maxClientCashAmountAllow;

  const marginatorPriceFinal = displayWalletSavings ? walletPrices?.priceFinalMax : walletPrices?.priceFinal;

  const eligibleClientCash = (marginatorPriceFinal > 0 ? marginatorPriceFinal : 0) * (!isEmpty(clientCashPercentage) ? parseInt(clientCashPercentage) : 0) / 100;

  const token = useMemo(() => getToken(account), [account]);

  const chargeDescription = GetPreferenceValue(account, MERCHANT_CHARGE_DESCRIPTION_FIELD_KEY);
  const walletNoDecimals = account?.walletNoDecimals;
  const walletIsExternal = account?.walletIsExternal;
  const walletNoPassEarnings = account?.walletNoPassEarnings;

  const convertionRate = userWalletData?.convertionRate ? userWalletData?.convertionRate : 1;

  const balanceCalculated = marginatorPrice / convertionRate;

  const maxWalletClientCashCalculated = maxWalletClientCash ? walletNoDecimals ? Math.floor(maxWalletClientCash / convertionRate) : maxWalletClientCash / convertionRate : null;
  const userWalletDataBalanceCalculated = (walletNoDecimals && userWalletData?.balance) ? Math.floor(userWalletData?.balance) : userWalletData?.balance;
  const maxWalletAmountIntermediated = walletNoPassEarnings ? 
                            maxWalletClientCashCalculated ?
                              (maxWalletClientCashCalculated && userWalletDataBalanceCalculated > maxWalletClientCashCalculated) ? 
                                maxWalletClientCashCalculated : 
                                userWalletDataBalanceCalculated : 
                              0 : 
                            userWalletDataBalanceCalculated > balanceCalculated ? 
                              balanceCalculated : 
                              userWalletDataBalanceCalculated;

  const maxWalletAmount = (maxWalletClientCashCalculated && maxWalletAmountIntermediated > maxWalletClientCashCalculated) ? maxWalletClientCashCalculated : maxWalletAmountIntermediated;

  const clientCash = selectedCarsReviewClientCash ? GetWalletNumber(selectedCarsReviewClientCash, account) : Number(0).toFixed(walletNoDecimals ? 0 : 2);
  
  const onChangeAccept = (e: { target: CheckboxChangeEventTarget }): void => {
    setIsAccept(e.target.checked);
  };

  const onChangeAcceptBookingRemarks = (e: { target: CheckboxChangeEventTarget }): void => {
    setIsAcceptBookingRemarks(e.target.checked);
  };

  const onChangeAcceptClientCash = (e: { target: CheckboxChangeEventTarget }): void => {
    setIsClientCash(e.target.checked);
  };

  const onChangeAcceptClientCashNotRefundable = (e: { target: CheckboxChangeEventTarget }): void => {
    setIsClientCashNotRefundable(e.target.checked);
    setShowNotRefundableMessage(e.target.checked);
  };

  const onChangeAcceptCancellationPolicy = (e: { target: CheckboxChangeEventTarget }): void => {
    setIsAcceptCancellationPolicy(e.target.checked);
  };

  const onChangeAcceptAgeRestriction = (e: { target: CheckboxChangeEventTarget }): void => {
    setIsAcceptAgeRestriction(e.target.checked);
  };

  const onChangeAcceptMajorCreditCard = (e: { target: CheckboxChangeEventTarget }): void => {
    setIsAcceptMajorCreditCard(e.target.checked);
  };

  const handleRedirect = () => {
    const { loginStore } = this.props;
    const { account } = loginStore;

    const logoutUrlStorage = localStorage.getItem(USER_BASE_URL);
    const logoutPref = getPreferenceFromAccount(account, LOGOUT_FIELD_KEY);
    const logoutUrl = !isEmpty(logoutUrlStorage) ? logoutUrlStorage : logoutPref;
    if (!isEmpty(logoutUrl)) {
      window.location.replace(logoutUrl);
    }
  }

  const handleOnCompleteBooking = () => {
    const { carsReviewBookStore, carsDetailsStore, loginStore } = props;
    
    const { card, driver, loadingBooking, isSoldOutError, selectedCarsReviewClientCash } = carsReviewBookStore;
    /*const { referenceNumber, agencyNumber } = hotelsStore;*/
    const { account, userWallet, userWalletData } = loginStore;
    const { car } = carsDetailsStore;

    const balance = car?.price;
    const convertionRate = userWalletData?.convertionRate ? userWalletData?.convertionRate : 1;
    const finalBalanceIntermediate = balance - (selectedCarsReviewClientCash ? selectedCarsReviewClientCash * convertionRate : 0);
    const finalBalance = Math.floor(finalBalanceIntermediate * 100) / 100;

    const isPrivateWithToken = account?.type === LoginType.PrivateWithToken;
    
    if (isPrivateWithToken && !token) {
      setIsSessionExpired(true); 
      return;
    }

    if (loadingBooking || isSoldOutError || !isAccept || !isAcceptBookingRemarks || !isAcceptCancellationPolicy || !isAcceptAgeRestriction || !isAcceptMajorCreditCard) {
      return;
    }

    /*
    let quote;
    if (values[QUOTE_LABEL]) {
      quote = (values[QUOTE_LABEL] as string);
    }
    */

    const data: any = {
      /*
      sessionKey,
      marginator: {
        percentage: marginatorStore.percentage,
        commission: marginatorStore.commission,
        impersonator: {
          memberId: marginatorStore.impersonator.memberId
        }
      },
      quote,
      */

      id: car?.id,
      customer: {
        primary: {
          personName: {
              givenName: driver?.givenName,
              surname: driver?.surname
          },
          telephone: {
              phoneTechType: '1',
              phoneNumber: driver?.phone
          },
          email: driver?.email,
          address: {
              streetNmbr: card?.addressLine,
              cityName: card?.city,
              postalCode: card?.zipCode,
              stateCode: card?.state,
              stateProv: card?.state
          },
          countryCode: driver?.country,
          birthDate: `${moment(driver?.birthDate, DATE_FORMAT_FILTER).format('yyyy-MM-DD')}`,
          language: 'EN',
        }
      },
      card: {
        addressLine: card?.addressLine,
        addressLine2: null,
        CVV: card?.cvv,
        cardNumber: card?.cardNumber,
        cardType: card?.cardType,
        city: card?.city,
        state: card?.state,
        country: card?.country,
        expireDate: card?.expireDate,
        holderName: card?.holderName,
        phone: driver?.phone,
        zipCode: card?.zipCode,
        addPaymentMethod: card?.addPaymentMethod,
      },
      currency: account?.currency,
    }

    const impersonatingStore = IsImpersonatingStore();
    const privateTokenFromUrl = account?.privateTokenFromUrl;
    if (isPrivateWithToken && privateTokenFromUrl) {
      const ssoSessionKey = UrlUtils.getSearchParam(SESSION_KEY_TOKEN_NAME_PARAM);
      data.ssoSessionKey = ssoSessionKey;
      data.lifeType = UrlUtils.getSearchParam(LIFE_STYLE_NAME_PARAM);
    } else if (!isEmpty(impersonatingStore)) {
      data.lifeType = impersonatingStore;
    }



    const hasClientCash = account?.hasClientCash;
    const isLogged = !!userWallet;

    const paymentMethod = { paymentMethodId: card?.id, CVC: card?.cvv };
    const creditCard = { ...data.card };

    if (hasClientCash && isLogged) {
      data.clientCashAmount = selectedCarsReviewClientCash ? selectedCarsReviewClientCash : 0;

      if (finalBalance > 0) {
        if (!isEmpty(card?.id)) {
          data.paymentMethod = paymentMethod;
        } else {
          data.card = creditCard;
        }
      }
    } else {
      if (!isEmpty(card?.id)) {
        data.paymentMethod = paymentMethod;
      } else {
        data.card = creditCard;
      }
    }

    const formPayment = paymentFormRef.current.validateFields();
    const formDriver = driverFormRef.current.validateFields();
    const formEmail = emailFormRef.current.validateFields();

    Promise.all([formPayment, formDriver, formEmail])
      .then(() => {
        getBookPackageWithCard(data);
      })
      .catch((error: IFormErrors) => {
        const { errorFields } = error;
        const { name } = errorFields[ZERO];

        const { scrollToField: scrollToPaymentField } = paymentFormRef.current;
        const { scrollToField: scrollToDriverField } = driverFormRef.current;
        const { scrollToField: scrollToEmailField } = emailFormRef.current;

        (() => {
          scrollToEmailField(name[ZERO], { behavior: ScrollBehaviorEnum.Smooth });
          scrollToPaymentField(name[ZERO], { behavior: ScrollBehaviorEnum.Smooth });
          scrollToDriverField(name[ZERO], { behavior: ScrollBehaviorEnum.Smooth });
        })();
      });

  }
  
  return (
    <div className="hotel-info cars-details-disclaimer">
      <div className="hotel-info__item">
        <div className="hotel-info__item-content">
          <CancellationPolicies
            displayCheckbox={true}
            disable={loading || loadingBooking}
            onChangeAcceptPolicy={onChangeAcceptCancellationPolicy}
          />
            
          <div className="review-book__wrapper">
            <Checkbox onChange={onChangeAccept} disabled={loading || loadingBooking}  />
            <p className="review-book__accept-text">
              <FormattedMessage
                id="accept.you.will.be.charged"
                values={{
                  price: renderNumber(marginatorPriceFinal, 2),
                  type: <FormattedMessage id="hotel" />,
                  reservation_name: (!isEmpty(chargeDescription)) ? chargeDescription : <FormattedMessage id="accept.you.will.be.charged.travcoding" />,
                  currency: currency
                }}
              />
            </p>
          </div>

          <div className="review-book__wrapper">
            <Checkbox onChange={onChangeAcceptBookingRemarks} disabled={loading || loadingBooking}  />
            <p className="review-book__accept-text">
              <FormattedMessage 
                id="cars.by.checking.this.box.you.acknowledge.that.you.have.read"
                values={{
                  pp: (msg: string) => {
                    return (
                      <a target="_blank" rel="noreferrer" href="https://www.travcoding.com/privacy-policy/" onClick={() => {
                        ReactGA.event({
                          category: account?.name as string,
                          action: `${C_PP}_${account?.name.toUpperCase()}`,
                          label: `User clicked privacy policy on book`,
                          nonInteraction: false,
                        });
                      }}>
                        {msg}
                      </a>);
                  },
                  tu: (msg: string) => {
                    return (
                      <a target="_blank" rel="noreferrer" href="https://www.travcoding.com/terms-of-use/" onClick={() => {
                        ReactGA.event({
                          category: account?.name as string,
                          action: `${C_TERMS}_${account?.name.toUpperCase()}`,
                          label: `User clicked terms of use on book`,
                          nonInteraction: false,
                        });
                      }}>
                        {msg}
                      </a>);
                  },
                }}
            />
            </p>
          </div>


          <div className="review-book__wrapper">
            <Checkbox onChange={onChangeAcceptAgeRestriction} disabled={loading || loadingBooking}  />
            <p className="review-book__accept-text">
              <FormattedMessage id="by.checking.this.box.you.acknowledge.age.restriction" />
            </p>
          </div>

          <div className="review-book__wrapper">
            <Checkbox onChange={onChangeAcceptMajorCreditCard} disabled={loading || loadingBooking}  />
            <p className="review-book__accept-text">
              <FormattedMessage id="by.checking.this.box.you.acknowledge.major.credit.cards" />
            </p>
          </div>

          {hasClientCash && isLogged &&
            <div className="review-book__wrapper">
              <Checkbox onChange={onChangeAcceptClientCash} disabled={loading || loadingBooking}  />
              <p className="review-book__accept-text">
                {!walletIsExternal ? 
                  (<FormattedMessage id="wallet.client.cash.by.checking.acknowledge" values={{ clientCash, reward: walletNoDecimals ? Math.floor(eligibleClientCash).toFixed(0) : eligibleClientCash.toFixed(2), clientCashPercentage: clientCashPercentage, clientCashName: account?.walletClientCashName }} />) :
                  (<FormattedMessage id="wallet.client.cash.by.checking.acknowledge.external" values={{ clientCash, clientCashName: account?.walletClientCashName }} />)}
              </p>
            </div>}

          {!walletIsExternal && hasClientCash && isLogged && selectedCarsReviewClientCash > 0 &&
            <div className="review-book__wrapper">
              <Checkbox onChange={onChangeAcceptClientCashNotRefundable} disabled={loading || loadingBooking}  />
              <p className="review-book__accept-text">
                <FormattedMessage id="wallet.client.not_refundable.acknowledge" values={{ clientCash, clientCashName: account?.walletClientCashName }} />
              </p>

              <ModalMessage text={intl.formatMessage({ id: 'wallet.client.not_refundable.modal.acknowledge'}, { clientCashName: account?.walletClientCashName })} disableTextLocalization disableTitleLocalization={true} visible={showNotRefundableMessage} onCancel={() => setShowNotRefundableMessage(false)} />
            </div>}

          {hasClientCash && !isLogged &&
            <div className="review-book__wrapper client-cash-message">
              <p className="review-book__accept-text">
                <FontAwesomeIcon icon={faTriangleExclamation} />{' '}<FormattedMessage id="wallet.client.not_logged.acknowledge" values={{ reward: eligibleClientCash.toFixed(2), currency: currency, clientCashPercentage: clientCashPercentage, clientCashName: account?.walletClientCashName }} />
              </p>
              <p className="booking-confirmed__contact-property" style={{ fontSize: '18px', marginTop: '10px', textAlign: 'right' }}>
                <BlueButton onClick={this.hangleGoToLogin}><FormattedMessage id="sign.in" /></BlueButton> <FormattedMessage id="or" /> <BlueButton onClick={this.hangleGoToSignUp}><FormattedMessage id="sign.up" /></BlueButton>
              </p>
            </div>}

          <div className="review-book__complete-btn">
            <BlueButton
              onClick={handleOnCompleteBooking}
              disabled={
                isSoldOutError || 
                loadingBooking || 
                !isAccept || 
                !isAcceptBookingRemarks || 
                !isAcceptCancellationPolicy || 
                !isAcceptAgeRestriction || 
                !isAcceptMajorCreditCard || 
                (hasClientCash && isLogged && (!isClientCash)) || 
                (hasClientCash && isLogged && !isClientCashNotRefundable && selectedCarsReviewClientCash > 0 && !walletIsExternal) || 
                (hasClientCash && isLogged && selectedCarsReviewClientCash > maxWalletAmount) || 
                (hasClientCash && isLogged && !!selectedCarsReviewClientCash && selectedCarsReviewClientCash <= maxWalletAmount && walletNoDecimals && Math.floor(selectedCarsReviewClientCash) != selectedCarsReviewClientCash)
              }
            >
              <FormattedMessage id="complete.booking" />

              {(loading || loadingBooking) ? <Spin /> : null}
            </BlueButton>

            <ThreeDSModal isCars />

            {isSessionExpired ? (
              <div className="result-wrapper__expired-message">
                <ExpiredSessionModal visible={isSessionExpired} hasRedirect={true} onHandleRedirect={handleRedirect} />
              </div>) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    marginatorStore: state.marginatorStore,
    loginStore: state.loginStore,
    carsStore: state.carsStore,
    carsDetailsStore: state.carsDetailsStore,
    carsReviewBookStore: state.carsReviewBookStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getBookPackageWithCard: getCarsBookPackageWithCard,
};

export const CarsDisclaimers = connect(mapStateToProps, mapDispatchToProps)(injectIntl(CarsDisclaimersComponent));

