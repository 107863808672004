
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { IBenefitsItems } from '@share/common-types';
import { ILoginState, IMenuState } from '@share/store/slices';
import { RootState, isExternalUrl, getLocalURI } from '@share/utils';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps {
  benefit: IBenefitsItems;
}

function BenefitComponent(props: IProps) {
  const { benefit, loginStore } = props;
  const { type, title, url, description, imageUrl } = benefit;
  const { account } = loginStore;

  if (isExternalUrl(url)) {
    return (
      <a href={url} className="benefit-rsi-upgrade" target="_blank" rel="noreferrer">
        <div className="benefit-rsi-upgrade__img-wrapper">
          <img src={imageUrl} alt={type} />
        </div>
        <div className="benefit-rsi-upgrade__text-wrapper">
          <p className="benefit-rsi-upgrade__title"><FormattedMessage id={title} /></p>
          <p className="benefit-rsi-upgrade__description"><FormattedMessage id={description} /></p>
        </div>
      </a>
    );
  }

  return (
    <Link 
      to={`/${account?.name}${getLocalURI(url)}`}
      className="benefit-rsi-upgrade"
    >
      <div className="benefit-rsi-upgrade__img-wrapper">
        <img src={imageUrl} alt={type} />
      </div>
      <div className="benefit-rsi-upgrade__text-wrapper">
        <p className="benefit-rsi-upgrade__title"><FormattedMessage id={title} /></p>
        <p className="benefit-rsi-upgrade__description"><FormattedMessage id={description} /></p>
      </div>
    </Link>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    menuStore: state.navigationMenuStore,
  };
};

export const Benefit = connect(mapStateToProps)(BenefitComponent);
