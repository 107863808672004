import React from 'react';

export const InfoGraySvg: React.FunctionComponent = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
      stroke="#292929"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8 5.33336H8.00667" stroke="#292929" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 7H8V11H9" stroke="#292929" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
