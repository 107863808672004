
import { MEMBERSHIP_LINK } from '@constants';
import { Routes } from '@share/constants';

import AmericanLifestyle from '@assets/images/list-clickable-cards/american-lifestyle.png';
import BeforeYouGo from '@assets/images/list-clickable-cards/before-you-go.png';
import BeforeYouGoSmall from '@assets/images/list-clickable-cards/before-you-go-small.png';
import LocalBenefits from '@assets/images/list-clickable-cards/local-benefits.png';
import Membership from '@assets/images/list-clickable-cards/membership.png';

import AmericanLifestyleRsiUpgrade from '@assets/images/list-clickable-cards/american-lifestyle-rsi-upgrade.jpg';
import BeforeYouGoRsiUpgrade from '@assets/images/list-clickable-cards/before-you-go-rsi-upgrade.jpg';
import LocalBenefitsRsiUpgrade from '@assets/images/list-clickable-cards/local-benefits-rsi-upgrade.jpg';
import MembershipRsiUpgrade from '@assets/images/list-clickable-cards/membership-rsi-upgrade.jpg';

const dailyDeals = {
  title: 'local.benefits',
  content: 'save.money.with.daily.deals',
  image: LocalBenefits,
  url: Routes.DailyBenefits,
  excludeMLM: undefined,
};

const beforeGo = {
  title: 'before.you.go',
  content: 'need.a.crib',
  image: BeforeYouGo,
  url: Routes.Resources,
  excludeMLM: undefined,
};

const beforeGoBase = {
  ...beforeGo,
  image: BeforeYouGoSmall,
};

const americanLifeStyle = {
  title: 'american.lifestyle',
  content: 'our.monthly.magazine',
  image: AmericanLifestyle,
  url: Routes.AmericanLifestyle,
  excludeMLM: true,
};


BeforeYouGoSmall
const renewMembership = {
  title: 'i.need.to.renew',
  content: 'i.need.to.book',
  image: Membership,
  url: MEMBERSHIP_LINK,
  excludeMLM: true,
};

export const ListCardsBase = [
  { ...dailyDeals },
  { ...beforeGoBase },
  { ...americanLifeStyle },
];

export const ListCards = [
  { ...dailyDeals },
  { ...beforeGo },
  { ...americanLifeStyle },
  { ...renewMembership },
];

export const ListCardsRsiUpgrade = [
  {
    ...dailyDeals,
    image: LocalBenefitsRsiUpgrade
  },
  {
    ...americanLifeStyle,
    image: AmericanLifestyleRsiUpgrade
  },
  {
    ...beforeGo,
    title: 'travel.resources',
    content: 'travel.resources.message',
    image: BeforeYouGoRsiUpgrade
  },
  {
    ...renewMembership,
    title: 'renew.membership',
    content: 'renew.membership.message',
    image: MembershipRsiUpgrade
  },
];

export interface INotificationBar {
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
}

