import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@share/utils';
import { getHeaders, axiosInstance } from '@share/utils';
import { Urls } from '@share/constants';
import { IHotelPopularDestinations } from '@common-types';
import { LocationsTypes } from '@share/common-types';

export interface IPopularDestinationsState {
  popularDestinations: IHotelPopularDestinations;
  loading: boolean;
  error: string;
}

const initialState: IPopularDestinationsState = {
  popularDestinations: null,
  loading: false,
  error: '',
};

const popularDestinationsSlice = createSlice({
  name: 'popularDestinations',
  initialState,
  reducers: {
    setLoading: (state: IPopularDestinationsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: IPopularDestinationsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setPopularDestinations: (
      state: IPopularDestinationsState,
      { payload }: PayloadAction<IHotelPopularDestinations>,
    ) => {
      state.popularDestinations = payload;
    },
  },
});

export const popularDestinationsActions = popularDestinationsSlice.actions;
export const popularDestinationsReducer = popularDestinationsSlice.reducer;

const popularDestinations: any = {
  "popularDestinations":[
     {
         "count":2259,
         "imageUrl":"https://travel.membergetaways.com/StaticFiles/PopularDestinations/Hotel/Orlando.png",
         "name":"Orlando, FL",
         "location":{
            "code": "34467",
            "name": "Orlando, Florida, United States of America",
            "type": LocationsTypes.Destination,
            "geoLocation": {
               "latitude": 28.54129,
               "longitude": -81.37904
            }
         }
     },
     {
        "count":485,
        "imageUrl":"https://travel.membergetaways.com/StaticFiles/PopularDestinations/Hotel/LasVegas.png",
        "name":"Las Vegas, NV",
        "location":{
           "code":"94511",
           "name":"Las Vegas, NV, United States",
           "geoLocation":{
              "latitude":36.114666,
              "longitude":-115.172872
           },
           "type":LocationsTypes.Destination
        }
     },
     {
        "count":1201,
        "imageUrl":"	https://travel.membergetaways.com/StaticFiles/PopularDestinations/Hotel/NewYork.png",
        "name":"New York City",
        "location":{
           "code":"111238",
           "name":"New York, NY, United States",
           "geoLocation":{
              "latitude":40.75668,
              "longitude":-73.98647
           },
           "type":LocationsTypes.Destination
        }
     },
     {
        "count":5422,
        "imageUrl":"https://travel.membergetaways.com/StaticFiles/PopularDestinations/Hotel/Paris.png",
        "name":"Paris, France",
        "location":{
           "code":"910195",
           "name":"Paris, France",
           "geoLocation":{
              "latitude":48.86272,
              "longitude":2.34375
           },
           "type":LocationsTypes.Destination
        }
     },
     {
        "count":9537,
        "imageUrl":"https://travel.membergetaways.com/StaticFiles/PopularDestinations/Hotel/London.png",
        "name":"London, England",
        "location":{
           "code":"852288",
           "name":"London, United Kingdom",
           "geoLocation":{
              "latitude":51.507538,
              "longitude":-0.127804
           },
           "type":LocationsTypes.Destination
        }
     },
     {
        "count":369,
        "imageUrl":"https://i.travelapi.com/hotels/2000000/1120000/1111300/1111293/ed52911a_t.jpg",
        "name":"Barcelona, Catalonia, Spain",
        "location":{
           "code":"871898",
           "name":"Barcelona, Catalonia, Spain",
           "geoLocation":{
             "latitude": 0,
             "longitude": 0
           },
           "type":LocationsTypes.Destination
        }
     },
     {
        "count":233,
        "imageUrl":"https://i.travelapi.com/hotels/3000000/2760000/2759600/2759555/45bac9a3_z.jpg",
        "name":"Buenos Aires, Argentina",
        "location":{
           "code":"258069",
           "name":"Buenos Aires, Argentina",
           "geoLocation":{
              "latitude":51.507538,
              "longitude":-0.127804
           },
           "type":LocationsTypes.Destination
        }
     },
     {
        "count":159,
        "imageUrl":"https://i.travelapi.com/hotels/12000000/11070000/11062300/11062225/ba6a2cc8_z.jpg",
        "name":"Chicago, Illinois, United States of America",
        "location":{
           "code":"50918",
           "name":"Chicago, Illinois, United States of America",
           "geoLocation":{
             "latitude": 41.88078,
             "longitude": -87.62813
           },
           "type":LocationsTypes.Destination
        }
     }
  ]
};

export const getHotelPopularDestinations = (): AppThunk => {
  return async (dispatch) => {
    dispatch(popularDestinationsActions.setLoading(true));

    try {
      dispatch(popularDestinationsActions.setPopularDestinations(popularDestinations));

      dispatch(popularDestinationsActions.setLoading(false));
    } catch (error) {
      dispatch(popularDestinationsActions.setError(error.toString()));
      dispatch(popularDestinationsActions.setLoading(false));
    }
  };
};
