import React from 'react';

export const CondoLoaderSvg: React.FunctionComponent = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.3" cx="24" cy="24" r="24" fill="#F2C94C" />
    <path
      d="M24 18V15"
      stroke="#F2994A"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.25 19.7496L30.4 17.5996"
      stroke="#F2994A"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30 24H33"
      stroke="#F2994A"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.25 28.25L30.4 30.4"
      stroke="#F2994A"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24 30V33"
      stroke="#F2994A"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.7501 28.25L17.6001 30.4"
      stroke="#F2994A"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 24H15"
      stroke="#F2994A"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.7501 19.7496L17.6001 17.5996"
      stroke="#F2994A"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
