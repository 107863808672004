import React from 'react';
import { Select } from 'antd';
import { SelectValue } from 'antd/lib/select';

import { getMonths } from '@share/utils';

import { ArrowsSvg } from '@share/assets';

import './style.scss';

interface IProps {
  onChange: (value: SelectValue) => void;
  value: number;
  date: Date;
  minMonth: number;
}

export class MonthSelect extends React.Component<IProps> {
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  getPopupContainer = (): HTMLElement => {
    return this.wrapperRef ? this.wrapperRef.current : document.body;
  };

  render(): React.ReactNode {
    const { value, onChange, date, minMonth } = this.props;
    const todayYear: number = new Date().getFullYear();
    const isSameYear: boolean = date.getFullYear() === todayYear;
    const months = isSameYear ? getMonths().slice(minMonth) : getMonths();

    return (
      <div className="month-select" ref={this.wrapperRef}>
        <Select
          className="month-select__select"
          value={value}
          onChange={onChange}
          virtual={false}
          suffixIcon={ArrowsSvg}
          getPopupContainer={this.getPopupContainer}
          defaultActiveFirstOption={false}
        >
          {months.map(({ label, value }) => {
            return (
              <Select.Option value={value} key={value} disabled={isSameYear && value < minMonth}>
                {label}
                <span className="month-select__hidden">, {date.getFullYear()}</span>
              </Select.Option>
            );
          })}
        </Select>
      </div>
    );
  }
}
