import React from 'react';

import { FormattedMessage } from 'react-intl';
import { Currency } from '../../currency';
import { IRange } from '@share/common-types';

interface IProps {
    range: IRange;
    currency: string;
}

export class FilterBudgetLabel extends React.Component<IProps> {
  render(): React.ReactNode {
    const { range, currency } = this.props;

    const { from, to } = range;

    if (from && to) {
      return (
        <span>
          <Currency currency={currency} />{from} <FormattedMessage id="to" /> <Currency currency={currency} />{to}
        </span>
      );
    }

    if (!from && to) {
      return (
        <span>
          <FormattedMessage id="less.than" /> <Currency currency={currency} />{to}
        </span>
      );
    }

    if (from && !to) {
      return (
        <span>
          <FormattedMessage id="greater.than" /> <Currency currency={currency} />{from}
        </span>
      );
    }
  }
}
