
import { FormattedMessage } from 'react-intl';
import { OfficesList } from '@constants';

import { IMenuState } from '@share/store/slices';

import './style.scss';
import { RootState } from '@share/utils';
import { connect } from 'react-redux';

interface IMapStateToProps {
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps {}

function ConciergeOfficesComponent(props: IProps) {
  const { menuStore } = props;
  const { items, loading } = menuStore;

  if (items?.isRemoveConciergeMessage || loading) {
    return null;
  }

  return (
    <div className="offices-wrapper">
      <div className="offices-wrapper__wrapper">
        <h2 className="offices-wrapper__heading secondary-font">
          <FormattedMessage id="concierge.offices.heading" />
        </h2>
        <div className="offices-wrapper__images-wrapper">
          {OfficesList.map(({ src, alt, id }) => (
            <img key={id} className="offices-wrapper__image" src={src} alt={alt} />
          ))}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
  };
};

export const ConciergeOffices = connect(mapStateToProps)(ConciergeOfficesComponent);
