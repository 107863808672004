
import { FormattedMessage } from 'react-intl';
import { Carousel } from 'antd';
import { connect } from 'react-redux';
import { useMemo } from 'react';

import { TreatYourselfLogos } from '@common-types';
import { MOBILE_MEDIA_POINT } from '@share/constants';

import TreatBackground from '@assets/images/treat-rsi-upgrade.jpg';

import './style.scss';

const SPEED = 1000;
const SLIDES_TO_SHOW = 3;
const SLIDES_TO_SHOW_MOBILE = 1;
const SLIDES_TO_SCROLL = 1;

const propsCarouselDesktop = {
  dots: false,
  infinite: true,
  arrows: false,
  speed: SPEED,
  slidesToShow: SLIDES_TO_SHOW,
  slidesToScroll: SLIDES_TO_SCROLL,
  autoplay: true,
};

const propsCarouselMobile = {
  ...propsCarouselDesktop,
  slidesToShow: SLIDES_TO_SHOW_MOBILE,
};

function TreatYourselfComponent() {
  const logos = TreatYourselfLogos;

  const isMobile = document.body.offsetWidth <= MOBILE_MEDIA_POINT;
  const propsCarousel = useMemo(() => isMobile ? propsCarouselMobile : propsCarouselDesktop, [isMobile]);

  return (
    <div className="treat-yourself-rsi-upgrade" style={{ backgroundImage: `url(${TreatBackground})` }}>
      <div className="treat-yourself-rsi-upgrade__wrapper">
        <div className="treat-yourself-rsi-upgrade__content">
          <p className="treat-yourself-rsi-upgrade__description">
            <FormattedMessage id="your.award.winning.travel" />
          </p>
          <p className="treat-yourself-rsi-upgrade__info-text">
            <FormattedMessage id="we.have.been.recognized" />
          </p>
          <div className="treat-yourself-rsi-upgrade__logos-wrapper">
            <div className="treat-yourself-rsi-upgrade__carrousel-contaner">
              <Carousel {...propsCarousel} lazyLoad="ondemand">
                {logos.map((item, index) => (
                  <div key={`${index}-${item.name}`}>
                    <div className="treat-yourself-rsi-upgrade__img-wrapper">
                      <img className="treat-yourself-rsi-upgrade__img" src={isMobile ? item.imageMobile : item.image} alt={item.name} />
                    </div>
                    <div>
                      <p className="treat-yourself-rsi-upgrade__logo-name">
                        <FormattedMessage id={item.name} />
                      </p>
                      <p className="treat-yourself-rsi-upgrade__logo-reward">
                        <FormattedMessage id={item.reward} />
                      </p>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const TreatYourself = connect()(TreatYourselfComponent);
