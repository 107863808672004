import React, { ChangeEvent, RefObject, useEffect, useMemo, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';

import { connect } from 'react-redux';
import { Form, Input, Select, Checkbox } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  GUEST_TYPE_MEMBER,
  namesPrefix,
  SECOND_CHARACTER_POSITION,
  TENTH_CHARACTER_POSITION,
  CONDO_BOOKING_GUEST_INFO_LABEL,
  USA_STATES,
  WEEKS_BOOKING_SAME_ADDRESS_INFO_LABEL,
  NULL_VALUE,
} from '@constants';
import {
  insuranceActions,
  refreshInsuranceQuote,
  IInsuranceState,
  IWeeksReviewBookState,
  weeksReviewBookAction,
} from '@store/slices';
import { ICondosStaticState, ILoginState, IMenuState } from '@share/store/slices';
import { ZipRuleTypeEnum } from '@share/common-types';
import { ICondoCardBooking, ICondoGuestBooking } from '@common-types';
import { SupplierType, IRciCountriesCodes, IIntervalCountriesCodes } from '@share/common-types';
import { RootState, Toaster } from '@share/utils';
import {
  setNumberPhone,
  updateWeeksGuest,
  getMemberPhone,
  getState,
  isPostalCode,
  hideBrowserAutoFill,
  getCountryCode,
  setValidator,
  getCountryIsoCode,
} from '@utils';
import { checkInLatinLetters } from '@share/utils';
import { countriesCode } from '@share/constants';
import { MemberChangeValue, SavedMembers } from '@components';

import './style.scss';

interface IMapStateToProps {
  weeksReviewBookStore: IWeeksReviewBookState;

  condosStaticStore: ICondosStaticState;

  insuranceStore: IInsuranceState;

  menuStore: IMenuState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setWeeksGuest: (guest: ICondoGuestBooking) => void;
  setWeeksCard: (card: ICondoCardBooking) => void;
  setErrorMessageZipCode: (value: boolean) => void;

  resetInsurance: () => void;
  refreshInsuranceQuote: (state: string, priceChangeHandler?: () => void) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  formRef: RefObject<FormInstance>;
  isSomeoneElse: boolean;

  onChangeSomeoneElse: (e: { target: CheckboxChangeEventTarget }) => void;
  onUpdateAddress: () => void;
  onChangeCountryState: () => void;
}

const ZERO = 0;
const MAX_LENGTH_VALID_POST_CODE = 5;
const MAX_LENGTH_VALID_POST_CODE_CANADA = 7;
const MAX_LENGTH_NAME = 35;
const MAX_LENGTH_INTERVALS = 25;
const MAX_LENGTH_CITY_FOR_INTERVALS = 15;
const MAX_LENGTH_SURNAME = 35;
const MIN_LENGTH_SURNAME = 2;
const MAX_LENGTH = 100;
const POSITION = 3;
const COUNTRY_CANADA = 'Canada';
const MAX_LENGTH_NUMBER = 12;
const MAX_LENGTH_ADDRESS = 25;

function WeeksGuestInfoComponent(props: IProps) {
  const wrapperRef: React.RefObject<HTMLDivElement> = useRef(null);

  const { menuStore, isSomeoneElse, formRef, loginStore, weeksReviewBookStore, condosStaticStore, intl, insuranceStore, onChangeSomeoneElse, setWeeksGuest, setWeeksCard, resetInsurance, refreshInsuranceQuote, onChangeCountryState } = props
  const { card, guest, weeksBookingSummary, errorsField, isErrorMessageZipCode, loadingBooking } = weeksReviewBookStore;
  const { condosCountriesCode } = condosStaticStore;
  const { bookingCard, supplierType } = weeksBookingSummary;
  const { account } = loginStore;
  const { items } = menuStore;
  const { bedRoomType, totalGuests, totalChildren, totalBathrooms, unitName } = bookingCard;
  const { Option } = Select;

  const getPopupContainer = (_props: any): HTMLElement | null => {
    return wrapperRef ? wrapperRef.current : document.body;
  };

  const onChangeNamePrefix = (value: string) => {
    setWeeksGuest(updateWeeksGuest(guest, { namePrefix: value }) as ICondoGuestBooking);
    formRef.current?.setFieldsValue({ prefixName: value });
  };

  const onChangeFirstName = (e: { target: HTMLInputElement }): void => {
    setWeeksGuest(updateWeeksGuest(guest, { givenName: e.target.value }) as ICondoGuestBooking);
  };

  const onChangeLastName = (e: { target: HTMLInputElement }): void => {
    setWeeksGuest(updateWeeksGuest(guest, { surname: e.target.value }) as ICondoGuestBooking);
  };

  const onChangePhone = (e: { target: HTMLInputElement }): void => {
    setWeeksGuest(updateWeeksGuest(guest, { phone: setNumberPhone(e.target.value) }) as ICondoGuestBooking);
  };

  const onChangePhoneCountryCode = (value: string) => {
    setWeeksGuest(updateWeeksGuest(guest, { phoneCountryCode: value }) as ICondoGuestBooking);
  };

  const handleUpdateAddress = () => {
    setTimeout(() => props.onUpdateAddress(), 200);
  }

  const onChangeAddressLine1 = (e: { target: HTMLInputElement }): void => {
    setWeeksGuest(updateWeeksGuest(guest, { addressLine1: e.target.value }) as ICondoGuestBooking);

    const isAddressAsPrimary = localStorage.getItem(WEEKS_BOOKING_SAME_ADDRESS_INFO_LABEL);
    if (isAddressAsPrimary === 'true') {
      setWeeksCard(updateWeeksGuest(card, { addressLine1: e.target.value }, false) as ICondoCardBooking);
      handleUpdateAddress();
    }
  };

  const onChangeAddressLine2 = (e: { target: HTMLInputElement }): void => {
    setWeeksGuest(updateWeeksGuest(guest, { addressLine2: e.target.value }) as ICondoGuestBooking);

    const isAddressAsPrimary = localStorage.getItem(WEEKS_BOOKING_SAME_ADDRESS_INFO_LABEL);
    if (isAddressAsPrimary === 'true') {
      setWeeksCard(updateWeeksGuest(card, { addressLine2: e.target.value }, false) as ICondoCardBooking);
      handleUpdateAddress();
    }
  };

  const onChangePrimaryCity = (e: { target: HTMLInputElement }): void => {
    setWeeksGuest(updateWeeksGuest(guest, { city: e.target.value }) as ICondoGuestBooking);

    const isAddressAsPrimary = localStorage.getItem(WEEKS_BOOKING_SAME_ADDRESS_INFO_LABEL);
    if (isAddressAsPrimary === 'true') {
      setWeeksCard(updateWeeksGuest(card, { city: e.target.value }, false) as ICondoCardBooking);
      handleUpdateAddress();
    }
  };

  const onChangePrimaryCountry = (value: string): void => {
    const countryCode = getCountryCode(value, supplierType, condosCountriesCode);
    const zipCode = isPostalCode(value, condosCountriesCode, weeksBookingSummary.supplierType) ? guest?.zipCode : '';
    const stateCode = !isEmpty(getState(value, condosCountriesCode, weeksBookingSummary.supplierType)) ? guest?.stateCode : '';
    const country = value;
    const newGuestData = { country, stateCode, zipCode, countryCode };
  
    setWeeksGuest(updateWeeksGuest(guest, newGuestData) as ICondoGuestBooking);

    const isAddressAsPrimary = localStorage.getItem(WEEKS_BOOKING_SAME_ADDRESS_INFO_LABEL);
    if (isAddressAsPrimary === 'true') {
      const countryCodeCard = getCountryIsoCode(value, supplierType, condosCountriesCode);
      setWeeksCard(
        updateWeeksGuest(card, {
          ...newGuestData,
          countryCode: countryCodeCard,
          country: supplierType !== SupplierType.Intervals ? value : countryCode
        }, false) as ICondoCardBooking,
      );
      handleUpdateAddress();
    }
    
    if (account?.enableInsurance && !items?.isRemoveInsurance) {
      if (guest?.country === 'USA' && value !== 'USA') {
        resetInsurance();
        handleToaster('insurance.policy.not_valid');
      } else if (guest?.country !== 'USA' && value === 'USA') {
        const isUSState = USA_STATES.map(s => s.postalCode).includes(card?.stateCode);
        refreshInsuranceQuote(isUSState ? card?.stateCode : NULL_VALUE);
        handleToaster('insurance.policy.us_resident');
      }
    }

    props.formRef.current?.setFieldsValue({ primaryCountry: value });
    onChangeCountryState();
  };

  const onChangePrimaryState = (value: string): void => {
    setWeeksGuest(updateWeeksGuest(guest, { stateCode: value }) as ICondoGuestBooking);

    const isAddressAsPrimary = localStorage.getItem(WEEKS_BOOKING_SAME_ADDRESS_INFO_LABEL);
    if (isAddressAsPrimary === 'true') {
      setWeeksCard(updateWeeksGuest(card, { stateCode: value }, false) as ICondoCardBooking);
      handleUpdateAddress();
    }

    if (account?.enableInsurance && !items?.isRemoveInsurance && guest?.country === 'USA') {
      refreshInsuranceQuote(value as string, () => handleToaster('insurance.policy.state_change')); 
    }

    formRef.current?.setFieldsValue({ primaryState: value });
    onChangeCountryState();
  };

  const handleToaster = (message: string) => {
    Toaster.info(intl.formatMessage({ id : message }), {
      position: 'bottom-center',
      icon: false,
      hideProgressBar: true,  
    });
  }

  const onChangePrimaryStateInput = (e: { target: HTMLInputElement }): void => {
    onChangePrimaryState(e.target.value);
  };

  const onChangePrimaryPostalCode = (e: ChangeEvent<HTMLInputElement>): void => {
    const isAddressAsPrimary = localStorage.getItem(WEEKS_BOOKING_SAME_ADDRESS_INFO_LABEL);

    if (props.weeksReviewBookStore.errorsField) {
      props.setErrorMessageZipCode(false);
    }

    if (e.target.value.length === POSITION && guest?.country === COUNTRY_CANADA) {
      const newValue = [e.target.value.slice(ZERO, POSITION), e.target.value.slice(POSITION)].join(
        ' ',
      );

      props.setWeeksGuest(
        updateWeeksGuest(guest, { zipCode: newValue }) as ICondoGuestBooking,
      );

      if (isAddressAsPrimary === 'true') {
        props.setWeeksCard(
          updateWeeksGuest(card, { zipCode: e.target.value } , false) as ICondoCardBooking,
        );
        handleUpdateAddress();
      }
    } else {
      props.setWeeksGuest(
        updateWeeksGuest(guest, { zipCode: e.target.value }) as ICondoGuestBooking,
      );

      if (isAddressAsPrimary === 'true') {
        props.setWeeksCard(
          updateWeeksGuest(card, { zipCode: e.target.value } , false) as ICondoCardBooking,
        );
        handleUpdateAddress();
      }
    }
  };

  const getErrorMessagePostalCode = () => {
    const countryCode =
      supplierType !== SupplierType.Intervals
        ? condosCountriesCode.rciCountries?.find((item: any) => item.name === guest?.country)
        : condosCountriesCode.intervalCountries?.find((item: any) => item.name === guest?.country);

    if (!isEmpty(countryCode) && supplierType !== SupplierType.Intervals) {
      const stateData = countryCode.states?.find((value: any) => value.code === guest?.stateCode);

      if ('zipRuleType' in countryCode && countryCode?.zipRuleType === ZipRuleTypeEnum.startsWith) {
        return (
          <FormattedMessage
            id="error.message.postal.code.start.with"
            values={{ value: stateData?.zipRule }}
          />
        );
      } else if (
        'zipRuleType' in countryCode &&
        countryCode.zipRuleType === ZipRuleTypeEnum.range
      ) {
        return (
          <FormattedMessage
            id="error.message.postal.code.range"
            values={{ value: stateData?.zipRule }}
          />
        );
      } else {
        return <FormattedMessage id="error.message.postal.code" />;
      }
    }
  };

  useEffect(() => {
    const guestInfo = localStorage.getItem(CONDO_BOOKING_GUEST_INFO_LABEL);
    
    let guestFromStorage: ICondoGuestBooking | undefined = undefined;
    if (guestInfo) {
      try {
        guestFromStorage = JSON.parse(guestInfo);
      } catch (error) {
        console.error(error);
      }
    }

    const resultData = guestFromStorage ? guestFromStorage : {};

    props.setWeeksGuest(
      updateWeeksGuest(guest, {
        phone: setNumberPhone(
          menuStore.items?.memberType === GUEST_TYPE_MEMBER && !isSomeoneElse
            ? getMemberPhone(menuStore?.items?.memberType, weeksBookingSummary.mainGuest.phone)
            : guest?.phone,
        ),
        ...resultData,
      }) as ICondoGuestBooking,
    );

    if (guestFromStorage) {
      setTimeout(() => {
        props.formRef?.current?.setFieldsValue({
          prefixName: guestFromStorage.namePrefix,
          givenName: guestFromStorage.givenName,
          surname: guestFromStorage.surname,
          phone: guestFromStorage.phone,
          primaryAddressLine1: guestFromStorage.addressLine1,
          primaryAddressLine2: guestFromStorage.addressLine2,
          primaryCity: guestFromStorage.city,
          primaryCountry: guestFromStorage.country,
          primaryState: guestFromStorage.stateCode,
          primaryPostalCode: guestFromStorage.zipCode,
        });
      });
    }
  }, []);

  const onChangeSavedMmeber = (selection: MemberChangeValue) => {
    props.setWeeksGuest(
      updateWeeksGuest(guest, { 
        givenName: selection.firstName,
        surname: selection.lastName,
        phone: setNumberPhone(selection.phone),
        email: selection.email,
      }) as ICondoGuestBooking,
    );
  }

  const countryStates = useMemo(() => guest ? getState(guest?.country, condosCountriesCode, supplierType) : null, [guest, condosCountriesCode, supplierType]);

  const loadingQuote = insuranceStore?.loading;

  return (
    <div className="condo-guest-info" ref={wrapperRef}>
      <div className="condo-guest-info__header">
        <h4 className="condo-guest-info__title secondary-font">
          <FormattedMessage id="guest.info" />
        </h4>
      </div>

      <div className="condo-guest-info__unit-info">
        <h4 className="condo-guest-info__unit-title capitalize">{unitName}</h4>
        <p className="condo-guest-info__unit-description">
          {totalGuests} <FormattedMessage id="adults" />
          {totalChildren !== ZERO ? (
            <>
              {', '}
              {totalChildren} <FormattedMessage id="children" />
            </>) : null}
          {(bedRoomType || totalBathrooms) ? ', ' : null}
          {bedRoomType}
          {totalBathrooms ? (
            <>
              {', '}
              <FormattedMessage id="bathrooms.count" values={{ count: totalBathrooms }} />
            </>) : null}
        </p>
      </div>

      <SavedMembers onMemberChange={onChangeSavedMmeber} />

      <div className="condo-guest-info__wrapper">
        <p className="condo-guest-info__guest-title capitalize">
          <FormattedMessage id="main.guest.title" />
        </p>
        <Checkbox onChange={onChangeSomeoneElse} checked={guest?.isBookingForSomeoneElse || false} disabled={loadingBooking}>
          {' '}
          <FormattedMessage id="booking.someone.else" />
        </Checkbox>
        <div className="condo-guest-info__block-wrapper">
          <Form.Item
            name="prefixName"
            className="prefixName"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'error.message.prefix.name' }),
              },
            ]}
          >
            <div className="condo-guest-info__select-wrapper prefix-name">
              <Select
                value={guest?.namePrefix}
                onChange={(value) => {
                  onChangeNamePrefix(value);
                }}
                disabled={loadingBooking}
              >
                {namesPrefix.map((prefix, index) => (
                  <Option key={index} value={prefix.namePrefix}>
                    {prefix.namePrefix}
                  </Option>
                ))}
              </Select>
            </div>
          </Form.Item>
          <Form.Item
            name="givenName"
            rules={[
              {
                required: true,
                max: supplierType !== SupplierType.Intervals ? MAX_LENGTH_NAME : MAX_LENGTH_INTERVALS,
                pattern: checkInLatinLetters,
                message: intl.formatMessage({ id: 'error.message.contact.name' }),
              },
            ]}
          >
            <div className="condo-guest-info__name-input-wrapper">
              <span className="condo-guest-info__input-label">
                <FormattedMessage id="first.name" />
              </span>
              <div className="condo-guest-info__input">
                <Input
                  placeholder={intl.formatMessage({ id: 'first.name' })}
                  value={guest?.givenName}
                  maxLength={
                    supplierType !== SupplierType.Intervals ? MAX_LENGTH_NAME : MAX_LENGTH_INTERVALS
                  }
                  onChange={(e) => {
                    onChangeFirstName(e);
                  }}
                  disabled={loadingBooking}
                />
              </div>
            </div>
          </Form.Item>
          <Form.Item
            name="surname"
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                max:
                  supplierType !== SupplierType.Intervals ? MAX_LENGTH_SURNAME : MAX_LENGTH_INTERVALS,
                pattern: checkInLatinLetters,
                message: intl.formatMessage({ id: 'error.message.contact.name' }),
              },
              {
                min: supplierType !== SupplierType.Intervals ? MIN_LENGTH_SURNAME : undefined,
                message: intl.formatMessage({ id: 'error.message.contact.name.minimum.letters' }),
              },
            ]}
          >
            <div className="condo-guest-info__name-input-wrapper">
              <span className="condo-guest-info__input-label">
                <FormattedMessage id="last.name" />
              </span>
              <div className="condo-guest-info__input">
                <Input
                  placeholder={intl.formatMessage({ id: 'last.name' })}
                  value={guest?.surname}
                  maxLength={
                    supplierType !== SupplierType.Intervals ? MAX_LENGTH_SURNAME : MAX_LENGTH_INTERVALS
                  }
                  onChange={(e) => {
                    onChangeLastName(e);
                  }}
                  disabled={loadingBooking}
                />
              </div>
            </div>
          </Form.Item>
        </div>
        <Form.Item
          name={'phone'}
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'error.message.site.phone' }),
            },
            {
              max: MAX_LENGTH_NUMBER,
              message: intl.formatMessage({ id: 'error.message.number.must.be' }),
            },
          ]}
        >
          <div className="condo-guest-info__name-input-wrapper">
            <span className="condo-guest-info__input-label">
              <FormattedMessage id="mobile.phone" />
            </span>
            <div className="condo-guest-info__input">
              <Input.Group compact>
                <Form.Item
                  name={['phone', 'code']}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <div>
                    <Select
                      defaultValue={`${countriesCode[ZERO].isoCode} ${countriesCode[ZERO].dialCode}`}
                      onChange={(value) => {
                        onChangePhoneCountryCode(value);
                      }}
                      getPopupContainer={getPopupContainer as (props: any) => HTMLElement}
                      disabled={loadingBooking}
                      optionFilterProp="label"
                      showSearch={true}
                      options={countriesCode.map(code => ({
                        value: code.dialCode,
                        label: `${code.isoCode} ${code.dialCode}`
                      }))}
                    >
                    </Select>
                  </div>
                </Form.Item>
                <Form.Item
                  name={['phone', 'number']}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <div className="condo-guest-info__phone_number">
                    <Input
                      placeholder={intl.formatMessage({ id: 'phone.number' })}
                      onChange={(e) => {
                        onChangePhone(e);
                      }}
                      value={guest?.phone}
                      maxLength={12}
                      disabled={loadingBooking}
                    />
                  </div>
                </Form.Item>
              </Input.Group>
            </div>
          </div>
        </Form.Item>

        <div className="condo-guest-info__guest-message">
          <FormattedMessage id="name.changes" />
        </div>

        <div className="condo-guest-info__primary-address-wrapper">
          <p className="condo-guest-info__primary-address-title">
            <FormattedMessage id="primary.address" />
          </p>
          <Form.Item
            name="primaryAddressLine1"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'error.message.address' }),
              },
            ]}
          >
            <div className="condo-guest-info__name-input-wrapper">
              <span className="condo-guest-info__input-label">
                <FormattedMessage id="address.line" values={{ number: 1 }} />
              </span>
              <div className="condo-guest-info__input">
                <Input
                  placeholder={intl.formatMessage({ id: 'street.and.number' })}
                  value={guest?.addressLine1}
                  maxLength={MAX_LENGTH_ADDRESS}
                  onChange={(e) => {
                    onChangeAddressLine1(e);
                  }}
                  disabled={loadingBooking}
                />
              </div>
            </div>
          </Form.Item>
          <Form.Item name="primaryAddressLine2" rules={[{ required: false }]}>
            <div className="condo-guest-info__name-input-wrapper">
              <span className="condo-guest-info__input-label">
                <FormattedMessage id="address.line" values={{ number: 2 }} />
              </span>
              <div className="condo-guest-info__input">
                <Input
                  placeholder={intl.formatMessage({ id: 'address.optional' })}
                  value={guest?.addressLine2}
                  maxLength={MAX_LENGTH_ADDRESS}
                  onChange={(e) => {
                    onChangeAddressLine2(e);
                  }}
                  disabled={loadingBooking}
                />
              </div>
            </div>
          </Form.Item>
          <Form.Item
            name="primaryCity"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'error.message.city' }),
                pattern: checkInLatinLetters,
              },
            ]}
          >
            <div className="condo-guest-info__name-input-wrapper">
              <span className="condo-guest-info__input-label">
                <FormattedMessage id="city" />
              </span>
              <div className="condo-payment__input">
                <Input
                  placeholder={intl.formatMessage({ id: 'city' })}
                  maxLength={
                    supplierType !== SupplierType.Intervals ? MAX_LENGTH : MAX_LENGTH_CITY_FOR_INTERVALS
                  }
                  value={guest?.city}
                  onChange={onChangePrimaryCity}
                  disabled={loadingBooking}
                />
              </div>
            </div>
          </Form.Item>
          <Form.Item
            name="primaryCountry"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'error.message.country' }),
              },
            ]}
          >
            <div className="condo-guest-info__name-input-wrapper">
              <span className="condo-guest-info__input-label">
                <FormattedMessage id="country" />
              </span>
              <div className="condo-payment__input">
                <Select
                  placeholder={hideBrowserAutoFill(
                    intl.formatMessage({ id: 'country' }),
                    SECOND_CHARACTER_POSITION,
                  )}
                  value={guest?.country || null}
                  onChange={onChangePrimaryCountry}
                  disabled={loadingBooking || loadingQuote}

                  optionFilterProp="label"
                  showSearch={true}
                  options={supplierType !== SupplierType.Intervals ?
                    condosCountriesCode?.rciCountries?.map(
                      (item: IRciCountriesCodes) => ({
                        value: item.name,
                        label: item.name,
                      })) : 
                    condosCountriesCode?.intervalCountries?.map(
                      (item: IIntervalCountriesCodes) => ({
                        value: item.name,
                        label: item.name,
                      }))}
                >
                </Select>
              </div>
            </div>
          </Form.Item>

          <Form.Item
            name="primaryState"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'error.message.simple.state' }),
                pattern: checkInLatinLetters,
              },
            ]}
          >
            <div className="condo-guest-info__name-input-wrapper">
              <span className="condo-guest-info__input-label">
                <FormattedMessage id="state" />
              </span>
              <div className="condo-payment__input">
                {!isEmpty(countryStates) ? (
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder={hideBrowserAutoFill(
                      intl.formatMessage({ id: 'select.state' }),
                      TENTH_CHARACTER_POSITION,
                    )}
                    value={guest?.stateCode || null}
                    onChange={onChangePrimaryState}
                    disabled={loadingBooking || loadingQuote}
                  >
                    {countryStates?.map(
                      (item, index) => {
                        return (
                          <Select.Option key={index} value={item.code}>
                            {item.name}
                          </Select.Option>
                        );
                      },
                    )}
                  </Select>) : (
                  <Input
                    placeholder={intl.formatMessage({ id: 'error.message.simple.state' })}
                    value={guest?.stateCode || NULL_VALUE}
                    onChange={onChangePrimaryStateInput}
                    disabled={loadingBooking}
                  />)}
              </div>
            </div>
          </Form.Item>

          <Form.Item
            name="primaryPostalCode"
            rules={[
              {
                required: true,
                message: getErrorMessagePostalCode(),
              },
              {
                max:
                  guest?.country === COUNTRY_CANADA
                    ? MAX_LENGTH_VALID_POST_CODE_CANADA
                    : MAX_LENGTH_VALID_POST_CODE,
                message:
                  guest?.country === COUNTRY_CANADA
                    ? intl.formatMessage({ id: 'error.message.postal.code.length.canada' })
                    : intl.formatMessage({ id: 'error.message.postal.code.length' }),
              },
              {
                validator: async () => {
                  await setValidator(errorsField, 'guests[0]', isErrorMessageZipCode);
                },
              },
            ]}
          >
            <div className="condo-guest-info__name-input-wrapper postal-code-input">
              <span className="condo-guest-info__input-label">
                <FormattedMessage id="postal-code" />
              </span>
              <div className="condo-payment__input">
                <Input
                  placeholder={intl.formatMessage({ id: 'postal.code.label' })}
                  onChange={onChangePrimaryPostalCode}
                  value={guest?.zipCode}
                  max={
                    guest?.country === COUNTRY_CANADA
                      ? MAX_LENGTH_VALID_POST_CODE_CANADA
                      : MAX_LENGTH_VALID_POST_CODE
                  }
                  disabled={loadingBooking}
                />
              </div>
            </div>
          </Form.Item>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    weeksReviewBookStore: state.weeksReviewBookStore,
    condosStaticStore: state.condosStaticStore,
    menuStore: state.navigationMenuStore,
    insuranceStore: state.insuranceStore,
    loginStore: state.loginStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setWeeksGuest: weeksReviewBookAction.setWeeksGuest,
  setWeeksCard: weeksReviewBookAction.setWeeksCard,
  setErrorMessageZipCode: weeksReviewBookAction.setErrorMessageZipCode,

  refreshInsuranceQuote,
  resetInsurance: insuranceActions.reset,
};

export const WeeksGuestInfo = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(WeeksGuestInfoComponent));
