
import { IGeoLocation } from './hotels';

export enum CondoMatchTypeEnum {
  Alternative = 'Alternative',
  Unspecified = 'Unspecified',
  Exact = 'Exact',
}

export interface IBounds {
  southWest: IGeoLocation;
  northEast: IGeoLocation;
}

export enum DateSearchTypeEnum {
  Strict = 'Strict',
  Flexible = 'Flexible',
  Anytime = 'Anytime',
}
