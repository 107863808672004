import React, { Component } from 'react';

import { BrioRciHowTo } from '@components';

export class BrioRciHowToPage extends Component {
  render(): React.ReactNode {
    return (
      <div className="brio-rci-how-to-page">
        <BrioRciHowTo />
      </div>
    );
  }
}
