import React from 'react';

import { withRouter, RouteComponentProps } from 'react-router-dom';

import { chunk, isEmpty } from 'lodash';
import { connect } from 'react-redux';

import { GetNumber } from '@share/utils';
import { ICar } from '@share/common-types';

import {
  PeopleSvg,
  SuitecaseSvg,
  DoorsSvg,
  TransmitionSvg,
  AirSvg,
} from '@assets';

import './style.scss';

interface IProps extends RouteComponentProps {
  car: ICar;

  chunks?: number;
}

class CarsCardItemsComponent extends React.Component<IProps> {

  render(): React.ReactNode {
    const { car, chunks } = this.props;
    const { vehicle, rental } = car;

    const doors =  GetNumber(vehicle?.vehType?.doorCount);
    const persons =  GetNumber(vehicle?.passengerQuantity);
    const baggage =  GetNumber(vehicle?.baggageQuantity);
    const transmission =  vehicle?.transmissionType;
    const airCondition =  vehicle?.airConditionInd;

    const unlimited = rental?.rentalRate?.rateDistance?.unlimited;
    const distanceType = rental?.rentalRate?.rateDistance?.distUnitName;
    
    const itemList = [];
    if (persons) {
      itemList.push(<div className="cars-card__item"><PeopleSvg /><label>Seats: {persons}</label></div>);
    }
    if (baggage) {
      itemList.push(<div className="cars-card__item"><SuitecaseSvg /><label>Baggage: {baggage}</label></div>);
    }
    if (doors) {
      itemList.push(<div className="cars-card__item"><DoorsSvg /><label>Doors: {doors}</label></div>);
    }
    if (airCondition) {
      itemList.push(<div className="cars-card__item"><AirSvg /><label>A/C</label></div>);
    }
    if (unlimited) {
      itemList.push(<div className="cars-card__item"><span>{distanceType === 'Mile' ? 'ml' : 'km'}</span><label>Unlimited</label></div>);
    }
    if (!isEmpty(transmission)) {
      itemList.push(<div className="cars-card__item"><TransmitionSvg /><label>{transmission}</label></div>);
    }

    if (!itemList?.length) {
        return null;
    }

    const primaryList = chunk(itemList, chunks ? chunks : 3);

    return (
      primaryList.map((i, index) => (
        <div key={`container_${index}`} className="cars-card__item-container">
          {i.map((item, indexItem) => (<React.Fragment key={`item_${indexItem}`}>{item}</React.Fragment>))}
        </div>
      ))
    );
  }
}

const CarsCardItemsComponentRouter = withRouter(CarsCardItemsComponent);

export const CarsCardItems = connect()(CarsCardItemsComponentRouter);
