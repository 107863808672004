import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CondoLoaderSvg, BookingProgressSvg } from '@assets';
import './style.scss';

interface IProps {
  isLoadingBooking?: boolean;
}

export class ReservationProgress extends React.Component<IProps> {
  render(): React.ReactNode {
    const { isLoadingBooking } = this.props;

    return (
      <div className={`progress ${isLoadingBooking ? 'loading-progress' : ''}`}>
        {isLoadingBooking ? <CondoLoaderSvg /> : <BookingProgressSvg />}
        <p className="progress__title secondary-font">
          <FormattedMessage id="reservation.in.progress" />
          ...
        </p>
        {isLoadingBooking && (
          <p className="progress__description">
            <FormattedMessage id="do.not.close.text.condo" />
          </p>
        )}
      </div>
    );
  }
}
