import React, { Component } from 'react';

import { NewMembers } from '@components';

export class NewMembersPage extends Component {
  render(): React.ReactNode {
    return (
      <div className="new-members-page" style={{ flex: '1' }}>
        <NewMembers />
      </div>
    );
  }
}
