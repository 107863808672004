import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { BlueButton } from '@share/components';
import { Routes } from '@share/constants';
import { ErrorAlertSvg } from '@assets';
import { connect } from 'react-redux';
import { getAccountUsernameFromPath } from '@share/utils';

import './style.scss';

interface IProps extends RouteComponentProps {}

class CarsDetailsServerErrorComponent extends React.Component<IProps> {
  refreshPage = (): void => {
    window.location.reload();
  };

  render(): React.ReactNode {
    const accountName = getAccountUsernameFromPath(this.props.history);
    return (
      <div className="hotel-details-server-error">
        <div className="hotel-details-server-error__wrapper">
          <div className="hotel-details-server-error__icon">
            <ErrorAlertSvg />
          </div>
          <div className="hotel-details-server-error__header secondary-font">
            <FormattedMessage id="problem.with.our.system" />
          </div>
          <div className="hotel-details-server-error__description">
            <FormattedMessage id="we.are.working.on.the.problem.try" />{' '}
            <a onClick={this.refreshPage}>
              <FormattedMessage id="refresh" />
            </a>{' '}
            <FormattedMessage id="this.page" />
          </div>
          <div className="hotel-details-server-error__footer">
            <Link to={`/${accountName}${Routes.CarsSearch}${location.search}`}>
              <BlueButton>
                <FormattedMessage id="back" />
              </BlueButton>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export const CarsDetailsServerError = connect()(withRouter(CarsDetailsServerErrorComponent));
