import React from 'react';

export const AdultSvg: React.FunctionComponent = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.99996 9.99992C11.8409 9.99992 13.3333 8.50753 13.3333 6.66659C13.3333 4.82564 11.8409 3.33325 9.99996 3.33325C8.15901 3.33325 6.66663 4.82564 6.66663 6.66659C6.66663 8.50753 8.15901 9.99992 9.99996 9.99992Z"
      fill="#C5C5C5"
    />
    <path
      d="M4.16663 16.6666V14.7221C4.16663 13.6907 4.57635 12.7016 5.30566 11.9723C6.03496 11.243 7.02412 10.8333 8.05552 10.8333H11.9444C12.9758 10.8333 13.965 11.243 14.6943 11.9723C15.4236 12.7016 15.8333 13.6907 15.8333 14.7221V16.6666"
      fill="#C5C5C5"
    />
  </svg>
);
