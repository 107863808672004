import React from 'react';

import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { isEmpty } from 'lodash';

import { DATE_ONLY_MONTH_FORMAT } from '@share/constants';
import { DATE_FORMAT } from '@share/constants';
import { IAccount, ICarsRecentSearches } from '@share/common-types';
import { formatDateCheckInOut } from '@share/utils';

import { CarsSearchHandlerArgs } from '../cars-recent-searches-wrapper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCalendar, faLocation, faReply } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IProps extends WrappedComponentProps {
  data: ICarsRecentSearches;
  disabled: boolean;
  account: IAccount;

  onSearchHandler: CarsSearchHandlerArgs;
}

class CarsRecentSearchesComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { data, disabled, account, onSearchHandler } = this.props;
    const { carRequest, dropOffLocation, pickUpLocation, filter } = data;
    const { pickUpDate, pickUpTime, returnDate, returnTime, driverAge, countryISO } = carRequest;

    const formattedStartDate = pickUpDate && formatDateCheckInOut(pickUpDate, DATE_ONLY_MONTH_FORMAT);
    const formattedEndDate = returnDate && formatDateCheckInOut(returnDate, DATE_FORMAT);
  
    const color: any = account?.searchIconsColor;
    const generalBorderRadius: any = account?.generalBorderRadius ? account?.generalBorderRadius : null;

    const style = !isEmpty(generalBorderRadius) ? { borderRadius: generalBorderRadius } : {};  
    const styleColor = !isEmpty(color)? { color } : {};  

    return (
      <div
        role="button"
        className={`recent-searches-picker cars-recents-searches ${disabled ? 'disabled' : ''}`}
        style={style}
        onClick={() =>{
          if (!disabled) {
            onSearchHandler(
              pickUpLocation,
              dropOffLocation,
              pickUpDate,
              returnDate,
              pickUpTime,
              returnTime,
              driverAge,
              countryISO,
              filter?.simpleType,
              filter?.rateDistance,
              filter?.rentalCompany,
              filter?.fuel
            );
          }
        }}
      >
        <div className="recent-searches-picker__info" style={{ width: '20px', ...styleColor }}>
          <FontAwesomeIcon icon={faLocation} />
          <FontAwesomeIcon icon={faReply} />
          <FontAwesomeIcon icon={faCalendar} />
        </div>

        <div className="recent-searches-picker__info">
          <p className="recent-searches-picker__title">{pickUpLocation.name}</p>
          <p className="recent-searches-picker__title">{(!isEmpty(dropOffLocation?.name) && dropOffLocation?.code !== pickUpLocation?.code) ? dropOffLocation?.name : <FormattedMessage id="cars.dropoff.location.placeholder" />}</p>
          <p className="recent-searches-picker__subtitle ttl">
            <span>
              {formattedStartDate}, {pickUpTime} - {formattedEndDate}, {returnTime}
            </span>
          </p>

          <div className={`search-again secondary-font ${disabled ? 'disabled' : ''}`}>
            <FormattedMessage id="availability.status.modal.search_again" />
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </div>
      </div>
    );
  }
}

export const CarsRecentSearchesItem = injectIntl(CarsRecentSearchesComponent);
