import React, { useEffect, useState } from 'react';

import { Carousel, Spin } from 'antd';
import { get } from 'lodash';

import { ImageLoadingObject } from '@common-types';
import { IImage } from '@share/common-types';

import './style.scss';

interface IProps {
  images: IImage[];
}

const speed = 500;
const slidesToShow = 1;
const slidesToScroll = 1;

export function WeekCarouselHotelImages(props: IProps) {
  const [failedImages, setFailedImages] = useState<string[]>([]);
  const [imagesMap, setImagesMap] = useState<ImageLoadingObject>();
  
  const { images } = props;

  const generateImagesMap = (): ImageLoadingObject => {
    return images?.reduce((res: ImageLoadingObject, img: IImage, index: number) => {
      res[index] = {
        img,
        loading: true,
      };

      return res;
    }, {} as ImageLoadingObject);
  };

  useEffect(() => {
    setFailedImages([]);
    setImagesMap(generateImagesMap());
  }, []);

  useEffect(() => {
    setImagesMap(generateImagesMap());
  }, [images]);

  const addFailedImage = (src: string): void => {
    setFailedImages([...failedImages, src]);
  };

  const onImageLoad = (index: number): void => {
    setImagesMap({
      ...imagesMap,
      [index]: {
        ...imagesMap[index],
        loading: false,
      },
    });
  };

  const propsCarousel = {
    dots: false,
    infinite: true,
    arrows: true,
    speed,
    slidesToShow,
    slidesToScroll,
  };

  if (!images) {
    return null;
  }

  return (
    <Carousel {...propsCarousel} lazyLoad="ondemand">
      {images?.map((image: IImage, i: number) => {
        if (failedImages?.includes(image.url)) {
          return null;
        }

        const loading = get(imagesMap, `[${i}].loading`, false);

        return (
          <div className="condo-custom-carousel-image" key={i}>
            <div
              className="condo-custom-carousel-image__spinner"
              style={{
                display: loading ? 'flex' : 'none',
              }}
            >
              <Spin />
            </div>
            <img
              style={{ display: loading ? 'none' : 'block' }}
              className="condo-custom-carousel-image__item"
              src={image.url}
              alt={image.description}
              onLoad={() => onImageLoad(i)}
              onError={() => addFailedImage(image.url)}
            />
          </div>
        );
      })}
    </Carousel>
  );
}
