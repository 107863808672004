import React from 'react';

import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import { IBookingSubStatus } from '@common-types';

import './style.scss';

interface IProps {
  text: string;
  className?: string;
  hideBorder?: boolean;
  subStatus?: IBookingSubStatus[];
}

export class Status extends React.Component<IProps> {
  render(): React.ReactNode {
    const { className, text, hideBorder } = this.props;
    if (!isEmpty(text)) {
      return (
        <div className={`${!hideBorder? 'status' : ''}`} >
          <div className={`text ${className? className : ''}`}>
            <FormattedMessage id={text} />
          </div>
        </div>
      );
    }

    return null;
  }
}
