import React from 'react';
import moment from 'moment';
import format from 'date-fns/format';

import { Modal, Spin, Tooltip, Typography } from 'antd';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { DateSearchTypeEnum, IAccount, ICondo, ICondoLocation, ISessionKey } from '@share/common-types';
import { RefundableEnum } from '@common-types';
import { IMarginatorState,  ILoginState } from '@share/store/slices';
import { RootState, renderNumber, formatDateCheckInOut, getSelectedCurrency, LoginType } from '@share/utils';
import { HasPermission, ViewQuotesSharePermission, openCondoPage } from '@utils';
import { Currency, ModalShare, } from '@components';
import { BlueButton } from '@share/components';
import { DATE_FORMAT_CHECK_IN_OUT } from '@constants';
import { DownLoadShareType, HandleDownload } from '@share/api';

import './style.scss';

const modalWidth = 900;
const percents = 100;
const ONE = 1;
const DATE_FORMAT = 'd MMM';

interface IMapStateToProps {
  marginatorStore: IMarginatorState;
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps, RouteComponentProps {
  visible: boolean;
  adultsCount: number;
  kidsCount: number;
  bedroomsCount: number;
  isFlexible: boolean;
  months?: string[];
  startDate?: string;
  endDate?: string;
  locationObject: ICondoLocation;
  sessionKey: ISessionKey;
  condos: ICondo[];
  onCancel: () => void;
  onRemove: (condo: ICondo) => void;
}

interface IState {
  loading: boolean;
  visibleAction: boolean;
  isShare: boolean;
}

class ModalCompareComponent extends React.Component<IProps, IState> {

  state: IState = { loading: false, visibleAction: false, isShare: false };

  handleView = (condo: ICondo) => {
    const { condoId } = condo;
    
    openCondoPage(condoId, this.props.history);
  }

  handleData = (members?: string[], comment?: string) => {
    const { sessionKey, locationObject, loginStore, condos, marginatorStore, adultsCount, kidsCount, bedroomsCount, months, isFlexible, startDate, endDate } = this.props;
    const { value } = marginatorStore;
    const { account } = loginStore;

    const currency = getSelectedCurrency(account as IAccount);

    const data: any = {
      quotes: condos.map(condo => {
        const marginatorPrice = (condo.price ? condo.price : 0) / (1 - (value / 100));
        const savingsNunmber = condo.savings / marginatorPrice * 100;
        const discount = renderNumber(savingsNunmber, 0);

        return ({
          condoId: condo.condoId,
          displayName: condo.displayName,
          fullAddress: condo.fullAddress,
          refundability: condo.refundability,
          reviewsCount: condo.rating?.reviewsCount,
          rating: condo.rating?.value,
          currency,
          price: marginatorPrice,
          publicPrice: condo.publicPrice,
          availableDates: condo.availableDates?.length ? [...condo.availableDates?.map(ad => ({
            matchType: ad.matchType,
            totalNights: moment(ad.to, 'yyyy-MM-DD').diff(moment(ad.from, 'yyyy-MM-DD'), 'days'),
            from: ad.from,
            to: ad.to
          }))] : [],
          discount,
          image: get(condo, 'images[0].url'),
        });
      }),
      comment,
      members,
      location: locationObject,
      searchType: !isFlexible ? DateSearchTypeEnum.Strict : months?.length ? DateSearchTypeEnum.Flexible : DateSearchTypeEnum.Anytime,
      condoRequest: {
        location: locationObject,
        adultsCount,
        kidsCount,
        childrenCount: kidsCount,
        bedroomsCount,
      },
      sessionKey
    };

    if (isFlexible) {
      data.condoRequest.months = months?.length ? [...months.map(m => moment(m, 'DD MMM yyyy').format('yyyy-MM-DD'))] : [];
    } else {
      data.condoRequest.checkIn = startDate;
      data.condoRequest.checkOut = endDate;
    }

    return data;
  }

  handleDownload = () => {
    const loading = this.state.loading;
    this.setState({ loading: true });
    HandleDownload(DownLoadShareType.CONDO, loading, this.handleData(), this.props.intl, () => this.setState({ loading: false }), () => this.setState({ visibleAction: false}));
  }

  render(): React.ReactNode {
    const {
      visible,
      condos,
      adultsCount,
      kidsCount,
      bedroomsCount,
      isFlexible,
      months,
      startDate,
      endDate,
      onCancel,
      onRemove
    } = this.props;
    const { loading } = this.state;
    const { user, account } = this.props.loginStore;

    const currency = getSelectedCurrency(account as IAccount);

    const fontFamily = account?.fontFamily;
    const secondaryFontFamily = account?.secondaryFontFamily;

    const background = account?.buttonColor;
    const border = account?.buttonBorder;
    const color = account?.buttonTextColor;
    const borderRadius = account?.buttonBorderRadius;
    const buttonTextTransform = account?.buttonTextTransform;
      
    const styleButtonBorderRadius = !isEmpty(borderRadius) ? { borderRadius: `${borderRadius}px` } : {};  
    const styleButtonnTextTransform = (buttonTextTransform && !isEmpty(buttonTextTransform)) ? { ...styleButtonBorderRadius, textTransform: buttonTextTransform as any } : { ...styleButtonBorderRadius };  
    const styleButtonBorder = !isEmpty(border)? { ...styleButtonnTextTransform, border } : { ...styleButtonnTextTransform };  
    const styleButtonColor = !isEmpty(color)? { ...styleButtonBorder, color } : { ...styleButtonBorder };  
    const styleButton = !isEmpty(background)? { ...styleButtonColor, background } : { ...styleButtonColor };  
  
    return (
      <Modal
        className={`condo-modal-compare ${fontFamily ? `${fontFamily}-font`: ''} ${secondaryFontFamily ? `secondary-${secondaryFontFamily}-font`: ''}`}
        open={visible}
        footer={null}
        onCancel={onCancel}
        wrapClassName="condo-modal-compare__wrapper"
        width={modalWidth}
      >
        <div>
          <h4 className="condo-modal-compare__title secondary-font">
            <FormattedMessage id="results.condos.card.compare.modal.title" />
          </h4>

          <div className="condo-modal-compare__hotels" style={{ flexDirection: 'column' }}>
            <div className="hotels-dates">
              {isFlexible ? (
                <><FormattedMessage id="flexible.dates" /> - {months?.length ? months?.map(m => moment(m, 'DD MMM yyyy').format('MMMM')).join(', ') : <FormattedMessage id="anytime" />}</>) : (
                <><FormattedMessage id="specific.dates" /> - {formatDateCheckInOut(startDate as string, DATE_FORMAT_CHECK_IN_OUT)} - {formatDateCheckInOut(endDate as string, DATE_FORMAT_CHECK_IN_OUT)}</>)}
            </div>
            <div className="hotels-dates">
              {adultsCount} <FormattedMessage id="adult" values={{ count: adultsCount }} /> - {kidsCount} <FormattedMessage id="child" values={{ count: kidsCount }} /> - {bedroomsCount} <FormattedMessage id="bedroom" values={{ count: bedroomsCount }} />
            </div>
          </div>

          <div className="condo-modal-compare__hotels">
            {condos.map((condo, index) => {
              const { price, availableDates } = condo;

              const { value } = this.props.marginatorStore;
              const marginatorPrice = (price ? price : 0) / (1 - (value / 100));

              const msave = condo.publicPrice ? (marginatorPrice * percents) / condo.publicPrice : 100;
              const savings = Math.floor(percents - msave);

              return (
                <div key={index} className={`condo-modal-compare__hotel ${index === 0 ? 'display-border' : ''} hotel_${condos.length}`}>
                  <div className="condo-images-container">
                    <div className="condo-image" style={{ backgroundImage: `url(${get(condo, 'images[0].url')})` }}>
                    </div>
                  </div>

                  <div className="condo-text-container" style={{ height: '86px' }}>
                    <p
                      style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          lineClamp: 3,
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: 'vertical',
                      }}>{condo.displayName}</p>
                  </div>

                  <div className="condo-text-container">
                    <p
                      style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          lineClamp: 4,
                          WebkitLineClamp: 4,
                          WebkitBoxOrient: 'vertical',
                      }}>{condo.fullAddress}</p>
                  </div>

                  <div className="condo-text-container" style={{ height: '40px' }}>
                    {availableDates?.length ? (
                      <div className="condo-modal-compare__date-label">
                        {availableDates.length >= ONE && (
                          <div className="condo-modal-compare__date-more">
                            <p className="condo-modal-compare__date-more-text">
                              <Tooltip className="condo-compare-tooltip" overlayStyle={{ whiteSpace: 'pre-line' }} placement="top" title={
                                <>
                                  {availableDates.map((d, i) => <Typography key={i} style={{ color: '#FFFFFF' }}>{format(new Date(d.from), DATE_FORMAT)} - {format(new Date(d.to), DATE_FORMAT)}</Typography>)}
                                </>
                              }>
                                {availableDates.length} <FormattedMessage id="available.dates" />
                              </Tooltip>
                            </p>
                          </div>)}
                      </div>) : null}
                  </div>

                  {condo.refundability && condo.refundability !== RefundableEnum.Unknown ? (
                    <div className="condo-text-container" style={{ height: '43px' }}>
                      <div className="hotel-card-wrapper__refundable">
                        {condo.refundability === RefundableEnum.Refundable && (
                          <div className="hotel-card-wrapper__refundable-ref">
                            <FormattedMessage id="refundable" />
                          </div>)}
                        {condo.refundability === RefundableEnum.Nonrefundable && (
                          <div className="hotel-card-wrapper__refundable-nonref">
                            <FormattedMessage id="non.refundable" />
                          </div>)}
                      </div>
                    </div>) : null}

                  <div className="condo-amenities-container" style={{ height: '43px' }}>
                    {(condo.rating?.value || condo.rating?.reviewsCount) ? (
                      <label>{condo.rating?.value ? (<><strong>{condo.rating?.value.toFixed(1)}</strong> / 5</>) : null} {condo.rating?.reviewsCount ? (<>({condo.rating?.reviewsCount} <FormattedMessage id="reviews" />)</>) : null}</label>) : (
                      <label><FormattedMessage id="reviews.guest.no.ratings" /></label>)}
                  </div>

                  <div className="condo-text-container" style={{ height: '90px' }}>
                    {condo.publicPrice ? (<p style={{ textDecoration: 'line-through' }}><Currency currency={currency} />{renderNumber(condo.publicPrice, 2)}</p>) : null}
                    <p><strong style={{ fontSize: '20px', fontWeight: 'bold' }}><Currency currency={currency} />{renderNumber(marginatorPrice, 2)}</strong></p>
                    {savings > 0 ? (<p>{savings.toFixed(0)} % <FormattedMessage id="results.hotel.card.compare.modal.off" /></p>) : null}
                  </div>

                  <div className="condo-view-container">
                    <div className="view-button" style={styleButton} onClick={() => this.handleView(condo)}>
                      <FormattedMessage id="results.hotel.card.compare.modal.view" />
                    </div>
                  </div>

                  <div className="condo-remove-container">
                    <label className={`${condos.length <= 2 ? 'disabled' : ''}`} onClick={() => {
                      if (condos.length > 2) {
                        onRemove(condo);
                      }
                    }}><FormattedMessage id="remove" /></label>
                  </div>
                </div>)
            })}
          </div>

          <div className="condo-modal-compare__buttons">
            {(!account || account.type !== LoginType.PrivateWithToken) ?
              <BlueButton disabled={loading} onClick={this.handleDownload}>
                {loading ? (<Spin />) : <FormattedMessage id="results.hotel.card.compare.modal.download" />}
              </BlueButton> :
              <>
                <BlueButton disabled={loading} onClick={() => this.setState({ visibleAction: true, isShare: false })}>
                  {loading ? (<Spin />) : <FormattedMessage id="results.hotel.card.compare.modal.download" />}
                </BlueButton>

                {HasPermission(user, ViewQuotesSharePermission) ?
                  <BlueButton disabled={loading} onClick={() => this.setState({ visibleAction: true, isShare: true })}>
                    {loading ? (<Spin />) : <FormattedMessage id="results.hotel.card.compare.modal.share" />}
                  </BlueButton> : null}
              </>}
          </div>
        </div>

        <ModalShare
          visible={this.state.visibleAction}
          isShare={this.state.isShare}
          type={DownLoadShareType.CONDO}
        
          onCancel={() => this.setState({ visibleAction: false})}
          onLoading={(loading) => this.setState({ loading })}
          convertData={this.handleData}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    marginatorStore: state.marginatorStore,
    loginStore: state.loginStore,
  };
};

const ModalCompareComponentRouter = withRouter(ModalCompareComponent);

export const ModalCompare = connect(mapStateToProps)(injectIntl(ModalCompareComponentRouter));