import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import isEmpty from 'lodash/isEmpty';
import {
  fetchMemberStories,
  selectMemberStoriesById,
  memberStoriesActions,
  selectMemberStoriesIsLoading,
} from '@store/slices';
import { RootState } from '@share/utils';
import { ImagesGallery, MemberStoriesCarousel } from '@components';
import { IMemberStories } from '@common-types';
import { scrollTop } from '@share/utils';
import { MemberStorySkeleton } from './member-story-skeleton';

import MemberStoryBackground from '@assets/images/member-story-background.png';

import './style.scss';

interface IMapStateToProps {
  memberStory: IMemberStories;
  loading: boolean;
}

interface IMapDispatchToProps {
  getMemberStories: () => void;
  setActiveStoryById: (id: number) => void;
}

interface IParams {
  id: string;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps<IParams> {}

class MemberStoryPageComponent extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    const { id } = props.match.params;
    this.setActiveStory(id);
  }

  setActiveStory = (id: string): void => {
    this.props.setActiveStoryById(Number(id));
    scrollTop();
  };

  getQuoted(str: string, currentIndex: number, length: number): string {
    const firstIndex = 0;
    const lastIndex = length - 1;

    if (currentIndex === firstIndex) {
      return '”' + str;
    }

    if (currentIndex === lastIndex) {
      return str + '”';
    }

    return str;
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const prevId = prevProps.match.params.id;
    const currentId = this.props.match.params.id;

    if (prevId !== currentId) {
      this.setActiveStory(currentId);
    }
  }

  componentDidMount() {
    this.props.getMemberStories();
  }

  render() {
    const { memberStory, loading } = this.props;

    return (
      <div className="member-story">
        <div
          className="member-story__header-wrapper"
          style={{ backgroundImage: `url(${MemberStoryBackground})` }}
        >
          <div className="member-story__container">
            <div className="member-story__header-title secondary-font">
              <FormattedMessage id="memberStories.header" />
            </div>
          </div>
        </div>
        <div className="member-story__content member-story__container">
          {loading ? (
            <MemberStorySkeleton />
          ) : (
            <>
              {!isEmpty(memberStory?.images) && (
                <ImagesGallery titleIntlId="memberStories.header" images={memberStory.images} />
              )}
              <div className="member-story__description_block">
                {memberStory?.paragraphs.map((paragraph, index) => (
                  <p key={index}>
                    {this.getQuoted(paragraph, index, memberStory?.paragraphs.length)}
                  </p>
                ))}

                <div className="member-story__description_block_author secondary-font">{memberStory?.author}</div>
              </div>
            </>
          )}
        </div>
        <MemberStoriesCarousel />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    memberStory: selectMemberStoriesById(state) as IMemberStories,
    loading: selectMemberStoriesIsLoading(state),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  setActiveStoryById: (id: number) => dispatch(memberStoriesActions.setActiveStoryById(id)),
  getMemberStories: () => dispatch(fetchMemberStories()),
});

export const MemberStoryPage = connect(mapStateToProps, mapDispatchToProps)(withRouter(MemberStoryPageComponent));
