import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ICondoPopularDestinationsState, getCondoPopularDestinations } from '@store/slices';
import {
  ICondosState,
  condosActions,
  ICondosLocationPickerState,
  condosLocationPickerActions,
} from '@share/store/slices';
import { RootState, LoginType } from '@share/utils';
import { ICondoLocation } from '@share/common-types';
import { get, isEmpty } from 'lodash';
import { IPopularDestinations } from '@common-types';
import { ILoginState } from '@share/store/slices';

import './style.scss';

interface IMapStateToProps {
  condoPopularDestinationsStore: ICondoPopularDestinationsState;
  condosStore: ICondosState;
  condosLocationPickerStore: ICondosLocationPickerState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getCondoPopularDestinations: () => void;
  setIsFocusDatePicker: (bool: boolean) => void;
  selectLocationLabel: (value: string) => void;
  selectLocation: (value: ICondoLocation) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {}

const ONE = 1;
const TWO = 2;

class CondoPopularDestinationsComponent extends React.Component<IProps> {
  onClickPopularDestination = (location: ICondoLocation, hasPublicValidToken: boolean): void => {
    if (hasPublicValidToken) {
      const { setIsFocusDatePicker, selectLocation, selectLocationLabel } = this.props;

      setIsFocusDatePicker(true);
      selectLocation(location);
      selectLocationLabel(location.name);
    }
  };

  componentDidMount() {
    this.props.getCondoPopularDestinations();
  }

  render() {
    const { condoPopularDestinationsStore, loginStore } = this.props;
    const { condoPopularDestinations, loading } = condoPopularDestinationsStore;
    const { accessToken, account } = loginStore;

    const hasPublicAccessToken = account?.hasPublicAccessToken;
    const hasPublicValidToken = account?.type !== LoginType.Public || !hasPublicAccessToken || !isEmpty(accessToken);

    const popularDestinations: IPopularDestinations[] = condoPopularDestinations?.popularDestinations ? condoPopularDestinations?.popularDestinations.slice(0, 5) : [];

    return condoPopularDestinations && !loading ? (
      <div
        className={`condo-popular-destinations ${
          !popularDestinations.length ? 'hide-section' : ''
        }`}
      >
        <p className="condo-popular-destinations__title secondary-font">
          <FormattedMessage id="destination.cities" />
        </p>
        <div className="condo-popular-destinations__wrapper">
          {popularDestinations.map((destination, index) => {
            const detinationNameSplit = destination.name.split(',');
            const sortName = get(detinationNameSplit, '[0]', null);
            return (
              <div
                key={index + destination.count}
                className={`condo-popular-destinations__destination-wrapper
              ${
                index === popularDestinations.length - ONE ||
                index === popularDestinations.length - TWO
                  ? 'small-wrapper'
                  : ''
              } ${
                !hasPublicValidToken ? 'no-access-token' : ''
              }`}
                onClick={() => this.onClickPopularDestination(destination.location, hasPublicValidToken)}
              >
                <div className="condo-popular-destinations__destination-info">
                  <p className="condo-popular-destinations__destination-name">{sortName}</p>
                  <p className="condo-popular-destinations__destination-count">
                    {destination.count} <FormattedMessage id="condos.available" />
                  </p>
                </div>
                <div className="condo-popular-destinations__image-wrapper">
                  <img src={destination.imageUrl} alt={destination.name} />
                </div>
              </div>
          )})}
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condoPopularDestinationsStore: state.condoPopularDestinationsStore,
    condosLocationPickerStore: state.condosLocationPickerStore,
    condosStore: state.condosStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getCondoPopularDestinations,
  setIsFocusDatePicker: condosActions.setIsFocusDatePicker,
  selectLocationLabel: condosLocationPickerActions.selectLocationLabel,
  selectLocation: condosLocationPickerActions.selectLocation,
};

export const CondoPopularDestinations = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CondoPopularDestinationsComponent);
