
import { connect } from 'react-redux';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ICondoReviewBookState } from '@store/slices';
import { ICondoDetailsState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { BookingErrorsEnum } from '@share/common-types';
import { ModalAvailabilityStatus, Type } from '@components';

import './style.scss';

interface IMapStateToProps {
  condoDetailsStore: ICondoDetailsState;
  condoReviewBookStore: ICondoReviewBookState;
}

interface IProps extends WrappedComponentProps, IMapStateToProps, RouteComponentProps {
  isGetaways?: boolean;
}

function CondoAvailabilityStatusModalComponent(props: IProps) {
  const { isGetaways, condoDetailsStore, condoReviewBookStore } = props;
  const { error } = condoDetailsStore;
  const { bookingErrorCode, condoBookingSummary } = condoReviewBookStore;
  
  const hasSoldout = BookingErrorsEnum.SoldOut === error || BookingErrorsEnum.SoldOut === bookingErrorCode;
  const isUnavailable = BookingErrorsEnum.RoomsUnavailable === error || BookingErrorsEnum.RoomsUnavailable === bookingErrorCode;;

  return (
    <ModalAvailabilityStatus
      hasSoldout={hasSoldout}
      isUnavailable={isUnavailable}
      type={isGetaways ? Type.GETAWAYS : Type.CONDOS}
      propertyId={condoBookingSummary?.bookingCard?.condoId}
      bookingErrorCode={bookingErrorCode}
    />
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condoDetailsStore: state.condoDetailsStore,
    condoReviewBookStore: state.condoReviewBookStore,
  };
};

export const CondoAvailabilityStatusModal = connect(mapStateToProps)(injectIntl(withRouter(CondoAvailabilityStatusModalComponent)));
