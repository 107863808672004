import React, { Component } from 'react';

import { MasterClassesRegister } from '@components';

import './style.scss';

export class MasterClassesRegisterPage extends Component {
  render(): React.ReactNode {
    return (
      <div className="master-classes-register-page">
        <MasterClassesRegister />
      </div>
    );
  }
}
