import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CarsErrorTypes } from './cars-dates';

export interface ICarsDriverState {
  driverAgeSelected: string;
  driverAge: string;
  driverCountrySelected: string;
  driverCountry: string;

  ages: string[];
  error: string;
  errorType: CarsErrorTypes;
}

const initialState: ICarsDriverState = {
  driverAgeSelected: '25',
  driverAge: undefined,
  driverCountrySelected: 'US',
  driverCountry: undefined,

  ages: [...Array.from({length: 85}, (_, i) => i + 15).map(a => a.toString())],

  error: '',
  errorType: undefined,
};

const carsDriverSlice = createSlice({
  name: 'carsDriver',
  initialState,
  reducers: {
    setDriverAgeSelected: (state: ICarsDriverState, { payload }: PayloadAction<string>) => {
      state.driverAgeSelected = payload;
    },
    setDriverAge: (state: ICarsDriverState, { payload }: PayloadAction<string>) => {
      state.driverAge = payload;
    },
    setDriverCountrySelected: (state: ICarsDriverState, { payload }: PayloadAction<string>) => {
      state.driverCountrySelected = payload;
    },
    setDriverCountry: (state: ICarsDriverState, { payload }: PayloadAction<string>) => {
      state.driverCountry = payload;
    },
    setError: (state: ICarsDriverState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setErrorType: (state: ICarsDriverState, { payload }: PayloadAction<CarsErrorTypes>) => {
      state.errorType = payload;
    },

    resetCarsDriver: (state: ICarsDriverState) => {
      state.driverCountry = undefined;
      state.driverCountrySelected = 'US';
      state.driverAge = undefined;
      state.driverAgeSelected = '25';

      state.error = '';
      state.errorType = undefined;
    },
    applyCarsDriver: (state: ICarsDriverState) => {
      state.driverAge = state.driverAgeSelected;
      state.driverCountry = state.driverCountrySelected;
    },
  },
});

export const carsDriverActions = carsDriverSlice.actions;

export const carsDriverReducer = carsDriverSlice.reducer;
