
import { useMemo } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Header } from '@components';
import { isCondoWeeksFromPath, isCondoWorldFromPath, isThreeDSFromPath, isVacationRentalsFromPath, Responsive } from '@share/utils';
import { isErrorFromPath, isWidgetFromPath } from '@share/utils';

interface IProps extends RouteComponentProps {}

function ApplicationHeaderComponent(props: IProps) {
  const { history } = props;

  const isThreeDS = useMemo(() => isThreeDSFromPath(history), [history]);
  const isWidgetPage = useMemo(() => isWidgetFromPath(history), [history]);
  const isErrorPage = useMemo(() => isErrorFromPath(history), [history]);
  const isVacationRentals = useMemo(() => isVacationRentalsFromPath(history), [history]);
  const isCondoWorldMap = useMemo(() => isCondoWorldFromPath(history), [history]);
  const isCondoWeeks = useMemo(() => isCondoWeeksFromPath(history), [history]);

  if (isWidgetPage || isCondoWorldMap || isThreeDS || isCondoWeeks) {
    return null;
  }

  return (
    <Responsive>
      <Header isError={isErrorPage} isVacationRentals={isVacationRentals} />
    </Responsive>
  );
}

const ApplicationComponentWithRouter = withRouter(ApplicationHeaderComponent);

export const ApplicationHeader = connect()(ApplicationComponentWithRouter);
