import React from 'react';

import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

import { ICarsDetailsState, ICarsReviewBookState } from '@store/slices';
import { RootState } from '@share/utils';

import { CarsInfo } from '../cars-info';
import { CarsLocations } from '../cars-location';
import { CarsPolicies } from '../cars-policies';
import { CarsExtras } from '../cars-extras';

import { CarsPayment } from '../cars-payment';
import { CarsDisclaimers } from '../cars-disclaimers';
import { FormInstance } from 'antd/lib/form';
import { DriverInformation } from '../cars-driver-information';
import { CarsConfirmationEmail } from '../cars-confirmation-email';

import { CarsDetailsAnchors } from '../cars-details-anchors';

import './style.scss';

interface IMapStateToProps {
  carsDetailsStore: ICarsDetailsState;
  carsReviewBookStore: ICarsReviewBookState;
}

interface IProps extends IMapStateToProps, RouteComponentProps {
  dealId: number;
  backHomeType: string;

  isCheckout: boolean;

  overviewRef: React.RefObject<HTMLDivElement>;
  paymentRef: React.RefObject<HTMLDivElement>;
  driverRef: React.RefObject<HTMLDivElement>;
  emailConfirmationRef: React.RefObject<HTMLDivElement>;
  reserveRef: React.RefObject<HTMLDivElement>;
  locationRef: React.RefObject<HTMLDivElement>;
  policiesRef: React.RefObject<HTMLDivElement>;
  extrasRef: React.RefObject<HTMLDivElement>;

  paymentFormRef: React.RefObject<FormInstance>;
  driverFormRef: React.RefObject<FormInstance>;
  emailFormRef: React.RefObject<FormInstance>;
}

class CarsDetailsComponent extends React.Component<IProps> {
  
  render(): React.ReactNode {
    const { carsDetailsStore, carsReviewBookStore, isCheckout } = this.props;
    const { car } = carsDetailsStore;
    const { bookingComplete } = carsReviewBookStore;
        
    if (bookingComplete) {
      return null;
    }

    return (
      <>
        <CarsDetailsAnchors
          overviewRef={this.props.overviewRef}
          paymentRef={this.props.paymentRef}
          driverRef={this.props.driverRef}
          emailConfirmationRef={this.props.emailConfirmationRef}
          reserveRef={this.props.reserveRef}
          locationRef={this.props.locationRef}
          policiesRef={this.props.policiesRef}
          extrasRef={this.props.extrasRef}
          isCheckout={isCheckout}
        />

        <div ref={this.props.overviewRef} />
        <CarsInfo car={car} />

        {isCheckout ? (
          <>
            <div ref={this.props.driverRef} />
            <DriverInformation formRef={this.props.driverFormRef} car={car} />

            <div ref={this.props.paymentRef} />
            <CarsPayment formRef={this.props.paymentFormRef} car={car} />

            <div ref={this.props.emailConfirmationRef} />
            <CarsConfirmationEmail formRef={this.props.emailFormRef} />

            <div ref={this.props.reserveRef} />
            <CarsDisclaimers driverFormRef={this.props.driverFormRef} paymentFormRef={this.props.paymentFormRef} emailFormRef={this.props.emailFormRef} />
          </>) : (
          <>
            <div ref={this.props.locationRef} />
            <CarsLocations car={car} />

            <div ref={this.props.policiesRef} />
            <CarsPolicies car={car} />

            <CarsExtras display={!isCheckout} extrasRef={this.props.extrasRef} />
          </>)}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    carsDetailsStore: state.carsDetailsStore,
    carsReviewBookStore: state.carsReviewBookStore,
  };
};

export const CarsDetails = connect(mapStateToProps)(withRouter(CarsDetailsComponent));

