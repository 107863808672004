import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUser } from '@share/common-types';
import { Urls } from '@share/constants';
import { CreateUserWebsite, GetUserById } from '@share/services';
import { setLoginUser } from '@share/store/slices';
import { getHeaders, axiosInstance, Toaster, AppThunk } from '@share/utils';

export enum AccountUserSaveStatus {
  Processing = 'Processing',
  Error = 'Error',
  Completed = 'Completed',
  Nulo = 'null',
}

export interface IAccountUserSaveState {
  loading: boolean;
  isWebSiteCreated: boolean;
  webSite: string;
  error: string;
  status: AccountUserSaveStatus;
}

const initialState: IAccountUserSaveState = {
  loading: false,
  isWebSiteCreated: false,
  status: AccountUserSaveStatus.Nulo,
  webSite: '',
  error: '',
};

const accountUserSaveSlice = createSlice({
  name: 'userSave',
  initialState,
  reducers: {
    setAccountUserSaveLoading: (
      state: IAccountUserSaveState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.loading = payload;
    },
    setAccountUserSaveStatus: (
      state: IAccountUserSaveState,
      { payload }: PayloadAction<AccountUserSaveStatus>,
    ) => {
      state.status = payload;
    },
    setIsWebSiteCreated: (state: IAccountUserSaveState, { payload }: PayloadAction<boolean>) => {
      state.isWebSiteCreated = payload;
    },
    setWebSite: (state: IAccountUserSaveState, { payload }: PayloadAction<string>) => {
      state.webSite = payload;
    },
    setError: (state: IAccountUserSaveState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
  },
});

export const {
  setAccountUserSaveLoading,
  setIsWebSiteCreated,
  setWebSite,
  setError,
  setAccountUserSaveStatus,
} = accountUserSaveSlice.actions;

export const accountUserSaveReducer = accountUserSaveSlice.reducer;

export const accountUserSaveSelector = (state: {
  accountUserSaveStore: IAccountUserSaveState;
}): IAccountUserSaveState => {
  return state.accountUserSaveStore;
};

const getUserByCriteria = async (user: IUser, criteria: any) => {
  return await axiosInstance.post(`${Urls.Users}/search`, criteria, {
    ...getHeaders(),
  });
};

const checkUserExists = async (userToSave: IUser, user: IUser) => {
  const fieldsToSearch = ['email', 'username'];
  const duplicatedFields: string[] = [];
  for (let i = 0; i < fieldsToSearch.length; i++) {
    const field = fieldsToSearch[i];
    const key = field as keyof IUser;
    const criteria = {
      page: 1,
      pageSize: 1000,
      keyword: userToSave[key],
      KeywordField: field,
      KeywordSearchType: 'equal',
    };
    const resSearchByName = await getUserByCriteria(user, criteria);
    const { users } = resSearchByName.data;
    const usersFiltered: string[] = (users as IUser[])
      .map((u) => u.userId)
      .filter((u) => u !== userToSave.userId);
    if (usersFiltered.length > 0) {
      duplicatedFields.push(field);
    }
  }
  return duplicatedFields;
};

export const saveAccountUser = (userToSave: IUser): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setAccountUserSaveLoading(true));
    dispatch(setAccountUserSaveStatus(AccountUserSaveStatus.Processing));

    const { loginStore } = getState();
    const { user } = loginStore;

    const duplicatedFields = await checkUserExists(userToSave, user);
    if (duplicatedFields.length > 0) {
      dispatch(setError('Error Saving Account User'));
      dispatch(setAccountUserSaveStatus(AccountUserSaveStatus.Error));
      const message =
        duplicatedFields.length > 1
          ? duplicatedFields.slice(0, -1).join(', ') + ' and ' + duplicatedFields.slice(-1)
          : duplicatedFields.join(', ');
      dispatch(setAccountUserSaveLoading(false));
      Toaster.error(`There is a User with same ${message}`);
      return;
    }

    try {
      const res = await axiosInstance.post(Urls.Users, userToSave, {
        ...getHeaders(),
      });

      dispatch(setAccountUserSaveLoading(false));
      if (res?.status !== 200) {
        dispatch(setError('Error Saving Account User'));
        dispatch(setAccountUserSaveStatus(AccountUserSaveStatus.Error));
        Toaster.error(
          `Error occured: Error Saving Account User. If the problem persist, please contact system administrator`,
        );
      } else {
        dispatch(setAccountUserSaveStatus(AccountUserSaveStatus.Completed));
        const action = userToSave?.userId ? 'Updated' : 'Created';
        Toaster.success(`User ${action} successfully!`);
      }
    } catch (error: any) {
      console.error(error);
      dispatch(setError(error.toString()));
      dispatch(setAccountUserSaveLoading(false));
      dispatch(setAccountUserSaveStatus(AccountUserSaveStatus.Error));
      Toaster.error(
        `Error occured: ${error.toString()}. If the problem persist, please contact system administrator`,
      );
    }
  };
};

export const createWebsite = (name: string): AppThunk => {
  return async (dispatch, getState) => {
    const { loginStore } = getState();
    const { user } = loginStore;

    try {
      dispatch(setAccountUserSaveLoading(true));

      const webSiteAccount = await CreateUserWebsite(name);

      const response = await GetUserById(user?.userId);
      dispatch(setLoginUser(response.data));

      dispatch(setIsWebSiteCreated(webSiteAccount?.data?.isValid));
      dispatch(setWebSite(name));
    } catch (e: any) {
      console.error(e);
      dispatch(setError(e?.response?.data));
    } finally {
      dispatch(setAccountUserSaveLoading(false));
    }
  };
};
