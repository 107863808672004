import React from 'react';

export const CheckMarkSvg: React.FunctionComponent = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.91663 7.00016L5.83329 9.91683L11.6666 4.0835"
      stroke="#292929"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
