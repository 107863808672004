import React from 'react';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { IDatesState, ILocationsState, ILoginState, IRoomsState } from '@share/store/slices';
import { formatDateCheckInOut, RootState } from '@share/utils';
import { DATE_FORMAT } from '@share/constants';
import { guestsCount } from '@share/utils';

import { SearchSvg } from '@share/assets';

import './style.scss';

interface IMapStateToProps {
  datesStore: IDatesState;
  roomsStore: IRoomsState;
  loginStore: ILoginState;
  locationsStore: ILocationsState;
}

interface IProps extends IMapStateToProps {
  onToggle: (e: React.MouseEvent<HTMLParagraphElement>) => void;
}

class SearchInfoComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { locationsStore, datesStore, roomsStore, loginStore } = this.props;
    const { rooms } = roomsStore;
    const { account } = loginStore;
    const locale = account?.locale;

    return datesStore.startDate && datesStore.endDate ? (
      <div className="search-info" onClick={this.props.onToggle}>
        <SearchSvg />
        <div className="search-info__info">
          <p className="search-info__locations">{locationsStore.selectedLocationLabel}</p>
          <p className="search-info__description">
            {formatDateCheckInOut(datesStore.startDate, DATE_FORMAT, locale)} -{' '}
            {formatDateCheckInOut(datesStore.endDate, DATE_FORMAT, locale)}, {guestsCount(rooms)}{' '}
            <FormattedMessage id="guests" />
          </p>
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    datesStore: state.datesStore,
    roomsStore: state.roomsStore,
    loginStore: state.loginStore,
    locationsStore: state.locationsStore,
  };
};

export const SearchInfo = connect(mapStateToProps)(SearchInfoComponent);
