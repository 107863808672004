import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, Form, Input, Alert } from 'antd';

import { RootState } from '@share/utils';
import { IPasswordState, sendVerifyEmail } from '@share/store/slices';
import { getAccountUsernameFromPath } from '@share/utils';

import './style.scss';

interface IMapStateToProps {
  passwordStore: IPasswordState;
}

interface IMapDispatchToProps {
  sendVerifyEmail: (email: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps { }

function SendVerificationComponent(props: IProps) {
  const [accountName, setAccountName] = useState(null);
  const { isSuccess, message } = props.passwordStore;

  useEffect(() => {
    const accountName = getAccountUsernameFromPath(props.history);
    setAccountName(accountName);
  }, [])

  const onFinish = (values: any) => {
    props.sendVerifyEmail(values.email);
  };

  return (
    <div className="password-page">
      <div className="login-box col-sm-5 col-xs-12">
        <Form
          name="normal_password"
          className="password-form"
          layout="vertical"
          onFinish={onFinish}
        >
          <p className="form-title"><FormattedMessage id="send.verification.email" /></p>

          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              { type: 'email', message: <FormattedMessage id="the.input.is.not.valid.email" /> },
              { required: true, message: <FormattedMessage id="please.input.your.email" /> },
            ]}
          >
            <Input />
          </Form.Item>

          {message ?
            <Form.Item>
              {isSuccess
                ? <Alert message="" description={message} type="success" showIcon />
                : <Alert message="" description={message} type="error" showIcon />
              }
            </Form.Item> : null}

          <Form.Item>
            <Button type="primary" htmlType="submit" className="password-form-button">
              <FormattedMessage id="send.email" />
            </Button>
          </Form.Item>
          <Button className="password-form-button cancel" href={`${accountName}/signin`}><FormattedMessage id="back.to.login" /></Button>
        </Form>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    passwordStore: state.passwordStore
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  sendVerifyEmail: (email: string) => {
    dispatch(sendVerifyEmail(email));
  }
});

const SendVerificationComponentRouter = withRouter(SendVerificationComponent);

export const SendVerification = connect(mapStateToProps, mapDispatchToProps)(SendVerificationComponentRouter);
