import React from 'react';

import { FormattedMessage, } from 'react-intl';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, Form, Input, Alert } from 'antd';

import { GetHomeParams, RootState, getAccountUsernameFromPath } from '@share/utils';
import { ILoginState, signUp } from '@share/store/slices';
import { Routes } from '@share/constants';
import { NULL_VALUE } from '@constants';

import './style.scss';
import { IAccount } from '@share/common-types';

const DOMAIN_REGEX = /^([a-z0-9._-]+)$/;
const NAME_REGEX = /^([a-zA-Z ]+)$/;

interface IState {
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  password: string;
  passwordConfirm: string;
  accountName: string;
}

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  signUp: (username: string, firstname: string, lastname: string, email: string, password: string, bookingId: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps { }

class SignUpComponent extends React.Component<IProps, IState> {

  state: IState = { accountName: NULL_VALUE, username: NULL_VALUE, firstname: NULL_VALUE, lastname: NULL_VALUE, email: NULL_VALUE, password: NULL_VALUE, passwordConfirm: NULL_VALUE };

  componentDidMount() {
    const accountName = getAccountUsernameFromPath(this.props.history);
    this.setState({ accountName });
  }

  handleUsernameChange = (event: any) => {
    const username = event.target.value;
    this.setState({ username });
  }

  handleFirstnameChange = (event: any) => {
    const firstname = event.target.value;
    this.setState({ firstname });
  }

  handleLastnameChange = (event: any) => {
    const lastname = event.target.value;
    this.setState({ lastname });
  }

  handleEmailChange = (event: any) => {
    const email = event.target.value;
    this.setState({ email });
  }

  handlePasswordChange = (event: any) => {
    const password = event.target.value;
    this.setState({ password });
  }

  handlePasswordConfirmChange = (event: any) => {
    const passwordConfirm = event.target.value;
    this.setState({ passwordConfirm });
  }

  handleLogin = () => {
    const { username, firstname, lastname, email, password, passwordConfirm } = this.state;
    if (!isEmpty(username) && !isEmpty(firstname) && !isEmpty(lastname) && !isEmpty(email) && !isEmpty(password) && !isEmpty(passwordConfirm) && password === passwordConfirm) {
      const params: any = this.props.match.params;
      this.props.signUp(username, firstname, lastname, email, password, params?.bookingID);
    }
  }

  onFinish = () => {
    this.handleLogin();
  };

  render(): React.ReactNode {
    const { loginStore } = this.props;
    const { account } = loginStore;
    const hasClientCash = account?.hasClientCash;
    
    const homeParams = GetHomeParams(account as IAccount);

    return (
      <div className="login-page row">
        <div className="login-box col-sm-5 col-xs-12">
          <Form
            name="login-form"
            className="login-form"
            layout="vertical"
            onFinish={this.onFinish}
          >
            <p className="form-title"><FormattedMessage id="sign.up" /></p>

            <Form.Item
              name="userName"
              label="Username"
              rules={[
                {
                  pattern: new RegExp(DOMAIN_REGEX),
                  message: <FormattedMessage id="the.input.is.not.valid.username" />
                },
                { required: true, message: <FormattedMessage id="please.input.your.username" /> },
              ]}
            >
              <Input onChange={this.handleUsernameChange} />
            </Form.Item>

            <Form.Item
              name="firstName"
              label="Firstname"
              className="login-page__first-name"
              rules={[
                {
                  pattern: new RegExp(NAME_REGEX),
                  message: <FormattedMessage id="the.input.is.not.valid.firstname" />
                },
                { required: true, message: <FormattedMessage id="please.input.your.firstname" /> },
              ]}
            >
              <Input onChange={this.handleFirstnameChange} />
            </Form.Item>

            <Form.Item
              name="lastName"
              label="Lastname"
              className="login-page__last-name"
              rules={[
                {
                  pattern: new RegExp(NAME_REGEX),
                  message: <FormattedMessage id="the.input.is.not.valid.lastname" />
                },
                { required: true, message: <FormattedMessage id="please.input.your.lastname" /> },
              ]}
            >
              <Input onChange={this.handleLastnameChange} />
            </Form.Item>
            
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                { type: 'email', message: <FormattedMessage id="the.input.is.not.valid.email" /> },
                { required: true, message: <FormattedMessage id="please.input.your.email" /> },
              ]}
            >
              <Input onChange={this.handleEmailChange} />
            </Form.Item>

            <Form.Item
              name="password"
              label="Password"
              rules={[
                { 
                  required: !isEmpty(this.state.passwordConfirm),
                  validator: (_, value) => {
                    if (isEmpty(value) || isEmpty(this.state.passwordConfirm) || value === this.state.passwordConfirm) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Password and Password Confirm are not equal!');
                  }
                },
                { required: true, message: <FormattedMessage id="please.input.your.password" /> }
              ]}
            >
              <Input.Password onChange={this.handlePasswordChange} />
            </Form.Item>
            <Form.Item
              name="passwordConfirm"
              label="Confirm Password"
              rules={[
                { 
                  required: !isEmpty(this.state.password),
                  validator: (_, value) => {
                    if (isEmpty(value) || isEmpty(this.state.password) || value === this.state.password) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Password and Password Confirm are not equal!');
                  }
                },
                { required: true, message: <FormattedMessage id="please.input.your.password" /> }
              ]}
            >
              <Input.Password onChange={this.handlePasswordConfirmChange} />
            </Form.Item>

            {this.props.loginStore.error ?
              <Form.Item>
                <Alert message="" description={this.props.loginStore.error} type="error" showIcon />
              </Form.Item>: null}

            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button" style={{ marginTop: '20px' }}>
                <FormattedMessage id="sign.up" />
              </Button>
            </Form.Item>

            {hasClientCash && (
              <Form.Item>
                <a className="login-form-forgot" href={`/${this.state.accountName}${Routes.Search}${homeParams}`}><FormattedMessage id="confirmation.go.back_home" /></a>
              </Form.Item>)}

            <Form.Item>
              <FormattedMessage id="already.have.account" /> <a className="login-form-forgot" href={`${this.state.accountName}${Routes.Login}`}><FormattedMessage id="login" /></a>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  signUp,
};

const SignUpComponentRouter = withRouter(SignUpComponent);

export const SignUp = connect(mapStateToProps, mapDispatchToProps)(SignUpComponentRouter);
