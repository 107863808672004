import React from 'react';
import { FormattedMessage } from 'react-intl';

import { IHotelDetails } from '@common-types';
import { ICondoAmenitiesFacilities } from '@share/common-types';

import { CheckSvg } from '@assets';

import './style.scss';

interface IProps {
  hotelDetail: IHotelDetails;
  refAnchor: React.RefObject<HTMLDivElement>;
}

interface IState {
  isFull: boolean;
}

const MAX_ACTIVITIES_COUNT = 9;

export class GeneralActivities extends React.Component<IProps, IState> {
  state: IState = {
    isFull: false,
  };

  toggleAmenities = (): void => {
    this.setState(({ isFull }) => ({
      isFull: !isFull,
    }));
  };

  getDistance = (activity: ICondoAmenitiesFacilities): React.ReactNode => {
    return (
      (activity.distanceInKms || activity.distanceInMiles) && (
        <p className="condo-activities__distance">
          ({activity.distanceInKms ? (<FormattedMessage id="distance.km" values={{ distance: activity.distanceInKms }} />) : null}
          {activity.distanceInKms && activity.distanceInMiles && '/'}
          {activity.distanceInMiles ? (<FormattedMessage id="distance.miles" values={{ distance: activity.distanceInMiles }} />) : null})
        </p>
      )
    );
  };

  render(): React.ReactNode {
    const { hotelDetail, refAnchor } = this.props;
    const { activities } = hotelDetail;
    const { isFull } = this.state;

    const activityItemsToShow: ICondoAmenitiesFacilities[] = isFull
      ? activities
      : activities.slice(0, MAX_ACTIVITIES_COUNT);

    return (activityItemsToShow.length
      ?
      <>
        <div className="activities pb-0" ref={refAnchor}>
          <div className="rooms-search__list-text secondary-font vacation mb-0">
            <div className="rooms-search__header secondary-font" style={{ fontSize: '24px' }}><FormattedMessage id="tab.activities" /></div>
          </div>
        </div>
        <div className="general-activities" ref={refAnchor}>
          <div className="general-activities__items">
            {activityItemsToShow.map((activity, index) => {
              return (
                <div className="general-activities__item" key={index}>
                  <span className="general-activities__text">
                    <span style={{ verticalAlign: 'middle', marginRight: '8px' }}><CheckSvg /></span>
                    {activity.name}
                  </span>
                  <div className="general-activities__proximity">
                    {activity.proximity ? (<FormattedMessage id={`proximity.${activity.proximity}.title`} />) : null}
                    {this.getDistance(activity)}
                  </div>
                </div>
              );
            })}
          </div>
          {activities.length > MAX_ACTIVITIES_COUNT && (
            <div className="general-activities__show-link" onClick={this.toggleAmenities}>
              {isFull
                ? <FormattedMessage id="show.less" />
                : <FormattedMessage id="show.more" values={{ count: activities.length }} />
              }
            </div>
          )}
        </div>
      </>
      : null
    );
  }
}

