import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import { Menu } from 'antd';

import './style.scss';

export interface DashboardMenuItem {
  title: string;
  key: string;

  render: () => JSX.Element;
}

interface IProps {
  selectedOption: string;
  menu: DashboardMenuItem[];

  setSelectedOption: (option: string) => void;
}

function DashboardItemComponent(props: IProps) {
  const { selectedOption, menu, setSelectedOption } = props;

  const moreOptions = useMemo(() => menu?.length > 1, [menu]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
      {moreOptions && (
        <div style={{ width: '200px'}}>
          <Menu
            selectedKeys={[selectedOption]}
            mode="inline"
          >
            {menu.map(m => (
              <Menu.Item key={m.key} className="header__item-menu" onClick={() => setSelectedOption(m.key)}>
                <div style={{ color: selectedOption === m.key ? '#1890ff' : 'black' }}>{m.title}</div>
              </Menu.Item>))}
          </Menu>
        </div>)}

      <div className="dashboard-item__container" style={{ width: (moreOptions ? 'calc(100% - 200px)' : '100%'), paddingTop: '15px', paddingBottom: '15px' }}>
        {menu.filter(m => selectedOption === m.key).map(m => <React.Fragment key={m.key}>{m.render()}</React.Fragment>)}
      </div>
    </div>
  );
}

export const DashboardItem = connect()(DashboardItemComponent);
