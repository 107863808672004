
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { TravelItems } from '@constants';
import { TravelItemLinkIcon } from '@assets';
import { ILoginState } from '@share/store/slices';
import { RootState } from '@share/utils';

import './style.scss';

const ZERO = 0;

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {}

function ResourcesTravelItemsComponent(props: IProps) {
  const { loginStore } = props;
  const { account } = loginStore;

  let styles: any = account?.mainBorderColor ? { borderColor: account?.mainBorderColor } : {};
  styles = account?.mainBorderRadius ? { ...styles, borderRadius: `${account?.mainBorderRadius}px` } : { ...styles };
  
  const mainHideLinkIcon = account?.mainHideLinkIcon;

  return (
    <div className="travel-items-wrapper">
      <div className="travel-items-wrapper__wrapper">
        <h2 className="travel-items-wrapper__heading secondary-font">
          <FormattedMessage id="resources.travel.items.heading" />
        </h2>
        <div className="travel-items-wrapper__items">
          {TravelItems.map(({ id, src, alt, heading, text, textBold, link }, i) => (
            <a
              key={id}
              className="travel-items-wrapper__item"
              href={link}
              target="__blank"
              rel="noreferrer"
              style={styles}
            >
              <img
                className={`travel-items-wrapper__image${i === ZERO ? ' position-right' : ''}`}
                src={src}
                alt={alt}
              />
              <div className="travel-items-wrapper__text-wrapper">
                <h3 className="travel-items-wrapper__subheading">
                  <FormattedMessage id={heading} />{' '}
                </h3>
                <p className="travel-items-wrapper__paragraph">
                  <FormattedMessage id={text} />
                  {textBold ? (
                    <div className="travel-items-wrapper__paragraph-bold">
                      <FormattedMessage id={textBold} />
                    </div>
                  ) : null}
                </p>
              </div>

              {!mainHideLinkIcon && (
                <i className="travel-items-wrapper__icon">
                  <TravelItemLinkIcon />
                </i>)}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

export const ResourcesTravelItems = connect(mapStateToProps)(ResourcesTravelItemsComponent);
