import React, { Component } from 'react';

import { Webinars } from '@components';

export class WebinarsPage extends Component {
  render(): React.ReactNode {
    return (
      <div className="webinars-page">
        <Webinars />
      </div>
    );
  }
}
