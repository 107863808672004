import React from 'react';
import { Skeleton } from 'antd';
import { FormattedMessage } from 'react-intl';

import './style.scss';

interface IProps {}

const NUMBER = 1;
const NUMBER_OF_BLOCKS = [NUMBER, NUMBER, NUMBER];
const ONE_ROW = 1;
const TWO_ROWS = 2;
const THREE_ROWS = 3;


export class CondoSkeletonResult extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <div className="condo-skeleton">
        <div className="condo-skeleton__wrapper">
          <div className="condo-skeleton__filters">
            <Skeleton
              className="condo-skeleton__filter"
              active
              title={false}
              paragraph={{ rows: ONE_ROW }}
            />
            <Skeleton
              className="condo-skeleton__filter"
              active
              title={false}
              paragraph={{ rows: ONE_ROW }}
            />
            <Skeleton
              className="condo-skeleton__filter"
              active
              title={false}
              paragraph={{ rows: ONE_ROW }}
            />

            <Skeleton
              className="condo-skeleton__info-block"
              active
              title={false}
              paragraph={{ rows: ONE_ROW }}
            />
          </div>
          <div className="condo-skeleton__info">
            <p className="condo-skeleton__info-text">
              <FormattedMessage id="searching.for.condos" />
            </p>
          </div>
          <div className="condo-skeleton__items">
            {NUMBER_OF_BLOCKS.map((item, index) => (
              <div key={`${index}-${item}`} className="condo-skeleton__item">
                <Skeleton
                  className="condo-skeleton__item-img"
                  active
                  title={false}
                  paragraph={{ rows: ONE_ROW }}
                />
                <div className="condo-skeleton__item-blocks">
                  <Skeleton
                    className="condo-skeleton__item-block-top"
                    active
                    title={false}
                    paragraph={{ rows: ONE_ROW }}
                  />
                  <Skeleton
                    className="condo-skeleton__item-block-middle"
                    active
                    title={false}
                    paragraph={{ rows: THREE_ROWS }}
                  />
                  <div className="condo-skeleton__item-block-bottom">
                    <Skeleton
                      className="condo-skeleton__item-block-bottom-left"
                      active
                      title={false}
                      paragraph={{ rows: TWO_ROWS }}
                    />
                    <Skeleton
                      className="condo-skeleton__item-block-bottom-right"
                      active
                      title={false}
                      paragraph={{ rows: ONE_ROW }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
