
import axios, { Canceler } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CondoLocationsEnum, ICondoLocation } from '@share/common-types';
import { Urls } from '@share/constants';
import { AppThunk } from '@share/utils';
import { getHeaders, axiosInstance } from '@share/utils';

export interface ICondosLocationPickerState {
  locations: ICondoLocation[];
  loading: boolean;
  error: string;
  selectedLocationLabel: string;
  selectedLocation: ICondoLocation;
  shouldFocus: boolean;
}

const initialState: ICondosLocationPickerState = {
  locations: [],
  loading: false,
  error: '',
  selectedLocationLabel: '',
  selectedLocation: undefined,
  shouldFocus: false,
};

const condosLocationPickerSlice = createSlice({
  name: 'condos-location-picker',
  initialState,
  reducers: {
    setLoading: (state: ICondosLocationPickerState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setShouldFocus: (state: ICondosLocationPickerState, { payload }: PayloadAction<boolean>) => {
      state.shouldFocus = payload;
    },
    setError: (state: ICondosLocationPickerState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setLocations: (
      state: ICondosLocationPickerState,
      { payload }: PayloadAction<ICondoLocation[]>,
    ) => {
      state.locations = payload;
    },
    selectLocation: (
      state: ICondosLocationPickerState,
      { payload }: PayloadAction<ICondoLocation>,
    ) => {
      state.selectedLocation = payload;
    },
    selectLocationExperiences: (
      state: ICondosLocationPickerState,
      { payload }: PayloadAction<ICondoLocation>,
    ) => {
      state.selectedLocation = payload;
    },
    selectLocationLabel: (
      state: ICondosLocationPickerState,
      { payload }: PayloadAction<string>,
    ) => {
      state.selectedLocationLabel = payload;
    },
    selectLocationLabelExperiences: (
      state: ICondosLocationPickerState,
      { payload }: PayloadAction<string>,
    ) => {
      state.selectedLocationLabel = payload;
    },
    resetLocations: () => {
      return initialState;
    },
  },
});

export const condosLocationPickerActions = condosLocationPickerSlice.actions;

export const condosLocationPickerReducer = condosLocationPickerSlice.reducer;

let cancelRequest: Canceler;

export const getCondoLocations = (
  search: string,
  selectedLocation: ICondoLocation | undefined,
  onlyCities: boolean
): AppThunk => {
  return async (dispatch) => {
    dispatch(condosLocationPickerActions.setLoading(true));

    try {
      if (cancelRequest) {
        cancelRequest();
      }

      const res = await axiosInstance.get(Urls.CondosLocations, {
        ...getHeaders(),
        params: {
          SearchString: search.trim(),
        },
        cancelToken: new axios.CancelToken((canceler: Canceler) => {
          cancelRequest = canceler;
        }),
      });
      let locations: ICondoLocation[] = [...res.data.locations];

      if (selectedLocation && search === selectedLocation.name) {
        locations = locations.filter(({ id }) => id !== selectedLocation.id);
        locations.unshift(selectedLocation);
      }

      if (onlyCities) {
        locations = locations.filter(({ locationType }) => locationType === CondoLocationsEnum.GenericRegion);
      }

      dispatch(condosLocationPickerActions.setLoading(false));
      dispatch(condosLocationPickerActions.setLocations(locations?.map(l => ({
        id: l.id,
        name: l.name,
        locationType: l.locationType,
        geoLocation: l.geoLocation
      } as ICondoLocation))));
    } catch (error) {
      if (!(error instanceof axios.Cancel)) {
        console.error(error);
        dispatch(condosLocationPickerActions.setLoading(false));
      }
    }
  };
};
