
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Carousel } from 'antd';
import { useMemo } from 'react';

import { RootState } from '@share/utils';
import { IBenefitsState } from '@share/store/slices';
import { SkeletonBenefits } from '@components';
import { MOBILE_MEDIA_POINT } from '@share/constants';

import { Benefit } from '../benefit/component';

import './style.scss';

const SPEED = 1000;
const SLIDES_TO_SHOW = 3;
const SLIDES_TO_SHOW_MOBILE = 1;
const SLIDES_TO_SCROLL = 1;

const propsCarouselDesktop = {
  dots: false,
  infinite: true,
  arrows: false,
  speed: SPEED,
  slidesToShow: SLIDES_TO_SHOW,
  slidesToScroll: SLIDES_TO_SCROLL,
  autoplay: true,
};

const propsCarouselMobile = {
  ...propsCarouselDesktop,
  slidesToShow: SLIDES_TO_SHOW_MOBILE,
};

interface IMapStateToProps {
  benefitsStore: IBenefitsState;
}

interface IProps extends IMapStateToProps {}

function BenefitsComponent(props: IProps) {
  const { benefitsStore } = props;
  const { benefits, loading } = benefitsStore;

  if (!benefits) {
    return null;
  }

  if (loading) {
    return <SkeletonBenefits />;
  }

  const isMobile = document.body.offsetWidth <= MOBILE_MEDIA_POINT;
  const propsCarousel = useMemo(() => isMobile ? propsCarouselMobile : propsCarouselDesktop, [isMobile]);

  return (
    <div className="benefits-rsi-upgrade">
      <div className="benefits-rsi-upgrade__wrapper">
        <div className="benefits-rsi-upgrade__info-wrapper">
          <p className="benefits-rsi-upgrade__title">
            <FormattedMessage id="member.benefits" />
          </p>
          <p className="benefits-rsi-upgrade__description">
            <FormattedMessage id="member.benefits.message" />
          </p>
        </div>
        <div className="benefits-rsi-upgrade__benefits-wrapper">
          <div className="benefits-rsi-upgrade__carrousel-container">
            <Carousel {...propsCarousel} lazyLoad="ondemand">
              {benefits.items.map((benefit) => <Benefit key={benefit.type} benefit={benefit} />)}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    benefitsStore: state.benefitsStore,
  };
};

export const Benefits = connect(mapStateToProps)(BenefitsComponent);
