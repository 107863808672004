import React from 'react';

import { withRouter, RouteComponentProps } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { ICar } from '@share/common-types';
import { BigMap } from '@components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons';

import EmptyMarker from '@assets/images/map-empty-marker.svg';

import './style.scss';
import { AIRPORT, AIRPORT_SHUTTLE, CITY } from '@constants';

interface IProps extends RouteComponentProps {
  car: ICar;
}

interface IState {
  isMapModalOpen: boolean;
}

class CarsCardLocationComponent extends React.Component<IProps, IState> {
  state: IState = {
    isMapModalOpen: false
  };

  showMap = () => {
    this.setState({ isMapModalOpen: true });
    document.body.style.overflow = 'hidden';
  };

  closeMap = () => {
    this.setState({ isMapModalOpen: false });
    document.body.style.overflow = 'initial';
  };

  render(): React.ReactNode {
    const { car } = this.props;
    const { isMapModalOpen } = this.state;
    const { pickUpLocationDetail, info, vendor } = car;
    
    const vendorImage =  info?.tpA_Extensions?.supplierLogo?.url;
    const location = pickUpLocationDetail?.whereType;
    const position = pickUpLocationDetail?.position;

    const isShuttle = location === AIRPORT_SHUTTLE;
    const isAirport = location === AIRPORT;
    const pickUpName = pickUpLocationDetail?.name;
    const pickUpContext = pickUpLocationDetail?.codeContext;

    return (
      <div className="hotel-card-wrapper__refundable cars-content-location">
        {!isEmpty(vendorImage) ? (
          <div className="cars-content-location__vendor">
            <img alt="" src={vendorImage} width="100%" />
          </div>) : null}
        <div className="cars-content-location__content">
          <div className="cars-location-title" onClick={this.showMap}>
            <FontAwesomeIcon icon={faMapLocationDot} /> {`${pickUpName}${(isShuttle && pickUpContext?.length === 3 && !pickUpName.includes(pickUpContext)) ? ` (${pickUpContext})` : ''}`}
          </div>
          {(!isEmpty(location) && [AIRPORT_SHUTTLE, AIRPORT, CITY].includes(location)) ? (
            <div className="cars-location-position">
              <FormattedMessage id={isShuttle ? 'cars.location.shuttle' : isAirport ? 'cars.location.airport' : 'cars.location.city'} />
            </div>) : null}
        </div>

        <BigMap title={vendor?.value} marker={EmptyMarker} isMapModalOpen={isMapModalOpen} location={position} onCloseMap={this.closeMap} />
      </div>
    );
  }
}

const CarsCardLocationComponentRouter = withRouter(CarsCardLocationComponent);

export const CarsCardLocation = connect()(CarsCardLocationComponentRouter);
