import React from 'react';
import ReactGA from 'react-ga4';

import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { LabeledValue, SelectValue } from 'antd/lib/select';

import { SkeletonFilters, FilterBudgetLabel } from '@components';
import { BlueButton, CustomSelect } from '@share/components';
import {
  ILoginState,
  ICarsState,
  ICarLocationsState,
  ICarsDatesState,
  ICarsFiltersState,
  carsFiltersActions,
} from '@share/store/slices';
import { CloseBlueSvg } from '@assets';
import { RootState, getSelectedCurrency } from '@share/utils';
import { IAccount, ICar } from '@share/common-types';
import { FILTERS_LABEL, getSortByCodeByValue, SortTypes } from '@share/constants';
import { getSortOptions, isCarsFiltersEmpty } from '@utils';
import { UrlUtils } from '@share/utils';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import { CarsFilterItem } from '../cars-filter-item';

import './style.scss';

interface IMapStateToProps {
  carsStore: ICarsState;
  carsLocationsStore: ICarLocationsState;
  carsDatesStore: ICarsDatesState;
  carsFiltersStore: ICarsFiltersState;

  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setSortBy: (value: string) => void;
  setAirCondition: (value: string[]) => void;
  setBudget: (value: string[]) => void;
  setCarType: (value: string[]) => void;
  setSimpleType: (value: string[]) => void;
  setCategory: (value: string[]) => void;
  setDoors: (value: string[]) => void;
  setDriveType: (value: string[]) => void;
  setFuel: (value: string[]) => void;
  setRateDistance: (value: string[]) => void;
  setRentalCompany: (value: string[]) => void;
  setSeats: (value: string[]) => void;
  setTransmissionType: (value: string[]) => void;
  setRefundable: (value: string[]) => void;
  setWhereLocation: (value: string[]) => void;

  resetFilters: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps, RouteComponentProps {
  wrapperRef: React.RefObject<HTMLDivElement>;
  showFilters: boolean;
  isMobileFilter: boolean;

  onShowMap: (center: google.maps.LatLngLiteral, selectedCar: ICar) => void;
  onMobileFilter: () => void;
  onFiltersOrSortChange: (forceReset?: boolean) => void;
}

/*
interface IState {
  isMapView: boolean;
}
*/

class CarsFiltersComponent extends React.Component<IProps/*, IState*/> {
  /*
  state: IState = {
    isMapView: false,
  };
  */

  onFilterChange = (checkedValues: string[], setter: (values: string[]) => void) => {
    setter(checkedValues);
    return !this.props.isMobileFilter && this.props.onFiltersOrSortChange();
  };

  onAirConditionChange = (checkedValues: string[]) => {
    return this.onFilterChange(checkedValues, this.props.setAirCondition);
  };
  onBudgetChange = (checkedValues: string[]) => {
    return this.onFilterChange(checkedValues, this.props.setBudget);
  };
  onCarType = (checkedValues: string[]) => {
    return this.onFilterChange(checkedValues, this.props.setCarType);
  };
  onCarSimpleType = (checkedValues: string[]) => {
    return this.onFilterChange(checkedValues, this.props.setSimpleType);
  };
  onCategoryChange = (checkedValues: string[]) => {
    return this.onFilterChange(checkedValues, this.props.setCategory);
  };
  onDoorsChange = (checkedValues: string[]) => {
    return this.onFilterChange(checkedValues, this.props.setDoors);
  };
  onDriveTypeChange = (checkedValues: string[]) => {
    return this.onFilterChange(checkedValues, this.props.setDriveType);
  };
  onFuelChange = (checkedValues: string[]) => {
    return this.onFilterChange(checkedValues, this.props.setFuel);
  };
  onRateDistanceChange = (checkedValues: string[]) => {
    return this.onFilterChange(checkedValues, this.props.setRateDistance);
  };
  onRentalCompanyChange = (checkedValues: string[]) => {
    return this.onFilterChange(checkedValues, this.props.setRentalCompany);
  };
  onSeatsChange = (checkedValues: string[]) => {
    return this.onFilterChange(checkedValues, this.props.setSeats);
  };
  onTransmissionTypeChange = (checkedValues: string[]) => {
    return this.onFilterChange(checkedValues, this.props.setTransmissionType);
  };
  onRefundableChange = (checkedValues: string[]) => {
    return this.onFilterChange(checkedValues, this.props.setRefundable);
  };
  onWhereLocationChange = (checkedValues: string[]) => {
    return this.onFilterChange(checkedValues, this.props.setWhereLocation);
  };

  getSortOptions = (): LabeledValue[] => {
    const { loginStore } = this.props;
    const { account } = loginStore;

    const hasQuick = account?.hasQuick;
    const isB2C = account?.isB2C;
    const walletWalletSavings = account?.walletWalletSavings;

    return getSortOptions(hasQuick as boolean, isB2C as boolean, walletWalletSavings as boolean);
  };

  onSortChange = (value: SelectValue) => {
    const valueStr = value as string;
    this.props.setSortBy(valueStr);

    const { loginStore } = this.props;
    const { account } = loginStore;

    const action = getSortByCodeByValue(valueStr);

    if (action) {
      ReactGA.event({
        category: account?.name as string,
        action: `${action}_${account?.name.toUpperCase()}`,
        label: `User selected ${action} sort on result`,
        nonInteraction: false,
      });
    }

    return !this.props.isMobileFilter && this.props.onFiltersOrSortChange();
  };

  onResetFilters = (_fromMap = false) => {
    UrlUtils.setUrl(FILTERS_LABEL, null);

    /*
    if (fromMap) {
      this.props.setMapLoading(fromMap);
    }
    */

    this.props.resetFilters();
    return !this.props.isMobileFilter && this.props.onFiltersOrSortChange(true);
  };

  onApply = () => {
    this.props.onMobileFilter();
    this.props.onFiltersOrSortChange();
  };

  render(): React.ReactNode {
    //const { isMapView } = this.state;
    const { showFilters, isMobileFilter, carsStore, /*carsLocationsStore,*/ loginStore, carsFiltersStore, onShowMap } = this.props;
    const {
      cars,
      loading,
      counters,
      isSessionExpired,
    } = carsStore;
    const {
      sortBy,
      airCondition,
      budget,
      carType,
      simpleType,
      category,
      doors,
      driveType,
      fuel,
      rateDistance,
      rentalCompany,
      seats,
      transmissionType,
      refundable,
      whereLocation,
    } = carsFiltersStore;
    const { account } = loginStore;
    //const { pickUp } = carsLocationsStore;
    const resultCounters = counters;

    //const location = pickUp?.location;

    const isFiltersApplied = !isCarsFiltersEmpty(carsFiltersStore);

    const hasBudgets = !!resultCounters?.budgetRanges?.filter(({ count }) => !!count).length;

    const applyFilters = [
      sortBy,
      airCondition,
      budget,
      carType,
      category,
      doors,
      driveType,
      fuel,
      rateDistance,
      rentalCompany,
      seats,
      transmissionType,
      refundable,
      whereLocation,
    ].filter(String);
    
    const currency = getSelectedCurrency(account as IAccount);
    //const background: any = account ? account?.buttonColor : null;
    //const styleColor = (cars?.length && !isEmpty(background))? { background } : {};  
    
    if (loading || !resultCounters) {
      return (
        <div className="result-wrapper__filters-wrapper">
          <SkeletonFilters />
        </div>);
    }

    if (!cars?.length) {
      return null;
    }

    return (
      <div
        className={`result-wrapper__filters-wrapper cars-main-filters-container ${isMobileFilter ? 'is-mobile' : ''} ${
          isSessionExpired ? 'disabled' : ''} ${!showFilters ? 'hite-filters' : ''}`}
        ref={this.props.wrapperRef}
      >
        {/*!isMapView && (
          <div
            className="result-wrapper__map-view-wrapper"
            onClick={() => onShowMap(Map.getGoogleLocation(location.geoLocation), null)}
          >
            <div
              className={`result-wrapper__map-view ${cars?.length ? '' : 'disabled'}`}
              style={{ backgroundImage: `url(${cars?.length ? MapImage : MapMonoImage})` }}
            >
              <div className={`result-wrapper__map-button ${cars?.length ? '' : 'disabled'}`} style={styleColor}>
                <MapSearchSvg /> <FormattedMessage id="view.on.map" />
              </div>
            </div>
          </div>)*/}

        <div className="result-wrapper__filters-header-wrapper-sticky cars-filters">
          {isFiltersApplied && !isSessionExpired && (
            <div className="result-wrapper__reset-filters-wrapper cars-reset-filters">
              <span
                className="result-wrapper__reset-filters-link"
                onClick={() => this.onResetFilters()}
              >
                <FormattedMessage id="reset.all" />
              </span>
            </div>)}

          <div className="result-wrapper__filters-header-wrapper">
            <div className="result-wrapper__filters-header-item" onClick={this.props.onMobileFilter}>
              <CloseBlueSvg />
              <p className="result-wrapper__filters-header-text">
                <FormattedMessage id="sort.filter" />
              </p>
            </div>
            {isFiltersApplied && (
              <p
                className="result-wrapper__mobile-reset-filters-link"
                onClick={() => this.onResetFilters()}
              >
                <FormattedMessage id="reset.all" />
              </p>
            )}
          </div>
          <div className={`result-wrapper__sort-select-wrapper ${isSessionExpired ? 'disabled' : ''}`}>
            <span className="result-wrapper__sort-label">
              <FormattedMessage id="sort.by" />:{' '}
            </span>
            <div className="result-wrapper__select">
              <CustomSelect
                disabled={isSessionExpired}
                value={sortBy}
                onChange={this.onSortChange}
                options={this.getSortOptions()}
              />
            </div>
          </div>
          
          <p className="result-wrapper__filters-tittle secondary-font">
            <FormattedMessage id="filter.by" />
          </p>

          <CarsFilterItem
            title="budget"
            counters={resultCounters?.budgetRanges}
            filters={budget}
            onFiltersChange={this.onBudgetChange}
            disabled={!hasBudgets}
            displayZero={!hasBudgets}

            getKeyConverter={(key) => `${key.from}-${key.to}`}
            getLabelConverter={(key) => <FilterBudgetLabel range={key} currency={currency} />}
          
            exluceNonSelected
            displayAll
          />

          <CarsFilterItem title="cars.filter.air_condition" counters={resultCounters?.airCondition} filters={airCondition} onFiltersChange={this.onAirConditionChange} />

          <CarsFilterItem
            title="cars.filter.car_type"
            counters={resultCounters?.simpleType}
            filters={simpleType}
            onFiltersChange={this.onCarSimpleType}
            orderByOptions
          />

          <CarsFilterItem
            title="cars.filter.car_type.detailed"
            counters={resultCounters?.carType}
            filters={carType}

            getKeyConverter={(key) => key.key}
            getLabelConverter={(key) => key.value}

            onFiltersChange={this.onCarType}

            orderByOptions
          />

          <CarsFilterItem title="cars.filter.category" counters={resultCounters?.category} filters={category} onFiltersChange={this.onCategoryChange} />

          <CarsFilterItem title="cars.filter.doors" counters={resultCounters?.doors} filters={doors} onFiltersChange={this.onDoorsChange} />

          <CarsFilterItem title="cars.filter.drive_type" counters={resultCounters?.driveType} filters={driveType} onFiltersChange={this.onDriveTypeChange} />

          <CarsFilterItem
            title="cars.filter.fuel"
            counters={resultCounters?.fuel}
            filters={fuel}
            onFiltersChange={this.onFuelChange}
            getLabelConverter={(key) => key === 'Petrol' ? 'Gas' : key}
          />

          <CarsFilterItem title="cars.filter.where_location" counters={resultCounters?.whereLocation} filters={whereLocation} onFiltersChange={this.onWhereLocationChange} />

          <CarsFilterItem
            title="cars.filter.rate_distance"
            counters={resultCounters?.rateDistance}
            filters={rateDistance}
            onFiltersChange={this.onRateDistanceChange}
            getLabelConverter={(key) => key === 'Non Unlimited' ? 'Limited Mileage' : key === 'Unlimited' ? 'Unlimited Mileage' : key}
          />

          <CarsFilterItem title="cars.filter.rental_company" counters={resultCounters?.rentalCompany} filters={rentalCompany} onFiltersChange={this.onRentalCompanyChange} />

          <CarsFilterItem title="cars.filter.seats" counters={resultCounters?.seats} filters={seats} onFiltersChange={this.onSeatsChange} />

          <CarsFilterItem title="cars.filter.transmission_type" counters={resultCounters?.transmissionType} filters={transmissionType} onFiltersChange={this.onTransmissionTypeChange} />

          <CarsFilterItem title="cars.filter.refundable" counters={resultCounters?.refundable} filters={refundable} onFiltersChange={this.onRefundableChange} />

          {!isSessionExpired && (
            <div className="result-wrapper__filters-apply">
              <BlueButton onClick={this.onApply} disabled={isSessionExpired}>
                <FormattedMessage id="apply" />
                <span className="result-wrapper__filters-apply-counts">
                  {applyFilters.length}
                </span>
                <FormattedMessage id="filters" />
              </BlueButton>
            </div>
          )}
        </div>
      </div>            
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    carsStore: state.carsStore,
    carsLocationsStore: state.carsLocationsStore,
    carsDatesStore: state.carsDatesStore,
    carsFiltersStore: state.carsFiltersStore,

    loginStore: state.loginStore,    
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  setSortBy: (value: string) => {
    dispatch(carsFiltersActions.setSortBy(value as SortTypes));
  },
  setAirCondition: (value: string[]) => {
    dispatch(carsFiltersActions.setAirCondition(value));
  },
  setBudget: (value: string[]) => {
    dispatch(carsFiltersActions.setBudget(value));
  },
  setCarType: (value: string[]) => {
    dispatch(carsFiltersActions.setCarType(value));
  },
  setSimpleType: (value: string[]) => {
    dispatch(carsFiltersActions.setSimpleType(value));
  },
  setCategory: (value: string[]) => {
    dispatch(carsFiltersActions.setCategory(value));
  },
  setDoors: (value: string[]) => {
    dispatch(carsFiltersActions.setDoors(value));
  },
  setDriveType: (value: string[]) => {
    dispatch(carsFiltersActions.setDriveType(value));
  },
  setFuel: (value: string[]) => {
    dispatch(carsFiltersActions.setFuel(value));
  },
  setRateDistance: (value: string[]) => {
    dispatch(carsFiltersActions.setRateDistance(value));
  },
  setRentalCompany: (value: string[]) => {
    dispatch(carsFiltersActions.setRentalCompany(value));
  },
  setSeats: (value: string[]) => {
    dispatch(carsFiltersActions.setSeats(value));
  },
  setTransmissionType: (value: string[]) => {
    dispatch(carsFiltersActions.setTransmissionType(value));
  },
  setRefundable: (value: string[]) => {
    dispatch(carsFiltersActions.setRefundable(value));
  },
  setWhereLocation: (value: string[]) => {
    dispatch(carsFiltersActions.setWhereLocation(value));
  },

  resetFilters: () => {
    dispatch(carsFiltersActions.resetFilters(false));
  },

  /*setMapLoading: (isMapLoading: boolean) => {
    dispatch(hotelsActions.setMapLoading(isMapLoading));
  },*/
});

export const CarsFilters = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(CarsFiltersComponent)));
