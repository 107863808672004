import React from 'react';
import moment from 'moment';

import { Checkbox, Tooltip } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { IFullPublicPrice, IHotelInfo, RefundableEnum } from '@common-types';
import { IAccount, IClientCash, IHotel, IUserWallet, LOGOUT_FIELD_KEY } from '@share/common-types';
import { getTopSuppliers, openHotelPage, getFullAddress, getAmenityImage, isFreeBreakfastMealPlan, isAllInclusivetMealPlan, HasPermission, ViewQuotesSharePermission, getWalletPrices, WalletPriceType, getSupplierMessage, getMlmSupplierMessage } from '@utils';
import { HotelStars, Currency, VacantionRentalPossiblestaysDisclaimer, ExpiredSessionModal, WalletMessage, CarouselImages } from '@components';
import { renderNumber, getPreferenceFromAccount, getRankingClass, RootState, getToken, isVacationRentalsFromPath, LoginType, GetDisableNewTabOpen } from '@share/utils';

import { hotelsActions, IHotelsState, ILocationsState, ILoginState, IMarginatorState, marginatorActions, IMenuState } from '@share/store/slices';
import { AVAILABILITY_SOLDOUT, R_SELECT_ROOM, USER_BASE_URL } from '@share/constants';
import { roomsSearchActions, hotelDetailsActions } from '@store/slices';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { LocationMapSvg, InfoSvg, NoImageSvg } from '@assets';

import Package from '@assets/images/package.png';
import FreeBreakfast from '@assets/images/free-breakfast.png';
import AllInclusive from '@assets/images/all-inclusive.png';
import BadgetPercent from '@assets/images/badget-percent.png';

import './style.scss';
import ReactGA from 'react-ga4';

interface IMapStateToProps {
  menuStore: IMenuState;
  hotelsStore: IHotelsState;
  loginStore: ILoginState;
  locationsStore: ILocationsState;
  marginatorStore: IMarginatorState;
}

interface IMapDispatchToProps {
  setCommission: (value: number) => void;
  setSupplier: (value: string) => void;
  setHotelDetails: (hotel: IHotelInfo) => void;
  setSelectedHotel: (hotel: IHotel) => void;
  setSelectedHotelSearchClientCash: (selectedClientCash: IClientCash) => void;
  resetState: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  hotel: IHotel;
  nights: number;
  matches?: boolean;
  isFromSearch?: boolean;
  isSimilar?: boolean;
  disabled?: boolean;
  compared?: boolean;
  displayCompare?: boolean;
  disableCompare?: boolean;
  isVacationRentals?: boolean;

  showOnMap?: () => void;
  onCompareSelect?: (hotel: IHotel) => void;
}

interface IState {
  isAllSuppliersShown: boolean;
  marginatorCommission: number;
  isSessionExpired: boolean;
}

const zero = 0;
const percents = 100;
const maxCountOfSimpleSuppliers = 3;
const pricesPaddings = 30;
const priceWidth = 164;

class HotelCardWrapper extends React.Component<IProps, IState> {
  cardRef: React.RefObject<HTMLDivElement> = React.createRef();
  state = {
    isAllSuppliersShown: false,
    isSessionExpired: false,
    marginatorCommission: 0
  };

  getMinPublicPrice = (): number => {
    const { publicPrices } = this.props.hotel;

    if (publicPrices && publicPrices.length) {
      return Math.min(...publicPrices.map(({ price }) => price));
    }

    return zero;
  };

  toggleSuppliers = () => {
    this.setState(({ isAllSuppliersShown }) => ({
      isAllSuppliersShown: !isAllSuppliersShown,
    }));
  };

  getMaxPrices = (): number => {
    if (this.cardRef && this.cardRef.current) {
      return Math.floor((this.cardRef.current.offsetWidth - pricesPaddings) / priceWidth);
    }

    return zero;
  };

  getPricesComponent = (
    sortedSuppliers: IFullPublicPrice[],
    marginatorValue: number,
    marginatorsavePercents: number,
    currency: string,
    isMobile = false,
  ): React.ReactElement => {
    const { loginStore, menuStore, marginatorStore, hotel } = this.props;
    const { user, account } = loginStore;
    const { value } = marginatorStore;
    const { items } = menuStore;
    const { price, availability } = hotel;

    const { isTravelAgentPro } = account;

    const pubPrice = this.getMinPublicPrice();
    const marginatorPrice = price / (1 - (value / 100));
    
    const isSoldout = availability === AVAILABILITY_SOLDOUT;

    if (isSoldout && !(marginatorValue > 0 && marginatorsavePercents < 0) && !(sortedSuppliers?.length && (isTravelAgentPro || pubPrice >= marginatorPrice))) {
      return null;
    }

    const expediaPublicPriceList = sortedSuppliers.filter(s => ['74', '61', '65'].includes(s.supplierId));
    let hotelsPublicPrice;
    if (expediaPublicPriceList?.length) {
      const minPrice = Math.min(...expediaPublicPriceList.map(s => s.price));
      hotelsPublicPrice = get(expediaPublicPriceList.filter(s => s.price === minPrice), '[0]');
    }
    
    return (
      <div className={`hotel-card-wrapper__suppliers ${isMobile ? 'mobile' : 'desktop'} ${pubPrice < marginatorPrice ? 'no-price' : ''}`}>
        {(sortedSuppliers?.length && (isTravelAgentPro || pubPrice >= marginatorPrice)) ? (
          <div className="hotel-card-wrapper__suppliers-list">
            {sortedSuppliers
              .slice(zero, maxCountOfSimpleSuppliers)
              .map(({ supplierId, price }) => {
                return (
                  <div className="hotel-card-wrapper__simple-supplier" key={supplierId}>
                    <div className="hotel-card-wrapper__simple-supplier-name">
                      <FormattedMessage id={items?.isMLM ? getMlmSupplierMessage(supplierId) : getSupplierMessage(supplierId)} />
                    </div>
                    <div className="hotel-card-wrapper__simple-supplier-price">
                      <Currency currency={currency} />{renderNumber(price, 2)}
                    </div>
                  </div>
                );
              })}
              {(items?.isMLM && hotelsPublicPrice?.price) ? (
                <div className="hotel-card-wrapper__simple-supplier">
                  <div className="hotel-card-wrapper__simple-supplier-name">
                    <FormattedMessage id="hotels.com" />
                  </div>
                  <div className="hotel-card-wrapper__simple-supplier-price">
                    <Currency currency={currency} />{renderNumber(hotelsPublicPrice?.price, 2)}
                  </div>
                </div>) : null}
          </div>) : null}

        {marginatorValue > 0 && marginatorsavePercents < 0  && 
          <div className="hotel-card-wrapper__label-container">
            <div className="hotel-card-wrapper__higher-internet-rate">
              <p className="hotel-card-wrapper__label-title-higher-internet-rate">
                <FontAwesomeIcon icon={faExclamationCircle} className="me-1" />
                <FormattedMessage id="higher.internet.rate" /> {Math.abs(marginatorsavePercents)}%
              </p>
            </div>
          </div>}
        
        {(this.props.displayCompare && !isSoldout) ? (
          <div className="hotel-card-wrapper__compare">
            <Checkbox
              checked={this.props.compared}
              onChange={() => this.props.onCompareSelect(this.props.hotel)}
              disabled={this.props.disableCompare}
            >
              {HasPermission(user, ViewQuotesSharePermission) ? <FormattedMessage id="results.hotel.card.compare.share" /> : <FormattedMessage id="results.hotel.card.compare" /> }
            </Checkbox>
          </div>) : null}
      </div>
    );
  };

  onHotelPage = (marginatorCommission: number, selectedPropertyClientCash: number) => {
    const { loginStore, hotel, isVacationRentals } = this.props;

    const { hotelId, availability } = hotel;
    const { account } = loginStore;

    this.props.setCommission(marginatorCommission);
    this.props.setSupplier(this.props.hotel.supplierId);

    const token = getToken(account);
    if (account?.type === LoginType.PrivateWithToken && !token) {
      this.setState({ isSessionExpired: true }); 
    } else {
      if (availability !== AVAILABILITY_SOLDOUT) {
        this.props.setHotelDetails(null);
        this.props.resetState();
        this.props.setSelectedHotel(this.props.hotel);
        
        const { hotelsStore } = this.props;
        const { selectedHotelSearchClientCash } = hotelsStore;
    
        const clientCash: any = { ...selectedHotelSearchClientCash, selectedPropertyClientCash, selectedPropertyReviewClientCash: null };
        this.props.setSelectedHotelSearchClientCash(clientCash);
    
        const disableNewTabOpen = GetDisableNewTabOpen(this.props.loginStore.account, this.props.menuStore.items);

        openHotelPage(hotelId, this.props.history, disableNewTabOpen as boolean, account?.isTravelAgentPro, isVacationRentals, account?.forceIframe);
      
        ReactGA.event({
          category: account?.name as string,
          action: `${R_SELECT_ROOM}_${account?.name.toUpperCase()}`,
          label: `User selected a room on resuls`,
          nonInteraction: false,
        });
      }  
    }
  };

  onMapPage = () => {
    if (this.props.showOnMap) {
      this.props.showOnMap();
    }
  };

  handleRedirect = () => {
    const { loginStore } = this.props;
    const { account } = loginStore;

    const logoutUrlStorage = localStorage.getItem(USER_BASE_URL);
    const logoutPref = getPreferenceFromAccount(account, LOGOUT_FIELD_KEY);
    const logoutUrl = !isEmpty(logoutUrlStorage) ? logoutUrlStorage : logoutPref;
    if (!isEmpty(logoutUrl)) {
      window.location.replace(logoutUrl);
    }
  }

  render(): React.ReactNode {
    const { isAllSuppliersShown, isSessionExpired } = this.state;
    const { hotel, nights, matches, loginStore, locationsStore, hotelsStore, menuStore, history, isFromSearch = true, disabled = false, isSimilar = false } = this.props;
    const {
      address,
      displayName,
      images,
      price,
      rating,
      pricePerNight,
      amenities,
      publicPrices,
      starRating,
      city,
      countryCode,
      zipCode,
      state,
      refundability,
      savings,
      tag,
      isPackage,
      exactSearch,
      availability,
      supplierId,
      possibleStays,
      currency,
      mealPLan,
      maxWalletClientCash
    } = hotel;

    const { account, userWalletData } = loginStore;
    const { selectedHotelSearchClientCash } = hotelsStore;
    const { items } = menuStore;

    const isTravelAgentPro= account?.isTravelAgentPro;
    const disableExclusivePricingTagVacationRentals = account?.disableExclusivePricingTagVacationRentals;
    const disableSavingsTagVacationRentals = account?.disableSavingsTagVacationRentals;

    const pubPrice = this.getMinPublicPrice();
    const sortedSuppliers = getTopSuppliers(publicPrices || []);
    const fullAddress = getFullAddress(address, city, state, zipCode, countryCode);
    const addressStr = fullAddress.join(', ');
    const recommended = (tag === 'Recommeded' || tag === 'Recommended');
    const background = account?.buttonColor;
    const minSavings = account?.savingsPercentageMin;
    const buttonBorderRadius = account?.buttonBorderRadius;
    const buttonTextColor = account?.buttonTextColor;
    
    const filterdImages = images?.length ? images : [];

    const styleButtonBackground = !isEmpty(background)? { background } : {};
    const styleButtonTextColor = !isEmpty(buttonTextColor)? { color: buttonTextColor } : {};
    const styleButton: any = !isEmpty(buttonBorderRadius)? { ...styleButtonBackground, ...styleButtonTextColor, borderRadius: `${buttonBorderRadius}px` } : { ...styleButtonBackground, ...styleButtonTextColor };
    
    const { location } = locationsStore;
    const isSoldout = availability === AVAILABILITY_SOLDOUT;

    const { value, showCommission, showSupplier } = this.props.marginatorStore;
    const marginatorPrice = price / (1 - (value / 100));
    const marginatorSavings = pubPrice - marginatorPrice;
    const marginatorpricePerNight = pricePerNight / (1 - (value / 100));
    const msave = pubPrice ? (marginatorPrice * percents) / pubPrice : 100;
    const marginatorsavePercents = Math.floor(percents - msave);
    const marginatorCommission = marginatorPrice - price;

    const isVacationRentals = isVacationRentalsFromPath(history);

    const isFreeBreakfast = isFreeBreakfastMealPlan(mealPLan);
    const isAllINclusive = isAllInclusivetMealPlan(mealPLan);
    
    const nightsCount = !possibleStays?.length ? nights : moment(possibleStays[0].checkOut).diff(moment(possibleStays[0].checkIn), 'days')

    const walletHasSliderOnSearch = account?.walletHasSliderOnSearch;
    
    const hotelPrices = getWalletPrices(account as IAccount, userWalletData as IUserWallet, selectedHotelSearchClientCash, marginatorPrice, maxWalletClientCash, WalletPriceType.Search);

    const displayWalletSavings = account?.walletWalletSavings && (hotelPrices?.priceSavings > 0 || hotelPrices?.maxClientCashAmountAllow > 0) && !!hotelPrices?.maxClientCashAmountAllow;

    const clientCashApplied = hotelPrices?.clientCashApplied;
    const marginatorPriceFinal = displayWalletSavings ? hotelPrices?.priceFinalMax : hotelPrices?.priceFinal;

    return (
      <div className={`hotel-card ${disabled ? 'disabled' : ''}`}>
        {disabled && <div className="hotel-card__disabled-wrapper" />}
        {location?.type === 'Hotel' && !isSimilar && <div className="hotel-card__hotel-selected-wrapper mt-3"><FormattedMessage id={'result.hotel.card.your_interested'} /></div>}
        <div
          className={`hotel-card-wrapper
            ${isAllSuppliersShown ? 'prices-open' : ''}
            ${exactSearch? '' : 'no-exact-search'}
            ${(location?.type === 'Hotel' && !isSimilar)? 'hotel-only' : ''}`}
            ref={this.cardRef}
        >
          <div className="hotel-card-wrapper__slider" onClick={() => this.onHotelPage(marginatorCommission, clientCashApplied)}>
            <div className='hotel-card-wrapper__container'>
              {(!isSoldout && !disabled && !!isFinite(marginatorsavePercents) && marginatorsavePercents > 0 && !displayWalletSavings && (!isVacationRentals || !disableSavingsTagVacationRentals)) ? (
                <div className="hotel-card-wrapper__label-container">
                  <div className="hotel-card-wrapper__label">
                    <p className="hotel-card-wrapper__label-title">
                      <FormattedMessage id={account?.forceMemberWording ? 'member.save' : 'save'} /> {marginatorsavePercents}%
                    </p>
                  </div>
                  <div className="hotel-card-wrapper__label-corner"></div>
                </div>) : null}

              {displayWalletSavings ? (
                <div className="hotel-card-wrapper__label-container">
                  <div className="hotel-card-wrapper__label">
                    <p className="hotel-card-wrapper__label-title">
                      <FormattedMessage id="save.up.to" values={{ number: hotelPrices?.priceSavings ? hotelPrices?.priceSavings : '<1' }} />
                    </p>
                  </div>
                  <div className="hotel-card-wrapper__label-corner"></div>
                </div>) : null}

              {(!isSoldout && recommended) ? (
                <div className="hotel-card-wrapper__label-container">
                  <div className="hotel-card-wrapper__label-recommended" style={{marginTop: 5}}>
                    <p className="hotel-card-wrapper__label-title">
                      <FormattedMessage id="result.recommended" /> 
                    </p>
                  </div>
                  <div className="hotel-card-wrapper__label-recommended-corner"></div>
                </div>) : null}

              {(!isSoldout && isPackage && (!isVacationRentals || !disableExclusivePricingTagVacationRentals)) ? 
                (<div className="hotel-card-wrapper__label-container">
                  <div className="hotel-card-wrapper__label-package" style={{marginTop: 5}}>
                    <p className="hotel-card-wrapper__label-title-package">
                      <img src={Package} style={{width: 16, marginRight: 5}} />
                      <FormattedMessage id="result.package" /> 
                    </p>
                  </div>
                  <div className="hotel-card-wrapper__label-package-corner"></div>
                </div>) : null}

              {(!isSoldout && !exactSearch) ?
                <div className="hotel-card-wrapper__label-container">
                  <div className="hotel-card-wrapper__label-stay-more" style={{marginTop: 5}}>
                    <p className="hotel-card-wrapper__label-title-stay-more">
                      <img src={BadgetPercent} style={{width: 16, marginRight: 5}} />
                      <FormattedMessage id="result.hotel.card.stay_more" /> 
                    </p>
                  </div>
                  <div className="hotel-card-wrapper__label-recommended-corner"></div>
                </div> : null}

              {(!isSoldout && isFreeBreakfast) ? 
                (<div className="hotel-card-wrapper__label-container">
                  <div className="hotel-card-wrapper__label-free-breakfast" style={{marginTop: 5}}>
                    <p className="hotel-card-wrapper__label-title-package">
                      <img src={FreeBreakfast} style={{width: 16, marginRight: 5}} />
                      <FormattedMessage id="result.free-breakfast" /> 
                    </p>
                  </div>
                  <div className="hotel-card-wrapper__label-free-breakfast-corner"></div>
                </div>) : null}

              {(!isSoldout && isAllINclusive) ? 
                (<div className="hotel-card-wrapper__label-container">
                  <div className="hotel-card-wrapper__label-all-inclusive" style={{marginTop: 5}}>
                    <p className="hotel-card-wrapper__label-title-package">
                      <img src={AllInclusive} style={{width: 16, marginRight: 5}} />
                      <FormattedMessage id="result.all-inclusive" /> 
                    </p>
                  </div>
                  <div className="hotel-card-wrapper__label-all-inclusive-corner"></div>
                </div>) : null}

            </div>
            {filterdImages?.length ? (
              <CarouselImages images={filterdImages} />
            ) : (
              <div className="hotel-card-wrapper__no-image">
                <NoImageSvg />
              </div>
            )}
          </div>
          <div className="hotel-card-wrapper__content">
            <div className={`hotel-card-wrapper__hotel-info ${isSoldout? 'sold-out' : ''} ${exactSearch? '' : 'no-exact-search'}`}>
              <div className="hotel-card-wrapper__hotel-info-items">
                <div className="hotel-card-wrapper__hotel-info-item">
                  {isFromSearch ? (
                    <h4
                      className="hotel-card-wrapper__hotel-info-title header-title"
                      onClick={() => this.onHotelPage(marginatorCommission, clientCashApplied)}
                    >
                      {displayName}{' '}
                    </h4>
                  ) : (
                    <h4 className="hotel-card-wrapper__hotel-info-title header-title">
                      {displayName}{' '}
                    </h4>
                  )}

                  {starRating > 0 && (<h4 className="hotel-card-wrapper__hotel-info-title">
                    <HotelStars stars={starRating} />
                  </h4>)}

                  {rating && rating.value !== zero && (
                    <div
                      className={`hotel-card-wrapper__trust-you-info ${
                        rating.value === zero ? 'hide-trust-you-info' : ''
                      }`}
                    >
                      <p className="hotel-card-wrapper__trust-you-text">
                        <span className={`hotel-card-wrapper__bold ${getRankingClass(rating.value)}`}>{renderNumber(rating.value, 2)}</span>(
                        {renderNumber(rating.reviewsCount)} <FormattedMessage id="reviews" />)
                      </p>
                    </div>
                  )}

                  <div className="hotel-card-wrapper__hotel-info-address">
                    <p className="hotel-card-wrapper__address">
                      {fullAddress.length !== zero ? (
                        <Tooltip title={addressStr}>
                          <span>{addressStr}</span>
                        </Tooltip>
                      ) : (
                        address
                      )}
                    </p>
                    {!isSoldout && isFromSearch && (
                      <a onClick={this.onMapPage} className="hotel-card-wrapper__link-map">
                        <LocationMapSvg />
                        <span className="hotel-card-wrapper__link-text">
                          <FormattedMessage id="view.map" />
                        </span>
                      </a>
                    )}
                  </div>

                  {!isSoldout && !isFromSearch && (
                    <div className="hotel-card-wrapper__map-mobile-data">
                      <div className="hotel-card-wrapper__mobile-price-per-night">
                        <FormattedMessage id="from" />{' '}
                        <span style={{ fontWeight: 'bold' }}>
                          <Currency currency={currency} />{renderNumber(marginatorpricePerNight)}/<FormattedMessage id="per.night" />
                        </span>
                      </div>
                      <div className="hotel-card-wrapper__mobile-total">
                        <span className="hotel-card-wrapper__mobile-total-price">
                          <Currency currency={currency} />{renderNumber(Math.floor(price))}{' '}
                        </span>
                        <FormattedMessage id="for" />{' '}
                        <FormattedMessage id="nights" values={{ count: nightsCount }} />
                      </div>
                    </div>
                  )}
                </div>

                {!isSoldout && (<div className={`hotel-card-wrapper__label-info ${location?.type === 'Hotel' ? 'hotel-type' : ''}`}>
                  <p className="hotel-card-wrapper__label-text">
                    <FormattedMessage id="from" />
                  </p>
                  <p className="hotel-card-wrapper__label-price">
                    <Currency currency={currency} />{renderNumber(marginatorpricePerNight, 2)}
                  </p>
                  <p className="hotel-card-wrapper__label-text">
                    <FormattedMessage id="per.night" />
                  </p>
                </div>)}
              </div>

              {isFromSearch && amenities.length > 0 && (
                <div className="hotel-card-wrapper__field">
                  <div className="hotel-card-wrapper__description">
                    {amenities.map(a => ({...getAmenityImage(a), name: a.name})).filter(a => !!a && a.image).slice(0, 6).map((a, i) => {
                      return (
                        <Tooltip title={a.name} placement="topLeft" key={i}>
                          <img src={a.image} alt={a.name} width="40" />
                        </Tooltip>);
                    })}
                  </div>
                </div>
              )}

              {(!exactSearch && possibleStays?.length) ? (
                <div className={`hotel-card-wrapper__suppliers ${isFromSearch ? '' : 'map-view'}`}>
                  <VacantionRentalPossiblestaysDisclaimer account={account} possibleStays={possibleStays} displayHeader={isFromSearch} displayMoreOptions>
                  </VacantionRentalPossiblestaysDisclaimer>
                </div>): null}
            </div>

            {(isFromSearch && exactSearch) ?
              this.getPricesComponent(sortedSuppliers, value, marginatorsavePercents, currency) : null}

          </div>

          <div className={`hotel-card-wrapper__price-content ${isSoldout? 'sold-out' : ''}`}>
            {!isSoldout && (<div className="hotel-card-wrapper__price-content-top">
              {(((isTravelAgentPro || minSavings || pubPrice >= marginatorPrice) && (pubPrice > zero || savings > zero || !matches)) || walletHasSliderOnSearch || displayWalletSavings) ? (
                <div className="hotel-card-wrapper__price-info">
                  {(pubPrice > zero && !displayWalletSavings) ? (
                    <div className="hotel-card-wrapper__price-public">
                      <p className="hotel-card-wrapper__public-text">
                        <FormattedMessage id={account?.forceMemberWording ? 'result.hotel.card.public.rate' : 'result.hotel.card.public'} />
                      </p>
                      <p className="hotel-card-wrapper__public-text price">
                        <Currency currency={currency} />{renderNumber(pubPrice, 2)}
                      </p>
                    </div>) : null}

                  {displayWalletSavings ? (
                    <div className="hotel-card-wrapper__price-public">
                      <p className="hotel-card-wrapper__public-text">
                        <FormattedMessage id="result.hotel.card.public" />
                      </p>
                      <p className="hotel-card-wrapper__public-text price">
                        <Currency currency={currency} />{renderNumber(marginatorPrice, 2)}
                      </p>
                    </div>) : null}

                  {(savings > zero && !displayWalletSavings) ? (
                    <div className="hotel-card-wrapper__price-savings">
                      <p className="hotel-card-wrapper__savings-text" style={{ marginRight: '6px' }}>
                        <FormattedMessage id={items?.isPromoSite ? 'points' : account?.forceMemberWording ? 'member.savings' : 'savings'} />
                      </p>
                      {marginatorSavings >= 1 ? (
                        <p className="hotel-card-wrapper__savings-text" style={{ whiteSpace: 'nowrap' }}>
                          <Currency currency={currency} />{renderNumber(marginatorSavings)}
                        </p>) : (
                        <p className="hotel-card-wrapper__savings-text" style={{ whiteSpace: 'nowrap' }}>
                          &lt; <Currency currency={currency} />{renderNumber(1)}
                        </p>)}
                    </div>) : null}

                  {displayWalletSavings ? (
                    <div className="hotel-card-wrapper__price-savings">
                      <p className="hotel-card-wrapper__savings-text" style={{ marginRight: '6px' }}>
                        <FormattedMessage id="wallet.save.up.to.max" values={{ clientCashName: account?.walletClientCashName }} />
                      </p>
                      {hotelPrices?.maxClientCashAmountAllow >= 1 ? (
                        <p className="hotel-card-wrapper__savings-text value-amount" style={{ whiteSpace: 'nowrap' }}>
                          {renderNumber(hotelPrices?.maxClientCashAmountAllow)}
                        </p>) : (
                        <p className="hotel-card-wrapper__savings-text value-amount" style={{ whiteSpace: 'nowrap' }}>
                          &lt;{renderNumber(1)}
                        </p>)}
                    </div>) : null}

                  {(!account?.walletWalletSavings && !account?.isMLM) ? (
                    <WalletMessage
                      price={marginatorPrice}
                      maxClientCash={maxWalletClientCash}
                      currency={currency}
                      type={WalletPriceType.Search}
                    />) : null}

                  {showCommission && (
                    <div className="hotel-card-wrapper__price-public">
                      <p className="hotel-card-wrapper__commission-text">
                        <FormattedMessage id="commission.price" />
                      </p>
                      <p className="hotel-card-wrapper__commission-text">
                        <Currency currency={currency} />{renderNumber(marginatorCommission, 2)}
                      </p>
                    </div>)}

                  {showSupplier && (
                    <div className="hotel-card-wrapper__price-public">
                      <p className="hotel-card-wrapper__supplier-text"><FormattedMessage id="supplier" />:</p>
                      <p className="hotel-card-wrapper__supplier-text">{supplierId}</p>
                    </div>)}
                </div>
              ) : null}
              <div className="hotel-card-wrapper__price-total" style={{ marginTop: '10px' }}>
                {refundability && refundability !== RefundableEnum.Unknown ? (
                  <div className={`hotel-card-wrapper__refundable ${(!isTravelAgentPro && pubPrice < marginatorPrice) ? 'no-price' : ''}`}>
                    {refundability === RefundableEnum.Refundable && (
                      <div className="hotel-card-wrapper__refundable-ref">
                        <FormattedMessage id="refundable" />
                      </div>)}
                    {refundability === RefundableEnum.Nonrefundable && (
                      <div className="hotel-card-wrapper__refundable-nonref">
                        <FormattedMessage id="non.refundable" />
                      </div>)}
                  </div>) : null}

                <p className="hotel-card-wrapper__price-total-text">
                  <span style={{fontSize: '13px', marginRight: '4px'}}><Currency currency={currency} /></span>{renderNumber(marginatorPriceFinal, 2)}
                </p>

                <div className="hotel-card-wrapper__for-info">
                  <p className="hotel-card-wrapper__for-info-text">
                    <FormattedMessage id="for" />{' '}
                    <FormattedMessage id="nights" values={{ count: nightsCount }} />
                  </p>
                  <Tooltip title={<FormattedMessage id={account?.breakdownTaxes ? 'price.without.taxes.fees' : 'price.includes.taxes.fees'} />}>
                    <span className="hotel-card-wrapper__price-info-icon">
                      <InfoSvg />
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>)}
            {!isSoldout && isFromSearch && (
              <div className="hotel-card-wrapper__price-content-bottom" onClick={() => this.onHotelPage(marginatorCommission, clientCashApplied)}>
                <a className="hotel-card-wrapper__price-btn" style={styleButton}>
                  <FormattedMessage id="select.room" />
                </a>
              </div>
            )}
            {isSoldout && isFromSearch && (
              <div className="hotel-card-wrapper__price-content-bottom">
                <a className="hotel-card-wrapper__price-btn sold-out">
                  <FormattedMessage id="sold.out" />
                </a>
              </div>
            )}
          </div>
          {!isSoldout && isFromSearch ?
            this.getPricesComponent(sortedSuppliers, value, marginatorsavePercents, currency, true): null}
        </div>

        {!isSoldout &&isAllSuppliersShown && isFromSearch ? (
          <div className="hotel-card-wrapper__public-prices">
            {(matches ? sortedSuppliers.filter(({ supplierId }) => supplierId !== 'EXPP').slice(zero, this.getMaxPrices()) : sortedSuppliers).map(
              ({ price, supplierId }, index) => {
                return (
                  <div className="hotel-card-wrapper__public-price-item" key={index}>
                    <div className="hotel-card-wrapper__supplier-logo-wrapper">
                      <FormattedMessage id={getSupplierMessage(supplierId)} />
                    </div>
                    <div className="hotel-card-wrapper__public-price-item-right">
                      <div className="hotel-card-wrapper__full-supplier-price">
                        <Currency currency={currency} />{renderNumber(price, 2)}
                      </div>
                      <div className="hotel-card-wrapper__full-supplier-nights">
                        <div className="hotel-card-wrapper__for-info">
                          <p className="hotel-card-wrapper__for-info-text">
                            <FormattedMessage id="for" />{' '}
                            <FormattedMessage id="nights" values={{ count: nightsCount }} />

                          </p>
                          <Tooltip title={<FormattedMessage id={account?.breakdownTaxes ? 'price.without.taxes.fees' : 'price.includes.taxes.fees'} />}>
                            <span className="hotel-card-wrapper__price-info-icon">
                              <InfoSvg />
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              },
            )}
          </div>
        ) : null}


        {isSessionExpired && (
          <div className="result-wrapper__expired-message">
            <ExpiredSessionModal visible={isSessionExpired} hasRedirect={true} onHandleRedirect={this.handleRedirect} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
    hotelsStore: state.hotelsStore,
    loginStore: state.loginStore,
    locationsStore: state.locationsStore,
    marginatorStore: state.marginatorStore
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  setCommission: (value: number) => {
    dispatch(marginatorActions.setCommission(value));
  },
  setSupplier: (value: string) => {
    dispatch(marginatorActions.setSupplier(value));
  },
  setHotelDetails: (hotel: IHotelInfo) => {
    dispatch(hotelDetailsActions.setHotelDetails(hotel));
  },
  setSelectedHotel: (hotel: IHotel) => {
    dispatch(hotelsActions.setSelectedHotel(hotel));
  },
  resetState: () => {
    dispatch(roomsSearchActions.resetState());
  },
  setSelectedHotelSearchClientCash: (selectedClientCash: IClientCash) => {
    dispatch(hotelsActions.setSelectedHotelSearchClientCash(selectedClientCash));
  }

});

const HotelCardWrapperRouter = withRouter(HotelCardWrapper);

export const HotelCardComponent = connect(mapStateToProps, mapDispatchToProps)(HotelCardWrapperRouter);
