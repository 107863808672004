import React, { useEffect, useRef } from 'react';

import { FunctionalRootState, scrollTop } from '@share/utils';

import './style.scss';
import ArrowDownBanner from '@assets/images/mlm-levalti/new-agents/arrow-down-banner.svg';
import AgentDashboard from '@assets/images/mlm-levalti/technology/image-asset.jpg';
import AgentWebsiteImg from '@assets/images/mlm-levalti/technology/travnow-desktop1.jpg';
import SupplierImg from '@assets/images/mlm-levalti/technology/travnow-desktop2.jpg';
import DealsImg from '@assets/images/mlm-levalti/technology/travnow-laptop.jpg';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ILoginState } from '@share/store/slices';
import { useSelector } from 'react-redux';

export default function MLMTechnology() {
  useEffect(() => {
    scrollTop();
  }, []);

  const startedSectionRef = useRef<HTMLDivElement>(null);
  //Smooth Scroll Click
  const scrollToSection = () => {
    if (startedSectionRef.current) {
      startedSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const { account }: ILoginState = useSelector((state: FunctionalRootState) => state.loginStore);

  const intl = useIntl();

  // Obtén el mensaje como un string
  const paragraph: string[] = intl
    .formatMessage({ id: 'technology.supplier.paragraph' })
    .split('.');

  return (
    <div className="technology">
      {/*Header*/}
      <header className="technology__header">
        <section className="technology__header-title">
          <h1 className="technology__header-title-main">
            <span className="technology__header-title-main-subtitle1">
              <FormattedMessage id="technology.header.title" />
            </span>{' '}
            <span className="technology__header-title-main-subtitle2">
              {' '}
              <FormattedMessage id="technology.header.subtitle" />
            </span>
          </h1>
        </section>
        <section className="technology__header-arrow">
          <img
            src={ArrowDownBanner}
            alt="arrow down"
            onClick={scrollToSection}
            className="move-down-on-hover"
          />
        </section>
      </header>
      <section className="technology__main-section" ref={startedSectionRef}>
        {/*First Section*/}
        <section className="technology__main-section-success">
          <section className="technology__main-section-success-one">
            <h2>
              <FormattedMessage id="technology.success.title" />
            </h2>
            <p>
              <FormattedMessage id="technology.success.paragraph" />
            </p>
          </section>
        </section>
        {/*Second Section*/}
        <section className="technology__main-section-main">
          <section className="technology__main-section-main-container">
            <section className="technology__main-section-main-container-img">
              <img src={AgentDashboard} alt="Agent Dashboard Img" />
            </section>
            <section className="technology__main-section-main-container-content">
              <h2 className="technology__main-section-main-container-content-title">
                <span>
                  <FormattedMessage id="technology.agent.title" />
                </span>{' '}
                <span className="technology__main-section-main-container-content-title-bold">
                  <FormattedMessage id="technology.agent.subtitle" />
                </span>
              </h2>
              <p className="technology__main-section-main-container-content-paragraph">
                <FormattedMessage id="technology.agent.paragraph" />
              </p>
              <ul className="technology__main-section-main-container-content-list">
                <li>
                  <FormattedMessage id="technology.agent.list1" />
                </li>
                <li>
                  <FormattedMessage id="technology.agent.list2" />
                </li>
                <li>
                  <FormattedMessage id="technology.agent.list3" />
                </li>
                <li>
                  <FormattedMessage id="technology.agent.list4" />
                </li>
                <li>
                  <FormattedMessage id="technology.agent.list5" />
                </li>
              </ul>
            </section>
          </section>
        </section>
        {/*Third Section*/}
        <section className="technology__main-section-main">
          <section className="technology__main-section-main-container">
            <section className="technology__main-section-main-container-content">
              <h2 className="technology__main-section-main-container-content-title">
                <span>
                  <FormattedMessage id="technology.website.title" />
                </span>{' '}
                <span className="technology__main-section-main-container-content-title-bold">
                  <FormattedMessage id="technology.website.subtitle" />
                </span>
              </h2>
              <p className="technology__main-section-main-container-content-paragraph">
                <FormattedMessage id="technology.website.paragraph" />
              </p>
              <ul className="technology__main-section-main-container-content-list">
                <li>
                  <FormattedMessage id="technology.website.list1" />
                </li>
                <li>
                  <FormattedMessage id="technology.website.list2" />
                </li>
                <li>
                  <FormattedMessage id="technology.website.list3" />
                </li>
                <li>
                  <FormattedMessage id="technology.website.list4" />{' '}
                  <i>
                    <FormattedMessage id="technology.website.url1" />
                  </i>
                </li>
                <li>
                  <FormattedMessage id="technology.website.list5" />{' '}
                  <i>
                    <a href={intl.formatMessage({ id: 'technology.website.url2' })} target="_blank">
                      (<FormattedMessage id="technology.website.url2" />)
                    </a>
                  </i>
                </li>
                <li>
                  <FormattedMessage id="technology.website.list6" />
                </li>
              </ul>
            </section>
            <section className="technology__main-section-main-container-img">
              <img src={AgentWebsiteImg} alt="Agent Dashboard Img" />
            </section>
          </section>
        </section>
        {/*Fourth Section*/}
        <section className="technology__main-section-main">
          <section className="technology__main-section-main-container">
            <section className="technology__main-section-main-container-img">
              <img src={SupplierImg} alt="Agent Dashboard Img" />
            </section>
            <section className="technology__main-section-main-container-content">
              <h2 className="technology__main-section-main-container-content-title">
                <span>
                  <FormattedMessage id="technology.promotion.title" />
                </span>{' '}
                <span className="technology__main-section-main-container-content-title-bold">
                  <FormattedMessage id="technology.promotion.subtitle" />
                </span>
              </h2>
              <p className="technology__main-section-main-container-content-paragraph">
                <FormattedMessage id="technology.promotion.paragraph" />
              </p>
              <ul className="technology__main-section-main-container-content-list">
                <li>
                  <FormattedMessage id="technology.promotion.list1" />
                </li>
                <li>
                  <FormattedMessage id="technology.promotion.list2" />
                </li>
                <li>
                  <FormattedMessage id="technology.promotion.list3" />
                </li>
                <li>
                  <FormattedMessage id="technology.promotion.list4" />
                </li>
              </ul>
            </section>
          </section>
        </section>
        {/*Fifth Section*/}
        <section className="technology__main-section-main">
          <section className="technology__main-section-main-container">
            <section className="technology__main-section-main-container-content">
              <h2 className="technology__main-section-main-container-content-title">
                <span>
                  <FormattedMessage id="technology.supplier.title" />
                </span>{' '}
                <span className="technology__main-section-main-container-content-title-bold">
                  <FormattedMessage id="technology.supplier.subtitle" />
                </span>
              </h2>
              <p className="technology__main-section-main-container-content-paragraph">
                {paragraph[0]}. {paragraph[1]}. <strong>{paragraph[2]}</strong>.
              </p>
              <ul className="technology__main-section-main-container-content-list">
                <li>
                  <FormattedMessage id="technology.supplier.list1" />
                </li>
                <li>
                  <FormattedMessage id="technology.supplier.list2" />
                </li>
                <li>
                  <FormattedMessage id="technology.supplier.list3" />
                </li>
                <li>
                  <FormattedMessage id="technology.supplier.list4" />
                </li>
                <li>
                  <FormattedMessage id="technology.supplier.list5" />
                </li>
                <li>
                  <FormattedMessage id="technology.supplier.list6" />
                </li>
              </ul>
              <Link
                to={`/${account?.name}/exclusive-access`}
                className="technology__main-section-main-container-content-link"
              >
                <button className="technology__main-section-main-container-content-btn">
                  <FormattedMessage id="technology.supplier.seemore" />
                </button>
              </Link>
            </section>
            <section className="technology__main-section-main-container-img">
              <img src={DealsImg} alt="Agent Dashboard Img" />
            </section>
          </section>
        </section>
      </section>
    </div>
  );
}
