import React from 'react';

type SizeProps = {
  size?: string;
}

export const BathSvg: React.FunctionComponent<SizeProps> = ({ size }: SizeProps) => (
  <svg width={size || 16} height={size || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.66671 6.66667H14C14.3687 6.66667 14.6667 6.96467 14.6667 7.33333V8.66667C14.6667 10.404 13.5507 11.882 12 12.4333V14.6667H10.6667V12.6667H5.33337V14.6667H4.00004V12.4333C2.44937 11.882 1.33337 10.404 1.33337 8.66667V7.33333C1.33337 6.96467 1.63137 6.66667 2.00004 6.66667H3.33337V4.66667C3.33337 3.196 4.52937 2 6.00004 2C7.47071 2 8.66671 3.196 8.66671 4.66667H7.33337C7.33337 3.93133 6.73537 3.33333 6.00004 3.33333C5.26471 3.33333 4.66671 3.93133 4.66671 4.66667V6.66667Z"
      fill="#333333"
    />
  </svg>
);
