import { createSelector } from '@reduxjs/toolkit';
import first from 'lodash/first';
import { IVideoMedia } from '@api';
import { RootState } from '@share/utils';
import { IMemberVideosState } from './slice';

export const selectMemberVideosState = (state: RootState): IMemberVideosState => state.memberVideos;

export const selectMemberVideos = (state: RootState): IVideoMedia[] =>
  selectMemberVideosState(state).videos;

export const selectMemberVideossIsLoading = (state: RootState): boolean =>
  selectMemberVideosState(state).loading;

export const selectMemberVideossError = (state: RootState): string =>
  selectMemberVideosState(state).error;

export const selectMemberActiveVideos = (state: RootState): string =>
  selectMemberVideosState(state).activeVideo;

export const selectMemberVideosByFileName = createSelector(
  selectMemberVideos,
  selectMemberActiveVideos,
  (memberVideos, activeFileName) => {
    if (!activeFileName) {
      return first(memberVideos);
    }

    return memberVideos.find(({ fileName }) => fileName === activeFileName);
  },
);
