import React from 'react';

import { Experiences } from '@components';
import { scrollTop } from '@share/utils';

import './style.scss';

interface IProps {}

class ExperiencesPageComponent extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);

    scrollTop();
  }

  render(): React.ReactNode {
    return (
      <div className="experiences-page">
        <Experiences />
      </div>
    );
  }
}

export const ExperiencesPage = ExperiencesPageComponent;
