import React from 'react';

import { SortTypes } from "@share/constants";
import { IWeeksFiltersState, IWeeksLocationsState, IWeekState, WeeksFiltersInitialState } from "@share/store/slices";
import { LabeledValue } from "antd/lib/select";
import { isEqual, omit } from "lodash";
import { FormattedMessage } from "react-intl";
import { CondoLocationsEnum } from '@share/common-types';
import { WEEKS_PACKAGE_ID_ELEMENT } from '@constants';

const defaultDistance = 20;
const ZERO = 0;

export const getWeeksAvailabilityId = (): string => {
  return location.pathname.split('/')[WEEKS_PACKAGE_ID_ELEMENT];
};

export const isWeeksFiltersEmpty = (currentFilters: IWeeksFiltersState): boolean => {
    return isEqual(omit(currentFilters, ['sortType']), omit(WeeksFiltersInitialState, ['sortType']));
};

export const getWeeksSortOptions = (weeksLocationState: IWeeksLocationsState, isB2C: boolean, walletWalletSavings: boolean): LabeledValue[] => {
    const { location } = weeksLocationState;
    const isSpecificCondo: boolean = location?.locationType === CondoLocationsEnum.GenericCondo;
  
    const options = [];
  
    if (!isB2C || walletWalletSavings) {
      options.push({ value: SortTypes.BiggestSavingsPercentage, label: <FormattedMessage id="sort.biggest.percent" /> });
      options.push({ value: SortTypes.BiggestSavings, label: <FormattedMessage id="sort.biggest" /> });
    }
  
    options.push(
      { value: SortTypes.MostPopular, label: <FormattedMessage id="most.popular" /> },
      { value: SortTypes.PriceAsc, label: <FormattedMessage id="sort.lowest.price" /> },
      { value: SortTypes.PriceDesc, label: <FormattedMessage id="sort.highest.price" /> },
    );
  
    if (isSpecificCondo) {
      options.push({ value: SortTypes.Nearest, label: <FormattedMessage id="sort.nearest" /> });
    }
      
    return options;
};

export const getWeeksFiltersCount = (currentFilters: IWeeksFiltersState): number => {
    let res = ZERO;
    const objToCount: { [key: string]: (string | number | boolean)[] } = omit(currentFilters, [
      'sortBy',
      'distanceInMiles',
      'propertyName',
      'isAllInclusiveOnly',
    ]);
  
    Object.keys(objToCount).forEach((key) => {
      if (objToCount[key] && objToCount[key].length) {
        res += objToCount[key].length;
      }
    });
  
    if (currentFilters.distanceInMiles !== defaultDistance) {
      res++;
    }
  
    if (currentFilters.isAllInclusiveOnly) {
      res++;
    }
  
    return res;
};
  