
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TablePaginationConfig } from 'antd/lib/table';

import { IAccount } from '@share/common-types';
import { Urls } from '@share/constants';
import { getHeaders, axiosInstance, Toaster, AppThunk } from '@share/utils';

const PAGE_SIZE = 10;

export interface TableSortingConfig {
  key: string;
  type: string;
}

export interface IAccountsState {
  selectedAccount: IAccount;
  accounts: IAccount[];
  pagination: TablePaginationConfig;
  sorting: TableSortingConfig;
  retrieveDeleted: boolean;
  loading: boolean;
  keyword: string;
  error: string;
}

const initialState: IAccountsState = {
  selectedAccount: null,
  accounts: null,
  loading: false,
  sorting: null,
  pagination: {
    total: null,
    current: 1,
    pageSize: PAGE_SIZE,
  },
  retrieveDeleted: false,
  keyword: '',
  error: '',
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setAccountsLoading: (state: IAccountsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setAccountsError: (state: IAccountsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setAccountsKeyword: (state: IAccountsState, { payload }: PayloadAction<string>) => {
      state.keyword = payload;
    },
    setAccounts: (state: IAccountsState, { payload }: PayloadAction<IAccount[]>) => {
      state.accounts = payload;
    },
    setSelectedAccount: (state: IAccountsState, { payload }: PayloadAction<IAccount>) => {
      state.selectedAccount = payload;
    },
    setAccountPagination: (state: IAccountsState, { payload }: PayloadAction<TablePaginationConfig>) => {
      state.pagination = payload;
    },
    setAccountSorting: (state: IAccountsState, { payload }: PayloadAction<TableSortingConfig>) => {
      state.sorting = payload;
    },
    setRetrieveDeleted: (state: IAccountsState, { payload }: PayloadAction<boolean>) => {
      state.retrieveDeleted = payload;
    },
  },
});

export const {
  setAccountsLoading, 
  setAccountsError, 
  setAccounts, 
  setSelectedAccount,
  setAccountPagination,
  setAccountSorting,
  setAccountsKeyword,
  setRetrieveDeleted
} = accountsSlice.actions;

export const accountsReducer = accountsSlice.reducer;

export const accountsSelector = (state: { accountsStore: IAccountsState }): IAccountsState => {
  return state.accountsStore;
};

export const getAccounts = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setAccountsLoading(true));
    dispatch(setSelectedAccount(null));

    try {
      const { accountsStore, loginStore } = getState();
      const { pagination, sorting, keyword, retrieveDeleted } = accountsStore;
      const { user } = loginStore;
      const { current, pageSize } = pagination;
      const criteria = {
        page: current,
        pageSize,
        sorting,
        retrieveDeleted,
        keyword
      }
      const res = await axiosInstance.post(`${Urls.Accounts}/search`, criteria, {
        ...getHeaders(),
      });

      const { accounts, total } = res.data;
      const paginationUpdated = {
        total,
        current,
        pageSize
      };

      dispatch(setAccountsLoading(false));
      dispatch(setAccounts(accounts));
      dispatch(setAccountPagination(paginationUpdated));
    } catch (error) {
      console.error(error);
      dispatch(setAccountsError(error.toString()));
      dispatch(setAccountsLoading(false));
    }
  };
};

export const getSelectedAccountDetails = (accountId: number): AppThunk => {
  return async (dispatch, getState) => {
    if (!accountId) {
      dispatch(setSelectedAccount(null));
      return
    }

    dispatch(setAccountsLoading(true));

    const { loginStore } = getState();
    const { user } = loginStore;

    try {
      const res = await axiosInstance.get(`${Urls.Accounts}/${accountId}`, {
        ...getHeaders(),
      });

      dispatch(setAccountsLoading(false));
      if (res?.status === 204) {
        dispatch(setAccountsError('Invalid Account'));
      }  else {
        dispatch(setSelectedAccount(res.data));
      }
  } catch (error) {
      console.error(error);
      dispatch(setAccountsError(error.toString()));
      dispatch(setAccountsLoading(false));
    }
  };
};

export const deleteAccount = (accountId: number): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setAccountsLoading(true));

    try {
      await axiosInstance.delete(`${Urls.Accounts}/${accountId}`, {
        ...getHeaders(),
      });

      dispatch(setAccountsLoading(false));
      dispatch(setSelectedAccount(null));

      Toaster.success(`Account successfully Deleted!`);

      dispatch(getAccounts());

    } catch (error) {
      console.error(error);
      dispatch(setAccountsError(error.toString()));
      dispatch(setAccountsLoading(false));

      Toaster.error(`Error occured. If the problem persist, please contact system administrator`);
    }
  };
};

export const activateAccount = (accountId: number): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setAccountsLoading(true));

    try {
      const { loginStore } = getState();
      const { user } = loginStore;
  
      await axiosInstance.post(`${Urls.Accounts}/${accountId}/activate`, {}, {
        ...getHeaders(),
      });

      dispatch(setAccountsLoading(false));
      dispatch(setSelectedAccount(null));

      Toaster.success(`Account successfully Activated!`);

      dispatch(getAccounts());

    } catch (error) {
      console.error(error);
      dispatch(setAccountsError(error.toString()));
      dispatch(setAccountsLoading(false));

      Toaster.error(`Error occured. If the problem persist, please contact system administrator`);
    }
  };
};
