import React from 'react';

import { connect } from 'react-redux';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';

import { CategoryEnum } from '@components';

import './style.scss';

interface IProps extends WrappedComponentProps {
  category: string;
}

class ReservationCategoryComponent extends React.Component<IProps> {
  
  render(): React.ReactNode {
    const { category } = this.props;

    if (!isEmpty(category)) {
      const value = category.toLowerCase();
      return <div className='status' >
        <div className={`text ${value?.toLocaleLowerCase() === CategoryEnum.Hotel ? 'green' : value?.toLocaleLowerCase() === CategoryEnum.Condo ? 'cyan' : 'geekblue'}`}>
          <FormattedMessage id={category} />
        </div>
      </div>
    }
    return null;
  }
}

export const ReservationCategory = connect()(injectIntl(ReservationCategoryComponent));
