import React from 'react';

export const LocationMapSvg: React.FunctionComponent = () => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.94275 11.9331L9.77142 9.10447C10.5173 8.35858 11.0252 7.40827 11.231 6.37371C11.4367 5.33916 11.3311 4.26682 10.9274 3.29229C10.5237 2.31777 9.84015 1.48483 8.9631 0.89881C8.08604 0.312788 7.05491 0 6.00008 0C4.94526 0 3.91413 0.312788 3.03707 0.89881C2.16001 1.48483 1.47643 2.31777 1.07275 3.29229C0.669077 4.26682 0.563443 5.33916 0.769209 6.37371C0.974974 7.40827 1.4829 8.35858 2.22875 9.10447L5.05808 11.9331C5.30809 12.1829 5.64703 12.3232 6.00042 12.3232C6.35381 12.3232 6.69274 12.1829 6.94275 11.9331ZM8.00008 5.33314C8.00008 6.43771 7.10465 7.33314 6.00008 7.33314C4.89551 7.33314 4.00008 6.43771 4.00008 5.33314C4.00008 4.22857 4.89551 3.33314 6.00008 3.33314C7.10465 3.33314 8.00008 4.22857 8.00008 5.33314Z"
      fill="#0D99D6"
    />
  </svg>
);
