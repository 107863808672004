
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router';

import { ILoginState } from '@share/store/slices';
import { RootState } from '@share/utils';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, ButtonProps, RouteComponentProps {
  reverse?: boolean;
}

function BlueButtonComponent(props: IProps) {

  const { reverse, loginStore } = props;
  
  const account = loginStore?.account;
  const buttonColor = account?.buttonColor;
  const buttonBorder = account?.buttonBorder;
  const buttonTextColor = account?.buttonTextColor;
  const buttonBorderRadius = account?.buttonBorderRadius;
  const buttonTextTransform = account?.buttonTextTransform;
  const buttonColorReverse = account?.buttonColorReverse;
  const buttonTextColorReverse = account?.buttonTextColorReverse;
  const buttonBorderReverse = account?.buttonBorderReverse;
  const buttonBorderRadiusReverse = account?.buttonBorderRadiusReverse;
  
  const borderRadius = (reverse && !isEmpty(buttonBorderRadiusReverse)) ? buttonBorderRadiusReverse : buttonBorderRadius;
  const background = (reverse && !isEmpty(buttonColorReverse)) ? buttonColorReverse : buttonColor;
  const border = (reverse && !isEmpty(buttonBorderReverse)) ? buttonBorderReverse : buttonBorder;
  const color = (reverse && !isEmpty(buttonTextColorReverse)) ? buttonTextColorReverse : buttonTextColor;

  const styleButtonProps = !!props?.style ? { ...props?.style } : {};
  const styleButtonBorderRadius = !isEmpty(borderRadius) ? { ...styleButtonProps, borderRadius: `${borderRadius}px` } : { ...styleButtonProps };  
  const styleButtonnTextTransform = (buttonTextTransform && !isEmpty(buttonTextTransform)) ? { ...styleButtonBorderRadius, textTransform: buttonTextTransform as any } : { ...styleButtonBorderRadius };  
  const styleButtonBorder = !isEmpty(border)? { ...styleButtonnTextTransform, border } : { ...styleButtonnTextTransform };  
  const styleButtonColor = !isEmpty(color)? { ...styleButtonBorder, color } : { ...styleButtonBorder };  
  const styleButton = !isEmpty(background)? { ...styleButtonColor, background } : { ...styleButtonColor };  

  const { htmlType, onClick } = props;

  const propsButton = { htmlType, onClick };
  const className = `blue-button ${props.className ? props.className : ''} ${isEmpty(buttonBorderRadius) ? 'with-radius' : ''}`;

  return (
    <Button {...propsButton} disabled={props.disabled} className={className} style={styleButton}>
      {props.children}
    </Button>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};


export const BlueButton = connect(mapStateToProps)(withRouter(BlueButtonComponent));