import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@share/utils';
import { Urls } from '@share/constants';
import { getHeaders, axiosInstance } from '@share/utils';
import {
  ICancelReservation,
  IMarkAsCancelled,
  IRequestCancelReservation,
  IReservationCancellationDetails,
} from '@share/common-types';

export interface IReservationCancellationState {
  reservationCancellationDetails: IReservationCancellationDetails;
  loading: boolean;
  error: string;
  requestCancelReservation: IRequestCancelReservation;
  loadingRequestCancelReservation: boolean;
  errorRequestCancelReservation: string;
  cancelReservation: ICancelReservation;
  loadingCancelReservation: boolean;
  errorCancelReservation: string;
  markAsCancelled: IMarkAsCancelled;
  loadingMarkAsCancelled: boolean;
  errorMarkAsCancelled: string;
}

const initialState: IReservationCancellationState = {
  reservationCancellationDetails: null,
  loading: false,
  error: '',
  requestCancelReservation: null,
  loadingRequestCancelReservation: false,
  errorRequestCancelReservation: '',
  cancelReservation: null,
  loadingCancelReservation: false,
  errorCancelReservation: '',
  markAsCancelled: null,
  loadingMarkAsCancelled: false,
  errorMarkAsCancelled: '',
};

const reservationCancellationSlice = createSlice({
  name: 'ReservationCancellation',
  initialState,
  reducers: {
    setReservationCancellationDetails: (
      state: IReservationCancellationState,
      { payload }: PayloadAction<IReservationCancellationDetails>,
    ) => {
      state.reservationCancellationDetails = payload;
    },
    setLoading: (state: IReservationCancellationState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: IReservationCancellationState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setRequestCancelReservation: (
      state: IReservationCancellationState,
      { payload }: PayloadAction<IRequestCancelReservation>,
    ) => {
      state.requestCancelReservation = payload;
    },
    setLoadingRequestCancelReservation: (
      state: IReservationCancellationState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.loadingRequestCancelReservation = payload;
    },
    setErrorRequestCancelReservation: (
      state: IReservationCancellationState,
      { payload }: PayloadAction<string>,
    ) => {
      state.errorRequestCancelReservation = payload;
    },
    setCancelReservation: (
      state: IReservationCancellationState,
      { payload }: PayloadAction<ICancelReservation>,
    ) => {
      state.cancelReservation = payload;
    },
    setLoadingCancelReservation: (
      state: IReservationCancellationState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.loadingCancelReservation = payload;
    },
    setErrorCancelReservation: (
      state: IReservationCancellationState,
      { payload }: PayloadAction<string>,
    ) => {
      state.errorCancelReservation = payload;
    },
    setMarkAsCancelled: (
      state: IReservationCancellationState,
      { payload }: PayloadAction<IMarkAsCancelled>,
    ) => {
      state.markAsCancelled = payload;
    },
    setLoadingMarkAsCancelled: (
      state: IReservationCancellationState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.loadingMarkAsCancelled = payload;
    },
    setErrorMarkAsCancelled: (
      state: IReservationCancellationState,
      { payload }: PayloadAction<string>,
    ) => {
      state.errorMarkAsCancelled = payload;
    },
  },
});

export const reservationCancellationAction = reservationCancellationSlice.actions;
export const reservationCancellationReducer = reservationCancellationSlice.reducer;

export const getRequestCancelReservation = (key: string): AppThunk => {
  return async (dispatch) => {
    dispatch(reservationCancellationAction.setLoadingRequestCancelReservation(true));
    try {
      // mlg
      const params = {
        CancelationId: key,
      };
      const res = await axiosInstance.post(Urls.requestCancelReservation, params, {
        ...getHeaders(),
      });

      dispatch(reservationCancellationAction.setLoadingRequestCancelReservation(false));
      dispatch(reservationCancellationAction.setRequestCancelReservation(res.data));
    } catch (error) {
      dispatch(reservationCancellationAction.setLoadingRequestCancelReservation(false));
      dispatch(reservationCancellationAction.setErrorRequestCancelReservation(error.toString()));
    }
  };
};

export const getReservationCancellationDetails = (tempKey: string): AppThunk => {
  return async (dispatch) => {
    dispatch(reservationCancellationAction.setLoading(true));

    try {
      const params = {
        tempKey: tempKey,
      };
      const res = await axiosInstance.post(Urls.reservationCancellationDetails, null, {
        params,
        ...getHeaders(),
      });

      dispatch(reservationCancellationAction.setLoading(false));
      dispatch(reservationCancellationAction.setReservationCancellationDetails(res.data));
    } catch (error) {
      console.error(error);
      dispatch(reservationCancellationAction.setLoading(false));
      dispatch(reservationCancellationAction.setError(error.toString()));
    }
  };
};

export const getCancelReservation = (tempKey: string): AppThunk => {
  return async (dispatch) => {
    dispatch(reservationCancellationAction.setLoadingCancelReservation(true));

    try {
      const params = {
        linkKey: tempKey,
      };
      const res = await axiosInstance.post(Urls.cancelReservation, params, {
        ...getHeaders(),
      });

      dispatch(reservationCancellationAction.setLoadingCancelReservation(false));
      dispatch(reservationCancellationAction.setCancelReservation(res.data));
    } catch (error) {
      console.error(error);
      dispatch(reservationCancellationAction.setErrorCancelReservation(error.toString()));
    }
  };
};

export const getMarkAsCancelled = (orderId: number, reason: string): AppThunk => {
  return async (dispatch) => {
    dispatch(reservationCancellationAction.setLoadingMarkAsCancelled(true));
    try {
      const body = {
        orderId,
        reason,
      };

      const res = await axiosInstance.post(Urls.MarkAsCancelled, body, {
        ...getHeaders(),
      });

      dispatch(reservationCancellationAction.setMarkAsCancelled(res.data));
      dispatch(reservationCancellationAction.setLoadingMarkAsCancelled(false));
    } catch (error) {
      console.error(error);
      dispatch(reservationCancellationAction.setErrorMarkAsCancelled(error.toString()));
    }
  };
};

