import React from 'react';

export const PlaySvg: React.FunctionComponent = () => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM11.5188 8.12339C11.8387 7.94776 12.229 7.96039 12.5369 8.15634L23.5369 15.1563C23.8253 15.3399 24 15.6581 24 16C24 16.3419 23.8253 16.6601 23.5369 16.8437L12.5369 23.8437C12.229 24.0396 11.8387 24.0522 11.5188 23.8766C11.1988 23.701 11 23.365 11 23V9C11 8.63502 11.1988 8.29903 11.5188 8.12339Z"
      fill="white"
    />
  </svg>
);
