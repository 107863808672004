import React, { RefObject, useState } from 'react';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FormInstance } from 'antd/lib/form';

import { CreditCardsList, PaymentMethodBase } from '@components';
import { ICarDetails, IUserCard } from '@share/common-types';

import { ICardBooking } from '@common-types';
import { ICarsReviewBookState, setCarsCard } from '@store/slices';
import { RootState } from '@share/utils';
import { ILoginState } from '@share/store/slices';

import './style.scss';

interface IMapStateToProps {
  carsReviewBookStore: ICarsReviewBookState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setCard: (card: ICardBooking) => void;
}

interface IProps extends IMapDispatchToProps, IMapStateToProps {
  car: ICarDetails;

  formRef: RefObject<FormInstance>;
}

function CarsPaymentComponent(props: IProps) {    

  const { loginStore, carsReviewBookStore, formRef, setCard } = props;
  const { account } = loginStore;
  const { card, errorsField, loadingBooking } = carsReviewBookStore;
  
  const [ refreshPayment, setRefreshPayment ] = useState(false);

  const handleCardSelect = async (selectedCard: IUserCard) => {
    const { setCard } = props;
    await setCard(selectedCard);
    setRefreshPayment(true);
  }

  return (
    <div className="hotel-info">
      <div className="hotel-info__item border-top">
        <div className="hotel-info__item-content">
          <h4 className="hotel-info__title">
            <FormattedMessage id="tab.payment" />
          </h4>

          <CreditCardsList
            selectedCardId={card?.id}
            onSelectCard={handleCardSelect}
            selectable
            hideIfNoData
            isCheckout
          />

          <PaymentMethodBase
            form={null}
            formRef={formRef}

            refreshData={refreshPayment}
            onDataRefreshed={() => setRefreshPayment(false)}

            card={card}
            errorsField={errorsField}
            loading={loadingBooking}
            setCard={(card) => setCard(card)}

            displaySave={account?.isPrivateTokenAccountType || account?.isPrivateLoginAccountType}

            disableInsurance
            isRequired
            display
          />

        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    carsReviewBookStore: state.carsReviewBookStore,
    loginStore: state.loginStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setCard: setCarsCard,
};

export const CarsPayment = connect(mapStateToProps, mapDispatchToProps)(CarsPaymentComponent);
