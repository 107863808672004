import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import VacationHome from '@assets/images/sidekick/vacation-home.jpg';

import './style.scss';
import { isEmpty } from 'lodash';
import { IMenuState } from '@share/store/slices';
import { RootState } from '@share/utils';

interface IMapStateToProps {
	menuStore: IMenuState;
}

interface IProps extends IMapStateToProps, RouteComponentProps, WrappedComponentProps { }

function SideKickRewardsPrivacyComponent(props: IProps) {	
	const { menuStore } = props;
	const { items } = menuStore;

	const [scroll, setScroll] = useState(0);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
	}, []);

	const handleScroll = () => {
		const scrollPosition = window.scrollY;
		setScroll(scrollPosition / 3);
	}

	const sidekickName = !isEmpty(items?.sideKickName) ? items?.sideKickName : 'Sidekick Rewards';
	const sidekickNameShort = !isEmpty(items?.sideKickShortName) ? items?.sideKickShortName : 'Sidekick';

	return (
		<div className="sidekicks-terms-wrapper">
			<section className="image-bg overlay parallax">
				<div className="background-image-holder fadeIn" style={{ transform: `translate3d(0px, ${scroll}px, 0px)`, background: `url(${VacationHome})`, top: '-40px' }}>
				</div>
				<div className="container">
					<div className="col-lg-12">
						<h1 className="getaway-title"><FormattedMessage id="sidekick.rewards.privacy.title" /></h1>
					</div>
				</div>
			</section>

			<section className="search-results-section">
				<div className="container">
					<div className="col-md-12" style={{ marginBottom: '16px' }}>
						<div className="card">
							<div className="row">
								<div className="col-lg-12 ">
									<p><FormattedMessage id="sidekick.rewards.privacy.message1" /></p>

									<p><FormattedMessage id="sidekick.rewards.privacy.message2" /> <a href="mailto:privacy@membergetaways.com" target="_blank">privacy@membergetaways.com</a>. </p>


									<p><b><FormattedMessage id="sidekick.rewards.privacy.message3" /></b><br/><FormattedMessage id="sidekick.rewards.privacy.message4" /></p>

									<p><b><FormattedMessage id="sidekick.rewards.privacy.message5" /></b><br/><FormattedMessage id="sidekick.rewards.privacy.message6" /></p>

									<p><b><FormattedMessage id="sidekick.rewards.privacy.message7" /></b><br/><FormattedMessage id="sidekick.rewards.privacy.message8" /></p>

									<p><FormattedMessage id="sidekick.rewards.privacy.message9" /></p>

									<p><b><FormattedMessage id="sidekick.rewards.privacy.message10" /></b><br/><FormattedMessage id="sidekick.rewards.privacy.message11" /></p>

									<p><FormattedMessage id="sidekick.rewards.privacy.message12" /></p>

									<ul>
										<li><FormattedMessage id="sidekick.rewards.privacy.message13" /></li>
										<li><FormattedMessage id="sidekick.rewards.privacy.message14" /></li>
										<li><FormattedMessage id="sidekick.rewards.privacy.message15" /></li>
									</ul>

									<p><b><FormattedMessage id="sidekick.rewards.privacy.message16" /></b><br/><FormattedMessage id="sidekick.rewards.privacy.message17" /></p>

									<p><FormattedMessage id="sidekick.rewards.privacy.message18" /></p>

									<p><FormattedMessage id="sidekick.rewards.privacy.message19" /></p>

									<p><b><FormattedMessage id="sidekick.rewards.privacy.message20" /></b><br/><FormattedMessage id="sidekick.rewards.privacy.message21" /></p>

									<p><b><FormattedMessage id="sidekick.rewards.privacy.message22" /></b><br/><FormattedMessage id="sidekick.rewards.privacy.message23" /></p>

									<p><b><FormattedMessage id="sidekick.rewards.privacy.message24" values={{ name: sidekickName }} /></b><br/><FormattedMessage id="sidekick.rewards.privacy.message25" values={{ name: sidekickName, nameShort: sidekickNameShort }} /></p>

									<p><b><FormattedMessage id="sidekick.rewards.privacy.message26" /></b><br/><FormattedMessage id="sidekick.rewards.privacy.message27" /></p>

								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
	return {
		menuStore: state.navigationMenuStore,
	};
};

const ComponentIntl = injectIntl(SideKickRewardsPrivacyComponent);

export const SideKickRewardsPrivacy = connect(mapStateToProps)(withRouter(ComponentIntl));
