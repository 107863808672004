import React from 'react';

import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { getawayBookingActions, IGetawayBookingState } from '@store/slices';
import { ILoginState, IMenuState } from '@share/store/slices';
import { IGetawayBookInfo } from '@share/common-types';
import { RootState } from '@share/utils';
import { ManBlueSvg } from '@assets';
import { CloseSvg } from '@share/assets';
import { BlueButton } from '@share/components';
import { GetawayGuests } from '../getaway-guests';

import './style.scss';
import { isEmpty } from 'lodash';

interface IMapStateToProps {
  getawayBookingStore: IGetawayBookingState;
  menuStore: IMenuState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setGetawayBookInfo: (getawayBookInfo: IGetawayBookInfo) => void;
  updateAdultsCount: (count: number) => void;
  updateYoungerChildrenCount: (count: number) => void;
  updateMiddleChildrenCount: (count: number) => void;
  updateOlderChildrenCount: (count: number) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {}

interface IState {
  isFocused: boolean;
}

const TWO = 2;

class GetawayGuestsPikerComponent extends React.Component<IProps, IState> {
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  listRef: React.RefObject<HTMLDivElement> = React.createRef();
  state: IState = {
    isFocused: false,
  };

  focus = (): void => {
    if (!this.state.isFocused) {
      this.setState({ isFocused: true });
    }
  };

  blur = (): void => {
    if (this.state.isFocused) {
      this.setState({ isFocused: false });
    }
  };

  onDone = (): void => {
    this.blur();
  };

  handleClickOutside = (event: MouseEvent): void => {
    if (
      (this.wrapperRef && !this.wrapperRef.current?.contains(event.target as Node)) ||
      (event.target as HTMLElement).classList.contains('getaway-guests-picker__fixed-bg')
    ) {
      this.blur();
    }
  };

  componentDidMount(): void {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  render() {
    const { isFocused } = this.state;
    const {
      getawayBookingStore,
      loginStore,
      updateAdultsCount,
      updateYoungerChildrenCount,
      updateMiddleChildrenCount,
      updateOlderChildrenCount,
    } = this.props;
    const { account } = loginStore;

    const { adultsCount, youngerChildrenCount, middleChildrenCount, olderChildrenCount } =
      getawayBookingStore;

    const kidsCount = youngerChildrenCount + middleChildrenCount + olderChildrenCount;

    const generalBorderRadius = account?.generalBorderRadius;
    const style = !isEmpty(generalBorderRadius) ? { borderRadius: generalBorderRadius } : {};  

    return (
      <div className="getaway-guests-picker" ref={this.wrapperRef}>
        <div
          className={`getaway-guests-picker__wrapper ${isFocused ? 'selected' : ''}`}
          onClick={this.focus}
          style={style}
        >
          <div className="getaway-guests-picker__left">
            <div className="getaway-guests-picker__icon">
              <ManBlueSvg />
            </div>
          </div>
          <div className="getaway-guests-picker__right">
            <div className="getaway-guests-picker__label">
              <FormattedMessage id="guest" values={{ count: TWO }} />
            </div>
            <div className="getaway-guests-picker__header">
              {adultsCount} <FormattedMessage id="adult" values={{ count: adultsCount }} />
              {kidsCount ? (
                <>
                  , {kidsCount} <FormattedMessage id="child" values={{ count: kidsCount }} />
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className="getaway-guests-picker__dropdown"
          style={{ display: isFocused ? 'block' : 'none' }}
        >
          <div className="getaway-guests-picker__close-icon" onClick={this.blur}>
            <CloseSvg />
          </div>
          <div className="getaway-guests-picker__list-wrapper" ref={this.listRef}>
            <GetawayGuests
              getawayBookingStore={getawayBookingStore}
              updateAdultsCount={updateAdultsCount}
              updateYoungerChildrenCount={updateYoungerChildrenCount}
              updateMiddleChildrenCount={updateMiddleChildrenCount}
              updateOlderChildrenCount={updateOlderChildrenCount}
            />
          </div>
          <div className="getaway-guests-picker__footer">
            <BlueButton onClick={this.onDone}>
              <FormattedMessage id="done" />
            </BlueButton>
          </div>
        </div>
        {isFocused && <div className="getaway-guests-picker__fixed-bg" />}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    getawayBookingStore: state.getawayBookingStore,
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setGetawayBookInfo: getawayBookingActions.setGetawayBookInfo,
  updateAdultsCount: getawayBookingActions.updateAdultsCount,
  updateYoungerChildrenCount: getawayBookingActions.updateYoungerChildrenCount,
  updateMiddleChildrenCount: getawayBookingActions.updateMiddleChildrenCount,
  updateOlderChildrenCount: getawayBookingActions.updateOlderChildrenCount,
};

export const GetawayGuestsPiker = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(GetawayGuestsPikerComponent));
