
import React from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';

import './style.scss';

interface IProps extends RouteComponentProps {
  title: string;
  message: string;
}

function LifeStylePanelComponent(props: IProps) {
  const { title, message } = props;

  return (
    <div className="lifestyle-panel">
      <div className="lifestyle-panel__content">
        <div className="lifestyle-panel__title">
          <label><FormattedMessage id={title} /></label>
        </div>
        <label className="lifestyle-panel__message">
          <FormattedMessage
            id={message}
            values={{ 
              a: (message: string) => (<a href="https://cloud.visit.capital/lifestyles/BestRateClaimForm.pdf" target="_blank">{message}</a>),
              b: (message: string) => (<strong className="lifestyle-panel__message-remark">{message}</strong>),
            }}
          />
        </label>
      </div>
    </div>
  );
}

export const LifeStylePanel = connect()(withRouter(LifeStylePanelComponent));
