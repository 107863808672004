import React, { RefObject } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Checkbox, Form, Input, Select } from 'antd';
import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';
import { FormInstance } from 'antd/lib/form';
import { isEmpty } from 'lodash';

import { IGetawayBookInfo, ContactMe, BestTime } from '@share/common-types';
import { setNumberPhone, updateGetawayBookInfo } from '@utils';
import { checkInLatinLetters, mailFormat } from '@share/utils';
import { getawayBookingActions, IGetawayBookingState } from '@store/slices';
import { ILoginState, IMenuState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { countriesCode } from '@share/constants';

import './style.scss';

interface IMapStateToProps {
  getawayBookingStore: IGetawayBookingState;
  menuStore: IMenuState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setGetawayBookInfo: (getawayBookInfo: IGetawayBookInfo) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  formRef: RefObject<FormInstance>;
  onChangeSomeoneElse: (e: { target: CheckboxChangeEventTarget }) => void;
  isSomeoneElse: boolean;
}

const ZERO = 0;
const MAX_LENGTH_NAME = 25;
const MAX_LENGTH_EMAIL = 65;
const MAX_LENGTH_NUMBER = 12;

export class GetawayContactInfoComponent extends React.Component<IProps> {
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  onChangeFirstName = (e: { target: HTMLInputElement }): void => {
    const { getawayBookInfo } = this.props.getawayBookingStore;
    this.props.setGetawayBookInfo(
      updateGetawayBookInfo(getawayBookInfo, { firstName: e.target.value }) as IGetawayBookInfo,
    );
  };

  onChangeLastName = (e: { target: HTMLInputElement }): void => {
    const { getawayBookInfo } = this.props.getawayBookingStore;
    this.props.setGetawayBookInfo(
      updateGetawayBookInfo(getawayBookInfo, { lastName: e.target.value }) as IGetawayBookInfo,
    );
  };

  onChangeEmail = (e: { target: HTMLInputElement }): void => {
    const { getawayBookInfo } = this.props.getawayBookingStore;
    this.props.setGetawayBookInfo(
      updateGetawayBookInfo(getawayBookInfo, { email: e.target.value }) as IGetawayBookInfo,
    );
  };

  onChangePhoneCountryCode = (value: string): void => {
    const { getawayBookInfo } = this.props.getawayBookingStore;
    this.props.setGetawayBookInfo(
      updateGetawayBookInfo(getawayBookInfo, { phoneCountryCode: value }) as IGetawayBookInfo,
    );
  };

  onChangePhone = (e: { target: HTMLInputElement }): void => {
    const { getawayBookInfo } = this.props.getawayBookingStore;
    this.props.setGetawayBookInfo(
      updateGetawayBookInfo(getawayBookInfo, {
        phone: setNumberPhone(e.target.value),
      }) as IGetawayBookInfo,
    );
  };

  onFocusPhone = (): void => {
    this.props.formRef.current?.setFieldsValue({
      phone: this.props.getawayBookingStore.getawayBookInfo.phone,
    });
  };

  onChangeContactMe = (value: string): void => {
    const { getawayBookInfo } = this.props.getawayBookingStore;
    this.props.setGetawayBookInfo(
      updateGetawayBookInfo(getawayBookInfo, { preferredContact: value }) as IGetawayBookInfo,
    );
  };

  onChangeBestTime = (value: string): void => {
    const { getawayBookInfo } = this.props.getawayBookingStore;
    this.props.setGetawayBookInfo(
      updateGetawayBookInfo(getawayBookInfo, { preferredTime: value }) as IGetawayBookInfo,
    );
  };

  getPopupContainer = (): HTMLElement => {
    return (this.wrapperRef ? this.wrapperRef.current : document.body) as HTMLElement;
  };

  onFocus = (e: { target: HTMLInputElement }): void => {
    this.props.formRef.current?.setFieldsValue({ email: e.target.value });
  };

  render(): React.ReactNode {
    const { Option } = Select;
    const { intl, getawayBookingStore, loginStore, onChangeSomeoneElse, isSomeoneElse } = this.props;
    const { getawayBookInfo } = getawayBookingStore;
    const { account } = loginStore;


    const generalBorderRadius: any = account?.generalBorderRadius ? account?.generalBorderRadius : null;
    const style = !isEmpty(generalBorderRadius) ? { borderRadius: generalBorderRadius } : {};  

    return (
      getawayBookInfo && (
        <div className="getaway-contact-info" ref={this.wrapperRef}>
          <p className="getaway-contact-info__header secondary-font">
            <FormattedMessage id="contact.info" />
          </p>
          <Checkbox
            className="getaway-contact-info__checkbox"
            onChange={onChangeSomeoneElse}
            checked={isSomeoneElse}
          >
            {' '}
            <FormattedMessage id="booking.someone.else" />
          </Checkbox>
          <div className="getaway-contact-info__block-wrapper">
            <Form.Item
              name="firstname"
              rules={[
                {
                  required: true,
                  max: MAX_LENGTH_NAME,
                  pattern: checkInLatinLetters,
                  message: intl.formatMessage({ id: 'error.message.contact.name' }),
                },
              ]}
            >
              <div className="getaway-contact-info__name-input-wrapper" style={style}>
                <span className="getaway-contact-info__input-label">
                  <FormattedMessage id="first.name" />
                </span>
                <div className="getaway-contact-info__input">
                  <Input
                    placeholder={intl.formatMessage({ id: 'first.name' })}
                    value={getawayBookInfo.firstName}
                    maxLength={MAX_LENGTH_NAME}
                    onChange={(e) => {
                      this.onChangeFirstName(e);
                    }}
                  />
                </div>
              </div>
            </Form.Item>
            <Form.Item
              name="lastname"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  max: MAX_LENGTH_NAME,
                  pattern: checkInLatinLetters,
                  message: intl.formatMessage({ id: 'error.message.contact.name' }),
                },
              ]}
            >
              <div className="getaway-contact-info__name-input-wrapper" style={style}>
                <span className="getaway-contact-info__input-label">
                  <FormattedMessage id="last.name" />
                </span>
                <div className="getaway-contact-info__input">
                  <Input
                    placeholder={intl.formatMessage({ id: 'last.name' })}
                    value={getawayBookInfo.lastName}
                    maxLength={MAX_LENGTH_NAME}
                    onChange={(e) => {
                      this.onChangeLastName(e);
                    }}
                  />
                </div>
              </div>
            </Form.Item>
          </div>
          <div className="getaway-contact-info__rule">
            <Form.Item
              name="email"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  pattern: new RegExp(mailFormat),
                  message: intl.formatMessage({ id: 'error.message.email.validation' }),
                },
              ]}
            >
              <div className="getaway-contact-info__name-input-wrapper" style={style}>
                <span className="getaway-contact-info__input-label">
                  <FormattedMessage id="email" />
                </span>
                <div className="getaway-contact-info__input">
                  <Input
                    placeholder={intl.formatMessage({ id: 'email.address' })}
                    value={getawayBookInfo.email}
                    maxLength={MAX_LENGTH_EMAIL}
                    onChange={this.onChangeEmail}
                    onFocus={this.onFocus}
                  />
                </div>
              </div>
            </Form.Item>
          </div>
          <Form.Item
            name={'phone'}
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'error.message.site.phone' }),
              },
              {
                max: MAX_LENGTH_NUMBER,
                message: intl.formatMessage({ id: 'error.message.number.must.be' }),
              },
            ]}
          >
            <div className="getaway-contact-info__name-input-wrapper" style={style}>
              <span className="getaway-contact-info__input-label">
                <FormattedMessage id="mobile.phone" />
              </span>
              <div className="getaway-contact-info__input">
                <Input.Group compact>
                  <Form.Item
                    name={['phone', 'code']}
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <div>
                      <Select
                        defaultValue={`${countriesCode[ZERO].isoCode} ${countriesCode[ZERO].dialCode}`}
                        onChange={(value) => {
                          this.onChangePhoneCountryCode(value);
                        }}
                        getPopupContainer={this.getPopupContainer}
                      >
                        {countriesCode.map((code, index) => (
                          <Option key={index} value={code.dialCode}>
                            {code.isoCode} {code.dialCode}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </Form.Item>
                  <Form.Item
                    name={['phone', 'number']}
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <div className="getaway-contact-info__phone_number">
                      <Input
                        placeholder={intl.formatMessage({ id: 'phone.number' })}
                        onChange={(e) => {
                          this.onChangePhone(e);
                        }}
                        value={getawayBookInfo.phone}
                        maxLength={MAX_LENGTH_NUMBER}
                        onFocus={this.onFocusPhone}
                      />
                    </div>
                  </Form.Item>
                </Input.Group>
              </div>
            </div>
          </Form.Item>
          <Form.Item name="contactme">
            <div className="getaway-contact-info__name-input-wrapper" style={style}>
              <span className="getaway-contact-info__input-label">
                <FormattedMessage id="how.can.we.reach.you" />
              </span>
              <div className="getaway-contact-info__input select">
                <div>
                  <Select
                    defaultValue={`${ContactMe[ZERO]}`}
                    onChange={(value) => {
                      this.onChangeContactMe(value);
                    }}
                    getPopupContainer={this.getPopupContainer}
                  >
                    {ContactMe.map((item, index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </Form.Item>
          <Form.Item name="besttime">
            <div className="getaway-contact-info__name-input-wrapper" style={style}>
              <span className="getaway-contact-info__input-label">
                <FormattedMessage id="what.is.the.best.time.to.reach.you" />
              </span>
              <div className="getaway-contact-info__input select">
                <div>
                  <Select
                    defaultValue={`${BestTime[ZERO]}`}
                    onChange={(value) => {
                      this.onChangeBestTime(value);
                    }}
                    getPopupContainer={this.getPopupContainer}
                  >
                    {BestTime.map((item, index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </Form.Item>
        </div>
      )
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    getawayBookingStore: state.getawayBookingStore,
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setGetawayBookInfo: getawayBookingActions.setGetawayBookInfo,
};

export const GetawayContactInfo = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(GetawayContactInfoComponent));
