import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { ConfirmedSvg } from '@assets';
import { RootState } from '@share/utils';
import { IGetawayBookingState } from '@store/slices';
import './style.scss';

interface IMapStateToProps {
  getawayBookingStore: IGetawayBookingState;
}

interface IProps extends IMapStateToProps {}

export class GetawaySuccessComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <div className="getaway-success">
        <ConfirmedSvg />
        <p className="getaway-success__title-info secondary-font">
          <FormattedMessage id="thank.you" />!
        </p>
        <p className="getaway-success__title secondary-font">
          <FormattedMessage id="we.have.received.your.request" />
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    getawayBookingStore: state.getawayBookingStore,
  };
};

export const GetawaySuccess = connect(mapStateToProps)(GetawaySuccessComponent);
