import { v4 } from 'uuid';
import { IRoom, IGuest } from '@share/common-types';

export const getEmptyKid = (): IGuest => {
  return {
    id: v4(),
    age: undefined,
  };
};

export const guestsCount = (room: IRoom[]): number => {
  const defaultChildrenCount = 0;
  const startCount = 0;
  const adultsCount: number = room.reduce((res: number, { adultsCount }) => {
    return res + adultsCount;
  }, startCount);
  const childrenCount: number = room.reduce((res: number, { kids }) => {
    return res + kids.length;
  }, startCount);

  return adultsCount + (childrenCount ? childrenCount : defaultChildrenCount);
};
