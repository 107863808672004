import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { BlueButton } from '@share/components';
import { Routes } from '@share/constants';

import { ILoginState, IMenuState } from '@share/store/slices';
import { connect } from 'react-redux';
import { RootState } from '@share/utils';

import TutorialVideosImg from '@assets/images/tutorial-videos.png';

import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
}

interface IProps extends RouteComponentProps, IMapStateToProps {}

function TutorialVideosComponent(props: IProps) {

  const { loginStore, menuStore } = props;
  const { account } = loginStore;
  const { items, loading } = menuStore;

  if (items?.isRemoveMemberVideos || loading) {
    return null;
  }

  const goToVideos = () => {
    props.history.push(`/${account?.name}${Routes.MemberVideos}`);
  };

  const styles = account?.mainBackgroundColor ? { background: account?.mainBackgroundColor } : {};

  return (
    <div className="tutorial-videos" style={styles}>
      <div className="tutorial-videos__wrapper">
        <div className="tutorial-videos__info-wrapper">
          <p className="tutorial-videos__title secondary-font">
            <FormattedMessage id="member.tutorial.videos" />
          </p>
          <p className="tutorial-videos__description">
            <FormattedMessage id="get.the.most.out.of.your.membership" />
          </p>
          <div className="tutorial-videos__btn-wrapper">
            <BlueButton onClick={goToVideos} reverse>
              <FormattedMessage id="watch.videos" />
              <FontAwesomeIcon icon={faArrowRight} />
            </BlueButton>
          </div>
        </div>
        <div className="tutorial-videos__img-wrapper">
          <img src={TutorialVideosImg} alt="watch.videos" />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    menuStore: state.navigationMenuStore,
  };
};

const TutorialVideosWithRouter = withRouter(TutorialVideosComponent);

export const TutorialVideos = connect(mapStateToProps)(TutorialVideosWithRouter);
