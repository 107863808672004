import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ICustomGetawaysList } from '@share/common-types';

import './style.scss';

interface IProps {
  getawaysInfoList: ICustomGetawaysList[];
}

export class GetawayInfo extends React.Component<IProps> {
  render(): React.ReactNode {
    const { getawaysInfoList } = this.props;

    return (
      <div className="getaway__info">
        <div className="getaway__container">
          <h2 className="getaway__info_title secondary-font">
            <FormattedMessage id="getaway.info.title" />
          </h2>
          <p className="getaway__info_subtitle secondary-font">
            <FormattedMessage id="getaway.info.subtitle" />
          </p>
          <p className="getaway__info_text">
            <FormattedMessage id="getaway.info.text" />
          </p>
          <p className="getaway__info_text">
            <FormattedMessage id="getaway.info.paragraph" />
          </p>
          <div className="getaway__info-list">
            {getawaysInfoList.map(({ title, text }, index) => (
              <div key={`${index}-${title}`} className="getaway__info-item">
                <p className="getaway__info-item_title secondary-font">
                  <FormattedMessage id={title} values={{br: () => (<br/>)}} />
                </p>
                <p className="getaway__info-item_text">
                  <FormattedMessage id={text} values={{br: () => (<br/>)}} />
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
