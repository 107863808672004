import React from 'react';
import ReactGA from 'react-ga4';

import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { LabeledValue, SelectValue } from 'antd/lib/select';

import { WalletSearchSlider } from '@components';
import { CustomSelect } from '@share/components';
import {
  ILoginState,
  ICarsState,
  ICarsDatesState,
  carsActions,
  ICarsFiltersState,
  carsFiltersActions,
  loginActions,
} from '@share/store/slices';
import { RootState, UrlUtils } from '@share/utils';
import { IClientCash } from '@share/common-types';
import { DEFAULT_MEDIA_POINT, getSortByCodeByValue, LIFE_STYLE_NAME_PARAM, SortTypes } from '@share/constants';
import { getCarsSortOptions, GetLifeStyleStores } from '@utils';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import './style.scss';

interface IMapStateToProps {
  carsStore: ICarsState;
  carsDatesStore: ICarsDatesState;
  carsFiltersStore: ICarsFiltersState;

  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setSortBy: (value: string) => void;
  setLifeStyle: (value: string) => void;
  setSelectedCarsSearchClientCash: (selectedClientCash: IClientCash) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps, RouteComponentProps {
  isMobileFilter: boolean;

  onFiltersOrSortChange: (isStoreChange?: boolean) => void;
}

class CarsResultTopComponent extends React.Component<IProps> {

  getSortOptions = (): LabeledValue[] => {
    const { loginStore } = this.props;
    const { account } = loginStore;
    const { hasQuick, isB2C, walletWalletSavings } = account;
    return getCarsSortOptions(hasQuick, isB2C, walletWalletSavings);
  };

  onSortChange = (value: SelectValue) => {
    const valueStr = value as string;
    this.props.setSortBy(valueStr);

    const { loginStore } = this.props;
    const { account } = loginStore;

    const action = getSortByCodeByValue(valueStr);
    if (action) {
      ReactGA.event({
        category: account?.name as string,
        action: `${action}_${account?.name.toUpperCase()}`,
        label: `User selected ${action} sort on result`,
        nonInteraction: false,
      });
    }

    return !this.props.isMobileFilter && this.props.onFiltersOrSortChange();
  };

  onLifeStyleChange = (value: SelectValue) => {
    const valueStr = value as string;
    this.props.setLifeStyle(valueStr);

    UrlUtils.removeFromUrl(LIFE_STYLE_NAME_PARAM);
    UrlUtils.setUrl(LIFE_STYLE_NAME_PARAM, valueStr);

    return !this.props.isMobileFilter && this.props.onFiltersOrSortChange(true);
  };

  handleOnSelectClientCash = (selectedSearchClientCash: number) => {
    const { carsStore } = this.props;
    const { selectedCarsSearchClientCash } = carsStore;

    const clientCash = { ...selectedCarsSearchClientCash, selectedSearchClientCash };
    this.props.setSelectedCarsSearchClientCash(clientCash);
  }

  render(): React.ReactNode {
    const { carsStore, loginStore, carsFiltersStore } = this.props;
    const { isSessionExpired, selectedCarsSearchClientCash } = carsStore;
    const { sortBy } = carsFiltersStore;
    const { account, lifeStyle } = loginStore;

    const isCapital = account?.isCapitalVacations;
    const isCapitalAgent = account?.isCapitalVacationsAgent;

    const walletHasSliderOnSearch = account?.walletHasSliderOnSearch;
        
    const isMobile = document.body.offsetWidth <= DEFAULT_MEDIA_POINT;

    return (
      <>
        <div className="result-wrapper__top-section">
          <div className="col-12">
            <div className="row" style={{ justifyContent: 'space-between' }}>
              <div className={((isCapital && !isCapitalAgent) ? isMobile ? 'col-md-8' : 'col-md-5' : 'col-md-6')}>
                <p className="result-wrapper__top-header">
                  <FormattedMessage
                    id="cars.found"
                    values={{ count: carsStore?.counters?.totalFiltered }}
                  />
                </p>
              </div>

              {(isCapital && !isCapitalAgent) ? (
                <div className={isMobile ? 'col-md-4 col-sm-4' : 'col-md-3 col-sm-3'}>
                  <div className="result-wrapper__sort-select capital-vacations">
                    <span className="result-wrapper__sort-label">
                      <FormattedMessage id="lifestyle.change" />{' '}
                    </span>
                    <CustomSelect
                      disabled={isSessionExpired}
                      value={lifeStyle}
                      onChange={this.onLifeStyleChange}
                      options={GetLifeStyleStores()}
                    />
                  </div>
                </div>) : null}

              {!isMobile ? (
                <div className="col-md-4">
                  <div className="result-wrapper__sort-select">
                    <span className="result-wrapper__sort-label">
                      <FormattedMessage id="sort.by" />{' '}
                    </span>
                    <CustomSelect
                      disabled={isSessionExpired}
                      value={sortBy}
                      onChange={this.onSortChange}
                      options={this.getSortOptions()}
                    />
                  </div>
                </div>) : null}
            </div>
          </div>
        </div>

        {walletHasSliderOnSearch ? (
          <div className="result-wrapper__top-section" style={{ backgroundColor: '#FFFFFF', marginBottom: '20px', marginTop: (isCapital ? '8px' : '0px'), borderRadius: (isCapital ? '0px' : '5px'), minHeight: 'unset', border: (isCapital ? '3px solid #006098' : '1px solid #D2D2D2') }}>
            <WalletSearchSlider
              display={true}
              selectedClientCash={selectedCarsSearchClientCash?.selectedSearchClientCash}
              onSelectClientCash={this.handleOnSelectClientCash}
              titleLabel="cars.wallet.client.cash.want_use"
            />
          </div>) : null}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    carsStore: state.carsStore,
    carsDatesStore: state.carsDatesStore,
    carsFiltersStore: state.carsFiltersStore,

    loginStore: state.loginStore,    
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  setSortBy: (value: string) => {
    dispatch(carsFiltersActions.setSortBy(value as SortTypes));
  },
  setLifeStyle: (value: string) => {
    dispatch(loginActions.setLifeStyle(value));
  },
  setSelectedCarsSearchClientCash: (selectedClientCash: IClientCash) => {
    dispatch(carsActions.setSelectedCarsSearchClientCash(selectedClientCash));
  },
});


export const CarsResultTop = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(CarsResultTopComponent)));
