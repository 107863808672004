import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IRoomsContent } from '@common-types';
import {
  SmokingVacSvg,
  SmokingOffSvg,
  PetsSvg,
  PetsOffSvg
} from '@assets';
import './style.scss';

interface IProps {
  room: IRoomsContent;
}

class PropertiesComponent extends React.Component<IProps> {

  render(): React.ReactNode {
    const { room } = this.props;

    return (
      <div className="properties">
        <h4 className="properties__title secondary-font"><FormattedMessage id="tab.property.rules" /></h4>

        <div className="properties__properties-list">
          <div className="col-12">
            <span style={{ marginRight: '2em' }}>
              <span style={{ marginRight: '8px' }}>{room.petsAllowed ? <PetsSvg size='20' /> : <PetsOffSvg size='20' />}</span>
              <FormattedMessage id="pets.allowed" />: <FormattedMessage id={room.petsAllowed ? "yes" : "no"} />
            </span>
            <span>
              <span style={{ marginRight: '8px' }}>{room.nonSmoking ? <SmokingVacSvg size='20' /> : <SmokingOffSvg size='20' />}</span>
              <FormattedMessage id="smoking.allowed" />: <FormattedMessage id={room.nonSmoking ? "yes" : "no"} />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export const Properties = PropertiesComponent;
