import React from 'react';
import parse from 'html-react-parser';
import moment from 'moment';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { Tooltip } from 'antd';

import { IClientCash, ICondoGuests, ICondoUnit, ICondoUnits } from '@share/common-types';
import { DATE_FORMAT_UNIT } from '@constants';
import { getReserveUnitUrl, getSelectedCurrency } from '@share/utils';
import { renderNumber, getNights, formatDateCheckInOut, formatDateCheckInOutWithLabel, creatingArrayWithNumber, UrlUtils } from '@share/utils';
import { BlueButton } from '@share/components';
import { Currency, ModalCancellationPolicies, WalletMessage } from '@components';
import { RootState } from '@share/utils';
import { IUnitsSearchState, setSelectedCondoReviewClientCash, setSelectedCondoReviewClientCashStr } from '@store/slices';
import { ICondoDetailsState, ICondosState, condosActions } from '@share/store/slices';
import { ILoginState } from '@share/store/slices';
import { CondoMatchTypeEnum } from '@share/common-types';
import { CONDO_DATES_LABEL, CONDO_IS_FLEXIBLE_LABEL } from '@share/constants';
import { WalletPriceType, getWalletPrices } from '@utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { InfoSvg, ManDarkSvg } from '@assets';

import { ModalUnitDetails } from '../../modal-unit-details';

import './style.scss';
import { RefundableEnum } from '@common-types';

interface IMapStateToProps {
  condosStore: ICondosState;
  condoGuestsStore: ICondoGuests;
  condoDetailsStore: ICondoDetailsState;

  unitsSearchStore: IUnitsSearchState;

  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setSelectedCondoSearchClientCash: (selectedClientCahs: IClientCash) => void;
  setSelectedCondoReviewClientCash: (selectedClientCash: number) => void;
  setSelectedCondoReviewClientCashStr: (clientCash: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  unit: ICondoUnit;
  condoId: number;
  isOnlyAlternative: boolean;
  isWalletSearch: boolean;
}

interface IState {
  isModalCancellationPoliciesVisible: boolean;
  isModalVisible: boolean;
  isStrict: boolean;
  endDate: string;
  startDate: string;
}

class CondoUnitRowComponent extends React.Component<IProps, IState> {
  state: IState = {
    isModalCancellationPoliciesVisible: false,
    isModalVisible: false,
    isStrict: false,
    startDate: null,
    endDate: null,
  };

  componentDidMount(): void {
    const values = UrlUtils.getValues();
    if (values[CONDO_IS_FLEXIBLE_LABEL] && values[CONDO_DATES_LABEL]) {
      const { endDate, startDate } = values[CONDO_DATES_LABEL] as { startDate: string; endDate: string };
      const isStrict: boolean = values[CONDO_IS_FLEXIBLE_LABEL] === '0' && !isEmpty(startDate) && !isEmpty(endDate);

      const startDateFormatted = startDate ? get(startDate.split('T'), '[0]') : null;
      const endDateFormatted = endDate ? get(endDate.split('T'), '[0]') : null;

      this.setState({ isStrict, endDate: endDateFormatted, startDate: startDateFormatted });
    }
  }

  changeMatchTypeForUnit = (unit: ICondoUnit, units: ICondoUnits[]) => {
    return units.map((item) => {
      return {
        ...item,
        availibilities: item.availibilities.map((availibility) => {
          if (
            unit.dateRange.from === availibility.dateRange.from &&
            unit.dateRange.to === availibility.dateRange.to
          ) {
            return { ...availibility, matchType: CondoMatchTypeEnum.Exact };
          }
          
          return availibility;
        }),
      };
    });
  };

  showCancellationPolicies = (): void => {
    this.setState({
      isModalCancellationPoliciesVisible: true,
    });
  };

  handleCancelModalCancellationPolicies = (): void => {
    this.setState({ isModalCancellationPoliciesVisible: false });
  };

  handleCancel = (): void => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };

  handleReviewCondo = async (accountName: string, condoId: number, unitId: string, walletPrices: any) => {
    const { loginStore, condosStore, history } = this.props;
    const { selectedCondoSearchClientCash } = condosStore;
    const { account } = loginStore;

    const displayWalletSavings = account?.walletWalletSavings && (walletPrices?.priceSavings > 0 || walletPrices?.maxClientCashAmountAllow > 0) && !!walletPrices?.maxClientCashAmountAllow;

    const selectedPropertyClientCash = displayWalletSavings ? walletPrices?.maxClientCashPermited : selectedCondoSearchClientCash?.selectedPropertyClientCash;

    await this.props.setSelectedCondoReviewClientCash(selectedPropertyClientCash);
    await this.props.setSelectedCondoReviewClientCashStr(selectedPropertyClientCash ? selectedPropertyClientCash.toString() : '');
    await this.props.setSelectedCondoSearchClientCash({ ...selectedCondoSearchClientCash, selectedPropertyReviewClientCash: selectedPropertyClientCash });

    history.push(getReserveUnitUrl(accountName, condoId, unitId));
  }

  render(): React.ReactNode {
    const { unit, condoId, isWalletSearch, loginStore, condoDetailsStore, condoGuestsStore, condosStore } = this.props;

    const { isModalCancellationPoliciesVisible, isModalVisible, isStrict, endDate, startDate } = this.state;
    const { dateRange, price, publicPrice, unitId, name, cancellationPolicyText, bathroomsCount, bedroomsCount, maxOccupancy, maxWalletClientCash, refundability } = unit;
    const { account, userWalletData } = loginStore;
    const { condo } = condoDetailsStore;
    const { selectedCondoSearchClientCash } = condosStore;

    const baseClassName = 'unit-row__left-block-text';
    const baseClassNameMobile = `${baseClassName}-mobile`;
    const baseClassNameDateOnlyAlternative = `${baseClassName} dates}`;
    const baseClassNameOnlyAlternative = `${baseClassName}`;
    const baseClassNameOnlyAlternativeMobile = `${baseClassNameMobile}`;

    const fromDateFormatted = dateRange.from ? moment(dateRange.from, 'yyyy-MM-DDTHH:mm:ss').format('yyyy-MM-DD') : null;
    const toDateFormatted = dateRange.from ? moment(dateRange.to, 'yyyy-MM-DDTHH:mm:ss').format('yyyy-MM-DD') : null;

    const isSameDate = isStrict ? startDate === fromDateFormatted && endDate === toDateFormatted : false;

    const factor = Math.pow(10, 0);
    const savings = (!!price && !!publicPrice && publicPrice - price >= 0) ? publicPrice - price : null;
    const savingsPercent = !!savings && !!publicPrice ? Math.round(((savings / publicPrice) * 100) * factor) / factor : 0;

    const priceInt = price;
    const maxWalletClientCashInt = maxWalletClientCash;

    const walletPrices = getWalletPrices(account, userWalletData, selectedCondoSearchClientCash, priceInt, maxWalletClientCashInt, WalletPriceType.Details);
    const finalPriceCalculated = priceInt - (walletPrices?.clientCashAmountApplied ? walletPrices?.clientCashAmountApplied : 0);
    const finalPriceMax = priceInt - (walletPrices?.maxClientCashAmountAllow ? walletPrices?.maxClientCashAmountAllow : 0);

    const currency = getSelectedCurrency(account);

    const displayWalletSavings = account?.walletWalletSavings && (walletPrices?.priceSavings > 0 || walletPrices?.maxClientCashAmountAllow > 0) && !!walletPrices?.maxClientCashAmountAllow;

    const finalPrice = displayWalletSavings ? finalPriceMax : finalPriceCalculated;
    
    const isRefundability = refundability === RefundableEnum.Refundable;

    return (
      <div className={`unit-row ${isSameDate ? 'same-dates' : ''}`}>
        {isSameDate ? (
          <>
            <div className="same-dates-information"></div>
            <div className="same-dates-information-icon">
              <Tooltip title={<FormattedMessage id="condos.your.selection" />} placement="top" >
                <FontAwesomeIcon icon={faCircleInfo} />
              </Tooltip>
            </div>
          </>) : null }
        <div className="unit-row__left-block">
          <p className={baseClassName} style={{ width: '220px' }}>
            <label>{name}</label>
            <label>
              <span className="units-search-unit__unit-details-text" style={{ fontWeight: 'normal' }} onClick={this.handleCancel}>
                <FormattedMessage id="more.info" />
              </span>
            </label>
          </p>
          <p className={baseClassNameMobile} style={{ marginBottom: '20px' }}>
            <label style={{ fontSize: '14px', fontWeight: 'bold' }}>{name}</label>
            <label>
              <span className="units-search-unit__unit-details-text" style={{ fontWeight: 'normal' }} onClick={this.handleCancel}>
                <FormattedMessage id="more.info" />
              </span>
            </label>
          </p>
          <ModalUnitDetails
              condo={condo.condoDetails}
              unit={unit}
              visible={isModalVisible}
              onCancel={this.handleCancel}
              walletPrices={walletPrices}
              adultsCount={condoGuestsStore?.adultsCount}
              childrenCount={condoGuestsStore?.kidsCount}
            />
        </div>

        <div className="unit-row__left-block">
          <p
            className={baseClassNameDateOnlyAlternative}
            style={{ minWidth: '180px' }}
          >
            <label>
              {parse(formatDateCheckInOutWithLabel(dateRange.from))} -{' '}
            </label>
            <label>
              {parse(formatDateCheckInOutWithLabel(dateRange.to))}
            </label>
          </p>
          <div className={baseClassNameOnlyAlternativeMobile}>
            <p className="unit-row__date-title">
              <FormattedMessage id="check.in" />:
            </p>
            <p className="unit-row__date-info">
              {formatDateCheckInOut(dateRange.from, DATE_FORMAT_UNIT)}
            </p>
          </div>
          <div className={baseClassNameOnlyAlternativeMobile} >
            <p className="unit-row__date-title">
              <FormattedMessage id="check.out" />:
            </p>
            <p className="unit-row__date-info">
              {formatDateCheckInOut(dateRange.to, DATE_FORMAT_UNIT)}
            </p>
          </div>
        </div>

        <div className="unit-row__left-block">
          <p
            className={baseClassNameOnlyAlternative}
            style={{ width: '90px' }}
          >
            <label>
              {getNights(dateRange.from, dateRange.to)}
            </label>
          </p>
          <div className={baseClassNameOnlyAlternativeMobile}>
            <p className="unit-row__date-title">
              <FormattedMessage id="nights.title" />:
            </p>
            <p className="unit-row__date-info">
              <FormattedMessage
                id="nights"
                values={{ count: getNights(dateRange.from, dateRange.to) }}
              />
            </p>
          </div>
        </div>

        <div className="unit-row__left-block">
          <p className={baseClassNameOnlyAlternative} style={{ width: '90px' }}>
            <label>
              {bedroomsCount} / {bathroomsCount}
            </label>
          </p>
          <div className={baseClassNameOnlyAlternativeMobile} >
            <p className="unit-row__date-title">
              <FormattedMessage id="bedrooms.bathrooms" />:
            </p>
            <p className="unit-row__date-info">
                {bedroomsCount} / {bathroomsCount}
            </p>
          </div>
        </div>

        <div className="unit-row__left-block">
          <p className={baseClassNameOnlyAlternative} style={{ width: '70px' }}>
            <label>
              {maxOccupancy}
            </label>
          </p>
          <div className={baseClassNameOnlyAlternativeMobile}>
            <p className="unit-row__date-title">
              <FormattedMessage id="guests" />:
            </p>
            <p className="unit-row__date-info">
              {maxOccupancy}{'  '}
              {maxOccupancy > 0 && (
                <span className="unit-main-info__guests">
                  {creatingArrayWithNumber(maxOccupancy, 1).map((value, i) => (
                    <span className="unit-main-info__icon-wrapper" key={i}>
                      <ManDarkSvg />
                    </span>))}
                </span>)}
            </p>
          </div>
        </div>
        
        <div className="unit-row__price-total-wallet mobile">
          <WalletMessage
            price={priceInt}
            currency={currency}
            type={WalletPriceType.Details}
            isCondo
            isOneLine
          />
        </div>

        <div className="unit-row__right-block">
          <div className="unit-row__right-block-wrapper">
            <div className="unit-row__price">
              <div className="unit-row__price-total">
                {isWalletSearch ? (
                  <div className="unit-row__price-total-wallet desktop">
                    <WalletMessage
                      price={priceInt}
                      currency={currency}
                      type={WalletPriceType.Details}
                      isCondo
                      isShort
                      style={{ marginBottom: '10px' }}
                    />
                  </div>) : null}

                {(displayWalletSavings && walletPrices?.priceSavings > 0) ? (
                  <div className="unit-row__for-info">
                    <p className="unit-row__for-info-text public">
                      <Currency currency={currency} />{renderNumber(Math.floor(priceInt))}
                    </p>
                  </div>) : null}

                <p className="unit-row__price-total-text"><Currency currency={currency} />{renderNumber(Math.floor(finalPrice))}</p>
                <div className="unit-row__for-info">
                  <p className="unit-row__for-info-text">
                    <span className="unit-row__price-includes-taxes">
                      <FormattedMessage id="abbreviated.price.includes.taxes" />
                    </span>
                  </p>
                </div>
                {(!!savingsPercent && savingsPercent > 0 && !displayWalletSavings) ? (
                  <div className="unit-row__for-info">
                    <p className="unit-row__for-info-text savings">
                      <FormattedMessage id={account?.forceMemberWording ? 'member.save' : 'save'} /> {savingsPercent}%
                    </p>
                  </div>) : null}

                {(displayWalletSavings && walletPrices?.priceSavings > 0) ? (
                  <div className="unit-row__for-info">
                    <p className="unit-row__for-info-text savings">
                      <FormattedMessage id="save.up.to" values={{ number: walletPrices?.priceSavings ? walletPrices?.priceSavings : '<1' }} />
                    </p>
                  </div>) : null}
              </div>
            </div>
          </div>

          <div className="unit-row__right-block-wrapper refundable">
            <div className="unit-row__right-block">
              <div className="unit-row__refundable" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div
                  className={isRefundability ? 'unit-row__refundable-ref' : 'unit-row__refundable-nonref'}
                  onClick={this.showCancellationPolicies}
                >
                  <span>
                    <FormattedMessage id={isRefundability ? 'refundable' : 'non.refundable'} />
                  </span>
                  <InfoSvg />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="unit-row__right-block refundable">
          <div className="unit-row__refundable">
            <div
              className={isRefundability ? 'unit-row__refundable-ref' : 'unit-row__refundable-nonref'}
              onClick={this.showCancellationPolicies}
            >
              <span>
                <FormattedMessage id={isRefundability ? 'refundable' : 'non.refundable'} />
              </span>
              <InfoSvg />
            </div>
          </div>
        </div>

        <div className="unit-row__right-block">
          <div className="unit-row__action">
            <BlueButton onClick={() => this.handleReviewCondo(account?.name, condoId, unitId, walletPrices)}>
              <FormattedMessage id="reserve.unit" />
            </BlueButton>
          </div>
        </div>

        <ModalCancellationPolicies
          isForCondo={true}
          cancellationPoliciesText={cancellationPolicyText}
          visible={isModalCancellationPoliciesVisible}
          onCancel={this.handleCancelModalCancellationPolicies}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condosStore: state.condosStore,
    condoDetailsStore: state.condoDetailsStore,
    condoGuestsStore: state.condoGuestsStore,

    unitsSearchStore: state.unitsSearchStore,

    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setSelectedCondoSearchClientCash: condosActions.setSelectedCondoSearchClientCash,
  setSelectedCondoReviewClientCash,
  setSelectedCondoReviewClientCashStr,
};


export const CondoUnitRow = connect(mapStateToProps, mapDispatchToProps)(withRouter(CondoUnitRowComponent));
