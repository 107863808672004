import React from 'react';

export const SquareSvg: React.FunctionComponent = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 3.33333V12.6667C2 13.402 2.598 14 3.33333 14H12.6667C13.402 14 14 13.402 14 12.6667V3.33333C14 2.598 13.402 2 12.6667 2H3.33333C2.598 2 2 2.598 2 3.33333ZM12.668 12.6667H3.33333V3.33333H12.6667L12.668 12.6667Z"
      fill="#333333"
    />
    <path
      d="M9.99996 8.00032H11.3333V4.66699H7.99996V6.00033H9.99996V8.00032ZM7.99996 10.0003H5.99996V8.00032H4.66663V11.3337H7.99996V10.0003Z"
      fill="#4F4F4F"
    />
  </svg>
);

type SizeProps = {
  size?: string;
}

export const SquareFootageSvg: React.FunctionComponent<SizeProps> = ({ size }: SizeProps) => (
  <svg width={size || 16} height={size || 16} viewBox="8 5 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.7428 14.3499V18.3409L26.4186 17.3256V14.3499H24.7428ZM28.579 16.0168V13.4857C28.579 12.7698 27.9987 12.1895 27.2828 12.1895H23.8786C23.1627 12.1895 22.5823 12.7698 22.5823 13.4857V19.6498L16.9855 23.0406C16.4752 23.3497 16.3122 24.014 16.6213 24.5242C16.9305 25.0344 17.5947 25.1975 18.1049 24.8884L20.3102 23.5523V40.2501H19.5571C18.9605 40.2501 18.4769 40.7337 18.4769 41.3303C18.4769 41.9269 18.9605 42.4105 19.5571 42.4105H32.052C32.6486 42.4105 33.1322 41.9269 33.1322 41.3303C33.1322 40.7337 32.6486 40.2501 32.052 40.2501H30.9086V35.163L30.9074 35.1374C30.8983 34.9476 30.9528 34.6451 31.1103 34.4223C31.2252 34.2597 31.4534 34.0453 32.052 34.0453C32.6486 34.0453 33.1322 33.5616 33.1322 32.9651C33.1322 32.3685 32.6486 31.8848 32.052 31.8848C30.7871 31.8848 29.8857 32.4117 29.3459 33.1755C28.8572 33.8671 28.7276 34.6607 28.7482 35.2113V40.2501H22.4706V22.2435L32.6118 16.0996C33.122 15.7904 33.285 15.1262 32.9759 14.6159C32.6668 14.1057 32.0026 13.9427 31.4923 14.2518L28.579 16.0168ZM30.9086 23.8799H32.052C32.6486 23.8799 33.1322 23.3963 33.1322 22.7997C33.1322 22.2031 32.6486 21.7195 32.052 21.7195H30.0444C29.3285 21.7195 28.7482 22.2998 28.7482 23.0157V27.2428C28.7482 27.9587 29.3285 28.539 30.0444 28.539H32.052C32.6486 28.539 33.1322 28.0554 33.1322 27.4588C33.1322 26.8622 32.6486 26.3786 32.052 26.3786H30.9086V23.8799Z" fill="black" />
    <path d="M31.2341 14.6159C30.925 15.1261 31.088 15.7904 31.5983 16.0995L41.7394 22.2434V40.25H35.4619V35.2113C35.4825 34.6607 35.3528 33.8671 34.8641 33.1755C34.3243 32.4116 33.423 31.8848 32.158 31.8848C31.5614 31.8848 31.0778 32.3684 31.0778 32.965C31.0778 33.5616 31.5614 34.0452 32.158 34.0452C32.7567 34.0452 32.9848 34.2596 33.0998 34.4223C33.2572 34.6451 33.3117 34.9476 33.3027 35.1373L33.3014 35.163V40.25H32.158C31.5614 40.25 31.0778 40.7337 31.0778 41.3303C31.0778 41.9268 31.5614 42.4105 32.158 42.4105H44.6529C45.2495 42.4105 45.7331 41.9268 45.7331 41.3303C45.7331 40.7337 45.2495 40.25 44.6529 40.25H43.8998V23.5523L46.1051 24.8883C46.6153 25.1974 47.2796 25.0344 47.5887 24.5242C47.8978 24.0139 47.7348 23.3497 47.2245 23.0405L32.7177 14.2517C32.2075 13.9426 31.5432 14.1056 31.2341 14.6159ZM33.3014 23.8799H32.158C31.5614 23.8799 31.0778 23.3962 31.0778 22.7996C31.0778 22.2031 31.5614 21.7194 32.158 21.7194H34.1656C34.8815 21.7194 35.4619 22.2998 35.4619 23.0157V27.2427C35.4619 27.9586 34.8815 28.539 34.1656 28.539H32.158C31.5614 28.539 31.0778 28.0554 31.0778 27.4588C31.0778 26.8622 31.5614 26.3786 32.158 26.3786H33.3014V23.8799Z" fill="black" />
    <path d="M21.6975 45.4375C22.1194 45.8593 22.1194 46.5433 21.6975 46.9651L20.6826 47.9801H30.1461C30.7427 47.9801 31.2263 48.4637 31.2263 49.0603C31.2263 49.6569 30.7427 50.1405 30.1461 50.1405H20.6826L21.6975 51.1555C22.1194 51.5773 22.1194 52.2613 21.6975 52.6831C21.2757 53.105 20.5917 53.105 20.1699 52.6831L17.4636 49.9769C16.9574 49.4707 16.9574 48.6499 17.4636 48.1437L20.1699 45.4375C20.5917 45.0156 21.2757 45.0156 21.6975 45.4375Z" fill="black" />
    <path d="M43.0418 45.4375C42.62 45.8593 42.62 46.5433 43.0418 46.9651L44.0568 47.9801H26.7575C26.1609 47.9801 25.6773 48.4637 25.6773 49.0603C25.6773 49.6569 26.1609 50.1405 26.7575 50.1405H44.0568L43.0418 51.1555C42.62 51.5773 42.62 52.2613 43.0418 52.6831C43.4636 53.105 44.1476 53.105 44.5695 52.6831L47.2757 49.9769C47.7819 49.4707 47.7819 48.6499 47.2757 48.1437L44.5695 45.4375C44.1476 45.0156 43.4636 45.0156 43.0418 45.4375Z" fill="black" />
  </svg>
);
