
import React from 'react';
import ReactGA from 'react-ga4';

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import { Locales, LocalesCountry } from '@i18n';
import { D_CLICK_LANGUAGE, H_CLICK_LANGUAGE, R_CLICK_LANGUAGE, SELECTED_LANGUAGE_COUNRTY_LABEL } from '@share/constants';
import { IHotelsState, ILoginState, loginActions } from '@share/store/slices';
import { isDetailFromPath, isResultsFromPath, RootState } from '@share/utils';

import Spain from '@share/assets/images/flags/spain-flag-icon.png';
import US from '@share/assets/images/flags/us-flag-icon.png';
import Italy from '@share/assets/images/flags/italy-flag-icon.png';
import French from '@share/assets/images/flags/france-flag-icon.png';
import Portuguese from '@share/assets/images/flags/brazil-flag-icon.png';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
  hotelsStore: IHotelsState;
}

interface IMapDispatchToProps {
  setUserLanguage: (locale: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  onLanguageSelected: () => void;
}

class LanguagesComponent extends React.Component<IProps> {
  
  handleSelectLanguage = (locale: Locales, localesCountry: LocalesCountry) => {
    localStorage.setItem(SELECTED_LANGUAGE_COUNRTY_LABEL, localesCountry)
    this.props.setUserLanguage(locale);
    this.props.onLanguageSelected();

    const { hotelsStore, history } = this.props;
    const { hotels, isSearch } = hotelsStore;

    const isSearchPage = isResultsFromPath(history);
    const isDetailPage = isDetailFromPath(history);

    const isAfterSearchLoad = isSearch || (hotels && !!hotels.length);

    let key;
    if (isDetailPage) {
      key = D_CLICK_LANGUAGE;
    } else if (isSearchPage) {
      if (isAfterSearchLoad) {
        key = R_CLICK_LANGUAGE;
      } else {
        key = H_CLICK_LANGUAGE;
      }
    }

    ReactGA.event({
      category: this.props.loginStore.account?.name as string,
      action: `${key}_${this.props.loginStore.account?.name.toUpperCase()}`,
      label: `User clicked language/currency link`,
      nonInteraction: false,
    });

    window.location.reload();
  }


  renderItem = (title: string, flag: any, locale: Locales, localesCountry: LocalesCountry) => {
    return (
      <div className="col-md-4 region" onClick={() => this.handleSelectLanguage(locale, localesCountry)}>
        <div 
          className="modal-internalization__languages"
          style={{ backgroundImage: `url(${flag})` }}
        ></div>
        <FormattedMessage id={title} />
      </div>
    );
  }

  render(): React.ReactNode {
    return (
      <div className="language-modal">
        <label className="title"><FormattedMessage id="languages.title" /></label>

        <div className="container-fluid">
          <div className="row">
            {this.renderItem('languages.english', US, Locales.English, LocalesCountry.EnglishUS)}
            {this.renderItem('languages.spanish', Spain, Locales.Spanish, LocalesCountry.Spanish)}
            {this.renderItem('languages.french', French, Locales.French, LocalesCountry.FrenchFR)}
          </div>

          <div className="row">
            {this.renderItem('languages.italian', Italy, Locales.Italian, LocalesCountry.Italy)}
            {this.renderItem('languages.portuguese', Portuguese, Locales.Portuguese, LocalesCountry.PortugueseBR)}
          </div>
        </div>
      </div>);
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    hotelsStore: state.hotelsStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setUserLanguage: loginActions.setUserLanguage,
};

export const Languages = connect(mapStateToProps, mapDispatchToProps)(withRouter(LanguagesComponent));
