import React from 'react';
import parse from 'html-react-parser';
import ReactGA from 'react-ga4';

import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { HotelImagesSlider } from '../hotel-image-slider';
import { IImage, IAccount } from '@share/common-types';

import { FullScreenSvg, ImagesQuantitySvg, CheckSvg } from '@assets';
import { D_CLICK_IMAGES } from '@share/constants';

import NotImageImg from '@assets/images/not_image_room.png';

import './style.scss';

interface IProps {
  account: IAccount;
  images: IImage[];
  hotelName: string;
  hotelDescription: string[];
  additionalDescriptions: string[];
  hotelRooms: number;
  onClickScrollToRooms?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

interface IState {
  isModalVisible: boolean;
  startIndex: number;
  isEllipsis: boolean;
  showMoreLink: boolean;
}

const FIRST_ELEMENT_ARRAY = 0;
const MIN_IMAGE = 0;
const MAX_IMAGE = 4;
const ONE_SECOND = 1000;

const { Paragraph } = Typography;

export class HotelImagesDescription extends React.Component<IProps, IState> {
  state: IState = {
    isModalVisible: false,
    startIndex: 0,
    isEllipsis: true,
    showMoreLink: false,
  };

  showSlider = (index: number): void => {
    this.setState({ isModalVisible: true });
    this.setState({ startIndex: index });

    const { account } = this.props;
    ReactGA.event({
      category: account.name,
      action: `${D_CLICK_IMAGES}_${account.name.toUpperCase()}`,
      label: `User clicked an image on detail`,
      nonInteraction: false,
    });
  };

  handleCancel = (e: React.MouseEvent<HTMLDivElement>, isScrollToRooms = false): void => {
    if (isScrollToRooms) {
      this.props.onClickScrollToRooms(e);
    }

    this.setState({ isModalVisible: false });
  };

  showMore = (): void => {
    this.setState({ isEllipsis: !this.state.isEllipsis });
  };

  componentDidMount(): void {
    setTimeout(() => {
      const moreLink: HTMLLinkElement = document.querySelector(
        '.hotel-images__hotel-text .ant-typography-expand',
      );

      if (moreLink) {
        this.setState({ showMoreLink: true });
      }
    }, ONE_SECOND);
  }

  getOtherImages = (images: IImage[]): React.ReactNode =>
    images.map((img: IImage, i: number) => {
      if (i === MIN_IMAGE || i > MAX_IMAGE || !img || !img.url) {
        return;
      }
  
      return (
        <div key={i} className="hotel-images__other-image-wrapper">
          <img className="hotel-images__image" src={img.url} alt={img.description} />
          <div className="hotel-images__middle">
            <div
              className="hotel-images__btn-hover-wrapper"
              onClick={() => this.showSlider(Number(i))}
            >
              <FullScreenSvg />
              <span className="hotel-images__btn-hover-text">
                <FormattedMessage id="full.view" />
              </span>
            </div>
          </div>
        </div>
      );  
    });

  render(): React.ReactNode {
    const {
      images = [],
      hotelName,
      hotelDescription = [],
      hotelRooms,
    } = this.props;
    const { isModalVisible, startIndex, isEllipsis, showMoreLink } = this.state;

    const firstImage = images?.length ? images[FIRST_ELEMENT_ARRAY] : { url: NotImageImg, width: 450, description: 'not found image' };
    const hasImages = images?.length > 0;

    return (
      <div className="hotel-images">
        <div className="hotel-images__images-quantity">
          <ImagesQuantitySvg />
          <span className="hotel-images__images-quantity-text">{images.length}+</span>
        </div>

        <div className="hotel-images__wrapper">
          <div className="hotel-images__main-image-wrapper">
            <img
              className={`hotel-images__image ${hasImages ? '' : 'disabled-hover'}`}
              src={firstImage?.url? firstImage.url : NotImageImg}
              width={firstImage?.width? firstImage.width : 450}
              alt={firstImage?.description? firstImage.description : 'not found image'}
            />
            {!hasImages && (<div className="hotel-images__no-images">No images found</div>)}
            {hasImages && (<div className="hotel-images__middle">
              <div
                className="hotel-images__btn-hover-wrapper"
                onClick={() => this.showSlider(FIRST_ELEMENT_ARRAY)}
              >
                <FullScreenSvg />
                <span className="hotel-images__btn-hover-text">
                  <FormattedMessage id="full.view" />
                </span>
              </div>
            </div>)}
          </div>
          {hasImages && (<div className="hotel-images__other-images-wrapper">{this.getOtherImages(images)}</div>)}
        </div>

        {hasImages && (<HotelImagesSlider
          images={images}
          onCancel={this.handleCancel}
          visible={isModalVisible}
          hotelName={hotelName}
          startIndex={startIndex}
        />)}

        <div className="hotel-images__hotel-info">
          <div className="hotel-images__hotel-description">
            {!!hotelDescription.length && (
              <div className="hotel-images__hotel-text">
                <Paragraph ellipsis={isEllipsis ? { rows: 6, expandable: true } : false}>
                  {hotelDescription.map((hd, i) => <div key={i}><span style={{ marginRight: '0.5em' }}><CheckSvg /></span>{parse(hd)}</div>)}
                </Paragraph>

                {!!showMoreLink && (
                  <p className="hotel-images__read-more-button" onClick={this.showMore}>
                    {isEllipsis ? (
                      <FormattedMessage id="show.more" />
                    ) : (
                      <FormattedMessage id="show.less" />
                    )}
                  </p>
                )}
              </div>
            )}
          </div>
          
          {!!hotelRooms && (
            <div className="hotel-images__hotel-info-rooms">
              <p className="hotel-images__hotel-room-title">
                <FormattedMessage id="number.of.rooms" />:
              </p>
              <span className="hotel-images__rooms-quantity">{hotelRooms}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}
