import React from 'react';

import { ReservationStatusEnum } from '@share/common-types';
import { Status } from '../status';

import './style.scss';

interface IProps {
  status: string;
}

export class ReservationStatus extends React.Component<IProps> {
  getText = (): string => {
    const { status } = this.props;
    const map: any = {
      [ReservationStatusEnum.NotAvailable]: 'not_available',
      [ReservationStatusEnum.Confirmed]: 'confirmed',
      [ReservationStatusEnum.Cancelled]: 'cancelled',
      [ReservationStatusEnum.Pending]: 'pending',
      [ReservationStatusEnum.Unconfirmed]: 'unconfirmed',
      [ReservationStatusEnum.Error]: 'error',
      [ReservationStatusEnum.Deleted]: 'deleted',
      [ReservationStatusEnum.SoldOut]: 'sold-out',
      [ReservationStatusEnum.ToCheck]: 'to-check',
      [ReservationStatusEnum.ToCancel]: 'to-cancel',
      [ReservationStatusEnum.InProgress]: 'in-progress',
      [ReservationStatusEnum.Prebooking]: 'pre-booking',
    };
  
    return `bookings.booking.status.${map[status]}`;
  };

  getClass = (): string => {
    const { status } = this.props;
    const map: any = {
      [ReservationStatusEnum.NotAvailable]: 'not_available',
      [ReservationStatusEnum.Confirmed]: 'confirmed',
      [ReservationStatusEnum.Cancelled]: 'cancelled',
      [ReservationStatusEnum.Pending]: 'pending',
      [ReservationStatusEnum.Unconfirmed]: 'unconfirmed',
      [ReservationStatusEnum.Error]: 'error',
      [ReservationStatusEnum.Deleted]: 'deleted',
      [ReservationStatusEnum.SoldOut]: 'sold-out',
      [ReservationStatusEnum.ToCheck]: 'to-check',
      [ReservationStatusEnum.ToCancel]: 'to-cancel',
      [ReservationStatusEnum.InProgress]: 'in-progress',
      [ReservationStatusEnum.Prebooking]: 'pre-booking',
    };

    return map[status];
  };

  render(): React.ReactNode {
    if (this.props.status) {
      return <Status
        text={this.getText()}
        className={this.getClass()} />;
    }

    return null;
  }
}
