import React, { useMemo } from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ThunkDispatch } from 'redux-thunk';
import { Checkbox } from 'antd';
import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';
import { LabeledValue, SelectValue } from 'antd/lib/select';
import { Action } from 'redux';
import { RootState } from '@share/utils';
import {
  ILoginState,
  IWeekState,
  IWeeksFiltersState,
  IWeeksLocationsState,
  condoFiltersActions,
  weeksFiltersActions,
} from '@share/store/slices';
import { CloseSvg } from '@share/assets';
import { FilterCheckbox, FilterDistance } from '@components';
import { CustomSelect, BlueButton } from '@share/components';
import { getWeeksSortOptions, getWeeksFiltersCount, isWeeksFiltersEmpty } from '@utils';
import { getBudgetRangeLabel } from '@share/utils';
import { CondoLocationsEnum } from '@share/common-types';
import {
  C_R_FILTER_ACCESSIBILITIES,
  C_R_FILTER_ACTIVITIES,
  C_R_FILTER_ALL_INCLUSIVE,
  C_R_FILTER_AMENITIES,
  C_R_FILTER_BATHROOM,
  C_R_FILTER_BUDGET,
  C_R_FILTER_CONDO_TYPE,
  C_R_FILTER_DAY,
  C_R_FILTER_DISNTACE,
  C_R_FILTER_FACILITIES,
  C_R_FILTER_GUEST_RATING,
  C_R_FILTER_KITCHEN,
  C_R_FILTER_MONTH,
  C_R_FILTER_NEIGHBORHOODS,
  C_R_FILTER_PLACES,
  C_R_FILTER_SUPPLIER,
  SortTypes,
} from '@share/constants';

import './style.scss';

interface IMapStateToProps {
  weeksFiltersStore: IWeeksFiltersState;
  weeksLocationsStore: IWeeksLocationsState;
  weeksStore: IWeekState;

  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setBudgetRanges: (value: string[]) => void;
  setDistanceInMiles: (value: number) => void;
  setGuestRatings: (value: string[]) => void;
  setNeighbourhoods: (value: string[]) => void;
  setPlaces: (value: string[]) => void;
  setUnitAmenities: (value: string[]) => void;
  setFacilities: (value: string[]) => void;
  setActivities: (value: string[]) => void;
  setCondoTypes: (value: string[]) => void;
  setAccessibilities: (value: string[]) => void;
  setSuppliers: (value: string[]) => void;
  setBathrooms: (value: number[]) => void;
  setDays: (value: number[]) => void;
  setMonths: (value: number[]) => void;
  setAllInclusive: (value: boolean) => void;
  setKitchenTypes: (value: string[]) => void;
  resetFilters: () => void;
  setSortType: (value: SortTypes) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {
  onClose: () => void;
  onFiltersOrSortChange: () => void;
  isDisabled: boolean;
  matches?: boolean;
}

const distanceOptions = [
  {
    name: <FormattedMessage id="mile" values={{ count: 10 }} />,
    value: 10,
  },
  {
    name: <FormattedMessage id="mile" values={{ count: 20 }} />,
    value: 20,
  },
  {
    name: <FormattedMessage id="mile" values={{ count: 50 }} />,
    value: 50,
  },
  {
    name: <FormattedMessage id="mile" values={{ count: 100 }} />,
    value: 100,
  },
];

const ZERO = 0;

function WeeksFiltersWrapperComponent(props: IProps) {
  const {
    onClose,
    weeksFiltersStore,
    weeksStore,
    isDisabled,
    matches,
    weeksLocationsStore,
    loginStore,
  } = props;
  const {
    days,
    months,
    bathrooms,
    kitchenTypes,
    neighbourhoods,
    places,
    accessibilities,
    condoTypes,
    activities,
    distanceInMiles,
    budgetRanges,
    facilities,
    unitAmenities,
    guestRatings,
    sortBy,
    isAllInclusiveOnly,
  } = weeksFiltersStore;
  const { account } = loginStore;
  const { location } = weeksLocationsStore;
  const { counters } = weeksStore;

  const { isB2C, walletWalletSavings } = account;

  const onChange = (
    value: string[] | number[] | number | boolean,
    setter: (value: string[] | number[] | number | boolean) => void,
    analyticsCode: string,
  ) => {
    setter(value);

    if (props.matches) {
      handleFilterChange(analyticsCode);
    }
  };

  const onBudgetChange = (value: string[]) =>
    onChange(value, props.setBudgetRanges, C_R_FILTER_BUDGET);

  const onDistanceChange = (value: number) =>
    onChange(value, props.setDistanceInMiles, C_R_FILTER_DISNTACE);

  const onGuestRatingChange = (value: string[]) =>
    onChange(value, props.setGuestRatings, C_R_FILTER_GUEST_RATING);

  const onNeighborhoodsChange = (value: string[]) =>
    onChange(value, props.setNeighbourhoods, C_R_FILTER_NEIGHBORHOODS);

  const onPlacesChange = (value: string[]) => onChange(value, props.setPlaces, C_R_FILTER_PLACES);

  const onAmenitiesChange = (value: string[]) =>
    onChange(value, props.setUnitAmenities, C_R_FILTER_AMENITIES);

  const onFacilitiesChange = (value: string[]) =>
    onChange(value, props.setFacilities, C_R_FILTER_FACILITIES);

  const onActivitiesChange = (value: string[]) =>
    onChange(value, props.setActivities, C_R_FILTER_ACTIVITIES);

  const onCondoTypesChange = (value: string[]) =>
    onChange(value, props.setCondoTypes, C_R_FILTER_CONDO_TYPE);

  const onAccessibilitiesChange = (value: string[]) =>
    onChange(value, props.setAccessibilities, C_R_FILTER_ACCESSIBILITIES);

  const onSuppliersChange = (value: string[]) =>
    onChange(value, props.setSuppliers, C_R_FILTER_SUPPLIER);

  const onBathroomChange = (value: string[]) =>
    onChange(value, props.setBathrooms, C_R_FILTER_BATHROOM);

  const onDayChange = (value: string[]) =>
    onChange(
      value.map((val) => +val),
      props.setDays,
      C_R_FILTER_DAY,
    );

  const onMonthChange = (value: string[]) =>
    onChange(
      value.map((val) => +val),
      props.setMonths,
      C_R_FILTER_MONTH,
    );

  const onKitchenChange = (value: string[]) =>
    onChange(value, props.setKitchenTypes, C_R_FILTER_KITCHEN);

  const onAllInclusiveChange = (value: { target: CheckboxChangeEventTarget }) =>
    onChange(value.target.checked, props.setAllInclusive, C_R_FILTER_ALL_INCLUSIVE);

  const handleFilterChange = (key: string) => {
    props.onFiltersOrSortChange();
    handleAnalytics(key);
  };

  const handleAnalytics = (key: string) => {
    ReactGA.event({
      category: account?.name as string,
      action: `${key}_${account?.name.toUpperCase()}`,
      label: `User selected filter on condo resuls`,
      nonInteraction: false,
    });
  };

  const resetFilters = () => {
    props.resetFilters();
    props.onFiltersOrSortChange();
  };

  const onSortChange = (value: SelectValue): void => {
    props.setSortType(value as SortTypes);
    props.onFiltersOrSortChange();
    props.onClose();
  };

  const applyFilters = () => {
    props.onFiltersOrSortChange();
    props.onClose();
  };

  if (!counters) {
    return null;
  }

  const sortOptions = useMemo(
    (): LabeledValue[] => getWeeksSortOptions(weeksLocationsStore, isB2C, walletWalletSavings),
    [weeksLocationsStore, isB2C, walletWalletSavings],
  );
  const isSpecificCondo = useMemo(
    () => location?.locationType === CondoLocationsEnum.GenericCondo,
    [location?.locationType],
  );
  const showBudgetRanges = useMemo(
    () =>
      counters.budgetRanges?.length ? counters.budgetRanges.some(({ count }) => count) : false,
    [counters],
  );
  const showNeighbourhoods = useMemo(
    () =>
      counters.neighbourhoods?.length ? counters.neighbourhoods?.some(({ count }) => count) : false,
    [counters],
  );
  const showPlaces = useMemo(
    () => (counters.places?.length ? counters.places?.some(({ count }) => count) : false),
    [counters],
  );
  const showAmenities = useMemo(
    () =>
      counters.unitAmenities?.length ? counters.unitAmenities?.some(({ count }) => count) : false,
    [counters],
  );
  const showFacilities = useMemo(
    () => (counters.facilities?.length ? counters.facilities?.some(({ count }) => count) : false),
    [counters],
  );
  const showActivities = useMemo(
    () => (counters.activities?.length ? counters.activities?.some(({ count }) => count) : false),
    [counters],
  );
  const showCondoTypes = useMemo(
    () => (counters.condoTypes?.length ? counters.condoTypes?.some(({ count }) => count) : false),
    [counters],
  );
  const showAccessibilities = useMemo(
    () =>
      counters.accessibilities?.length
        ? counters.accessibilities?.some(({ count }) => count)
        : false,
    [counters],
  );
  const showGuestRatings = useMemo(
    () =>
      counters.guestRatings?.length ? counters.guestRatings?.some(({ count }) => count) : false,
    [counters],
  );
  const showBathrooms = useMemo(
    () => (counters.bathrooms?.length ? counters.bathrooms?.some(({ count }) => count) : false),
    [counters],
  );
  const showDays = useMemo(
    () => (counters.days?.length ? counters.days?.some(({ count }) => count) : false),
    [counters],
  );
  const showMonths = useMemo(
    () => (counters.months?.length ? counters.months?.some(({ count }) => count) : false),
    [counters],
  );
  const showKitchenTypes = useMemo(
    () =>
      counters.kitchenTypes?.length ? counters.kitchenTypes?.some(({ count }) => count) : false,
    [counters],
  );

  return (
    <div className="condo-filters-wrapper">
      <div className="condo-filters-wrapper__title">
        <div className="condo-filters-wrapper__left">
          {!matches && (
            <div className="condo-filters-wrapper__close" onClick={onClose}>
              <CloseSvg />
            </div>
          )}
          {matches ? <FormattedMessage id="filter" /> : <FormattedMessage id="sort.filter" />}
        </div>
        <div className="condo-filters-wrapper__right">
          {!isWeeksFiltersEmpty(weeksFiltersStore) && !isDisabled && (
            <div className="condo-filters-wrapper__reset" onClick={resetFilters}>
              <FormattedMessage id="reset.filter" />
            </div>
          )}
          {matches && (
            <div className="condo-filters-wrapper__close" onClick={onClose}>
              <CloseSvg />
            </div>
          )}
        </div>
      </div>
      <div className="condo-filters-wrapper__main">
        {!matches && (
          <div className="condo-filters-wrapper__sort">
            <div className="condo-result-wrapper__condos-sort">
              <div className="condo-result-wrapper__sort-select">
                <span className="condo-result-wrapper__sort-label">
                  <FormattedMessage id="sort.by" />:{' '}
                </span>
                <CustomSelect
                  disabled={isDisabled || !isSpecificCondo}
                  value={sortBy}
                  onChange={onSortChange}
                  options={sortOptions}
                />
              </div>
            </div>
          </div>
        )}
        {counters.allInclusiveCount !== ZERO ? (
          <Checkbox
            className="condo-filters-wrapper__filter-all-inclusive"
            onChange={onAllInclusiveChange}
            checked={isAllInclusiveOnly}
          >
            <span className="filter__option-name">
              <FormattedMessage id="show.all.inclusive.only" />
            </span>
            <span className="filter__option-quantity">{counters.allInclusiveCount}</span>
          </Checkbox>
        ) : null}
        {showBudgetRanges && (
          <FilterCheckbox
            disabled={isDisabled}
            onChange={onBudgetChange}
            values={budgetRanges}
            options={counters.budgetRanges.map(({ count, key }) => {
              return {
                count,
                value: `${key.from}-${key.to}`,
                label: getBudgetRangeLabel(key),
              };
            })}
            title="price.per.night"
          />
        )}
        {isSpecificCondo && (
          <FilterDistance
            disabled={isDisabled}
            title="distance.from.the.condo"
            onChange={onDistanceChange}
            value={distanceInMiles}
            options={distanceOptions}
          />
        )}
        {showGuestRatings && (
          <FilterCheckbox
            disabled={isDisabled}
            onChange={onGuestRatingChange}
            values={guestRatings.map((val) => `${val}`)}
            options={counters.guestRatings?.map(({ count, key }) => {
              return {
                count: count,
                value: `${key.from}-${key.to}`,
                label: key.title,
              };
            })}
            title="sort.guest"
          />
        )}
        {showNeighbourhoods && (
          <FilterCheckbox
            disabled={isDisabled}
            onChange={onNeighborhoodsChange}
            values={neighbourhoods}
            options={counters.neighbourhoods?.map(({ count, key }) => {
              return {
                count,
                value: key,
                label: <span>{key}</span>,
              };
            })}
            title="neighborhoods"
            withShowMore={true}
          />
        )}
        {showPlaces && (
          <FilterCheckbox
            disabled={isDisabled}
            onChange={onPlacesChange}
            values={places.map((val) => `${val}`)}
            options={counters.places?.map(({ count, key }) => {
              return {
                count,
                value: `${key.city}|${key.state}|${key.country}|${key.title}`,
                label: <span>{key.title}</span>,
              };
            })}
            title="filter.by.area"
            withShowMore={true}
          />
        )}
        {showAmenities && (
          <FilterCheckbox
            disabled={isDisabled}
            onChange={onAmenitiesChange}
            values={unitAmenities}
            options={counters.unitAmenities?.map(({ count, key }) => {
              return {
                count,
                value: key,
                label: <span>{key}</span>,
              };
            })}
            title="unit.amenities"
            withShowMore={true}
          />
        )}
        {showFacilities && (
          <FilterCheckbox
            disabled={isDisabled}
            onChange={onFacilitiesChange}
            values={facilities}
            options={counters.facilities?.map(({ count, key }) => {
              return {
                count,
                value: key,
                label: <span>{key}</span>,
              };
            })}
            title="condo.facilities"
            withShowMore={true}
          />
        )}
        {showActivities && (
          <FilterCheckbox
            disabled={isDisabled}
            onChange={onActivitiesChange}
            values={activities}
            options={counters.activities?.map(({ count, key }) => {
              return {
                count,
                value: key,
                label: <span>{key}</span>,
              };
            })}
            title="tab.activities"
            withShowMore={true}
          />
        )}
        {showCondoTypes && (
          <FilterCheckbox
            disabled={isDisabled}
            onChange={onCondoTypesChange}
            values={condoTypes}
            options={counters.condoTypes?.map(({ count, key }) => {
              return {
                count,
                value: key,
                label: <span>{key}</span>,
              };
            })}
            title="condo.type"
            withShowMore={true}
          />
        )}
        {showBathrooms && (
          <FilterCheckbox
            disabled={isDisabled}
            onChange={onBathroomChange}
            values={bathrooms.map((b) => `${b}`)}
            options={counters.bathrooms?.map(({ count, key }) => {
              return {
                count,
                value: `${!key ? 0 : key}`,
                label: (
                  <span>
                    {!key ? (
                      <FormattedMessage id="bathrooms.unespecified" />
                    ) : (
                      <FormattedMessage id="bathrooms.count" values={{ count: key }} />
                    )}
                  </span>
                ),
              };
            })}
            title="number.of.bathrooms"
          />
        )}
        {showDays && (
          <FilterCheckbox
            disabled={isDisabled}
            onChange={onDayChange}
            values={days.map((b) => `${b}`)}
            options={counters.days?.map(({ count, key }) => {
              return {
                count,
                value: `${key}`,
                label: (
                  <span>
                    <FormattedMessage id="days.count" values={{ count: key }} />
                  </span>
                ),
              };
            })}
            title="number.of.days"
          />
        )}
        {showMonths && (
          <FilterCheckbox
            disabled={isDisabled}
            onChange={onMonthChange}
            values={months.map((b) => `${b}`)}
            options={counters.months?.map(({ count, key }) => {
              return {
                count,
                value: `${key}`,
                label: <FormattedMessage id={`months.${key}`} />,
              };
            })}
            title="number.of.months"
          />
        )}
        {showKitchenTypes && (
          <FilterCheckbox
            disabled={isDisabled}
            onChange={onKitchenChange}
            values={kitchenTypes}
            options={counters.kitchenTypes?.map(({ count, key }) => {
              return {
                count,
                value: key,
                label: <span>{key}</span>,
              };
            })}
            title="kitchen.type"
          />
        )}
        {showAccessibilities && (
          <FilterCheckbox
            disabled={isDisabled}
            onChange={onAccessibilitiesChange}
            values={accessibilities}
            options={counters.accessibilities?.map(({ count, key }) => {
              return {
                count,
                value: key,
                label: <span>{key}</span>,
              };
            })}
            title="accessibility"
            withShowMore={true}
          />
        )}
      </div>
      <div className="condo-filters-wrapper__apply-button">
        <BlueButton onClick={applyFilters}>
          <FormattedMessage
            id="apply.filters"
            values={{ count: getWeeksFiltersCount(weeksFiltersStore) }}
          />
        </BlueButton>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    weeksFiltersStore: state.weeksFiltersStore,
    weeksLocationsStore: state.weeksLocationsStore,
    weeksStore: state.weeksStore,

    loginStore: state.loginStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setBudgetRanges: weeksFiltersActions.setBudgetRanges,
  setDistanceInMiles: weeksFiltersActions.setDistanceInMiles,
  setGuestRatings: weeksFiltersActions.setGuestRatings,
  setNeighbourhoods: weeksFiltersActions.setNeighbourhoods,
  setPlaces: weeksFiltersActions.setPlaces,
  setUnitAmenities: weeksFiltersActions.setUnitAmenities,
  setFacilities: weeksFiltersActions.setFacilities,
  setActivities: weeksFiltersActions.setActivities,
  setCondoTypes: weeksFiltersActions.setCondoTypes,
  setAccessibilities: weeksFiltersActions.setAccessibilities,
  setSuppliers: weeksFiltersActions.setSuppliers,
  setBathrooms: weeksFiltersActions.setBathrooms,
  setDays: weeksFiltersActions.setDays,
  setMonths: weeksFiltersActions.setMonths,
  setKitchenTypes: weeksFiltersActions.setKitchenTypes,
  setAllInclusive: weeksFiltersActions.setAllInclusive,
  setSortType: weeksFiltersActions.setSortBy,
  resetFilters: weeksFiltersActions.resetFilters,
};

export const WeeksFiltersWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WeeksFiltersWrapperComponent);
