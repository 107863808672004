import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ITreatYourselfLogos } from '@common-types';
import { connect } from 'react-redux';
import { RootState } from '@share/utils';
import { IMenuState } from '@share/store/slices';

import './style.scss';

interface IMapStateToProps {
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps {
  logos: ITreatYourselfLogos[];

  isCruise?: boolean;
}

class TreatYourselfComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { logos, isCruise, menuStore } = this.props;
    const { items } = menuStore;

    const customerServiceNumber = isCruise ? items?.phone : items?.customerServiceNumber;
    
    if (!items) {
      return null;
    }

    return (
      <div className="treat-yourself">
        <div className="treat-yourself__wrapper">
          <p className="treat-yourself__title sencodary-font">
            <FormattedMessage id="treat.yourself.direct.line" />
            <a href={`tel:${customerServiceNumber}`} className="treat-yourself__contact-phone">
              {customerServiceNumber}
            </a>
          </p>
          <p className="treat-yourself__description">
            <FormattedMessage id="your.award.winning.travel" />
          </p>
          <p className="treat-yourself__info-text">
            <FormattedMessage id="we.have.been.recognized" />
          </p>
          <div className="treat-yourself__logos-wrapper">
            {logos.map((item, index) => (
              <div key={`${index}-${item.name}`}>
                <div className="treat-yourself__img-wrapper">
                  <img className="treat-yourself__img" src={item.image} alt={item.name} />
                </div>
                <div>
                  <p className="treat-yourself__logo-name">
                    <FormattedMessage id={item.name} />
                  </p>
                  <p className="treat-yourself__logo-reward">
                    <FormattedMessage id={item.reward} />
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return { menuStore: state.navigationMenuStore };
};

export const TreatYourself = connect(mapStateToProps)(TreatYourselfComponent);
