import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getHeaders, axiosInstance } from '@share/utils';
import { ICurrentUser } from '@common-types';
import { AppThunk } from '@share/utils';
import { Urls } from '@share/constants';

export interface ICurrentUserState {
  currentUser: ICurrentUser;
  loading: boolean;
  error: string;
}

const initialState: ICurrentUserState = {
  currentUser: null,
  loading: false,
  error: '',
};

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setLoading: (state: ICurrentUserState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: ICurrentUserState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setCurrentUser: (state: ICurrentUserState, { payload }: PayloadAction<ICurrentUser>) => {
      state.currentUser = payload;
    },
  },
});

export const currentUserActions = currentUserSlice.actions;

export const currentUserReducer = currentUserSlice.reducer;

export const getCurrentUser = (): AppThunk => {
  return async (dispatch) => {
    dispatch(currentUserActions.setLoading(true));

    try {
      const res = await axiosInstance.get(`${Urls.CurrentUser}`, {
        ...getHeaders(),
      });

      dispatch(currentUserActions.setLoading(false));
      dispatch(currentUserActions.setCurrentUser(res.data));
    } catch (error) {
      console.error(error);
      dispatch(currentUserActions.setError(error.toString()));
      dispatch(currentUserActions.setLoading(false));
    }
  };
};
