import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { CONDO_SESSION_KEY, CONDO_UNITS_SESSION_KEY_LABEL, Routes, QUOTE_LABEL } from '@share/constants';
import {
  condoBookingResetState,
  getCondoReviewBook,
  ICondoReviewBookState,
  setCondoExpiredSession,
  setLoadingCondoBook,
} from '@store/slices';
import { ICondosStaticState } from '@share/store/slices';
import { BookingErrorsEnum, ISessionKey } from '@share/common-types';
import { getAvailabilityId, getCondoId } from '@utils';
import { getSessionObj, getTimeout, UrlUtils } from '@share/utils';
import { RootState } from '@share/utils';
import { Notification, SkeletonReviewBook } from '@components';
import { CondoReviewBook } from '../condo-review-book';
import { CondoUnitInfo } from '../condo-unit-info';
import { ILoginState } from '@share/store/slices';
import { NULL_VALUE } from '@constants';

import './style.scss';

interface IMapStateToProps {
  condoReviewBookStore: ICondoReviewBookState;
  condosStaticStore: ICondosStaticState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  condoBookingResetState: () => void;
  getCondoReviewBook: (
    condoId: number,
    availabilityId: string,
    sessionKey: ISessionKey,
    isGetCoupon?: false,
    quote?: string
  ) => void;
  setLoadingCondoBook: (loadingBook: boolean) => void;
  setCondoExpiredSession: (expiredSession: boolean) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {}

const ZERO = 0;
const THIRD_ELEMENT = 3;

class CondoReviewBookWrapperComponent extends React.Component<IProps> {
  onExpiredSession = (sessionData: ISessionKey) => {
    const expireDate = sessionData.expireDate;

    if (getTimeout(expireDate) < ZERO) {
      this.props.setCondoExpiredSession(true);
    } else {
      this.setState({
        // @ts-ignore
        expirationTimeout: setTimeout(() => {
          this.props.setCondoExpiredSession(true);
        }, getTimeout(expireDate)),
      });
    }
  };

  componentWillUnmount() {
    this.props.condoBookingResetState();
  }

  componentDidMount() {
    const values = UrlUtils.getValues();
    const sessionKey = getSessionObj(
      values[CONDO_UNITS_SESSION_KEY_LABEL] as ISessionKey,
      values[CONDO_SESSION_KEY] as ISessionKey,
    );
    let quote;
    if (values[QUOTE_LABEL]) {
      quote = (values[QUOTE_LABEL] as string);
    }

    this.props.getCondoReviewBook(getCondoId(), getAvailabilityId(), sessionKey, false, quote);
    this.onExpiredSession(sessionKey);
    window.scrollTo(ZERO, ZERO);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const { history, loginStore, condoReviewBookStore } = this.props;
    const { loading, error } = condoReviewBookStore;
    const { account } = loginStore;
    const condoId = Number(history.location.pathname.split('/')[THIRD_ELEMENT]);

    if (
      prevProps.condoReviewBookStore.loading !== loading &&
      !loading &&
      error === BookingErrorsEnum.BookingSummeryInformationFailed
    ) {
      history.push(`/${account?.name}${Routes.Condo}/${condoId}${location.search}`);
      location.reload();
    }
  }

  render(): React.ReactNode {
    const { condoReviewBookStore, condosStaticStore } = this.props;
    const {
      condoBookingSummary,
      loading,
      expiredSession,
      isExternalError,
      bookingComplete,
      isBookingPending,
      bookingErrorCode,
      loadingBooking,
      isSavingsMismatch,
    } = condoReviewBookStore;
    const { condosCountriesCode } = condosStaticStore;
    const { account } = this.props.loginStore;
    const condoId = Number(this.props.history.location.pathname.split('/')[THIRD_ELEMENT]);
    const isNotification =
      bookingComplete ||
      loadingBooking ||
      isBookingPending ||
      bookingErrorCode === BookingErrorsEnum.SoldOut;

    const isUnavailable = bookingErrorCode === BookingErrorsEnum.SoldOut || bookingErrorCode === BookingErrorsEnum.RoomsUnavailable;

    return expiredSession || isExternalError || !condosCountriesCode || isUnavailable? (
      <Notification
        expiredSession={expiredSession}
        isExternalError={isExternalError || !condosCountriesCode}
        isFromCondo={true}
        isSavingsMismatch={isSavingsMismatch}
        errorCode={isUnavailable ? bookingErrorCode : NULL_VALUE}
      />

    ) : (
      <div className="review-book-wrapper print">
        {!isNotification && (
          <div className="review-book-wrapper__header print">
            <div className="review-book-wrapper__back-link">
              <Link to={`/${account?.name}${Routes.Condo}/${condoId}${location.search}`}>
                <FormattedMessage id="back.to.condo" />
              </Link>
            </div>
            <h4 className="review-book-wrapper__title secondary-font">
              <FormattedMessage id="review.book" />
            </h4>
            <p className="review-book-wrapper__description">
              <FormattedMessage id="enter.your.details" />
            </p>
          </div>
        )}
        
        {loading ? <SkeletonReviewBook /> : null}
        {condoBookingSummary && !loading && condosCountriesCode ? (
          <div className="review-book-wrapper__content">
            <CondoReviewBook />
            <CondoUnitInfo />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condoReviewBookStore: state.condoReviewBookStore,
    condosStaticStore: state.condosStaticStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getCondoReviewBook,
  setLoadingCondoBook,
  setCondoExpiredSession,
  condoBookingResetState,
};

export const CondoReviewBookWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CondoReviewBookWrapperComponent));
