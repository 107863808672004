import React from 'react';
import { Skeleton } from 'antd';
import './style.scss';

const ONE_ROW = 1;
const NUMBER_OF_BLOCKS = [ONE_ROW, ONE_ROW, ONE_ROW, ONE_ROW];

interface IProps {
  blocks?: number;
  noMarginTop?: boolean;
  isSlider?: boolean;
}

export class GetawayListCardSkeleton extends React.Component<IProps> {
  render(): React.ReactNode {
    const { blocks, isSlider, noMarginTop } = this.props;
    const BLOCKS = blocks ? 
                      Array.from({length: blocks}, () => ONE_ROW) : 
                      isSlider ?
                          [ONE_ROW] :
                          NUMBER_OF_BLOCKS;

    const windowWidth = window.innerWidth;
    const styles: any ={};
    if (isSlider) {
      if (windowWidth >= 430) {
        styles.minWidth = '400px';
      } else {
        styles.minWidth = `${windowWidth - 30}px`;
      }
    }

    return (
      <div className={`getaway-list-skeleton_list getaway-list-skeleton_card ${noMarginTop ? 'no-margin' : ''}`}>
        {BLOCKS.map((item, index) => (
          <div key={`${index}-${item}`} className="getaway-list-skeleton_list__item" style={styles}>
            <Skeleton
              className="getaway-list-skeleton_list__item-img"
              active
              title={false}
              paragraph={{ rows: ONE_ROW }}
            />
            <div className="getaway-list-skeleton_list__item-info">
              <div className="getaway-list-skeleton_list__item-text">
                <Skeleton
                  className="getaway-list-skeleton_list__item-text_skeleton"
                  active
                  title={false}
                  paragraph={{ rows: ONE_ROW }}
                />
                <Skeleton
                  className="getaway-list-skeleton_list__item-text_skeleton"
                  active
                  title={false}
                  paragraph={{ rows: ONE_ROW }}
                />
              </div>
              <Skeleton
                className="getaway-list-skeleton_list__item_location"
                active
                title={false}
                paragraph={{ rows: ONE_ROW }}
              />
              <div className="getaway-list-skeleton_list__item_details">
                <Skeleton
                  className="getaway-list-skeleton_list__item_details_skeleton"
                  active
                  title={false}
                  paragraph={{ rows: ONE_ROW }}
                />
                <Skeleton
                  className="getaway-list-skeleton_list__item_details_skeleton"
                  active
                  title={false}
                  paragraph={{ rows: ONE_ROW }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
