import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Urls } from '@share/constants';

import { AppThunk, axiosInstance, getHeaders } from '@share/utils';
import moment from 'moment';

export enum DateType {
  NOT_SPECIFIED = 0,
  LAST_7 = 1,
  LAST_30 = 2,
  LAST_60 = 3,
  LAST_90 = 4,
  CUSTOM_RANGE = 5,
}

export interface IReportsRequest {
  dateType: DateType;
  fromDate?: string;
  toDate?: string;
}

export interface IReportsTopDestinationsResponse {
  destination: string;
  numberOfRooms: number;
  numberOfBookings: number;
  percentage: number;
  saving: number;
  commissions: number;
  grossAmount: number;
}

export interface IReportsTopMembersResponse {
  leadName: string;
  numberOfBookings: number;
  totalSpent: number;
}

export interface IReportsRoomsBookedDaysMonthsResponse {
  date: string;
  numberOfRooms: number;
}

export interface IReportsBookingsTotalDaysMonthsResponse {
  date: string;
  grossAmount: number;
  savings: number;
}

export interface IReportsResponse {
  sales: number;
  savings: number;
  commissions: number;
  confirmedBookings: number;
  canceledBookings: number;

  topDestinationsHomes: IReportsTopDestinationsResponse[];
  topMembersHomes: IReportsTopMembersResponse[];
  roomsBookedDays: IReportsRoomsBookedDaysMonthsResponse[];
  roomsBookedMonths: IReportsRoomsBookedDaysMonthsResponse[];
  bookingsTotalDays: IReportsBookingsTotalDaysMonthsResponse[];
  bookingsTotalMonths: IReportsBookingsTotalDaysMonthsResponse[];
}

export interface IDashboardReportState {
  request: IReportsRequest;
  dashBoardData: IReportsResponse;
  loading: boolean;
  error: string;
}

const initialState: IDashboardReportState = {
  request: {
    dateType: DateType.LAST_30,
  },
  dashBoardData: null,
  loading: false,
  error: '',
};

const dashboardReportsSlice = createSlice({
  name: 'dashboardReports',
  initialState,
  reducers: {
    setDashboardReportsRequest: (
      state: IDashboardReportState,
      { payload }: PayloadAction<IReportsRequest>,
    ) => {
      state.request = payload;
    },
    setDashboardReportsData: (
      state: IDashboardReportState,
      { payload }: PayloadAction<IReportsResponse>,
    ) => {
      state.dashBoardData = payload;
    },
    setDashboardReportsLoading: (
      state: IDashboardReportState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.loading = payload;
    },
    setDashboardReportsError: (
      state: IDashboardReportState,
      { payload }: PayloadAction<string>,
    ) => {
      state.error = payload;
    },
  },
});

export const {
  setDashboardReportsRequest,
  setDashboardReportsData,
  setDashboardReportsLoading,
  setDashboardReportsError,
} = dashboardReportsSlice.actions;

export const dashboardReportsReducer = dashboardReportsSlice.reducer;

export const dashboardReportsSelector = (state: {
  dashboardReportsStore: IDashboardReportState;
}): IDashboardReportState => {
  return state.dashboardReportsStore;
};

export const getDashboarReports = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setDashboardReportsLoading(true));

    try {
      const { dashboardReportsStore } = getState();
      const { request } = dashboardReportsStore;

      let params = '';
      if (request?.dateType) {
        params = `?tipoFecha=${request.dateType}`;
        if (request.dateType === DateType.CUSTOM_RANGE) {
          params = `${params}&fromDate=${moment(request?.fromDate, 'MM/DD/yyyy').format(
            'yyyy-MM-DD',
          )}&ToDate=${moment(request?.toDate, 'MM/DD/yyyy').format('yyyy-MM-DD')}`;
        }
      }
      const res = await axiosInstance.get(`${Urls.DashboardReports}${params}`, { ...getHeaders() });

      dispatch(setDashboardReportsLoading(false));
      dispatch(setDashboardReportsData(res.data));
    } catch (error) {
      console.error(error);
      dispatch(setDashboardReportsError(error.toString()));
      dispatch(setDashboardReportsLoading(false));
    }
  };
};
