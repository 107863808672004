import React from 'react';

export const NoResults: React.FunctionComponent = () => (
  <svg width="100" height="72" viewBox="0 0 100 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.1343 43.7601C69.8552 37.5804 72.1255 26.4953 67.039 17.6482C61.4352 7.90126 49.0241 4.56172 39.3181 10.1891C29.612 15.8165 26.2864 28.2799 31.8902 38.0268C36.9768 46.8741 47.6721 50.4422 56.862 46.8169L64.8419 60.6968C65.6808 62.1558 67.541 62.6542 68.9969 61.8101C70.4528 60.966 70.9531 59.099 70.1143 57.64L62.1343 43.7601ZM64.4028 19.1759C69.166 27.4608 66.3393 38.0547 58.0891 42.8379C49.839 47.6212 39.2895 44.7826 34.5263 36.4977C29.7631 28.2128 32.5898 17.619 40.8399 12.8357C49.0901 8.05239 59.6395 10.891 64.4028 19.1759Z"
      fill="#A0B3C2"
    />
    <rect x="29.2451" y="64.4204" width="29.2453" height="3.78947" rx="1.89474" fill="#DEDEE3" />
    <rect x="60.3774" y="64.4204" width="9.43396" height="3.78947" rx="1.89474" fill="#DEDEE3" />
    <rect x="36.9639" y="20.8882" width="24.3518" height="13.2461" rx="2" stroke="#DEDEE3" />
    <rect x="39.5005" y="23.436" width="8.11727" height="8.15144" rx="1" fill="#A0B3C2" />
    <rect x="48.6323" y="23.436" width="10.1466" height="2.03786" rx="1.01893" fill="#A0B3C2" />
    <rect x="48.6323" y="26.4927" width="8.11727" height="2.03786" rx="1.01893" fill="#A0B3C2" />
    <rect x="74.5063" y="20.8882" width="24.3518" height="13.2461" rx="2" stroke="#DEDEE3" />
    <rect x="77.043" y="23.436" width="8.11727" height="8.15144" rx="1" fill="#DEDEE3" />
    <rect x="86.1748" y="23.436" width="10.1466" height="2.03786" rx="1.01893" fill="#DEDEE3" />
    <rect x="86.1748" y="26.4927" width="8.11727" height="2.03786" rx="1.01893" fill="#DEDEE3" />
    <rect x="0.943359" y="20.8882" width="24.3518" height="13.2461" rx="2" stroke="#D6DCE6" />
    <rect x="3.47998" y="23.436" width="8.11727" height="8.15144" rx="1" fill="#D6DCE6" />
    <rect x="12.6123" y="23.436" width="10.1466" height="2.03786" rx="1.01893" fill="#D6DCE6" />
    <rect x="12.6123" y="26.4927" width="8.11727" height="2.03786" rx="1.01893" fill="#D6DCE6" />
    <rect
      y="19.8945"
      width="19.8113"
      height="15.1579"
      fill="url(#paint0_linear)"
      fillOpacity="0.9"
    />
    <rect
      x="100"
      y="35.0522"
      width="19.8113"
      height="15.1579"
      transform="rotate(-180 100 35.0522)"
      fill="url(#paint1_linear)"
      fillOpacity="0.9"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="-2.15689e-08"
        y1="29.2568"
        x2="22.0624"
        y2="29.7115"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.195531" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="100"
        y1="44.4145"
        x2="122.062"
        y2="44.8692"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.195531" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
