import React from 'react';

export const TransmitionSvg: React.FunctionComponent = () => (
  <svg viewBox="0 0 16 17" width="16" height="16" className="design-system-svg design-system-svg-size-small" fill="none">
    <path d="M9 8.5L7 8.5" stroke="#000000" strokeWidth="1.25" strokeLinecap="round"></path>
    <path d="M3.5 3C3.5 6.90524 3.5 14 3.5 14" stroke="#000000" strokeWidth="1.25" strokeLinecap="round"></path>
    <path d="M4.5 8.5C4.5 9.05228 4.05228 9.5 3.5 9.5C2.94772 9.5 2.5 9.05228 2.5 8.5C2.5 7.94772 2.94772 7.5 3.5 7.5C4.05228 7.5 4.5 7.94772 4.5 8.5Z" fill="#000000" stroke="#000000"></path>
    <circle cx="12.5" cy="13" r="1" fill="#000000" stroke="#000000"></circle><circle cx="12.5" cy="4" r="1" fill="#000000" stroke="#000000"></circle>
    <path d="M13.5 8.5C13.5 9.05228 13.0523 9.5 12.5 9.5C11.9477 9.5 11.5 9.05228 11.5 8.5C11.5 7.94772 11.9477 7.5 12.5 7.5C13.0523 7.5 13.5 7.94772 13.5 8.5Z" fill="#000000" stroke="#000000"></path>
  </svg>
);
