import React from 'react';

import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

import { ICondoUnitWeek } from '@common-types';

import { CondoWeeksDatesTable } from '../condo-week-dates-table';

import { BlueButton } from '@share/components';

import './style.scss';
import { RootState } from '@share/utils';
import { DEFAULT_PERIOD, ILoginState, weeksDatesActions, weeksLocationsActions } from '@share/store/slices';
import { CondoEngines, CondoLocationsEnum, ICondoLocation } from '@share/common-types';
import { get } from 'lodash';
import moment from 'moment';
import { Routes } from '@share/constants';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setWeeksSelectLocation: (location: ICondoLocation) => void;
  setWeeksSelectLocationLabel: (locationLabel: string) => void;
  applyWeeksLocation: () => void;

  setDateSelected: (date: string) => void;
  setPeriodSelected: (period: string) => void;
  applyWeeksDates: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  condo: ICondoUnitWeek;

  isWeeks: boolean;

  redirect: string;
}

function CondoWeeksCardWrapper(props: IProps) {

  const { condo, isWeeks, redirect, history, loginStore } = props;
  const { image } = condo;
  const { account } = loginStore;
  
  const handleOpenWeeks = () => {
    props.setDateSelected(moment(get(condo?.dates, '[0].checkIn', moment().format('yyyy-MM-DD')), 'yyyy-MM-DD').format('MM/DD/yyyy'));
    props.setPeriodSelected(DEFAULT_PERIOD);
    props.applyWeeksDates();

    props.setWeeksSelectLocationLabel(condo?.locationName);
    props.setWeeksSelectLocation({
      id: condo?.locationId?.toString(),
      name: condo?.locationName,
    
      locationType: CondoLocationsEnum.GenericRegion,
      realType: CondoLocationsEnum.GenericRegion,

      engine: CondoEngines.WEEKS 
    } as ICondoLocation);
    props.applyWeeksLocation();

    setTimeout(() => history.push(`/${account?.name}${Routes.WeeksDetails}/${condo?.propertyId}${location.search}`), 500);
  }

  return (
    <div className="condo-weeks-card-wrapper">
      <div className="tns-item">
        <div className="card" style={{ backgroundImage: `url(${image})` }}>
          <div className="position-absolute top-100 start-50 translate-middle w-100" style={{ zIndex: 0 }}>
            <div className="card-body text-center bg-mode shadow rounded mx-4 p-3" style={{ display: 'flex', flexDirection: 'column' }}>
              <CondoWeeksDatesTable condo={condo} displayReduced />

              {redirect ? (
                <div className="mt-2">
                  <a className="btn btn-sm btn-success mb-0" style={{ cursor: 'pointer' }} href="https://getaways-club.com" target="_blank">Book</a>
                </div>) : isWeeks ? (
                  <div className="mt-2" style={{ display: 'flex', justifyContent: 'center' }}>
                    <BlueButton onClick={handleOpenWeeks}>Book</BlueButton>
                  </div>) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setDateSelected: weeksDatesActions.setDateSelected,
  setPeriodSelected: weeksDatesActions.setPeriodSelected,
  applyWeeksDates: weeksDatesActions.applyWeeksDates,

  setWeeksSelectLocation: weeksLocationsActions.setSelectLocation,
  setWeeksSelectLocationLabel: weeksLocationsActions.setSelectLocationLabel,
  applyWeeksLocation: weeksLocationsActions.applyLocation,
};

const CondoWeeksCardWrapperRouter = withRouter(CondoWeeksCardWrapper);

export const CondoWeeksCard = connect(mapStateToProps, mapDispatchToProps)(CondoWeeksCardWrapperRouter);
