
import { useMemo } from 'react';

import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';

import { BlueButton } from '@share/components';
import { Routes } from '@share/constants';
import { ILoginState, IMenuState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { IAccount, RSI_MASTER_CLASS_REGISTRATION_URL_FIELD_KEY } from '@share/common-types';
import { GetPreferenceValue } from '@utils';

import MasterClassesImg from '@assets/images/master-classes-rsi-upgrade.jpg';
import Chevrons from '@assets/icons/chevrons.png';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps {}

function MasterClassesComponent(props: IProps) {
  const { loginStore, menuStore } = props;
  const { account } = loginStore;
  const { items, loading } = menuStore;

  if (items?.isMLM || items?.isRemoveMasterclass || loading) {
    return null;
  }

  const MasterClassesRegistrationPref = useMemo(() => GetPreferenceValue(account as IAccount, RSI_MASTER_CLASS_REGISTRATION_URL_FIELD_KEY), [account]);

  return (
    <div className="master-classes-rsi-upgrade">
      <div className="master-classes-rsi-upgrade__info-wrapper">
        <p className="master-classes-rsi-upgrade__title">
          <FormattedMessage id="master.classes" />
        </p>
        <p className="master-classes-rsi-upgrade__description">
          <FormattedMessage id="we.are.proud.to.offer.our.members" />
        </p>
        <p className="master-classes-rsi-upgrade__description">
          <FormattedMessage id="be.included.in.our.future.classes" />
        </p>
        <div className="master-classes-rsi-upgrade__btn-info">
          {!isEmpty(MasterClassesRegistrationPref) ? (
            <a href={MasterClassesRegistrationPref} target="_blank" rel="noreferrer" className="master-classes-rsi-upgrade__btn-link">
              <BlueButton>
                <FormattedMessage id="register.now" />
                <div style={{ backgroundImage: `url(${Chevrons})` }} className="image-chevrons" />
              </BlueButton>
            </a>) : null}
          {isEmpty(MasterClassesRegistrationPref) ? (
            <Link to={`/${account?.name}${Routes.MasterClassesRegister}`} className="master-classes-rsi-upgrade__btn-link">
              <BlueButton>
                <FormattedMessage id="register.now" />
                <div style={{ backgroundImage: `url(${Chevrons})` }} className="image-chevrons" />
              </BlueButton>
            </Link>) : null}
        </div>
      </div>
      
      <div className="master-classes-rsi-upgrade__img-wrapper" style={{ backgroundImage: `url(${MasterClassesImg})`}}></div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    menuStore: state.navigationMenuStore,
  };
};

export const MasterClasses = connect(mapStateToProps)(MasterClassesComponent);
