import React from 'react';
import { Skeleton } from 'antd';
import './style.scss';

interface IProps {}

export class SkeletonPersonalInfo extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <div className="skeleton-personal-info">
        <div className="skeleton-personal-info__wrapper">
          <div className="skeleton-personal-info__left-block">
            <Skeleton
              className="skeleton-personal-info__left-block-top"
              active
              title
              paragraph={{ rows: 4 }}
            />
            <div className="skeleton-personal-info__center-block">
              <Skeleton
                className="skeleton-personal-info__center-block-top"
                active
                title={false}
                paragraph={{ rows: 2 }}
              />
              <Skeleton
                className="skeleton-personal-info__center-block-bottom"
                active
                title={false}
                paragraph={{ rows: 4 }}
              />
            </div>
            <Skeleton
              className="skeleton-personal-info__left-block-bottom"
              active
              title={false}
              paragraph={{ rows: 1 }}
            />
          </div>
        </div>
      </div>
    );
  }
}
