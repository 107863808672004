
import { isEmpty } from 'lodash';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BookingErrorsEnum, ICarDetails, ICarExta, ISessionKey } from '@share/common-types';
import {
  SESSION_SEARCH_EXPIRED_MESSAGE,
  SESSION_EXPIRED_STATUS,
  Urls,
  Routes,
} from '@share/constants';
import { SERVER_ERROR_STATUSES } from '@share/constants';
import { getHeaders, axiosInstance, AppThunk, delay, getSelectedCurrency } from '@share/utils';
import { carsActions, getUserCards, RefreshSearch } from '@share/store/slices';
import { resetCarsState } from './cars-review-book';


export interface ICarsDetailsState {
  car: ICarDetails;
  selectedExtras: ICarExta[];
  loading: boolean;
  isServerError: boolean;
  error: string;
}

const initialState: ICarsDetailsState = {
  car: null,
  selectedExtras: [],
  loading: true,
  error: '',
  isServerError: false
};

const zero = 0;

const carsDetailsSlice = createSlice({
  name: 'cars-details',
  initialState,
  reducers: {
    setCarsDetailsLoading: (state: ICarsDetailsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setCarsDetailsError: (state: ICarsDetailsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setCarsDetails: (state: ICarsDetailsState, { payload }: PayloadAction<ICarDetails>) => {
      state.car = payload;
    },
    setSelectedExtras: (state: ICarsDetailsState, { payload }: PayloadAction<ICarExta[]>) => {
      state.selectedExtras = payload;
    },
    setCarsDetailsIsServerError: (state: ICarsDetailsState, { payload }: PayloadAction<boolean>) => {
      state.isServerError = payload;
    },
  },
});

export const carsDetailsActions = carsDetailsSlice.actions;
export const { setCarsDetailsLoading, setCarsDetailsError, setCarsDetails, setSelectedExtras, setCarsDetailsIsServerError } = carsDetailsSlice.actions;

export const carsDetailsReducer = carsDetailsSlice.reducer;


const getCar = async (carId: string, sessionKey: ISessionKey, dealId: number, quote: string, getState: any) => {
  const { loginStore } = getState();
  const { account, lifeStyle } = loginStore;

  return await axiosInstance.post(
    Urls.CarsDetails,
    {
      searchId: sessionKey?.value,
      id: carId,
      currency: getSelectedCurrency(account),
      lifeStyle
    },
    { ...getHeaders() },
  );
}

const getCarRetry = async (carId: string, sessionKey: ISessionKey, dealId: number, quote: string, getState: any
  ) => {
  try {
    return await getCar(carId, sessionKey, dealId, quote, getState);
  } catch (error) {
    delay(300);
    return await getCar(carId, sessionKey, dealId, quote, getState);
  }
}

export const getCarsDetails = (
  carId: string,
  sessionKey: ISessionKey,
  dealId?: number,
  quote?: string
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setCarsDetailsLoading(true));
    dispatch(setCarsDetailsError(''));
    
    dispatch(setCarsDetailsIsServerError(false));
    dispatch(setSelectedExtras([]));
    
    dispatch(resetCarsState());

    const { loginStore } = getState();
    const { account } = loginStore;

    try {
      const res: any = await getCarRetry(carId, sessionKey, dealId, quote, getState);

      dispatch(setCarsDetails(res.data));
      dispatch(setCarsDetailsLoading(false));
      dispatch(getUserCards());
    } catch (error) {
      console.error(error);
      dispatch(setCarsDetailsError(!isEmpty(error?.code) ? error?.code?.toString() : 'GENERIC'));
      dispatch(setCarsDetailsLoading(false));

      // Map this error
      //code: "31426"

      if (
        error?.response?.status === SESSION_EXPIRED_STATUS &&
        error?.response?.data &&
        error?.response?.data[zero] === SESSION_SEARCH_EXPIRED_MESSAGE
      ) {
        history.replaceState(null, null, `/${account.name}${Routes.CarsSearch}${location.search}`);
        location.reload();
      } else if (SERVER_ERROR_STATUSES.includes(error?.response?.status)) {
        dispatch(setCarsDetailsIsServerError(true));
      } else if ([BookingErrorsEnum.CarUnavailable, '31426'].includes(error?.response?.data?.code)) {
        dispatch(setCarsDetailsError(BookingErrorsEnum.CarUnavailable));

        dispatch(RefreshSearch());
      } else {
        dispatch(setCarsDetailsIsServerError(true));
      }
    }
  };
};

