import { scrollTop } from '@share/utils';
import React, { Component } from 'react';
import { ResourcesHeading } from '../resources-heading';
import { ResourcesOtherResources } from '../resources-other-resources';
import { ResourcesTravelItems } from '../resources-travel-items';

export class ResourcesWrapper extends Component {
  componentDidMount(): void {
    scrollTop();
  }

  render(): React.ReactNode {
    return (
      <>
        <ResourcesHeading />
        <ResourcesTravelItems />
        <ResourcesOtherResources />
      </>
    );
  }
}
