import React, { ChangeEvent } from 'react';
import { Modal, Input } from 'antd';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { WhiteButton } from '@components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { validateEmail } from "@utils";
import { BlueButton } from '@share/components';

import './style.scss';

interface IProps extends WrappedComponentProps {
  visible: boolean;
  loading: boolean;

  onCancel: () => void;
  onVoucher: (recepientEmail: string, additionalEmail: string) => void;
  email: string;
  emailAdditional: string;
  isAdditionalEmailAdded: boolean;
  setEmailModal: (email: string) => void;
  setEmailAdditionalModal: (emailAdditional: string) => void;
  setIsAdditionalEmailAddedModal: (isAdditionalEmail: boolean) => void;
}

interface IState {
  email: string;
   emailAdditional: string;
   isAdditionalEmailAdded: boolean;
   disabled: boolean;
}

const modalWidth = 450;

class VoucherModalComponent extends React.Component<IProps, IState> {
  state: IState = {
    email: this.props.email,
    emailAdditional: null,
    isAdditionalEmailAdded: false,
    disabled: !validateEmail(this.props.email),
  };

  addEmail = () => {
    this.setState((prevState: IState) => ({
      email: prevState.email,
      emailAdditional: null,
      isAdditionalEmailAdded: true,
      disabled:true,
    }));

    this.props.setEmailAdditionalModal(null);   
    this.props.setIsAdditionalEmailAddedModal(true);     
  };

  removeEmail = () => {
    this.setState((prevState: IState) => (      
    {
      isAdditionalEmailAdded: false,      
      emailAdditional: null,
      disabled: !validateEmail(prevState.email),      
      email: prevState.email,
    }));

    this.props.setEmailAdditionalModal(null);  
    this.props.setIsAdditionalEmailAddedModal(false);   
  };

  onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
      this.setState((prevState: IState) => (
      {
            email: e.target.value,
            emailAdditional: prevState.emailAdditional,
            disabled: !(validateEmail(e.target.value) && 
                      (!this.props.isAdditionalEmailAdded || this.props.isAdditionalEmailAdded && validateEmail(this.state.emailAdditional))),
            isAdditionalEmailAdded: prevState.isAdditionalEmailAdded,
      }));

      this.props.setEmailModal(e.target.value);
  };

  onEmailAdditionalChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState((prevState: IState) => (
        {            
            emailAdditional: e.target.value,
            disabled: !(validateEmail(e.target.value) && validateEmail(prevState.email)),            
            email: prevState.email,
            isAdditionalEmailAdded: prevState.isAdditionalEmailAdded,               
        }));

        this.props.setEmailAdditionalModal(e.target.value);
  };

  render(): React.ReactNode {
    const { visible, onCancel, onVoucher, loading, intl } = this.props;

    return (
      <div className="voucher-modal">
        <Modal
          className="voucher-modal-wrapper"
          wrapClassName="voucher-modal-wrapper__wrap"
          visible={visible}
          footer={null}
          onCancel={() => {
            if (!loading) {
              onCancel();
            }
          }}
          closable={true}
          destroyOnClose={true}
          closeIcon={<FontAwesomeIcon icon={faTimes} />}
          width={modalWidth}
          title={<FormattedMessage id="send.voucher" />}
        >
          <div className="voucher-modal-wrapper__body">
            <div className="voucher-modal-wrapper__list">
              <div className="voucher-modal-wrapper__left">
                <FormattedMessage id="recepient.email" />
              </div>
              <div className="voucher-modal-wrapper__right">
                <div className="voucher-modal-wrapper__inputs">                  
                     {
                        <div className="reminder-modal-wrapper__input">
                          <Input
                            placeholder={intl.formatMessage({ id: 'enter.additional.email.address' })}
                            value={this.props.email}
                            onChange={(e) => this.onEmailChange(e)}                        
                          />
                        </div>
                      }

                      { this.props.isAdditionalEmailAdded &&
                        <div className="reminder-modal-wrapper__input">
                          <Input
                              placeholder={intl.formatMessage({ id: 'enter.additional.email.address' })}
                              value={this.props.emailAdditional}
                              onChange={(e) => this.onEmailAdditionalChange(e)}                        
                          />
                        </div>
                      }
                </div>
                {this.props.isAdditionalEmailAdded ? (
                  <div className="voucher-modal-wrapper__remove-email" onClick={this.removeEmail}>
                    <FormattedMessage id="remove" />
                  </div>
                ) : (
                  <div className="voucher-modal-wrapper__add-email" onClick={this.addEmail}>
                    <FormattedMessage id="add.additional.email" />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="voucher-modal-wrapper__footer">
            <WhiteButton disabled={loading} onClick={onCancel}>
              <FormattedMessage id="cancel" />
            </WhiteButton>
            <BlueButton disabled={this.state.disabled || loading} onClick={() => onVoucher(this.state.email, this.state.emailAdditional)}>
              <FormattedMessage id="send.email" />
            </BlueButton>
          </div>
        </Modal>
      </div>
    );
  }
}

export const VoucherModal = injectIntl(VoucherModalComponent);
