
import React from 'react';

import { RE_CHECK_ERROR } from '@constants';
import { BookingErrorsEnum } from '@share/common-types';

import { AvailabilityError } from '../availability-error';
import { ExpiredSession } from '../expired-session';
import { ExternalError } from '../external-error';

import './style.scss';

interface IProps {
  expiredSession: boolean;

  isExternalError: boolean;
  isFromCondo?: boolean;
  isSavingsMismatch?: boolean;
  isVacationRentals?: boolean;

  errorCode?: string;
}

export class Notification extends React.Component<IProps> {
  render(): React.ReactNode {
    const { expiredSession, isExternalError, isFromCondo = false, isSavingsMismatch, errorCode, isVacationRentals } = this.props;

    const isRecheckError = [RE_CHECK_ERROR, BookingErrorsEnum.SoldOut, BookingErrorsEnum.RoomsUnavailable].includes(errorCode);
    return (
      <div className="notification-wrapper">
        {isRecheckError && (<AvailabilityError isFromCondo={isFromCondo} />)}
        {expiredSession && !isRecheckError && (<ExpiredSession isFromCondo={isFromCondo} isSavingsMismatch={isSavingsMismatch} />)}
        {isExternalError && !expiredSession && !isRecheckError && (<ExternalError isFromCondo={isFromCondo} isVacationRentals={isVacationRentals} />)}
      </div>
    );
  }
}
