
import { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Form, Input } from 'antd';
import { isEmpty } from 'lodash';

import { MOBILE_MEDIA_POINT, Routes } from '@share/constants';
import { RootState, axiosInstanceExternal } from '@share/utils';
import { ILoginState, IMenuState } from '@share/store/slices';
import { BlueButton } from '@share/components';
import { ModalMessage } from '@components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faEnvelope, faLink } from '@fortawesome/free-solid-svg-icons';

import Tropical from '@assets/images/sidekick/tropical-resort.jpg';
import VacationHero from '@assets/images/sidekick/vacation-hero.jpg';
import SidekickLogo from '@assets/images/sidekick/sidekick-logo.svg';

import './style.scss';

interface IMapStateToProps {
	menuStore: IMenuState;
	loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, RouteComponentProps, WrappedComponentProps { }

function SideKickRewardsComponent(props: IProps) {
  	const { intl, loginStore, menuStore } = props;
  	const { user, account } = loginStore;
  	const { items } = menuStore;

	const isMobile = document.body.offsetWidth <= MOBILE_MEDIA_POINT;

	const divRef = useRef();

  	const [form] = Form.useForm();
  	const [formURL] = Form.useForm();

	const [url, setURL] = useState('');
	const [showMessage, setShowMessage] = useState(false);
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState('');
	const [messageValues, setMessageValues] = useState<any>(null);
	const [scroll, setScroll] = useState(0);
	const [scrollHero, setScrollHero] = useState(0);

	const logoSrc = items?.sideKickLogoUrl ? items?.sideKickLogoUrl : SidekickLogo;

	useEffect(() => {
    	if (!!user) {
			setSideKickUrl();
    	}
  	}, [user]);
	
	  useEffect(() => {
    	if (!!items) {
			setSideKickUrl();
    	}
  	}, [items]);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		setSideKickUrl();
	}, []);
			
	const handleCopyToClipboard = () => {
		navigator.clipboard.writeText(url);
		setMessage('sidekick.rewards.clipboard.message');
		setMessageValues(null);
		setShowMessage(true);
	}

	const setSideKickUrl = () => {
		const sidekickUrl = items?.sideKickUrl ? items?.sideKickUrl : 'https://sidekickrewards.com';
		const url = `${sidekickUrl}/index.php?page=register&c=SR${user?.keyid}`;
		setURL(url);
		formURL.setFieldsValue({ url });
	}

	const handleScroll = () => {
		const scrollPosition = window.scrollY;
		const height = window.innerHeight;

		const element: any = divRef.current;
		var elementDistanceToTop = element.getBoundingClientRect().top;

		const diff = Math.abs(height - elementDistanceToTop);
		const scrollPositionHero = diff < 0 ? 0 : diff;

		setScroll(scrollPosition);
		setScrollHero(scrollPositionHero / 2);
	}

	const onFinish = (values: any) => {
		const { email, firstName, lastName } = values;
		if (!loading && !isEmpty(email) && !isEmpty(firstName) && !isEmpty(lastName)) {
			setLoading(true);

			axiosInstanceExternal.get(`https://svc.accessrsi.com/api/sidekick/referafriend?c=SR${user?.keyid}&e=${email}&f=${firstName}&l=${lastName}`)
				.then(() => {
					setMessage('sidekick.rewards.refer.message');
					setMessageValues({ name: !isEmpty(items?.sideKickName) ? items?.sideKickName : 'Sidekick Rewards', nameShort: !isEmpty(items?.sideKickShortName) ? items?.sideKickShortName : 'Sidekick' });
					setLoading(false);
					form.resetFields();
				})
				.finally(() => setShowMessage(true));
		}
	};
	
  	return (
		<div className={`sidekicks-wrapper ${isMobile ? 'mobile' : ''}`}>
			<section className="image-bg overlay parallax">
				<div className="background-image-holder fadeIn" style={{ transform: `translate3d(0px, ${scroll}px, 0px)`, backgroundImage: `url(${Tropical})`, top: '-120px' }}></div>
				<div className="container">
					<div style={{ maxWidth: '700px' }}>
						<h1 className="secondary-font"><FormattedMessage id="sidekick.rewards.how" /></h1>
						<ol className="h4 secondary-font">
							<li><FormattedMessage id="sidekick.rewards.how.message1" /></li>
							<li><FormattedMessage id="sidekick.rewards.how.message2" values={{ b: (message: string) => (<b>{message}</b>), name: !isEmpty(items?.sideKickName) ? items?.sideKickName : 'Sidekick Rewards' }} /></li>
							<li><FormattedMessage id="sidekick.rewards.how.message3" /></li>
							<li><FormattedMessage id="sidekick.rewards.how.message4" /></li>
						</ol>
						<h3 className="secondary-font"><FormattedMessage id="sidekick.rewards.seriously" /></h3>
						{!items?.isMLM ? (
							<div className="text-left">
								<video width="100%" controls style={{ maxWidth: '700px' }}>
									<source src="https://serenitytime.accessrsi.com/videos/Sidekick%20Rewards.mp4" type="video/mp4" />
								</video>
							</div>) : null}
					</div>
				</div>
			</section>

			<section className="primary-bg">
				<div className="col-lg-10">
					<p className="lead"><FormattedMessage id={items?.isMLM ? 'sidekick.rewards.lead.mlm.message1' : 'sidekick.rewards.lead.message1'} values={{ b: (message: string) => (<b>{message}</b>), name: !isEmpty(items?.sideKickName) ? items?.sideKickName : 'Sidekick Rewards' }} /></p>
					<p className="lead"><FormattedMessage id="sidekick.rewards.lead.message2" values={{ b: (message: string) => (<b>{message}</b>), name: !isEmpty(items?.sideKickName) ? items?.sideKickName : 'Sidekick Rewards' }} /></p>
					<p className="lead"><FormattedMessage id="sidekick.rewards.lead.message3" values={{ b: (message: string) => (<b>{message}</b>), name: !isEmpty(items?.sideKickName) ? items?.sideKickName : 'Sidekick Rewards' }} /></p>
				</div>
			</section>

			<section className="refer-bg">
				<div className="col-lg-10 text-center">
					<h2 className="secondary-font"><FormattedMessage id="sidekick.rewards.refer.ways" /></h2>
					<p className="h4 secondary-font"><FormattedMessage id="sidekick.rewards.refer.ways.message" /></p>
				</div>
				<div className="col-lg-8 row" style={{ marginTop: '32px' }}>
					<div className="col-lg-6">
						<div className="row">
							<div className="panel panel-form">
								<div className="">
									<div className="title text-center">
										<FontAwesomeIcon icon={faEnvelope} size="2x" />
										<span className="form-title"><FormattedMessage id="sidekick.rewards.refer.email" /></span>
									</div>
								</div>
								<div className="panel-body" style={{ paddingTop: '32px' }}>
									<Form name="refForm" form={form} onFinish={onFinish}>
										<Form.Item>
											<div className="row">
												<div className="col-sm-12">
													<Form.Item
														name={['firstName']}
														rules={[{ required: true, message: intl.formatMessage({ id: 'field.required' }) }]}
													>
														<Input placeholder={intl.formatMessage({ id: 'first.name' })} />
													</Form.Item>
												</div>
											</div>
										</Form.Item>

										<Form.Item>
											<div className="row">
												<div className="col-sm-12">
													<Form.Item
														name={['lastName']}
														rules={[{ required: true, message: intl.formatMessage({ id: 'field.required' }) }]}
													>
														<Input placeholder={intl.formatMessage({ id: 'last.name' })} />
													</Form.Item>
												</div>
											</div>
										</Form.Item>

										<Form.Item>
											<div className="row">
												<div className="col-sm-12">
													<Form.Item
														name={['email']}
														rules={[
															{ type: 'email', message: <FormattedMessage id="the.input.is.not.valid.email" /> },
															{ required: true, message: intl.formatMessage({ id: 'field.required' }) }
														]}
													>
														<Input placeholder={intl.formatMessage({ id: 'email' })} />
													</Form.Item>
												</div>
											</div>
										</Form.Item>

										<Form.Item>
											<div className="row">
												<div className="col-sm-12">
													<Form.Item
														name={['confirmEmail']}
														dependencies={['email']}
														rules={[
															{ type: 'email', message: <FormattedMessage id="the.input.is.not.valid.email" /> },
															{ required: true, message: intl.formatMessage({ id: 'field.required' }) },
															({ getFieldValue }) => ({
																validator(_, value) {
																  if (!value || getFieldValue('email') === value) {
																	return Promise.resolve();
																  }
																  return Promise.reject(new Error(props.intl.formatMessage({ id: 'the.two.emails.that.you.entered.do.not.match' })));
																},
															  }),
										
														]}
													>
														<Input placeholder={intl.formatMessage({ id: 'confirm.email' })} />
													</Form.Item>
												</div>
											</div>
										</Form.Item>

										<Form.Item>
											<div className="row">
												<div className="col-sm-12">
													<div className="text-center">
														<FormattedMessage id="sidekick.rewards.refer.by_inviting" /><br/> <FormattedMessage id="sidekick.rewards.refer.to_the" /> <Link to={`/${account?.name}${Routes.SidekickRewardsTerms}`}><FormattedMessage id="sidekick.rewards.refer.terms_conditions" values={{ name: !isEmpty(items?.sideKickName) ? items?.sideKickName : 'Sidekick Rewards' }} /></Link>
														<BlueButton htmlType="submit" style={{ marginBottom: '0px' }} disabled={loading}><FormattedMessage id="sidekick.rewards.refer.send" /></BlueButton>
													</div>
												</div>
											</div>
										</Form.Item>
									</Form>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="row">
							<div className="panel panel-form">
								<div className="">
									<div className="title text-center">
										<FontAwesomeIcon icon={faLink} size="2x" />
										<span className="form-title"><FormattedMessage id="sidekick.rewards.refer.link" /></span>
									</div>
								</div>
								<div className="panel-body">
									<p className="lead" style={{ margin: '0px 15px 20px 15px', color: '#666' }}><FormattedMessage id="sidekick.rewards.refer.copy" /></p>
									<div className="form-group">
										<div className="col-md-12">

											<Form name="refFormUrl" form={formURL}>
												<Form.Item>
													<div className="row">
														<div className="col-sm-12">
															<Form.Item
																name={['url']}
																rules={[{ required: true }]}
															>
																<Input disabled={true} style={{ margin: '0px 15px 5px', width: 'calc(100% - 35px)' }} />
															</Form.Item>
														</div>
													</div>
												</Form.Item>
											</Form>
										</div>
									</div>
									<div className="text-center referral-link">
										<div onClick={handleCopyToClipboard}><FontAwesomeIcon icon={faClipboard} /> <FormattedMessage id="sidekick.rewards.refer.copy.clipboard" /></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="image-bg parallax overlay" ref={divRef} style={{ paddingTop: '96px', paddingBottom: '96px' }}>
				<div className="background-image-holder" style={{ transform: `translate3d(0px, ${scrollHero}px, 0px)`, backgroundImage: `url(${VacationHero})` }}></div>
				<div className="col-lg-12 text-center bottom-section">
					<h1 className="secondary-font"><FormattedMessage id="sidekick.rewards.how.title" /></h1>
					<div className="row row-center how-it-works">
						<div className="col-lg-4 hiw">
							<div className="title">
								<FormattedMessage id="sidekick.rewards.how.save" />
							</div>
							<div className="subtitle">
								<FormattedMessage id="sidekick.rewards.how.your_friends" />
							</div>
						</div>
						<div className="col-lg-4 hiw2">
							<div className="title">
								<FormattedMessage id="sidekick.rewards.how.earn" />
							</div>
							<div className="subtitle">
								<FormattedMessage id={items?.isMLM ? 'sidekick.rewards.mlm.how.you_earn' : 'sidekick.rewards.how.you_earn'} />
							</div>
						</div>
					</div>
					<div className="row row-center how-it-works">
						<div className="col-lg-8 hiw-body">
							<div className="title mb8">
								<FormattedMessage id="sidekick.rewards.how.sample" />: {items?.isMLM ? '$250' : '$500'}
							</div>
							<div className="row row-center">
								<div className="col-lg-6 row row-center">
									<div className="col-lg-8 sample">
										<div className="person">
											<FormattedMessage id="sidekick.rewards.how.save" />
										</div>
										<div className="earn">
											{items?.isMLM ? '$250' : '$500'}
										</div>
									</div>
								</div>
								<div className="col-lg-6 row row-center">
									<div className="col-lg-8 sample">
										<div className="person">
											<FormattedMessage id="sidekick.rewards.how.earn" />
										</div>
										<div className="earn">
											$250
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-12" style={{ marginTop: '64px', marginBottom: '32px', padding: '0px 20px' }}>
						<div className="row row-center">
							<div className="col-lg-6 col-lg-offset-3 col-md-6 col-md-offset-3 hiw-body" style={{ marginBottom: '50px', borderRadius: '10px 10px 10px 10px' }}>
								<FormattedMessage id="sidekick.rewards.how.sky" /><br /><FormattedMessage id="sidekick.rewards.how.live" />
							</div>
						</div>
						{!logoSrc ? (
							<div className="row row-center">
								<img alt="image" src={logoSrc} style={{ width: '550px' }} />
							</div>) : null}
					</div>
				</div>
			</section>

			<ModalMessage 
				text={message}
				visible={showMessage}
				textValues={messageValues}
				onCancel={() => { setShowMessage(false); setMessage(''); setMessageValues(null); }}
			/>
		</div>
	);
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
  };
};

const ComponentIntl = injectIntl(SideKickRewardsComponent);

export const SideKickRewards = connect(mapStateToProps)(withRouter(ComponentIntl));
