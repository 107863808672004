import React, { Component } from 'react';
import { MasterClassesRegisterHeading } from './master-classes-register-heading';
import { MasterClassesRegisterMain } from './master-classes-register-main';
import './style.scss';

export class MasterClassesRegister extends Component {
  componentDidMount(): void {
    window.scrollTo(0, 0);
  }

  render(): React.ReactNode {
    return (
      <>
        <MasterClassesRegisterHeading />
        <MasterClassesRegisterMain />
      </>
    );
  }
}
