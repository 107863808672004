import React from 'react';

export const NoImageSvg: React.FunctionComponent = () => (
  <svg width="48" height="44" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.2843 44C8.32206 43.9981 6.6204 42.6253 6.17998 40.6891L0.107831 13.9925C-0.412259 11.6957 0.998925 9.40548 3.26569 8.86951L15.2224 6.08031C16.4725 5.74353 17.7562 6.49679 18.0889 7.76237C18.4215 9.02794 17.6775 10.3277 16.4275 10.6644C16.3759 10.6787 16.3244 10.69 16.2729 10.7005L4.78475 13.3949L10.6507 39.1616L29.092 34.8545C30.3476 34.5385 31.6182 35.3126 31.9303 36.5839C32.2423 37.8552 31.4777 39.1416 30.2221 39.4575C30.1986 39.4632 30.1743 39.4689 30.1499 39.4746L11.2213 43.8861C10.914 43.9591 10.6 43.9981 10.2843 44Z"
      fill="#C8D1DF"
    />
    <path
      d="M40.9929 40H40.5907L14.8169 37.5665C12.5024 37.3518 10.8017 35.3217 11.0187 33.0326L13.8252 3.77511C14.0423 1.48599 16.0949 -0.196176 18.4093 0.0184555L44.1831 2.40566C46.4976 2.62029 48.1983 4.65042 47.9813 6.93954L45.1748 36.197C44.9727 38.3326 43.1624 39.9676 40.9929 39.9722V40ZM15.7338 33.0048L40.6001 35.3365L43.2756 7.03209L18.4281 4.67265L15.7338 33.0048Z"
      fill="#93A4C1"
    />
    <path
      d="M38.9396 32.9972L34.5084 18.7446L29.1461 25.2265L25.6271 21.8317L19.0547 31.0545L38.9396 32.9972Z"
      fill="#93A4C1"
    />
    <path
      d="M26.0019 14.9361C27.313 14.9361 28.3759 13.8381 28.3759 12.4837C28.3759 11.1292 27.313 10.0312 26.0019 10.0312C24.6908 10.0312 23.6279 11.1292 23.6279 12.4837C23.6279 13.8381 24.6908 14.9361 26.0019 14.9361Z"
      fill="#93A4C1"
    />
  </svg>
);
