import React from 'react';

export const ArrowsSvg: React.FunctionComponent = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 6.75L9 3.75L12 6.75"
      stroke="#0D99D6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 11.25L9 14.25L6 11.25"
      stroke="#0D99D6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
