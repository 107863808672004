import React from 'react';
import ReactGA from 'react-ga4';
import isBefore from 'date-fns/isBefore';

import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import upperCase from 'lodash/upperCase';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import {
  IDetails,
  ICondoUnit,
} from '@share/common-types';
import {
  ICondoGuests,
  CondoMatchTypeEnum,
  DateSearchTypeEnum,
  StayForEnum,
} from '@share/common-types';
import { CondoGuestsPickerComponent, CondoStrictDatePickerComponentWithIntl } from '@share/components';
import { BlueButton } from '@share/components';
import { getDateCheckInOutISOString, UrlUtils } from '@share/utils';
import {
  IUnitsSearchState,
  unitsSearchActions,
  getUnitsDetails,
} from '@store/slices';
import { getCheckinStatus, ICondoDetailsState, ICondoFlexibleDatePickerState } from '@share/store/slices';
import { IMenuState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { UpdateSvg } from '@share/assets';
import {
  CONDO_GUESTS_LABEL,
  CONDO_DATES_LABEL,
  CONDO_UNITS_SEARCH_LABEL,
  CONDO_IS_FLEXIBLE_LABEL,
  C_D_UPDATE_BUTTON,
  CONDO_DATES_FLEXIBLE_LABEL,
} from '@share/constants';

import { UnitSkeleton } from '../unit-skeleton';
import { AlternativeDatesSkeleton } from '../alternative-dates-skeleton';
import { ListUnits } from '../condo-list-units';
import { ILoginState } from '@share/store/slices';

import './style.scss';

interface IMapStateToProps {
  condoDetailsStore: ICondoDetailsState;
  unitsSearchStore: IUnitsSearchState;
  menuStore: IMenuState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  clearError: () => void;
  setDates: (dates: { startDate: Date; endDate: Date }) => void;
  setKey: (key: string) => void;
  setError: (key: string) => void;
  updateAdultsCount: (count: number) => void;
  updateKidsCount: (count: number) => void;
  updateBedroomCount: (count: number) => void;
  setDatesString: (dates: { startDate: string; endDate: string }) => void;
  setSearchType: (type: DateSearchTypeEnum) => void;
  setGuestsCount: (guests: ICondoGuests) => void;

  setMonths: (months: string[]) => void;
  selectMonths: (month: string) => void;

  getUnitsDetails: (condoId: number) => void;
  resetDates: () => void;
  resetFlexibleState: () => void;
  getCheckinStatus: (start: string, end: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  condoDetails: IDetails;
  refAnchor: React.RefObject<HTMLDivElement>;
  matches?: boolean;
  isShortStaysSupported?: boolean;
}

interface IState {
  showOnlyAvailableUnits: boolean;
  currentUnitName: string;
}

const ZERO = 0;
const ONE = 1;

class UnitsSearchComponent extends React.Component<IProps, IState> {
  state: IState = {
    showOnlyAvailableUnits: false,
    currentUnitName: '',
  };

  getUnits = (matchType?: string) => {
    const { condoDetails, unitsSearchStore } = this.props;
    const { showOnlyAvailableUnits } = this.state;
    const units: ICondoUnit[] = [];

    const getUnits =
      (unitsSearchStore.isSearchUnits && unitsSearchStore.condoInfo) ||
      (isEmpty(condoDetails.units) && !isUndefined(unitsSearchStore.condoInfo?.units))
        ? unitsSearchStore.condoInfo?.units
        : condoDetails.units;

    const condoUnits =
      showOnlyAvailableUnits && matchType === CondoMatchTypeEnum.Alternative
        ? getUnits.filter((item) =>
            item.availibilities.some((value) => value.matchType === CondoMatchTypeEnum.Exact),
          )
        : getUnits;

    condoUnits.forEach((unit) => {
      unit.availibilities.forEach((item) => {
        if (!matchType || item.matchType === matchType) {
          units.push({
            id: unit.id,
            name: unit.name
              ? unit.name
              : `${upperCase(unit.kitchenType)}-${unit.bedroomsCount}/${unit.maxOccupancy}/${
                  unit.privacyOccupancy
                }`,
            maxOccupancy: unit.maxOccupancy,
            privacyOccupancy: unit.privacyOccupancy,
            bedroomsCount: unit.bedroomsCount,
            bathroomsCount: unit.bathroomsCount,
            kitchenType: unit.kitchenType,
            unitId: item.id,
            price: item.price,
            pricePerNight: item.pricePerNight,
            publicPrice: item?.publicPrice,
            dateRange: {
              from: item.dateRange.from,
              to: item.dateRange.to,
            },
            matchType: item.matchType,
            sleepingArrangements: unit.sleepingArrangements,
            bathroom: unit.bathroom,
            cancellationPolicyText: item.cancellationPolicyText,
            refundability: item.refundability,
            bedRoomType: unit.bedRoomType,
            nonSmokingRoomsByRequest: unit.nonSmokingRoomsByRequest,
            loft: unit.loft,
            unitConfig: unit.unitConfig,
            outdoorLivingSpaceInfo: unit.outdoorLivingSpaceInfo,
            bathroomDescription: unit.bathroomDescription,
            bedroomBathLocation: unit.bedroomBathLocation,
            rollInShower: unit.rollInShower,
            bathroomAccessible: unit.bathroomAccessible,
            kitchenEquipments: unit.kitchenEquipments,
            babyFacilities: unit.babyFacilities,
            roomAmenities: unit.roomAmenities,
            roomFurnishing: unit.roomFurnishing,
            televisionServiceType: unit.televisionServiceType,
            televisionType: unit.televisionType,
            numberOfTVs: unit.numberOfTVs,
            televisionLocations: unit.televisionLocations,
            coolingSystems: unit.coolingSystems,
            accessibility: unit.accessibility,
            safebox: unit.safebox,
            safetyEquipment: unit.safetyEquipment,
            housekeeping: unit.housekeeping,
            housekeepingFeeDescription: unit.housekeepingFeeDescription,
            supplierIdentifier: unit.supplierIdentifier,
            maxWalletClientCash: item?.maxWalletClientCash
          });
        }
      });
    });

    return units;
  };


  updateSearch = (buttonClicked?: boolean) => {
    const { getUnitsDetails, resetFlexibleState, resetDates, setError, clearError, condoDetails, unitsSearchStore, intl } = this.props;

    if (unitsSearchStore.startDate && unitsSearchStore.endDate) {
      clearError();
      getUnitsDetails(condoDetails.id);

      if (unitsSearchStore.searchType === DateSearchTypeEnum.Strict) {
        resetFlexibleState();
      } else {
        resetDates();
      }
  
      const { loginStore } = this.props;
      const { account } = loginStore;
  
      ReactGA.event({
        category: account?.name as string,
        action: `${C_D_UPDATE_BUTTON}_${account?.name.toUpperCase()}`,
        label: `User clicked update button on detail`,
        nonInteraction: false,
      });
  
    } else {
      if (buttonClicked) {
        setError(intl.formatMessage({ id: 'please.select.dates' }));
      }
    }
  };

  getCurrentUnit = (value: string): void => {
    this.setState({ currentUnitName: value });
  };

  componentDidMount() {
    const { setDatesString, setGuestsCount, setSearchType, setMonths } = this.props;
    const values = UrlUtils.getValues();
    const isFlexibleSearch = values[CONDO_IS_FLEXIBLE_LABEL] === '1';

    if (!values[CONDO_IS_FLEXIBLE_LABEL]) {
      UrlUtils.setUrl(CONDO_IS_FLEXIBLE_LABEL, ONE);
    }

    setSearchType(DateSearchTypeEnum.Strict);

    if (values[CONDO_UNITS_SEARCH_LABEL]) {
      const { startDate, endDate, guests, selectedMonths } = values[CONDO_UNITS_SEARCH_LABEL] as {
        startDate: string;
        endDate: string;
        guests: ICondoGuests;
        searchType: DateSearchTypeEnum;
        stayFor: StayForEnum;
        selectedMonths: string[];      
      };

      if (!isFlexibleSearch) {
        setDatesString({ startDate, endDate } as { startDate: string; endDate: string });
      } else {
        setMonths(selectedMonths);
      }

      setGuestsCount(guests);

      setTimeout(() => {
        this.updateSearch();
      }, 300);
    } else {
      if (values[CONDO_DATES_LABEL]) {
        if (!isFlexibleSearch) {
          setDatesString(values[CONDO_DATES_LABEL] as { startDate: string; endDate: string });
        }
      }

      if (values[CONDO_GUESTS_LABEL]) {
        setGuestsCount(values[CONDO_GUESTS_LABEL] as ICondoGuests);
      }

      if (values[CONDO_DATES_FLEXIBLE_LABEL]) {
        const selectedData: ICondoFlexibleDatePickerState = values[CONDO_DATES_FLEXIBLE_LABEL] as ICondoFlexibleDatePickerState;
        const today = new Date();
        today.setHours(ZERO, ZERO, ZERO, ZERO);
        today.setDate(ONE);
        const months = selectedData?.selectedMonths.filter((dateString: string) => {
          const date = new Date(dateString);
          date.setHours(ZERO, ZERO, ZERO, ZERO);
          date.setDate(ONE);
  
          return !isBefore(date, today);
        });
        setMonths(months);
      }
    }
  }

  render(): React.ReactNode {
    const {
      refAnchor,
      condoDetails,
      unitsSearchStore,
      loginStore,
      condoDetailsStore,
      matches,
      updateAdultsCount,
      updateKidsCount,
      updateBedroomCount,
      setDates,
      selectMonths,
      resetDates,
      resetFlexibleState,
      getCheckinStatus,
      setGuestsCount,
      isShortStaysSupported,
      menuStore,
    } = this.props;
    const { currentUnitName } = this.state;
    const { endDate, startDate, guests, isUnitsLoading, key, error, selectedMonths, stayFor } = unitsSearchStore;
    const isOnlyAlternative = this.getUnits(CondoMatchTypeEnum.Exact).length === ZERO;

    const units = [...this.getUnits(CondoMatchTypeEnum.Exact), ...this.getUnits(CondoMatchTypeEnum.Alternative)];
    
    const condoStrictDates = {
      startDate: startDate,
      endDate: endDate,
      key: key,
      error,
      // TODO remove this when backend is ready
      searchType: DateSearchTypeEnum.Strict //searchType,
    };

    return (
      <div className="units-search" ref={refAnchor}>
        <h2 className="units-search__header secondary-font">
          <FormattedMessage id="availability.title" values={{ name: 'Units' }} />
        </h2>

        <h3 className="list-units__title" style={{ marginBottom: '0px', marginTop: '20px' }}><FormattedMessage id="update.search.your.hotel" /></h3>

        <div className="units-search__search">
          <CondoStrictDatePickerComponentWithIntl
            getCheckinStatus={getCheckinStatus}
            loginStore={loginStore}
            isShortStaysSupported={isShortStaysSupported}
            condoDetailsStore={condoDetailsStore}
            matches={matches}
            isFromDetails={true}
            isSearch={false}
            condoStrictDatesStore={condoStrictDates}
            condoFlexibleDatePickerStore={{
              stayFor: stayFor,
              selectedMonths: selectedMonths,            
            }}
            setSearchTypeExperiences={() => void ZERO}
            setSearchType={() => void ZERO}
            clearError={() => void ZERO}
            setDates={setDates}
            setDatesExperiences={setDates}
            selectMonths={selectMonths}
            resetDates={resetDates}
            resetFlexibleState={resetFlexibleState}

          />

          <CondoGuestsPickerComponent
            updateAdultsCount={updateAdultsCount}
            updateKidsCount={updateKidsCount}
            updateBedroomCount={updateBedroomCount}
            updateAdultsCountExperiences={updateAdultsCount}
            updateKidsCountExperiences={updateKidsCount}
            updateBedroomCountExperiences={updateBedroomCount}
            updateIncludeStudio={included => setGuestsCount({ ...guests, includeStudio: included })}
            updateIncludeStudioExperiences={included => setGuestsCount({ ...guests, includeStudio: included })}
            condoGuestsStore={{
              ...guests,
              error
            }}
            loginStore={loginStore}
          />
          <div className="units-search__button">
            <BlueButton onClick={() => this.updateSearch(true)}>
              <UpdateSvg />
              <FormattedMessage id="update.search.your.hotel" />
            </BlueButton>
          </div>
        </div>

        {error && <div className="condo-strict-date-picker__error" style={{ marginTop: '7px' }}>{error}</div>}

        <div className="units-search__list">
          {isUnitsLoading ? (
            <>
              <div className="units-search__list-skeleton">
                <UnitSkeleton />
                <UnitSkeleton />
                <UnitSkeleton />
                <UnitSkeleton />
              </div>
              <AlternativeDatesSkeleton />
            </>
          ) : (
            <>
              {error.length === ZERO ? (
                <div className="units-search__list-wrapper">
                  {!isEmpty(units) && (
                    <ListUnits
                      title={<FormattedMessage id="available.dates" />}
                      alternativeUnits={[...units]}
                      condoId={condoDetails.id}
                      isOnlyAlternative={isOnlyAlternative}
                      currentUnitName={currentUnitName}
                      getCurrentUnit={this.getCurrentUnit}
                      baseUrl={menuStore.items?.baseUrl as string}
                    />
                  )}
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condoDetailsStore: state.condoDetailsStore,
    unitsSearchStore: state.unitsSearchStore,
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  setDates: (dates: { startDate: Date; endDate: Date }) => {
    dispatch(
      unitsSearchActions.setDates({
        startDate: getDateCheckInOutISOString(dates.startDate),
        endDate: getDateCheckInOutISOString(dates.endDate),
      }),
    );
  },
  setDatesString: (dates: { startDate: string; endDate: string }) => {
    dispatch(
      unitsSearchActions.setDates({
        startDate: dates.startDate,
        endDate: dates.endDate,
      }),
    );
  },
  setKey: (key: string) => {
    dispatch(unitsSearchActions.setKey(key));
  },
  setSearchType: (type: DateSearchTypeEnum) => {
    dispatch(unitsSearchActions.setSearchType(type));
  },
  clearError: () => {
    dispatch(unitsSearchActions.setError(''));
  },
  setError: (text: string) => {
    dispatch(unitsSearchActions.setError(text));
  },
  updateAdultsCount: (adults: number) => {
    dispatch(unitsSearchActions.updateAdultsCount(adults));
  },
  updateKidsCount: (kids: number) => {
    dispatch(unitsSearchActions.updateKidsCount(kids));
  },
  updateBedroomCount: (bedroom: number) => {
    dispatch(unitsSearchActions.updateBedroomCount(bedroom));
  },
  setGuestsCount: (guests: ICondoGuests) => {
    dispatch(unitsSearchActions.setGuestsCount(guests));
  },
  selectMonths: (month: string) => {
    dispatch(unitsSearchActions.selectMonths(month));
  },
  setMonths: (months: string[]) => {
    dispatch(unitsSearchActions.setMonths(months));
  },
  getCheckinStatus: (start: string, end: string) => {
    dispatch(getCheckinStatus(start, end));
  },
  resetDates: () => {
    dispatch(unitsSearchActions.resetDates());
  },
  resetFlexibleState: () => {
    dispatch(unitsSearchActions.resetFlexibleState());
  },
  getUnitsDetails: (condoId: number) => {
    dispatch(getUnitsDetails(condoId));
  },
});

export const UnitsSearch = connect(mapStateToProps, mapDispatchToProps)(injectIntl(UnitsSearchComponent));

