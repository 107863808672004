
import { isEmpty } from 'lodash';
import { ICarLocationsState, ICarsDatesState, ICarsDriverState, ICarsFiltersState } from '@share/store/slices';

import differenceInDays from 'date-fns/differenceInDays';

export const CarsMaxDaysRange = 40;

export const GetCarTimeAsNumber = (time: string) => {
  return !isEmpty(time) ? Number(time?.replaceAll(':', '')) : undefined;
}

export const GetCarsFilters = (filtersStore: ICarsFiltersState) => {
  const {
    airCondition,
    budget,
    carType,
    category,
    doors,
    driveType,
    fuel,
    rateDistance,
    rentalCompany,
    seats,
    transmissionType,
    refundable,
    whereLocation,
    simpleType
  } = filtersStore;

  const filters: any = {};

  let emptyFilters = true;

  if (budget?.length) {
    filters.budgetRanges = budget.map((range: string) => {
      const [from, to]: string[] = range.split('-');
      return { from: +from || null, to: +to || null };
    });
    emptyFilters = false;
  }
  if (airCondition?.length) {
    filters.airCondition = airCondition;
    emptyFilters = false;
  }
  if (carType?.length) {
    filters.carType = carType;
    emptyFilters = false;
  }
  if (category?.length) {
    filters.category = category;
    emptyFilters = false;
  }
  if (doors?.length) {
    filters.doors = doors;
    emptyFilters = false;
  }
  if (driveType?.length) {
    filters.driveType = driveType;
    emptyFilters = false;
  }
  if (fuel?.length) {
    filters.fuel = fuel;
    emptyFilters = false;
  }
  if (rateDistance?.length) {
    filters.rateDistance = rateDistance;
    emptyFilters = false;
  }
  if (simpleType?.length) {
    filters.simpleType = simpleType;
    emptyFilters = false;
  }
  if (rentalCompany?.length) {
    filters.rentalCompany = rentalCompany;
    emptyFilters = false;
  }
  if (seats?.length) {
    filters.seats = seats;
    emptyFilters = false;
  }
  if (transmissionType?.length) {
    filters.transmissionType = transmissionType;
    emptyFilters = false;
  }
  if (refundable?.length) {
    filters.refundable = refundable;
    emptyFilters = false;
  }
  if (whereLocation?.length) {
    filters.whereLocation = whereLocation;
    emptyFilters = false;
  }
  
  if (emptyFilters) {
    return undefined;
  }

  return filters;
};

export const isCarsDataValid = (carsLocationsStore: ICarLocationsState, carsDatesStore: ICarsDatesState, carsDriverStore: ICarsDriverState): {
  isDatesValid: boolean;
  isLocationValid: boolean;
  isValid: boolean;
  isDatesMaxError: boolean;
  isTimesError: boolean;
  isDriverCountryError: boolean;
} => {
  const res = {
    isDatesValid: true,
    isLocationValid: true,
    isValid: true,
    isDatesMaxError: false,
    isTimesError: false,
    isDriverCountryError: false,
  };

  if (!carsLocationsStore.pickUp.selectedLocation) {
    res.isLocationValid = false;
    res.isValid = false;
  }

  if (!carsDatesStore.endDateSelected || !carsDatesStore.startDateSelected) {
    res.isDatesValid = false;
    res.isValid = false;
  }

  if (!carsDatesStore.endDateTimeSelected || !carsDatesStore.startDateTimeSelected) {
    res.isDatesValid = false;
    res.isTimesError = true;
    res.isValid = false;
  }

  if (carsDatesStore.endDateSelected && carsDatesStore.startDateSelected) {
    const start = new Date(carsDatesStore.startDateSelected);
    const end = new Date(carsDatesStore.endDateSelected);

    if (differenceInDays(end, start) > CarsMaxDaysRange) {
      res.isDatesValid = false;
      res.isValid = false;
      res.isDatesMaxError = true;
    }
  }
  
  if (!carsDriverStore.driverCountrySelected) {
    res.isDriverCountryError = true;
    res.isValid = false;
  }

  return res;
};
