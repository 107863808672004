import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateSearchTypeEnum } from '@share/common-types';

export interface ICondoStrictDatesState {
  startDate: string;
  endDate: string;
  key: string;
  error: string;
  searchType: DateSearchTypeEnum;
}

const initialState: ICondoStrictDatesState = {
  startDate: undefined,
  endDate: undefined,
  key: 'selection',
  error: '',
  searchType: DateSearchTypeEnum.Flexible,
};

const condoStrictDatesSlice = createSlice({
  name: 'condo-strict-dates',
  initialState,
  reducers: {
    setDates: (
      state: ICondoStrictDatesState,
      { payload }: PayloadAction<{ startDate: string; endDate: string }>,
    ) => {
      state.startDate = payload.startDate;
      state.endDate = payload.endDate;
    },
    setDatesExperiences: (
      state: ICondoStrictDatesState,
      { payload }: PayloadAction<{ startDate: string; endDate: string }>,
    ) => {
      state.startDate = payload.startDate;
      state.endDate = payload.endDate;
    },
    setStartDate: (state: ICondoStrictDatesState, { payload }: PayloadAction<string>) => {
      state.startDate = payload;
    },
    setEndDate: (state: ICondoStrictDatesState, { payload }: PayloadAction<string>) => {
      state.endDate = payload;
    },
    setKey: (state: ICondoStrictDatesState, { payload }: PayloadAction<string>) => {
      state.key = payload;
    },
    setError: (state: ICondoStrictDatesState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setSearchType: (
      state: ICondoStrictDatesState, { payload }: PayloadAction<DateSearchTypeEnum>) => {
      state.searchType = payload;
    },
    setSearchTypeExperiences: ( state: ICondoStrictDatesState, { payload }: PayloadAction<DateSearchTypeEnum>) => {
      state.searchType = payload;
    },
    /* eslint-disable */
    resetDates: (state: ICondoStrictDatesState, payload: PayloadAction<boolean>) => {
      return initialState;
    },
    /* eslint-enable */
  },
});

export const condoStrictDatesActions = condoStrictDatesSlice.actions;

export const condoStrictDatesReducer = condoStrictDatesSlice.reducer;
