import React from 'react';

export const ArrowTopSvg: React.FunctionComponent = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 8.75L7 5.25L3.5 8.75"
      stroke="#0D99D6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
