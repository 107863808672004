import React from 'react';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { BlueButton } from '@share/components';
import { Routes } from '@share/constants';
import { setExternalError, setCondoExternalError } from '@store/slices';
import { RootState, VACATIONS_PAGE } from '@share/utils';
import { ILoginState } from '@share/store/slices';

import ExpiredSessionImgage from '@assets/images/expired_session.png';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setExternalError: (externalError: boolean) => void;
  setCondoExternalError: (externalError: boolean) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  isFromCondo?: boolean;
  isVacationRentals?: boolean;
}

export class ExternalErrorComponent extends React.Component<IProps> {
  reloadPage = (): void => {
    const { setExternalError, setCondoExternalError, isFromCondo = false } = this.props;

    if (isFromCondo) {
      setCondoExternalError(false);
    } else {
      setExternalError(false);
    }

    location.reload();
  };

  render(): React.ReactNode {
    const { isFromCondo = false, loginStore, isVacationRentals } = this.props;
    const { account } = loginStore;

    const url = isFromCondo
      ? `/${account?.name}${Routes.CondoSearch}${location.search}`
      : isVacationRentals ?
      `/${account?.name}${Routes.Search}/${VACATIONS_PAGE}${location.search}` :
      `/${account?.name}${Routes.Search}${location.search}`;

    return (
      <div className="expired-error">
        <img src={ExpiredSessionImgage} alt="" />
        <p className="expired-error__title secondary-font">
          <FormattedMessage id="gone.wrong" />
        </p>
        <p className="expired-error__description">
          <FormattedMessage id="we.are.working.on.the.problem" />
          {', '}
          <span className="expired-error__link-text" onClick={this.reloadPage}>
            <FormattedMessage id="click.here" />
          </span>{' '}
          <FormattedMessage id="refresh.page" />.
        </p>
        <div className="expired-error__btn-wrapper">
          <Link to={url}>
            <BlueButton>
              <FormattedMessage id={isFromCondo ? 'search.condos' : 'search.hotel'} />
            </BlueButton>
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = { setExternalError, setCondoExternalError };

export const ExternalError = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ExternalErrorComponent));
