import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { BlueButton } from '@share/components';
import { Routes } from '@share/constants';

import { ILoginState, IMenuState } from '@share/store/slices';
import { connect } from 'react-redux';
import { RootState } from '@share/utils';

import ExploreGetawaysBackground from '@assets/images/explore-getaways.jpg';
import Chevrons from '@assets/icons/chevrons.png';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
}

interface IProps extends RouteComponentProps, IMapStateToProps {}

function ExploreGetawaysComponent(props: IProps) {

  const { loginStore, menuStore } = props;
  const { account } = loginStore;
  const { items, loading } = menuStore;

  if (items?.isRemoveMemberVideos || loading) {
    return null;
  }

  const goToVideos = () => {
    props.history.push(`/${account?.name}${Routes.Getaways}`);
  };

  return (
    <div className="explore-getaways-rsi-upgrade" style={{ backgroundImage: `url(${ExploreGetawaysBackground})` }}>
      <div className="explore-getaways-rsi-upgrade__wrapper">
        <div className="explore-getaways-rsi-upgrade__info-wrapper">
          <p className="explore-getaways-rsi-upgrade__title">
            <FormattedMessage id="explore.getaways" />
          </p>
          <p className="explore-getaways-rsi-upgrade__description">
            <FormattedMessage id="explore.getaways.message" />
          </p>
          <div className="explore-getaways-rsi-upgrade__btn-wrapper">
            <BlueButton onClick={goToVideos} reverse>
              <FormattedMessage id="view.all.getaways" />
              <div style={{ backgroundImage: `url(${Chevrons})` }} className="image-chevrons" />
            </BlueButton>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    menuStore: state.navigationMenuStore,
  };
};

const ExploreGetawaysWithRouter = withRouter(ExploreGetawaysComponent);

export const ExploreGetaways = connect(mapStateToProps)(ExploreGetawaysWithRouter);
