import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import {
  ICondoGuestsState,
  updateAdultsCount,
  updateKidsCount,
  updateBedroomCount,
  updateIncludeStudio,
  updateAdultsCountExperiences,
  updateKidsCountExperiences,
  updateBedroomCountExperiences,
  updateIncludeStudioExperiences,
} from '@share/store/slices';
import { RootState } from '@share/utils';
import { BlueButton } from '@share/components';
import { ILoginState } from '@share/store/slices';

import { CloseSvg } from '@share/assets';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

import { Guests } from '../guests';

import './style.scss';

interface IMapStateToProps {
  condoGuestsStore: ICondoGuestsState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  updateAdultsCount: (count: number) => void;
  updateKidsCount: (count: number) => void;
  updateBedroomCount: (count: number) => void;
  updateIncludeStudio: (include: boolean) => void;
  updateAdultsCountExperiences: (count: number) => void;
  updateKidsCountExperiences: (count: number) => void;
  updateBedroomCountExperiences: (count: number) => void;
  updateIncludeStudioExperiences: (include: boolean) => void;
}

interface IProps extends IMapDispatchToProps, IMapStateToProps {
  isExperiences?: boolean;
}

interface IState {
  isFocused: boolean;
}

export class CondoGuestsPickerComponent extends React.Component<IProps, IState> {
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  listRef: React.RefObject<HTMLDivElement> = React.createRef();
  state: IState = {
    isFocused: false,
  };

  focus = (): void => {
    if (!this.state.isFocused) {
      this.setState({ isFocused: true });
    }
  };

  blur = (): void => {
    if (this.state.isFocused) {
      this.setState({ isFocused: false });
    }
  };

  handleClickOutside = (event: MouseEvent): void => {
    if (
      (this.wrapperRef && !this.wrapperRef.current?.contains(event.target as Node)) ||
      (event.target as HTMLElement).classList.contains('guests-picker__fixed-bg')
    ) {
      this.blur();
    }
  };

  componentDidMount(): void {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onDone = (): void => {
    this.blur();
  };

  render(): React.ReactNode {
    const { isFocused } = this.state;
    const {
      isExperiences,
      condoGuestsStore,
      loginStore,
      updateAdultsCount,
      updateKidsCount,
      updateBedroomCount,
      updateIncludeStudio,
      updateAdultsCountExperiences,
      updateKidsCountExperiences,
      updateBedroomCountExperiences,
      updateIncludeStudioExperiences,
    } = this.props;
    const { kidsCount, adultsCount, bedroomsCount, includeStudio, error } = condoGuestsStore;

    const account = loginStore?.account;
    const color = account?.searchIconsColor;
    const generalBorderRadius = account?.generalBorderRadius;

    const styleColor = !isEmpty(color)? { color } : {};  
    const style = !isEmpty(generalBorderRadius) ? { borderRadius: generalBorderRadius } : {};  

    return (
      <div className={`guests-picker ${!isEmpty(error) ? 'error' : ''}`} ref={this.wrapperRef}>
        <div
          className={`guests-picker__wrapper ${isFocused ? 'selected' : ''}`}
          onClick={this.focus}
          style={style}
        >
          <div className="guests-picker__left">
            <div className="guests-picker__icon" style={styleColor}>
              <FontAwesomeIcon icon={faUserPlus} />
            </div>
          </div>
          <div className="guests-picker__right">
            <div className="guests-picker__label">
              <FormattedMessage id="room" values={{ count: 2 }} /> &{' '}
              <FormattedMessage id="guest" values={{ count: 2 }} />
            </div>
            <div className="guests-picker__header">
              {(adultsCount || kidsCount || bedroomsCount) ? (
                <>
                  {adultsCount} <FormattedMessage id="adult" values={{ count: adultsCount }} />,{' '}
                  {kidsCount ? (
                    <>
                      {kidsCount} <FormattedMessage id="child" values={{ count: kidsCount }} />,{' '}
                    </>) : null}
                  {!includeStudio ? (
                    <>
                      {bedroomsCount} <FormattedMessage id="bedroom" values={{ count: bedroomsCount }} />
                    </>) : (
                    <>
                      <FormattedMessage id="Studio" />
                    </>)}
                </>) : 
                <FormattedMessage id="click.to.select.rooms.guests" />}
            </div>
          </div>
        </div>
        <div className="guests-picker__dropdown" style={{ display: isFocused ? 'block' : 'none' }}>
          <div className="guests-picker__close-icon" onClick={this.blur}>
            <CloseSvg />
          </div>
          <div className="guests-picker__list-wrapper" ref={this.listRef}>
            <Guests
              condoGuests={condoGuestsStore}
              updateAdultsCount={isExperiences ? updateAdultsCountExperiences : updateAdultsCount}
              updateKidsCount={isExperiences ? updateKidsCountExperiences : updateKidsCount}
              updateBedroomsCount={isExperiences ? updateBedroomCountExperiences : updateBedroomCount}
              updateIncludeStudio={isExperiences ? updateIncludeStudioExperiences : updateIncludeStudio}
            />
          </div>
          <div className="guests-picker__footer">
            <BlueButton onClick={this.onDone}>
              <FormattedMessage id="done" />
            </BlueButton>
          </div>
        </div>
        {isFocused && <div className="guests-picker__fixed-bg" />}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condoGuestsStore: state.condoGuestsStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  updateAdultsCount,
  updateKidsCount,
  updateBedroomCount,
  updateIncludeStudio,
  updateAdultsCountExperiences,
  updateKidsCountExperiences,
  updateBedroomCountExperiences,
  updateIncludeStudioExperiences
};

export const CondoGuestsPicker = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CondoGuestsPickerComponent);
