import React from 'react';

export const LocationGreySvg: React.FunctionComponent = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.06051 14.6548L12.2428 11.4725C13.0818 10.6334 13.6533 9.56429 13.8847 8.40042C14.1162 7.23654 13.9974 6.03015 13.5433 4.93381C13.0891 3.83747 12.3201 2.90042 11.3334 2.24114C10.3467 1.58187 9.18669 1.22998 8.00001 1.22998C6.81333 1.22998 5.65331 1.58187 4.66662 2.24114C3.67993 2.90042 2.91089 3.83747 2.45676 4.93381C2.00262 6.03015 1.88378 7.23654 2.11527 8.40042C2.34676 9.56429 2.91817 10.6334 3.75725 11.4725L6.94026 14.6548C7.22152 14.9358 7.60282 15.0936 8.00038 15.0936C8.39795 15.0936 8.77925 14.9358 9.06051 14.6548ZM10.25 7.22979C10.25 8.47243 9.24265 9.47979 8.00001 9.47979C6.75737 9.47979 5.75001 8.47243 5.75001 7.22979C5.75001 5.98714 6.75737 4.97978 8.00001 4.97978C9.24265 4.97978 10.25 5.98714 10.25 7.22979Z"
      fill="#C5C5C5"
    />
  </svg>
);
