import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { push } from 'react-router-redux';

import { IUser } from '@share/common-types';
import { IAccount } from '@share/common-types';
import { Routes, Urls } from '@share/constants';
import { getHeaders, axiosInstance, Toaster, AppThunk } from '@share/utils';

export enum AccountSaveStatus {
  Processing = 'Processing',
  Error = 'Error',
  Completed = 'Completed'
}

export interface IAccountSaveState {
  loading: boolean;
  status: AccountSaveStatus;
  error: string;
}

const initialState: IAccountSaveState = {
  loading: false,
  status: null,
  error: '',
};

const accountSaveSlice = createSlice({
  name: 'accountSave',
  initialState,
  reducers: {
    setAccountSaveLoading: (state: IAccountSaveState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setAccountSaveStatus: (state: IAccountSaveState, { payload }: PayloadAction<AccountSaveStatus>) => {
      state.status = payload;
    },
    setAccountSaveError: (state: IAccountSaveState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
  },
});

export const { setAccountSaveLoading, setAccountSaveError, setAccountSaveStatus } = accountSaveSlice.actions;

export const accountSaveReducer = accountSaveSlice.reducer;

export const accountSaveSelector = (state: { accountSaveStore: IAccountSaveState }): IAccountSaveState => {
  return state.accountSaveStore;
};

const getAccountByCriteria = async (user: IUser, criteria: any) => {
  return await axiosInstance.post(`${Urls.Accounts}/search`, criteria, {
    ...getHeaders(),
  });
}

const checkAccountExists = async (account: IAccount, user: IUser) => {
  const fieldsToSearch = ['name', 'email', 'username'];
  const duplicatedFields: string[] = [];
  for (let i = 0; i < fieldsToSearch.length; i++) {
    const field = fieldsToSearch[i];
    const key = field as keyof IAccount;
    const criteria = {
      page: 1,
      pageSize: 1000,
      keyword: account[key],
      KeywordField: field,
      KeywordSearchType: 'equal'
    }
    const resSearchByName = await getAccountByCriteria(user, criteria);
    const { accounts } = resSearchByName.data;
    const accountsFiltered: number[] = (accounts as IAccount[]).map(u => u.id).filter(u => u !== account.id);
    if (accountsFiltered.length > 0) {
      duplicatedFields.push(field);
    }
  }
  return duplicatedFields;
}

export const saveAccount = (account: IAccount, logoFile: any, backgroundPhotoFile: any): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setAccountSaveLoading(true));
    dispatch(setAccountSaveStatus(AccountSaveStatus.Processing));

    try {
      const { loginStore } = getState();
      const { user } = loginStore;

      const duplicatedFields = await checkAccountExists(account, user);
      if (duplicatedFields.length > 0) {
        dispatch(setAccountSaveError('Error Saving Account'));
        dispatch(setAccountSaveStatus(AccountSaveStatus.Error));
        const message = duplicatedFields.length > 1 ?
            duplicatedFields.slice(0, -1).join(', ') + ' and ' + duplicatedFields.slice(-1) : 
            duplicatedFields.join(', ')
          dispatch(setAccountSaveLoading(false));
          Toaster.error(`There is an account with same ${message}`);
        return;
      } 

      const data = new FormData() 
      const accountJson = JSON.stringify(account);
      data.append('query', accountJson);
      if (logoFile) {
        data.append('logo', logoFile);
      }
      if (backgroundPhotoFile) {
        data.append('background_photo', backgroundPhotoFile);
      }
  
      const res = await axiosInstance.post(Urls.Accounts, data, {
        ...getHeaders(),
      });

      dispatch(setAccountSaveLoading(false));
      if (res?.status !== 200) {
        dispatch(setAccountSaveError('Error Saving Account'));
        dispatch(setAccountSaveStatus(AccountSaveStatus.Error));
        Toaster.error(`Error occured: Error Saving Account. If the problem persist, please contact system administrator`);
      } else {
        dispatch(setAccountSaveStatus(AccountSaveStatus.Completed));
        dispatch(push(Routes.Accounts));
        const action = account?.id ? 'Updated' : 'Created';
        Toaster.success(`Account ${action} successfully!`);
      }
      
    } catch (error) {
      console.error(error);
      dispatch(setAccountSaveError(error.toString()));
      dispatch(setAccountSaveLoading(false));
      dispatch(setAccountSaveStatus(AccountSaveStatus.Error));
      Toaster.error(`Error occured: ${error.toString()}. If the problem persist, please contact system administrator`);
    }
  };
};
