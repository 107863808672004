import React from 'react';
import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { WhiteButton } from '@components';
import { BlueButton } from '@share/components';

import './style.scss';
import { ILoginState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { connect } from 'react-redux';

interface Message {
  message: string;
  values: any;
}

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {
  message: string | Message;
  title: string;
  yesMessage: string;
  noMessage: string;
  visible: boolean;
  disabled?: boolean;

  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>, isScrollToRooms?: boolean) => void;
  onConfirm: (e: React.MouseEvent<HTMLElement, MouseEvent>, isScrollToRooms?: boolean) => void;
}

function ModalConfirmationComponent(props: IProps) {

  const {
    title,
    message,
    yesMessage,
    noMessage,
    visible,
    disabled,
    onCancel,
    onConfirm,
    loginStore
  } = props;
  const { account } = loginStore;
  
  let values = {};
  if (typeof message !== 'string') {
    values = { ...message.values };
  }

  values = { 
    ...values,
    BR: () => <br />,
    title: (message: string) => (<label style={{ width: '100px' }}>{message}</label>),
    strong: (message: string) => (<strong>{message}</strong>)
  };

  const fontFamily = account?.fontFamily;
  const secondaryFontFamily = account?.secondaryFontFamily;

  return (
    <Modal
      className={`modal-cancellation-policies ${fontFamily ? `${fontFamily}-font`: ''} ${secondaryFontFamily ? `secondary-${secondaryFontFamily}-font`: ''}`}
      open={visible}
      footer={null}
      onCancel={onCancel}
      wrapClassName="modal-cancellation-policies__wrapper"
    >
      <div>
        <h4 className="modal-cancellation-policies__title secondary-font">
          <FormattedMessage id={title} />
        </h4>
        <div className="modal-cancellation-policies__refundability">
          <p className="modal-cancellation-policies__refundability-description">
            <FormattedMessage id={typeof message === 'string' ? message : message.message}  values={values} />
          </p>
          <div className="modal-cancellation-policies__buttons">
            <WhiteButton onClick={onCancel} disabled={disabled} >
              <FormattedMessage id={noMessage} />
            </WhiteButton>
            <BlueButton onClick={onConfirm} disabled={disabled} >
              <FormattedMessage id={yesMessage} />
            </BlueButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}
const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

export const ModalConfirmation = connect(mapStateToProps)(ModalConfirmationComponent);
