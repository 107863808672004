import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICondoDateRange, IQuote } from '@share/common-types';
import { AppThunk, Toaster } from '@share/utils';
import { QuotesAPI } from '@share/api';
import moment, { Moment } from 'moment';

export interface IQuoteState {
  loading: boolean;
  errorHotels: string;
  errorCondos: string;
  result: {
    quotesHotels: IQuote[];
    quotesCondos: IQuote[];
    quotesHistorical: IQuote[];
  }
}

const initialState: IQuoteState = {
  loading: true,
  errorHotels: '',
  errorCondos: '',
  result: {
    quotesHotels: [],
    quotesCondos: [],
    quotesHistorical: []
  }
};

const quotesSlice = createSlice({
  name: 'quotes',
  initialState,
  reducers: {
    setLoading: (state: IQuoteState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setErrorHotels: (state: IQuoteState, { payload }: PayloadAction<string>) => {
      state.errorHotels = payload;
    },
    setErrorCondos: (state: IQuoteState, { payload }: PayloadAction<string>) => {
      state.errorCondos = payload;
    },
    setQuotesHotels: (state: IQuoteState, { payload }: PayloadAction<IQuote[]>) => {
      state.result.quotesHotels = payload;
    },
    setQuotesCondos: (state: IQuoteState, { payload }: PayloadAction<IQuote[]>) => {
      state.result.quotesCondos = payload;
    },
    setQuotesHistorical: (state: IQuoteState, { payload }: PayloadAction<IQuote[]>) => {
      state.result.quotesHistorical = payload;
    }
  },
});

export const quotesActions = quotesSlice.actions;
export const quotesReducer = quotesSlice.reducer;

const isValidCheckIn = (checkIn: string, today: Moment) => moment(checkIn, 'yyyy-MM-DD').isAfter(today);
const isValidAvailableDates = (availableDates: ICondoDateRange[], today: Moment) => availableDates?.filter(a => moment(a.from, 'yyyy-MM-DD').isAfter(today)).length > 0;

export const getQuotes = (id: any): AppThunk => {
  return async (dispatch) => {
    dispatch(quotesActions.setLoading(true));
    dispatch(quotesActions.setQuotesHotels([]));

    let error = false;

    try {
      const response = await QuotesAPI.get(id);
      if (response.status === 200) {
        const { data } = response;
        const today = moment().format('yyyy-MM-DD');
        const todayMoment = moment(today, 'yyyy-MM-DD');

        const hotels = data?.length ? [...data.filter((d: IQuote) => !d.isCondo && isValidCheckIn(d.checkIn, todayMoment))] : [];
        const condos = data?.length ? [...data.filter((d: IQuote) => d.isCondo && isValidAvailableDates(d.availableDates, todayMoment))] : [];
        const historical = data?.length ? [...data.filter((d: IQuote) => (!d.isCondo && !isValidCheckIn(d.checkIn, todayMoment) || (d.isCondo && !isValidAvailableDates(d.availableDates, todayMoment))))] : [];

        dispatch(quotesActions.setQuotesHotels(hotels));
        dispatch(quotesActions.setQuotesCondos(condos));
        dispatch(quotesActions.setQuotesHistorical(historical));
      } else {
        dispatch(quotesActions.setErrorHotels(`[${response.status}] ${response.statusText}`));
        Toaster.error(`An error occurred. [${response.status}] ${response.statusText}. If the problem persists, please contact the system administrator.`);
        error = true;
      }
    } catch (error) {
      console.error(error);
      dispatch(quotesActions.setErrorHotels(error.toString()));
      Toaster.error(`An error occurred: ${error.toString()}. If the problem persists, please contact the system administrator.`);
      error = true;
    }

    dispatch(quotesActions.setLoading(false));
  };
};