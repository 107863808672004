import { AxiosPromise } from 'axios';
import { axiosInstance, getHeaders } from '@share/utils';
import { Urls } from '@share/constants';
import { IMyProfile } from '@share/common-types';

export interface IAuthorizedUser {

}

export const TravelHistoryAPI = {
  get(id: number): AxiosPromise<any> {
    return axiosInstance.get(`${Urls.getTravelHistory}?memberId=${id}`, { ...getHeaders() });
  }
};

export const MyProfileAPI = {
  get(id: number): AxiosPromise<any> {
    return axiosInstance.get(`${Urls.ssoMember}?memberId=${id}`, { ...getHeaders() });
  },

  update(id: number, profile: IMyProfile): AxiosPromise<any> {
    return axiosInstance.put(Urls.ssoMember, { memberId: id, data: profile }, { ...getHeaders() });
  },

  addAuthorizedUser(data: IAuthorizedUser): AxiosPromise<any> {
    return axiosInstance.post(`${Urls.ssoMember}/authuser`, data, { ...getHeaders() });
  },

  deleteAuthorizedUser(rsiId: number, authId: number): AxiosPromise<any> {
    return axiosInstance.delete(`${Urls.ssoMember}/authuser`, { ...getHeaders(), data: { rsiId, authId } });
  },
};
