import React from 'react';

import { ReservationCancellationWrapper } from '@components';

import './style.scss';

interface IProps {}

export class ReservationCancellation extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <div>
        <ReservationCancellationWrapper />
      </div>
    );
  }
}
