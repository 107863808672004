
import { RSIUpgradeProfile, RSIUpgradeProfileHome } from "./rsi-upgrade";
import { SportsIllustratesProfile } from "./sports-illustrates";

export const RSI_UPGRADE_PROFILE = 'RSI_UPGRADE';
export const SPORTS_ILLUSTRATES_PROFILE = 'SPORTS_ILLUSTRATES';

export enum IProfileEnum {
  SportsIllustrates = SPORTS_ILLUSTRATES_PROFILE,
  RSIUpgrade = RSI_UPGRADE_PROFILE
};

export enum IPageType {
  Home,
  Flow,
}

export const GetProfile = (profile: IProfileEnum, pageType?: IPageType) => {
  if (profile === IProfileEnum.SportsIllustrates) {
    return SportsIllustratesProfile;
  } else if (profile === IProfileEnum.RSIUpgrade) {
    if (pageType === IPageType.Home) {
      return RSIUpgradeProfileHome;
    }
    return RSIUpgradeProfile;
  }
  return null;
}