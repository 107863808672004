import React from 'react';

export const CarSvg: React.FunctionComponent = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.66671 12.6667C5.40309 12.6667 6.00004 12.0697 6.00004 11.3333C6.00004 10.597 5.40309 10 4.66671 10C3.93033 10 3.33337 10.597 3.33337 11.3333C3.33337 12.0697 3.93033 12.6667 4.66671 12.6667Z"
      fill="#292929"
      stroke="#292929"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3333 12.6667C12.0697 12.6667 12.6667 12.0697 12.6667 11.3333C12.6667 10.597 12.0697 10 11.3333 10C10.597 10 10 10.597 10 11.3333C10 12.0697 10.597 12.6667 11.3333 12.6667Z"
      fill="#292929"
      stroke="#292929"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 7.33333V4M3.33333 11.3333H2V7.33333L3.33333 4H9.33333L12 7.33333H12.6667C13.0203 7.33333 13.3594 7.47381 13.6095 7.72386C13.8595 7.97391 14 8.31305 14 8.66667V11.3333H12.6667H3.33333ZM10 11.3333H6H10ZM2 7.33333H12H2Z"
      stroke="#292929"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
