
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { Loading } from '@share/components';
import { LoadingSise } from '@common-types';

import { DashboardReportTable, TableHeader } from '../dashboard-report-table';

import './style.scss';

interface IProps extends RouteComponentProps, WrappedComponentProps {
  title: string;
  loading: boolean;
  topList: any[];
  header: TableHeader[];
}

function DashboardReportTopBaseComponent(props: IProps) {
  const { loading, topList, header, title } = props;

  return (
    <div className="dasshboard-reports-top-base-wrapper">
      <div className="dasshboard-reports-top-base-wrapper__title">
        <h1><FormattedMessage id={title} /></h1>
      </div>
      <div className="dasshboard-reports-top-base-wrapper__content centered">
        {loading ?
          <Loading size={LoadingSise.Normal} /> : 
          <DashboardReportTable list={topList} header={header} />}
      </div>
    </div >
  );
}

const ComponentIntl = injectIntl(DashboardReportTopBaseComponent);

export const DashboardReportTopBase = connect()(withRouter(ComponentIntl));
