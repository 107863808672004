
import { useMemo, useState } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { Select, Tooltip } from 'antd';
import { get } from 'lodash';

import { renderNumber } from '@share/utils';

import { DashboardReportEmpty } from '../dashboard-report-empty';

import './style.scss';

const PAGE_SIZE = 5;

export enum ColumnType {
  TEXT,
  NUMBER,
  PERCENTAGE,
  CURRENCY
}

export interface TableHeader {
  text: string;
  title?: string;
  width: number;
  type: ColumnType;
}

interface IProps extends RouteComponentProps, WrappedComponentProps {
  list: any[];
  header: TableHeader[];
}

function DashboardReportTableComponent(props: IProps) {
  const [currentPage, setCurrentPage] = useState(1);
  
  const { list, header, intl } = props;

  const listFiltered = useMemo(() => list?.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE), [list, currentPage]);
  const pages = useMemo(() => Math.ceil(list?.length / PAGE_SIZE), [list]);
  const pageList = useMemo(() => Array.from({length: pages}, (_, i) => i + 1), [pages]);
  const page = useMemo(() => (currentPage - 1) * PAGE_SIZE, [currentPage]);
  const options = useMemo(() => pageList.map(o => <Select.Option key={o} value={o}>{o}</Select.Option>), [pageList]);
  
  const headerRow = useMemo(() => (
    <div className="content-table__header">
      {header?.map((h, i) => {
        const headerType = header[i]?.type;
        const key = h?.title ? h?.title : h?.text;
        return (
          <div key={h?.text} className="header-item" style={{ width: `${h?.width}%`, paddingLeft: '5px', textAlign: [ColumnType.CURRENCY].includes(headerType) ? 'right' : 'left' }}>
            <Tooltip title={intl.formatMessage({ id: key })} placement="top"><FormattedMessage id={key} /></Tooltip>
          </div>
        );
      })}
    </div>), [header]);

  const getText = (text: string, type: ColumnType) => {
    if (type === ColumnType.NUMBER) {
      return renderNumber(Number(text), 0);
    } else if (type === ColumnType.PERCENTAGE) {
      return `${renderNumber(Number(text), 0)}%`;
    } else if (type === ColumnType.CURRENCY) {
      return `$ ${renderNumber(Number(text), 2)}`;
    }
    return text;
  }

  const content = useMemo(() => listFiltered?.map((c, index) => (
    <div key={`row_${index}`} className={`content-table__content ${index % 2 ? '' : 'grey-background'}`}>
      {header?.map((h, i) => {
        const headerType = header[i]?.type;
        const headerField = header[i]?.text;
        const text = getText(get(c, headerField), headerType);

        return (
          <div key={`${h?.text}_${i}`} className="content-item" style={{ width: `${h?.width}%`, textAlign: [ColumnType.CURRENCY].includes(headerType) ? 'right' : 'left' }}>
            <Tooltip title={text}>{text}</Tooltip>
          </div>)
        })}
    </div>)), [listFiltered, header]);

  if (!list) {
    return null;
  }

  return (
    <div className="dasshboard-reports-table-wrapper">
      <div className="content-table">
        {headerRow}
        {!list?.length ? <DashboardReportEmpty /> : content}
      </div>
      <div className="content-footer">
        <div>Displaying {!page ? 1 : page} to {page + listFiltered?.length} of {list?.length}</div>
        <div><label style={{ marginRight: '6px' }}>Page</label><Select value={currentPage} onChange={v => setCurrentPage(Number(v))}>{options}</Select></div>
      </div>
    </div>
  );
}

const ComponentIntl = injectIntl(DashboardReportTableComponent);

export const DashboardReportTable = connect()(withRouter(ComponentIntl));
