import React from 'react';

import { Status } from '../status';

import { IReservation } from '@share/common-types';
import { FormattedMessage } from 'react-intl';

import './style.scss';

interface IProps {
  refundability: string;
}

interface IPriceProps {
  record: IReservation;
}

export class ReservationRefundability extends React.Component<IProps> {
  getText = (): string => {
    const { refundability } = this.props;
    const map: any = {
      ['false']: 'non-refundable',
      ['true']: 'refundable',
    };
  
    return `bookings.booking.status.${map[refundability]}`;
  };

  getClass = (): string => {
    const { refundability } = this.props;
    const map: any = {
      ['false']: 'non-refundable',
      ['true']: 'refundable',
    };

    return map[refundability];
  };

  render(): React.ReactNode {
    if (this.props.refundability) {
      return <Status
        text={this.getText()}
        className={this.getClass()} />;
    }

    return null;
  }
}

export class ReservationPrice extends React.Component<IPriceProps> {
  getText = (): string => {
    const { record } = this.props;
    const map: any = {
      ['false']: 'non-refundable',
      ['true']: 'refundable',
    };
  
    return `bookings.booking.status.${map[record?.refundable.toString()]}`;
  };

  render(): React.ReactNode { //<Currency currency={currency} />{renderNumber(totalAmount, 2)}
    return (<div className="price-col"><div>{this.props.record?.priceDetail?.totalPaid}</div><div className="refundable-col">{<FormattedMessage id={this.getText()} />}</div></div>);
  }
}