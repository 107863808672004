import React from 'react';

import NotResultImg from '@assets/images/no_all_results.png';
import NotCondostImg from '@assets/images/no_condos_results.png';
import Image from '@assets/images/availability-status.png';

import './style.scss';

export enum TypeToShow {
  NOT_RESULT = 1,
  NOT_GETAWAYS = 2,
}

interface IProps {
  title: string;
  type?: TypeToShow;
}

const NotGetawayToShow: React.FC<IProps> = ({ title, type }) => {
  return (
    <section className="not__getaway">
      <img
        src={
          type === TypeToShow.NOT_RESULT
            ? NotResultImg
            : type === TypeToShow.NOT_GETAWAYS
            ? NotCondostImg
            : Image
        }
        alt=""
        className="not__getaway-img"
      />
      <h1 className="not__getaway-title">{title}</h1>
    </section>
  );
};

export default NotGetawayToShow;
