import React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { get, isEmpty } from 'lodash';

import { InfoColorSvg, BookingConfirmedSvg } from '@assets';
import { DATE_FORMAT_DAY_MONTH } from '@constants';
import { Routes } from '@share/constants';
import { BlueButton } from '@share/components';
import { CallbackFunction, GetHomeParams, RootState, VACATIONS_PAGE } from '@share/utils';
import { IReviewBookState } from '@store/slices';
import { ILoginState, IMenuState } from '@share/store/slices';
import { formatDateCheckInOut, getAccountUsernameFromPath } from '@share/utils';
import { resetHotelsFull, setLoginRedirect } from '@share/store/slices';
import { InsuranceConfirmed, VoucherModal } from '@components';
import { voucherBooking, downloadVoucher } from '@share/store/slices';

import { CancellationPolicies } from '../../review-book/cancellation-policies';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';


import './style.scss';

interface IMapStateToProps {
  menuStore: IMenuState;
  loginStore: ILoginState;
  reviewBookStore: IReviewBookState;
}

interface IMapDispatchToProps {
  resetHotelsFull: () => void;
  setLoginRedirect: (redirect: string) => void;
  voucherBooking: (bookingId: string, recepientEmail: string, additionalEmail: string, callback: CallbackFunction) => void;
  downloadVoucher: (bookingId: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  packageData: IReviewBookState;
  isBookingComplete: boolean;
  isVacationRentals: boolean;
}

interface IState {
  showVoucherModal: boolean;
  emailResend: string;
  emailAdditional: string;
  isAdditionalEmailAdded: boolean;
}

class BookingConfirmedComponent extends React.Component<IProps, IState> {
  state: IState = { showVoucherModal: false, emailAdditional: '', emailResend: '', isAdditionalEmailAdded: false };

  nameMainGuest = (): string => {
    return this.props.packageData.guests.find((guest) => guest.isMainGuest === true).givenName;
  };

  updateMenu = (): void => {
    const { loginStore, history } = this.props;
    const { account } = loginStore;

    if (account?.isDisableHomes) {
      if (!isEmpty(account?.disableHomesRedirect)) {
        window.location.replace(account?.disableHomesRedirect);
      } else {
        history.push(Routes.NotAuthorized);
      }
    } else {
      this.props.resetHotelsFull();
  
      const accountName = getAccountUsernameFromPath(this.props.history);
  
      const homeParams = GetHomeParams(account);

      if (this.props.isVacationRentals) {
        this.props.history.push(`/${accountName}${Routes.Search}/${VACATIONS_PAGE}${homeParams}`);
      } else {
        this.props.history.push(`/${accountName}${Routes.Search}${homeParams}`);
      }
    }
  };

  handleDownloadVoucher = () => {
    const { reviewBookStore, downloadVoucher } = this.props;
    const { booking } = reviewBookStore;

    const bookingId = get(booking?.hotelSegments, '[0].bookingGuid');

    downloadVoucher(bookingId);
  }

  hangleGoToLogin = () => {
    const { loginStore, reviewBookStore, history } = this.props;
    const { account } = loginStore;
    const { booking } = reviewBookStore;

    const { pathname, search } = history.location;

    const bookingGuid = get(booking, 'hotelSegments[0].bookingGuid');

    this.props.setLoginRedirect(`${pathname}${isEmpty(search) ? '' : search}`);

    this.props.history.push(`/${account.name}${Routes.Login}/${bookingGuid}`);
  }

  hangleGoToSignUp = () => {
    const { loginStore, reviewBookStore, history } = this.props;
    const { account } = loginStore;
    const { booking } = reviewBookStore;

    const { pathname, search } = history.location;

    const bookingGuid = get(booking, 'hotelSegments[0].bookingGuid');

    this.props.setLoginRedirect(`${pathname}${isEmpty(search) ? '' : search}`);

    this.props.history.push(`/${account.name}${Routes.SignUp}/${bookingGuid}`);
  }

  handleVoucherBooking = (recepientEmail: string, additionalEmail: string) => {
    const { reviewBookStore } = this.props;
    const { booking } = reviewBookStore;
    const bookingId = get(booking?.hotelSegments, '[0].bookingGuid');

    this.props.voucherBooking(bookingId, recepientEmail, additionalEmail, () => this.setState({ showVoucherModal: false }));
  }

  render(): React.ReactNode {
    const { packageData, isBookingComplete, reviewBookStore, loginStore } = this.props;
    const { showVoucherModal, emailResend, emailAdditional, isAdditionalEmailAdded } = this.state;
    const { account, userWallet } = loginStore;
    const { selectedHotelReviewClientCash, bookingSummary, bookingComplete, booking, guests } = reviewBookStore;

    const email = get(guests, '[0].email');

    const nameHotel = packageData.bookingSummary.bookingCard.name;
    const startDate = packageData.bookingSummary.bookingCard.checkIn;
    const balance = bookingSummary.bookingCard.balance;

    const isLogged = !!userWallet;

    const locale = account?.locale;
    const disableDownloadVoucherCheckout = account.disableDownloadVoucherCheckout;
    const hasClientCash = account.hasClientCash;
    const clientCashPercentage = account.clientCashPercentage;
    const currency = bookingSummary.bookingCard.package.currency;

    const usedClientCash = (bookingComplete && booking) ? get(booking?.hotelSegments, '[0].clientCash', 0) : 0;
    const clientCash = bookingComplete ? usedClientCash : (selectedHotelReviewClientCash ? selectedHotelReviewClientCash : 0)
    const finalBalanceIntermediate = balance - clientCash;
    const finalBalanceCalculated = Math.floor(finalBalanceIntermediate * 100) / 100;
    const finalBalance = finalBalanceCalculated > 0 ? finalBalanceCalculated : 0;
    const eligibleClientCash = finalBalance > 0 ? finalBalance * (!isEmpty(clientCashPercentage) ? parseInt(clientCashPercentage) : 0) / 100 : 0;

    return (
      isBookingComplete &&
      packageData.booking && (
        <div className="booking-confirmed">
          <div className="success-logo-container">
            <div className='col-md-3'>
              <BookingConfirmedSvg />
            </div>
            <div className='col-md-8' style={{padding: '8px'}}>
              <p className='title-name'><FormattedMessage id="thank.you" />, {this.nameMainGuest()}!</p>
              <p className='title-confirmed'>
                <FormattedMessage id="your.booking.in" /> {nameHotel}{' '}
                <FormattedMessage id="is.confirmed" />.
              </p>
              <p className='title-email'><FormattedMessage id="email.sent" values={{ email: email }} /></p>
            </div>
          </div>

          {
            !isEmpty(packageData.booking.warningMessage) &&
            <div className="booking-hotel-reservation__warning">
              <InfoColorSvg /> {packageData.booking.warningMessage}
            </div>
          }
          
          <p className="booking-confirmed__description">
            <span className="booking-confirmed__description_item">{nameHotel} </span>
            <FormattedMessage id="is.expecting.you.on" />{' '}
            <span className="booking-confirmed__description_item">
              {formatDateCheckInOut(startDate, DATE_FORMAT_DAY_MONTH, locale)}
            </span>
          </p>
          <p className="booking-confirmed__number-info">
            <FormattedMessage id="confirmation.number" />:{' '}
            <span className="booking-confirmed__number">
              {packageData.booking.confirmationCode}
            </span>
          </p>
          <p className="booking-confirmed__instructional-text">
            <InfoColorSvg />
            <FormattedMessage id="you.may.be.asked" />
          </p>
          <p className="booking-confirmed__instructional-text">
            <InfoColorSvg />
            <FormattedMessage id="you.may.need.this.number" />
          </p>
          <p className="booking-confirmed__instructional-text">
            <InfoColorSvg />
            <label className="condo-guest-info__guest-message" style={{ fontSize: '13px' }}>
              <FormattedMessage id="name.changes" />
            </label>
          </p>
          
          {packageData.booking.memberId ? (
            <p className="booking-confirmed__number-info">
              <FormattedMessage id="member.ID" />:{' '}
              <span className="booking-confirmed__number">{packageData.booking.memberId}</span>
            </p>) : null}

          <CancellationPolicies />

          <p className="booking-confirmed__contact-property">
            <FormattedMessage id="contact.property" />:{' '}
            <span className="booking-confirmed__contact-property-phone">
              {packageData.booking.resortAddress.phone}
            </span>
          </p>
          <p className="booking-confirmed__contact-property">
            <FormattedMessage id="support" />:{' '}
            <span className="booking-confirmed__contact-property-phone">
              {packageData.booking.customerServiceNumber}
            </span>
          </p>

          <p className="condo-booking-confirmed__contact-property" style={{ display: 'flex' }}>
            <FormattedMessage id="confirmation.resend.email" />{' '}
            <span className="condo-booking-confirmed__contact-property-phone" style={{ marginLeft: '5px' }}>
              <div style={{ cursor: 'pointer', textDecoration: 'underline', color: '#40a9ff'}} onClick={() => this.setState({ showVoucherModal: true })}>
                <FormattedMessage id="confirmation.resend.email.resend" />
              </div>
            </span>
          </p>

          {(hasClientCash && !isLogged && !usedClientCash) && (
            <div className="booking-confirmed__client-cash-login">
              <p className="booking-confirmed__contact-property" style={{ marginTop: '0px', fontSize: '15px', paddingTop: '0px' }}>
                <FontAwesomeIcon icon={faTriangleExclamation} />{' '}<FormattedMessage id="wallet.client.not_logged.thanks" values={{ clientCashPercentage, currency: currency, reward: eligibleClientCash.toFixed(2), clientCashName: account?.walletClientCashName }} />
              </p>
              <p className="booking-confirmed__contact-property" style={{ fontSize: '18px', marginTop: '10px', textAlign: 'right' }}>
                <BlueButton onClick={this.hangleGoToLogin}><FormattedMessage id="sign.in" /></BlueButton> <FormattedMessage id="or" /> <BlueButton onClick={this.hangleGoToSignUp}><FormattedMessage id="sign.up" /></BlueButton>
              </p>
            </div>)}

          <InsuranceConfirmed insurance={booking?.insurance} />

          <div className="booking-confirmed__btn-wrapper" style={{ justifyContent: (!disableDownloadVoucherCheckout ? 'space-between' : 'flex-end') }}>
            {!disableDownloadVoucherCheckout ? (
              <BlueButton onClick={this.handleDownloadVoucher}>
                <FormattedMessage id="download.voucher" />
              </BlueButton>) : null}
            <BlueButton onClick={this.updateMenu}>
              <FormattedMessage id="confirmation.go.back_home" />
            </BlueButton>
          </div>

          <VoucherModal
            visible={showVoucherModal}
            loading={reviewBookStore?.loading || loginStore?.loading}
            onCancel={() => this.setState({ showVoucherModal: false })}
            onVoucher={this.handleVoucherBooking}
            email={emailResend}
            emailAdditional={emailAdditional}
            isAdditionalEmailAdded={isAdditionalEmailAdded}
            setEmailModal={(emailResend) => this.setState({ emailResend })}
            setEmailAdditionalModal={(emailAdditional) => this.setState({ emailAdditional })}
            setIsAdditionalEmailAddedModal={(isAdditionalEmailAdded) => this.setState({ isAdditionalEmailAdded })}
          />

        </div>
      )
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
    reviewBookStore: state.reviewBookStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = { 
  resetHotelsFull,
  setLoginRedirect,
  voucherBooking,
  downloadVoucher
};

export const BookingConfirmed = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(BookingConfirmedComponent));
