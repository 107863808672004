import React from 'react';

import { LuxuryCruises } from '@components';

import './style.scss';

interface IProps {}

export class LuxuryCruisesPage extends React.Component<IProps> {
  render(): React.ReactNode {
    return <LuxuryCruises />;
  }
}
