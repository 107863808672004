import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import purify from 'dompurify';
import { RootState } from '@share/utils';
import { IReviewBookState } from '@store/slices';

import { ILoginState } from '@share/store/slices';
import { get } from 'lodash';
import { Checkbox } from 'antd';
import { Refundability } from '@components';
import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';

import './style.scss';
import { NON_REFUNDABLE_TYPE } from '@constants';

interface IMapStateToProps {
  reviewBookStore: IReviewBookState;
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps {
  displayCheckbox?: boolean;
  disable?: boolean;
  
  onChangeAcceptPolicy?: (e: { target: CheckboxChangeEventTarget }) => void;
}

interface IState {
  showMoreBookingRemarks: boolean;
}

const zero = 0;

class CancellationPoliciesComponent extends React.Component<IProps, IState> {
  
  state: IState = { showMoreBookingRemarks: false };
  wrapperRef: React.RefObject<HTMLParagraphElement> = React.createRef();

  getBookingRemarks = (): string => {
    const { reviewBookStore } = this.props;
    const { bookingSummary } = reviewBookStore;
    const { packageCancellationPolicies } = bookingSummary;

    if (packageCancellationPolicies && packageCancellationPolicies[zero]) {
      const { bookingCancellationPolicy } = packageCancellationPolicies[zero];

      if (bookingCancellationPolicy) {
        return bookingCancellationPolicy.bookingRemarks;
      }
    }

    return '';
  };

  render(): React.ReactNode {
    const { showMoreBookingRemarks } = this.state;
    const { reviewBookStore, loginStore, disable, displayCheckbox, intl, onChangeAcceptPolicy } = this.props;
    const { account, userWallet } = loginStore;
    const { bookingSummary, selectedHotelReviewClientCash, bookingComplete, booking } = reviewBookStore;
    const { cancellationPolicyText, refundabilityText } = bookingSummary;
    
    const { refundability } = bookingSummary.bookingCard?.package;

    const bookingRemarks = this.getBookingRemarks();

    const hasClientCash = account?.hasClientCash;
    const walletIsExternal = account?.walletIsExternal;

    const isLogged = !!userWallet;

    const usedClientCash = (bookingComplete && booking) ? get(booking?.hotelSegments, '[0].clientCash', 0) : 0;
    const cancellationPolicyTextFinal = (!walletIsExternal && hasClientCash && ((isLogged && selectedHotelReviewClientCash > 0) || usedClientCash > 0)) ?
        intl.formatMessage({ id: 'cancellation.not_refundable.message.policy' }) :
        cancellationPolicyText;
    
    const refundabilityFinal = (!walletIsExternal && hasClientCash && isLogged && (selectedHotelReviewClientCash > 0 || usedClientCash > 0)) ? NON_REFUNDABLE_TYPE : refundability;

    return (
      <div className="cancellation-policies">
        <div className="cancellation-policies__header policy">
          <h4 className="cancellation-policies__title secondary-font">
            <FormattedMessage id="cancellation.policy" />:
          </h4>

          <Refundability />
        </div>

        <div className="cancellation-policies__info">
          <p className="cancellation-policies__info-description">{cancellationPolicyTextFinal}</p>
        </div>

        {(!bookingComplete && NON_REFUNDABLE_TYPE === refundabilityFinal) ? (
          <div className="cancellation-policies__info">
            <p className="cancellation-policies__info-description"><FormattedMessage id="no-refundble.acknowledge" /></p>
          </div>) : null}

        {displayCheckbox ? (
          <div className="review-book__wrapper" style={{ flexDirection: 'row' }}>
            <Checkbox onChange={onChangeAcceptPolicy} disabled={disable} />
            <p className="review-book__accept-text">
              <FormattedMessage id="by.checking.this.box.you.acknowledge.cancellation.policy" />
            </p>
          </div>) : null}

        {refundabilityText && (
          <div className="cancellation-policies__info">
            <p className="cancellation-policies__info-title">
              <FormattedMessage id="refundability" />:
            </p>
            <p className="cancellation-policies__info-description">{refundabilityText}</p>
          </div>
        )}
        {bookingRemarks && (
          <div className="cancellation-policies__info">
            <div className="cancellation-policies__header">
              <h4 className="cancellation-policies__title secondary-font">
                <FormattedMessage id="booking.remarks" />:
              </h4>
            </div>
            <p
              className={`cancellation-policies__info-description ${!showMoreBookingRemarks ? 'booking-remarks' : ''}`}
              dangerouslySetInnerHTML={{ __html: purify.sanitize(bookingRemarks) }}
              ref={this.wrapperRef}
            >
            </p>
            
            <div className="booking-remarks__show-more" onClick={() => this.setState({ showMoreBookingRemarks: !showMoreBookingRemarks })}>
              <FormattedMessage id={!showMoreBookingRemarks ? 'show.more' : 'show.less'} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    reviewBookStore: state.reviewBookStore,
    loginStore: state.loginStore
  };
};

export const CancellationPolicies = connect(mapStateToProps)(injectIntl(CancellationPoliciesComponent));
