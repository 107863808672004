import React from 'react';

export const ChildSvg: React.FunctionComponent = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99994 7C11.6568 7 12.9999 5.65685 12.9999 4C12.9999 2.34315 11.6568 1 9.99994 1C8.34308 1 6.99994 2.34315 6.99994 4C6.99994 5.65685 8.34308 7 9.99994 7ZM9.99994 7H7.99989C7.76632 7 7.55145 7.08008 7.38124 7.21428L7.37959 7.21185L3.62808 9.75761C3.21047 10.041 3.10166 10.6093 3.38505 11.0269C3.66844 11.4445 4.23671 11.5533 4.65433 11.2699L6.99989 9.67823V12H12.9999V9.72445L14.9651 11.0581C15.3828 11.3415 15.951 11.2326 16.2344 10.815C16.5178 10.3974 16.409 9.82915 15.9914 9.54576L12.7655 7.35672C12.6476 7.21651 12.4919 7.10916 12.3141 7.05036L12.2399 7L12.2229 7.02497C12.1512 7.00863 12.0766 7 11.9999 7H9.99994ZM7.43046 12.4923L9.08824 14.6015L7.30318 16.0045L8.42421 17.4308C8.73609 17.8276 8.66725 18.4021 8.27046 18.7139C7.87367 19.0258 7.29918 18.957 6.9873 18.5602L5.86628 17.1339L5.85606 17.142L5.43419 16.6052C4.75162 15.7368 4.90227 14.4795 5.7707 13.7969L7.43046 12.4923ZM11.1133 14.6015L12.7711 12.4923L14.4308 13.7969C15.2993 14.4795 15.4499 15.7368 14.7673 16.6052L14.3455 17.142L14.3352 17.1339L13.2142 18.5602C12.9023 18.957 12.3279 19.0258 11.9311 18.7139C11.5343 18.4021 11.4654 17.8276 11.7773 17.4308L12.8983 16.0045L11.1133 14.6015Z"
      fill="#C5C5C5"
    />
  </svg>
);
