import React from 'react';

import { Modal, Spin } from 'antd';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { IHotel, IRoom, ISessionKey } from '@share/common-types';
import { IHotelInfo, RefundableEnum } from '@common-types';
import { IMarginatorState, hotelsActions, marginatorActions, ILoginState, ILocationsState, IMenuState } from '@share/store/slices';
import { RootState, formatDateCheckInOut, renderNumber, UrlUtils, LoginType, GetDisableNewTabOpen } from '@share/utils';
import { hotelDetailsActions, roomsSearchActions } from '@store/slices';
import { AVAILABILITY_SOLDOUT } from '@share/constants';
import { getAmenityImage, openHotelPage, HasPermission, ViewQuotesSharePermission } from '@utils';
import { Currency, HotelStars, ModalShare } from '@components';
import { BlueButton } from '@share/components';
import { DATE_FORMAT_CHECK_IN_OUT, LOCATION_OBJECT_LABEL, NULL_VALUE } from '@constants';
import { DownLoadShareType, HandleDownload } from '@share/api';

import './style.scss';

const modalWidth = 900;
const percents = 100;

interface IMapStateToProps {
  marginatorStore: IMarginatorState;
  loginStore: ILoginState;
  locationsStore: ILocationsState;
  menuStore: IMenuState;
}

interface IMapDispatchToProps {
  setCommission: (value: number) => void;
  setSupplier: (value: string) => void;
  setHotelDetails: (hotel: IHotelInfo) => void;
  setSelectedHotel: (hotel: IHotel) => void;
  resetState: () => void;
}

interface IProps extends IMapDispatchToProps, IMapStateToProps, WrappedComponentProps, RouteComponentProps {
  visible: boolean;
  rooms: IRoom[];
  checkIn: string;
  checkOut: string;
  locationCode: string;
  sessionKey: ISessionKey;
  hotels: IHotel[];
  isVacationRentals?: boolean;

  onCancel: () => void;
  onRemove: (hotel: IHotel) => void;
}

interface IState {
  loading: boolean;
  visibleAction: boolean;
  isShare: boolean;
}

class ModalCompareComponent extends React.Component<IProps, IState> {

  state: IState = { loading: false, visibleAction: false, isShare: false };

  handleView = (hotel: IHotel, marginatorCommission: number) => {
    const { isVacationRentals, loginStore } = this.props;
    const { account } = loginStore;

    const { hotelId, availability } = hotel;
    this.props.setCommission(marginatorCommission);
    this.props.setSupplier(hotel.supplierId);
    if (availability !== AVAILABILITY_SOLDOUT) {
      this.props.setHotelDetails(NULL_VALUE);
      this.props.resetState();
      this.props.setSelectedHotel(hotel);
      
      const disableNewTabOpen = GetDisableNewTabOpen(this.props.loginStore.account, this.props.menuStore.items);

      openHotelPage(hotelId, this.props.history, disableNewTabOpen as boolean, account?.isTravelAgentPro, isVacationRentals, account?.forceIframe);
    }
  }

  handleData = (members?: string[], comment?: string) => {
    const { sessionKey, checkIn, checkOut, rooms, locationCode, hotels, marginatorStore, loginStore } = this.props;
    const { value } = marginatorStore;
    const { account } = loginStore;
    const search = UrlUtils.getUrl(LOCATION_OBJECT_LABEL, this.props.locationsStore.selectedLocation);
    const locationObject = search.split('&').find(x => x.includes("locationObject"))?.substring(15);
    const isB2C = account?.isB2C;

    const data = {
      quotes: hotels.map(hotel => {
        const marginatorPrice = hotel.price / (1 - (value / 100));
        const savingsNunmber = hotel.savings / marginatorPrice * 100;
        const discount = renderNumber(savingsNunmber, 0);
        const publicPrice = (isB2C || !hotel.publicPrices?.length) ? null : Math.min(...hotel.publicPrices.map(p => p.price))

        return ({
          hotelId: hotel.hotelId,
          displayName: hotel.displayName,
          fullAddress: `${hotel.address}, ${hotel.city}, ${hotel.state}, ${hotel.countryCode}`,
          mealPLan: hotel.mealPLan,
          refundability: hotel.refundability,
          starRating: hotel.starRating,
          reviewsCount: hotel.rating?.reviewsCount,
          rating: hotel.rating?.value,
          currency: hotel.currency,
          price: marginatorPrice,
          publicPrice,
          discount,
          image: get(hotel, 'images[0].url'),
          amenities: hotel?.amenities?.map(a => a.name)
        });
      }),
      comment,
      members,
      location: locationObject,
      hotelRequest: {
        checkIn,
        checkOut,
        location: locationCode,
        rooms: rooms.map(r => ({ adultsCount: r.adultsCount, kidsAges: r.kids?.map(k => k.age || 0 )}))
      },
      sessionKey
    };

    return data;
  }

  handleDownload = () => {
    const loading = this.state.loading;
    this.setState({ loading: true });
    HandleDownload(DownLoadShareType.CONDO, loading, this.handleData(), this.props.intl, () => this.setState({ loading: false }), () => this.setState({ visibleAction: false}));
  }

  render(): React.ReactNode {
    const {
      visible,
      hotels,
      rooms,
      checkIn,
      checkOut,
      onCancel,
      onRemove
    } = this.props;
    const { loading } = this.state;
    const { user, account } = this.props.loginStore;

    const adults = rooms?.length ? rooms.map(room => room.adultsCount || 0).reduce((acc, curr) => acc + curr, 0) : 0;
    const childs = rooms?.length ? rooms.map(room => room.kids?.length || 0).reduce((acc, curr) => acc + curr, 0) : 0;
    const isB2C = account?.isB2C;

    const fontFamily = account?.fontFamily;
    const secondaryFontFamily = account?.secondaryFontFamily;

    const background = account?.buttonColor;
    const border = account?.buttonBorder;
    const color = account?.buttonTextColor;
    const borderRadius = account?.buttonBorderRadius;
    const buttonTextTransform = account?.buttonTextTransform;
      
    const styleButtonBorderRadius = !isEmpty(borderRadius) ? { borderRadius: `${borderRadius}px` } : {};  
    const styleButtonnTextTransform = (buttonTextTransform && !isEmpty(buttonTextTransform)) ? { ...styleButtonBorderRadius, textTransform: buttonTextTransform as any } : { ...styleButtonBorderRadius };  
    const styleButtonBorder = !isEmpty(border)? { ...styleButtonnTextTransform, border } : { ...styleButtonnTextTransform };  
    const styleButtonColor = !isEmpty(color)? { ...styleButtonBorder, color } : { ...styleButtonBorder };  
    const styleButton = !isEmpty(background)? { ...styleButtonColor, background } : { ...styleButtonColor };  

    return (
      <Modal
        className={`modal-compare ${fontFamily ? `${fontFamily}-font`: ''} ${secondaryFontFamily ? `secondary-${secondaryFontFamily}-font`: ''}`}
        open={visible}
        footer={null}
        onCancel={onCancel}
        wrapClassName="modal-compare__wrapper"
        width={modalWidth}
      >
        <div>
          <h4 className="modal-compare__title secondary-font">
            <FormattedMessage id="results.hotel.card.compare.modal.title" />
          </h4>

          <div className="modal-compare__hotels" style={{ flexDirection: 'column' }}>
            <div className="hotels-dates">
              {formatDateCheckInOut(checkIn, DATE_FORMAT_CHECK_IN_OUT)} - {formatDateCheckInOut(checkOut, DATE_FORMAT_CHECK_IN_OUT)}
            </div>
            <div className="hotels-dates">
              {rooms?.length} <FormattedMessage id="room" values={{ count: rooms?.length }} /> - {adults} <FormattedMessage id="adult" values={{ count: adults }} /> - {childs} <FormattedMessage id="child" values={{ count: childs }} />
            </div>
          </div>

          <div className="modal-compare__hotels">
            {hotels.map((hotel, index) => {
              const { price } = hotel;

              const { value } = this.props.marginatorStore;
              const marginatorPrice = price / (1 - (value / 100));
              const marginatorCommission = marginatorPrice - price;

              const publicPrice = (isB2C || !hotel.publicPrices?.length) ? null : Math.min(...hotel.publicPrices.map(p => p.price))

              const msave = publicPrice ? (marginatorPrice * percents) / publicPrice : 100;
              const savings = Math.floor(percents - msave);

              return (
                <div key={index} className={`modal-compare__hotel ${index === 0 ? 'display-border' : ''} hotel_${hotels.length}`}>
                  <div className="hotel-images-container">
                    <div className="hotel-image" style={{ backgroundImage: `url(${get(hotel, 'images[0].url')})` }}>
                    </div>
                  </div>

                  <div className="hotel-text-container" style={{ height: '86px' }}>
                    <p
                      style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          lineClamp: 3,
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: 'vertical',
                      }}>{hotel.displayName}</p>
                  </div>

                  <div className="hotel-text-container">
                    <p
                      style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          lineClamp: 4,
                          WebkitLineClamp: 4,
                          WebkitBoxOrient: 'vertical',
                      }}>{hotel.address}, {hotel.city}, {hotel.state}, {hotel.countryCode}</p>
                  </div>

                  <div className="hotel-text-container" style={{ height: '43px' }}>
                    {hotel.mealPLan}
                  </div>

                  {hotel.refundability && hotel.refundability !== RefundableEnum.Unknown ? (
                    <div className="hotel-text-container" style={{ height: '43px' }}>
                      <div className="hotel-card-wrapper__refundable">
                        {hotel.refundability === RefundableEnum.Refundable && (
                          <div className="hotel-card-wrapper__refundable-ref">
                            <FormattedMessage id="refundable" />
                          </div>)}
                        {hotel.refundability === RefundableEnum.Nonrefundable && (
                          <div className="hotel-card-wrapper__refundable-nonref">
                            <FormattedMessage id="non.refundable" />
                          </div>)}
                      </div>
                    </div>) : null}

                  {(hotel.starRating >= 0 || hotel.rating?.value || hotel.rating?.reviewsCount) ? (
                    <div className="hotel-amenities-container">
                      {hotel.starRating >= 0 ? (<HotelStars stars={hotel.starRating} />) : null}
                      <label>{hotel.rating?.value ? (<><strong>{hotel.rating?.value}</strong>/10</>) : null} {hotel.rating?.reviewsCount ? (<>({hotel.rating?.reviewsCount} <FormattedMessage id="reviews" />)</>) : null}</label>
                    </div>) : null}

                  <div className="hotel-text-container" style={{ height: '90px' }}>
                    {publicPrice ? (<p style={{ textDecoration: 'line-through' }}><Currency currency={hotel.currency} />{renderNumber(publicPrice, 2)}</p>) : null}
                    <p><strong style={{ fontSize: '20px', fontWeight: 'bold' }}><Currency currency={hotel.currency} />{renderNumber(marginatorPrice, 2)}</strong></p>
                    {savings > 0 ? (<p>{savings.toFixed(0)} % <FormattedMessage id="results.hotel.card.compare.modal.off" /></p>) : null}
                  </div>

                  {hotel?.amenities?.length ? (
                    <div className="hotel-text-container" style={{ height: '87px' }}>
                      {hotel?.amenities?.filter(a => !isEmpty(a))?.length > 0 ? hotel?.amenities.map(a => ({ ...(getAmenityImage(a) as any), name: a.name })).filter(a => !!a).slice(0, 2).map((a, i) => <div key={i} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><img src={a.image} alt={a.name} width="33" /> {a.name}</div>) : <FormattedMessage id="results.hotel.card.compare.modal.no_amenities" />}
                    </div>) : null}

                  <div className="hotel-view-container">
                    <div className="view-button" style={styleButton} onClick={() => this.handleView(hotel, marginatorCommission)}>
                      <FormattedMessage id="results.hotel.card.compare.modal.view" />
                    </div>
                  </div>

                  <div className="hotel-remove-container">
                    <label className={`${hotels.length === 2 ? 'disabled' : ''}`} onClick={() => {
                      if (hotels.length > 2) {
                        onRemove(hotel);
                      }
                    }}><FormattedMessage id="remove" /></label>
                  </div>
                </div>)
            })}
          </div>

          <div className="modal-compare__buttons">
            {(!account || account.type !== LoginType.PrivateWithToken)
              ?
              <BlueButton disabled={loading} onClick={this.handleDownload}>
                {loading ? (<Spin />) : <FormattedMessage id="results.hotel.card.compare.modal.download" />}
              </BlueButton>
              :
              <>
                <BlueButton disabled={loading} onClick={() => this.setState({ visibleAction: true, isShare: false })}>
                  {loading ? (<Spin />) : <FormattedMessage id="results.hotel.card.compare.modal.download" />}
                </BlueButton>

                {HasPermission(user, ViewQuotesSharePermission)?
                  <BlueButton disabled={loading} onClick={() => this.setState({ visibleAction: true, isShare: true })}>
                    {loading ? (<Spin />) : <FormattedMessage id="results.hotel.card.compare.modal.share" />}
                  </BlueButton> : null} 
              </>
            }
          </div>
        </div>

        <ModalShare
          visible={this.state.visibleAction}
          isShare={this.state.isShare}
          type={DownLoadShareType.HOTEL}
        
          onCancel={() => this.setState({ visibleAction: false})}
          onLoading={(loading) => this.setState({ loading })}
          convertData={this.handleData}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    marginatorStore: state.marginatorStore,
    loginStore: state.loginStore,
    locationsStore: state.locationsStore,
    menuStore: state.navigationMenuStore,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  setCommission: (value: number) => {
    dispatch(marginatorActions.setCommission(value));
  },
  setSupplier: (value: string) => {
    dispatch(marginatorActions.setSupplier(value));
  },
  setHotelDetails: (hotel: IHotelInfo) => {
    dispatch(hotelDetailsActions.setHotelDetails(hotel));
  },
  setSelectedHotel: (hotel: IHotel) => {
    dispatch(hotelsActions.setSelectedHotel(hotel));
  },
  resetState: () => {
    dispatch(roomsSearchActions.resetState());
  }
});

export const ModalCompare = connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(ModalCompareComponent)));
