import React from 'react';

export const UserSvg: React.FunctionComponent = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8C8 10.2091 9.79086 12 12 12Z"
      fill="#292929"
    />
    <path
      d="M5 20V17.6667C5 16.429 5.49167 15.242 6.36683 14.3668C7.242 13.4917 8.42899 13 9.66667 13H14.3333C15.571 13 16.758 13.4917 17.6332 14.3668C18.5083 15.242 19 16.429 19 17.6667V20"
      fill="#292929"
    />
  </svg>
);
