import React, { SetStateAction, Dispatch, ChangeEvent, useState, useEffect } from 'react';
import { GetawayListSkeleton } from '../getaway-list-skeleton';
import { GETAWAYS_FILTERS, GETAWAYS_LOCATION, GETAWAYS_PROMOID, GETAWAYS_SORT_ORDER, SortTypes } from '@share/constants';
import { IGetawayFilters } from '../../getaway-wrapper';
import { Button, Checkbox, Input, Select } from 'antd';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import {
  defaultLocationValue,
  GetawaysLocationEnum,
  IGetawaysLocations,
} from '@share/common-types';
import { filterDefaultCheckedValues, getGetawayOptionLabel, getGetawaySortOptions } from '@utils';
import { connect, useDispatch } from 'react-redux';
import { GetawayItem, IGateway, IGetawaysState, resetAllFilters, SubCategory } from '@store/slices';
import { get, isEmpty } from 'lodash';
import { RootState } from '@share/utils';
import { ILoginState, IMenuState } from '@share/store/slices';

import './style.scss';
import { BlueButton } from '@share/components';

export interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps {
  getawaysFilter: GetawayItem[];
  setGetawaysFilters: Dispatch<SetStateAction<any>>;
  getawayTotalCount: number;
  setSortGetawayFilters: Dispatch<SetStateAction<IGetawayFilters>>;
  sortGetawayFilters: IGetawayFilters;
  notGatewaysToShowResult: number;
  notPromosToShow: number;
  getawayStore: IGetawaysState;
  condosStore: IGateway;
  staycationStore: IGateway;
  cruiseStore: IGateway;
  fantasyStore: IGateway;
  getawaysToShow: number;
  countRequestData: number;
  initialFilterState: IGetawayFilters;
}

const GetawayFiltersComponent: React.FC<IProps> = ({
  getawaysFilter,
  setGetawaysFilters,
  getawayTotalCount,
  setSortGetawayFilters,
  sortGetawayFilters,
  notGatewaysToShowResult,
  notPromosToShow,
  getawayStore,
  condosStore,
  staycationStore,
  cruiseStore,
  fantasyStore,
  getawaysToShow,
  countRequestData,
  initialFilterState,
  loginStore,
  menuStore,
}) => {
  const sortAndFormatLocation = (
    loc: IGetawaysLocations[],
    label: string,
    getaway: SubCategory,
  ) => {
    const sortLoc = [...loc].sort((a, b) => {
      if (a.city.toLowerCase() < b.city.toLowerCase()) {
        return -1;
      }
      if (a.city.toLowerCase() > b.city.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    const options = sortLoc
      .filter((location) => location.city !== defaultLocationValue)
      .map((location) => ({
        label: location.city === defaultLocationValue ? defaultCheckedValues : getGetawayOptionLabel(location),
        value: `${location.city ? location.city : ''}|${getaway}|${location.state ? location.state : ''}|${location.country ? location.country : ''}`,
        getaway,
      }));

    return {
      label,
      options,
      getaway,
    };
  };

  const condosSortLocation: any = condosStore?.getawaysLocations?.length
    ? sortAndFormatLocation(
        condosStore?.getawaysLocations,
        'Condos',
        GetawaysLocationEnum.NewCondos,
      )
    : [];
  const staycationSortLocation: any = staycationStore?.getawaysLocations?.length
    ? sortAndFormatLocation(
        staycationStore?.getawaysLocations,
        'Staycation',
        GetawaysLocationEnum.Staycation,
      )
    : [];
  const cruiseSortLocation: any = cruiseStore?.getawaysLocations?.length
    ? sortAndFormatLocation(cruiseStore?.getawaysLocations, 'Cruise', GetawaysLocationEnum.Cruise)
    : [];
  const fantasySortLocation: any = fantasyStore?.getawaysLocations?.length
    ? sortAndFormatLocation(
        fantasyStore?.getawaysLocations,
        'Fantasy',
        GetawaysLocationEnum.Fantasy,
      )
    : [];

  const intl = useIntl();
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<IGetawayFilters>(initialFilterState);
  const { sort, location, promoId } = filterState;

  useEffect(() => {
    setFilterState((prevState) => ({ ...prevState, ...sortGetawayFilters }));
  },[sortGetawayFilters]);

  //Disabled del checkbox
  let checkGroupOptions = [
    {
      label: 'Condos',
      value: GetawaysLocationEnum.NewCondos,
      disabled: condosStore.getawaysTotalCount === 0 && !condosStore.isRequestPopulated,
    },
    {
      label: 'Short Stays',
      value: GetawaysLocationEnum.Staycation,
      disabled: staycationStore.getawaysTotalCount === 0 && !staycationStore.isRequestPopulated,
    },
    {
      label: 'Cruises',
      value: GetawaysLocationEnum.Cruise,
      disabled: cruiseStore.getawaysTotalCount === 0 && !cruiseStore.isRequestPopulated,
    },
    {
      label: 'Fantasy',
      value: GetawaysLocationEnum.Fantasy,
      disabled: fantasyStore.getawaysTotalCount === 0 && !fantasyStore.isRequestPopulated,
    },
  ];

  //Saber si es condo o no
  const isCondo = (condo: string) =>
    condo === GetawaysLocationEnum.Condos || condo === GetawaysLocationEnum.Condo;

  const onCheckHandle = (checkedValues: CheckboxValueType[]) => {
    const updatedGetawaysFilter = getawaysFilter.map((getaway) => {
      const isChecked = isCondo(getaway.getaway)
        ? checkedValues.includes(getaway.subCategory as CheckboxValueType)
        : checkedValues.includes(getaway.getaway);

      return {
        ...getaway,
        show: isChecked,
      };
    });

    setGetawaysFilters(updatedGetawaysFilter);

    localStorage.setItem(GETAWAYS_FILTERS, JSON.stringify(updatedGetawaysFilter));
  };

  // Obtener los valores de los checkboxes que deben estar seleccionados inicialmente
  const defaultCheckedValues = getawaysFilter
    .filter((getaway) => getaway.show)
    .map((getaway) => getaway.subCategory || getaway.getaway);

  //Default checked para el checkbox group
  const filteredValues = filterDefaultCheckedValues(
    defaultCheckedValues,
    condosStore,
    null,
    null,
    staycationStore,
    cruiseStore,
    fantasyStore,
  );

  //onHandle sort Change
  const onSortChange = (value: SortTypes) => {
    //setFilterState((prevState) => ({ ...prevState, sort: value }));
    setSortGetawayFilters((prevState) => ({ ...prevState, sort: value }));

    localStorage.setItem(GETAWAYS_SORT_ORDER, value);
  };

  //OnHandle Search
  const onSearchChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    //setFilterState((prevState) => ({ ...prevState, promoId: target.value }));
    setSortGetawayFilters((prevState) => ({ ...prevState, promoId: target.value }));

    localStorage.setItem(GETAWAYS_PROMOID, target.value);
  };

  //onHandle Locations
  const onLocationChange = (v: string, option: any) => {
    const value = get(v?.split('|'), '[0]');

    const isDefaultLocation = value === defaultLocationValue;
    const location: IGetawaysLocations | undefined = !isDefaultLocation? getawayStore[option?.getaway as SubCategory].getawaysLocations.find((loc) => loc.city === value) : undefined;
    if (isDefaultLocation) {
      localStorage.removeItem(GETAWAYS_SORT_ORDER);
      localStorage.removeItem(GETAWAYS_LOCATION);
      localStorage.removeItem(GETAWAYS_FILTERS);
      localStorage.removeItem(GETAWAYS_PROMOID);
    }

    const getaway = option?.getaway === 'all' ? null : option?.getaway;

    const finalLocation = isDefaultLocation
                              ? defaultLocationValue
                              : `${location?.city ? location.city : ''} ${location?.country ? location?.country : ''}${location?.state ? ',' : ''} ${location?.state ? location.state : ''}`;

    const finalLocationState = isDefaultLocation
                                  ? { city: defaultLocationValue, state: '', country: '', getaway: null }
                                  : {
                                      city: location?.city ? location.city : '',
                                      country: location?.country ? location?.country : '',
                                      state: location?.state ? location.state : '',
                                      getaway,
                                    };

    setSortGetawayFilters((prevState) => ({
      ...prevState,
      location: finalLocation,
      locationState: finalLocationState,
    }));

    localStorage.setItem(GETAWAYS_LOCATION, JSON.stringify(finalLocationState));
  };

  //Boton Reset
  const resetFilter = () => {
    dispatch(resetAllFilters());
    setGetawaysFilters((prevState: GetawayItem[]) => {
      return prevState.map((showGetaway) => ({ ...showGetaway, show: true }));
    });

    setFilterState(initialFilterState);
    setSortGetawayFilters(initialFilterState);

    localStorage.removeItem(GETAWAYS_SORT_ORDER);
    localStorage.removeItem(GETAWAYS_LOCATION);
    localStorage.removeItem(GETAWAYS_FILTERS);
    localStorage.removeItem(GETAWAYS_PROMOID);
  };

  // Función para generar y filtrar las opciones
  const generateOptions = (conditions: any) => {
    const {
      condosSortLocation,
      staycationSortLocation,
      cruiseSortLocation,
      fantasySortLocation,
    } = conditions;

    //Filtrar Opciones del select allLocations
    const allOptions = [
      {
        label: 'All',
        options: [{ label: defaultLocationValue, value: defaultLocationValue, getaway: 'all' }],
      },
      condosSortLocation?.options?.length && {
        label: condosSortLocation.label,
        options: condosSortLocation.options?.filter((o1: any, i: number, arr: any[]) => arr.findIndex(o2 => (o2.value === o1.value)) === i),
      },
      staycationSortLocation?.options?.length && {
        label: staycationSortLocation.label,
        options: staycationSortLocation.options?.filter((o1: any, i: number, arr: any[]) => arr.findIndex(o2 => (o2.value === o1.value)) === i),
      },
      cruiseSortLocation?.options?.length && {
        label: cruiseSortLocation.label,
        options: cruiseSortLocation.options?.filter((o1: any, i: number, arr: any[]) => arr.findIndex(o2 => (o2.value === o1.value)) === i),
      },
      fantasySortLocation?.options?.length && {
        label: fantasySortLocation.label,
        options: fantasySortLocation.options?.filter((o1: any, i: number, arr: any[]) => arr.findIndex(o2 => (o2.value === o1.value)) === i),
      },
    ];

    // Filtra las opciones vacías
    return allOptions.filter(Boolean);
  };

  //Opciones del select allLocations
  const locationSelectOtions = generateOptions({
    condosSortLocation,
    staycationSortLocation,
    cruiseSortLocation,
    fantasySortLocation,
  });
  
  const filterApplied =
    filteredValues.length === getawaysToShow &&
    location === defaultLocationValue &&
    sort === SortTypes.PriceAsc &&
    isEmpty(promoId);

  const { account } = loginStore;

  const background = account?.buttonColor;
  const border = account?.buttonBorder;
  const color = account?.buttonTextColor;
  const borderRadius = account?.buttonBorderRadius;
  const buttonTextTransform = account?.buttonTextTransform;
    
  const styleButtonBorderRadius = !isEmpty(borderRadius) ? { borderRadius: `${borderRadius}px` } : {};  
  const styleButtonnTextTransform = (buttonTextTransform && !isEmpty(buttonTextTransform)) ? { ...styleButtonBorderRadius, textTransform: buttonTextTransform as any } : { ...styleButtonBorderRadius };  
  const styleButtonBorder = !isEmpty(border)? { ...styleButtonnTextTransform, border } : { ...styleButtonnTextTransform };  
  const styleButtonColor = !isEmpty(color)? { ...styleButtonBorder, color } : { ...styleButtonBorder };  
  const styleButton = !isEmpty(background)? { ...styleButtonColor, background } : { ...styleButtonColor };  

  return (
    <>
      {loginStore.loading ||
       menuStore.loading ||
       condosStore.loadingGetaways ||
       staycationStore.loadingGetaways ||
       cruiseStore.loadingGetaways ||
       fantasyStore.loadingGetaways ? (
        <GetawayListSkeleton isGetaways />
      ) : (
        <section className="getaway__filter">
          <section className="getaway__filter-filters">
            <div className="getaway__filter-filters-filter">
              <Select
                value={location}
                defaultValue={location}
                onChange={onLocationChange}
                disabled={!isEmpty(promoId) || notGatewaysToShowResult === getawaysFilter.length}
                options={locationSelectOtions}
              />
            </div>

            <div className="getaway__filter-filters-search">
              <Input
                allowClear
                value={promoId}
                onChange={onSearchChange}
                placeholder={intl.formatMessage({ id: 'search.promo.id' })}
                prefix={<SearchOutlined />}
                disabled={
                  notPromosToShow === 0 || notGatewaysToShowResult === getawaysFilter.length
                }
              />
            </div>
            <div className="getaway__filter-filters-sort">
              <p className="getaway__filter-filters-sort-text">
                <FormattedMessage id="sort.by" />
                {':'}
              </p>
              <div className="getaway__filter-sort_wrapper">
                <Select
                  value={sort as SortTypes}
                  defaultValue={SortTypes.PriceAsc}
                  onChange={onSortChange}
                  disabled={!isEmpty(promoId) || notGatewaysToShowResult === getawaysFilter.length}
                >
                  {getGetawaySortOptions().map((sort) => (
                    <Select.Option value={sort.value} key={sort.value}>
                      {sort.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </section>
          <section className="getaway__filter-show-getaways-container">
            <div className="getaway__filter-show-getaways-count">
              <span className={`${getawayTotalCount === 0 && 'not-getaway'}`}>
                +{getawayTotalCount} Getaways
              </span>
              {!filterApplied && (
                <BlueButton
                  onClick={resetFilter}
                  className={`getaway__filter-btn-filter${filterApplied ? '-none' : ''}`}
                  style={styleButton}
                >
                  <FilterOutlined className="getaway__filter-filter-icon" />
                  <FormattedMessage id="reset.filter" />
                </BlueButton>)}
            </div>
            <div className="getaway__filter-show-getaways-types">
              <span className="getaway__filter-show-getaways-title">Show Getaways Types: </span>
              <div className="getaway__filter-show-getaways-checkboxes">
                <Checkbox.Group
                  options={checkGroupOptions}
                  value={filteredValues}
                  onChange={onCheckHandle}
                  disabled={
                    !isEmpty(promoId) ||
                    countRequestData === getawaysFilter.length ||
                    sortGetawayFilters.location !== defaultLocationValue
                  }
                />
              </div>
            </div>
          </section>
        </section>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    menuStore: state.navigationMenuStore,
  };
};

export const GetawayFilters = connect(mapStateToProps)(GetawayFiltersComponent);
