
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { IMenuState } from '@share/store/slices';
import { connect } from 'react-redux';
import { RootState } from '@share/utils';

import './style.scss';

import Hero from '@assets/videos/hero-video.mp4';

interface IMapStateToProps {
  menuStore: IMenuState;
}

interface IProps extends RouteComponentProps, IMapStateToProps {}

function HeroVideoComponent(props: IProps) {

  const { menuStore } = props;
  const { items, loading } = menuStore;

  if (!items || loading) {
    return null;
  }

  return (
    <div className="hero-video">
      <video id="banner-video" className="hero-video__video" autoPlay muted playsInline loop>
        <source src={Hero} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="hero-video__text-container">
        <div className="text1">AWAKEN YOUR LOVE OF TRAVEL</div>
        <div className="text2">EMBARK ON AN</div>
        <div className="text2">EXHILARATIG ADVENTURE</div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
  };
};

const HeroVideoWithRouter = withRouter(HeroVideoComponent);

export const HeroVideo = connect(mapStateToProps)(HeroVideoWithRouter);
