import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';

import { IReservationsState, getReservations, getStatic, reservationsActions } from '@share/store/slices';
import { IFilterField, IReservation, IReservationsFilters, ITextSearch } from '@share/common-types';
import { RootState } from '@share/utils';

import { ReservationsBase } from '../reservations-wrapper-base';

import './style.scss';

interface IMapStateToProps {
  reservationsStore: IReservationsState;
}

interface IMapDispatchToProps {
  getReservations: (status: string) => void;
  getStatic: (id: number) => void;
  setFilters: (filters: IReservationsFilters) => void;
  setPagination: (pagination: TablePaginationConfig) => void;
  setSort: (sort: SorterResult<IReservation>) => void;
  setBookingType: (bookingType: IFilterField<string>) => void;

  resetFilters: () => void;

  setTextSearch: (textSearch: ITextSearch) => void;
  setTextSearchLabel: (textSearchLabel: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps {}

class ReservationsComponent extends React.Component<IProps> {
  render(): React.ReactNode {    
    return (
      <ReservationsBase
        title="reservation.search.title"
        store={this.props.reservationsStore}
        getReservations={this.props.getReservations}
        getStatic={this.props.getStatic}
        setBookingType={this.props.setBookingType}
        setFilters={this.props.setFilters}
        setPagination={this.props.setPagination}
        setSort={this.props.setSort}
        resetFilters={this.props.resetFilters}
        setTextSearch={this.props.setTextSearch}
        setTextSearchLabel={this.props.setTextSearchLabel}
      />);
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    reservationsStore: state.reservationsStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getReservations,
  getStatic,
  setFilters: reservationsActions.setFilters,  
  setPagination: reservationsActions.setPagination,  
  setSort: reservationsActions.setSort,  
  resetFilters: reservationsActions.resetFilters,  
  setTextSearch: reservationsActions.setTextSearch,
  setTextSearchLabel: reservationsActions.setTextSearchLabel,
  setBookingType: reservationsActions.setBookingType,
};

const ComponentIntl = injectIntl(ReservationsComponent);

export const Reservations = connect(mapStateToProps, mapDispatchToProps)(withRouter(ComponentIntl));
