import React, { ReactElement } from 'react';
import moment from 'moment';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { get, isEmpty } from 'lodash';

import { Currency } from '@components';
import { ICarDetails } from '@share/common-types';
import { renderNumber } from '@share/utils';
import { DATE_FORMAT_UNIT_ROW } from '@share/constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

const INCOMING_DATE_FORMAT = 'yyyy-MM-DDTHH:mm:ss';

interface IProps {
  car: ICarDetails;
}

class CarsPoliciesComponent extends React.Component<IProps> {

  renderRows = (items: any[], title: string, isList: boolean, renderer: (item: any)=> ReactElement) => {
    if (!items?.length) {
      return null;
    }

    return (
      <div className="car-policies__cancelattion-policies">
        {!isEmpty(title) ? (<span>{'- '}<FormattedMessage id={title} /></span>) : null}

        {isList ? 
          <ul>
            {items.map((item: any, index: number) => 
              <li key={`pi_${index}`}>
                {renderer(item)}
              </li>)}
          </ul> :
          items.map((item: any, index: number) => 
            <label key={`pi_${index}`}>
              {renderer(item)}
            </label>)}
      </div>
    );
  }

  render(): React.ReactNode {
    const { car } = this.props;
    
    const vendor = car?.vendor;
    const info = car?.info;
    const rental = car?.rental;
    const refundable = car?.refundable;
    const currency = car?.currency;
    const cancellationPolicies = !refundable ? [{ message: 'cancellation.not_refundable.message.policy' }] : car?.cancellationPolicies;

    const productInformationUrl = info?.tpA_Extensions?.productInformation?.url;
    const termsConditionsUrl = info?.tpA_Extensions?.termsConditions?.url;
    const paymentRules = info?.paymentRules?.paymentRules?.length ? [...info?.paymentRules?.paymentRules] : [];
    const pricedCoverages = info?.pricedCoverages;

    const vendorMessages = vendor?.vendorMessages?.length ? [...vendor?.vendorMessages?.filter(v => ![productInformationUrl, termsConditionsUrl].includes(get(v?.subSection, '[0].text[0]')))] : [];

    const ageRestriction = rental?.rentalRate?.rateRestrictions;
    const rateDistance = rental?.rentalRate?.rateDistance;

    if (!vendorMessages?.length && 
        isEmpty(productInformationUrl) &&
        isEmpty(termsConditionsUrl) &&
        !paymentRules?.length &&
        !pricedCoverages?.length &&
        !cancellationPolicies?.length &&
        !ageRestriction) {
      return null;
    }

    if (!isEmpty(productInformationUrl)) {
      vendorMessages.push({
        title: <FormattedMessage id="cars.product_information" />,
        url: productInformationUrl
      })
    }

    if (!isEmpty(termsConditionsUrl)) {
      vendorMessages.push({
        title: <FormattedMessage id="cars.terms" />,
        url: termsConditionsUrl
      })
    }

    if (ageRestriction) {
      paymentRules.push({
        value: <label><FormattedMessage id="cars.age.restriction" values={{ min: ageRestriction?.minimumAge, max: ageRestriction?.maximumAge }} /></label>
      });
    }

    const coveredIncluded = pricedCoverages?.filter(p => p?.included);
    const notCovered = pricedCoverages?.filter(p => !p?.included);
    
    const coveredIncludedFinal = coveredIncluded?.length ? [...coveredIncluded] : [];
    if (rateDistance?.unlimited) {
      coveredIncludedFinal.push({
        description: <FormattedMessage id={rateDistance?.distUnitName === 'Mile' ? 'cars.distante.restriction.miles' : 'cars.distante.restriction.kms'} />
      });
    }

    return (
      <div className="hotel-info">
        <div className="hotel-info__item border-top">
          <div className="hotel-info__item-content">
            <h4 className="hotel-info__title">
              <FormattedMessage id="cars.policies" />
            </h4>

            {(cancellationPolicies?.length || !refundable) ? (
              <>
                <div className="col-md-12 car-location__title">
                  <FormattedMessage id="cars.cancellation_polices" />
                </div>

                {this.renderRows(cancellationPolicies, null, true, (c) => (
                  c?.message ?
                    <FormattedMessage id={c?.message} /> :
                    <FormattedMessage id="cancellation.policy.after" values={{ date: moment(c.from, INCOMING_DATE_FORMAT).format(DATE_FORMAT_UNIT_ROW), value: <><Currency currency={currency} />{renderNumber(c.amount, 2)}</> }} />))}
                
              </>) : null}

            {pricedCoverages?.length ? (
              <>
                <div className="col-md-12 car-location__title">
                  <FormattedMessage id="cars.price_coverage" />
                </div>

                {this.renderRows(coveredIncludedFinal, 'cars.price.tax.included', false, (c) => (<span className="cars-cancellation-policies"><FontAwesomeIcon icon={faCircleCheck} />{c.description}{c.amount ? (<>{' - '}<FormattedMessage id="amount" />: <Currency currency={currency} />{renderNumber(c.amount, 2)}</>) : null}</span>))}

                {this.renderRows(notCovered, 'cars.price.tax.no_included', false, (c) => (<span className="cars-cancellation-policies not-included"><FontAwesomeIcon icon={faCircleXmark} />{c.description} - <FormattedMessage id="amount" />: <Currency currency={currency} />{renderNumber(c.amount, 2)}</span>))}
              </>) : null}

            {paymentRules?.length ? (
              <>
                <div className="col-md-12 car-location__title">
                  <FormattedMessage id="cars.payment_rules" />
                </div>

                {this.renderRows(paymentRules, null, false, (c) => (<><FontAwesomeIcon icon={faCheck} />{c.value}</>))}
              </>) : null}

            {(vendorMessages?.length || !isEmpty(productInformationUrl) || !isEmpty(termsConditionsUrl)) ? (
              <>
                <div className="col-md-12 car-location__title">
                  <FormattedMessage id="cars.vendro_message" />
                </div>

                {this.renderRows(vendorMessages, null, false, (c) => {
                  const url = get(c?.subSection, '[0].text[0]', c?.url);

                  if (!isEmpty(url)) {
                    return (<a href={url} target="_blank"><FontAwesomeIcon icon={faCheck} />{c.title}</a>);
                  }

                  return (<><FontAwesomeIcon icon={faCheck} />{c.title}</>);
                })}

              </>) : null}
          </div>
        </div>
      </div>
    );
  }
}

export const CarsPolicies = connect(null)(CarsPoliciesComponent);

