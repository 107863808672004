import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import {
  CompanyLogos,
  ResultWrapper,
  SkeletonResult,
  PopularDestinations,
  RecentSearchesWrapper,
  ConciergeTeam,
  Membership,
  GetawayList,
} from '@components';
import { SearchWrapper } from '@share/components';
import { IBenefitsState, IMenuState } from '@share/store/slices';
import { IHotelsState } from '@share/store/slices';
import { RootState, UrlUtils, Responsive } from '@share/utils';
import { HotelsCompanyLogos } from '@common-types';
import { BackgroundColor } from '@constants';

import './style.scss';
import { GetawaysLocationEnum, ISideKickStatus } from '@share/common-types';

interface IMapStateToProps {
  hotelsStore: IHotelsState;
  menuStore: IMenuState;
  benefitsStore: IBenefitsState;
}

interface IProps extends IMapStateToProps {
  isVacationRentals?: boolean;
}

class SearchRsiComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { hotelsStore, menuStore, benefitsStore, isVacationRentals } = this.props;
    const { hotels, loading, searchedLocation, error } = hotelsStore;
    const { items } = menuStore;
    const { benefits } = benefitsStore;

    const values = UrlUtils.getValues();

    return (
      <div className="search-rsi">
        <SearchWrapper isVacationRentals={isVacationRentals} />
        
        {isEmpty(values) && !hotels && isEmpty(error) ? (
          <>
            {(benefits?.items && items?.isMLM && items?.sideKickStatus === ISideKickStatus.PARENT) ? (
              <GetawayList
                getaway={GetawaysLocationEnum.Staycation}
                condoSubcategory={null}
              
                isFromProperty
                isInternal
                isHome
                isSlider
              />) : null}

            <RecentSearchesWrapper isVacationRentals={isVacationRentals} />
            <PopularDestinations />
            <ConciergeTeam
              backgroundColor={BackgroundColor.dark}
              isImageSizeSmaller={false}
            />
            <CompanyLogos isBackgroundColorDark={false} logos={HotelsCompanyLogos} />
            <Membership />
          </>) : null}

        {hotels && loading && isEmpty(error) ? <SkeletonResult label={searchedLocation} /> : null}

        {(!isEmpty(values) && !loading && (hotels || !isEmpty(error))) ? (
          <Responsive>
            <ResultWrapper label={searchedLocation} isVacationRentals={isVacationRentals} />
          </Responsive>) : null}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    hotelsStore: state.hotelsStore,
    menuStore: state.navigationMenuStore,
    benefitsStore: state.benefitsStore,
  };
};

export const SearchRsi = connect(mapStateToProps)(SearchRsiComponent);
