import React from 'react';

export const BusinessCenterSvg: React.FunctionComponent = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 4.66667H10C9.63137 4.66667 9.33337 4.96467 9.33337 5.33333V7.33333H8.00004V2.66667C8.00004 2.298 7.70204 2 7.33337 2H2.00004C1.63137 2 1.33337 2.298 1.33337 2.66667V13.3333C1.33337 13.702 1.63137 14 2.00004 14H6.66671H7.33337H10H10.6667H14C14.3687 14 14.6667 13.702 14.6667 13.3333V5.33333C14.6667 4.96467 14.3687 4.66667 14 4.66667ZM5.33337 4H6.66671V5.33333H5.33337V4ZM4.00004 10.6667H2.66671V9.33333H4.00004V10.6667ZM4.00004 8H2.66671V6.66667H4.00004V8ZM4.00004 5.33333H2.66671V4H4.00004V5.33333ZM6.66671 10.6667H5.33337V9.33333H6.66671V10.6667ZM6.66671 8H5.33337V6.66667H6.66671V7.33333V8ZM12.6667 10.6667H11.3334V9.33333H12.6667V10.6667ZM12.6667 8H11.3334V6.66667H12.6667V8Z"
      fill="#333333"
    />
  </svg>
);
