import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import addDays from 'date-fns/addDays';

import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  DateType,
  getDashboarReports,
  IDashboardReportState,
  IReportsRequest,
  setDashboardReportsRequest,
} from '@store/slices';

import { Select } from 'antd';
import { RootState } from '@share/utils';

import { DEFAULT_MEDIA_POINT } from '@share/constants';

import { DateRange, Range, RangeFocus } from 'react-date-range';
import { CloseSvg } from '@share/assets';
import { BlueButton } from '@share/components';
import { ILoginState } from '@share/store/slices';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

const mobileMonthCount = 12;
const fullMonthCount = 2;
const isStart = 0;
const defaultRange = 0;
const oneItem = 1;
const seven = 7;
const focusZIndex = 4;
const blurZIndex = 1;

interface IMapStateToProps {
  dashboardReportsStore: IDashboardReportState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getDashboarReports: () => void;
  setDashboardReportsRequest: (request: IReportsRequest) => void;
}

interface IProps
  extends IMapStateToProps,
    IMapDispatchToProps,
    RouteComponentProps,
    WrappedComponentProps {}

function DashboardReportFiltersComponent(props: IProps) {
  const [focusedRange, setFocusedRange] = useState([defaultRange, isStart]);
  const [isFocused, setIsFocused] = useState(false);
  const [isEndDateSelected, setIsEndDateSelected] = useState(true);
  const [isInitial, setIsInitial] = useState(false);

  const { dashboardReportsStore, loginStore, setDashboardReportsRequest, getDashboarReports } =
    props;
  const { request } = dashboardReportsStore;
  const { account } = loginStore;

  const period = request?.dateType;
  const fromDate = request?.fromDate;
  const toDate = request?.toDate;

  const locale = account?.locale;

  const isCustomRange = useMemo(() => period === DateType.CUSTOM_RANGE, [period]);
  const isMobile = document.body.offsetWidth <= DEFAULT_MEDIA_POINT;

  useEffect(() => {
    if (!isCustomRange) {
      getDashboarReports();
    }
  }, [request]);

  const updatePeriod = (dateType: DateType) => {
    const newRequest: IReportsRequest = { ...request, dateType };
    if (dateType !== DateType.CUSTOM_RANGE) {
      newRequest.fromDate = undefined;
      newRequest.toDate = undefined;
    }
    setDashboardReportsRequest({ ...newRequest });
  };

  const updateDate = (ranges: any): void => {
    const range = ranges as { selection: Range };

    setIsInitial(false);

    if (range.selection) {
      const startDate = new Date(
        range.selection.startDate?.getFullYear() as number,
        range.selection.startDate?.getMonth() as number,
        range.selection.startDate?.getDate() as number,
      );

      let endDate = new Date(
        range.selection.endDate?.getFullYear() as number,
        range.selection.endDate?.getMonth() as number,
        range.selection.endDate?.getDate() as number,
      );

      if (!isEndDateSelected && startDate.toString() === endDate.toString()) {
        endDate = addDays(new Date(startDate), seven);
      }

      const newRequest: IReportsRequest = { ...request };
      newRequest.fromDate = moment(startDate).format('MM/DD/yyyy');
      newRequest.toDate = moment(endDate).format('MM/DD/yyyy');

      setDashboardReportsRequest({ ...newRequest });
    }
  };

  const onRangeFocusChange = (focusedRange: RangeFocus): void => {
    setFocusedRange(focusedRange);
    setIsEndDateSelected(!focusedRange[oneItem]);
  };

  const blur = (): void => {
    if (isFocused) {
      setIsFocused(false);
      setIsEndDateSelected(true);
      setFocusedRange([defaultRange, isStart]);
    }
  };

  const setInitialValues = (): void => {
    blur();
  };

  const onDone = (): void => {
    getDashboarReports();
    blur();
  };

  const focus = () => {
    if (!isFocused) {
      setIsFocused(true);
    }
  };

  const selectionRange = {
    startDate: !isEmpty(fromDate) ? moment(fromDate, 'MM/DD/yyyy').toDate() : moment().toDate(),
    endDate: !isEmpty(toDate) ? moment(toDate, 'MM/DD/yyyy').toDate() : moment().toDate(),
    key: 'selection',
  };

  const generalBorderRadius: any = account?.generalBorderRadius ? account?.generalBorderRadius : null;
  const style = !isEmpty(generalBorderRadius) ? { borderRadius: generalBorderRadius } : {};  

  return (
    <div className="dasshboard-reports-filters-wrapper">
      <Select value={period} onChange={(v) => updatePeriod(v)}>
        <Select.Option value={DateType.LAST_7}>
          <FormattedMessage id="LAST_7" />
        </Select.Option>
        <Select.Option value={DateType.LAST_30}>
          <FormattedMessage id="LAST_30" />
        </Select.Option>
        {/* <Select.Option value={DateType.LAST_60}><FormattedMessage id="LAST_60" /></Select.Option> */}
        <Select.Option value={DateType.LAST_90}>
          <FormattedMessage id="LAST_90" />
        </Select.Option>
        <Select.Option value={DateType.CUSTOM_RANGE}>
          <FormattedMessage id="CUSTOM_RANGE" />
        </Select.Option>
      </Select>

      {isCustomRange && (
        <div
          className={`date-picker__wrapper ${isFocused && toDate ? 'selected' : ''} ${
            isFocused ? 'open' : ''
          } `}
          onClick={focus}
          style={{ ...style, zIndex: isFocused ? focusZIndex : blurZIndex }}
        >
          <div className="date-picker__left">
            <div className="date-picker__icon">
              <FontAwesomeIcon icon={faCalendarAlt} />
            </div>
          </div>
          <div className="date-picker__right">
            <div className="date-picker__header">
              {isFocused ? (
                !isInitial && fromDate ? (
                  <span className="date-picker__date">
                    {moment(fromDate, 'MM/DD/yyyy').format('DD MMM yyyy')}
                  </span>
                ) : (
                  <FormattedMessage id="select.date" />
                )
              ) : (
                <>
                  {fromDate && toDate && !isInitial ? (
                    <>
                      <span className="date-picker__date">
                        {moment(fromDate, 'MM/DD/yyyy').format('DD MMM yyyy')} -{' '}
                        {moment(toDate, 'MM/DD/yyyy').format('DD MMM yyyy')}
                      </span>
                    </>
                  ) : (
                    <FormattedMessage id="select.date" />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="date-picker__dropdown" style={{ display: isFocused ? 'block' : 'none' }}>
        <div className="date-picker__close-icon" onClick={setInitialValues}>
          <CloseSvg />
        </div>
        <div className={`date-picker__picker ${toDate ? '' : 'show-checkout'}`}>
          <div className="date-picker__picker-wrapper">
            <DateRange
              editableDateInputs={false}
              months={isMobile ? mobileMonthCount : fullMonthCount}
              direction={isMobile ? 'vertical' : 'horizontal'}
              locale={locale}
              onChange={updateDate}
              moveRangeOnFirstSelection={false}
              monthDisplayFormat="MMMM, yyyy"
              ranges={[selectionRange]}
              weekStartsOn={0}
              weekdayDisplayFormat="EEEEEE"
              minDate={moment().add(-100, 'years').toDate()}
              maxDate={moment().toDate()}
              focusedRange={focusedRange as RangeFocus}
              onRangeFocusChange={onRangeFocusChange}
            />
          </div>
        </div>
        <div className={`date-picker__footer`}>
          <BlueButton onClick={onDone}>
            <FormattedMessage id="done" />
          </BlueButton>
        </div>
      </div>
      {isFocused && <div className="date-picker__fixed-bg" />}
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    dashboardReportsStore: state.dashboardReportsStore,
    loginStore: state.loginStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getDashboarReports,
  setDashboardReportsRequest,
};

const ComponentIntl = injectIntl(DashboardReportFiltersComponent);

export const DashboardReportFilters = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ComponentIntl));
