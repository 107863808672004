import React from 'react';

export const StarSvg: React.FunctionComponent = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 0L9.48378 4.76957L14.6329 5.18237L10.7098 8.54293L11.9084 13.5676L7.5 10.875L3.09161 13.5676L4.29018 8.54293L0.367076 5.18237L5.51622 4.76957L7.5 0Z"
      fill="#20274D"
    />
  </svg>
);
