import React from 'react';
import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import ImageGallery from 'react-image-gallery';
import { IImage } from '@share/common-types';

import './style.scss';

interface IProps {
  images: IImage[];
  visible: boolean;
  titleIntlId?: string;
  startIndex: number;
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>, isScrollToRooms?: boolean) => void;
}

export class ImagesSlider extends React.Component<IProps> {
  render(): React.ReactNode {
    const { images, visible, titleIntlId, startIndex, onCancel } = this.props;

    const imagesGallery = images.map((image) => {
      return {
        original: image.url,
        thumbnail: image.url,
      };
    });

    return (
      <Modal
        className="images-slider"
        visible={visible}
        footer={null}
        onCancel={onCancel}
        wrapClassName="images-slider-wrapper"
      >
        {titleIntlId && (
          <div className="images-slider__header">
            <h4 className="images-slider__title">
              <FormattedMessage id={titleIntlId} />
            </h4>
          </div>
        )}

        <ImageGallery startIndex={startIndex} showIndex items={imagesGallery} />
      </Modal>
    );
  }
}
