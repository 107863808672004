import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRedeemCode } from '@common-types';
import { ERROR_COUPON_STATUS } from '@constants';

import { ISessionKey } from '@share/common-types';
import { Urls } from '@share/constants';
import { AppThunk } from '@share/utils';
import { getHeaders, axiosInstance } from '@share/utils';

export interface ICondoCouponState {
  condoRedeemCode: IRedeemCode;
  loading: boolean;
  error: string;
  coupon: string;
  isGetCoupon: boolean;
  errorText: string;
}

const initialState: ICondoCouponState = {
  condoRedeemCode: null,
  loading: false,
  error: '',
  coupon: '',
  isGetCoupon: false,
  errorText: '',
};

const zeroItem = 0;

const condoRedeemCodeSlice = createSlice({
  name: 'condoRedeemCode',
  initialState,
  reducers: {
    setLoadingCondoCoupon: (state: ICondoCouponState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setErrorCondoCoupon: (state: ICondoCouponState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setCondoRedeemCode: (state: ICondoCouponState, { payload }: PayloadAction<IRedeemCode>) => {
      state.condoRedeemCode = payload;
    },
    setCondoCoupon: (state: ICondoCouponState, { payload }: PayloadAction<string>) => {
      state.coupon = payload;
    },
    setUpdateCondoCoupon: (state: ICondoCouponState, { payload }: PayloadAction<boolean>) => {
      state.isGetCoupon = payload;
    },
    setErrorCondoText: (state: ICondoCouponState, { payload }: PayloadAction<string>) => {
      state.errorText = payload;
    },
  },
});

export const {
  setLoadingCondoCoupon,
  setErrorCondoCoupon,
  setCondoRedeemCode,
  setCondoCoupon,
  setUpdateCondoCoupon,
  setErrorCondoText,
} = condoRedeemCodeSlice.actions;

export const condoRedeemCodeReducer = condoRedeemCodeSlice.reducer;

export const getCondoRedeemCode = (code: string, sessionKey: ISessionKey): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoadingCondoCoupon(true));

    try {
      const res = await axiosInstance.post(
        Urls.CondoRedeemCode,
        { code, sessionKey, isCondo: true, isHotel: false },
        { ...getHeaders() },
      );

      dispatch(setLoadingCondoCoupon(false));
      dispatch(setCondoRedeemCode(res.data));

      if (res.data.success) {
        dispatch(setUpdateCondoCoupon(true));
      }
    } catch (error) {
      console.error(error);
      dispatch(setErrorCondoCoupon(error.toString()));
      dispatch(setLoadingCondoCoupon(false));
      dispatch(setUpdateCondoCoupon(false));

      if (error?.response?.status === ERROR_COUPON_STATUS && error?.response?.data) {
        dispatch(setErrorCondoText(error?.response?.data[zeroItem]));
      }
    }
  };
};

export const getCondoRemoveCode = (sessionKey: ISessionKey): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const { loginStore } = getState();
      const { user } = loginStore;
      const res = await axiosInstance.post(
        Urls.CondoRemoveCode,
        { sessionKey },
        { ...getHeaders() });

      dispatch(setCondoRedeemCode(res.data));
      dispatch(setUpdateCondoCoupon(false));
    } catch (error) {
      console.error(error);
    }
  };
};
