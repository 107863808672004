import React, { Component } from 'react';
import { SubmitRequestWrapper } from '@components';
import './style.scss';

interface IProps {
  isCondoPage?: boolean;
}

export class SubmitRequestPage extends Component<IProps> {
  render(): React.ReactNode {
    const { isCondoPage } = this.props;

    return (
      <div className="submit-request-page">
        <SubmitRequestWrapper isCondoPage={isCondoPage} />
      </div>
    );
  }
}
