import React from 'react';

import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { ICar } from '@share/common-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IProps extends RouteComponentProps {
  car: ICar;
}

class CarsCardRefundabilityComponent extends React.Component<IProps> {

  render(): React.ReactNode {
    const { car } = this.props;
    const { refundable } = car;

    return (
      <div className="hotel-card-wrapper__refundable cars-content" style={{ maxWidth: '160px' }}>
        {refundable && (
          <div className="hotel-card-wrapper__refundable-ref">
            <FontAwesomeIcon icon={faCheck} /><FormattedMessage id="refundable" />
          </div>)}
        {!refundable && (
          <div className="hotel-card-wrapper__refundable-nonref">
            <FontAwesomeIcon icon={faTimes} /><FormattedMessage id="non.refundable" />
          </div>)}
      </div>
    );
  }
}

const CarsCardRefundabilityComponentRouter = withRouter(CarsCardRefundabilityComponent);

export const CarsCardRefundability = connect()(CarsCardRefundabilityComponentRouter);
