import React, { Component } from 'react';
import { Skeleton } from 'antd';

import './style.scss';

const ONE = 1;
const FIVE = 5;

export class SkeletonItems extends Component {
  render(): React.ReactNode {
    return (
      <div className="skeleton-items-wrapper">
        <div className="skeleton-items-wrapper__wrapper">
          <div className="skeleton-items-wrapper__heading">
            <Skeleton active title paragraph={false} />
          </div>
          <div className="skeleton-items-wrapper__items">
            <div className="skeleton-items-wrapper__item span">
              <div className="skeleton-items-wrapper__item-image">
                <Skeleton active title={false} paragraph={{ rows: ONE, width: '100%' }} />
              </div>
              <div className="skeleton-items-wrapper__item-text-wrapper">
                <div className="skeleton-items-wrapper__item-heading">
                  <Skeleton active title={false} paragraph={{ rows: ONE, width: '45%' }} />
                </div>
                <div className="skeleton-items-wrapper__item-paragraph">
                  <Skeleton active title={false} paragraph={{ rows: FIVE, width: '100%' }} />
                </div>
              </div>
            </div>
            <div className="skeleton-items-wrapper__item span">
              <div className="skeleton-items-wrapper__item-image">
                <Skeleton active title={false} paragraph={{ rows: ONE, width: '100%' }} />
              </div>
              <div className="skeleton-items-wrapper__item-text-wrapper">
                <div className="skeleton-items-wrapper__item-heading">
                  <Skeleton active title={false} paragraph={{ rows: ONE, width: '45%' }} />
                </div>
                <div className="skeleton-items-wrapper__item-paragraph">
                  <Skeleton active title={false} paragraph={{ rows: FIVE, width: '100%' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
