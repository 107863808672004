import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { IBenefitsState, ILoginState, IMenuState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { fetchMemberStories } from '@store/slices';
import { Responsive } from '@share/utils';
import { CompanyLogos, GetawayList } from '@components';
import { GetawaysLocationEnum, ISideKickStatus } from '@share/common-types';
import { LandingCompanyLogos, TreatYourselfLogos } from '@common-types';
import { NULL_VALUE } from '@constants';

import { TravelLeader } from '../travel-leader';
import { ListClickableCards } from '../list-clickable-cards';
import { TutorialVideos } from '../tutorial-videos';
import { TreatYourself } from '../../../common';
import { MasterClasses } from '../master-classes';
import { Benefits } from '../benefits';
import { ConciergeServices } from '../concierge-services';
import { SpecialBanners } from '../special-banners';

import './style.scss';

interface IMapStateToProps {
  menuStore: IMenuState;
  benefitsStore: IBenefitsState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getMemberStories: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {}

function HomeRsiComponent(props: IProps) {

  const { menuStore, benefitsStore, loginStore, getMemberStories } = props;
  const { account } = loginStore;
  const { items } = menuStore;
  const { benefits } = benefitsStore;

  useEffect(() => {
    getMemberStories();
  }, []);

  const siteId = items?.siteId;
  const organizationId = items?.organizationId;

  const displaySpecialBannersRsi = account?.displaySpecialBannersRsi;
  const displaySpecialBannersRsiOrgidList = account?.displaySpecialBannersRsiOrgidList;
  const displaySpecialBannersRsiSiteidList = account?.displaySpecialBannersRsiSiteidList;

  const displayBanners = useMemo(() => displaySpecialBannersRsi && ((!displaySpecialBannersRsiOrgidList?.length && !displaySpecialBannersRsiSiteidList?.length) || 
                                                                      displaySpecialBannersRsiOrgidList?.includes(organizationId?.toString() as string) || 
                                                                      displaySpecialBannersRsiSiteidList?.includes(siteId?.toString() as string)),[account, items, organizationId, siteId]);

  return (
    <div className="home-rsi-wrapper">
      <Responsive>
        <TravelLeader />
      </Responsive>

      {(benefits?.items && items?.isMLM && items?.sideKickStatus === ISideKickStatus.PARENT) ? (
        <GetawayList
          getaway={GetawaysLocationEnum.Staycation}
          condoSubcategory={NULL_VALUE}
        
          isFromProperty
          isInternal
          isHome
        />) :
        <div style={{ width: '100%', padding: '20px' }}></div>}

      <SpecialBanners display={displayBanners as boolean} />

      {!items?.isMLM ? (<Benefits />) : null}

      <ConciergeServices />

      <ListClickableCards />

      {!items?.isMLM ? (<TutorialVideos />) : null}

      <CompanyLogos logos={LandingCompanyLogos} />

      <TreatYourself logos={TreatYourselfLogos} />

      <MasterClasses />
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
    benefitsStore: state.benefitsStore,
    loginStore: state.loginStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = { getMemberStories: fetchMemberStories };

export const HomeRsi = connect(mapStateToProps, mapDispatchToProps)(HomeRsiComponent);
