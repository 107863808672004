import React from 'react';

type SizeProps = {
    size?: string;
}
  
export const CoffeeMakerSvg: React.FunctionComponent<SizeProps> = ({ size }: SizeProps) => (
    <svg width={size || 16} height={size || 16} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 49.0584C12 47.0701 13.6119 45.4582 15.6002 45.4582H46.3081C48.2965 45.4582 49.9083 47.0701 49.9083 49.0584V51.388C49.9083 52.0898 49.3394 52.6587 48.6377 52.6587H13.2707C12.5689 52.6587 12 52.0898 12 51.388V49.0584ZM15.6002 47.576C14.7815 47.576 14.1178 48.2397 14.1178 49.0584V50.5409H47.7906V49.0584C47.7906 48.2397 47.1268 47.576 46.3081 47.576H15.6002Z" fill="black" />
        <path d="M15.6002 46.3053C15.6002 47.0071 16.1691 47.576 16.8709 47.576H45.0374C45.7392 47.576 46.3081 47.0071 46.3081 46.3053V23.645C46.3081 22.9432 45.7392 22.3743 45.0374 22.3743H16.8709C16.1691 22.3743 15.6002 22.9432 15.6002 23.645V46.3053ZM17.718 45.4582V24.4921H44.1903V45.4582H17.718Z" fill="black" />
        <path d="M12 13.4797C12 12.7779 12.5689 12.209 13.2707 12.209H48.6377C49.3394 12.209 49.9083 12.7779 49.9083 13.4797V23.2215C49.9083 23.9232 49.3394 24.4921 48.6377 24.4921H13.2707C12.5689 24.4921 12 23.9232 12 23.2215V13.4797ZM14.1178 14.3268V22.3743H47.7906V14.3268H14.1178Z" fill="black" />
        <path d="M25.7531 30.4738C25.8776 31.0609 26.396 31.4808 26.9961 31.4808H34.9123C35.5125 31.4808 36.0308 31.0609 36.1554 30.4738L37.548 23.9087C37.7156 23.1184 37.1128 22.3743 36.3049 22.3743H25.6035C24.7956 22.3743 24.1929 23.1184 24.3605 23.9087L25.7531 30.4738ZM27.6824 29.363L26.6492 24.4921H35.2593L34.2261 29.363H27.6824Z" fill="black" />
        <path d="M25.7531 45.7219C25.8776 46.309 26.396 46.7289 26.9961 46.7289H34.9123C35.5125 46.7289 36.0308 46.309 36.1554 45.7219L37.548 39.1567C37.7156 38.3664 37.1128 37.6224 36.3049 37.6224H25.6035C24.7956 37.6224 24.1929 38.3664 24.3605 39.1567L25.7531 45.7219ZM27.6824 44.6111L26.6492 39.7402H35.2593L34.2261 44.6111H27.6824Z" fill="black" />
        <path d="M35.5074 41.4344C35.5074 40.8496 35.9815 40.3755 36.5663 40.3755H39.5312C40.116 40.3755 40.5901 40.8496 40.5901 41.4344C40.5901 42.0192 40.116 42.4933 39.5312 42.4933H36.5663C35.9815 42.4933 35.5074 42.0192 35.5074 41.4344Z" fill="black" />
        <path d="M17.0827 18.3506C17.0827 17.7657 17.5568 17.2917 18.1416 17.2917H23.436C24.0208 17.2917 24.4949 17.7657 24.4949 18.3506C24.4949 18.9354 24.0208 19.4095 23.436 19.4095H18.1416C17.5568 19.4095 17.0827 18.9354 17.0827 18.3506Z" fill="black" />
        <path d="M37.6252 18.3506C37.6252 17.7657 38.0993 17.2917 38.6841 17.2917H38.79C39.3748 17.2917 39.8489 17.7657 39.8489 18.3506C39.8489 18.9354 39.3748 19.4095 38.79 19.4095H38.6841C38.0993 19.4095 37.6252 18.9354 37.6252 18.3506Z" fill="black" />
        <path d="M42.0725 18.3506C42.0725 17.7657 42.5466 17.2917 43.1314 17.2917H43.2373C43.8221 17.2917 44.2962 17.7657 44.2962 18.3506C44.2962 18.9354 43.8221 19.4095 43.2373 19.4095H43.1314C42.5466 19.4095 42.0725 18.9354 42.0725 18.3506Z" fill="black" />
        <path d="M30.8483 29.5748C31.4331 29.5748 31.9072 30.0489 31.9072 30.6337V32.9633C31.9072 33.5481 31.4331 34.0222 30.8483 34.0222C30.2635 34.0222 29.7894 33.5481 29.7894 32.9633V30.6337C29.7894 30.0489 30.2635 29.5748 30.8483 29.5748Z" fill="black" />
        <path d="M35.083 28.6218C35.083 28.037 35.5571 27.5629 36.1419 27.5629H52.0267C52.6115 27.5629 53.0856 28.037 53.0856 28.6218C53.0856 29.2066 52.6115 29.6807 52.0267 29.6807H36.1419C35.5571 29.6807 35.083 29.2066 35.083 28.6218Z" fill="black" />
    </svg>
);