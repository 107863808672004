import React from 'react';

export const SmokingSvg: React.FunctionComponent = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.33337 10.6668H11.3334V12.6668H1.33337V10.6668ZM13.6667 10.6668H14.6667V12.6668H13.6667V10.6668ZM12 10.6668H13V12.6668H12V10.6668ZM12.5667 5.1535C12.98 4.74683 13.2334 4.18683 13.2334 3.56683C13.2334 2.3335 12.2334 1.3335 11 1.3335V2.3335C11.68 2.3335 12.2334 2.88683 12.2334 3.56683C12.2334 4.24683 11.68 4.80016 11 4.80016V5.80016C12.4934 5.80016 13.6667 7.02016 13.6667 8.5135V10.0002H14.6667V8.50683C14.6667 7.02683 13.8134 5.74683 12.5667 5.1535ZM10.6867 6.80016H9.66671C8.98671 6.80016 8.43337 6.14683 8.43337 5.46683C8.43337 4.78683 8.98671 4.30016 9.66671 4.30016V3.30016C8.43337 3.30016 7.43337 4.30016 7.43337 5.5335C7.43337 6.76683 8.43337 7.76683 9.66671 7.76683H10.6867C11.3867 7.76683 12 8.26016 12 9.1335V10.0002H13V8.90683C13 7.70016 11.9334 6.80016 10.6867 6.80016Z"
      fill="#333333"
    />
  </svg>
);

type SizeProps = {
  size?: string;
}

export const SmokingVacSvg: React.FunctionComponent<SizeProps> = ({ size }: SizeProps) => (
  <svg width={size || 16} height={size || 16} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88">
    <path fill="#fff" d="M61.44,12.1A49.34,49.34,0,1,1,12.1,61.44,49.34,49.34,0,0,1,61.44,12.1Z" />
    <path fill="#33a867" d="M61.44,0A61.31,61.31,0,1,1,38,4.66,61.29,61.29,0,0,1,61.44,0ZM92.27,23a49.43,49.43,0,0,0-49.7-7.09A49.32,49.32,0,1,0,107,80.3a49,49,0,0,0,3.73-18.86h0a49.16,49.16,0,0,0-8.48-27.67A51,51,0,0,0,97.65,28,49.39,49.39,0,0,0,92.27,23Z" />
    <path d="M25.38,68.87H97.5A2.53,2.53,0,0,1,100,71.39v9.7a2.52,2.52,0,0,1-2.52,2.52H25.38a2.52,2.52,0,0,1-2.52-2.52v-9.7a2.53,2.53,0,0,1,2.52-2.52ZM89.61,65a2,2,0,0,1-1.67,2.14A1.89,1.89,0,0,1,86,65.25c-.15-2.58-.35-4-.77-4.74A4.62,4.62,0,0,0,83.11,59c-1.49-.72-5.47-.81-10.12-.92-11.11-.25-25.63-.59-26.11-10.62-.27-5.74,4.51-8.53,9.46-11.43,3.86-2.26,7.85-4.6,8.41-8.06V27.9a3.71,3.71,0,0,0-1-3.14A11.32,11.32,0,0,0,58.42,22a2,2,0,0,1-1.31-2.43,1.8,1.8,0,0,1,2.17-1.47,14.57,14.57,0,0,1,6.93,3.8,7.81,7.81,0,0,1,2.07,6.72v.09c-.88,5.41-5.66,8.21-10.28,10.91-3.91,2.29-7.68,4.5-7.54,7.66.3,6.3,12.94,6.59,22.61,6.81,5,.11,9.36.21,11.46,1.23,1.8.87,2.94,1.55,3.81,3.13.76,1.41,1.09,3.3,1.28,6.58Zm9.52-1.93a1.49,1.49,0,1,1-2.94,0c0-11.43-3.24-11.91-9.77-12.86-1-.15-2.15-.32-3.41-.54a28.39,28.39,0,0,1-8.25-2.44A8.25,8.25,0,0,1,69.94,40a9.59,9.59,0,0,1,2.85-6.87,26.67,26.67,0,0,1,5.75-4.53,1.37,1.37,0,0,1,2,.75,1.91,1.91,0,0,1-.63,2.35,23.63,23.63,0,0,0-5.11,4,6,6,0,0,0-1.91,4.12c.09,2,1.33,3.31,3.09,4.25a26.67,26.67,0,0,0,7.48,2.16c1.14.2,2.27.37,3.33.53C95,48,99.13,48.55,99.13,63.07Zm-2.58,8.61a1.17,1.17,0,0,1,1.2,1.2c-.41,4.63-4.43-1.2-1.2-1.2Zm-2.5,3.55a.75.75,0,0,1,.77.77c-.26,3-2.85-.77-.77-.77ZM96.43,79a.71.71,0,0,1,.73.72c-.25,2.8-2.68-.72-.73-.72Zm-4.61-7.31a.48.48,0,0,1,.49.48c-.17,1.86-1.79-.48-.49-.48ZM42.71,71.2H87.44L86.31,73l2,.18c-.72,1-2.19,1.58-3.34,2.55,2.51-.26,1.93,1.65,2.89,2.48-1.51,2.15,1.28,2.08,1.92,3.13H42.71V71.2Z" />
  </svg>
);

export const SmokingOffSvg: React.FunctionComponent<SizeProps> = ({ size }: SizeProps) => (
  <svg width={size || 16} height={size || 16} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88">
    <path fill="#fff" d="M61.44,12.1A49.34,49.34,0,1,1,12.1,61.44,49.34,49.34,0,0,1,61.44,12.1Z" />
    <path d="M25.38,68.87H97.5A2.53,2.53,0,0,1,100,71.39v9.7a2.52,2.52,0,0,1-2.52,2.52H25.38a2.52,2.52,0,0,1-2.52-2.52v-9.7a2.53,2.53,0,0,1,2.52-2.52ZM89.61,65a2,2,0,0,1-1.67,2.14A1.89,1.89,0,0,1,86,65.25c-.15-2.58-.35-4-.77-4.74A4.62,4.62,0,0,0,83.11,59c-1.49-.72-5.47-.81-10.12-.92-11.11-.25-25.63-.59-26.11-10.62-.27-5.74,4.51-8.53,9.46-11.43,3.86-2.26,7.85-4.6,8.41-8.06a3.7,3.7,0,0,0-1-3.2A11.32,11.32,0,0,0,58.42,22a2,2,0,0,1-1.31-2.43,1.8,1.8,0,0,1,2.17-1.47,14.57,14.57,0,0,1,6.93,3.8,7.86,7.86,0,0,1,2.06,6.81c-.88,5.41-5.66,8.21-10.28,10.91-3.91,2.29-7.68,4.5-7.54,7.66.3,6.3,12.94,6.59,22.61,6.81,5,.11,9.36.21,11.46,1.23,1.8.87,2.94,1.55,3.81,3.13.76,1.41,1.09,3.3,1.28,6.58Zm9.52-1.93a1.49,1.49,0,1,1-2.94,0c0-11.43-3.24-11.91-9.77-12.86-1-.15-2.15-.32-3.41-.54a28.39,28.39,0,0,1-8.25-2.44A8.25,8.25,0,0,1,69.94,40a9.55,9.55,0,0,1,2.85-6.87,26.67,26.67,0,0,1,5.75-4.53,1.37,1.37,0,0,1,2,.75,1.91,1.91,0,0,1-.63,2.35,23.63,23.63,0,0,0-5.11,4,6,6,0,0,0-1.91,4.12c.09,2,1.33,3.31,3.09,4.25a26.67,26.67,0,0,0,7.48,2.16c1.14.2,2.27.37,3.33.53C95,48,99.13,48.55,99.13,63.07Zm-2.58,8.61a1.17,1.17,0,0,1,1.2,1.2c-.41,4.63-4.43-1.2-1.2-1.2Zm-2.5,3.55a.75.75,0,0,1,.77.77c-.26,3-2.85-.77-.77-.77ZM96.43,79a.71.71,0,0,1,.73.72c-.25,2.8-2.68-.72-.73-.72Zm-4.61-7.31a.48.48,0,0,1,.49.48c-.17,1.86-1.79-.48-.49-.48ZM42.71,71.2H87.44L86.31,73l2,.18c-.72,1-2.19,1.58-3.34,2.55,2.51-.26,1.93,1.65,2.89,2.48-1.51,2.15,1.28,2.08,1.92,3.13H42.71V71.2Z" />
    <path fill="#d92d27" d="M61.44,0A61.31,61.31,0,1,1,38,4.66,61.29,61.29,0,0,1,61.44,0Zm40.24,32.93L32.93,101.68A49.44,49.44,0,0,0,80.31,107,49.53,49.53,0,0,0,107,80.3a49,49,0,0,0,3.73-18.86h0a48.93,48.93,0,0,0-9.08-28.51ZM24,93.5,93.5,24A49.32,49.32,0,0,0,24,93.5Z" />
  </svg>
);