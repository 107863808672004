import React, { useEffect, useRef, useState, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  IWeekState,
  IWeeksFiltersState,
  IWeeksLocationsState,
  weeksActions,
  GetWeeks,
  ResetWeeksFull,
} from '@share/store/slices';
import { ILoginState, IMenuState } from '@share/store/slices';
import { IWeek, SearchTypeEnum } from '@share/common-types';
import { GetHomeParams, RootState, getAccountUsernameFromPath } from '@share/utils';
import { CondoExpiredSessionMessage, CondoSkeletonResult } from '@components';
import { FilterSvg } from '@assets';
import { CloseSvg } from '@share/assets';
import { isWeeksFiltersEmpty } from '@utils';
import { UrlUtils } from '@share/utils';
import { APP_SELECTOR } from '@constants';
import { WEEKS_FILTERS_LABEL, DEFAULT_PAGE_SIZE, Routes } from '@share/constants';

import { WeeksFiltersWrapper } from '../weeks-filters-wrapper';

import { CondoSkeletonFilters } from '../../../../condo/condo-result/condo-skeleton-filters';

import MapMarker from '@assets/images/map-price-icon.png';
import MapMarkerSelected from '@assets/images/map-price-selected-icon.png';
import MapMultipleDoubleMarker from '@assets/images/map-multiple-double-price-icon.png';
import MapMultipleDoubleMarkerSelected from '@assets/images/map-multiple-double-price-selected-icon.png';
import MapMultipleMarker from '@assets/images/map-multiple-price-icon.png';
import MapMultipleMarkerSelected from '@assets/images/map-multiple-price-selected-icon.png';

import { WeeksResultFiltersHeader } from './week-result-filters-header';
import { WeeksResultMap } from './week-result-map';
import { WeeksResult } from './week-result';

import './style.scss';

interface IMapStateToProps {
  weeksStore: IWeekState;
  weeksFiltersStore: IWeeksFiltersState;
  weeksLocationsStore: IWeeksLocationsState;

  menuStore: IMenuState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getWeeks: (searchType: SearchTypeEnum) => void;
  setSelectedWeek: (week: IWeek) => void;
  addMapWeek: (week: IWeek) => void;

  resetWeeksFull: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  matches?: boolean;
}

const debounceTime = 300;
const scrollPixels = 150;
const scrollWindowPixels = 350;
const zero = 0;
const one = 1;
const topZIndex = 5;
const CHECK_WIDTH = 1020;

function WeeksResultWrapperComponent(props: IProps) {
  const [visibleFilters, setVisibleFilters] = useState(false);
  const [visibleMap, setVisibleMap] = useState(false);
  const [isNoAvailableCondosNotification, setIsNoAvailableCondosNotification] = useState(false);

  const {
    weeksLocationsStore,
    loginStore,
    weeksStore,
    weeksFiltersStore,
    menuStore,
    matches,
  } = props;

  const { selectedLocation } = weeksLocationsStore;
  const { account } = loginStore;
  const {
    weeks,
    loading,
    isSessionExpired,
    loadingMore,
    counters,
    mapWeeks,
    selectedCompareWeeks,
  } = weeksStore;
  const { location } = weeksLocationsStore;

  const loadingRef = useRef(loading);
  const loadingMoreRef = useRef(loadingMore);
  const isSessionExpiredRef = useRef(isSessionExpired);
  const contentWrapperRef = useRef(null);
  const weeksRef = useRef(weeks);
  const countersRef = useRef(counters);

  const root: HTMLDivElement = document.querySelector(APP_SELECTOR);

  let prevFilters: IWeeksFiltersState = null;

  const handleResize = debounce(() => {
    const footer: HTMLDivElement = document.querySelector('.footer');
    showBodyScroll(!!(window.innerWidth <= CHECK_WIDTH && footer));
  }, debounceTime);

  const showBodyScroll = (show: boolean, withScroll = true) => {
    const footer: HTMLDivElement = document.querySelector('.footer');

    if (show) {
      document.body.style.overflow = 'auto';
      if (!isNull) {
        footer.style.display = 'block';
      }
    } else {
      if (withScroll) {
        window.scrollTo(zero, zero);
      }
      const values = UrlUtils.getValues();

      if (!isEmpty(values) && props.matches) {
        document.body.style.overflow = 'hidden';
      }

      if (!isNull) {
        footer.style.display = 'none';
      }
    }
  };

  const toggleFilters = () => {
    setVisibleFilters(!visibleFilters);
    showBodyScroll(!visibleFilters && !visibleMap, false);
  };

  const showMap = () => {
    setVisibleMap(!visibleMap);
    showBodyScroll(!visibleMap, false);
  };

  const onWindowScroll = debounce(() => {
    baseScroll(
      window.innerWidth <= CHECK_WIDTH,
      window.innerHeight + window.scrollY + scrollWindowPixels >= root.offsetHeight,
    );
  }, debounceTime);

  const onScroll = debounce(() => {
    const { current } = contentWrapperRef;
    baseScroll(
      window.innerWidth > CHECK_WIDTH,
      current.scrollTop + current.clientHeight > current.scrollHeight - scrollPixels,
    );
  }, debounceTime);

  const baseScroll = (condition1: boolean, condition2: boolean) => {
    if (!selectedLocation) {
      return;
    }

    if (
      weeksRef.current?.length > zero &&
      weeksRef.current?.length % DEFAULT_PAGE_SIZE === zero &&
      weeksRef.current?.length < countersRef.current?.totalFiltered &&
      !isSessionExpired &&
      !loadingMoreRef.current &&
      !loadingRef.current &&
      condition1 &&
      condition2
    ) {
      props.getWeeks(SearchTypeEnum.Pagination);
    }
  };

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
    contentWrapperRef?.current?.addEventListener('scroll', onScroll);
    window.addEventListener('scroll', onWindowScroll);

    setIsNoAvailableCondosNotification(isWeeksFiltersEmpty(weeksFiltersStore));

    loadingRef.current = loading;

    return () => {
      window.removeEventListener('resize', handleResize);
      contentWrapperRef?.current?.removeEventListener('scroll', onScroll);
      window.removeEventListener('scroll', onWindowScroll);

      props.setSelectedWeek(null);

      setTimeout(() => {
        document.body.style.overflow = 'auto';
      }, 10);
    };
  }, []);

  useEffect(() => {
    if (loadingRef.current !== loading && !loading) {
      setIsNoAvailableCondosNotification(isWeeksFiltersEmpty(weeksFiltersStore));
    }
    loadingRef.current = loading;
  }, [loading]);

  useEffect(() => {
    loadingMoreRef.current = loadingMore;
  }, [loadingMore]);

  useEffect(() => {
    isSessionExpiredRef.current = isSessionExpired;
  }, [isSessionExpired]);

  useEffect(() => {
    weeksRef.current = weeks;
  }, [weeks]);

  useEffect(() => {
    countersRef.current = counters;
  }, [counters]);

  const updateMarker = (
    marker: HTMLElement,
    zIndex: number,
    background1: string,
    background2: string,
    background3: string,
  ) => {
    marker.style.zIndex = `${zIndex}`;

    const locationList = marker.getAttribute('data-item-location-id');
    const locationListSplit = locationList ? locationList.split(',') : [];

    if (locationListSplit?.length == 2) {
      marker.style.background = `url(${background1})`;
    } else if (locationListSplit?.length > 2) {
      marker.style.background = `url(${background2})`;
    } else {
      marker.style.background = `url(${background3})`;
    }
  };

  const selectWeek = (week: IWeek) => {
    const markers = document.querySelectorAll('.price-marker');

    if (markers && markers.length) {
      markers.forEach((marker: HTMLDivElement) => {
        if (marker.dataset.itemNotChange) {
          return;
        }

        updateMarker(marker, one, MapMultipleDoubleMarker, MapMultipleMarker, MapMarker);
      });
    }

    props.setSelectedWeek(week);

    if (week) {
      if (mapWeeks.every(({ propertyId }) => propertyId !== week.propertyId)) {
        props.addMapWeek(week);
      }

      const selectedMarker: HTMLDivElement = document.querySelector(
        `[data-item-id="${week.propertyId}"]`,
      );

      if (selectedMarker && !selectedMarker.dataset.itemNotChange) {
        updateMarker(
          selectedMarker,
          topZIndex,
          MapMultipleDoubleMarkerSelected,
          MapMultipleMarkerSelected,
          MapMarkerSelected,
        );
      }

      if (!matches && !visibleMap) {
        setTimeout(() => {
          showMap();
        });
      }
    }
  };

  const onFiltersOrSortChange = debounce(() => {
    if (!isEqual(prevFilters, weeksFiltersStore)) {
      UrlUtils.setUrl(WEEKS_FILTERS_LABEL, weeksFiltersStore);
      prevFilters = weeksFiltersStore;
      props.getWeeks(SearchTypeEnum.SortsFilters);
      setIsNoAvailableCondosNotification(false);
    }
  }, debounceTime);

  const accountName = useMemo(() => getAccountUsernameFromPath(props.history), [props.history]);
  const homeParams = useMemo(() => GetHomeParams(account), [account]);

  const isDisableHomes = account?.isDisableHomes;

  const hasFooterMenu = menuStore?.items?.footer?.length > 0;

  return (
    <div className="condo-result-wrapper">
      <div className={`condo-result-wrapper__wrapper`}>
        <div
          ref={contentWrapperRef}
          className={`condo-result-wrapper__content ${account?.forceIframe ? 'force-iframe' : ''} ${
            visibleFilters ? 'filters-content' : ''
          } ${!hasFooterMenu ? 'no-footer' : ''}`}
        >
          <div className={`condo-result-wrapper__filters-wrapper ${visibleFilters ? 'show' : ''}`}>
            {visibleFilters ? (
              loading ? (
                <CondoSkeletonFilters />
              ) : (
                <WeeksFiltersWrapper
                  onClose={toggleFilters}
                  onFiltersOrSortChange={onFiltersOrSortChange}
                  isDisabled={isSessionExpired}
                  matches={matches}
                />
              )
            ) : null}
          </div>

          <div
            className={`condo-result-wrapper__hotels-wrapper ${!visibleFilters ? 'show' : ''} ${
              selectedCompareWeeks?.length && !hasFooterMenu ? 'compare-open' : ''
            }`}
          >
            {!loading && !isDisableHomes ? (
              <div className="result-wrapper__back-link">
                <div
                  className="back-home"
                  onClick={() => {
                    props.resetWeeksFull();
                    props.history.push(`/${accountName}${Routes.WeeksSearch}${homeParams}`);
                  }}
                >
                  <FormattedMessage id="back.home" />
                </div>
              </div>
            ) : null}

            {!matches && visibleMap && (
              <div className="condo-result-wrapper__map-top">
                <div className="condo-result-wrapper__map-close" onClick={showMap}>
                  <CloseSvg />
                </div>
                <div className="condo-result-wrapper__map-location">{location?.name}</div>
                <div
                  className={`condo-result-wrapper__map-filters ${
                    isSessionExpired ? 'disabled' : ''
                  }`}
                  onClick={toggleFilters}
                >
                  <FilterSvg />
                </div>
              </div>
            )}

            {loading ? (
              <CondoSkeletonResult />
            ) : (
              <>
                {isSessionExpired && (
                  <div className="condo-result-wrapper__session-expired">
                    <CondoExpiredSessionMessage onRefresh={() => window.location.reload()} />
                  </div>
                )}

                <WeeksResultFiltersHeader
                  matches={matches}
                  visibleMap={visibleMap}
                  visibleFilters={visibleFilters}
                  setVisibleMap={setVisibleMap}
                  setVisibleFilters={setVisibleFilters}
                  setIsNoAvailableCondosNotification={setIsNoAvailableCondosNotification}
                  showBodyScroll={showBodyScroll}
                  onFiltersOrSortChange={onFiltersOrSortChange}
                />

                <WeeksResult
                  matches={matches}
                  isNoAvailableCondosNotification={isNoAvailableCondosNotification}
                  onFiltersOrSortChange={onFiltersOrSortChange}
                  selectWeek={selectWeek}
                  updateMarker={updateMarker}
                />
              </>
            )}
          </div>
        </div>

        <WeeksResultMap
          matches={matches}
          visibleMap={visibleMap}
          setVisibleMap={setVisibleMap}
          selectWeek={selectWeek}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    weeksStore: state.weeksStore,
    weeksFiltersStore: state.weeksFiltersStore,
    weeksLocationsStore: state.weeksLocationsStore,

    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setSelectedWeek: weeksActions.setSelectedWeeks,
  addMapWeek: weeksActions.addMapWeek,

  getWeeks: GetWeeks,
  resetWeeksFull: ResetWeeksFull,
};

export const WeeksResultWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(WeeksResultWrapperComponent));
