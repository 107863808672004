import React from 'react';

import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';

import { getFullAddress } from '@utils';
import { ICondoUnitMap } from '@components';

import './style.scss';

interface IProps extends RouteComponentProps {
  condo: ICondoUnitMap;
}

class CondoCardWrapper extends React.Component<IProps> {

  render(): React.ReactNode {
    const { condo } = this.props;
    const {
      ImageURL,
      Resort,
      StateCode,
      UnitsAvailable,
      city,
      country,
    } = condo;
    
    const fullAddress = getFullAddress(null, city, StateCode, null, country);
    const addressStr = fullAddress.join(', ');

    return (
      <div className="condo-card-wrapper">
        <div className="condo-card-wrapper__image">
          <img src={ImageURL} />
        </div>
        <div className="condo-card-wrapper__content">
          <p className="condo-card-wrapper__address">
            <span>{addressStr}</span>
          </p>

          <div className="condo-card-wrapper__condo-title">
            {Resort}
          </div>

          <div className="condo-card-wrapper__available-unit">
            <FormattedMessage id="condos.world.map.units_available" />:
            <span style={{ marginLeft: '10px' }}>{UnitsAvailable}</span>
          </div>
        </div>

        <div className="condo-card-wrapper__triangle-down"></div>
      </div>
    );
  }
}

const CondoCardWrapperRouter = withRouter(CondoCardWrapper);

export const CondoCard = connect()(CondoCardWrapperRouter);
