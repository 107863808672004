import React from 'react';
import { scrollTop } from '@share/utils';
import { AllGetawayWrapper } from '@components';

import './style.scss';

interface IProps {}

export class AllGetawayPage extends React.Component {
  constructor(props: IProps) {
    super(props);

    scrollTop();
  }

  render(): React.ReactNode {
    return <AllGetawayWrapper />;
  }
}
