import React from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  IWeekState,
  IWeeksLocationsState,
  weeksActions,
  GetWeeks,
} from '@share/store/slices';
import { ILoginState, IMenuState } from '@share/store/slices';
import { IWeek, SearchTypeEnum } from '@share/common-types';
import { RootState } from '@share/utils';
import { CondoMatchTypeEnum, IBounds } from '@share/common-types';

import { WeeksMapWrapper } from '../../weeks-map';

import CondoMapPlaceholder from '@assets/images/condo-map-placeholder.jpg';

import './style.scss';

interface IMapStateToProps {
  weeksStore: IWeekState;
  weeksLocationsStore: IWeeksLocationsState;

  menuStore: IMenuState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getWeeks: (searchType: SearchTypeEnum) => void;
  setBounds: (bounds: IBounds) => void;
  addMapWeek: (week: IWeek) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  matches: boolean;
  visibleMap: boolean;
  
  setVisibleMap: (value: boolean) => void;
  selectWeek: (week: IWeek) => void;
}

function WeeksResultMapComponent(props: IProps) {

  const { weeksLocationsStore, loginStore, weeksStore, menuStore, visibleMap, matches } = props;

  const { account } = loginStore;
  const {
    weeks,
    loading,
    bounds,
    isSessionExpired,
    selectedWeeks,
    mapWeeks,
    selectedCompareWeeks,
  } = weeksStore;
  const { location } = weeksLocationsStore;

  const updateBounds = (bounds: IBounds): void => {
    props.setBounds(bounds);
    props.getWeeks(SearchTypeEnum.MapSearch);
  };

  const hasFooterMenu = menuStore?.items?.footer?.length > 0;

  return (
    <div
      className={`condo-result-wrapper__map-content ${account?.forceIframe ? 'force-iframe' : ''} ${visibleMap ? 'mobile-show' : ''} ${!hasFooterMenu ? 'no-footer' : ''}`}
      style={{ backgroundImage: `url(${CondoMapPlaceholder})` }}
    >
      {location &&
      weeks &&
      !loading &&
      bounds &&
      mapWeeks &&
      (visibleMap || matches) ? (
        <WeeksMapWrapper
          selectWeek={props.selectWeek}
          selectedWeek={selectedWeeks}
          disabled={isSessionExpired}
          locations={mapWeeks.map(({ propertyId, location, pricePerNight, matchType }) => ({
            id: propertyId,
            location,
            pricePerNight,
            isExact: matchType === CondoMatchTypeEnum.Exact,
            isDisabled:
              matchType === CondoMatchTypeEnum.Exact && !pricePerNight,
          }))}
          bounds={bounds}
          weeks={mapWeeks}
          setBounds={updateBounds}
          mapOptions={{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            zoomControlOptions: {
              position: google.maps.ControlPosition.LEFT_TOP,
            },
          }}
          hasCompareSelected={selectedCompareWeeks?.length > 0}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    weeksStore: state.weeksStore,
    weeksLocationsStore: state.weeksLocationsStore,

    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,    
  };
};

const mapDispatchToProps:IMapDispatchToProps = {
  setBounds: weeksActions.setBounds,
  addMapWeek: weeksActions.addMapWeek,

  getWeeks: GetWeeks,
};

export const WeeksResultMap = connect(mapStateToProps, mapDispatchToProps)(withRouter(WeeksResultMapComponent));
