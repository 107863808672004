import React, { useEffect, useMemo, useState } from 'react';
import Highlighter from 'react-highlight-words';
import debounce from 'lodash/debounce';

import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { Select, Spin, AutoComplete } from 'antd';
import { SelectValue, RefSelectProps } from 'antd/lib/select';
import { OptionGroupData } from 'rc-select/lib/interface';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import { ILoginState, IWeeksLocationsState, weeksLocationsActions, getWeeksLocations } from '@share/store/slices';
import { RootState } from '@share/utils';
import { WEEKS_RECENT_SEARCHES_LABEL, ENTER_KEY } from '@share/constants';
import { ICondoLocation, CondoLocationsEnum } from '@share/common-types';

import { ClearSvg, LocationGreySvg } from '@share/assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IMapStateToProps {
  weeksLocationsStore: IWeeksLocationsState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getLocations: (search: string, selectedLocation: ICondoLocation | undefined, onlyCities: boolean) => void;
  setLocations: (locations: ICondoLocation[]) => void;
  setLoading: () => void;
  clearError: () => void;

  setSelectLocation: (location: ICondoLocation) => void;
  setSelectLocationLabel: (locationLabel: string) => void;
}

interface IProps extends IMapDispatchToProps, IMapStateToProps {
  display: boolean;
  isAfterSearchLoad: boolean;

  isExperiences?: boolean;
  disable?: boolean;
}

const zero = 0;
const MIN_SEARCH_LENGTH = 3;
const LIST_HEIGHT = 500;
const SEARCH_DEBOUNCE = 500;

const { Option, OptGroup } = Select;

function WeeksLocationPickerComponent(props: IProps) {
  const selectRef: React.RefObject<RefSelectProps> = React.createRef();
  const wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  const [isFocused, setIsFocused] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  
  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [isDropdownOpen]);

  let lastSearch = '';
  let lastSelectedLocation: ICondoLocation;

  const { isAfterSearchLoad, loginStore, disable, display, weeksLocationsStore, isExperiences, setSelectLocation, setSelectLocationLabel, setLocations, clearError, getLocations, setLoading } = props;
  const { loading, locations, selectedLocationLabel, selectedLocation } = weeksLocationsStore;

  const isNothingFound = loading || !!locations.length || selectedLocationLabel?.length < MIN_SEARCH_LENGTH;

  const account = loginStore?.account;
  const color: any = account?.searchIconsColor;

  const styleColor = !isEmpty(color)? { color } : {};  

  const onFocus = () => {
    if (!isFocused) {
      onSearch(selectedLocationLabel || '');
    }
    setIsFocused(true);
  };

  const onChange = (value: SelectValue, option: OptionGroupData) => {
    const valueParsed = value as string;
    const l = locations.find(({ id }) => id === (option?.key as string));
    const location = !isEmpty(valueParsed) ? { ...l } : null;

    setSelectLocationLabel(valueParsed);
    setSelectLocation(location);

    setIsDropdownOpen(false);
    setIsFocused(true);

    setLocations([]);
    lastSelectedLocation = location;

    if (option?.key) {
      clearError();
    }
  };

  const debouncedSearch = debounce((search: string) => getLocations(search, selectedLocation, isExperiences), SEARCH_DEBOUNCE);

  const onSearch = (search: string) => {
    lastSearch = search;

    const recentSearchesStorage = localStorage.getItem(WEEKS_RECENT_SEARCHES_LABEL);
    const recentSearchesList = !isEmpty(recentSearchesStorage)? JSON.parse(recentSearchesStorage) : [];
    let recentSearchesLocations: any[] = [];
    if (recentSearchesList?.length) {
      const recentLocations = recentSearchesList
                                  .map((search: any) => ({ ...search.propertyRequest.location, realType: CondoLocationsEnum.Recents }))
                                  .filter((location: any, index: number, array: any[]) => array.findIndex(v => (v.id === location.id)) === index)
                                  .slice(0, 5);
      recentSearchesLocations = [...recentLocations];
    }

    setIsDropdownOpen(!!search || (search === '' && recentSearchesLocations?.length > 0));

    if (search.length >= MIN_SEARCH_LENGTH) {
      setLoading();
      debouncedSearch(search);
    } else {
      setLocations([...recentSearchesLocations]);
    }
  };

  const focusSelect = () => {
    if (selectRef?.current) {
      selectRef.current.focus();
    }
  };

  const getGroupedLocation = (): {
    destinations: ICondoLocation[];
    recents: ICondoLocation[];
  } => {
    const destinations: ICondoLocation[] = [];
    const recents: ICondoLocation[] = [];
  
    locations.forEach((location: ICondoLocation) => {
      switch (location.realType) {
        case CondoLocationsEnum.GenericRegion: {
          destinations.push(location);
          break;
        }
        case CondoLocationsEnum.Recents: {
          if (!isExperiences || [CondoLocationsEnum.Recents].includes(location.realType)) {
            recents.push({ ...location });
          }
          break;
        }
        default:
          return null;
      }
    });

    return {
      destinations,
      recents
    };
  };

  const { destinations, recents } = useMemo(() => getGroupedLocation(), [locations]);

  const getOptionGroup = (
    array: ICondoLocation[],
    label: string,
    icon: React.ReactNode,
  ): React.ReactNode | null => {
    return array.length ? (
      <OptGroup label={<FormattedMessage id={label} />}>
        {array.map((location: ICondoLocation) => {
          const inner = (
            <>
              <span className="locations-dropdown__option-icon">{icon}</span>
              <span className="locations-dropdown__location-name">
                <Highlighter
                  highlightClassName="bold-text"
                  searchWords={[lastSearch.trim()]}
                  autoEscape={true}
                  textToHighlight={location.name}
                />
              </span>
            </>
          );

          return location.locationType === CondoLocationsEnum.GenericCondo ? (
            <Option
              key={location.id}
              value={location.name}
              className="locations-dropdown__option hotel"
            >
              <div className="locations-dropdown__option-wrapper">{inner}</div>
            </Option>
          ) : (
            <Option key={location.id} value={location.name} className="locations-dropdown__option">
              {inner}
            </Option>
          );
        })}
      </OptGroup>
    ) : null;
  };

  const getDropdown = (menu: React.ReactElement): React.ReactElement => {
    return (
      <div className="locations-dropdown">
        {menu}
        {isNothingFound && (
          <div className="locations-dropdown__footer">
            <FormattedMessage id="search.more.label" />
          </div>
        )}
      </div>
    );
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (isDropdownOpen) {
      if (
        (wrapperRef?.current && !wrapperRef.current?.contains(event.target as Node)) ||
        (event.target as HTMLElement).classList.contains(`locations-picker__fixed-bg`)
      ) {
        setIsDropdownOpen(false);
        setIsFocused(false);
    
        setLocations([]);
        lastSearch = '';
      }
    } else {
      setIsFocused(false);
    }
  };

  const getPopupContainer = () => {
    return wrapperRef ? wrapperRef.current : document.body;
  };

  const getNotFoundContent = (): React.ReactNode => {
    let message: React.ReactNode;
    if (selectedLocationLabel?.length < MIN_SEARCH_LENGTH) {
      message = <FormattedMessage id="search.min.symbols" />;
    } else if (loading) {
      message = <FormattedMessage id="searching" />;
    } else {
      message = <FormattedMessage id="nothing.found" />;
    }

    return (
      <div className="locations-dropdown__nothing-found">
        {message}
      </div>);
  };

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    if (
      selectedLocationLabel &&
      isFocused &&
      !isDropdownOpen &&
      event.target &&
      (event.target as HTMLElement).classList.contains('ant-select-selection-search-input')
    ) {
      onSearch(selectedLocationLabel);
    } else if (
      (event.target as HTMLElement).classList.contains('ant-select-item-option-selected') ||
      (event.target as HTMLElement).closest('.ant-select-item-option-selected')
    ) {
      if (lastSelectedLocation) {
        setIsDropdownOpen(false);
        setIsFocused(true);

        setLocations([]);
      } else {
        const location = locations.find(
          ({ name }) => name.toLowerCase() === selectedLocationLabel.toLowerCase(),
        );

        if (location) {
          setIsDropdownOpen(false);
          setIsFocused(true);

          setSelectLocationLabel(location.name);
          setSelectLocation(location);
          setLocations([]);
          lastSelectedLocation = location;
          clearError();
        }
      }
    }
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (
      e.code === ENTER_KEY &&
      isDropdownOpen &&
      isFocused &&
      locations.length &&
      !lastSelectedLocation
    ) {
      const item = locations[zero];

      setIsDropdownOpen(false);
      setIsFocused(true);

      setSelectLocationLabel(item.name);
      setSelectLocation(item);
      setLocations([]);
      lastSelectedLocation = item;

      if (item) {
        clearError();
      }
    }
  };
  
  if (!display) {
    return null;
  }

  return (
    <div className={`locations-picker weeks-location-container ${isExperiences ? 'experiences' : ''}`}>
      <div className={`locations-picker__container`} ref={wrapperRef}>
        <div
          className={`locations-picker__wrapper ${isAfterSearchLoad ? 'after-search' : ''} ${isFocused ? 'selected' : ''}`}
          onClick={focusSelect}
        >
          {!isAfterSearchLoad ? (
            <div className="locations-picker__left">
              <div className="locations-picker__icon" style={styleColor}>
                <FontAwesomeIcon icon={faLocationDot} />
              </div>
            </div>) : null}

          <div className={`locations-picker__right ${isFocused ? 'location-focused' : ''}`}>
            <div className="locations-picker__label">
              <FormattedMessage id={'location'} />
            </div>
            <div className="locations-picker__input">
              <AutoComplete
                onKeyDown={onKeyDown}
                virtual={false}
                showSearch={true}
                placeholder={<FormattedMessage id={'location.input.placeholder'} />}
                onChange={onChange}
                onSearch={onSearch}
                onFocus={onFocus}
                showArrow={false}
                ref={selectRef}
                open={isDropdownOpen}
                defaultActiveFirstOption={false}
                filterOption={() => true}
                autoClearSearchValue={false}
                dropdownRender={getDropdown}
                getPopupContainer={getPopupContainer}
                listHeight={LIST_HEIGHT}
                allowClear={true}
                value={selectedLocationLabel}
                notFoundContent={getNotFoundContent()}
                onClick={onClick}
                disabled={disable}
                clearIcon={
                  <span className="locations-picker__clear">
                    {loading && isDropdownOpen ? <Spin /> : <ClearSvg />}
                  </span>
                }
              >

                {selectedLocationLabel?.length < MIN_SEARCH_LENGTH ? (
                  <Option key={MIN_SEARCH_LENGTH} value={MIN_SEARCH_LENGTH} disabled={true} className="locations-dropdown__option">
                    <FormattedMessage id="search.min.symbols" />
                  </Option>) : null}
                  
                {getOptionGroup(destinations, 'destinations', <LocationGreySvg />)}
                {getOptionGroup(recents, 'recents', <LocationGreySvg />)}

              </AutoComplete>
            </div>
          </div>
        </div>

        {isDropdownOpen ? <div className={`locations-picker__fixed-bg`} /> : null}
      </div>
    </div>);
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    weeksLocationsStore: state.weeksLocationsStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  getLocations: (search: string, selectedLocation: ICondoLocation | undefined, onlyCities: boolean) => dispatch(getWeeksLocations(search, selectedLocation, onlyCities)),
  setLocations: (locations: ICondoLocation[]) => dispatch(weeksLocationsActions.setLocations(locations)),
  setLoading: () => dispatch(weeksLocationsActions.setLoading(true)),
  clearError: () => dispatch(weeksLocationsActions.setError('')),

  setSelectLocation: (location: ICondoLocation) => dispatch(weeksLocationsActions.setSelectLocation(location)),
  setSelectLocationLabel: (locationLabel: string) => dispatch(weeksLocationsActions.setSelectLocationLabel(locationLabel)),
});

export const WeeksLocationPicker = connect(mapStateToProps, mapDispatchToProps)(WeeksLocationPickerComponent);
