import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@share/utils';
import { getHeaders, axiosInstance } from '@share/utils';
import { Urls } from '@share/constants';
import { ICondoPopularDestinations } from '@common-types';

export interface ICondoPopularDestinationsState {
  condoPopularDestinations: ICondoPopularDestinations;
  loading: boolean;
  error: string;
}

const initialState: ICondoPopularDestinationsState = {
  condoPopularDestinations: null,
  loading: false,
  error: '',
};

const condoPopularDestinationsSlice = createSlice({
  name: 'condoPopularDestinations',
  initialState,
  reducers: {
    setLoading: (state: ICondoPopularDestinationsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: ICondoPopularDestinationsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setCondoPopularDestinations: (
      state: ICondoPopularDestinationsState,
      { payload }: PayloadAction<ICondoPopularDestinations>,
    ) => {
      state.condoPopularDestinations = payload;
    },
  },
});

export const condoPopularDestinationsActions = condoPopularDestinationsSlice.actions;
export const condoPopularDestinationsReducer = condoPopularDestinationsSlice.reducer;

export const getCondoPopularDestinations = (): AppThunk => {
  return async (dispatch) => {
    dispatch(condoPopularDestinationsActions.setLoading(true));

    try {
      const res = await axiosInstance.get(Urls.CondosLocationsPopularDestinations, {
        ...getHeaders(),
      });

      dispatch(condoPopularDestinationsActions.setLoading(false));
      dispatch(condoPopularDestinationsActions.setCondoPopularDestinations(res.data));
    } catch (error) {
      dispatch(condoPopularDestinationsActions.setError(error.toString()));
      dispatch(condoPopularDestinationsActions.setLoading(false));
    }
  };
};
