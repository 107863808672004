import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IHotelDetails, IRoomsContent } from '@common-types';
import { formatDateCheckInOut, IsMinDate } from '@share/utils';
import { DATE_FORMAT_FOR_BOOKINGS } from '@constants';
import { HomeClockSvg, BedSmallSvg, BathSvg, SquareFootageSvg, KitchenSizeSvg, CoffeeMakerSvg } from '@assets';
import './style.scss';

interface IProps {
  hotel: IHotelDetails;
  room: IRoomsContent;
}

class EntireHomesComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { hotel, room } = this.props;
    const values = ['Yes', 'No', 'Partial']

    return (room.nbrOfBedrooms || room.nbrOfBathrooms || hotel.whenBuild || room.squareFootage || room.kitchenInfo?.length
      ?
        <div className="entire-home">
          <h4 className="entire-home__title secondary-font"><FormattedMessage id="tab.entire.home" /></h4>

          <div className="row col-12">
            {
              room.nbrOfBedrooms &&
              <div className='col-4 pb-2'><span style={{ verticalAlign: 'bottom', marginRight: '8px', marginLeft: '2px' }}><BedSmallSvg size='25' /></span>
                {<>{room.nbrOfBedrooms} <FormattedMessage id="bedrooms" /></>}
              </div>
            }

            {
              room.nbrOfBathrooms &&
              <div className='col-4 pb-2'><span style={{ verticalAlign: 'text-bottom', marginRight: '8px', marginLeft: '2px' }}><BathSvg size='25' /></span>
                {<>{room.nbrOfBathrooms} <FormattedMessage id="bathrooms" /></>}
              </div>
            }

            {
              !IsMinDate(hotel.whenBuild) &&
              <div className='col-4 pb-2'><span style={{ verticalAlign: 'text-bottom', marginRight: '8px', marginLeft: '2px' }}><HomeClockSvg size='25' /></span>
                {<><FormattedMessage id="year.built" />: {hotel.whenBuild ? formatDateCheckInOut(hotel.whenBuild.toString(), DATE_FORMAT_FOR_BOOKINGS) : ''}</>}
              </div>
            }

            {
              room.squareFootage &&
              <div className='col-4 pb-2'><span style={{ verticalAlign: 'text-bottom', marginRight: '8px' }}><SquareFootageSvg size='25' /></span>
                {<>{room.squareFootage} <FormattedMessage id="feet" /><sup>2</sup></>}
              </div>
            }

            {
              room.kitchenInfo?.length &&
              room.kitchenInfo.map((x, i) => {
                if (x.name === 'Coffee Maker' && values.some(val => x.details.includes(val))) {
                  return <div className='col-4 pb-2' key={i}><span style={{ marginRight: '8px' }}><CoffeeMakerSvg size='30' /></span>
                    <FormattedMessage id="coffee.maker" />: <FormattedMessage id={x.details[0].toLowerCase()} /></div>
                }
                if (x.name === 'Kitchen size' && values.some(val => x.details.includes(val))) {
                  return <div className='col-4 pb-2' key={i}><span style={{ marginRight: '8px' }}><KitchenSizeSvg size='30' /></span>
                    <FormattedMessage id="kitchen.size" />: <FormattedMessage id={x.details[0].toLowerCase()} /></div>
                }
              })
            }
          </div>
        </div>
      : null
    );
  }
}

export const EntireHome = EntireHomesComponent;