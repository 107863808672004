
import moment from 'moment';
import axios, { Canceler } from 'axios';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import { IBounds, ICar, ICarsCounters, ICarsRecentSearches, IClientCash, ISessionKey } from '@share/common-types';
import { DEFAULT_PAGE_NUMBER, DEFAULT_MAP_LIMIT, CAR_RECENT_SEARCHES_LABEL, Urls, DEFAULT_PAGE_SIZE } from '@share/constants';
import {
  AppThunk,
  axiosInstance,
  GetCarsFilters,
  getHeaders,
  GetNumber,
  getSelectedCurrency,
  hasChangedCurrency,
} from '@share/utils';
import { getTimeout } from '@share/utils';
import { carsDatesActions, carsDriverActions, carsFiltersActions, carsLocationsActions, getUserWallet } from '@share/store/slices';

import { condoDetailsActions } from './condo-details';

import { SearchTypeEnum } from '@share/common-types';

const zero = 0;

export interface ICarsState {
  isSearch: boolean;
  isWidget: boolean;
  isCollapse: boolean;
  cars: ICar[];
  searchCodes: string[];
  prevCars: ICar[];
  mapCars: ICar[];
  selectedCompareCars: ICar[];
  selectedCar: ICar;

  counters: ICarsCounters;

  loading: boolean;
  loadingMore: boolean;

  error: string;
  pageNumber: number;
  sessionKey: ISessionKey;
  isSessionExpired: boolean;
  isMapView: boolean;
  isMapLoading: boolean;
  bounds: IBounds;

  selectedCarsSearchClientCash: IClientCash;
}

const initialState: ICarsState = {
  isSearch: false,
  isWidget: false,
  isCollapse: false,
  cars: null,
  searchCodes: null,
  prevCars: null,
  mapCars: null,
  selectedCompareCars: [],
  loading: false,
  loadingMore: false,
  error: '',
  pageNumber: DEFAULT_PAGE_NUMBER,
  counters: null,
  sessionKey: null,
  isSessionExpired: false,
  bounds: null,

  selectedCar: null,
  isMapView: false,
  isMapLoading: false,
  selectedCarsSearchClientCash: null,
};

const carsSlice = createSlice({
  name: 'carsSearch',
  initialState,
  reducers: {
    setIsSearch: (state: ICarsState, { payload }: PayloadAction<boolean>) => {
      state.isSearch = payload;
    },
    setIsCollapse: (state: ICarsState, { payload }: PayloadAction<boolean>) => {
      state.isCollapse = payload;
    },
    setLoading: (state: ICarsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setLoadingMore: (state: ICarsState, { payload }: PayloadAction<boolean>) => {
      state.loadingMore = payload;
    },
    setError: (state: ICarsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setPageNumber: (state: ICarsState, { payload }: PayloadAction<number>) => {
      state.pageNumber = payload;
    },
    setCounters: (state: ICarsState, { payload }: PayloadAction<ICarsCounters>) => {
      state.counters = payload;
    },
    setSearchCodes: (state: ICarsState, { payload }: PayloadAction<string[]>) => {
      state.searchCodes = payload;
    },
    setSelectedCarsSearchClientCash: (state: ICarsState, { payload }: PayloadAction<IClientCash>) => {
      state.selectedCarsSearchClientCash = payload;
    },
    setSelectedCompareCars: (state: ICarsState, { payload }: PayloadAction<ICar[]>) => {
      state.selectedCompareCars = payload;
    },
    setCarsSessionKey: (state: ICarsState, { payload }: PayloadAction<ISessionKey>) => {
      state.sessionKey = payload;
    },
    setIsSessionExpired: (state: ICarsState, { payload }: PayloadAction<boolean>) => {
      state.isSessionExpired = payload;
    },
    setCars: (state: ICarsState, { payload }: PayloadAction<ICar[]>) => {
      state.cars = payload;
    },
    setPrevCars: (state: ICarsState, { payload }: PayloadAction<ICar[]>) => {
      state.prevCars = payload;
    },
    setBounds: (state: ICarsState, { payload }: PayloadAction<IBounds>) => {
      state.bounds = payload;
    },
    setIsWidget: (state: ICarsState, { payload }: PayloadAction<boolean>) => {
      state.isWidget = payload;
    },
    setIsMapView: (state: ICarsState, { payload }: PayloadAction<boolean>) => {
      state.isMapView = payload;
    },
    setMapLoading: (state: ICarsState, { payload }: PayloadAction<boolean>) => {
      state.isMapLoading = payload;
    },
    addCars: (state: ICarsState, { payload }: PayloadAction<ICar[]>) => {
      state.cars = [...(state.cars || []), ...payload];
    },
    setSelectedCar: (state: ICarsState, { payload }: PayloadAction<ICar>) => {
      state.selectedCar = payload;
    },
    setMapCars: (state: ICarsState, { payload }: PayloadAction<ICar[]>) => {
      state.mapCars = payload;
    },
    addMapCar: (state: ICarsState, { payload }: PayloadAction<ICar>) => {
      state.mapCars.push(payload);
    },
    addMapCars: (state: ICarsState, { payload }: PayloadAction<ICar[]>) => {
      state.mapCars = [...(state.mapCars || []), ...payload];
    },
    setDefaultValues: () => {
      return initialState;
    },
    resetCars: (state: ICarsState) => {
      state.prevCars = [];
      state.cars = [];
      state.searchCodes = null;
      state.selectedCar = null;
      state.error = '';
      state.pageNumber = DEFAULT_PAGE_NUMBER;
    },
  },
});

export const carsActions = carsSlice.actions;

export const carsReducer = carsSlice.reducer;

let cancelRequest: Canceler;
let expirationTimer: number;

export const GetCars = (searchType: SearchTypeEnum): AppThunk => {
  return async (dispatch) => {
    if (searchType === SearchTypeEnum.Pagination) {
      dispatch(carsActions.setLoadingMore(true));
    } else {
      dispatch(carsActions.setLoading(true));
    }

    await dispatch(ApplyData(searchType));

    dispatch(GetCarsBase(searchType));
  }
}

export const GetCarsInitial = (searchType: SearchTypeEnum, refreshWallet?: boolean): AppThunk => {
  return async (dispatch, getState) => {
    if (refreshWallet) {
      const { loginStore } = getState();
      const { user } = loginStore;

      dispatch(getUserWallet(user));
    }

    dispatch(GetCarsBase(searchType));
  }
}

const ApplyData = (searchType?: SearchTypeEnum): AppThunk => {
  return async (dispatch) => {
    dispatch(carsLocationsActions.applyLocation());
    dispatch(carsDatesActions.applyCarsDates());
    dispatch(carsDriverActions.applyCarsDriver());


    if (searchType === SearchTypeEnum.NewSearch) {
      dispatch(carsFiltersActions.applyCarsFiltersQuicks());
    }
  }
}

export const RefreshSearch = (): AppThunk => {
  return async (dispatch) => {
    dispatch(GetCarsInitial(SearchTypeEnum.NewSearch));
  }
}

const GetCarsBase = (searchType: SearchTypeEnum): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const { carsStore, carsLocationsStore, carsDatesStore, carsDriverStore, carsFiltersStore, loginStore } = getState();

      const { pickUp, dropOff } = carsLocationsStore;
      const { startDate, startDateTime, endDate, endDateTime } = carsDatesStore;
      const { driverAge, driverCountry } = carsDriverStore;
      const { pageNumber, sessionKey } = carsStore;
      const { sortBy } = carsFiltersStore;
      const { account, lifeStyle } = loginStore;

      const locationPickUp = pickUp?.location;
      const locationDropOff = dropOff?.location;
      
      dispatch(carsActions.setSelectedCar(null));
      dispatch(carsActions.setError(null));
      dispatch(carsActions.setSearchCodes(null));
      
      const number = searchType === SearchTypeEnum.Pagination ? pageNumber + 1 : DEFAULT_PAGE_NUMBER;
      if (searchType === SearchTypeEnum.Pagination) {
        dispatch(carsActions.setLoadingMore(true));
        dispatch(carsActions.setPageNumber(number));
      } else {
        dispatch(carsActions.setLoading(true));
        dispatch(carsActions.setBounds(null));    
        dispatch(carsActions.setCounters(null));    
        dispatch(carsActions.setMapCars([]));    
        dispatch(carsActions.setPrevCars([]));    
        dispatch(carsActions.setCars([]));
        dispatch(carsActions.setSelectedCompareCars([]));
        dispatch(carsActions.setPageNumber(DEFAULT_PAGE_NUMBER));
      }
      
      if (!carsStore.cars) {
        dispatch(carsActions.setCars([]));
      }

      if (cancelRequest) {
        cancelRequest();
      }

      const requestBody: any = {
        carRequest: {
          pickUpDate: startDate,
          pickUpTime: startDateTime,
          pickUpLocation: locationPickUp?.code,
          pickUpType: locationPickUp?.type,
          returnDate: endDate,
          returnTime: endDateTime,
          returnLocation: locationDropOff?.code,
          returnType: locationDropOff?.type,
          countryISO: driverCountry,
          driverAge: GetNumber(driverAge),
          quantity: 1
        },
        filter: GetCarsFilters(carsFiltersStore),
        page: {
          number,
          size: DEFAULT_PAGE_SIZE
        },
        currency: getSelectedCurrency(account),
        sortBy,
        lifeStyle
      };
      
      const isReuseSessionKey = hasChangedCurrency() ? false : [SearchTypeEnum.Pagination, SearchTypeEnum.SortsFilters].includes(searchType);

      if (sessionKey && isReuseSessionKey) {
        requestBody.sessionKey = { ...sessionKey };
      }
    
      dispatch(carsActions.setIsSearch(true)); 

      const { data } = await axiosInstance.post(
        Urls.CarsSearch,
        requestBody,
        {
          ...getHeaders(),
          cancelToken: new axios.CancelToken((canceler: Canceler) => cancelRequest = canceler),
        },
      );

      dispatch(carsActions.setLoading(false));

      if (data.sessionKey) {
        const sessionKey = { ...data.sessionKey } as ISessionKey;
        dispatch(carsActions.setCarsSessionKey(sessionKey));
        dispatch(carsActions.setIsSessionExpired(false));
        
        if (expirationTimer) {
          clearTimeout(expirationTimer);
        }
      
        // @ts-ignore
        expirationTimer = setTimeout(() => {
          dispatch(carsActions.setIsSessionExpired(true));
        }, getTimeout(sessionKey?.expireDate));
      }

      dispatch(carsActions.setLoadingMore(false));
      dispatch(carsActions.setPrevCars(data.results));

      if (searchType === SearchTypeEnum.Pagination) {
        dispatch(carsActions.addCars(data.results));
        dispatch(carsActions.addMapCars(data.results));
      } else {
        dispatch(carsActions.setCars(data.results.slice(zero, 50)));
        dispatch(carsActions.setMapCars(data.results.slice(zero, DEFAULT_MAP_LIMIT)));
      }
      
      dispatch(carsActions.setSearchCodes(data?.errors));

      if (data.counters) {
        dispatch(carsActions.setCounters(data.counters));
      } else {
        dispatch(carsActions.setCounters(null));
      }

      if (searchType === SearchTypeEnum.NewSearch || searchType === SearchTypeEnum.SortsFilters) {
        if (data.bounds) {
          dispatch(carsActions.setBounds(data.bounds));
        } else {
          dispatch(carsActions.setBounds(null)); 
        }
      }
      
      dispatch(condoDetailsActions.setCondoDetails(null));

      // Adding the search to the storage
      const recentSearchesStorage = localStorage.getItem(CAR_RECENT_SEARCHES_LABEL);
      const recentSearchesObject = !isEmpty(recentSearchesStorage)? JSON.parse(recentSearchesStorage) : [];
      const recentSearchesFiltered = recentSearchesObject?.length ?
        recentSearchesObject.filter((search: ICarsRecentSearches) => {
          if (search?.carRequest?.pickUpDate && search?.carRequest?.returnDate) {
            return moment().startOf('day').isSameOrBefore(moment(search?.carRequest?.pickUpDate, 'yyyy-MM-DD'));
          }
          return true;
        }) : [];
      const recentSearchesValid = recentSearchesFiltered.length > 10 ? recentSearchesFiltered.slice(0,8) : recentSearchesFiltered;
      const currentSearchFinal = { ...requestBody, pickUpLocation: locationPickUp, dropOffLocation: locationDropOff };
      const currentSearchStr = JSON.stringify(currentSearchFinal);
      if (!recentSearchesValid.map((r: any) => JSON.stringify(r)).includes(currentSearchStr)) {
        localStorage.setItem(CAR_RECENT_SEARCHES_LABEL, JSON.stringify([currentSearchFinal, ...recentSearchesValid]));
      }
    } catch (error) {
      console.error(error);

      if (!(error instanceof axios.Cancel)) {
        const code = error?.response?.data?.code?.toString();
        
        const codeToSent = ['invalid_leadtime'];
        dispatch(carsActions.setError(codeToSent.includes(code) ? code : 'cars.search.error'));
        dispatch(carsActions.setLoading(false));
        dispatch(carsActions.setLoadingMore(false));
      }
    }
  };
};

export const ResetCarsFull = (): AppThunk => {
  return async (dispatch) => {
    dispatch(carsDatesActions.resetCarsDates());
    dispatch(carsLocationsActions.resetCarsLocations());
    dispatch(carsDriverActions.resetCarsDriver());

    dispatch(carsFiltersActions.resetFilters());

    dispatch(carsActions.setIsSearch(false));
    dispatch(carsActions.setError(''));    
    dispatch(carsActions.setBounds(null));    
    dispatch(carsActions.setCounters(null));    
    dispatch(carsActions.setCars(null));    
    dispatch(carsActions.setMapCars(null));    
    dispatch(carsActions.setPrevCars(null));    
    dispatch(carsActions.setPageNumber(DEFAULT_PAGE_NUMBER));  
    dispatch(carsActions.setIsCollapse(false));
  };
};

export const ResetCarsFullWithParams = (): AppThunk => {
  return async (dispatch) => {
    dispatch(ResetCarsFull());
    dispatch(ApplyData());
  };
};
