import React, { useEffect, useRef } from 'react';

import { FunctionalRootState, scrollTop } from '@share/utils';

import './style.scss';
import ArrowDownBanner from '@assets/images/mlm-levalti/new-agents/arrow-down-banner.svg';
import CertifiedSpecialist from '@assets/images/mlm-levalti/training/certified-specialist.png';
import CruiseImg from '@assets/images/mlm-levalti/training/image-asset.jpg';
import SpecialityLogoImg from '@assets/images/mlm-levalti/training/speciality-travel-brand-logos.png';
import WorkingImg from '@assets/images/mlm-levalti/training/working-from-home.png';
import AgentDeskImg from '@assets/images/mlm-levalti/training/home-based-travel-agent-at-desk.png';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ILoginState } from '@share/store/slices';

export default function MLMTraining() {
  useEffect(() => {
    scrollTop();
  }, []);

  const startedSectionRef = useRef<HTMLDivElement>(null);
  //Smooth Scroll Click
  const scrollToSection = () => {
    if (startedSectionRef.current) {
      startedSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const { account }: ILoginState = useSelector((state: FunctionalRootState) => state.loginStore);

  return (
    <div className="training">
      {/*Header*/}
      <header className="training__header">
        <section className="training__header-title">
          <h1 className="training__header-title-main">
            <span className="training__header-title-main-subtitle1">
              <FormattedMessage id="training.header.title" />
            </span>{' '}
            <span className="training__header-title-main-subtitle2">
              <FormattedMessage id="training.header.subtitle" />
            </span>
          </h1>
          <img
            src={CertifiedSpecialist}
            alt="Certified Header Img"
            className="training__header-title-main-img"
          />
        </section>
        <section className="training__header-arrow">
          <img
            src={ArrowDownBanner}
            alt="arrow down"
            onClick={scrollToSection}
            className="move-down-on-hover"
          />
        </section>
      </header>
      <section className="training__main-section" ref={startedSectionRef}>
        {/*First Section*/}
        <section className="training__main-section-agent">
          <section className="training__main-section-agent-one">
            <h2>
              <FormattedMessage id="training.agent.title" />
            </h2>
            <p>
              <FormattedMessage id="training.agent.paragraph" />
            </p>
          </section>
          <section className="training__main-section-agent-two">
            <section className="training__main-section-agent-two-img">
              <img src={CruiseImg} alt="Cruise Img" />
            </section>
            <section className="training__main-section-agent-two-content">
              <section className="training__main-section-agent-two-content-text">
                <h2 className="training__main-section-agent-two-content-text-title">
                  <span>
                    <FormattedMessage id="training.agent.content.title" />
                  </span>{' '}
                  <span className="training__main-section-agent-two-content-text-title-bold">
                    <FormattedMessage id="training.agent.content.subtitle" />
                  </span>
                </h2>
                <p>
                  <FormattedMessage id="training.agent.content.paragraph" />
                </p>
              </section>
              <section className="training__main-section-agent-two-content-img">
                <img src={SpecialityLogoImg} alt="Speciality Logo Img" />
              </section>
            </section>
          </section>
        </section>
        {/*Second Section*/}
        <section className="training__main-section-training">
          <section className="training__main-section-training-container">
            <section className="training__main-section-training-container-img">
              <img src={WorkingImg} alt="Working Img" />
            </section>
            <section className="training__main-section-training-container-content">
              <h2 className="training__main-section-training-container-content-title">
                <span className="training__main-section-training-container-content-title-bold">
                  <FormattedMessage id="training.training.title" />
                </span>{' '}
                <span>
                  <FormattedMessage id="training.training.subtitle" />
                </span>
              </h2>
              <p className="training__main-section-training-container-content-paragraph">
                <FormattedMessage id="training.training.paragraph" />
              </p>
              <p className="training__main-section-training-container-content-paragraph">
                <FormattedMessage id="training.training.paragraph2" />
              </p>
              <ul className="training__main-section-training-container-content-list">
                <li>
                  <FormattedMessage id="training.training.list" />
                </li>
                <li>
                  <FormattedMessage id="training.training.list2" />
                </li>
              </ul>
              <Link to={`/${account?.name}/new-agents`}>
                <button className="training__main-section-training-container-content-btn">
                  <FormattedMessage id="training.training.learnmore" />
                </button>
              </Link>
            </section>
          </section>
        </section>
        {/*Third Section*/}
        <section className="training__main-section-experienced">
          <section className="training__main-section-experienced-container">
            <section className="training__main-section-experienced-container-img">
              <img src={AgentDeskImg} alt="Agent Desk Img" />
            </section>
            <section className="training__main-section-experienced-container-content">
              <h2 className="training__main-section-experienced-container-content-title">
                <span className="training__main-section-experienced-container-content-title-bold">
                  <FormattedMessage id="training.experienced.title" />
                </span>{' '}
                <span>
                  <FormattedMessage id="training.experienced.subtitle" />
                </span>
              </h2>
              <p className="training__main-section-experienced-container-content-paragraph">
                <FormattedMessage id="training.experienced.paragraph" />
              </p>
              <p className="training__main-section-experienced-container-content-paragraph">
                <FormattedMessage id="training.experienced.paragraph2" />
              </p>
              <Link to={`/${account?.name}/experienced-agents`}>
                <button className="training__main-section-experienced-container-content-btn">
                  <FormattedMessage id="training.training.learnmore" />
                </button>
              </Link>
            </section>
          </section>
        </section>
      </section>
    </div>
  );
}
