import React from 'react';
import { Skeleton } from 'antd';
import './style.scss';

const NUMBER = 1;
const NUMBER_OF_BLOCKS = [NUMBER, NUMBER, NUMBER];

interface IProps {}

export class SkeletonFilters extends React.Component<IProps> {
  render(): React.ReactNode {
    const SpaceItem = NUMBER_OF_BLOCKS.map((item, index) => (
      <div key={`${index}-${item}`} className="skeleton-filters__block">
        <Skeleton active title paragraph={false} />
        <Skeleton active title paragraph={false} />
      </div>
    ));

    const SpaceItems = NUMBER_OF_BLOCKS.map((item, index) => (
      <div key={`${item}-${index}`} className="skeleton-filters__filters">
        <Skeleton active title paragraph={false} />
        {SpaceItem}
      </div>
    ));

    return (
      <div className="skeleton-filters">
        <Skeleton className="skeleton-filters__map" active title={false} paragraph={{ rows: 1 }} />
        {SpaceItems}
        <div className="skeleton-filters__mobile-block">
          <Skeleton active title paragraph={false} />
          <Skeleton active title paragraph={false} />
        </div>
      </div>
    );
  }
}
