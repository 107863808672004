import React from 'react';

import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { ICarLocationsState, carsLocationsActions, ILoginState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { ILocation } from '@share/common-types';

import { CarsLocationsAutoComplete } from '../cars-locations-autocomplete';

import './style.scss';

interface IMapStateToProps {
  carsLocationsStore: ICarLocationsState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setSelectPickUpLocation: (location: ILocation) => void;
  setSelectPickUpLocationLabel: (locationLabel: string) => void;

  setSelectDropOffLocation: (location: ILocation) => void;
  setSelectDropOffLocationLabel: (locationLabel: string) => void;
}

interface IProps extends IMapDispatchToProps, IMapStateToProps {
  isExperiences?: boolean;
  disable?: boolean;
}

function CarsLocationsPickerComponent(props: IProps) {

  const { isExperiences, disable, carsLocationsStore, setSelectPickUpLocation, setSelectPickUpLocationLabel, setSelectDropOffLocation, setSelectDropOffLocationLabel } = props;
  const { pickUp, dropOff } = carsLocationsStore;

  return (
    <div className={`cars-locations-picker ${isExperiences ? 'experiences' : ''}`}>
      <CarsLocationsAutoComplete
        location={pickUp}
        type="pickUp"
        title="cars.pickup.location"
        placeholder="location.input.placeholder"
        isExperiences={isExperiences}
        disable={disable}
        setSelectLocation={setSelectPickUpLocation}
        setSelectLocationLabel={setSelectPickUpLocationLabel}
        display
        displayError
      />

      <CarsLocationsAutoComplete
        location={dropOff}
        type="dropOff"
        title="cars.dropoff.location"
        placeholder="cars.dropoff.location.placeholder"
        isExperiences={isExperiences}
        disable={disable}
        setSelectLocation={setSelectDropOffLocation}
        setSelectLocationLabel={setSelectDropOffLocationLabel}
        display
      />
    </div>
  );

}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    carsLocationsStore: state.carsLocationsStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  setSelectPickUpLocation: (location: ILocation) => dispatch(carsLocationsActions.setSelectPickUpLocation(location)),
  setSelectPickUpLocationLabel: (locationLabel: string) => dispatch(carsLocationsActions.setSelectPickUpLocationLabel(locationLabel)),

  setSelectDropOffLocation: (location: ILocation) => dispatch(carsLocationsActions.setSelectDropOffLocation(location)),
  setSelectDropOffLocationLabel: (locationLabel: string) => dispatch(carsLocationsActions.setSelectDropOffLocationLabel(locationLabel)),
});

export const CarsLocationsPicker = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarsLocationsPickerComponent);
