import React from 'react';
import { FormattedMessage } from 'react-intl';
import { WhiteButton } from '@components';
import { BlueButton } from '@share/components';
import { RedConfirmationCircleSvg } from '@assets';
import './style.scss';

interface IProps {
  bookAnotherUnit?: () => void;
  searchCondos: () => void;
  isAlternativeUnitsAvailable: boolean;
}

export function CondoUnitNotAvailableNotification(props: IProps) {
  const { bookAnotherUnit, isAlternativeUnitsAvailable, searchCondos } = props;

  return (
    <div className="condo-unit-not-available">
      <div className="condo-unit-not-available__icon">
        <RedConfirmationCircleSvg />
      </div>
      <div className="condo-unit-not-available__header secondary-font">
        <FormattedMessage id="unit.is.no.longer.available" />
      </div>
      <div className="condo-unit-not-available__description">
        <div className="condo-unit-not-available__text">
          <FormattedMessage id="we.were.unable.to.confirm.your.booking" />
        </div>
      </div>
      <div className="condo-unit-not-available__footer">
        {isAlternativeUnitsAvailable && (
          <WhiteButton onClick={bookAnotherUnit}>
            <FormattedMessage id="book.another.unit" />
          </WhiteButton>
        )}
        <BlueButton onClick={searchCondos}>
          <FormattedMessage id="search.condos" />
        </BlueButton>
      </div>
    </div>
  );
}
