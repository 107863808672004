import React from 'react';
import moment from 'moment';

import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { isEmpty } from 'lodash';

import { DATE_FORMAT_FILTER } from '@share/constants';
import { IAccount, IWeeksRecentSearches } from '@share/common-types';

import { WeeksSearchHandlerArgs } from '../weeks-recent-searches-wrapper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCalendar, faLocation } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IProps extends WrappedComponentProps {
  data: IWeeksRecentSearches;
  disabled: boolean;
  account: IAccount;

  onSearchHandler: WeeksSearchHandlerArgs;
}

class WeeksRecentSearchesComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { data, disabled, account, onSearchHandler } = this.props;
    const { propertyRequest } = data;
    const { checkIn, dateRange, location } = propertyRequest;

    const formattedDate = checkIn && moment(checkIn, 'yyyy-MM-DD').format(DATE_FORMAT_FILTER);
  
    const color: any = account?.searchIconsColor;
    const generalBorderRadius: any = account?.generalBorderRadius ? account?.generalBorderRadius : null;

    const style = !isEmpty(generalBorderRadius) ? { borderRadius: generalBorderRadius } : {};  
    const styleColor = !isEmpty(color)? { color } : {};  

    return (
      <div
        role="button"
        className={`recent-searches-picker cars-recents-searches ${disabled ? 'disabled' : ''}`}
        style={style}
        onClick={() =>{
          if (!disabled) {
            onSearchHandler(location, checkIn, dateRange?.toString());
          }
        }}
      >
        <div className="recent-searches-picker__info" style={{ width: '20px', ...styleColor }}>
          <FontAwesomeIcon icon={faLocation} />
          <FontAwesomeIcon icon={faCalendar} />
        </div>

        <div className="recent-searches-picker__info">
          <p className="recent-searches-picker__title">{location.name}</p>
          <p className="recent-searches-picker__subtitle ttl"><span>{formattedDate}</span></p>

          <div className={`search-again secondary-font ${disabled ? 'disabled' : ''}`}>
            <FormattedMessage id="availability.status.modal.search_again" />
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </div>
      </div>
    );
  }
}

export const WeeksRecentSearchesItem = injectIntl(WeeksRecentSearchesComponent);
