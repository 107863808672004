import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Checkbox } from 'antd';
import { get } from 'lodash';
import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';
import { getKeyCancellationFromPath, RootState } from '@share/utils';

import { getCancelReservation, IReservationCancellationState } from '@share/store/slices';
import { RedButton } from '@components';

import { ReservationCancellationPolicies } from '../reservation-cancellation-policies';

import './style.scss';

interface IMapStateProps {
  reservationCancellationStore: IReservationCancellationState;
}

interface IMapDispatchToProps {
  getCancelReservation: (tempKey: string) => void;
}

interface IProps extends IMapStateProps, IMapDispatchToProps {}

interface IState {
  isAccept: boolean;
}

export class ReservationCancellationComponent extends React.Component<IProps, IState> {
  state: IState = {
    isAccept: false,
  };

  getCancelReservation = (): void => {    
    const tempKey = getKeyCancellationFromPath();

    this.props.getCancelReservation(tempKey);
  };

  onChangeAccept = (e: { target: CheckboxChangeEventTarget }): void => {
    this.setState({ isAccept: e.target.checked });
  };

  render(): React.ReactNode {
    const {
      reservationCancellationDetails,
      loadingCancelReservation,
    } = this.props.reservationCancellationStore;

    const orderId = get(reservationCancellationDetails.reservationDetails, 'orderId');
    const supportPhone = get(reservationCancellationDetails.reservationDetails, 'supportPhone');

    return (
      <div className="reservation-cancellation">
        <p className="reservation-cancellation__title-text">
          <FormattedMessage id="cancel.booking.number" />:{' '}
          <span className="reservation-cancellation__number">{orderId}</span>
        </p>
        <ReservationCancellationPolicies />
        <p className="reservation-cancellation__refund-text">
          <FormattedMessage id="refund.can.take.up.to" />
        </p>
        <p className="reservation-cancellation__contact-property">
          <FormattedMessage id="support" />:{' '}
          <span className="reservation-cancellation__contact-property-phone">{supportPhone}</span>
        </p>
        <div className="reservation-cancellation__footer">
          <div className="reservation-cancellation__footer-text">
            <Checkbox onChange={this.onChangeAccept} />
            <div className="reservation-cancellation__wrapper-accept-text">
              <span className="reservation-cancellation__accept-text">
                <FormattedMessage id="by.clicking.this.box.you.acknowledge" />
              </span>
              <span className="reservation-cancellation__accept-text bold">
                <FormattedMessage id="cancellation.policy.booking.remarks" />
              </span>
            </div>
          </div>
          <div className="reservation-cancellation__btn-wrapper">
            <RedButton
              onClick={this.getCancelReservation}
              disabled={!this.state.isAccept}
              loading={loadingCancelReservation}
            >
              <FormattedMessage id="cancel.reservation" />
            </RedButton>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateProps => {
  return {
    reservationCancellationStore: state.reservationCancellationStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getCancelReservation,
};

export const ReservationCancellation = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReservationCancellationComponent);
