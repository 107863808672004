import { scrollTop } from '@share/utils';
import React, { Component } from 'react';

import { PromotionDetails } from '@components';

import './style.scss';

interface IProps {}

export class PromotionDetailsPage extends Component<IProps> {
  constructor(props: IProps) {
    super(props);

    scrollTop();
  }

  render(): React.ReactNode {
    return (
      <div className="promotion-details-page">
        <PromotionDetails />
      </div>
    );
  }
}
