import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { IVideoMedia } from '@api';
import {
  fetchMemberVideos,
  memberVideosActions,
  selectMemberVideosByFileName,
} from '@store/slices';
import { RootState } from '@share/utils';
import { MemberVideosCarousel, Video } from '@components';
import { scrollTop } from '@share/utils';

import MemberVideosBackground from '@assets/images/member-videos-background.png';

import './style.scss';

const headerStyle: React.CSSProperties = { backgroundImage: `url(${MemberVideosBackground})` };

interface IMapStateToProps {
  memberVideo: IVideoMedia;
}

interface IMapDispatchToProps {
  getMemberStories: () => void;
  setActiveVideo: (path: string) => void;
}

interface IParams {
  fileName: string;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps<IParams> {}

class MemberVideosPageComponent extends React.Component<IProps> {
  videoRef = React.createRef<HTMLVideoElement>();

  constructor(props: IProps) {
    super(props);
    this.setActiveVideo(props.location.search);
  }

  setActiveVideo = (search: string): void => {
    const fileName = new URLSearchParams(search).get('fileName');
    this.props.setActiveVideo(fileName as string);
    this.videoRef.current?.load();
    scrollTop();
  };

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const prevSearch = prevProps.location.search;
    const currentSearch = this.props.location.search;

    if (prevSearch !== currentSearch) {
      this.setActiveVideo(currentSearch);
    }
  }

  componentDidMount() {
    this.props.getMemberStories();
  }

  render() {
    const { memberVideo } = this.props;

    return (
      <div className="member-videos">
        <div className="member-videos__header-wrapper" style={headerStyle}>
          <div className="member-videos__container">
            <div className="member-videos__header-title secondary-font">
              <FormattedMessage id="member-videos.header" />
            </div>
          </div>
        </div>
        <div className="member-videos__content member-videos__container">
          <div className="member-videos__media">
            {memberVideo?.filePath && (
              <div className="member-videos__media_video-block member-videos__media_row">
                <Video videoRef={this.videoRef} src={memberVideo?.filePath} controls loop />
              </div>
            )}
            <div className="member-videos__content member-videos__media_row">
              <h4 className="member-videos__description_title secondary-font">{memberVideo?.title}</h4>

              <div className="member-videos__description_block">
                <p>{memberVideo?.description}</p>
              </div>
            </div>
          </div>
        </div>
        <MemberVideosCarousel />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    memberVideo: selectMemberVideosByFileName(state) as IVideoMedia,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  setActiveVideo: (path: string) => dispatch(memberVideosActions.setActiveVideoByFileName(path)),
  getMemberStories: () => dispatch(fetchMemberVideos()),
});

export const MemberVideosPage = connect(mapStateToProps, mapDispatchToProps)(withRouter(MemberVideosPageComponent));
