import React from 'react';

export const AirSvg: React.FunctionComponent = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.66671 3.66683C8.66671 2.38016 7.62004 1.3335 6.33337 1.3335C4.97737 1.3335 4.16671 2.35016 4.16671 3.3335H5.50004C5.50004 3.05683 5.75871 2.66683 6.33337 2.66683C6.88471 2.66683 7.33337 3.1155 7.33337 3.66683C7.33337 4.21816 6.88471 4.66683 6.33337 4.66683H1.33337V6.00016H6.33337C7.62004 6.00016 8.66671 4.9535 8.66671 3.66683ZM10.3334 10.0002H5.33337V11.3335H10.3334C10.8847 11.3335 11.3334 11.7822 11.3334 12.3335C11.3334 12.8848 10.8847 13.3335 10.3334 13.3335C9.75871 13.3335 9.50004 12.9435 9.50004 12.6668H8.16671C8.16671 13.6502 8.97737 14.6668 10.3334 14.6668C11.62 14.6668 12.6667 13.6202 12.6667 12.3335C12.6667 11.0468 11.62 10.0002 10.3334 10.0002Z"
      fill="#333333"
    />
    <path
      d="M12 3.3335C10.5294 3.3335 9.33337 4.5295 9.33337 6.00016H10.6667C10.6667 5.26483 11.2647 4.66683 12 4.66683C12.7354 4.66683 13.3334 5.26483 13.3334 6.00016C13.3334 6.7355 12.7354 7.3335 12 7.3335H1.33337V8.66683H12C13.4707 8.66683 14.6667 7.47083 14.6667 6.00016C14.6667 4.5295 13.4707 3.3335 12 3.3335ZM1.33337 10.0002H4.00004V11.3335H1.33337V10.0002Z"
      fill="#333333"
    />
  </svg>
);
