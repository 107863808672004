
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { getBenefitInfo, getLocalURI, isExternalUrl, RootState } from '@share/utils';
import { IBenefitsState, ILoginState, IMenuState } from '@share/store/slices';
import { BenefitsEnum } from '@share/common-types';
import { SkeletonBenefits, Benefit } from '@components';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
  benefitsStore: IBenefitsState;
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps {}

function BenefitsComponent(props: IProps) {
  const { benefitsStore, loginStore } = props;
  const { benefits, loading } = benefitsStore;
  const { account } = loginStore;

  let styles: any = account?.mainBorderColor ? { borderColor: account?.mainBorderColor } : {};
  styles = account?.mainBorderRadius ? { ...styles, borderRadius: `${account?.mainBorderRadius}px` } : { ...styles };

  return (
    <>
      {benefits && !loading ? (
        <div className="benefits">
          <p className="benefits__title secondary-font">
            <FormattedMessage id="our.products" />
          </p>
          <div className="benefits__wrapper">
            <div className="benefits__benefits-wrapper">
              {benefits.items.map((benefit) =>
                benefit.type !== BenefitsEnum.Getaways ? (
                  <Benefit key={benefit.type} benefit={benefit} />
                ) : null,
              )}
            </div>
            <div className="benefits__benefit-wrapper">
              {getBenefitInfo(benefits, BenefitsEnum.Getaways).map((benefit) =>
                isExternalUrl(benefit.url) ? (
                  <a
                    href={benefit.url}
                    className="benefits__benefit"
                    target="_blank"
                    rel="noreferrer"
                    key={benefit.type}
                    style={styles}
                  >
                    <div className="benefits__benefit-img-wrapper">
                      <img src={benefit.imageUrl} alt={benefit.type} />
                    </div>
                    <div className="benefits__benefit-text-wrapper">
                      <p className="benefits__benefit-title"><FormattedMessage id={benefit.title} /></p>
                      <p className="benefits__benefit-description"><FormattedMessage id={benefit.description} /></p>
                    </div>
                  </a>
                ) : (
                  <Link to={`/${account?.name}${getLocalURI(benefit.url)}`} className="benefits__benefit" key={benefit.type} style={styles}>
                    <div className="benefits__benefit-img-wrapper">
                      <img src={benefit.imageUrl} alt={benefit.type} />
                    </div>
                    <div className="benefits__benefit-text-wrapper">
                      <p className="benefits__benefit-title"><FormattedMessage id={benefit.title} /></p>
                      <p className="benefits__benefit-description"><FormattedMessage id={benefit.description} /></p>
                    </div>
                  </Link>
                ),
              )}
            </div>
          </div>
        </div>
      ) : null}
      {loading ? <SkeletonBenefits /> : null}
    </>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    benefitsStore: state.benefitsStore,
    menuStore: state.navigationMenuStore,
  };
};

export const Benefits = connect(mapStateToProps)(BenefitsComponent);
