import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Input, Button } from 'antd';
import { getCondoId, getAvailabilityId } from '@utils';
import { UrlUtils, formatMoney, formatMoneyCurrency, getSelectedCurrency } from '@share/utils';
import { RootState } from '@share/utils';
import {
  ICondoCouponState,
  getCondoRedeemCode,
  getCondoRemoveCode,
  setCondoCoupon,
  getCondoReviewBook,
  setErrorCondoText,
  setCondoRedeemCode,
  ICondoReviewBookState,
} from '@store/slices';
import { IRedeemCode } from '@common-types';
import { ISessionKey } from '@share/common-types';
import { CONDO_UNITS_SESSION_KEY_LABEL, CONDO_SESSION_KEY, QUOTE_LABEL } from '@share/constants';
import { BlueButton } from '@share/components';
import { ILoginState } from '@share/store/slices';

import './style.scss';

interface IMapStateToProps {
  condoReviewBookStore: ICondoReviewBookState;
  condoRedeemCodeStore: ICondoCouponState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setCondoCoupon: (coupon: string) => void;
  setErrorCondoText: (coupon: string) => void;
  setCondoRedeemCode: (coupon: IRedeemCode) => void;
  getCondoRedeemCode: (code: string, sessionKey: ISessionKey | undefined) => void;
  getCondoRemoveCode: (sessionKey: ISessionKey | undefined) => void;
  getCondoReviewBook: (
    condoId: number,
    availabilityId: string,
    sessionKey: ISessionKey | undefined,
    isGetCoupon: boolean,
    quote?: string
  ) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {}

interface IState {
  isShowCoupon: boolean;
}

const ZERO = 0;

class CondoDiscountCodeComponent extends React.Component<IProps, IState> {
  state: IState = {
    isShowCoupon: false,
  };

  getSession = (): ISessionKey => {
    const values = UrlUtils.getValues();
    const unitSession = values[CONDO_UNITS_SESSION_KEY_LABEL] as ISessionKey;
    const searchSession = values[CONDO_SESSION_KEY] as ISessionKey;

    return unitSession && unitSession.value ? unitSession : searchSession;
  };

  getQuote = (): string => {
    const values = UrlUtils.getValues();
    if (values[QUOTE_LABEL]) {
      return (values[QUOTE_LABEL] as string);
    }

    return null;
  }

  getCondoReviewBook = () => {
    this.props.getCondoReviewBook(getCondoId(), getAvailabilityId(), this.getSession(), true, this.getQuote());
  };

  onShowEnterCoupon = () => {
    this.setState({ isShowCoupon: true });
  };

  onChangeCoupon = (e: { target: HTMLInputElement }): void => {
    this.props.setCondoCoupon(e.target.value);
    this.props.setErrorCondoText('');
    this.props.setCondoRedeemCode(null);
  };

  onApplyCoupon = async () => {
    const { coupon } = this.props.condoRedeemCodeStore;

    await this.props.getCondoRedeemCode(coupon, this.getSession());

    if (
      this.props.condoRedeemCodeStore.isGetCoupon &&
      this.props.condoRedeemCodeStore.condoRedeemCode.success
    ) {
      this.getCondoReviewBook();
    }
  };

  onRemoveCoupon = async () => {
    this.props.setCondoCoupon('');
    await this.props.getCondoRemoveCode(this.getSession());
    this.getCondoReviewBook();
  };

  render(): React.ReactNode {
    const { isUpdatePrice, condoBookingSummary, bookingComplete, loadingBooking } = this.props.condoReviewBookStore;
    const { condoRedeemCode, coupon, errorText, isGetCoupon } = this.props.condoRedeemCodeStore;
    const { couponAmount } = condoBookingSummary.bookingCard.bookingPrice;
    const { isShowCoupon } = this.state;

    const currency = getSelectedCurrency(this.props.loginStore.account);

    return (
      <div className="discount-code">
        {bookingComplete && couponAmount !== ZERO ? (
          <>
            <div className="discount-code__info">
              <p className="discount-code__text">
                <FormattedMessage id="discount" />:
              </p>
              <p className="discount-code__text">-{formatMoneyCurrency(currency).format(couponAmount)}</p>
            </div>
            <div className="discount-code__note-wrapper">
              <p className="discount-code__note">
                <FormattedMessage id="promo-code" /> &quot;{coupon}&quot;
              </p>
            </div>
          </>
        ) : null}
        {isShowCoupon && !bookingComplete ? (
          <div className={`discount-code__wrapper ${isGetCoupon ? 'hide-form' : ''}`}>
            <p className="discount-code__label">
              <FormattedMessage id="title.promotion.code" />
            </p>
            <div className="discount-code__form">
              <Input
                className={`discount-code__input ${
                  errorText || (condoRedeemCode && !condoRedeemCode.success) ? 'error-coupon' : ''
                }`}
                placeholder="Enter code here"
                maxLength={10}
                onChange={this.onChangeCoupon}
                value={coupon}
                disabled={loadingBooking}
              />
              <BlueButton disabled={!coupon || loadingBooking} onClick={this.onApplyCoupon} loading={isUpdatePrice}>
                {!isUpdatePrice ? <FormattedMessage id="apply" /> : ''}
              </BlueButton>
            </div>
            {errorText ? (
              <p className="discount-code__error">{errorText}</p>
            ) : condoRedeemCode && !condoRedeemCode.success ? (
              <p className="discount-code__error">{condoRedeemCode.message}</p>
            ) : (
              ''
            )}
          </div>
        ) : !bookingComplete ? (
          <p
            className={`discount-code__title ${isGetCoupon ? 'hide-title' : ''}`}
            onClick={this.onShowEnterCoupon}
          >
            <FormattedMessage id="use.coupon" />
          </p>
        ) : null}
        {!isUpdatePrice && condoRedeemCode?.success && isGetCoupon && !bookingComplete ? (
          <>
            <div className="discount-code__info">
              <p className="discount-code__text">
                <FormattedMessage id="discount" />:
              </p>
              <p className="discount-code__text">-{formatMoneyCurrency(currency).format(couponAmount)}</p>
            </div>
            <div className="discount-code__note-wrapper">
              <p className="discount-code__note">
                <FormattedMessage id="promo-code" /> &quot;{coupon}&quot;
              </p>
              <Button
                type="link"
                className="discount-code__btn-remove"
                onClick={this.onRemoveCoupon}
              >
                <FormattedMessage id="remove" />
              </Button>
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condoReviewBookStore: state.condoReviewBookStore,
    condoRedeemCodeStore: state.condoRedeemCodeStore,
    loginStore: state.loginStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getCondoReviewBook,
  getCondoRedeemCode,
  getCondoRemoveCode,
  setCondoCoupon,
  setErrorCondoText,
  setCondoRedeemCode,
};

export const CondoDiscountCode = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CondoDiscountCodeComponent));
