import React from 'react';
import { Skeleton } from 'antd';
import './style.scss';

export interface IProps {}

export class RoomsSkeleton extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <div className="rooms-skeleton">
        <div className="rooms-skeleton__wrapper">
          <Skeleton className="rooms-skeleton__img" active paragraph={false} />
          <Skeleton className="rooms-skeleton__name" active paragraph={false} />
          <Skeleton className="rooms-skeleton__description" active paragraph={false} />
          <Skeleton className="rooms-skeleton__description" active paragraph={false} />
          <Skeleton className="rooms-skeleton__action" active paragraph={false} />
        </div>
      </div>
    );
  }
}
