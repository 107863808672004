import React, { Component } from 'react';

import { EliteRciHowTo } from '@components';

export class EliteRciHowToPage extends Component {
  render(): React.ReactNode {
    return (
      <div className="elite-rci-how-to-page">
        <EliteRciHowTo />
      </div>
    );
  }
}
