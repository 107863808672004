
import format from 'date-fns/format';
import addMonths from 'date-fns/addMonths';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';

import {
  ISessionKey,
  ITrustYouReview,
  IWeekDetails,
} from '@share/common-types';
import {
  Routes,
  SESSION_EXPIRED_STATUS,
  SESSION_SEARCH_EXPIRED_MESSAGE,
  Urls,
} from '@share/constants';
import { getDateCheckInOut, getSelectedCurrency } from '@share/utils';
import { getHeaders, axiosInstance } from '@share/utils';
import { AppThunk } from '@share/utils';
import { SERVER_ERROR_STATUSES } from '@share/constants';

import { events, getEndDateNextMonth } from '@share/utils';
import {
  ICondoCheckinStatuses,
  CustomEvents,
  BookingErrorsEnum
} from '@share/common-types';
import { trustYouReviewActions } from '@share/store/slices';

//import { trustYouReviewActions } from './trust-you-review';
//import { updateUnits, getUnitsDetails } from './condo-unit-search';

export interface IWeekDetailsState {
  week: IWeekDetails;
  loading: boolean;
  error: string;
  statuses: ICondoCheckinStatuses;
  isServerError: boolean;
  isCheckinStatusLoading: boolean;
  lastCheckinEndDate: string;
}

const initialState: IWeekDetailsState = {
  week: null,
  loading: true,
  error: '',
  statuses: null,
  isServerError: false,
  isCheckinStatusLoading: false,
  lastCheckinEndDate: '',
};

const ZERO = 0;
const one = 1;
const two = 2;
const emptyDatePartDays = 'T00:00:00.000Z';
const emptyDatePart = '-01T00:00:00.000Z';

const weekDetailsSlice = createSlice({
  name: 'week-details',
  initialState,
  reducers: {
    setLoading: (state: IWeekDetailsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setLastCheckinEndDate: (state: IWeekDetailsState, { payload }: PayloadAction<string>) => {
      state.lastCheckinEndDate = payload;

      setTimeout(() => {
        events.emit(CustomEvents.LastCheckinEndDate, payload);
      });
    },
    setError: (state: IWeekDetailsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setWeekDetails: (state: IWeekDetailsState, { payload }: PayloadAction<IWeekDetails>) => {
      state.week = payload;
    },
    setUpdateUnitsType: (state: IWeekDetailsState, { payload }: PayloadAction<IWeekDetails>) => {
      state.week = payload;
    },
    setIsCheckinStatusLoading: (state: IWeekDetailsState, { payload }: PayloadAction<boolean>) => {
      state.isCheckinStatusLoading = payload;
      events.emit(CustomEvents.CheckinStatusesLoading, payload);
    },
    setIsServerError: (state: IWeekDetailsState, { payload }: PayloadAction<boolean>) => {
      state.isServerError = payload;
    },
    setStatuses: (state: IWeekDetailsState, { payload }: PayloadAction<ICondoCheckinStatuses>) => {
      if (!payload) {
        state.statuses = payload;
      } else {
        if (!state.statuses) {
          state.statuses = payload;
        } else {
          const { availableDays, unavailableDays } = payload;

          Object.keys(availableDays).forEach((year) => {
            if (!state.statuses.availableDays[+year]) {
              state.statuses.availableDays[+year] = availableDays[+year];
            } else {
              Object.keys(availableDays[+year]).forEach((month) => {
                if (!state.statuses.availableDays[+year][+month]) {
                  state.statuses.availableDays[+year][+month] = availableDays[+year][+month];
                }
              });
            }
          });

          Object.keys(unavailableDays).forEach((year) => {
            if (!state.statuses.unavailableDays[+year]) {
              state.statuses.unavailableDays[+year] = unavailableDays[+year];
            } else {
              Object.keys(unavailableDays[+year]).forEach((month) => {
                if (!state.statuses.unavailableDays[+year][+month]) {
                  state.statuses.unavailableDays[+year][+month] = unavailableDays[+year][+month];
                }
              });
            }
          });
        }
      }

      setTimeout(() => {
        events.emit(CustomEvents.CheckinStatusesUpdated);
      });
    },
    resetState: () => {
      return initialState;
    },
  },
});

export const weekDetailsActions = weekDetailsSlice.actions;

export const weekDetailsReducer = weekDetailsSlice.reducer;

export const getWeekDetails = (
  propertyId: number,
  sessionKey: ISessionKey,
  isMobile = false,
  propertyRequest?: any,
  dealId?: number,
  quote?: string
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(weekDetailsActions.setLoading(true));
    dispatch(weekDetailsActions.setStatuses(null));
    dispatch(weekDetailsActions.setIsServerError(false));
    
    const { loginStore } = getState();
    const { account } = loginStore;

    try {  
      const res = await axiosInstance.post(
          Urls.WeeksDetails,
          { 
            propertyId,
            sessionKey,
            propertyRequest,
            currency: getSelectedCurrency(account),
            //searchType: condoRequest?.requestType,
            //dealId,
            //quote,
          },
          { ...getHeaders() },
        );

      dispatch(weekDetailsActions.setLoading(false));

      dispatch(weekDetailsActions.setWeekDetails(res.data));

      // @ts-ignore
      //updateUnits(res.data.sessionKey?.expireDate, dispatch, getState);

      //if (res.data?.sessionKey?.isInvalid) {
      //  dispatch(getUnitsDetails(condoId));
      //}

      setTimeout(() => {
        const { unitsSearchStore } = getState();

        if (unitsSearchStore.startDate && unitsSearchStore.endDate) {
          const today = new Date();
          const start = getDateCheckInOut(unitsSearchStore.startDate);
          const startMonth = new Date(`${format(start, 'yyyy-MM')}${emptyDatePart}`);
          const end = subDays(addMonths(startMonth, two), one);
          const isSameMonth = today.getMonth() === start.getMonth();
          const isNextMonth = today.getMonth() === start.getMonth() - one;
          const tomorrow = addDays(today, one);
          let startDate;
          let endDate;

          if (isSameMonth || isNextMonth || isMobile) {
            startDate = `${format(tomorrow, 'yyyy-MM-dd')}${emptyDatePartDays}`;
          } else {
            startDate = `${format(start, 'yyyy-MM')}${emptyDatePart}`;
          }

          if (isMobile) {
            endDate = `${format(addMonths(end, one), 'yyyy-MM-dd')}${emptyDatePartDays}`;
          } else {
            endDate = `${format(end, 'yyyy-MM-dd')}${emptyDatePartDays}`;
          }

          dispatch(getCheckinStatus(startDate, endDate));
        } else {
          const currentDate = new Date();
          const currentDateFormat = `${format(currentDate, 'yyyy-MM-dd')}${emptyDatePartDays}`;
          const endDateNextMonthFormat = `${format(
            getEndDateNextMonth(),
            'yyyy-MM-dd',
          )}${emptyDatePartDays}`;

          dispatch(getCheckinStatus(currentDateFormat, endDateNextMonthFormat));
        }
      });
    } catch (error) {
      console.error(error);

      const errorObject = error?.response?.data;
      const errorCode = get(errorObject, 'code', error.toString());

      dispatch(weekDetailsActions.setError(errorCode));
      dispatch(weekDetailsActions.setLoading(false));

      if (
        error?.response?.status === SESSION_EXPIRED_STATUS &&
        error?.response?.data &&
        error?.response?.data[ZERO].errorMessage === SESSION_SEARCH_EXPIRED_MESSAGE
      ) {
        history.replaceState(null, null, `/${account?.name}${Routes.WeeksSearch}${location.search}`);
        location.reload();
      } else if (SERVER_ERROR_STATUSES.includes(error?.response?.status)) {
        dispatch(weekDetailsActions.setIsServerError(true));
      }

      if ([BookingErrorsEnum.RoomsUnavailable, BookingErrorsEnum.SoldOut].includes(errorCode)) {
        dispatch(weekDetailsActions.setError(errorCode));
      }
    }
  };
};

export const getCheckinStatus = (startDate: string, endDate: string): AppThunk => {
  return async (dispatch, getState) => {
    try {
      dispatch(weekDetailsActions.setLastCheckinEndDate(endDate));
      dispatch(weekDetailsActions.setIsCheckinStatusLoading(true));
      const { weeksDetailsStore, unitsSearchStore, loginStore } = getState();
      const { user } = loginStore;

      const { data } = await axiosInstance.post(
        Urls.CheckinStatus,
        {
          condoId: weeksDetailsStore.week.propertyDetails.id,
          dateRange: {
            from: startDate,
            to: endDate,
          },
          adultsCount: unitsSearchStore.guests?.adultsCount,
          childrenCount: unitsSearchStore.guests?.kidsCount,
          bedroomsCount: unitsSearchStore.guests?.bedroomsCount,
        },
        {
          ...getHeaders(user?.accessToken),
        },
      );

      dispatch(weekDetailsActions.setStatuses(data));
      dispatch(weekDetailsActions.setIsCheckinStatusLoading(false));
    } catch (error) {
      dispatch(weekDetailsActions.setIsCheckinStatusLoading(false));
      console.error(error);
    }
  };
};
