import React from 'react';

export const LiftSvg: React.FunctionComponent = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.99997 12.6667H14C14.248 12.6667 14.4753 12.5287 14.5906 12.3094C14.706 12.0901 14.6893 11.8247 14.5486 11.6207L8.54864 2.95407C8.29997 2.59474 7.70131 2.59474 7.45197 2.95407L1.45197 11.6207C1.31064 11.8247 1.29464 12.0901 1.40997 12.3094C1.52464 12.5287 1.75197 12.6667 1.99997 12.6667Z"
      fill="#333333"
    />
  </svg>
);
