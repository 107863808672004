import React from 'react';

export const CondoSvg: React.FunctionComponent = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.33333 8H2L8 2L14 8H12.6667" fill="#C5C5C5" />
    <path
      d="M3.33334 8V12.6667C3.33334 13.0203 3.47382 13.3594 3.72387 13.6095C3.97392 13.8595 4.31305 14 4.66668 14H11.3333C11.687 14 12.0261 13.8595 12.2762 13.6095C12.5262 13.3594 12.6667 13.0203 12.6667 12.6667V8"
      fill="#C5C5C5"
    />
    <path
      className="path-white"
      d="M6 14V10C6 9.6464 6.14048 9.30726 6.39052 9.05721C6.64057 8.80716 6.97971 8.66669 7.33333 8.66669H8.66667C9.02029 8.66669 9.35943 8.80716 9.60948 9.05721C9.85952 9.30726 10 9.6464 10 10V14"
      fill="white"
    />
  </svg>
);
