import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { get } from 'lodash';

import { IHotelDetailsState, IReviewBookState } from '@store/slices';
import { RootState } from '@share/utils';
import { IPackageRoom } from '@common-types';
import { BookingErrorsEnum } from '@share/common-types';
import { ModalAvailabilityStatus, Type } from '@components';

import './style.scss';

interface IMapStateToProps {
  reviewBookStore: IReviewBookState;
  hotelDetailsStore: IHotelDetailsState;
}

interface IProps extends WrappedComponentProps, IMapStateToProps, RouteComponentProps {
  isVacationRentals?: boolean;
}

function AvailabilityStatusModalComponent(props: IProps) {
  const { reviewBookStore, hotelDetailsStore, isVacationRentals } = props;
  const { bookingSummary } = reviewBookStore;
  const { error, hotel } = hotelDetailsStore;

  const rooms = useMemo(() => get(bookingSummary, 'bookingCard.package.rooms', []), [bookingSummary]);
  const hasSoldout = useMemo(() => rooms.filter((r: IPackageRoom) => r.availability === BookingErrorsEnum.SoldOut).length > 0, [rooms]);
  const hasUnavailable = BookingErrorsEnum.RoomsUnavailable === error;

  return (
    <ModalAvailabilityStatus
      hasSoldout={hasSoldout}
      isUnavailable={hasUnavailable}
      type={isVacationRentals ? Type.VACATION_RENTALS : Type.HOTELS}
      propertyId={hotel?.hotelDetails?.id?.toString()}
      bookingErrorCode={error}
    />
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    reviewBookStore: state.reviewBookStore,
    hotelDetailsStore: state.hotelDetailsStore
  };
};

export const AvailabilityStatusModal = connect(mapStateToProps)(injectIntl(withRouter(AvailabilityStatusModalComponent)));
