import React from 'react';

export const PoolSvg: React.FunctionComponent = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.33337 13.3335C1.48482 13.5445 1.68536 13.7155 1.91766 13.8316C2.14996 13.9478 2.40704 14.0056 2.66671 14.0002C2.92637 14.0056 3.18346 13.9478 3.41576 13.8316C3.64806 13.7155 3.84859 13.5445 4.00004 13.3335C4.15149 13.1225 4.35202 12.9516 4.58432 12.8354C4.81662 12.7193 5.07371 12.6614 5.33337 12.6669C5.59304 12.6614 5.85012 12.7193 6.08242 12.8354C6.31473 12.9516 6.51526 13.1225 6.66671 13.3335C6.81815 13.5445 7.01869 13.7155 7.25099 13.8316C7.48329 13.9478 7.74038 14.0056 8.00004 14.0002C8.2597 14.0056 8.51679 13.9478 8.74909 13.8316C8.98139 13.7155 9.18193 13.5445 9.33337 13.3335C9.48482 13.1225 9.68535 12.9516 9.91766 12.8354C10.15 12.7193 10.407 12.6614 10.6667 12.6669C10.9264 12.6614 11.1835 12.7193 11.4158 12.8354C11.6481 12.9516 11.8486 13.1225 12 13.3335C12.1515 13.5445 12.352 13.7155 12.5843 13.8316C12.8166 13.9478 13.0737 14.0056 13.3334 14.0002C13.593 14.0056 13.8501 13.9478 14.0824 13.8316C14.3147 13.7155 14.5153 13.5445 14.6667 13.3335"
      stroke="#292929"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.33337 10.6665C1.48482 10.8775 1.68536 11.0485 1.91766 11.1646C2.14996 11.2808 2.40704 11.3386 2.66671 11.3332C2.92637 11.3386 3.18346 11.2808 3.41576 11.1646C3.64806 11.0485 3.84859 10.8775 4.00004 10.6665C4.15149 10.4555 4.35202 10.2846 4.58432 10.1684C4.81662 10.0523 5.07371 9.99442 5.33337 9.99986C5.59304 9.99442 5.85012 10.0523 6.08242 10.1684C6.31473 10.2846 6.51526 10.4555 6.66671 10.6665C6.81815 10.8775 7.01869 11.0485 7.25099 11.1646C7.48329 11.2808 7.74038 11.3386 8.00004 11.3332C8.2597 11.3386 8.51679 11.2808 8.74909 11.1646C8.98139 11.0485 9.18193 10.8775 9.33337 10.6665C9.48482 10.4555 9.68535 10.2846 9.91766 10.1684C10.15 10.0523 10.407 9.99442 10.6667 9.99986C10.9264 9.99442 11.1835 10.0523 11.4158 10.1684C11.6481 10.2846 11.8486 10.4555 12 10.6665C12.1515 10.8775 12.352 11.0485 12.5843 11.1646C12.8166 11.2808 13.0737 11.3386 13.3334 11.3332C13.593 11.3386 13.8501 11.2808 14.0824 11.1646C14.3147 11.0485 14.5153 10.8775 14.6667 10.6665"
      stroke="#292929"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 8V3C10 2.73478 10.1054 2.48043 10.2929 2.29289C10.4804 2.10536 10.7348 2 11 2C11.2652 2 11.5196 2.10536 11.7071 2.29289C11.8946 2.48043 12 2.73478 12 3"
      stroke="#292929"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 8V3C6 2.73478 5.89464 2.48043 5.70711 2.29289C5.51957 2.10536 5.26522 2 5 2C4.73478 2 4.48043 2.10536 4.29289 2.29289C4.10536 2.48043 4 2.73478 4 3"
      stroke="#292929"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10 3.3335H6" stroke="#292929" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 6.6665H10" stroke="#292929" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
