
import React from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { RootState } from '@share/utils';
import { IDashboardReportState } from '@store/slices';

import { DashboardReportHeaderItem, HeaderItemColor, HeaderItemType } from './dashboard-report-header-item';

import { faArrowTrendDown, faArrowTrendUp, faDollarSign, faPercentage, faPiggyBank } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IMapStateToProps {
  dashboardReportsStore: IDashboardReportState;
}

interface IProps extends IMapStateToProps, RouteComponentProps, WrappedComponentProps { }

function DashboardReportHeaderComponent(props: IProps) {
  const { dashboardReportsStore } = props;
  const { loading, dashBoardData } = dashboardReportsStore;

  return (
    <div className="dashboard-report-header-wrapper">
      <DashboardReportHeaderItem type={HeaderItemType.NUMBER} loading={loading} title={'bookings'} icon={faArrowTrendUp} value={dashBoardData?.confirmedBookings} color={HeaderItemColor.BLUE} />
      <DashboardReportHeaderItem type={HeaderItemType.CURRENCY} loading={loading} title={'sales'} icon={faDollarSign} value={dashBoardData?.sales} color={HeaderItemColor.GREEN} />
      <DashboardReportHeaderItem type={HeaderItemType.CURRENCY} loading={loading} title={'savings'} icon={faDollarSign} value={dashBoardData?.savings} color={HeaderItemColor.GREEN} />
      <DashboardReportHeaderItem type={HeaderItemType.CURRENCY} loading={loading} title={'commissions'} icon={faPiggyBank} value={dashBoardData?.commissions} color={HeaderItemColor.GREEN} />
      <DashboardReportHeaderItem type={HeaderItemType.NUMBER} loading={loading} title={'cancellations'} icon={faArrowTrendDown} value={dashBoardData?.canceledBookings} color={HeaderItemColor.BLUE} />
    </div >
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    dashboardReportsStore: state.dashboardReportsStore
  };
};

const ComponentIntl = injectIntl(DashboardReportHeaderComponent);

export const DashboardReportHeader = connect(mapStateToProps)(withRouter(ComponentIntl));
