import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { Canceler } from 'axios';

import { ILocation, LocationsTypes } from '@share/common-types';
import { Urls } from '@share/constants';
import { getHeaders, axiosInstance, AppThunk } from '@share/utils';
import { isEmpty } from 'lodash';

export interface ICarLocation {
  selectedLocationLabel: string;
  selectedLocation: ILocation;

  locationLabel: string;
  location: ILocation;
}

export interface ICarLocationsState {
  locations: ILocation[];

  loading: boolean;
  error: string;

  pickUp: ICarLocation;
  dropOff: ICarLocation;
}

const initialState: ICarLocationsState = {
  locations: [],
  loading: false,
  error: '',
  pickUp: {
    selectedLocationLabel: '',
    selectedLocation: null,
    locationLabel: '',
    location: null
  },
  dropOff: {
    selectedLocationLabel: '',
    selectedLocation: null,
    locationLabel: '',
    location: null
  },
};

const carsLocationsSlice = createSlice({
  name: 'carsLocations',
  initialState,
  reducers: {
    setLoading: (state: ICarLocationsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: ICarLocationsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setLocations: (state: ICarLocationsState, { payload }: PayloadAction<ILocation[]>) => {
      state.locations = payload;
    },

    setSelectPickUpLocation: (state: ICarLocationsState, { payload }: PayloadAction<ILocation>) => {
      state.pickUp.selectedLocation = payload;
    },
    setSelectPickUpLocationLabel: (state: ICarLocationsState, { payload }: PayloadAction<string>) => {
      state.pickUp.selectedLocationLabel = payload;
    },
    resetSelectedPickUpLocation: (state: ICarLocationsState) => {
      state.pickUp.selectedLocationLabel = state.pickUp.locationLabel;
      state.pickUp.selectedLocation = state.pickUp.location;
    },

    setSelectDropOffLocation: (state: ICarLocationsState, { payload }: PayloadAction<ILocation>) => {
      state.dropOff.selectedLocation = payload;
    },
    setSelectDropOffLocationLabel: (state: ICarLocationsState, { payload }: PayloadAction<string>) => {
      state.dropOff.selectedLocationLabel = payload;
    },

    applyLocation: (state: ICarLocationsState) => {
      state.pickUp.locationLabel = state.pickUp.selectedLocationLabel;
      state.pickUp.location = state.pickUp.selectedLocation;

      if (!isEmpty(state.dropOff.selectedLocationLabel)) {
        state.dropOff.locationLabel = state.dropOff.selectedLocationLabel;
      } else {
        state.dropOff.locationLabel = state.pickUp.selectedLocationLabel;
      }

      if (state.dropOff.selectedLocation) {
        state.dropOff.location = state.dropOff.selectedLocation;
      } else {
        state.dropOff.location = state.pickUp.selectedLocation;
      }
    },
    resetSelectedDropOffLocation: (state: ICarLocationsState) => {
      state.dropOff.selectedLocationLabel = state.dropOff.locationLabel;
      state.dropOff.selectedLocation = state.dropOff.location;
    },

    
    resetCarsLocations: (state: ICarLocationsState) => {
      state.locations = [];
      state.loading = false;
      state.error = '';
      state.pickUp.selectedLocationLabel = '';
      state.pickUp.selectedLocation = null;
      state.pickUp.locationLabel = '';
      state.pickUp.location = null;
      state.dropOff.selectedLocationLabel = '';
      state.dropOff.selectedLocation = null;
      state.dropOff.locationLabel = '';
      state.dropOff.location = null;
    },
  },
});

export const carsLocationsActions = carsLocationsSlice.actions;

export const carsLocationsReducer = carsLocationsSlice.reducer;

let cancelRequest: Canceler;

export const getCarsLocations = (search: string, selectedLocation: ILocation | undefined, onlyCities: boolean): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(carsLocationsActions.setLoading(true));

    try {
      if (cancelRequest) {
        cancelRequest();
      }

      const { hotelsStore } = getState();
      const { isWidget } = hotelsStore;
      const res = await axiosInstance.get(Urls.CarsLocations, {
        ...getHeaders(isWidget),
        params: {
          Search: search.trim(),
        },
        cancelToken: new axios.CancelToken((canceler: Canceler) => {
          cancelRequest = canceler;
        }),
      });

      let locations: ILocation[] = [...res.data.locations];

      if (selectedLocation && search === selectedLocation.name) {
        locations = locations.filter(({ code }) => code !== selectedLocation.code);
        locations.unshift(selectedLocation);
      }

      if (onlyCities) {
        locations = locations.filter(({ type }) => type === LocationsTypes.Destination);
      }

      dispatch(carsLocationsActions.setLoading(false));
      dispatch(carsLocationsActions.setLocations(locations));
    } catch (error) {
      if (!(error instanceof axios.Cancel)) {
        console.error(error);
        dispatch(carsLocationsActions.setLoading(false));
      }
    }
  };
};
