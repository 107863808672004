import React from 'react';

import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';
import { connect } from 'react-redux';

import { ICondoUnitWeek } from '@common-types';
import { CloseSvg } from '@share/assets';

import { CondoWeeksDatesTable } from '../condo-week-dates-table';

import './style.scss';

const modalWidth = 400;

interface IProps extends RouteComponentProps {
  condo: ICondoUnitWeek;

  visible: boolean;

  onCancel: () => void;
}

function CondoWeeksDatesModalComponent(props: IProps) {
  const { condo, visible, onCancel } = props;
  
  return (
    <Modal
      className="condo-weeks-modal-wrapper"
      wrapClassName="condo-weeks-modal-wrapper__wrap"
      visible={visible}
      footer={null}
      onCancel={onCancel}
      closable={true}
      destroyOnClose={true}
      closeIcon={<CloseSvg />}
      width={modalWidth}
      title={<FormattedMessage id="available.dates" />}
    >
      <CondoWeeksDatesTable condo={condo} />
    </Modal>
  );
}

const CondoWeeksDatesModalRouter = withRouter(CondoWeeksDatesModalComponent);

export const CondoWeeksDatesModal = connect()(CondoWeeksDatesModalRouter);
