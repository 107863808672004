import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AdultSvg, ChildSvg } from '@share/assets';
import { IGetawayBookingState } from '@store/slices';
import { GetawayCounts } from '../getaway-counts';
import './style.scss';

interface IProps {
  getawayBookingStore: IGetawayBookingState;
  updateAdultsCount: (count: number) => void;
  updateYoungerChildrenCount: (count: number) => void;
  updateMiddleChildrenCount: (count: number) => void;
  updateOlderChildrenCount: (count: number) => void;
}

const ZERO = 0;
const ONE = 1;
const TWO = 2;
const MAX_LENGTH = 100;

export class GetawayGuests extends React.Component<IProps> {
  render(): React.ReactNode {
    const {
      getawayBookingStore,
      updateAdultsCount,
      updateYoungerChildrenCount,
      updateMiddleChildrenCount,
      updateOlderChildrenCount,
    } = this.props;
    const { adultsCount, youngerChildrenCount, middleChildrenCount, olderChildrenCount } =
      getawayBookingStore;

    return (
      <div className="getaway-guests">
        <div className="getaway-guests__wrapper">
          <div className="getaway-guests__field">
            <div className="getaway-guests__user-icon">
              <AdultSvg />
            </div>
            <div className="getaway-guests__description">
              <div className="getaway-guests__user-label">
                <FormattedMessage values={{ count: TWO }} id="adult" />
              </div>
              <div className="getaway-guests__user-description">
                <FormattedMessage id="over.years" values={{ years: '17' }} />
              </div>
            </div>
            <div className="getaway-guests__counts">
              <GetawayCounts
                count={adultsCount}
                add={() => updateAdultsCount(adultsCount + ONE)}
                minus={() => updateAdultsCount(adultsCount - ONE)}
                min={ONE}
                max={MAX_LENGTH}
              />
            </div>
          </div>
        </div>
        <div className="getaway-guests__wrapper">
          <div className="getaway-guests__field">
            <div className="getaway-guests__user-icon">
              <ChildSvg />
            </div>
            <div className="getaway-guests__description">
              <div className="getaway-guests__user-label">
                <FormattedMessage values={{ count: 2, value: '0-2' }} id="getaway.child" />
              </div>
              <div className="getaway-guests__user-description">
                <FormattedMessage id="getaway.less.years" values={{ from: '0', to: '2' }} />
              </div>
            </div>
            <div className="getaway-guests__counts">
              <GetawayCounts
                count={youngerChildrenCount}
                add={() => updateYoungerChildrenCount(youngerChildrenCount + ONE)}
                minus={() => updateYoungerChildrenCount(youngerChildrenCount - ONE)}
                disabled={!adultsCount}
                min={ZERO}
                max={MAX_LENGTH}
              />
            </div>
          </div>
        </div>
        <div className="getaway-guests__wrapper">
          <div className="getaway-guests__field">
            <div className="getaway-guests__user-icon">
              <ChildSvg />
            </div>
            <div className="getaway-guests__description">
              <div className="getaway-guests__user-label">
                <FormattedMessage values={{ count: TWO, value: '3-12' }} id="getaway.child" />
              </div>
              <div className="getaway-guests__user-description">
                <FormattedMessage id="getaway.less.years" values={{ from: '3', to: '12' }} />
              </div>
            </div>
            <div className="getaway-guests__counts">
              <GetawayCounts
                count={middleChildrenCount}
                add={() => updateMiddleChildrenCount(middleChildrenCount + ONE)}
                minus={() => updateMiddleChildrenCount(middleChildrenCount - ONE)}
                disabled={!adultsCount}
                min={ZERO}
                max={MAX_LENGTH}
              />
            </div>
          </div>
        </div>
        <div className="getaway-guests__wrapper">
          <div className="getaway-guests__field">
            <div className="getaway-guests__user-icon">
              <ChildSvg />
            </div>
            <div className="getaway-guests__description">
              <div className="getaway-guests__user-label">
                <FormattedMessage values={{ count: TWO, value: '13-17' }} id="getaway.child" />
              </div>
              <div className="getaway-guests__user-description">
                <FormattedMessage id="getaway.less.years" values={{ from: '13', to: '17' }} />
              </div>
            </div>
            <div className="getaway-guests__counts">
              <GetawayCounts
                count={olderChildrenCount}
                add={() => updateOlderChildrenCount(olderChildrenCount + ONE)}
                minus={() => updateOlderChildrenCount(olderChildrenCount - ONE)}
                disabled={!adultsCount}
                min={ZERO}
                max={MAX_LENGTH}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
