
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { ILoginState, IMenuState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { Routes } from '@share/constants';

//import GetawayBanner from '@assets/images/getaways/getaway-banner.png';
//import CruiseBanner from '@assets/images/getaways/cruises-banner.png';

import GetawayBanner from '@assets/images/getaways/getaways.jpg';
import CruiseBanner from '@assets/images/getaways/bogo.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps, RouteComponentProps {
  display: boolean;
}

// 587 X 164 

function SpecialBannersComponent(props: IProps) {
  const { loginStore, menuStore, history, display } = props;
  const { account, user, loading } = loginStore;
  const { items } = menuStore;

  const handleGetaways = () => {
    history.push(`/${account?.name}${Routes.Getaways}`);
  }

  const handleBogo = () => {
    window.open(`https://membergetaways.com/odtest1.php?id=${user?.keyid}&t=BOGO`, '_blank');
  }

  if (!display || loading || !items || menuStore?.loading) {
    return null;
  }

  const buttonBorderRadius: any = account?.buttonBorderRadius ? account?.buttonBorderRadius : null;
  const bogoColor: any = account?.bogoColor ? account?.bogoColor : null;
  const bogoButtonTextColor: any = account?.bogoButtonTextColor ? account?.bogoButtonTextColor : null;
  const getawaysColor: any = account?.getawaysColor ? account?.getawaysColor : null;
  const getawaysButtonTextColor: any = account?.getawaysButtonTextColor ? account?.getawaysButtonTextColor : null;
  
  const stylesButtonBogoMain: any = !isEmpty(buttonBorderRadius) ? { borderRadius: `${buttonBorderRadius}px` } : {};  
  const stylesButtonBogo = !isEmpty(bogoButtonTextColor) ? { ...stylesButtonBogoMain, color: bogoButtonTextColor } : { ...stylesButtonBogoMain };  

  const stylesBogoContainer = !isEmpty(bogoColor) ? { borderColor: bogoColor } : {};  
  const stylesBogo = !isEmpty(bogoColor) ? { background: bogoColor } : {};  
  const stylesBogoCorner = !isEmpty(bogoColor) ? { borderLeftColor: bogoColor, borderRightColor: bogoColor } : {};  

  const stylesGetawaysBogoMain: any = !isEmpty(buttonBorderRadius) ? { borderRadius: `${buttonBorderRadius}px` } : {};  
  const stylesGetawaysBogo = !isEmpty(getawaysButtonTextColor) ? { ...stylesGetawaysBogoMain, color: getawaysButtonTextColor } : { ...stylesGetawaysBogoMain };  
  
  const stylesGetawaysContainer = !isEmpty(getawaysColor) ? { borderColor: getawaysColor } : {};  
  const stylesGetaways = !isEmpty(getawaysColor) ? { background: getawaysColor } : {};  
  const stylesGetawaysCorner = !isEmpty(getawaysColor) ? { borderLeftColor: getawaysColor, borderRightColor: getawaysColor } : {};  

  return (
    <div className={`special-banners-container ${items?.isMLM ? 'mlm' : ''}`}>
      <div className="special-banners-container__item blue" onClick={handleBogo} style={stylesBogoContainer}>
        <div className="special-banners-container__item-img" style={{ backgroundImage: `url(${CruiseBanner})`}}></div>

        <div className="special-banners-container__item-backgournd blue" style={stylesBogo}></div>
        <div className="special-banners-container__item-backgournd-corner blue" style={stylesBogoCorner}></div>

        <div className="special-banners-container__item-message bogo">
          <p className="bogo-title">
            <FormattedMessage id="bogo.offer" />
          </p>
          <p className="bogo-message">
            <FormattedMessage id="bogo.message" />
          </p>
        </div>

        <div className="special-banners-container__item-sub-message">
          <label className="sub-message1"><strong>1,410</strong></label>
          <label className="sub-message2"><FormattedMessage id="itineraries.today" /></label>
        </div>

        <div className="special-banners-container__item-view-details blue" style={stylesButtonBogo}>
          <FormattedMessage id="see.details" />
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      </div>

      <div className="special-banners-container__item red" onClick={handleGetaways} style={stylesGetawaysContainer}>
        <div className="special-banners-container__item-img" style={{ backgroundImage: `url(${GetawayBanner})`}}></div>

        <div className="special-banners-container__item-backgournd red" style={stylesGetaways}></div>
        <div className="special-banners-container__item-backgournd-corner red" style={stylesGetawaysCorner}></div>

        <div className="special-banners-container__item-message getaways">
          <label className="getaways-title"><FormattedMessage id="more.than" /></label>
          <label className="getaways-message"><FormattedMessage id="everyday.deeply" /></label>
          <label className="getaways-message"><FormattedMessage id="discounted.vacations" /></label>
          <label className="getaways-off"><FormattedMessage id="getaways.off" /></label>
        </div>

        <div className="special-banners-container__item-view-details red" style={stylesGetawaysBogo}>
          <FormattedMessage id="see.details" />
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    menuStore: state.navigationMenuStore,
  };
};

export const SpecialBanners = connect(mapStateToProps)(withRouter(injectIntl(SpecialBannersComponent)));
