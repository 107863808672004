import React from 'react';
import moment, { Moment } from 'moment';

import { Input } from 'antd';
import { isEmpty } from 'lodash';

import { Calendar } from 'react-date-range';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { insertSpacesInDate } from '@share/utils';

import './style.scss';

const MAX_LENGTH = 10;
const INPUT_KEY_TYPE = 'KEY';
const INPUT_CALENDAR_TYPE = 'CALENDAR';
const REGEX_DIGIT = /^\d+$/;

interface IProps {
  value: string;
  format: string;
  placeholder: string;

  disabled: boolean;
  hide?: boolean;

  minDate?: Date;
  maxDate?: Date;
  allowClear?: boolean;

  onDateChange: (value: string) => void;
  onCalendarOpen?: () => void;
}

interface IState {
  displayModal: boolean;
  forceUpdatePosition: boolean;
  caretStart: number;
  inputType: string;
}

export class CustomDatePicker extends React.Component<IProps, IState> {
  state: IState = { displayModal: false, forceUpdatePosition: false, caretStart: null, inputType: null };

  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  inputRef: React.RefObject<Input> = React.createRef();

  previousEvent: any;

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { value } = this.props;
    const { caretStart, inputType, forceUpdatePosition } = this.state;

    if(prevProps?.value !== value && inputType === INPUT_KEY_TYPE || forceUpdatePosition) {
      this.setState({ forceUpdatePosition: false }, () => this.inputRef.current.setSelectionRange(caretStart, caretStart));
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  validateDate = () => {
    const { value, format, onDateChange } = this.props;

    if(!moment(value, format, true).isValid()) {
      onDateChange(null);
    }
  }

  handleClickOutside = (event: MouseEvent) => {
    if (
      this.wrapperRef?.current &&
      !this.wrapperRef.current.contains(event.target as Node) &&
      !(event.target as HTMLElement).closest('.custom-date-picker__calendar')
    ) {
      this.setState({ displayModal: false, caretStart: null, inputType: null });
    }
  }

  handleDate = (value: string) => {
    const { onDateChange } = this.props;

    const matchDate = insertSpacesInDate(value);
    const mainDate = matchDate?.join('/').trim();

    onDateChange(mainDate);
  }

  render(): React.ReactNode {
    const { value, placeholder, format, disabled, allowClear, hide, minDate, maxDate, onDateChange, onCalendarOpen } = this.props;
    const { displayModal } = this.state;

    if (hide) {
      return null;
    }
    
    return (
      <div className="custom-date-picker">
        <Input
          placeholder={placeholder}
          value={value}
          maxLength={MAX_LENGTH}
          ref={this.inputRef}
          data-date-format={format ? format : null}
          onChange={(e) => {
            if (['Delete', 'Backspace'].includes(this.previousEvent?.key )) {
              onDateChange(e?.target?.value);
            } else {
              this.handleDate(e?.target?.value);
            }
          }}
          onClick={() => this.setState({ displayModal: true, caretStart: null, inputType: null }, () => {
            if (onCalendarOpen) {
              onCalendarOpen();
            }
          })}
          onKeyDown={(e) => {
            const isDeleteKey = ['Delete', 'Backspace'].includes(e?.key);
            const caretEnd = e.currentTarget.selectionEnd;
            const isDigit = REGEX_DIGIT.test(e?.key);
            const str = e?.currentTarget?.value;
            const isLeftKey = e?.key === 'ArrowLeft';
            const isRightKey = e?.key === 'ArrowRight';

            let caretStart = e.currentTarget.selectionStart;
            let forceUpdatePosition = false;

            if (str?.length === MAX_LENGTH && !isDeleteKey && !isDigit && !isLeftKey && !isRightKey) {
              e.preventDefault();
            } else {
              if (isDigit) {
                const strWS = str?.replaceAll('/', '');

                this.handleDate(`${str.slice(0, caretStart)}${e?.key}${str.slice(caretEnd)}`);
                
                caretStart += 1 + ([2, 4].includes(strWS?.length) ? 1 : 0);

                e.preventDefault();
              } else if(!isDeleteKey) {
                if (isLeftKey && caretStart !== 0) {
                  caretStart -= 1;
                  forceUpdatePosition = true;
                }

                if (isRightKey && str?.length > caretEnd) {
                  caretStart += 1;
                  forceUpdatePosition = true;
                }

                if (!(this.previousEvent?.key === 'Control' && ['v', 'x', 'c'].includes(e?.key?.toLowerCase())) || isLeftKey || isRightKey) {
                  e.preventDefault();
                }
                this.previousEvent = e;
              } else if (isDeleteKey) {
                if (e?.key === 'Backspace') {
                  caretStart -= 1;
                }
              }
              
              this.setState({ inputType: INPUT_KEY_TYPE, caretStart, forceUpdatePosition });
            }
          }}
          onBlur={this.validateDate}
          disabled={disabled}
        />

        {(allowClear && !isEmpty(value)) ? (
          <div style={{ cursor: 'pointer', padding: '3px' }} onClick={() => onDateChange(null)}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </div>) : (
          <FontAwesomeIcon icon={faCalendar} />)}

        {displayModal ? (
          <div className="custom-date-picker__calendar" ref={this.wrapperRef}>
            <Calendar
              date={null}
              minDate={minDate}
              maxDate={maxDate}
              onChange={(e) => {
                const date = e? moment(e as Date).format(format) : '';
                this.setState({ displayModal: false, inputType: INPUT_CALENDAR_TYPE, caretStart: null }, () => onDateChange(date));
              }}
            />
          </div>) : null}
      </div>
    );
  }
}
