import React from 'react';

import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { CategoryEnum } from '../../my-travel-history';

import './style.scss';

interface IProps {
  type: string;
}

export class ReservationType extends React.Component<IProps> {
  render(): React.ReactNode {
    const { type } = this.props;
    if (!isEmpty(type)) {
      const value = type.toLowerCase();
      return <div className='status' >
        <div className={`text ${value === CategoryEnum.Hotel ? 'green' : value === CategoryEnum.Condo ? 'cyan' : 'geekblue'}`}>
          <FormattedMessage id={type} />
        </div>
      </div>
    }
    return null;
  }
}
