import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setLoginUser, getUserWallet, setLoginOriginalUser } from '@share/store/slices';
import { IMyProfile, IUserCard } from '@share/common-types';
import { AppThunk, Toaster } from '@share/utils';
import { MyProfileAPI } from '@share/api';
import { AddUserCreditCard, DeleteUserCreditCard, GetUserCreditCards } from '@share/services';
import { NULL_VALUE } from '@constants';

export interface IMyProfileState {
  loading: boolean;
  loadingCards: boolean;
  error: string;
  result: {
    profile: IMyProfile;
    authorizedUsers: IMyProfile[];
    userCards: IUserCard[];
  }
}

const initialState: IMyProfileState = {
  loading: false,
  loadingCards: false,
  error: '',
  result: {
    profile: NULL_VALUE,
    authorizedUsers: [],
    userCards: []
  }
};

const myProfileSlice = createSlice({
  name: 'myProfile',
  initialState,
  reducers: {
    setLoading: (state: IMyProfileState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setLoadingCards: (state: IMyProfileState, { payload }: PayloadAction<boolean>) => {
      state.loadingCards = payload;
    },
    setError: (state: IMyProfileState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setMyProfile: (state: IMyProfileState, { payload }: PayloadAction<IMyProfile>) => {
      state.result.profile = payload;
    },
    setAuthorizedUsers: (state: IMyProfileState, { payload }: PayloadAction<IMyProfile[]>) => {
      state.result.authorizedUsers = payload;
    },
    setUserCards: (state: IMyProfileState, { payload }: PayloadAction<IUserCard[]>) => {
      state.result.userCards = payload;
    },
  },
});

export const myProfileActions = myProfileSlice.actions;

export const myProfileReducer = myProfileSlice.reducer;

export const getMyProfile = (): AppThunk => {
  return async (dispatch, getState) => {
    const { loginStore } = getState();
    const { user, account } = loginStore;

    const isPrivateTokenAccountType = account?.isPrivateTokenAccountType;
    if (!isPrivateTokenAccountType) {
      return;
    }

    dispatch(myProfileActions.setLoading(true));
    dispatch(myProfileActions.setMyProfile(NULL_VALUE));

    try {
      const response = await MyProfileAPI.get(user.keyid);
      const { data } = response;

      if (data.isSuccess) {
        dispatch(myProfileActions.setMyProfile(data.item));
        dispatch(myProfileActions.setAuthorizedUsers(data.authorizedUsers));
      } else {
        dispatch(myProfileActions.setError(`[${data.status}] ${data.message}`));
      }
      dispatch(myProfileActions.setLoading(false));

      dispatch(getUserCards());
    } catch (error: any) {
      console.error(error);
      dispatch(myProfileActions.setError(error.toString()));
      dispatch(myProfileActions.setLoading(false));
    }
  };
};

export const updateMyProfile = (profile: IMyProfile): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(myProfileActions.setLoading(true));

    try {
      const { loginStore } = getState();
      const { user, userWallet } = loginStore;

      const response = await MyProfileAPI.update(user.keyid, profile);
      const { data } = response;

      if (data.isSuccess) {
        dispatch(setLoginUser(data.user));
        dispatch(setLoginOriginalUser(data.user));
        
        if (userWallet) {
          dispatch(getUserWallet(data.user));
        }
        dispatch(getMyProfile());
        Toaster.success(data.message);
      } else {
        dispatch(myProfileActions.setError(`[${response.status}] ${data.message}`));
        Toaster.error(`An error occurred. [${response.status}] ${data.message}. If the problem persists, please contact the system administrator.`);
      }
      dispatch(myProfileActions.setLoading(false));
    } catch (error: any) {
      console.error(error);
      dispatch(myProfileActions.setError(error.toString()));
      dispatch(myProfileActions.setLoading(false));
      Toaster.error(`An error occurred: ${error.toString()}. If the problem persists, please contact the system administrator.`);
    }
  };
};


export const getUserCards = (): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(myProfileActions.setUserCards(NULL_VALUE));
      dispatch(myProfileActions.setLoadingCards(true));

      const response = await GetUserCreditCards();
      
      dispatch(myProfileActions.setUserCards(response?.data?.map((c: any) => ({
        id: c?.id,
        addressLine: c?.billing?.address?.line1,
        cvv: null,
        cardNumber: `**** **** **** ${c?.card?.last4}`,
        cardType: c?.card?.brand,
        city: c?.billing?.address?.city,
        country: c?.billing?.address?.country,
        state: c?.billing?.address?.state,
        expireDate: `${c?.card?.expMonth < 10 ? '0' : ''}${c?.card?.expMonth}${c?.card?.expYear}`,
        holderName: c?.billing?.name,
        phone: c?.billing?.name,
        zipCode: c?.billing?.address?.postalCode,
      } as any))));
      dispatch(myProfileActions.setLoadingCards(false));
    } catch(error) {
      console.error(error);
      dispatch(myProfileActions.setLoadingCards(false));
    }
  }
}

export const addUserCard = (userCard: IUserCard, sucessCallBack: () => void): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const { loginStore } = getState();
      const { user } = loginStore;
      dispatch(myProfileActions.setLoadingCards(true));

      await AddUserCreditCard(userCard, user);
      
      dispatch(getUserCards());
      dispatch(myProfileActions.setLoadingCards(false));

      sucessCallBack();
    } catch(error) {
      console.error(error);
      dispatch(myProfileActions.setLoadingCards(false));
    }
  }
}

export const deleteUserCard = (userCard: IUserCard, sucessCallBack: () => void): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(myProfileActions.setLoadingCards(true));
      
      await DeleteUserCreditCard(userCard?.id);
      
      dispatch(getUserCards());
      dispatch(myProfileActions.setLoadingCards(false));

      sucessCallBack();
    } catch(error) {
      console.error(error);
      dispatch(myProfileActions.setLoadingCards(false));
    }
  }
}
