import React from 'react';

export const MapSvg: React.FunctionComponent = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 5.8335L7.5 3.3335L12.5 5.8335L17.5 3.3335V14.1668L12.5 16.6668L7.5 14.1668L2.5 16.6668V5.8335Z"
      stroke="#0D99D6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 3.3335V14.1668"
      stroke="#0D99D6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 5.8335V16.6668"
      stroke="#0D99D6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
