
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import { APP_SELECTOR } from '@constants';
import { App } from '@pages';
import { store } from '@store';
import { history } from '@share/utils';

import reportWebVitals from './reportWebVitals';

import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import './assets/scss/style.scss';
import './assets/scss/main-theme.scss';

const appNode = document.querySelector(APP_SELECTOR);

if (appNode) {
  ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
        <Router history={history}>
          <App isWidget={true} />
        </Router>
      </Provider>
    </BrowserRouter>,
    appNode,
  );
}

reportWebVitals();
