import React, { useState, useEffect } from 'react';

import { FormattedMessage } from 'react-intl';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, Form, Input, Alert } from 'antd';
import { GetHomeParams, RootState } from '@share/utils';
import { ILoginState, IPasswordState, forgot } from '@share/store/slices';
import { getAccountUsernameFromPath } from '@share/utils';
import { Routes } from '@share/constants';

import './style.scss';

interface IMapStateToProps {
  passwordStore: IPasswordState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  forgotPassword: (email: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps { }

function ForgotPasswordComponent(props: IProps) {
  const [accountName, setAccountName] = useState(null);
  const { isSuccess, message } = props.passwordStore;

  useEffect(() => {
    const accountName = getAccountUsernameFromPath(props.history);
    setAccountName(accountName);
  }, [])

  const onFinish = (values: any) => {
    props.forgotPassword(values.email);
  };

  const { loginStore } = props;
  const { account } = loginStore;
  const hasClientCash = account?.hasClientCash;
  
  const homeParams = GetHomeParams(account);
  
  return (
    <div className="password-page">
      <div className="login-box col-sm-5 col-xs-12">
        <Form
          name="normal_password"
          className="password-form"
          layout="vertical"
          onFinish={onFinish}
        >
          <p className="form-title"><FormattedMessage id="forgot.password" /></p>

          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              { type: 'email', message: <FormattedMessage id="the.input.is.not.valid.email" /> },
              { required: true, message: <FormattedMessage id="please.input.your.email" /> },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          {message ?
            <Form.Item>
              {isSuccess
                ? <Alert message="" description={message} type="success" showIcon />
                : <Alert message="" description={message} type="error" showIcon />
              }
            </Form.Item> : null}

          <Form.Item>
            <Button type="primary" htmlType="submit" className="password-form-button">
              <FormattedMessage id="send.email" />
            </Button>
          </Form.Item>
          
          <Form.Item>
            <Button className="password-form-button cancel" href={`${accountName}/signin`}><FormattedMessage id="back.to.login" /></Button>
          </Form.Item>

          {hasClientCash && (
            <Form.Item>
              <a className="login-form-forgot" href={`/${accountName}${Routes.Search}${homeParams}`}><FormattedMessage id="confirmation.go.back_home" /></a>
            </Form.Item>)}

        </Form>

      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    passwordStore: state.passwordStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  forgotPassword: (email: string) => {
    dispatch(forgot(email));
  }
});

const ForgotPasswordComponentRouter = withRouter(ForgotPasswordComponent);

export const ForgotPassword = connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordComponentRouter);
