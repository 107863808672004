import { IUser } from "@share/common-types";

export const ViewQuotesSharePermission = 'VIEWQUOTESSHARE';
export const ViewCondoSupplierPermission = 'SEECONDOSUPPLIER';
export const VieAllAccountBookingsPermission = 'VIEWALLACCOUNTBOOKINGS';

export const ALL_PERMISSIONS: string[] = [
  ViewQuotesSharePermission,
  ViewCondoSupplierPermission 
];

export const HasPermission = (user: IUser | null, permission: string): boolean => {
  return !!user?.permissions && user?.permissions?.includes(permission);
}
