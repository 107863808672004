import React from 'react';

export const ParkingSvg: React.FunctionComponent = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2.6665H3.99996C3.26358 2.6665 2.66663 3.26346 2.66663 3.99984V11.9998C2.66663 12.7362 3.26358 13.3332 3.99996 13.3332H12C12.7363 13.3332 13.3333 12.7362 13.3333 11.9998V3.99984C13.3333 3.26346 12.7363 2.6665 12 2.6665Z"
      fill="#292929"
      stroke="#292929"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 10.6668V5.3335H8.66667C9.02029 5.3335 9.35943 5.47397 9.60948 5.72402C9.85952 5.97407 10 6.31321 10 6.66683C10 7.02045 9.85952 7.35959 9.60948 7.60964C9.35943 7.85969 9.02029 8.00016 8.66667 8.00016H6"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
