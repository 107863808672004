import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BackgroundColor } from '@constants';
import { ICompanyLogos } from '@common-types';
import { IMenuState } from '@share/store/slices';

import './style.scss';
import { RootState } from '@share/utils';
import { connect } from 'react-redux';

interface IMapStateToProps {
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps {
  isBackgroundColorDark?: boolean;
  logos: ICompanyLogos[];
  max?: number;
}

class CompanyLogosComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { isBackgroundColorDark = true, logos, max, menuStore } = this.props;
    const { items } = menuStore;

    const logosTodisplay = max ? logos.slice(0, max) : logos;
    
    if (!items) {
      return null;
    }
    
    return (
      <div
        className={`company-logos ${
          isBackgroundColorDark ? BackgroundColor.dark : BackgroundColor.light
        }`}
      >
        <div className="company-logos__wrapper">
          <p className="company-logos__title sencodary-font">
            <FormattedMessage id="company.you.keep" />
          </p>
          <div className="company-logos__logos-wrapper">
            {logosTodisplay.map((item, index) => (
              <div key={index} className="company-logos__img-wrapper">
                <img className="company-logos__img" src={item.image} alt={item.name} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
  };
};

export const CompanyLogos = connect(mapStateToProps)(CompanyLogosComponent);
