import React from 'react';

import Select from 'antd/lib/select';

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { ICarsFiltersState, ILoginState, carsFiltersActions } from '@share/store/slices';
import { RootState } from '@share/utils';

import { ThunkDispatch } from 'redux-thunk';
import { Action } from '@reduxjs/toolkit';

import { get, isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsLeftRightToLine, faCar, faGasPump, faTags } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IMapStateToProps {
  carsFiltersStore: ICarsFiltersState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setSimpleType: (value: string[]) => void;
  setFuel: (value: string[]) => void;
  setRentalCompany: (value: string[]) => void;
  setRateDistance: (value: string[]) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {
}

const CAR_TYPES = [
  'Car',
  'Wagon/Estate',
  'Passenger Van',
  'Limousine/Sedan',
  'Sport',
  'Convertible',
  'SUV',
  'Open Air All Terrain',
  'Special',
  'Pick up',
  'Special Offer Car',
  'Coupe',
  'Monospace',
  'Recreational Vehicle',
  'Motor Home',
  '2 Wheel Vehicle',
  'Roadster',
  'Crossover',
  'Commercial Van/Truck'
];

const FUEL = [
  'Petrol',
  'Diesel',
  'Electric',
];

const DISNTACE = [
  'Unlimited',
  'Non Unlimited',
];

const CAR_RENTALS = [
  'Hertz',
  'Thrifty US',
  'Dollar US',
  'Avis',
  'Budget',
  'WheeGo',
  'Zezgo',
  'NextCar',
  'OK Mobility',
  'Routes',
  'Greenmotion',
  'Entreprise',
  'Fox Rent-a-car',
  'Sixt'
];

class CarsSearchFiltersComponent extends React.Component<IProps> {

  onFilterChange = (checkedValues: string[], setter: (values: string[]) => void) => {
    setter(checkedValues);
  };

  onSimpleTypeChange = (e: string) => {
    return this.onFilterChange(!isEmpty(e) ? [e] : [], this.props.setSimpleType);
  };
  onFuelChange = (e: string) => {
    return this.onFilterChange(!isEmpty(e) ? [e] : [], this.props.setFuel);
  };
  onRateDistanceChange = (e: string) => {
    return this.onFilterChange(!isEmpty(e) ? [e] : [], this.props.setRateDistance);
  };
  onRentalCompanyChange = (e: string) => {
    return this.onFilterChange(!isEmpty(e) ? [e] : [], this.props.setRentalCompany);
  };

  render(): React.ReactNode {
    const { carsFiltersStore, loginStore } = this.props;
    const { simpleTypeQuick, fuelQuick, rateDistanceQuick, rentalCompanyQuick } = carsFiltersStore;
    const { account } =  loginStore;

    const color: any = account?.searchIconsColor;
    const generalBorderRadius: any = account?.generalBorderRadius ? account?.generalBorderRadius : null;

    const style = !isEmpty(generalBorderRadius) ? { borderRadius: generalBorderRadius } : {};  
    const styleColor = !isEmpty(color)? { color } : {};  

    return (
      <>
        <div className={`date-picker cars-dates cars-driver cars-search-filters`}>
          <div className="date-picker__time-selection">
            <div className="date-picker__wrapper cars-time bordered-left category-selection disable-error" style={style}>
              <div className="date-picker__left">
                <div className="date-picker__icon" style={styleColor}>
                  <FontAwesomeIcon icon={faTags} />
                </div>
              </div>
              <div className="date-picker__right">
                <div className="date-picker__label">
                  <FormattedMessage id="cars.filter.car_type" />
                </div>
                <div className="date-picker__header">
                  <Select
                    showSearch={true}
                    placeholder={'Car type'}
                    value={get(simpleTypeQuick, '[0]', null)}
                    onChange={this.onSimpleTypeChange}
                    allowClear
                  >
                    {CAR_TYPES.map((t, i) => (<Select.Option key={`categories_${t}_${i}`} value={t}><FormattedMessage id={`cars.filter.quick.${t}`} /></Select.Option>))}
                  </Select>
                </div>
              </div>
            </div>

            <div className={`date-picker__wrapper cars-time fuel-selection disable-error`} style={style}>
              <div className="date-picker__left">
                <div className="date-picker__icon" style={styleColor}>
                  <FontAwesomeIcon icon={faGasPump} />
                </div>
              </div>
              <div className="date-picker__right">
                <div className="date-picker__label">
                  <FormattedMessage id="cars.filter.fuel" />
                </div>
                <div className="date-picker__header">
                  <Select
                    showSearch={true}
                    placeholder={'Fuel'}
                    value={get(fuelQuick, '[0]', null)}
                    onChange={this.onFuelChange}
                    allowClear
                  >
                    {FUEL.map((t, i) => (<Select.Option key={`categories_${t}_${i}`} value={t}><FormattedMessage id={`cars.filter.quick.${t}`} /></Select.Option>))}
                  </Select>
                </div>
              </div>
            </div>

            <div className={`date-picker__wrapper cars-time rental-selection disable-error`} style={style}>
              <div className="date-picker__left">
                <div className="date-picker__icon" style={styleColor}>
                  <FontAwesomeIcon icon={faCar} />
                </div>
              </div>
              <div className="date-picker__right">
                <div className="date-picker__label">
                  <FormattedMessage id="cars.filter.rental_company" />
                </div>
                <div className="date-picker__header">
                  <Select
                    showSearch={true}
                    placeholder={'Car Rental company'}
                    value={get(rentalCompanyQuick, '[0]', null)}
                    onChange={this.onRentalCompanyChange}
                    allowClear
                  >
                    {CAR_RENTALS.map((t, i) => (<Select.Option key={`car_rental_${t}_${i}`} value={t}>{t}</Select.Option>))}
                  </Select>
                </div>
              </div>
            </div>

            <div className={`date-picker__wrapper cars-time distance-selection bordered disable-error`} style={style}>
              <div className="date-picker__left">
                <div className="date-picker__icon" style={styleColor}>
                  <FontAwesomeIcon icon={faArrowsLeftRightToLine} />
                </div>
              </div>
              <div className="date-picker__right">
                <div className="date-picker__label">
                  <FormattedMessage id="cars.filter.rate_distance" />
                </div>
                <div className="date-picker__header">
                  <Select
                    showSearch={true}
                    placeholder={'Mileage'}
                    value={get(rateDistanceQuick, '[0]', null)}
                    onChange={this.onRateDistanceChange}
                    allowClear
                  >
                    {DISNTACE.map((t, i) => (<Select.Option key={`distance_${t}_${i}`} value={t}><FormattedMessage id={`cars.filter.quick.${t}`} /></Select.Option>))}
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    carsFiltersStore: state.carsFiltersStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  setSimpleType: (value: string[]) => {
    dispatch(carsFiltersActions.setSimpleTypeQuick(value));
  },
  setFuel: (value: string[]) => {
    dispatch(carsFiltersActions.setFuelQuick(value));
  },
  setRentalCompany: (value: string[]) => {
    dispatch(carsFiltersActions.setRentalCompanyQuick(value));
  },
  setRateDistance: (value: string[]) => {
    dispatch(carsFiltersActions.setRateDistanceQuick(value));
  },
});

export const CarsSearchFilters = connect(mapStateToProps, mapDispatchToProps)(CarsSearchFiltersComponent);
