
import React from 'react';

import { withRouter, RouteComponentProps } from 'react-router-dom';

import { connect } from 'react-redux';

import { ICar, IImage } from '@share/common-types';
import { CarouselImages } from '@components';

import { NoImageSvg } from '@assets';

import './style.scss';

interface IProps extends RouteComponentProps {
  car: ICar;
}

class CarsCardImageComponent extends React.Component<IProps> {

  shouldComponentUpdate(nextProps: Readonly<IProps>): boolean {
    const { car } = nextProps;
    const { vehicle } = car;
    
    const image = vehicle?.pictureURL;
    const filterdImages = image ? [{ url: image } as IImage] : [];

    if(filterdImages?.length) {
      return false;
    }
    return true;
  }

  render(): React.ReactNode {
    const { car } = this.props;
    const { vehicle } = car;
    
    const image = vehicle?.pictureURL;
    const filterdImages = image ? [{ url: image } as IImage] : [];

    if (!filterdImages?.length) {
      return (
        <div className="hotel-card-wrapper__no-image">
          <NoImageSvg />
        </div>
      );
    }

    return (
      <CarouselImages images={filterdImages} isNoCoverFit />
    );
  }
}

const CarsCardImageRouter = withRouter(CarsCardImageComponent);

export const CarsCardImage = connect()(CarsCardImageRouter);
