import React from 'react';

export const MaestroCardSvg: React.FunctionComponent = () => (
  <svg width="42" height="29" viewBox="0 0 42 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="41" height="28" rx="3.5" fill="white" stroke="#C5C5C5" />
    <path d="M24.8333 18.9125H17.6528V6.09375H24.8333V18.9125Z" fill="#6C6BBD" />
    <path
      d="M18.1086 12.5019C18.1086 9.90153 19.3342 7.58522 21.2428 6.0925C19.8471 5.00095 18.0857 4.34944 16.1713 4.34944C11.6393 4.34944 7.96558 7.99935 7.96558 12.5019C7.96558 17.0044 11.6393 20.6543 16.1713 20.6543C18.0857 20.6543 19.8471 20.0028 21.2428 18.9112C19.3342 17.4185 18.1086 15.1022 18.1086 12.5019Z"
      fill="#EB001B"
    />
    <path
      d="M34.5204 12.5019C34.5204 17.0044 30.8467 20.6543 26.3147 20.6543C24.4004 20.6543 22.6389 20.0028 21.2427 18.9112C23.1518 17.4185 24.3774 15.1022 24.3774 12.5019C24.3774 9.90153 23.1518 7.58522 21.2427 6.0925C22.6389 5.00095 24.4004 4.34944 26.3147 4.34944C30.8467 4.34944 34.5204 7.99935 34.5204 12.5019Z"
      fill="#0099DF"
    />
    <path
      d="M27.2628 22.5331C27.3583 22.5331 27.4955 22.5512 27.6004 22.5922L27.4543 23.0358C27.3541 22.9949 27.2539 22.9814 27.1579 22.9814C26.848 22.9814 26.693 23.1805 26.693 23.5381V24.752H26.2188V22.5875H26.6883V22.8503C26.8115 22.6601 26.9894 22.5331 27.2628 22.5331Z"
      fill="#231F20"
    />
    <path
      d="M25.5107 23.018H24.7358V23.996C24.7358 24.2132 24.8131 24.3583 25.0505 24.3583C25.1736 24.3583 25.3286 24.3174 25.4694 24.236L25.6061 24.6387C25.4559 24.7429 25.219 24.8066 25.0139 24.8066C24.4531 24.8066 24.2574 24.5076 24.2574 24.0048V23.018H23.8149V22.5878H24.2574V21.9311H24.7358V22.5878H25.5107V23.018Z"
      fill="#231F20"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.031 22.5332C20.642 22.5332 21.0615 22.995 21.0662 23.6698C21.0662 23.7326 21.0616 23.7907 21.0569 23.8493L21.0568 23.8507H19.4388C19.5072 24.24 19.7853 24.3804 20.0905 24.3804C20.3091 24.3804 20.5418 24.2991 20.7239 24.1539L20.9566 24.5028C20.6921 24.7246 20.3916 24.806 20.0634 24.806C19.4112 24.806 18.9463 24.3576 18.9463 23.6698C18.9463 22.995 19.3929 22.5332 20.031 22.5332ZM20.0221 22.9531C19.6846 22.9531 19.4936 23.1661 19.443 23.4786H20.5736C20.5236 23.1433 20.3274 22.9531 20.0221 22.9531Z"
      fill="#231F20"
    />
    <path
      d="M23.1491 23.1403C23.0171 23.0584 22.7484 22.9542 22.4703 22.9542C22.2105 22.9542 22.0555 23.0496 22.0555 23.2082C22.0555 23.3528 22.2194 23.3937 22.4244 23.4207L22.6477 23.4523C23.122 23.5207 23.409 23.7198 23.409 24.1002C23.409 24.5122 23.0443 24.8066 22.4155 24.8066C22.0597 24.8066 21.7315 24.7159 21.4717 24.5257L21.695 24.1588C21.8547 24.2811 22.0921 24.3853 22.4202 24.3853C22.7437 24.3853 22.917 24.2904 22.917 24.1225C22.917 24.0007 22.7938 23.9323 22.534 23.8965L22.3107 23.8649C21.8228 23.7965 21.5583 23.5793 21.5583 23.2263C21.5583 22.7961 21.9141 22.5334 22.4656 22.5334C22.8121 22.5334 23.1267 22.6106 23.3542 22.7599L23.1491 23.1403Z"
      fill="#231F20"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.465 22.6208C29.321 22.5622 29.1645 22.5332 28.9954 22.5332C28.8264 22.5332 28.6698 22.5622 28.5258 22.6208C28.3818 22.6788 28.2582 22.7592 28.1538 22.8613C28.0495 22.9634 27.9675 23.0842 27.9081 23.2231C27.8486 23.3625 27.8188 23.5138 27.8188 23.6771C27.8188 23.8404 27.8486 23.9917 27.9081 24.1311C27.9675 24.27 28.0495 24.3913 28.1538 24.4934C28.2582 24.5955 28.3818 24.6754 28.5258 24.7339C28.6698 24.792 28.8264 24.821 28.9954 24.821C29.1645 24.821 29.321 24.792 29.465 24.7339C29.609 24.6754 29.7337 24.5955 29.8386 24.4934C29.944 24.3913 30.0259 24.27 30.0854 24.1311C30.1449 23.9917 30.1746 23.8404 30.1746 23.6771C30.1746 23.5138 30.1449 23.3625 30.0854 23.2231C30.0259 23.0842 29.944 22.9634 29.8386 22.8613C29.7337 22.7592 29.609 22.6788 29.465 22.6208ZM28.7235 23.0312C28.8075 22.9975 28.8978 22.9804 28.9954 22.9804C29.0929 22.9804 29.1837 22.9975 29.2672 23.0312C29.3512 23.0654 29.4243 23.1131 29.4853 23.1742C29.5474 23.2354 29.5954 23.309 29.6309 23.3945C29.6658 23.48 29.6831 23.5744 29.6831 23.677C29.6831 23.7801 29.6658 23.8739 29.6309 23.9595C29.5954 24.045 29.5474 24.1186 29.4853 24.1797C29.4243 24.2409 29.3512 24.2886 29.2672 24.3228C29.1837 24.357 29.0929 24.3736 28.9954 24.3736C28.8978 24.3736 28.8075 24.357 28.7235 24.3228C28.6401 24.2886 28.5675 24.2409 28.5065 24.1797C28.4454 24.1186 28.3974 24.045 28.3625 23.9595C28.3275 23.8739 28.3103 23.7801 28.3103 23.677C28.3103 23.5744 28.3275 23.48 28.3625 23.3945C28.3974 23.309 28.4454 23.2354 28.5065 23.1742C28.5675 23.1131 28.6401 23.0654 28.7235 23.0312Z"
      fill="#231F20"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4377 22.5876V23.6698V24.7521H17.9639V24.4893C17.8131 24.6842 17.5856 24.8065 17.2757 24.8065C16.6647 24.8065 16.1863 24.3307 16.1863 23.6698C16.1863 23.0085 16.6647 22.5332 17.2757 22.5332C17.5856 22.5332 17.8131 22.6555 17.9639 22.8504V22.5876H18.4377ZM17.3346 22.9774C16.9245 22.9774 16.6736 23.29 16.6736 23.6704C16.6736 24.0508 16.9245 24.3629 17.3346 24.3629C17.7265 24.3629 17.991 24.0643 17.991 23.6704C17.991 23.2765 17.7265 22.9774 17.3346 22.9774Z"
      fill="#231F20"
    />
    <path
      d="M15.7669 24.752V23.3935C15.7669 22.8819 15.4387 22.5378 14.9101 22.5331C14.632 22.5284 14.3445 22.6145 14.1442 22.9182C13.9939 22.6782 13.7565 22.5331 13.4236 22.5331C13.1915 22.5331 12.9634 22.601 12.7855 22.8544V22.5875H12.3118V24.752H12.7902V23.5521C12.7902 23.1763 13 22.9768 13.3235 22.9768C13.6381 22.9768 13.7977 23.1805 13.7977 23.5474V24.752H14.2762V23.5521C14.2762 23.1763 14.4953 22.9768 14.8094 22.9768C15.1335 22.9768 15.2884 23.1805 15.2884 23.5474V24.752H15.7669V24.752Z"
      fill="#231F20"
    />
  </svg>
);
