

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RadioChangeEvent } from 'antd/lib/radio';
import { get } from 'lodash';
import { Radio } from 'antd';

import { RootState } from '@share/utils';
import { ILoginState, IMenuState, IMyProfileState } from '@share/store/slices';
import { IUserCard } from '@share/common-types';

import { CreditCardItem } from './credit-card-item';

import './style.scss';

interface IMapStateToProps {
  myProfileStore: IMyProfileState;
  menuStore: IMenuState;
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, RouteComponentProps, WrappedComponentProps {
  selectable?: boolean;
  hideDelete?: boolean;
  hideIfNoData?: boolean;
  isCheckout?: boolean;

  selectedCardId?: string;
  onSelectCard?: (selectedCard: IUserCard) => void;
}

function CreditCardsListComponent(props: IProps) {

  const { myProfileStore, loginStore, menuStore, selectable, hideDelete, hideIfNoData, isCheckout, selectedCardId, onSelectCard } = props;
  const { result, loadingCards } = myProfileStore;
  const { account } = loginStore;
  const { items } = menuStore;
  const { userCards } = result;

  const handleOnChange = (selectedValue: RadioChangeEvent) => {
    if (onSelectCard) {
      const selectedCard: any = get(userCards?.filter((c: any) => c?.id === selectedValue.target.value), '[0]');
      onSelectCard(selectedCard);
    }
  }

  if (hideIfNoData && !userCards?.length && !loadingCards) {
    return null;
  }

  return (
    <>
      <h4 className="payment-method__title secondary-font" style={{ marginBottom: '15px', marginTop: isCheckout ? '40px' : '0px' }}>
        <FormattedMessage id="available.cards" />
      </h4>

      <div style={{ marginBottom: isCheckout ? '0px' : '25px' }}>
        {userCards?.length ? (
          <div className="credit-cards-list__credit-card-container">
            {selectable ? (
              <Radio.Group value={selectedCardId} onChange={handleOnChange} disabled={loadingCards}>
                {userCards.map((card, index) => 
                  <Radio key={index} value={card?.id}>
                    <CreditCardItem card={card} hideDelete={hideDelete} />
                  </Radio>)}
              </Radio.Group>) : 
              userCards.map((card, index) => <CreditCardItem key={index} card={card} hideDelete={hideDelete} />)}
          </div>) : (
          <FormattedMessage id="available.cards.no_card" />)}
      </div>

      {(account?.enableInsurance && !items?.isRemoveInsurance) ? (
        <div style={{ marginBottom: isCheckout ? '0px' : '25px', marginTop: '10px' }}>
          <FormattedMessage id="insurance.saved_card" />
        </div>) : null}
    </>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    myProfileStore: state.myProfileStore,
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
  };
};

const ComponentIntl = injectIntl(CreditCardsListComponent);

export const CreditCardsList = connect(mapStateToProps)(withRouter(ComponentIntl));
