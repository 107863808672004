import { IPersonalDetails } from '@common-types';
import { IAccount } from '@share/common-types';
import { AMERICAN_EXPRESS_TYPE, DINNERS_TYPE, DISCOVER_TYPE, JCB_TYPE, MAESTRO_TYPE, MASTERCARD_TYPE, VISA_TYPE } from '@share/constants';
import { isEmpty } from 'lodash';

import { MaestroCardSvg, MasterCardSvg, VisaCardSvg, AmexCardSvg } from '@assets';

import Diners from '@assets/images/diners.png';
import Discover from '@assets/images/discover.png';
import Jcb from '@assets/images/jcb.png';
import OtherCard from '@assets/images/credit-card.png';
import React from 'react';

export const MERCHANT_CHARGE_DESCRIPTION_FIELD_KEY = 'merchantChargeDEscription';

export const updatePersonalInfo = (
  infoObject: IPersonalDetails,
  value: { [key: string]: string | number | boolean },
): IPersonalDetails => {
  const newInfo = {
    ...infoObject,
    ...value,
  };
  return newInfo;
};

export const GetPreferenceValue = (account: IAccount, key: string, defaultValue: any = null) => {
  if (!isEmpty(account?.preferences)) {
    const prefDB: any = account?.preferences.find(p => p.key === key);
    if (prefDB) {
      return prefDB.value;
    }
  }
  return defaultValue;
}

export const GetPreferenceValueParsed = (account: IAccount, key: string, defaultValue: any = null) => {
  const pref = GetPreferenceValue(account, key, defaultValue);
  if (pref) {
    return JSON.parse(pref);
  }
  return null;
}


export const GetCreditCardImage = (cardType: string, cardIsValid: boolean): JSX.Element => {
  if (cardIsValid) {
    if (cardType === MAESTRO_TYPE) { return <MaestroCardSvg />}
    if (cardType === MASTERCARD_TYPE) { return <MasterCardSvg />}
    if (cardType === VISA_TYPE) { return <VisaCardSvg />}
    if (cardType === AMERICAN_EXPRESS_TYPE) { return <AmexCardSvg />}
    if (cardType === DINNERS_TYPE) { return <img src={Diners} alt="" />}
    if (cardType === DISCOVER_TYPE) { return <img src={Discover} alt="" />}
    if (cardType === JCB_TYPE) { return <img src={Jcb} alt="" />}
    if (![MAESTRO_TYPE, MASTERCARD_TYPE, VISA_TYPE, AMERICAN_EXPRESS_TYPE, DINNERS_TYPE, DISCOVER_TYPE, JCB_TYPE].includes(cardType)) { return <img src={OtherCard} alt="" />}
  }
  return null;
}