export const Currencies = {
    USD: { title: 'US', symbol: '$' },
    CAD: { title: 'CA', symbol: '$' },
    MXN: { title: 'MXN', symbol: '$' },
    BRL: { title: 'R', symbol: '$' },
    CLP: { title: 'CL', symbol: '' },
    PAB: { title: '', symbol: 'B/.' },
    COP: { title: 'COP', symbol: '' },
    CRC: { title: '', symbol: '₡' },
    EUR: { title: '', symbol: '€' },
    GBP: { title: '', symbol: '£' },
    SEK: { title: '', symbol: 'kr' },
    CHF: { title: '', symbol: 'Fr' },
    CZK: { title: '', symbol: 'Kč' },
    DKK: { title: '', symbol: 'kr' },
    EGP: { title: '', symbol: '£' },
    ZAR: { title: '', symbol: 'R' },
    MAD: { title: 'MAD', symbol: '' },
    TRY: { title: '', symbol: '₺' },
    PHP: { title: '', symbol: '₱' },
    AED: { title: '', symbol: 'د.إ' },
    SGD: { title: 'S', symbol: '$' },
    THB: { title: '', symbol: '฿' },
    QAR: { title: '', symbol: '﷼' },
    JPY: { title: '', symbol: '¥' },
    CNY: { title: '', symbol: '¥' },
    HKD: { title: 'HK', symbol: '$' },
    INR: { title: '', symbol: '₹' },
    ILS: { title: '', symbol: '₪' },
    AUD: { title: 'AU', symbol: '$' },
    NZD: { title: 'NZD', symbol: '$' }
}