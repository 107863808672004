import React from 'react';

import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { FilterCheckbox } from '@components';
import { ILoginState, ICarsState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { IBudgetRange, ICounterItem } from '@share/common-types';
import { getFilterList, getFilterListB } from '@utils';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import './style.scss';

interface IMapStateToProps {
  carsStore: ICarsState;
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps, RouteComponentProps {
  counters: (ICounterItem | IBudgetRange)[];
  filters: string[];

  title: string;

  disabled?: boolean;
  exluceNonSelected?: boolean;
  displayAll?: boolean;
  displayZero?: boolean;

  orderByOptions?: boolean;

  getKeyConverter?: (key: any) => string;
  getLabelConverter?: (key: any) => any;

  onFiltersChange: (checkedValues: string[]) => void;
}

interface IState {
  isFullToShow: boolean;
}

const maxAmenitiesCount = 6;

class CarsFilterItemComponent extends React.Component<IProps, IState> {
  state: IState = {
    isFullToShow: false,
  };

  render(): React.ReactNode {
    const { isFullToShow } = this.state;
    const { carsStore, counters, filters, title, exluceNonSelected, disabled, displayZero, displayAll, orderByOptions, getKeyConverter, getLabelConverter } = this.props;
    const { isSessionExpired } = carsStore;

    let countersToShow: (ICounterItem | IBudgetRange)[];

    let isShow;

    if (!!counters) {
      countersToShow = getFilterListB(counters, filters, isFullToShow || displayAll, exluceNonSelected);

      if (orderByOptions) {
        countersToShow = countersToShow.slice();
        countersToShow.sort((a, b) => b.count - a.count);
      }
      
      isShow = !!counters.filter(({ count }) => !!count).length;
    }
    
    if (!isShow || (counters?.length === 1 && counters?.map(({ key}) => getKeyConverter ? getKeyConverter(key) : key as string).includes('Unspecified'))) {
      return null;
    }

    return (
      <>
        <FilterCheckbox
          disabled={isSessionExpired || disabled}
          onChange={this.props.onFiltersChange}
          values={filters}
          displayZero={displayZero}
          options={countersToShow?.map(({ count, key }) => {
            return {
              count,
              value: getKeyConverter ? getKeyConverter(key) : key as string,
              label: getLabelConverter ? getLabelConverter(key) : <span>{key}</span>,
            };
          })}
          title={title}
        />

        {!displayAll && !isSessionExpired && counters?.length > maxAmenitiesCount && (
          <div className="result-wrapper__show-link" onClick={() => this.setState({ isFullToShow: !isFullToShow })}>
            {isFullToShow ? (
              <FormattedMessage id="show.less" />) : (
              <FormattedMessage id="show.more" />)}
          </div>)}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    carsStore: state.carsStore,
    loginStore: state.loginStore,    
  };
};

export const CarsFilterItem = connect(mapStateToProps)(injectIntl(withRouter(CarsFilterItemComponent)));
