import React, { ChangeEvent } from 'react';
import { Modal, Input } from 'antd';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { WhiteButton } from '@components';
import { BlueButton } from '@share/components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IProps extends WrappedComponentProps {
  visible: boolean;
  refundable: boolean;
  cancellationPolicesText: string;
  onCancel: (comment: string) => void;
  onClose: () => void;
}

interface IState {
  note: string;
}

const rows = 5;
const oneHundred = 1000;
const modalWidth = 450;
const { TextArea } = Input;

class CancelModalComponent extends React.Component<IProps, IState> {
  state: IState = {
    note: '',
  };

  onNoteChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      note: e.target.value,
    });
  };

  render(): React.ReactNode {
    const { visible, refundable, cancellationPolicesText, onCancel, onClose, intl } = this.props;

    return (
      <div className="cancel-modal">
        <Modal
          className="cancel-modal-wrapper"
          wrapClassName="cancel-modal-wrapper__wrap"
          visible={visible}
          footer={null}
          onCancel={onClose}
          closable={true}
          destroyOnClose={true}
          closeIcon={<FontAwesomeIcon icon={faTimes} />}
          width={modalWidth}
          title={<FormattedMessage id="mark.as.cancelled" />}
        >
          <div className="cancel-modal-wrapper__error">
            <FontAwesomeIcon icon={faInfoCircle} /> <FormattedMessage id="this.action.cannot.be.undone" />          
          </div>
          <div className="cancel-modal-wrapper__main">   
            <div className="cancel-modal-wrapper__area">
              <strong style={{ marginRight: '5px' }}>Cancellation Policy: </strong>
              {cancellationPolicesText}
            </div>         
            <div className="cancel-modal-wrapper__label">
              <FormattedMessage id="add.note" />
            </div>
            <div className="cancel-modal-wrapper__area">              
              <TextArea
                showCount={true}
                maxLength={oneHundred}
                value={this.state.note}
                onChange={this.onNoteChange}
                placeholder={intl.formatMessage({ id: 'enter.text.here' })}
                rows={rows}
              />
            </div>
          </div>
          <div className="cancel-modal-wrapper__footer">
            <WhiteButton onClick={onClose}>
              <FormattedMessage id="cancel" />
            </WhiteButton>
            <BlueButton onClick={() => onCancel(this.state.note)}>
              {refundable ? <FormattedMessage id="confirm.and.refund" /> : <FormattedMessage id="confirm.cancellation" />}
            </BlueButton>
          </div>
        </Modal>
      </div>
    );
  }
}

export const CancelModal = injectIntl(CancelModalComponent);
