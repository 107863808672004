import React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { selectMemberVideos, selectMemberVideosByFileName } from '@store/slices';
import { IVideoMedia } from '@api';
import { RootState } from '@share/utils';
import { Routes } from '@share/constants';
import { CarouselSlider, Video } from '@components';
import './style.scss';
import { ILoginState } from '@share/store/slices';

const SLIDES_TO_SHOW = 4;
const linkStyle: React.CSSProperties = { display: 'block' };

interface IMapStateToProps {
  videos: IVideoMedia[];
  memberVideo: IVideoMedia;
  loginStore: ILoginState;
}

interface IParams {
  path: string;
}

interface IState {
  isLoaded: boolean;
}

interface IProps extends IMapStateToProps, RouteComponentProps<IParams> {}

class MemberVideosCarouselComponent extends React.Component<IProps, IState> {
  state: IState = {
    isLoaded: false,
  };

  getSearchParamsPath = () => {
    return new URLSearchParams(this.props.location.search).get('path');
  };

  setLoaded = () => {
    this.setState({ isLoaded: true });
  };

  render(): React.ReactNode {
    const { isLoaded } = this.state;
    const { videos, memberVideo } = this.props;
    const videoFileName = this.getSearchParamsPath() || memberVideo?.fileName;

    const { loginStore } = this.props;
    const { account } = loginStore;

    return (
      <div className="member-videos-carousel">
        <CarouselSlider lazyLoad="progressive" slidesToShow={SLIDES_TO_SHOW}>
          {videos.map(({ title, fileName, filePath }) => {
            const isActivated = fileName === videoFileName;

            return (
              <div
                key={fileName}
                className={`member-videos-carousel__item custom-carousel__item${
                  isActivated && isLoaded ? '_activated' : ''
                }`}
              >
                <Link
                  className="member-videos-carousel__link"
                  style={linkStyle}
                  to={{
                    pathname: `/${account?.name}${Routes.MemberVideos}`,
                    search: `?fileName=${fileName}`,
                  }}
                >
                  <Video src={filePath} canPlay={!isActivated} onLoaded={this.setLoaded} />
                </Link>
                <p className="member-videos-carousel__title">{title}</p>
              </div>
            );
          })}
        </CarouselSlider>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    videos: selectMemberVideos(state),
    memberVideo: selectMemberVideosByFileName(state),
    loginStore: state.loginStore
  };
};

export const MemberVideosCarousel = connect(mapStateToProps)(
  withRouter(MemberVideosCarouselComponent),
);
