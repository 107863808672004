
import React, { useMemo } from 'react';

import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

import { renderNumber } from '@share/utils';

import './style.scss';
import { Loading, LoadingSise } from '@share/components';

export enum HeaderItemType {
  CURRENCY,
  PERCENTAGE,
  NUMBER,
}

export enum HeaderItemColor {
  GREEN,
  BLUE,
}

interface IProps {
  icon: any;
  title: string;
  value: number;
  type: HeaderItemType;
  color: HeaderItemColor;
  loading: boolean;
}

function DashboardReportHeaderItemComponent(props: IProps) {
  const { icon, title, value, type, color, loading } = props;

  const getValue = () => {
    if (type === HeaderItemType.CURRENCY) {
      return `$ ${renderNumber(value, 2)}`;
    } else if (type === HeaderItemType.PERCENTAGE) {
      return `${renderNumber(value, 0)} %`;
    } else {
      return renderNumber(value, 0);
    }
  }

  const calculatedValue = useMemo(() => getValue(),[value, type]);

  if (loading) {
    return (
      <div className="dashboard-report-header-item-wrapper centered">
        <Loading size={LoadingSise.Normal} />
      </div>);
  }

  return (
    <div className="dashboard-report-header-item-wrapper">
      <div className={`dashboard-report-header-item-wrapper__icon ${color === HeaderItemColor.GREEN ? 'green' : 'blue'}`}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className="dashboard-report-header-item-wrapper__content">
        <label className="content-value">{calculatedValue}</label>
        <label className="content-text"><FormattedMessage id={title} /></label>
      </div>
    </div >
  );
}

export const DashboardReportHeaderItem = connect()(DashboardReportHeaderItemComponent);
