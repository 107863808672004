
import React from 'react';
import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';

import { ILoginState } from '@share/store/slices';
import { RootState } from '@share/utils';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {
  text: string;
  title?: string;
  
  disableTitleLocalization?: boolean;
  disableTextLocalization?: boolean;
  textValues?: any;

  visible: boolean;

  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>, isScrollToRooms?: boolean) => void;
}

function ModalMessageComponent(props: IProps) {

  const {
    text,
    textValues,
    title,
    visible,
    disableTitleLocalization,
    disableTextLocalization,
    onCancel,
    loginStore
  } = props;
  const { account } = loginStore;

  const values = textValues ? { ...textValues } : {};

  const fontFamily = account?.fontFamily;
  const secondaryFontFamily = account?.secondaryFontFamily;

  return (
    <Modal
      className={`modal-message ${fontFamily ? `${fontFamily}-font`: ''} ${secondaryFontFamily ? `secondary-${secondaryFontFamily}-font`: ''}`}
      open={visible}
      footer={null}
      onCancel={onCancel}
      wrapClassName="modal-message__wrapper"
    >
      <div>
        {!isEmpty(title) && (
          <h4 className="modal-message__title secondary-font">
            {disableTitleLocalization ? title : (<FormattedMessage id={title} />)}
          </h4>)}

        <div>{disableTextLocalization ? text : (<FormattedMessage id={text} values={values} />)}</div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

export const ModalMessage = connect(mapStateToProps)(ModalMessageComponent);
