
import React from 'react';

import { connect } from 'react-redux';

import { RootState } from '@share/utils';

import { IDashboardReportState } from '@store/slices';

import { ColumnType } from '../dashboard-report-table';
import { DashboardReportTopBase } from '../dashboard-report-top-base';

import './style.scss';

interface IMapStateToProps {
  dashboardReportsStore: IDashboardReportState;
}

interface IProps extends IMapStateToProps {}

function DashboardReportTopMembersComponent(props: IProps) {
  const { dashboardReportsStore } = props;
  const { loading } = dashboardReportsStore;
  
  const topMembersHomes = dashboardReportsStore.dashBoardData?.topMembersHomes;

  return (
    <DashboardReportTopBase title="top.travelers" topList={topMembersHomes} loading={loading} header={[
      { text: 'leadName', width: 45, type: ColumnType.TEXT },
      { text: 'numberOfBookings', width: 25, type: ColumnType.NUMBER },
      { text: 'totalSpent', width: 30, type: ColumnType.CURRENCY }]} />
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    dashboardReportsStore: state.dashboardReportsStore
  };
};

export const DashboardReportTopMembers = connect(mapStateToProps)(DashboardReportTopMembersComponent);
