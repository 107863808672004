
import { FormattedMessage } from 'react-intl';
import { RedCheckMarkSvg } from '@assets';
import './style.scss';

interface IProps {
  header: string;
  description: string;
}

export function CondoBookingFailedWarning(props: IProps) {
  const { description, header } = props;

  return (
    <div className="condo-booking-failed-warning">
      <div className="condo-booking-failed-warning__icon">
        <RedCheckMarkSvg />
      </div>
      <div className="condo-booking-failed-warning__text">
        <div className="condo-booking-failed-warning__header">
          <FormattedMessage id={header} />
        </div>
        <div className="condo-booking-failed-warning__description">
          <FormattedMessage id={description} />
        </div>
      </div>
    </div>
  );
}
