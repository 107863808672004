
import { useCallback, useMemo } from 'react';

import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';
import { connect } from 'react-redux';
import { enUS } from 'date-fns/esm/locale';

import { IAccount, IRating } from '@share/common-types';
import { DATE_FORMAT_CHECK_IN_OUT_YEAR, MINIMUM_RATING_TO_SHOW } from '@constants';
import { getCondoTextRating } from '@utils';
import { getReserveUnitUrl, getSelectedCurrency } from '@share/utils';
import {
  creatingArrayWithNumber,
  formatDateCheckInOut,
  getRankingClass,
  renderNumber,
  RootState,
} from '@share/utils';
import { InfoSvg, ManDarkSvg, NoImageSvg } from '@assets';
import { BlueButton } from '@share/components';
import { ILoginState } from '@share/store/slices';
import { Currency, HotelStars } from '@components';

import { RefundableEnum } from '@common-types';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {
  imageUrl: string;
  data?: {
    title: string;
    address: string;
    unitName: string;
    unitDescription?: string;
    maxOccupancy?: number;
    adultsCount: number;
    childrenCount: number;
    bedroomsCount: number;
    bathroomsCount: number;
    checkIn: string;
    checkInTime?: string;
    checkOut: string;
    checkOutTime?: string;
    nightsCount: number;
    condoId?: number;
    unitId?: string;
    price?: number;
    isNotification?: boolean;
    bookingComplete?: boolean;
    isModalInfo?: boolean;
    rating?: IRating;
    supplierIdentifier?: string;
    walletPrices?: any;
    refundability?: string;
  }
  isAllInclusive?: boolean;
  savingsPercent?: number;
  hideImage?: boolean;
  renderOnlyImage?: boolean;
}

const ZERO = 0;
const ONE = 1;

function UnitMainInfoComponent(props: IProps) {

  const { imageUrl, data, hideImage, renderOnlyImage, isAllInclusive, savingsPercent, loginStore } = props;
  const { account } = loginStore;

  const refundability = data?.refundability;
  const title = data?.title;
  const address = data?.address;
  const unitDescription = data?.unitDescription;
  const maxOccupancy = data?.maxOccupancy;
  const checkIn = data?.checkIn;
  const checkOut = data?.checkOut;
  const nightsCount = data?.nightsCount;
  const condoId = data?.condoId;
  const unitId = data?.unitId;
  const price = data?.price;
  const checkInTime = data?.checkInTime;
  const checkOutTime = data?.checkOutTime;
  const isModalInfo = data?.isModalInfo;
  const rating = data?.rating;
  const supplierIdentifier = data?.supplierIdentifier;
  const unitName = data?.unitName;
  const walletPrices = data?.walletPrices;

  const isRefundable = refundability === RefundableEnum.Refundable;

  const displayWalletSavings = account?.walletWalletSavings && (walletPrices?.priceSavings > 0 || walletPrices?.maxClientCashAmountAllow > 0) && !!walletPrices?.maxClientCashAmountAllow;

  const renderImage = () => {
    if (hideImage && !renderOnlyImage) {
      return null;
    }

    return imageUrl?.length !== ZERO ? (
      <div className="unit-main-info__unit-image print">
        <img className="unit-main-info__unit-img print" src={imageUrl} alt={unitName} />

        {isAllInclusive ? (
          <div className="condo-hotel-card__label-all-inclusive">
            <p className="condo-hotel-card__label-title">
              <FormattedMessage id="all.inclusive" />
            </p>
          </div>) : null}

        {(savingsPercent && savingsPercent > ZERO && !displayWalletSavings) ? (
          <div className={`condo-hotel-card__label ${isAllInclusive ? 'two-label' : ''}`}>
            <p className="condo-hotel-card__label-title">
              <FormattedMessage id="save" /> {savingsPercent}%
            </p>
          </div>) : null}

        {displayWalletSavings ? (
          <div className={`condo-hotel-card__label ${isAllInclusive ? 'two-label' : ''}`}>
            <p className="condo-hotel-card__label-title">
              <FormattedMessage id="save.up.to" values={{ number: walletPrices?.priceSavings ? walletPrices?.priceSavings : '<1' }} />
            </p>
          </div>) : null}

      </div>) : (
      <div className="unit-info__unit-img no-image">
        <NoImageSvg />

        {isAllInclusive ? (
          <div className="condo-hotel-card__label-all-inclusive">
            <p className="condo-hotel-card__label-title">
              <FormattedMessage id="all.inclusive" />
            </p>
          </div>) : null}

        {(savingsPercent && savingsPercent > ZERO && !displayWalletSavings) ? (
          <div className={`condo-hotel-card__label ${isAllInclusive ? 'two-label' : ''}`}>
            <p className="condo-hotel-card__label-title">
              <FormattedMessage id="save" /> {savingsPercent}%
            </p>
          </div>) : null}

        {displayWalletSavings ? (
          <div className={`condo-hotel-card__label ${isAllInclusive ? 'two-label' : ''}`}>
            <p className="condo-hotel-card__label-title">
              <FormattedMessage id="save.up.to" values={{ number: walletPrices?.priceSavings ? walletPrices?.priceSavings : '<1' }} />
            </p>
          </div>) : null}

      </div>);
  }

  const renderRefundability = useCallback(() => {
    return (
      <div className="unit-main-info__refundable" style={{ display: 'inline-block' }}>
        <div className={isRefundable ? 'unit-main-info__refundable-ref' : 'unit-main-info__refundable-nonref'} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>
            <FormattedMessage id={isRefundable ? 'refundable' : 'non.refundable'} />
          </span>
          <InfoSvg />
        </div>
      </div>);
  }, [isRefundable]);
  
  const showRating = rating && rating?.value > ZERO;
  
  // For now we for english
  const locale = enUS;//getAccountDateFnsLocale(account);

  const currency = useMemo(() => getSelectedCurrency(account as IAccount), [account]);
  const rankingClass = useMemo(() => getRankingClass(rating?.value as number), [rating?.value]);

  return (
    <div className="unit-main-info">
      {renderImage()}
      {!renderOnlyImage ? (
        <div className="unit-main-info__content">
          <p className="unit-main-info__supplier">{supplierIdentifier ? <FormattedMessage id={`suppliers.${supplierIdentifier}`} /> : null}</p>
          <p className="unit-main-info__condo-name secondary-font">{title}</p>
          <p className="unit-main-info__address">{address}</p>
          {!isModalInfo ? (

            <div className="unit-main-info__link-reviews">
              {showRating ? <HotelStars stars={1} /> : null}
              <span className={`unit-main-info__trust-you-text ${rankingClass}`} style={{ marginLeft: '5px' }}>
                {showRating ? renderNumber(rating.value, ONE) : ''}
              </span>
              {rating?.reviewsCount ? (
                <p className="unit-main-info__trust-you-text" style={{ paddingTop: '4px' }}>
                  ({renderNumber(rating.reviewsCount)} <FormattedMessage id="reviews" />)
                </p>
              ) : showRating && rating.value >= MINIMUM_RATING_TO_SHOW ? (
                <p className="unit-main-info__trust-you-text" style={{ paddingTop: '4px' }}>
                  <FormattedMessage
                    id={getCondoTextRating(Number(renderNumber(rating.value, ONE)))}
                  />
                </p>
              ) : null}
            </div>
          ) : null}
          <div className="unit-main-info__unit-info">
            <div className="unit-main-info__unit-name-wrapper">
              <Tooltip title={unitName} placement="topLeft">
                <p className="unit-main-info__unit-name capitalize">{unitName}</p>
              </Tooltip>
              {(maxOccupancy && maxOccupancy > ZERO) && (
                <div className="unit-main-info__guests">
                  {creatingArrayWithNumber(maxOccupancy, ONE).map((_value, i) => {
                    return (
                      <span className="unit-main-info__icon-wrapper" key={i}>
                        <ManDarkSvg />
                      </span>
                    );
                  })}
                </div>
              )}
            </div>
            <p className="unit-main-info__unit-info-description lowercase">{unitDescription}</p>
          </div>

          <div className="unit-main-info__period">
            {checkIn && checkOut && (
              <>
                <div className="unit-main-info__period-item">
                  <p className="unit-main-info__period-item-title">
                    <FormattedMessage id="check.in" />:
                  </p>
                  <p className="unit-main-info__period-item-description">
                    {formatDateCheckInOut(checkIn, DATE_FORMAT_CHECK_IN_OUT_YEAR, locale)}
                    {checkInTime ? (
                      <>
                        {', '}
                        {checkInTime}
                      </>
                    ) : null}
                  </p>
                </div>
                <div className="unit-main-info__period-item">
                  <p className="unit-main-info__period-item-title">
                    <FormattedMessage id="check.out" />:
                  </p>
                  <p className="unit-main-info__period-item-description">
                    {formatDateCheckInOut(checkOut, DATE_FORMAT_CHECK_IN_OUT_YEAR, locale)}
                    {checkOutTime ? (
                      <>
                        {', '}
                        <FormattedMessage id="till" /> {checkOutTime}
                      </>
                    ) : null}
                  </p>
                </div>
              </>)}
              <div className="unit-main-info__period-item">
                <p className="unit-main-info__period-item-title">
                  <FormattedMessage id="nights.title" />:
                </p>
                <p className="unit-main-info__period-item-description">
                  <FormattedMessage id="nights" values={{ count: nightsCount }} />
                </p>
              </div>
          </div>
          
          {(price !== ZERO && isModalInfo) ? (
            <div className="unit-main-info__price-block">
              <div className="unit-main-info__price-total">
                {renderRefundability()}

                <p className="unit-main-info__price-total-text">
                  <Currency currency={currency} />{renderNumber(price as number, 2)}
                </p>

                <div className="unit-main-info__for-info">
                  <span className="unit-main-info__price-includes-taxes">
                    <FormattedMessage id="abbreviated.price.includes.taxes" />
                  </span>
                </div>
              </div>

              <div className="unit-main-info__action">
                <Link to={getReserveUnitUrl(account?.name as string, condoId as number, unitId as string)}>
                  <BlueButton>
                    <FormattedMessage id="reserve" />
                  </BlueButton>
                </Link>
              </div>
            </div>
          ) : (
            <div className="unit-main-info__price-block">
              <div className="unit-main-info__price-total">
                {renderRefundability()}
              </div>
            </div>
          )}
        </div>) : null }
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

export const UnitMainInfo = connect(mapStateToProps)(UnitMainInfoComponent);

