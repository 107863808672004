import React from 'react';
import { FormattedMessage } from 'react-intl';
import ConciergeTeamImageLarge from '@assets/images/concierge-team-large.png';
import './style.scss';

interface IProps {
  phone: string;
}

export class HomesConciergeServices extends React.Component<IProps> {
  render(): React.ReactNode {
    const { phone } = this.props;

    return (
      <div className="homes-concierge-services">
        <div className="homes-concierge-services__wrapper">
          <div className="homes-concierge-services__info-wrapper">
            <div className="homes-concierge-services__text-wrapper">
              <p className="homes-concierge-services__title secondary-font">
                <FormattedMessage id="treat.yourself.book.with.our.team" />
                {': '}
                <a href={`tel:${phone}`} className="homes-concierge-services__contact-phone secondary-font">
                  {phone}
                </a>
              </p>
              <p className="homes-concierge-services__title-small">
                <FormattedMessage id="your.award.winning.travel" />
              </p>
              <p className="homes-concierge-services__description">
                <FormattedMessage id="travel.partners.and.cruise.lines" />
              </p>
            </div>
          </div>
          <div className="homes-concierge-services__img-wrapper">
            <img src={ConciergeTeamImageLarge} alt="Concierge team" />
          </div>
        </div>
      </div>
    );
  }
}
