import React from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Responsive } from '@share/utils';
import { WeeksReviewBookWrapper } from '@components';

import './style.scss';

interface IProps extends RouteComponentProps {}

class WeeksReviewBookPageComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <div className="weeks--details-page">
        <Responsive>
          <WeeksReviewBookWrapper />
        </Responsive>
      </div>
    );
  }
}

export const WeeksReviewBookPage = connect()(withRouter(WeeksReviewBookPageComponent));
