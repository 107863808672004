
import { IWeeksLocationsState, IWeeksDatesState, IWeeksFiltersState } from '@share/store/slices';
import { getSeparatedPlace } from './condo';
import { ICondoGuests, IWeeksDetailsRequest } from '@share/common-types';

export const GetWeeksFilters = (filtersStore: IWeeksFiltersState) => {
  const {
    propertyName,
    budgetRanges,
    guestRatings,
    distanceInMiles,
    unitAmenities,
    facilities,
    activities,
    condoTypes,
    policies,
    accessibilities,
    suppliers,
    neighbourhoods,
    places,
    bathrooms,
    days,
    months,
    kitchenTypes,
    isAllInclusiveOnly,
  } = filtersStore;

  const filters: any = { propertyName: '' };

  if (budgetRanges?.length) {
    filters.budgetRanges = budgetRanges.map((range: string) => {
      const [from, to]: string[] = range.split('-');
      return { from: +from || null, to: +to || null };
    });
  }
  if (guestRatings?.length) {
    filters.guestRatings = guestRatings.map((range: string) => {
      const [from, to]: string[] = range.split('-');
      return { from: +from || null, to: +to || null };
    });
  }
  if (distanceInMiles) {
    filters.distanceInMiles = distanceInMiles;
  }
  if (unitAmenities?.length) {
    filters.unitAmenities = unitAmenities;
  }

  if (facilities?.length) {
    filters.facilities = facilities;
  }
  if (activities?.length) {
    filters.activities = activities;
  }
  if (condoTypes?.length) {
    filters.condoTypes = condoTypes;
  }
  if (policies?.length) {
    filters.policies = policies;
  }
  if (accessibilities?.length) {
    filters.accessibilities = accessibilities;
  }
  if (suppliers?.length) {
    filters.suppliers = suppliers;
  }
  if (neighbourhoods?.length) {
    filters.neighbourhoods = neighbourhoods;
  }
  if (bathrooms?.length) {
    filters.bathrooms = bathrooms;
  }

  if (places?.length) {
    filters.places = places.map((place) => getSeparatedPlace(place));
  }

  if (days?.length) {
    filters.days = days;
  }
  if (months?.length) {
    filters.months = months;
  }
  if (kitchenTypes?.length) {
    filters.kitchenTypes = kitchenTypes;
  }

  if (isAllInclusiveOnly) {
    filters.isAllInclusiveOnly = isAllInclusiveOnly;
  }

  return filters;
};

export const isWeeksDataValid = (weeksLocationsStore: IWeeksLocationsState, weeksDatesStore: IWeeksDatesState): {
  isDatesValid: boolean;
  isLocationValid: boolean;
  isPeriodValid: boolean;
  isValid: boolean;
} => {
  const res = {
    isDatesValid: true,
    isLocationValid: true,
    isPeriodValid: true,
    isValid: true,
  };

  if (!weeksLocationsStore.selectedLocation) {
    res.isLocationValid = false;
    res.isValid = false;
  }

  if (!weeksDatesStore.dateSelected) {
    res.isDatesValid = false;
    res.isValid = false;
  }

  if (!weeksDatesStore.periodSelected) {
    res.isPeriodValid = false;
    res.isValid = false;
  }
  
  return res;
};
