import React from 'react';
import { Skeleton } from 'antd';
import { PlaySvg } from '@assets';
import './style.scss';

interface IProps {
  src: string;
  controls?: boolean;
  loop?: boolean;
  canPlay?: boolean;
  onPlayVideo?: () => void;
  onPause?: () => void;
  onLoaded?: () => void;
  videoRef?: React.RefObject<HTMLVideoElement>;
}

interface IState {
  isLoaded: boolean;
  isVideoPlaying: boolean;
}

const CONTROLS = false;
const LOOP = false;
const CAN_PLAY = true;

export class Video extends React.Component<IProps, IState> {
  state: IState = {
    isLoaded: false,
    isVideoPlaying: false,
  };

  videoRef: React.RefObject<HTMLVideoElement>;

  constructor(props: IProps) {
    super(props);
    this.videoRef = props.videoRef || React.createRef<HTMLVideoElement>();
  }

  setLoaded = (): void => {
    this.setState({ isLoaded: true });
    this.props.onLoaded?.();
  };

  playVideo = async (): Promise<void> => {
    if (!this.state.isVideoPlaying) {
      await this.videoRef.current?.play();
      this.setState({ isVideoPlaying: true });
      this.props.onPlayVideo?.();
    }
  };

  pauseVideo = (): void => {
    this.setState({ isVideoPlaying: false });
    this.props.onPause?.();
  };

  render(): React.ReactNode {
    const { isLoaded, isVideoPlaying } = this.state;
    const { src, videoRef, canPlay = CAN_PLAY, controls = CONTROLS, loop = LOOP } = this.props;

    return (
      <div className="video">
        {!isLoaded && <Skeleton className="video__skeleton" active paragraph={false} />}
        <video
          width="100%"
          height="100%"
          ref={videoRef}
          loop={loop}
          controls={controls && isVideoPlaying}
          onPause={this.pauseVideo}
          onLoadedData={this.setLoaded}
        >
          <source src={src} />
        </video>
        {canPlay && !isVideoPlaying && isLoaded && (
          <button className="video__play-btn" onClick={this.playVideo}>
            <PlaySvg />
          </button>
        )}
      </div>
    );
  }
}
