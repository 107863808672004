
export enum VideoExtensionEnum {
  MOV = 'mov',
  MP4 = 'mp4',
}

export const SECOND_CHARACTER_POSITION = 2;
export const TENTH_CHARACTER_POSITION = 10;
export const CONDO_SUPPORT_EMAIL = 'condosupport@membergetaways.com';
export const SPECIAL_REQUEST = 'specialrequest';

export const CondoDestinationsConsider = [
  {
    content: 'orlando.kissimmee.wonderful.homes',
  },
  {
    content: 'asheville.north.carolina',
  },
  {
    content: 'gatlinburg.pigeon.forge',
  },
  {
    content: 'st.maarten',
  },
];
