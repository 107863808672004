import React, { Component } from 'react';
// @ts-ignore
import JotformEmbed from 'react-jotform-embed';
import './style.scss';

const iFrameProps = {
  src: 'https://form.jotform.com/220954698271163',
  frameBorder: '0',
};

export class MasterClassesRegisterMain extends Component {
  render(): React.ReactNode {
    return (
      <div className="master-classes-register-main">
        <JotformEmbed {...iFrameProps} />
      </div>
    );
  }
}
