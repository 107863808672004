
import { connect } from 'react-redux';

import { IBenefitsItems } from '@share/common-types';
import { ILoginState, IMenuState } from '@share/store/slices';
import { RootState, isExternalUrl, getLocalURI } from '@share/utils';
import { Link } from 'react-router-dom';

import { LinkSvg } from '@assets';

import './style.scss';
import { FormattedMessage } from 'react-intl';

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps {
  benefit: IBenefitsItems;
}

function BenefitComponent(props: IProps) {
  const { benefit, loginStore } = props;
  const { type, title, url, description, imageUrl } = benefit;
  const { account } = loginStore;

  let styles: any = account?.mainBorderColor ? { borderColor: account?.mainBorderColor } : {};
  styles = account?.mainBorderRadius ? { ...styles, borderRadius: `${account?.mainBorderRadius}px` } : { ...styles };
  
  return isExternalUrl(url) ? (
    <a href={url} className="benefit" target="_blank" rel="noreferrer" style={styles}>
      <div className="benefit__img-wrapper">
        <img src={imageUrl} alt={type} />
      </div>
      <div className="benefit__text-wrapper">
        <p className="benefit__title"><FormattedMessage id={title} /></p>
        <p className="benefit__description"><FormattedMessage id={description} /></p>
      </div>
      {!account?.mainHideLinkIcon && (<LinkSvg />)}
    </a>
  ) : (
    <Link 
      to={`/${account?.name}${getLocalURI(url)}`}
      className="benefit"
      style={styles}
    >
      <div className="benefit__img-wrapper">
        <img src={imageUrl} alt={type} />
      </div>
      <div className="benefit__text-wrapper">
        <p className="benefit__title"><FormattedMessage id={title} /></p>
        <p className="benefit__description"><FormattedMessage id={description} /></p>
      </div>
    </Link>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    menuStore: state.navigationMenuStore,
  };
};

export const Benefit = connect(mapStateToProps)(BenefitComponent);
