import React, { useEffect, useMemo } from 'react';
import isUndefined from 'lodash/isUndefined';

import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { Menu, Tooltip } from 'antd';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { IMenuState, setVisible } from '@share/store/slices';
import { IHotelsState, ILoginState, resetHotelsFullWithParams } from '@share/store/slices';
import { Routes } from '@share/constants';
import { GetDisableNewTabOpen, GetHomeParams, getLocalURI, isExternalUrl, RootState, UrlUtils, VACATIONS_PAGE } from '@share/utils';
import { IAccount } from '@share/common-types';

import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import './style.scss';

interface IMapStateToProps {
  hotelsStore: IHotelsState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  resetHotelsFull: () => void;
  setVisible: (visible: boolean) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps {
  matches: boolean;
  menuStore: IMenuState;
  isVacationRentals?: boolean;
  isDrawer?: boolean;
}

const HOTELS = 'Hotels';
const ALL_INCLUSIVES = 'All Inclusives';

function NavMenu(props: IProps) {
  let timeout: any = null;

  const { loginStore, isVacationRentals, isDrawer, intl } = props;
  const { account } = loginStore;

  const homeParams = GetHomeParams(account as IAccount);

  const headerFontColor = account?.headerFontColor;
  const headerBackgroundColorDB = account?.headerBackgroundColor;
  const headerSubMenuWhiteBackground = account?.headerSubMenuWhiteBackground;
  const menuItemHideBorderBottom = account?.menuItemHideBorderBottom;

  const setColors = (headerBackgroundColorDB: string, footerFontsColorDB: string, defaultColor?: string) => {
    const style: any = {};
    if (!isEmpty(headerBackgroundColorDB)) {
      style.backgroundColor = headerBackgroundColorDB;
      document.documentElement.style.setProperty('--background-color', headerBackgroundColorDB as string);
    } else {
      style.backgroundColor = defaultColor ? defaultColor : isDrawer? '#FFFFFF' : 'transparent';
      document.documentElement.style.setProperty('--background-color', defaultColor ? defaultColor : isDrawer? '#FFFFFF' : 'transparent');
    }
    if (!isEmpty(footerFontsColorDB)) {
      style.color = footerFontsColorDB;
      document.documentElement.style.setProperty('--header-font-color', footerFontsColorDB as string);
    }
    return style;
  }

  const styleBackground: any = useMemo(() => setColors(headerBackgroundColorDB as string, (isDrawer ? '#000000' : headerFontColor) as string), [isDrawer, headerFontColor, headerBackgroundColorDB]);
  const styleBackgroundSubMenu: any = useMemo(() => setColors('#FFFFFF', '#000000'), []);

  const styleFonts: any = useMemo(() => {
    const style: any = {};
    if (!isEmpty(headerFontColor)) {
      style.color = isDrawer ? '#000000' : headerFontColor;
    }
    return style;
  }, [isDrawer, headerFontColor]);
  const styleFontsSubMenu: any = useMemo(() => { color: '#000000' }, [headerFontColor]);

  const reloadSamePage = async (event: any, namePage: string[], isAllInclusives: boolean) => {
    props.setVisible(false);

    if (namePage.includes(event.target.innerText)) {
      event.preventDefault();

      UrlUtils.removeAll();
      
      await props.resetHotelsFull();

      timeout = setTimeout(() => {
        if (isAllInclusives) {
          props.history.push(`/${account?.name}${Routes.AllInclusive}${homeParams}`);
        } else if (isVacationRentals) {
          props.history.push(`/${account?.name}${Routes.Search}/${VACATIONS_PAGE}${homeParams}`);
        } else {
          props.history.push(`/${account?.name}${Routes.Search}${homeParams}`);
        }
      }, 300);
    }
  };

  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, []);

  // @ts-ignore
  const menuItem = (url: string, title: string, index: number, className: string, excludeLocalCheck: boolean, isSubmenu: boolean): React.ReactNode => {  
    const isAllInclusives = url.endsWith(Routes.AllInclusive);

    const forceLocalMenu = !excludeLocalCheck && import.meta.env.VITE_FORCE_MENU === 'true';
    const isExternal = isExternalUrl(!isUndefined(url) ? url : '');
    const localURI = url.replace(/^.*\/\/[^\/]+/, '');
    
    const goTo = (isExternal && !forceLocalMenu) ?
                      url :
                      !isUndefined(url) ? 
                          forceLocalMenu ?
                              `/${account?.name}${localURI}${homeParams}` :
                              `/${account?.name}${getLocalURI(url)}${homeParams}` : 
                          isVacationRentals ? 
                              `/${account?.name}${Routes.Search}/${VACATIONS_PAGE}${homeParams}` :
                              `/${account?.name}${Routes.Search}${homeParams}`;

    const isRsi = account?.isRSITemplate && account?.isPrivateTokenAccountType;
    
    const disableNewTabOpen = GetDisableNewTabOpen(account, menuStore.items);

    return (
      <Menu.Item key={`${title}-${index}`} className={`header__item-menu ${menuItemHideBorderBottom ? 'hide-border' : ''} ${fontFamily ? `${fontFamily}-font`: ''}`}>
        {url === 'disabled' ?
          <Tooltip title={intl.formatMessage({ id: 'menu.link.disable' })} placement="top">
            <div style={{ width: '100%' }} className={`${className} ant-menu-item-disabled`}>
              {isRsi ? (<FormattedMessage id={title} />) : title}
            </div>
          </Tooltip> :
          (!forceLocalMenu && isExternal) ? (
            <a className={className} style={isSubmenu ? styleBackgroundSubMenu : styleBackground} href={url} target={disableNewTabOpen ? '_self' : '_blank'} rel="noreferrer">
              {isRsi ? (<FormattedMessage id={title} />) : title}
            </a>) : (
            <Link
              to={goTo}
              className={className}
              // @ts-ignore
              onClick={(event) => reloadSamePage(event, [ALL_INCLUSIVES, HOTELS], isAllInclusives)}
              style={isSubmenu ? styleFontsSubMenu : styleFonts}
            >
              {isRsi ? (<FormattedMessage id={title} />) : title}
            </Link>)}
      </Menu.Item>
   )};

  const { SubMenu } = Menu;
  const { matches, menuStore } = props;
  const { visible, items } = menuStore;

  const mainItems = items?.main;

  const fontFamily = account?.fontFamily;
   const headerSubMenuRoundedCorners = account?.headerSubMenuRoundedCorners;
   const headerTextUpperCase = account?.headerTextUpperCase;

  return (
    <Menu
      mode={(visible || isDrawer) && !matches ? 'inline' : 'horizontal'}
      overflowedIndicatorPopupClassName={`header__overflow ${headerTextUpperCase ? 'upper-case' : ''} ${headerSubMenuRoundedCorners ? 'rounded-corners' : ''}`}
      style={{
        display: (!visible && !isDrawer) ? (!matches ? 'none' : 'block') : 'block',
        ...styleBackground
      }}
      
    >
      {mainItems?.map((item, index) =>
        !item.childItems ? (
          menuItem(item.url as string, item.title, index, `header__link-nav ${(!isDrawer && headerSubMenuWhiteBackground) ? 'white-background' : ''}`, item.excludeCheck as boolean, false)
        ) : (
          <SubMenu
            key={`${item.title}-${index}`}
            title={<span style={styleBackground}><FormattedMessage id={item.title} /></span>}
            className={`header__sub-menu ${menuItemHideBorderBottom ? 'hide-border' : ''} ${fontFamily ? `${fontFamily}-font`: ''} ${(!isDrawer && headerSubMenuWhiteBackground) ? 'white-background' : ''}`}
            popupClassName={`menu-submenu-popup ${fontFamily ? `${fontFamily}-font`: ''} ${headerTextUpperCase ? 'upper-case' : ''} ${headerSubMenuRoundedCorners ? 'rounded-corners' : ''} ${(!isDrawer && headerSubMenuWhiteBackground) ? 'white-background' : ''}`}
            style={styleBackground}
          >
            {item?.childItems?.map((childItem) =>
              menuItem(childItem.url as string, childItem.title, index, 'header__link-nav-child', childItem.excludeCheck as boolean, (!isDrawer && headerSubMenuWhiteBackground) as boolean),
            )}
          </SubMenu>
        ),
      )}
    </Menu>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    hotelsStore: state.hotelsStore,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  resetHotelsFull: () => {
    dispatch(resetHotelsFullWithParams());
  },
  setVisible: (visible: boolean) => {
    dispatch(setVisible(visible));
  },
});

const NavMenuIntl = injectIntl(NavMenu);

export const NavigationMenu = connect(mapStateToProps, mapDispatchToProps)(withRouter(NavMenuIntl));
