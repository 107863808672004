

export const MAX_SELECTED_MONTH_COUNT = 3;

export const CONDO_DETAILS_SORTING_SUITE_ASC = 'CONDO_DETAILS_SORTING_SUITE_ASC';
export const CONDO_DETAILS_SORTING_SUITE_DESC = 'CONDO_DETAILS_SORTING_SUITE_DESC';
export const CONDO_DETAILS_SORTING_DATES_ASC = 'CONDO_DETAILS_SORTING_DATES_ASC';
export const CONDO_DETAILS_SORTING_DATES_DESC = 'CONDO_DETAILS_SORTING_DATES_DESC';
export const CONDO_DETAILS_SORTING_NIGHTS_ASC = 'CONDO_DETAILS_SORTING_NIGHTS_ASC';
export const CONDO_DETAILS_SORTING_NIGHTS_DESC = 'CONDO_DETAILS_SORTING_NIGHTS_DESC';
export const CONDO_DETAILS_SORTING_BEBA_ASC = 'CONDO_DETAILS_SORTING_BEBA_ASC';
export const CONDO_DETAILS_SORTING_BEBA_DESC = 'CONDO_DETAILS_SORTING_BEBA_DESC';
export const CONDO_DETAILS_SORTING_GUESTS_ASC = 'CONDO_DETAILS_SORTING_GUESTS_ASC';
export const CONDO_DETAILS_SORTING_GUESTS_DESC = 'CONDO_DETAILS_SORTING_GUESTS_DESC';
export const CONDO_DETAILS_SORTING_PRICE_ASC = 'CONDO_DETAILS_SORTING_PRICE_ASC';
export const CONDO_DETAILS_SORTING_PRICE_DESC = 'CONDO_DETAILS_SORTING_PRICE_DESC';
export const CONDO_DETAILS_SORTING_POLICY_ASC = 'CONDO_DETAILS_SORTING_POLICY_ASC';
export const CONDO_DETAILS_SORTING_POLICY_DESC = 'CONDO_DETAILS_SORTING_POLICY_DESC';
export const CONDO_DETAILS_SORTING_WALLET_ASC = 'CONDO_DETAILS_SORTING_WALLET_ASC';
export const CONDO_DETAILS_SORTING_WALLET_DESC = 'CONDO_DETAILS_SORTING_WALLET_DESC';

export const USA = 'United States of America';
export const MEXICO = 'Mexico';
export const CANADA = 'Canada';
export const VICINITY = '(Vicinity)';
