import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IRoom, IGuest } from '@share/common-types';
import { v4 } from 'uuid';

const defaultAdultsCount = 2;

export const getEmptyRoom = (adultsCount = defaultAdultsCount): IRoom => {
  return {
    id: v4(),
    adultsCount,
    kids: [],
  };
};

export interface IRoomsState {
  roomsSelected: IRoom[];
  rooms: IRoom[];
  error: string;
}

const initialState: IRoomsState = {
  roomsSelected: [getEmptyRoom()],
  rooms: [getEmptyRoom()],
  error: null
};

const newRoomAdultsCount = 1;

const hotelsSlice = createSlice({
  name: 'rooms',
  initialState,
  reducers: {
    addEmptyRoom: (state: IRoomsState) => {
      state.roomsSelected.push(getEmptyRoom(newRoomAdultsCount));
    },
    updateAdultsCount: (
      state: IRoomsState,
      { payload }: PayloadAction<{ roomId: string; count: number }>,
    ) => {
      const room = state.roomsSelected.find(({ id }) => id === payload.roomId);

      if (room) {
        room.adultsCount = payload.count;

        if (!payload.count) {
          room.kids = [];
        }
      }
    },
    addKid: (state: IRoomsState, { payload }: PayloadAction<{ roomId: string; kid: IGuest }>) => {
      const room = state.roomsSelected.find(({ id }) => id === payload.roomId);

      if (room) {
        room.kids.push(payload.kid);
      }
    },
    updateKidAge: (
      state: IRoomsState,
      { payload }: PayloadAction<{ roomId: string; kidId: string; age: number }>,
    ) => {
      const room = state.roomsSelected.find(({ id }) => id === payload.roomId);

      if (room) {
        const kid = room.kids.find(({ id }) => id === payload.kidId);

        if (kid) {
          kid.age = payload.age;
        }
      }
    },
    removeRoom: (state: IRoomsState, { payload }: PayloadAction<string>) => {
      state.roomsSelected = state.roomsSelected.filter(({ id }) => id !== payload);
    },
    removeKid: (
      state: IRoomsState,
      { payload }: PayloadAction<{ roomId: string; kidId: string }>,
    ) => {
      const room = state.roomsSelected.find(({ id }) => id === payload.roomId);

      if (room) {
        room.kids = room.kids.filter(({ id }) => id !== payload.kidId);
      }
    },
    setRooms: (state: IRoomsState, { payload }: PayloadAction<IRoom[]>) => {
      state.roomsSelected = payload;
    },
    applyRooms: (state: IRoomsState) => {
      state.rooms = state.roomsSelected;
    },
    resetSelectedRooms: (state: IRoomsState) => {
      state.roomsSelected = state.rooms;
    },
    resetRooms: () => {
      return initialState;
    },
  },
});

export const roomsActions = hotelsSlice.actions;

export const roomsReducer = hotelsSlice.reducer;
