import React from 'react';

export const KitchenSvg: React.FunctionComponent = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.99996 6.66667H6.66663V2H5.33329V6.66667H3.99996V2H2.66663V7.33333C2.66663 8.436 3.56396 9.33333 4.66663 9.33333H5.33329V14H6.66663V9.33333H7.33329C8.43596 9.33333 9.33329 8.436 9.33329 7.33333V2H7.99996V6.66667ZM12.6666 2H12C11.2273 2 10.6666 2.84133 10.6666 4V9.33333H12V14H13.3333V2.66667C13.3333 2.298 13.0353 2 12.6666 2Z"
      fill="#333333"
    />
  </svg>
);

type SizeProps = {
  size?: string;
}

export const KitchenSizeSvg: React.FunctionComponent<SizeProps> = ({ size }: SizeProps) => (
  <svg width={size || 16} height={size || 16} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.6136 45.4376C22.0354 45.8595 22.0354 46.5434 21.6136 46.9653L20.5986 47.9802H30.0621C30.6587 47.9802 31.1423 48.4639 31.1423 49.0604C31.1423 49.657 30.6587 50.1407 30.0621 50.1407H20.5986L21.6136 51.1556C22.0354 51.5775 22.0354 52.2614 21.6136 52.6833C21.1917 53.1051 20.5078 53.1051 20.0859 52.6833L17.3797 49.977C16.8734 49.4708 16.8734 48.6501 17.3797 48.1439L20.0859 45.4376C20.5078 45.0158 21.1917 45.0158 21.6136 45.4376Z" fill="black" />
    <path d="M42.9578 45.4376C42.536 45.8595 42.536 46.5434 42.9578 46.9653L43.9728 47.9802H26.6735C26.0769 47.9802 25.5933 48.4639 25.5933 49.0604C25.5933 49.657 26.0769 50.1407 26.6735 50.1407H43.9728L42.9578 51.1556C42.536 51.5775 42.536 52.2614 42.9578 52.6833C43.3797 53.1051 44.0636 53.1051 44.4855 52.6833L47.1917 49.977C47.6979 49.4708 47.6979 48.6501 47.1917 48.1439L44.4855 45.4376C44.0636 45.0158 43.3797 45.0158 42.9578 45.4376Z" fill="black" />
    <path d="M17.9198 28.2162C17.9198 27.5003 18.5001 26.9199 19.216 26.9199H46.784C47.4999 26.9199 48.0802 27.5003 48.0802 28.2162V40.7841C48.0802 41.5 47.4999 42.0803 46.784 42.0803H19.216C18.5001 42.0803 17.9198 41.5 17.9198 40.7841V28.2162ZM20.0802 29.0803V30.4077H34.7596V31.3193H45.9198V29.0803H20.0802ZM45.9198 33.4797H34.7596C34.2203 33.4797 33.7734 33.0846 33.6924 32.5682H27.96V39.9199H45.9198V37.8791H34.7596C34.163 37.8791 33.6794 37.3955 33.6794 36.7989C33.6794 36.2023 34.163 35.7187 34.7596 35.7187H45.9198V33.4797ZM25.7996 39.9199V32.5682H20.0802V39.9199H25.7996Z" fill="black" />
    <path d="M17.9198 28.2162C17.9198 27.5003 18.5001 26.9199 19.216 26.9199H33.784C34.4999 26.9199 35.0802 27.5003 35.0802 28.2162V40.7841C35.0802 41.5 34.4999 42.0803 33.784 42.0803H19.216C18.5001 42.0803 17.9198 41.5 17.9198 40.7841V28.2162ZM20.0802 29.0803V39.9199H32.9198V29.0803H20.0802Z" fill="black" />
    <path d="M24 35.0001C24 35.5524 23.5523 36.0001 23 36.0001C22.4477 36.0001 22 35.5524 22 35.0001C22 34.4478 22.4477 34.0001 23 34.0001C23.5523 34.0001 24 34.4478 24 35.0001Z" fill="black" />
    <path d="M31 35.0001C31 35.5524 30.5523 36.0001 30 36.0001C29.4477 36.0001 29 35.5524 29 35.0001C29 34.4478 29.4477 34.0001 30 34.0001C30.5523 34.0001 31 34.4478 31 35.0001Z" fill="black" />
    <path d="M25.7439 14.0803V17.2219C25.7439 17.5645 25.6083 17.8931 25.3667 18.136L22.5979 20.9199H34.4021L31.6333 18.136C31.3917 17.8931 31.2561 17.5645 31.2561 17.2219V14.0803H25.7439ZM23.5835 13.2162C23.5835 12.5003 24.1639 11.9199 24.8798 11.9199H32.1202C32.8361 11.9199 33.4165 12.5003 33.4165 13.2162V16.8653L37.3995 20.87C38.2127 21.6876 37.6336 23.0803 36.4804 23.0803H20.5196C19.3664 23.0803 18.7873 21.6876 19.6005 20.87L23.5835 16.8653V13.2162Z" fill="black" />
  </svg>
);
