import { ICountryState } from '@common-types';

export const USA_STATES: ICountryState[] = [
  { name: 'Alabama', postalCode: 'AL' },
  { name: 'Alaska', postalCode: 'AK' },
  { name: 'Arizona', postalCode: 'AZ' },
  { name: 'Arkansas', postalCode: 'AR' },
  { name: 'California', postalCode: 'CA' },
  { name: 'Colorado', postalCode: 'CO' },
  { name: 'Connecticut', postalCode: 'CT' },
  { name: 'Delaware', postalCode: 'DE' },
  { name: 'District of Columbia', postalCode: 'DC' },
  { name: 'Florida', postalCode: 'FL' },
  { name: 'Georgia', postalCode: 'GA' },
  { name: 'Hawaii', postalCode: 'HI' },
  { name: 'Idaho', postalCode: 'ID' },
  { name: 'Illinois', postalCode: 'IL' },
  { name: 'Indiana', postalCode: 'IN' },
  { name: 'Iowa', postalCode: 'IA' },
  { name: 'Kansas', postalCode: 'KS' },
  { name: 'Kentucky', postalCode: 'KY' },
  { name: 'Louisiana', postalCode: 'LA' },
  { name: 'Maine', postalCode: 'ME' },
  { name: 'Maryland', postalCode: 'MD' },
  { name: 'Massachusetts', postalCode: 'MA' },
  { name: 'Michigan', postalCode: 'MI' },
  { name: 'Minnesota', postalCode: 'MN' },
  { name: 'Mississippi', postalCode: 'MS' },
  { name: 'Missouri', postalCode: 'MO' },
  { name: 'Montana', postalCode: 'MT' },
  { name: 'Nebraska', postalCode: 'NE' },
  { name: 'Nevada', postalCode: 'NV' },
  { name: 'New Hampshire', postalCode: 'NH' },
  { name: 'New Jersey', postalCode: 'NJ' },
  { name: 'New Mexico', postalCode: 'NM' },
  { name: 'New York', postalCode: 'NY' },
  { name: 'North Carolina', postalCode: 'NC' },
  { name: 'North Dakota', postalCode: 'ND' },
  { name: 'Ohio', postalCode: 'OH' },
  { name: 'Oklahoma', postalCode: 'OK' },
  { name: 'Oregon', postalCode: 'OR' },
  { name: 'Pennsylvania', postalCode: 'PA' },
  { name: 'Rhode Island', postalCode: 'RI' },
  { name: 'South Carolina', postalCode: 'SC' },
  { name: 'South Dakota', postalCode: 'SD' },
  { name: 'Tennessee', postalCode: 'TN' },
  { name: 'Texas', postalCode: 'TX' },
  { name: 'Utah', postalCode: 'UT' },
  { name: 'Vermont', postalCode: 'VT' },
  { name: 'Virginia', postalCode: 'VA' },
  { name: 'Washington', postalCode: 'WA' },
  { name: 'West Virginia', postalCode: 'WV' },
  { name: 'Wisconsin', postalCode: 'WI' },
  { name: 'Wyoming', postalCode: 'WY' },
  { name: 'American Samoa', postalCode: 'AS' },
  { name: 'Guam', postalCode: 'GU' },
  { name: 'Marshall Islands', postalCode: 'MH' },
  { name: 'Micronesia', postalCode: 'FM' },
  { name: 'Northern Mariana Islands', postalCode: 'MP' },
  { name: 'Palau', postalCode: 'PW' },
  { name: 'Puerto Rico', postalCode: 'PR' },
  { name: 'Virgin Islands', postalCode: 'VI' },
];

export const CANADA_STATES: ICountryState[] = [
  { name: 'Newfoundland and Labrador', postalCode: 'NL' },
  { name: 'Prince Edward Island', postalCode: 'PE' },
  { name: 'Nova Scotia', postalCode: 'NS' },
  { name: 'New Brunswick', postalCode: 'NB' },
  { name: 'Quebec', postalCode: 'QC' },
  { name: 'Ontario', postalCode: 'ON' },
  { name: 'Manitoba', postalCode: 'MB' },
  { name: 'Saskatchewan', postalCode: 'SK' },
  { name: 'Alberta', postalCode: 'AB' },
  { name: 'British Columbia', postalCode: 'BC' },
  { name: 'Yukon', postalCode: 'YT' },
  { name: 'Northwest Territories', postalCode: 'NT' },
  { name: 'Nunavut', postalCode: 'NU' },
];
