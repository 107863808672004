import React from 'react';

export const MapPinSvg: React.FunctionComponent = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.94275 13.9331L11.7714 11.1045C12.5173 10.3586 13.0252 9.40827 13.231 8.37371C13.4367 7.33916 13.3311 6.26681 12.9274 5.29229C12.5237 4.31777 11.8402 3.48483 10.9631 2.89881C10.086 2.31279 9.05491 2 8.00008 2C6.94526 2 5.91413 2.31279 5.03707 2.89881C4.16001 3.48483 3.47643 4.31777 3.07275 5.29229C2.66908 6.26681 2.56344 7.33916 2.76921 8.37371C2.97497 9.40827 3.4829 10.3586 4.22875 11.1045L7.05808 13.9331C7.30809 14.1829 7.64703 14.3232 8.00042 14.3232C8.35381 14.3232 8.69274 14.1829 8.94275 13.9331ZM10.0001 7.33314C10.0001 8.43771 9.10465 9.33314 8.00008 9.33314C6.89551 9.33314 6.00008 8.43771 6.00008 7.33314C6.00008 6.22857 6.89551 5.33314 8.00008 5.33314C9.10465 5.33314 10.0001 6.22857 10.0001 7.33314Z"
      fill="#0D99D6"
    />
  </svg>
);
