import React from 'react';
import {
  WifiSvg,
  ParkingSvg,
  PoolSvg,
  PetsSvg,
  AirSvg,
  BusinessCenterSvg,
  LiftSvg,
  CarSvg,
  SmokingSvg,
} from '@assets';

export enum TopAmenities {
  Wifi = 'WiFi',
  Parking = 'Parking',
  SwimmingPool = 'Swimming pool',
  PetsAllowed = 'Pets allowed',
  AirConditioning = 'Air conditioning',
  BusinessCenter = 'Business center',
  Lifts = 'Lifts',
  ShuttleService = 'Shuttle service',
  SmokingRooms = 'Smoking rooms',
}

export const TOP_AMENITIES: string[] = [
  TopAmenities.Wifi,
  TopAmenities.Parking,
  TopAmenities.SwimmingPool,
  TopAmenities.PetsAllowed,
  TopAmenities.AirConditioning,
  TopAmenities.BusinessCenter,
  TopAmenities.Lifts,
  TopAmenities.ShuttleService,
  TopAmenities.SmokingRooms,
];

export const TOP_AMENITIES_ICONS: { [key: string]: React.ReactElement } = {
  [TopAmenities.Wifi.toLowerCase()]: <WifiSvg />,
  [TopAmenities.Parking.toLowerCase()]: <ParkingSvg />,
  [TopAmenities.SwimmingPool.toLowerCase()]: <PoolSvg />,
  [TopAmenities.PetsAllowed.toLowerCase()]: <PetsSvg />,
  [TopAmenities.AirConditioning.toLowerCase()]: <AirSvg />,
  [TopAmenities.BusinessCenter.toLowerCase()]: <BusinessCenterSvg />,
  [TopAmenities.Lifts.toLowerCase()]: <LiftSvg />,
  [TopAmenities.ShuttleService.toLowerCase()]: <CarSvg />,
  [TopAmenities.SmokingRooms.toLowerCase()]: <SmokingSvg />,
};
