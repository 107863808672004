import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { IMenuState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { LoaderSvg, BookingProgressSvg } from '@assets';
import { HOTEL_SUPPORT_EMAIL } from '@constants';
import './style.scss';

interface IMapStateToProps {
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps {
  isLoadingBooking?: boolean;
  isBookingInProgress?: boolean;
}

class ReservationProgressComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { isLoadingBooking, isBookingInProgress, menuStore } = this.props;

    return (
      <div className={`progress ${isLoadingBooking ? 'loading-progress' : ''}`}>
        {isLoadingBooking ? <LoaderSvg /> : <BookingProgressSvg />}
        <p className="progress__title secondary-font">
          <FormattedMessage id="reservation.in.progress" />
          ...
        </p>
        {isLoadingBooking && (
          <p className="progress__description">
            <FormattedMessage id="do.not.close.text" />
          </p>
        )}
        {isBookingInProgress && (
          <>
            <p className="progress__description">
              <FormattedMessage id="booking.is.still.in.progress" />
            </p>
            <p className="progress__description-warning">
              <FormattedMessage id="do.not.try.booking.again" />
            </p>
            <div className="progress__wrapper-description">
              <p className="progress__description-warning">
                <FormattedMessage id="phone" />:
              </p>
              <a
                className="progress__description-link"
                href={`tel:${menuStore?.items?.customerServiceNumber}`}
              >
                {menuStore?.items?.customerServiceNumber}
              </a>
            </div>
            <div className="progress__wrapper-description">
              <p className="progress__description-warning pt-0">
                <FormattedMessage id="email" />:
              </p>
              <a className="progress__description-link" href={`mailto:${HOTEL_SUPPORT_EMAIL}`}>
                {HOTEL_SUPPORT_EMAIL}
              </a>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
  };
};

export const ReservationProgress = connect(mapStateToProps)(ReservationProgressComponent);
