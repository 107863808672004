import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Urls } from '@share/constants';
import { AppThunk } from '@share/utils';
import { getHeaders, axiosInstance } from '@share/utils';

export interface ICurrencyState {
    selected: string;
    currencies: [];
}

const initialState: ICurrencyState = {
    selected: "USD",
    currencies: []
};

const currencySlice = createSlice({
    name: 'currency',
    initialState,
    reducers: {
        setCurrency: (state: ICurrencyState, { payload }: PayloadAction<string>) => {
            state.selected = payload;
        },
        setCurrencies: (state: ICurrencyState, { payload }: PayloadAction<[]>) => {
            state.currencies = payload;
        }
    },
});

export const currencyActions = currencySlice.actions;
export const currencyReducer = currencySlice.reducer;

export const getAllCurrencies = (): AppThunk => {
    return async (dispatch) => {
        try {
            const res = await axiosInstance.get(Urls.Currencies, { ...getHeaders() });
            const items: any = [];
            res.data.map((el: any) => {
                items.push({ code: el.currency, symbol: el.symbol })
            });
            dispatch(currencyActions.setCurrencies(items));
        } catch (error) {
            console.error(error);
        }
    };
};