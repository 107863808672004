import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Form, Input } from 'antd';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import { FormInstance } from 'antd/lib/form';
import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';
import {
  getawayBookingActions,
  getSubmitRequest,
  ICurrentUserState,
  IGetawayBookingState,
  IGetawayDatesState,
} from '@store/slices';
import {
  IMenuState,
} from '@share/store/slices';
import { formatDateCheckInOut, RootState } from '@share/utils';
import { IGetawayBookInfo, ContactMe, BestTime, IGetawaysSubmitRequest } from '@share/common-types';
import { renderPhone } from '@share/utils';
import { updateGetawayBookInfo } from '@utils';
import { countriesCode } from '@share/constants';
import { InfoSvg } from '@assets';
import { BlueButton } from '@share/components';
import { GetawayImportantInformation } from '../getaway-important-information';
import { GetawaySuccess } from '../notification';
import { GetawayContactInfo } from '../getaway-contact-info';
import { GetawayTravelingInfo } from '../getaway-traveling-info';
import './style.scss';

interface IMapStateToProps {
  getawayBookingStore: IGetawayBookingState;
  menuStore: IMenuState;
  currentUserStore: ICurrentUserState;
  getawayDatesStore: IGetawayDatesState;
}

interface IMapDispatchToProps {
  setGetawayBookInfo: (getawayBookInfo: IGetawayBookInfo) => void;
  getSubmitRequest: (data: IGetawaysSubmitRequest) => void;
  setSuccessSubmitGetaway: (item: boolean) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps { }

interface IState {
  isSomeoneElse: boolean;
}

const ZERO = 0;
const MAX_LENGTH_DESCRIPTION = 1000;
const FOUR = 4;

class GetawayBookingComponent extends React.Component<IProps, IState> {
  state: IState = {
    isSomeoneElse: false,
  };

  formRef = React.createRef<FormInstance>();

  onChangeSomeoneElse = (e: { target: CheckboxChangeEventTarget }) => {
    const { currentUser } = this.props.currentUserStore;
    const { getawayBookInfo } = this.props.getawayBookingStore;
    const { firstName, lastName, phone1, email1 } = currentUser;

    if (!e.target.checked) {
      this.formRef.current.setFieldsValue({ firstname: firstName });
      this.formRef.current.setFieldsValue({ lastname: lastName });
      this.formRef.current.setFieldsValue({ email: email1 });
      this.formRef.current.setFieldsValue({
        phone: renderPhone(phone1),
      });
    }

    this.setState({ isSomeoneElse: e.target.checked });
    this.props.setGetawayBookInfo({
      ...(updateGetawayBookInfo(getawayBookInfo, {
        firstName: !e.target.checked ? firstName : '',
        lastName: !e.target.checked ? lastName : '',
        email: !e.target.checked ? email1 : '',
        phone: !e.target.checked ? renderPhone(phone1) : '',
      }) as IGetawayBookInfo),
    });
  };

  onChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const { getawayBookInfo } = this.props.getawayBookingStore;
    this.props.setGetawayBookInfo(
      updateGetawayBookInfo(getawayBookInfo, { comment: e.target.value }) as IGetawayBookInfo,
    );
  };

  onSubmitRequest = () => {
    const { getawayBookingStore, getawayDatesStore, menuStore } = this.props;
    const {
      getawayBookInfo,
      getawayInfo,
      adultsCount,
      youngerChildrenCount,
      middleChildrenCount,
      olderChildrenCount,
    } = getawayBookingStore;
    const { items } = menuStore;
    const {
      firstName,
      lastName,
      comment,
      email,
      phone,
      preferredContact,
      preferredTime,
      phoneCountryCode,
    } = getawayBookInfo;
    const { promoId, accommodationType, title, city, state } = getawayInfo;
    const { startDate, endDate } = getawayDatesStore;
    const activeGetawaySubcategory = location.pathname.split('/')[FOUR];
    const action = 'getaway_request';
    const locationInfo = `https://suitejourneys.com/index.php?page=getawaybooking&category=${promoId}`;
    const isoFormat = 'yyyy-MM-dd';

    const data = {
      location: locationInfo,
      action,
      sid: promoId,
      rsi_id: items.rsiId,
      memberNumber: items.rsiId,
      getaway_type:
        isUndefined(activeGetawaySubcategory) || isEmpty(activeGetawaySubcategory)
          ? accommodationType
          : activeGetawaySubcategory,
      getaway_name: title,
      org_name: items.organizationName,
      departureLocation: city,
      departureState: state,
      member_website: items.baseUrl,
      firstName,
      lastName,
      phone: `${phoneCountryCode}${phone}`,
      email,
      preferredContact,
      preferredTime,
      numAdults: adultsCount,
      numChildrenThirteenToSeventeen: olderChildrenCount,
      numChildrenThreeToTwelve: middleChildrenCount,
      numInfants: youngerChildrenCount,
      firstDate: formatDateCheckInOut(startDate, isoFormat),
      secondDate: formatDateCheckInOut(endDate, isoFormat),
      additionalInfo: comment,
    };

    this.formRef.current.setFieldsValue({ email: email });
    this.formRef.current.setFieldsValue({ firstname: firstName });
    this.formRef.current.setFieldsValue({ lastname: lastName });
    this.formRef.current.setFieldsValue({ phone: phone });

    Promise.all([this.formRef.current.validateFields()]).then(() => {
      this.props.getSubmitRequest(data);
    });
  };

  componentDidMount() {
    const { firstName, lastName, email1, phone1 } = this.props.currentUserStore.currentUser;

    this.props.setSuccessSubmitGetaway(false);
    this.props.setGetawayBookInfo({
      firstName,
      lastName,
      email: email1,
      phone: phone1,
      phoneCountryCode: countriesCode[ZERO].dialCode,
      preferredContact: ContactMe[ZERO],
      preferredTime: BestTime[ZERO],
      comment: '',
    });
  }

  render() {
    const { TextArea } = Input;
    const { intl, getawayBookingStore } = this.props;
    const { getawayBookInfo, successSubmitGetaway } = getawayBookingStore;
    const { isSomeoneElse } = this.state;

    return !successSubmitGetaway ? (
      <div className="getaway-booking">
        <Form name="getaway-booking-rule" ref={this.formRef}>
          <GetawayContactInfo
            formRef={this.formRef}
            onChangeSomeoneElse={this.onChangeSomeoneElse}
            isSomeoneElse={isSomeoneElse}
          />
          <GetawayTravelingInfo formRef={this.formRef} />
          <p className="getaway-booking__info">
            <FormattedMessage id="please.enter.additional.information" />
          </p>
          <div className="getaway-booking__notification">
            <InfoSvg />
            <div className="getaway-booking__notification-text-wrapper">
              <p className="getaway-booking__notification-text">
                <FormattedMessage id="filling.out.this.form" />
              </p>
              <p className="getaway-booking__notification-text">
                <FormattedMessage id="do.not.enter.any.payment.or.credit.card" />
              </p>
            </div>
          </div>
          <Form.Item
            name="comment"
            className="getaway-contact-info__text-area"
            validateTrigger="onBlur"
            rules={[
              {
                max: MAX_LENGTH_DESCRIPTION,
                message: intl.formatMessage({ id: 'error.message.chars' }),
              },
            ]}
          >
            <div className="getaway-contact-info__text-area-wrapper">
              <span className="getaway-contact-info__input-label">
                <FormattedMessage id="additional.comment" />
              </span>
              <div className="getaway-contact-info__input text-area">
                <TextArea
                  placeholder={intl.formatMessage({ id: 'enter.comment.or.questions' })}
                  value={getawayBookInfo?.comment ? getawayBookInfo?.comment : ''}
                  maxLength={MAX_LENGTH_DESCRIPTION}
                  onChange={(e) => {
                    this.onChangeComment(e);
                  }}
                  showCount
                />
              </div>
            </div>
          </Form.Item>
          <GetawayImportantInformation />
          <div className="getaway-booking__button-wrapper">
            <BlueButton onClick={this.onSubmitRequest}>
              <FormattedMessage id="submit.request" />
            </BlueButton>
          </div>
        </Form>
      </div>
    ) : (
      <GetawaySuccess />
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    getawayBookingStore: state.getawayBookingStore,
    menuStore: state.navigationMenuStore,
    currentUserStore: state.currentUserStore,
    getawayDatesStore: state.getawayDatesStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setGetawayBookInfo: getawayBookingActions.setGetawayBookInfo,
  setSuccessSubmitGetaway: getawayBookingActions.setSuccessSubmitGetaway,
  getSubmitRequest,
};

export const GetawayBooking = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(GetawayBookingComponent));
