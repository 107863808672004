import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Routes } from '@share/constants';
import { CustomErrorPage } from '@share/components';

import { ErrorPage } from './error';
import { GenericNotFoundPage } from './generic-not-found';
import { NotAuthorizedPage } from './not-authorized';

import './style.scss';

interface IProps {}

class ErrorPanelComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <div className="error-panel">
        <div className="error-panel__wrapper">
          <Switch>
            <Route path={Routes.BasicError} exact={true}>
              <ErrorPage />
            </Route>
            <Route path={Routes.NotFound} exact={true}>
              <GenericNotFoundPage />
            </Route>
            <Route path={Routes.CustomError} exact={true}>
              <CustomErrorPage />
            </Route>
            <Route path={Routes.NotAuthorized} exact={true}>
              <NotAuthorizedPage />
            </Route>
            <Route path="*">
              <GenericNotFoundPage />
            </Route>
          </Switch>
        </div>
      </div>
    );
  }
}

export const ErrorPanel = ErrorPanelComponent;
