import React from 'react';
import { FormattedMessage } from 'react-intl';

import ExpiredSessionImgage from '@assets/images/expired_session.png';

import './style.scss';

interface IProps {
  onReset: () => void;
}

export class SessionExpiredMessage extends React.Component<IProps> {
  render(): React.ReactNode {
    const { onReset } = this.props;


    return (
      <div className="session-expired-message">
        <img src={ExpiredSessionImgage} alt="" />
        <div className="session-expired-message__info">
          <p className="session-expired-message__title">
            <FormattedMessage id="session.has.been.expired" />
          </p>
          <p className="session-expired-message__text">
            <FormattedMessage id="session.expired.text.left" />{' '}
            <a onClick={onReset}>
              <FormattedMessage id="session.refresh.page" />
            </a>{' '}
            <FormattedMessage id="session.expired.text.right" />
          </p>
        </div>
      </div>
    );
  }
}
