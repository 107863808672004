
import isEmpty from 'lodash/isEmpty';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CarsMaxDaysRange, GetCarTimeAsNumber, GetDatediff } from '@share/utils';

const HOURS = [...Array.from(Array(12).keys())];
const MINUTES = [...Array.from({ length: 4 }, (_, i) => i * 15)];
const HOURS_MINUTES = HOURS.map(h => ([...MINUTES.map(m => `${h < 10 ? '0' : ''}${h}:${m < 10 ? '0' : ''}${m}`)])).flat(Infinity);
const HOURS_MINUTES_AFTERNOON = HOURS.map(h => ([...MINUTES.map(m => `${h + 12}:${m < 10 ? '0' : ''}${m}`)])).flat(Infinity);

const GetCarsTimes = (maxTime?: string) => {
  const times = [...HOURS_MINUTES.map(h => `${h}`), ...HOURS_MINUTES_AFTERNOON.map(h => `${h}`)];
  if (!isEmpty(maxTime)) {
    return times.filter(t => GetCarTimeAsNumber(t) <= GetCarTimeAsNumber(maxTime));
  }
  return times;
}

export enum CarsErrorTypes {
  Date,
  Time,
  DriverCountry,
}

export interface ICarsDatesState {
  startDateSelected: string;
  startDateTimeSelected: string;
  endDateSelected: string;
  endDateTimeSelected: string;

  startDate: string;
  startDateTime: string;
  endDate: string;
  endDateTime: string;

  key: string;
  error: string;
  errorType: CarsErrorTypes;
  isFocused?: boolean;
  times: string[];
  timesPickUp: string[];
  timesDropOff: string[];
}

const initialState: ICarsDatesState = {
  startDateSelected: undefined,
  startDateTimeSelected: '10:30',
  endDateSelected: undefined,
  endDateTimeSelected: '10:30',

  startDate: undefined,
  startDateTime: undefined,
  endDate: undefined,
  endDateTime: undefined,

  key: 'selection',
  error: '',
  errorType: undefined,
  isFocused: false,
  times: GetCarsTimes(),
  timesPickUp: GetCarsTimes(),
  timesDropOff: GetCarsTimes()
};

const CheckDropOffTime = (state: ICarsDatesState) => {
  if (!isEmpty(state.startDateSelected) && !isEmpty(state.endDateSelected)) {
    const diff = GetDatediff(state.startDateSelected, state.endDateSelected);
    let maxTime: string = undefined;
    if (diff === CarsMaxDaysRange && state.endDateTimeSelected && state.startDateTimeSelected) {
      if (GetCarTimeAsNumber(state.endDateTimeSelected) > GetCarTimeAsNumber(state.startDateTimeSelected)) {
        state.endDateTimeSelected = state.startDateTimeSelected;
      }
      maxTime = state.startDateTimeSelected;
    }
    state.timesDropOff = GetCarsTimes(maxTime);
  }
}

const carsDatesSlice = createSlice({
  name: 'carDates',
  initialState,
  reducers: {
    setDatesSelected: (
      state: ICarsDatesState,
      { payload }: PayloadAction<{ startDate: string; endDate: string; }>,
    ) => {
      state.startDateSelected = payload.startDate;
      state.endDateSelected = payload.endDate;

      CheckDropOffTime(state);
    },
    setTimesSelected: (
      state: ICarsDatesState,
      { payload }: PayloadAction<{ startDateTime: string; endDateTime: string; }>,
    ) => {
      state.startDateTimeSelected = payload.startDateTime;
      state.endDateTimeSelected = payload.endDateTime;

      CheckDropOffTime(state);
    },
    setStartDateSelected: (state: ICarsDatesState, { payload }: PayloadAction<string>) => {
      state.startDateSelected = payload;

      CheckDropOffTime(state);
    },
    setEndDateSelected: (state: ICarsDatesState, { payload }: PayloadAction<string>) => {
      state.endDateSelected = payload;
    },
    setStartDateTimeSelected: (state: ICarsDatesState, { payload }: PayloadAction<string>) => {
      state.startDateTimeSelected = payload;

      CheckDropOffTime(state);
    },
    setEndDateTimeSelected: (state: ICarsDatesState, { payload }: PayloadAction<string>) => {
      state.endDateTimeSelected = payload;
    },

    setKey: (state: ICarsDatesState, { payload }: PayloadAction<string>) => {
      state.key = payload;
    },
    setError: (state: ICarsDatesState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setErrorType: (state: ICarsDatesState, { payload }: PayloadAction<CarsErrorTypes>) => {
      state.errorType = payload;
    },
    setIsFocused: (state: ICarsDatesState, { payload }: PayloadAction<boolean>) => {
      state.isFocused = payload;
    },

    resetCarsDates: (state: ICarsDatesState) => {
      state. startDateSelected = undefined;
      state. startDateTimeSelected = '10:30';
      state.endDateSelected = undefined;
      state.endDateTimeSelected = '10:30';
      state.startDate = undefined;
      state.startDateTime = undefined;
      state.endDate = undefined;
      state.endDateTime = undefined;

      state.timesDropOff = GetCarsTimes();

      state.key = 'selection';
      state.error = '';
      state.errorType = undefined;
      state.isFocused = false;
    },
    applyCarsDates: (state: ICarsDatesState) => {
      state.startDate = state.startDateSelected;
      state.startDateTime = state.startDateTimeSelected;
      state.endDate = state.endDateSelected;
      state.endDateTime = state.endDateTimeSelected;
    },
    resetSelectedDates: (state: ICarsDatesState) => {
      state.startDateSelected = state.startDate;
      state.endDateSelected = state.endDate;
    },
  },
});

export const carsDatesActions = carsDatesSlice.actions;

export const carsDatesReducer = carsDatesSlice.reducer;
