import React from 'react';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import parse from 'html-react-parser';

import { FormattedMessage } from 'react-intl';

import { IDetails } from '@share/common-types';

import './style.scss';

interface IProps {
  condoDetails: IDetails;
  refAnchor: React.RefObject<HTMLDivElement>;
}

const ZERO = 0;

export class CondoPolicies extends React.Component<IProps> {
  isWeeksResortCheckInDays = (): boolean => {
    const { daysOfCheckIn } = this.props.condoDetails;

    return (
      !isUndefined(daysOfCheckIn) &&
      !isUndefined(daysOfCheckIn.weeksResortCheckInDays) &&
      !isEmpty(daysOfCheckIn.weeksResortCheckInDays)
    );
  };

  isDefaultTextWeeksResortCheckInDays = (): boolean => {
    const { daysOfCheckIn } = this.props.condoDetails;

    return (
      !isUndefined(daysOfCheckIn) &&
      !isUndefined(daysOfCheckIn.pointsResortCheckInDays) &&
      !isEmpty(daysOfCheckIn.pointsResortCheckInDays) &&
      (isUndefined(daysOfCheckIn.weeksResortCheckInDays) ||
        isEmpty(daysOfCheckIn.weeksResortCheckInDays))
    );
  };

  isPointsResortCheckInDays = (): boolean => {
    const { daysOfCheckIn } = this.props.condoDetails;

    return (
      !isUndefined(daysOfCheckIn) &&
      !isUndefined(daysOfCheckIn.pointsResortCheckInDays) &&
      !isEmpty(daysOfCheckIn.pointsResortCheckInDays)
    );
  };

  isCheckInTime = (): boolean => {
    const { checkInCheckOutInfo } = this.props.condoDetails;

    return !isUndefined(checkInCheckOutInfo) && !isUndefined(checkInCheckOutInfo.checkInTime);
  };

  isCheckOutTime = (): boolean => {
    const { checkInCheckOutInfo } = this.props.condoDetails;

    return !isUndefined(checkInCheckOutInfo) && !isUndefined(checkInCheckOutInfo.checkOutTime);
  };

  isPossibleApplicableFees = (): boolean => {
    const { mandatoryFees, fees, housekeepingFees } = this.props.condoDetails;

    return !isEmpty(mandatoryFees) || !isEmpty(fees) || !isEmpty(housekeepingFees);
  };

  isKnowBeforeYouGo = (): boolean => {
    const { urgentInfo, constructionDetails } = this.props.condoDetails;

    return !isEmpty(urgentInfo) || !isEmpty(constructionDetails);
  };

  isShowPolicies = (): boolean => {
    const {
      ageRestrictions,
      minimumNights,
      frontDeskInfo,
      pets,
      parking,
      smoking,
      orientation,
      policiesHighlights,
    } = this.props.condoDetails;

    return (
      !isEmpty(policiesHighlights) ||
      this.isCheckInTime() ||
      this.isCheckOutTime() ||
      (!isUndefined(ageRestrictions) &&
        !isEmpty(ageRestrictions) &&
        this.isWeeksResortCheckInDays()) ||
      this.isDefaultTextWeeksResortCheckInDays() ||
      this.isPointsResortCheckInDays() ||
      minimumNights > ZERO ||
      (!isUndefined(frontDeskInfo) && !isEmpty(frontDeskInfo)) ||
      !isEmpty(pets) ||
      !isEmpty(parking) ||
      !isEmpty(smoking) ||
      !isEmpty(orientation) ||
      this.isKnowBeforeYouGo() ||
      this.isPossibleApplicableFees()
    );
  };

  render(): React.ReactNode {
    const { condoDetails, refAnchor } = this.props;
    const {
      checkInCheckOutInfo,
      checkOutInformation,
      checkInInformation,
      ageRestrictions,
      daysOfCheckIn,
      minimumNights,
      frontDeskInfo,
      pets,
      parking,
      smoking,
      orientation,
      urgentInfo,
      mandatoryFees,
      fees,
      housekeepingFees,
      constructionDetails,
    } = condoDetails;

    return this.isShowPolicies() ? (
      <div className="condo-policies" ref={refAnchor}>
        <h2 className="condo-policies__title secondary-font">
          <FormattedMessage id="tab.policies" />
        </h2>
        <div className="condo-policies__highlights-wrapper">
          {condoDetails.policiesHighlights?.map((item, index) => {
            return (
              <p key={index} className="condo-policies__highlights">
                {parse(item)}
              </p>
            );
          })}
        </div>
        {this.isCheckInTime() ? (
          <div className="condo-policies__info">
            <p className="condo-policies__point">
              <FormattedMessage id="check.in" />:
            </p>
            <div className="condo-policies__info-text-wrapper">
              <p className="condo-policies__info-text bold">{checkInCheckOutInfo.checkInTime}</p>
              {!isEmpty(checkInInformation) && (
                <div className="condo-policies__info-wrapper">
                  {checkInInformation.map((checkIn, index) => (
                    <p key={index} className="condo-policies__info-text">
                      {checkIn}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>
        ) : null}
        {this.isCheckOutTime() ? (
          <div className="condo-policies__info">
            <p className="condo-policies__point">
              <FormattedMessage id="check.out" />:
            </p>
            <div className="condo-policies__info-text-wrapper">
              <p className="condo-policies__info-text bold">{checkInCheckOutInfo.checkOutTime}</p>
              {!isEmpty(checkOutInformation) && (
                <div className="condo-policies__info-wrapper">
                  {checkOutInformation.map((checkIn, index) => (
                    <p key={index} className="condo-policies__info-text">
                      {checkIn}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>
        ) : null}
        {!isUndefined(ageRestrictions) && !isEmpty(ageRestrictions) ? (
          <div className="condo-policies__info">
            <p className="condo-policies__point">
              <FormattedMessage id="age.restriction" />:
            </p>
            <div className="condo-policies__info-text-wrapper">
              {ageRestrictions.map((item, index) => (
                <p key={index} className="condo-policies__info-text">
                  {item}
                </p>
              ))}
            </div>
          </div>
        ) : null}
        {this.isWeeksResortCheckInDays() ? (
          <div className="condo-policies__info">
            <p className="condo-policies__point">
              <FormattedMessage id="weekly.stays" />:
            </p>
            <div className="condo-policies__info-text-wrapper">
              <div className="condo-policies__info-text-wrapper">
                {daysOfCheckIn.weeksResortCheckInDays.map((item, index) => (
                  <p key={index} className="condo-policies__info-text bold">
                    {item}
                  </p>
                ))}
              </div>
            </div>
          </div>
        ) : null}
        {this.isDefaultTextWeeksResortCheckInDays() ? (
          <div className="condo-policies__info">
            <p className="condo-policies__point">
              <FormattedMessage id="weekly.stays" />:
            </p>
            <div className="condo-policies__info-text-wrapper">
              <div className="condo-policies__info-text-wrapper">
                <FormattedMessage id="not.returned.days.of.check.in.for.weekly" />
              </div>
            </div>
          </div>
        ) : null}
        {this.isPointsResortCheckInDays() ? (
          <div className="condo-policies__info">
            <p className="condo-policies__point">
              <FormattedMessage id="short.stays" />:
            </p>
            <div className="condo-policies__info-text-wrapper">
              <div className="condo-policies__info-text-wrapper">
                {daysOfCheckIn.pointsResortCheckInDays.map((item, index) => (
                  <p key={index} className="condo-policies__info-text bold">
                    {item}
                  </p>
                ))}
              </div>
            </div>
          </div>
        ) : null}
        {minimumNights > ZERO && (
          <div className="condo-policies__info">
            <p className="condo-policies__point">
              <FormattedMessage id="minimum.night.stay" />:
            </p>
            <div className="condo-policies__info-text-wrapper">
              <p className="condo-policies__info-text bold">{minimumNights}</p>
            </div>
          </div>
        )}
        {!isUndefined(frontDeskInfo) && !isEmpty(frontDeskInfo) ? (
          <div className="condo-policies__info">
            <p className="condo-policies__point">
              <FormattedMessage id="front.desk.info" />:
            </p>
            <div className="condo-policies__info-text-wrapper">
              <p className="condo-policies__info-text bold">
                {frontDeskInfo.hoursOfOperationStartTime} - {frontDeskInfo.hoursOfOperationEndTime}
              </p>
              {frontDeskInfo.resortFrontDeskInfoIs24Hours && (
                <p className="condo-policies__info-text bold">
                  <FormattedMessage id="24.hours" />
                </p>
              )}
              {frontDeskInfo.resortFrontdeskInfoIsOnsite && (
                <p className="condo-policies__info-text bold">
                  <FormattedMessage id="onsite" />
                </p>
              )}
            </div>
          </div>
        ) : null}
        {!isEmpty(pets) && (
          <div className="condo-policies__info">
            <p className="condo-policies__point">
              <FormattedMessage id="pets" />:
            </p>
            <div className="condo-policies__info-text-wrapper">
              {pets.map((item, index) => (
                <p key={index} className="condo-policies__info-text">
                  {item}
                </p>
              ))}
            </div>
          </div>
        )}
        {!isEmpty(parking) && (
          <div className="condo-policies__info">
            <p className="condo-policies__point">
              <FormattedMessage id="parking" />:
            </p>
            <div className="condo-policies__info-text-wrapper">
              {parking.map((item, index) => (
                <p key={index} className="condo-policies__info-text">
                  {item}
                </p>
              ))}
            </div>
          </div>
        )}
        {!isEmpty(smoking) && (
          <div className="condo-policies__info">
            <p className="condo-policies__point">
              <FormattedMessage id="smoking" />:
            </p>
            <div className="condo-policies__info-text-wrapper">
              {smoking.map((item, index) => (
                <p key={index} className="condo-policies__info-text">
                  {item}
                </p>
              ))}
            </div>
          </div>
        )}
        {!isEmpty(orientation) && (
          <div className="condo-policies__info">
            <p className="condo-policies__point">
              <FormattedMessage id="other" />:
            </p>
            <div className="condo-policies__info-text-wrapper">
              {orientation.map((item, index) => (
                <p key={index} className="condo-policies__info-text">
                  {item}
                </p>
              ))}
            </div>
          </div>
        )}
        {this.isKnowBeforeYouGo() || this.isPossibleApplicableFees() ? (
          <div className="condo-policies__know-wrapper">
            {!isEmpty(urgentInfo) && (
              <div className="condo-policies__know-block">
                <h4 className="condo-policies__know-title">
                  <FormattedMessage id="know.before.you.go" />
                </h4>
                {!isEmpty(urgentInfo.generalUrgentInfoMessages) && (
                  <div className="condo-policies__know-text-wrapper">
                    {urgentInfo.generalUrgentInfoMessages.map((item, index) => (
                      <p key={index} className="condo-policies__know--text">
                        {parse(item)}
                      </p>
                    ))}
                  </div>
                )}
                {!isEmpty(urgentInfo.generalUrgentInfoForNonMembersMessages) && (
                  <div className="condo-policies__know-text-wrapper">
                    {urgentInfo.generalUrgentInfoForNonMembersMessages.map((item, index) => (
                      <p key={index} className="condo-policies__know--text">
                        {parse(item)}
                      </p>
                    ))}
                  </div>
                )}
                {urgentInfo.occupancyRestrictions && (
                  <div className="condo-policies__know-text-wrapper">
                    <p className="condo-policies__know--text">{parse(urgentInfo.occupancyRestrictions)}</p>
                  </div>
                )}
                {urgentInfo.oneInXRuleApplies && (
                  <div className="condo-policies__know-text-wrapper">
                    <p className="condo-policies__know--text">{parse(urgentInfo.oneInXRuleApplies)}</p>
                  </div>
                )}
                {urgentInfo.separateSwimmingPoolForGenders && (
                  <div className="condo-policies__know-text-wrapper">
                    <p className="condo-policies__know--text">
                      <FormattedMessage id="separate.swimming.pool.for.genders" />
                    </p>
                  </div>
                )}
                {urgentInfo.alcoholRestricted && (
                  <div className="condo-policies__know-text-wrapper">
                    <p className="condo-policies__know--text">
                      <FormattedMessage id="alcohol.restricted" />
                    </p>
                  </div>
                )}
                {urgentInfo.alcoholServedOnsite && (
                  <div className="condo-policies__know-text-wrapper">
                    <p className="condo-policies__know--text">
                      <FormattedMessage id="alcohol.served.onsite" />
                    </p>
                  </div>
                )}
                {urgentInfo.resortStayRestrictionsInfo && (
                  <div className="condo-policies__know-text-wrapper">
                    <p className="condo-policies__know--text">
                      {parse(urgentInfo.resortStayRestrictionsInfo)}
                    </p>
                  </div>
                )}
              </div>
            )}
            {this.isPossibleApplicableFees() && (
              <div className="condo-policies__know-block">
                <h4 className="condo-policies__know-title">
                  <FormattedMessage id="possible.applicable.fees" />
                </h4>
                {!isEmpty(mandatoryFees) && (
                  <div className="condo-policies__know-text-wrapper">
                    {mandatoryFees.map((item, index) => (
                      <div key={index} className="condo-policies__know-text-block">
                        {item.infoMessage && (
                          <p className="condo-policies__know--text">{parse(item.infoMessage)}</p>
                        )}
                        {item.additionalInfoMessage && (
                          <p className="condo-policies__know--text">{parse(item.additionalInfoMessage)}</p>
                        )}
                      </div>
                    ))}
                  </div>
                )}
                {!isEmpty(fees) && (
                  <div className="condo-policies__know-text-wrapper">
                    {fees.map((item, index) => (
                      <div key={index} className="condo-policies__know-text-block">
                        {item.infoMessage && (
                          <p className="condo-policies__know--text">{parse(item.infoMessage)}</p>
                        )}
                        {item.additionalInfoMessage && (
                          <p className="condo-policies__know--text">{parse(item.additionalInfoMessage)}</p>
                        )}
                      </div>
                    ))}
                  </div>
                )}
                {!isEmpty(housekeepingFees) && (
                  <div className="condo-policies__know-text-wrapper">
                    {housekeepingFees.map((item, index) => (
                      <div key={index} className="condo-policies__know-text-block">
                        {item.feeSchedule && (
                          <p className="condo-policies__know--text">{parse(item.feeSchedule)}</p>
                        )}
                        {item.infoMessage && (
                          <p className="condo-policies__know--text">{parse(item.infoMessage)}</p>
                        )}
                        {item.additionalInfoMessage && (
                          <p className="condo-policies__know--text">{parse(item.additionalInfoMessage)}</p>
                        )}
                        {item.mandatoryFee && (
                          <p className="condo-policies__know--text">
                            <FormattedMessage id="mandatory.fee" />
                          </p>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
            {!isEmpty(constructionDetails) && (
              <div className="condo-policies__know-block">
                <h4 className="condo-policies__know-title">
                  <FormattedMessage id="resort.construction.restrictions" />
                </h4>
                <div className="condo-policies__know-text-wrapper">
                  {constructionDetails.map((item, index) => (
                    <div key={index}>
                      {item.startDate && item.endDate && (
                        <p className="condo-policies__know--text">
                          {item.startDate} - {item.endDate}
                        </p>
                      )}
                      {item.infoMessage && (
                        <p className="condo-policies__know--text">{parse(item.infoMessage)}</p>
                      )}
                      {item.additionalInfoMessage && (
                        <p className="condo-policies__know--text">{parse(item.additionalInfoMessage)}</p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
    ) : null;
  }
}

