
import React from 'react';

import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Tooltip } from 'antd';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { isEmpty, isUndefined } from 'lodash';

import { Action, ThunkDispatch } from '@reduxjs/toolkit';

import {
  getLocalURI,
  isBackofficeFromPath,
  isExternalUrl,
  isReservationFromPath,
  isChangePasswordFromPath,
  RootState,
  isCondoDealsFromPath,
  isCondoEngineFromPath,
  VACATIONS_PAGE,
  GetHomeParams,
  UrlUtils,
  GetDisableNewTabOpen,
} from '@share/utils';
import { IMenuState, resetHotelsFullWithParams } from '@share/store/slices';
import { ILoginState } from '@share/store/slices';
import { ICondosState } from '@share/store/slices';
import { Routes } from '@share/constants';
import { IAccount } from '@share/common-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import CapitalLogo from '@assets/images/capital_logo.png';
import CondoresLogo from '@assets/images/tpi-white.png';
import ChevronDown from '@assets/icons/chevrons-down.png';

import './style.scss';

const HOTELS = 'Hotels';
const ALL_INCLUSIVES = 'All Inclusives';

interface IMapStateToProps {
  menuStore: IMenuState;
  loginStore: ILoginState;
  isGetawaysListPage: boolean;
  condosStore: ICondosState;
}

interface IMapDispatchToProps {
  resetHotelsFull: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps {
  isVacationRentals: boolean;
}

const ZERO = 0;

class FooterComponent extends React.Component<IProps> {

  timeout: any = null;

  reloadSamePage = async (event: any, namePage: string[], isAllInclusives: boolean) => {
    if (namePage.includes(event.target.innerText)) {
      const { isVacationRentals, loginStore } = this.props;
      const { account } = loginStore;
  
      event.preventDefault();

      UrlUtils.removeAll();
      
      await this.props.resetHotelsFull();

      const homeParams = GetHomeParams(account as IAccount);

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        if (isAllInclusives) {
          this.props.history.push(`/${account?.name}${Routes.AllInclusive}${homeParams}`);
        } else if (isVacationRentals) {
          this.props.history.push(`/${account?.name}${Routes.Search}/${VACATIONS_PAGE}${homeParams}`);
        } else {
          this.props.history.push(`/${account?.name}${Routes.Search}${homeParams}`);
        }
      }, 300);
    }
  };

  render(): React.ReactNode {
    const { isVacationRentals, menuStore, isGetawaysListPage, loginStore, condosStore, history, intl } = this.props;
    const { items, menuList, loading } = menuStore;
    const { account } = loginStore;
    const { condos } = condosStore;

    const footerMenu = items?.footer;

    const footerBackgroundColorDB = account?.footerBackgroundColorDB;
    const styleBackground: any = {};
    if (!isEmpty(footerBackgroundColorDB)) {
      styleBackground.backgroundColor = footerBackgroundColorDB;
    }

    const footerFontsColorDB = account?.footerFontsColorDB;
    const styleFonts: any = {};
    if (!isEmpty(footerFontsColorDB)) {
      styleFonts.color = footerFontsColorDB;
      styleBackground.color = footerFontsColorDB;
    }

    const isBackoffice = isBackofficeFromPath(history);
    const isReservation = isReservationFromPath(history);
    const isChangePassword = isChangePasswordFromPath(history);
    const isCondo = isCondoEngineFromPath(history);
    const isCondoDeal = isCondoDealsFromPath(history);

    if (account?.forceIframe) {
      return null;
    }

    const isRsi = account?.isRSITemplate && account?.isPrivateTokenAccountType;

    const logoDB = account?.logoDB;
    const footerLogo = account?.footerLogo;
    const footerLogoPosition = account?.footerLogoPosition;
    const footerTextUpperCase = account?.footerTextUpperCase;
    const footerMainMenu = account?.footerMainMenu;
    const forceFooterVertical = account?.forceFooterVertical;
    const footerDisplaySubMenuData = account?.footerDisplaySubMenuData;
    
    const isFooterLogoPositionTop = footerLogoPosition === 'top';

    const displayFooterMainMenu = footerMainMenu && !!menuList?.length;
    const displayFooterTop = !isEmpty(footerLogo) && isFooterLogoPositionTop;

    const homeParams = GetHomeParams(account as IAccount);

    const disableNewTabOpen = GetDisableNewTabOpen(account, items);

    return menuStore.error.length === ZERO && !loading ? (
      <div
        className={`footer ${footerTextUpperCase ? 'upper-case' : ''} ${isGetawaysListPage || isBackoffice || isReservation || isChangePassword ? 'footer__blue' : ''} footer__print`}
      >
        {!isBackoffice &&
        !isReservation &&
        !isChangePassword &&
        ((isCondo && !isCondoDeal) || (isCondoDeal && !condos)) &&
        (items?.essentials || items?.useful) ? (
          <div className="footer__trip-useful-wrapper">
            {items?.essentials && (
              <div className="footer__trip">
                <p className="footer__title secondary-font">
                  <FormattedMessage id="trip.essentials" />
                </p>
                <div className="footer__trip-wrapper">
                  {items?.essentials.map((item, index) => (
                    <div className="footer__trip-navigation" key={`${item.title}-${index}`}>
                      {isExternalUrl(item.url as string) ? (
                        <a
                          className="footer__useful-link-nav"
                          href={item.url}
                          target={disableNewTabOpen ? '_self' : '_blank'}
                          rel="noreferrer"
                        >
                          <FormattedMessage id={item.title} />
                          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                        </a>
                      ) : (
                        <Link
                          className="footer__useful-link-nav"
                          to={`/${account?.name}${getLocalURI(item.url as string)}`}
                        >
                          <FormattedMessage id={item.title} />
                          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                        </Link>
                      )}
                    </div>
                  ))}
                </div>
              </div>)}

            {items?.useful && (
              <div className="footer__useful">
                <p className="footer__title secondary-font">
                  <FormattedMessage id="useful.information" />
                </p>
                <div className="footer__useful-wrapper">
                  {items?.useful.map((item, index) => (
                    <div className="footer__userful-navigation" key={`${item.title}-${index}`}>
                      {item.isPopup || isExternalUrl(item.url as string) ? (
                        <a
                          className="footer__useful-link-nav"
                          target={disableNewTabOpen ? '_self' : '_blank'}
                          rel="noreferrer"
                          href={item.url}
                        >
                          <FormattedMessage id={item.title} />
                          {item.isPopup ? (
                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                          ) : null}
                        </a>
                      ) : (
                        <Link
                          className="footer__useful-link-nav"
                          to={`/${account?.name}${getLocalURI(item.url as string)}`}
                        >
                          <FormattedMessage id={item.title} />
                          {item.isPopup ? (
                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                          ) : null}
                        </Link>
                      )}
                    </div>
                  ))}
                </div>
              </div>)}
          </div>) : null}

        {(displayFooterTop || displayFooterMainMenu || footerDisplaySubMenuData && ((items?.brochureUrl && items?.showBrochure) || (items?.phone && items?.showContactInfo))) && (
          <div className={`footer__footer footer-top ${forceFooterVertical ? 'vertical' : 'normal-top-header'}`} style={styleBackground}>
            <div className={`footer__footer-top-container ${(displayFooterMainMenu && displayFooterTop) ? 'top-multiple' : ''} ${forceFooterVertical ? 'vertical' : ''}`}>
              {(displayFooterTop || footerDisplaySubMenuData) && (
                <div className="footer__footer-img" style={{ width: (displayFooterMainMenu && !forceFooterVertical) ? '50%' : '100%' }}>
                  {displayFooterTop && (
                    <img src={footerLogo} alt="" style={{ width: '100% '}} />)}

                  {(footerDisplaySubMenuData && ((items?.brochureUrl && items?.showBrochure) || (items?.phone && items?.showContactInfo))) && (
                    <>
                      {(items?.phone && items?.showContactInfo) && (
                        <div className="header__info-call" style={{ paddingLeft: '0px', marginTop: '10px', textTransform: 'none' }}>
                          <p className="header__call-text">
                            <FormattedMessage id="call.gent.now" />
                          </p>
                          <p className="header__call-text mobile">
                            <FormattedMessage id="talk.to.our.team" />:
                          </p>
                          <p className="header__call-text mobile-small">
                            <FormattedMessage id="concierge.contact.button" />:
                          </p>
                          <a href={`tel:${items?.phone}`} className="header__call-number" style={{ padding: '0px', borderRight: 'none' }}>
                            {items?.phone}
                          </a>
                        </div>)}

                      {(items?.brochureUrl && items?.showBrochure) && (
                        <a
                          href={items?.brochureUrl}
                          target={disableNewTabOpen ? '_self' : '_blank'}
                          rel="noreferrer"
                          className="header__link-member"
                          style={{ paddingLeft: '0px', marginTop: '6px', marginBottom: '20px' }}
                        >
                          <FormattedMessage id="member.brochure" />
                          <img src={ChevronDown} alt="" style={{ marginLeft: '8px', width: '12px', height: '12px' }} />
                        </a>)}
                    </>)}

                </div>)}

              {displayFooterMainMenu && (
                <div className="footer__footer-main-menu" style={{ width: (displayFooterTop && !forceFooterVertical) ? '50%' : '100%' }}>
                  <div className="container-fluid">
                    <div className="row" style={{ marginTop: '20px' }}>
                      {menuList?.map((item, index) => {
                        const forceLocalMenu = !item?.excludeCheck && import.meta.env.VITE_FORCE_MENU === 'true';
                        const isExternal = isExternalUrl((item?.url && !isUndefined(item?.url)) ? item?.url : '');
                        const localURI = item?.url?.replace(/^.*\/\/[^\/]+/, '');

                        const isAllInclusives = item?.url?.endsWith(Routes.AllInclusive);

                        const goTo = (isExternal && !forceLocalMenu) ?
                                          item?.url :
                                          !isUndefined(item?.url) ? 
                                              forceLocalMenu ?
                                                  `/${account?.name}${localURI}${homeParams}` :
                                                  `/${account?.name}${getLocalURI(item?.url)}${homeParams}` : 
                                              isVacationRentals ? 
                                                  `/${account?.name}${Routes.Search}/${VACATIONS_PAGE}${homeParams}` :
                                                  `/${account?.name}${Routes.Search}${homeParams}`;
  
                        return (
                          <div key={index} className={forceFooterVertical ? 'col-lg-12' : 'col-lg-4'} style={{ marginBottom: '15px' }}>
                            {(!forceLocalMenu && isExternal) ? (
                              <a className="header__link-nav" style={styleBackground} href={item?.url} target={disableNewTabOpen ? '_self' : '_blank'} rel="noreferrer">
                                {isRsi ? (<FormattedMessage id={item?.title} />) : item?.title}
                              </a>) : (
                              <Link
                                to={goTo as string}
                                className="header__link-nav"
                                // @ts-ignore
                                onClick={(event) => this.reloadSamePage(event, [ALL_INCLUSIVES, HOTELS], isAllInclusives)}
                                style={styleFonts}
                              >
                                {isRsi ? (<FormattedMessage id={item?.title} />) : item?.title}
                              </Link>)}
                          </div>);
                      })}
                    </div>
                  </div>
                </div>)}
            </div>
          </div>)}

        {((footerMenu && footerMenu?.length > 0 && !account?.isCapitalVacations) || (!isEmpty(footerLogo) && !isFooterLogoPositionTop)) ? (
          <div
            className={`footer__footer ${forceFooterVertical ? 'vertical' : ''}`}
            style={styleBackground}
          >
            {account?.isCapitalVacations ? (
              <img src={CapitalLogo} style={{ marginTop: '20px', marginBottom: '20px' }} />) : null}

            {(!isEmpty(footerLogo) && !isFooterLogoPositionTop) ? (
              <img src={footerLogo} style={{ marginTop: '20px', marginBottom: '20px' }} />) : null}

            {footerMenu?.map((item, index) => (
              <div className="footer__navigation" key={`${item.title}-${index}`}>
                {isEmpty(item.url) || item?.url === 'disabled' ? (
                  <div className="footer__link-nav disabled">
                    {item?.url === 'disabled' ? (
                      <Tooltip
                        title={intl.formatMessage({ id: '"menu.link.disable' })}
                        placement="top"
                      >
                        {item.title}
                      </Tooltip>
                    ) : (
                      item.title
                    )}
                  </div>
                ) : isExternalUrl(item.url as string) ? (
                  <a
                    className="footer__link-nav"
                    target={disableNewTabOpen ? '_self' : '_blank'}
                    rel="noreferrer"
                    href={item.url}
                    style={styleFonts}
                  >
                    {isRsi ? <FormattedMessage id={item.title} /> : item.title}
                  </a>
                ) : (
                  <Link
                    className="footer__link-nav"
                    to={`/${account?.name}${getLocalURI(item.url as string)}`}
                    style={styleFonts}
                  >
                    {isRsi ? <FormattedMessage id={item.title} /> : item.title}
                  </Link>
                )}
              </div>
            ))}
          </div>) : null}

        {account?.isCapitalVacations ? (
          <div className={`footer__footer capital-vacations`} style={styleBackground}>
            <div className="footer__footer-condores-top"></div>
            <div className="footer__footer-condores-container">
              <div className="footer__footer-condores-image-container">
                <div
                  className={`footer__footer-condores-image ${
                    account?.isCapitalVacationsCondoRes && account?.name !== 'condores'
                      ? 'condores'
                      : ''
                  }`}
                >
                  {!account?.isCapitalVacationsCondoRes ? (
                    <img src={CapitalLogo} style={{ marginTop: '20px', marginBottom: '20px' }} />
                  ) : null}
                  {account?.isCapitalVacationsCondoRes && account?.name === 'condores' ? (
                    <img src={CondoresLogo} style={{ marginTop: '20px', marginBottom: '20px' }} />
                  ) : null}
                  {account?.isCapitalVacationsCondoRes &&
                  account?.name !== 'condores' &&
                  !isEmpty(logoDB) ? (
                    <img src={logoDB} style={{ marginTop: '20px', marginBottom: '20px' }} />
                  ) : null}
                </div>
                <label>2024 Corporate Centre Drive</label>
                <label>Myrtle Beach, SC 29577</label>
                <label>844-777-Club</label>
              </div>
              <div className="footer__footer-condores-content-container">
                <label style={{ fontSize: '16px' }}>Follow Us</label>
                <div className="footer__footer-condores-content-socials">
                  <a
                    href="https://www.facebook.com/groups/225740804571396"
                    target="_blank"
                    className="footer__footer-condores-content-socials-item"
                  >
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/capitalvacations"
                    target="_blank"
                    className="footer__footer-condores-content-socials-item"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                  <a
                    href="https://www.instagram.com/capital.vacations/"
                    target="_blank"
                    className="footer__footer-condores-content-socials-item"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </div>
                <label>
                  Capital Vacations and Capital Vacations logo are registered trademarks,
                  OWNERewards is a trademark, and Capital Vacation Club and Home to A Million
                  Perfect Moments, Capital Lifestyles and Distinct Differences are service marks of
                  Capital Resorts Group, LLC. All other marks are registered marks of their
                  respective owners. Void where prohibited by law and where registration and/or
                  licensing requirements have not been met
                </label>
                <label>&#169; 2024 Capital Vacations Club. All Rights Reserved</label>
              </div>
            </div>
          </div>) : null}
      </div>
    ) : null;
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condosStore: state.condosStore,
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
    isGetawaysListPage: state.getawaysStore.premierescapes.isGetawaysListPage,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  resetHotelsFull: () => {
    dispatch(resetHotelsFullWithParams());
  },
});

const FooterComponentIntl = injectIntl(FooterComponent);

export const Footer = connect(mapStateToProps, mapDispatchToProps)(withRouter(FooterComponentIntl));
