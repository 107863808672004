import React, { Component } from 'react';

export class TravelItemLinkIcon extends Component {
  render(): React.ReactNode {
    return (
      <svg xmlns="http://www.w3.org/2000/svg">
        <path
          d="m6.667 0 2.195 2.195-4.667 4.667.943.943 4.667-4.667L12 5.333V0H6.667Z"
          fill="#C5C5C5"
        />
        <path
          d="M10.667 10.667H1.333V1.333H6L4.667 0H1.333C.598 0 0 .598 0 1.333v9.334C0 11.402.598 12 1.333 12h9.334c.735 0 1.333-.598 1.333-1.333V7.333L10.667 6v4.667Z"
          fill="#C5C5C5"
        />
      </svg>
    );
  }
}
