
const INTERNET_RATE_SUPPLIER = [
  'EXPS',
  'EXPSC',
  'NTP',
  'EXPP',
  'GAR',
  'EXP',
  'HBD',
  'ARN',
  'RCI',
  'HDO',
  'TBO',
  'RESTEL',
  'DID',
  '74',
  '61',
  '63',
  '64',
  '65',
  '66',
  '67',
  '68',
  '70',
  '76',
  '71',
  '72',
  '73'
];

const PRICELINE_SUPPLIER = [
  'PRL',
  'PRLN',
  'PRLP',
  'PRLPC',
  '69',
  '77',
  '62',
  '78'
];

const INTERNET_RATE_MLM_SUPPLIER = [
  '63',
  '64',
  '66',
  '67',
  '68',
  '70',
  '76',
  '71',
  '72',
  '73'
];

const EXPEDIA_MLM_SUPPLIER = ['74', '61', '65', 'EXPS', 'EXPP'];

const PRICELINE_MLM_SUPPLIER = ['69', '77', '62', '78'];

const HOTELS_MLM_SUPPLIER = ['HC'];

export const getSupplierMessage = (supplierId: string) => {
  if (PRICELINE_SUPPLIER.includes(supplierId)) {
    return 'priceline';
  }
  if (INTERNET_RATE_SUPPLIER.includes(supplierId)) {
    return 'internet.rate';
  }

  return 'internet.rate';
}

export const getMlmSupplierMessage = (supplierId: string) => {
  if (PRICELINE_MLM_SUPPLIER.includes(supplierId)) {
    return 'priceline.com';
  }
  if (EXPEDIA_MLM_SUPPLIER.includes(supplierId)) {
    return 'expedia.com';
  }
  if (HOTELS_MLM_SUPPLIER.includes(supplierId)) {
    return 'hotels.com';
  }
  if (INTERNET_RATE_MLM_SUPPLIER.includes(supplierId)) {
    return 'internet.rate';
  }

  return 'internet.rate';
}
