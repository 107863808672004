import { InputFilterType } from "@share/common-types";
import { IReservationsFilters } from '@share/common-types';

export type ComponentFunction = (text: string | boolean | number, record?: any) => JSX.Element;

type FilterKeys = keyof IReservationsFilters;

export interface IColumnMenu {
  description: string;
  key: string;
  display: boolean;
  type: InputFilterType;

  width?: number;
  displayConditions?: boolean;

  excludable?: boolean;
  sortable?: boolean;
  order?: number;
  decimals?: number;
    
  redirectPermission?: string[];
  defaultOrder?: string;

  incomingDateFormat?: string;

  filterFieldName?: FilterKeys;
  dropdowmOptions?: string[];
  fixed?: string,
  component?: ComponentFunction;

  children?: IColumnMenu[];
}

export const orderSort = (a: IColumnMenu, b: IColumnMenu) => a.order - b.order;
