import React from 'react';

import { FormattedMessage } from 'react-intl';
import { GuestReviews } from '@common-types';

import { isEmpty } from 'lodash';
import { Progress } from 'antd';

import './style.scss';

interface IProps {
  guestRatings: GuestReviews;
  refAnchor: React.RefObject<HTMLDivElement>;
}

function ReviewsComponent(props: IProps) {

  const renderRatingMessage = (value: number) => {
    if (value === 5) {
      return <FormattedMessage id="reviews.excellent" />
    }
    if (value < 5 && value >= 4) {
      return <FormattedMessage id="reviews.very.good" />
    }
    if (value < 4 && value >= 3) {
      return <FormattedMessage id="reviews.good" />
    }
    if (value < 3 && value >= 2) {
      return <FormattedMessage id="reviews.ok" />
    }
    if (value < 2 && value >= 1) {
      return <FormattedMessage id="reviews.poor" />
    }
    if (value < 1) {
      return <FormattedMessage id="reviews.terrible" />
    }

    return null;
  }

  const renderItem = (value: string, title: string) => {
    if (!isEmpty(value)) {
      const valueNumber = parseFloat(value);
      const percent = valueNumber / 5 * 100;
      return (
        <div className="col-md-4 guest-ratings__reviews-rating-item">
          <label className="rating">{value}/5 <label>{renderRatingMessage(valueNumber)}</label></label>
          <label className="title"><FormattedMessage id={title} /></label>
          <Progress percent={percent} showInfo={false} />
        </div>
      );
    }
    return null;
  }

  const { guestRatings, refAnchor } = props;

  return !!guestRatings ? (
    <div ref={refAnchor} className="guest-ratings">
      <h4 className="guest-ratings__title secondary-font">
        <FormattedMessage id="reviews.guest.ratings.title" />
      </h4>
      <div className="guest-ratings__reviews-wrapper">
        <div className="guest-ratings__reviews-rating">{guestRatings.overall}/5 {renderRatingMessage(parseFloat(guestRatings.overall))}</div>
        <div className="guest-ratings__reviews-overall"><FormattedMessage id="overall" /></div>
        <div className="container-fluid">
          <div className="row">
            {renderItem(guestRatings.amenities, 'reviews.amenities')}
            {renderItem(guestRatings.cleanliness, 'reviews.cleanliness')}
            {renderItem(guestRatings.comfort, 'reviews.comfort')}
            {renderItem(guestRatings.condition, 'reviews.condition')}
            {renderItem(guestRatings.neighborhood, 'reviews.neighborhood')}
            {renderItem(guestRatings.service, 'reviews.service')}
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export const ReviewsBase = ReviewsComponent;
