import moment from 'moment';

import { connect } from 'react-redux';

import AIGLogo from '@assets/images/aig-logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane, faQuestion, faSuitcase, faUsd, faUser } from '@fortawesome/free-solid-svg-icons';

import { FormattedMessage } from 'react-intl';

import { IInsuranceComplete } from '@common-types';

import './style.scss';

interface IProps {
  insurance: IInsuranceComplete;
}

function InsuranceConfirmedComponent(props: IProps) {

  const { insurance } = props;
  
  if (!insurance?.policyDetail) {
    return null;
  }
  

  return (
    <div className="insurance-container" style={{ marginTop: '30px' }}>
      {insurance?.policyDetail?.policyStatus !== 'Error' ? (
        <>
          <div className="insurance-container__header">
            <div className="header-title">Travel Guard &#174; <FormattedMessage id="insurance.travel.insurance" /></div>
            <div className="header-logo"><img src={AIGLogo} width="60" alt="aig_logo" /></div>
          </div>
          <div className="insurance-container__content">
            <div className="title"><FormattedMessage id="insurance.cover.your.trip" /></div>
            <div className="sub-title"><FormattedMessage id="insurance.ignore.unexpected" /></div>
            <div className="container-fluid" style={{ marginBottom: '10px' }}>
              <div className="row">
                <div className="col-md-6 insurance-option"><div className="icon-insurance"><FontAwesomeIcon icon={faUser} /></div><FormattedMessage id="insurance.family.member" /></div>
                <div className="col-md-6 insurance-option"><div className="icon-insurance"><FontAwesomeIcon icon={faPlane} /></div><FormattedMessage id="insurance.miss.connection" /></div>
                <div className="col-md-6 insurance-option"><div className="icon-insurance"><FontAwesomeIcon icon={faQuestion} /></div><FormattedMessage id="insurance.lose.passport" /></div>
                <div className="col-md-6 insurance-option"><div className="icon-insurance"><FontAwesomeIcon icon={faPlane} /></div><FormattedMessage id="insurance.trip.delayed" /></div>
                <div className="col-md-6 insurance-option"><div className="icon-insurance"><FontAwesomeIcon icon={faSuitcase} /></div><FormattedMessage id="insurance.baggage.lost" />.</div>
                <div className="col-md-6 insurance-option"><div className="icon-insurance"><FontAwesomeIcon icon={faUsd} /></div><FormattedMessage id="insurance.last.minute.accomodation" /></div>
              </div>
            </div>
            <div className="content-line"><label><FormattedMessage id="insurance.policy.number" />:</label><strong>{insurance?.policyDetail?.policyNumber}</strong></div>
            <div className="content-line"><label><FormattedMessage id="insurance.policy.price" />:</label>$ {insurance?.policyDetail?.productDetail?.price?.toFixed(2)}</div>
            <div className="content-line"><label><FormattedMessage id="insurance.policy.effective.date" />:</label>{moment(insurance?.policyDetail?.effectiveDate, 'yyyy-MM-DD').format('MM-DD-yyyy')}</div>
            <div className="content-line"><label><FormattedMessage id="insurance.policy.product.detail" />:</label>{insurance?.policyDetail?.productDetail?.productName}</div>
            <div className="content-line"><label><FormattedMessage id="insurance.policy.policy.holder" />:</label>{`${insurance?.policyDetail?.policyHolder?.firstName} ${insurance?.policyDetail?.policyHolder?.lastName}`}</div>
            <div className="content-line"><label><FormattedMessage id="insurance.policy.policy.holder.email" />:</label>{insurance?.policyDetail?.policyHolder?.email}</div>
            <div style={{ marginTop: '15px' }}><FormattedMessage id="insurance.policy.to_donload" /> <a href={insurance?.policyDetail?.policyLookup} target="_blank"><FormattedMessage id="insurance.message.click" /></a></div>
            <div style={{ marginTop: '15px' }}><FormattedMessage id="insurance.policy.disclaimer" /></div>
          </div>
        </>) : (
          <label style={{ color: 'red', fontWeight: 'bold' }}><FormattedMessage id="insurance.policy.not_created" /></label>)}
    </div>
  );
}

export const InsuranceConfirmed = connect()(InsuranceConfirmedComponent);
