import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { LUCKY_POINT_TRANSPARENT } from '@constants';

import BackgroundImage from '@assets/images/daily-benefits/heading-bg-img.jpg';

import './style.scss';

interface IProps {}

export class DailyBenefitsHeading extends Component<IProps> {
  render(): React.ReactNode {
    return (
      <div
        className="heading-wrapper"
        style={{
          background: `url(${BackgroundImage}) center / cover no-repeat ${LUCKY_POINT_TRANSPARENT}`,
        }}
      >
        <div className="heading-wrapper__wrapper">
          <h1 className="heading-wrapper__heading secondary-font">
            <FormattedMessage id="daily.benefits.header.heading" />
          </h1>
          <p className="heading-wrapper__paragraph">
            <FormattedMessage id="daily.benefits.header.paragraph" />
          </p>
        </div>
      </div>
    );
  }
}
