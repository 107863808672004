import React from 'react';
import moment from 'moment';

import { FormattedMessage } from 'react-intl';

import { formatDateCheckInOut } from '@share/utils';
import { DATE_FORMAT_CHECK_IN_OUT } from '@constants';
import { InputFilterType } from '@share/common-types';
import { IColumnMenu } from '@constants';

const MobileFirstColumnBase: IColumnMenu = {
  description: 'Description',
  key: 'description',
  type: InputFilterType.Custom,
  display: true,
  order: 1,
};

const FirstColumnBase: IColumnMenu = {
  description: 'Actions',
  key: 'actions',
  width: 180,
  type: InputFilterType.Custom,
  display: true,
  fixed: 'right',
  order: 1,
};

export enum Type {
  Condos,
  Hotels,
  Historicals
};

export type VisibleFunction = (visible: boolean, record?: any) => void;
export type QuoteBaseFunction = (quoteBase: string) => void;

const getActions = (type: Type, isMobile: boolean, record: any, setVisible: VisibleFunction, setQuoteBase: QuoteBaseFunction) => (
  <>
    <div style={{ marginTop: isMobile ? '15px' : '0px' }}>
      <a
        href="#"
        rel="noreferrer"
        onClick={(e) => { e.preventDefault(); setVisible(true, record); setQuoteBase(record.quoteBase) }}
      >
        <FormattedMessage id={type === Type.Historicals ? 'results.hotel.card.compare.modal.view.only' : 'results.hotel.card.compare.modal.view'} />
      </a>
    </div>
    <div>
      <a
        href={record.fileUrl}
        target="_blank"
        rel="noreferrer"
      >
        Download Quote
      </a>
    </div>
  </>);

const getMobileBaseColumn = (type: Type, record: any, setVisible: VisibleFunction, setQuoteBase: QuoteBaseFunction, getExtraColumns: (record: any) => JSX.Element) => (
  <>
    <div>
      <strong>Creation Date:</strong>
    </div>
    <div style={{ marginBottom: '6px' }}>
      <span>{moment(record.creationDate, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
    </div>
    {getExtraColumns(record)}
    <div>
      <strong>City:</strong>
    </div>
    <div style={{ marginBottom: '6px' }}>
      <span>{record.place}</span>
    </div>
    <div>
      <strong>Comment:</strong>
    </div>
    <div>
      <span>{record.comment}</span>
    </div>
    {getActions(type, true, record, setVisible, setQuoteBase)}
  </>);

const getHotelsMobileColumn = (record: any, setVisible: VisibleFunction, setQuoteBase: QuoteBaseFunction) => (
  <>
    {getMobileBaseColumn(Type.Hotels, record, setVisible, setQuoteBase, (record: any) => getHotelsMobileExtraColumn(record))}
  </>);

const getHotelsMobileExtraColumn = (record: any) => (
  <>
    <div>
      <strong>Check In:</strong>
    </div>
    <div style={{ marginBottom: '6px' }}>
      <span>{moment(record.checkIn, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
    </div>
    <div>
      <strong>Check Out:</strong>
    </div>
    <div style={{ marginBottom: '6px' }}>
      <span>{moment(record.checkOut, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
    </div>
  </>);

const getAvailableDateText = (availableDates: any[]) => (
  <div style={{ display: 'flex', flexDirection: 'row' }}>
    {availableDates?.map((date, index) => (
      <label key={index} style={{ marginRight: '6px' }}>{index + 1}:<strong style={{ marginLeft: '6px' }}>({date.availableDates?.length ? date.availableDates.length : 'NA'})</strong>,</label>
    ))}
  </div>
);

const getCondosMobileColumn = (record: any, setVisible: VisibleFunction, setQuoteBase: QuoteBaseFunction) => (
  <>
    {getMobileBaseColumn(Type.Condos, record, setVisible, setQuoteBase, (record: any) => getCondosMobileExtraColumn(record))}
  </>);

const getCondosMobileExtraColumn = (record: any) => (
  <>
    <div>
      <strong>Available Dates:</strong>
    </div>
    <div style={{ marginBottom: '6px' }}>
      {getAvailableDateText(record.availableDates)}
    </div>
  </>);

const getHistoricalMobileColumn = (record: any, setVisible: VisibleFunction, setQuoteBase: QuoteBaseFunction) => (
  <>
    {getMobileBaseColumn(Type.Historicals, record, setVisible, setQuoteBase, (record: any) => {
      const getMobileColumn = record.isCondo ? getCondosMobileExtraColumn : getHotelsMobileExtraColumn;
      return getMobileColumn(record);
    })}
  </>);

const ColumnsHotelsBase: IColumnMenu[] = [
  { description: 'Creation Date', key: 'creationDate', width: 150, incomingDateFormat: 'MM/DD/YYYY', display: true, order: 1, type: InputFilterType.Text, displayConditions: true },
  { description: 'Check In', key: 'checkIn', width: 150, incomingDateFormat: 'MM/DD/YYYY', display: true, order: 2, type: InputFilterType.Text, displayConditions: true },
  { description: 'Check Out', key: 'checkOut', width: 150, incomingDateFormat: 'MM/DD/YYYY', display: true, order: 3, type: InputFilterType.Text, displayConditions: true },
  { description: 'City', key: 'place', width: 450, display: true, order: 4, type: InputFilterType.Text, displayConditions: false },
  { description: 'Comment', key: 'comment', width: 300, display: true, order: 5, type: InputFilterType.Text, displayConditions: false },
];

const ColumnsCondosBase: IColumnMenu[] = [
  { description: 'Creation Date', key: 'creationDate', width: 150, incomingDateFormat: 'MM/DD/YYYY', display: true, order: 1, type: InputFilterType.Text, displayConditions: true },
  {
    description: 'Available Dates',
    key: 'availableDates',
    width: 280,
    display: true,
    order: 2,
    type: InputFilterType.Custom,
    component: (text: any, record?: any) => getAvailableDateText(record.availableDates)
  },
  { description: 'City', key: 'place', width: 450, display: true, order: 4, type: InputFilterType.Text, displayConditions: false },
  { description: 'Comment', key: 'comment', width: 300, display: true, order: 5, type: InputFilterType.Text, displayConditions: false },
];

const ColumnsHistoricalBase: IColumnMenu[] = [
  { description: 'Creation Date', key: 'creationDate', width: 150, incomingDateFormat: 'MM/DD/YYYY', display: true, order: 1, type: InputFilterType.Text, displayConditions: true },
  {
    description: 'Type',
    key: 'type',
    type: InputFilterType.Custom,
    width: 150,
    display: true,
    order: 2,
    component: (text: any, record?: any) => (<div><FormattedMessage id={record?.isCondo ? 'condo' : 'hotel'} /></div>)
  },
  { description: 'Check In', key: 'checkIn', width: 150, incomingDateFormat: 'MM/DD/YYYY', display: true, order: 3, type: InputFilterType.Text, displayConditions: true },
  { description: 'Check Out', key: 'checkOut', width: 150, incomingDateFormat: 'MM/DD/YYYY', display: true, order: 3, type: InputFilterType.Text, displayConditions: true },
  {
    description: 'Available Dates',
    key: 'availableDates',
    width: 280,
    display: true,
    order: 4,
    type: InputFilterType.Custom,
    component: (text: any, record?: any) => getAvailableDateText(record.availableDates)
  },
  { description: 'City', key: 'place', width: 450, display: true, order: 5, type: InputFilterType.Text, displayConditions: false },
  { description: 'Comment', key: 'comment', width: 300, display: true, order: 6, type: InputFilterType.Text, displayConditions: false },
];

export const GetColumns = (type: Type, isMobile: boolean, setVisible: VisibleFunction, setQuoteBase: QuoteBaseFunction) => {
  if (isMobile) {
    const getMobileColumn = type === Type.Condos ? getCondosMobileColumn : type === Type.Hotels ? getHotelsMobileColumn : getHistoricalMobileColumn;
    return [{
      ...MobileFirstColumnBase,
      component: (text: string, record: any) => getMobileColumn(record, setVisible, setQuoteBase)
    }];
  } else {
    const columnsBase = type === Type.Condos ? ColumnsCondosBase : type === Type.Hotels ? ColumnsHotelsBase : ColumnsHistoricalBase;
    return [{
        ...FirstColumnBase,
        component: (text: string, record: any) => getActions(type, false, record, setVisible, setQuoteBase)
      },
      ...columnsBase
    ];
  }
}

export const GetQuoteDatasourceData = (quote: any, originalList?: any[]) => {
  const isCondo = quote.isCondo;
  const data = {
    quoteBase: quote.quoteBaseId,
    creationDate: formatDateCheckInOut(quote.creationDate, DATE_FORMAT_CHECK_IN_OUT),
    place: quote.place,
    comment: quote.comment,
    fileUrl: quote.fileUrl,
    isCondo
  } as any;

  if (isCondo) {
    data.availableDates = originalList.filter(q => q.quoteBaseId === quote.quoteBaseId).map(({ availableDates }) => ({ availableDates }));
  } else {
    data.checkIn = formatDateCheckInOut(quote.checkIn, DATE_FORMAT_CHECK_IN_OUT);
    data.checkOut = formatDateCheckInOut(quote.checkOut, DATE_FORMAT_CHECK_IN_OUT);
  }

  return data;
}

export const HOTELS_KEY = '1';
export const CONDOS_KEY = '2';
export const HISTORICAL_KEY = '3';

export const GetMyQuotesTabs = (isMobile: boolean, dataSourceHotels: any[], dataSourceCondos: any[], dataSourceHistorical: any[], setVisibleHotels: VisibleFunction, setVisibleCondos: VisibleFunction, setQuoteBase: QuoteBaseFunction) => {
  return [
    { 
      key: HOTELS_KEY,
      title: 'hotels',
      columns: GetColumns(Type.Hotels, isMobile, setVisibleHotels, setQuoteBase),
      datasource: dataSourceHotels
    },
    { 
      key: CONDOS_KEY,
      title: 'condos',
      columns: GetColumns(Type.Condos, isMobile, setVisibleCondos, setQuoteBase),
      datasource: dataSourceCondos
    },
    {
      key: HISTORICAL_KEY,
      title: 'historical',
      columns: GetColumns(Type.Historicals, isMobile, (visible: boolean, record?: any) => {
        if (record.isCondo) {
          setVisibleCondos(visible);
        } else {
          setVisibleHotels(visible);
        }
      }, setQuoteBase),
      datasource: dataSourceHistorical
    },
  ];

}
