import React, { Component } from 'react';
import { Skeleton } from 'antd';
import './style.scss';

const mapArr = [1, 2, 3, 4];

export class CondoSkeletonFilters extends Component {
  render(): React.ReactNode {
    return (
      <div className="skeleton-filters">
        <div className="skeleton-filters__top">
          <div className="skeleton-filters__heading">
            <Skeleton active title paragraph={false} />
          </div>
          <div className="skeleton-filters__close">
            <Skeleton active title paragraph={false} round />
          </div>
        </div>

        {mapArr.map((item) => (
          <div className="skeleton-filters__filters" key={item}>
            <div className="skeleton-filters__subheading">
              <Skeleton active />
            </div>
          </div>
        ))}
      </div>
    );
  }
}

