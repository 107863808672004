
import LifeStyleValue from '@assets/images/lifestyles-value.png';
import LifeStyleChoice from '@assets/images/lifestyles-choice.png';
import LifeStyleUnlimited from '@assets/images/lifestyles-unlimited.png';
import LifeStyle from '@assets/images/lifestyles.png';

export const VALUE_LIFESTYLE = 'value';
export const VALUE_LIFESTYLE_DESC = 'Value';
export const CHOICE_LIFESTYLE = 'choice';
export const CHOICE_LIFESTYLE_DESC = 'Choice';
export const UNLIMITED_LIFESTYLE = 'unlimited';
export const UNLIMITED_LIFESTYLE_DESC = 'Unlimited';

export const GetCapitalWalletLog = (lifeStyle: string) => {
    const logo = lifeStyle?.toLocaleLowerCase() === VALUE_LIFESTYLE ?
                    LifeStyleValue :
                    lifeStyle?.toLocaleLowerCase() === CHOICE_LIFESTYLE ?
                    LifeStyleChoice :
                    lifeStyle?.toLocaleLowerCase() === UNLIMITED_LIFESTYLE ?
                        LifeStyleUnlimited :
                        LifeStyle;
    return logo;
}
