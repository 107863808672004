import React from 'react';
import Highlighter from 'react-highlight-words';

import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { AutoComplete, Select, Spin, Tooltip } from 'antd';
import { RefSelectProps, SelectValue } from 'antd/lib/select';

import { RouteComponentProps, withRouter } from 'react-router';
import { IReservationsState } from '@share/store/slices';

import { ITextSearch } from '@share/common-types';

import { ClearSvg } from '@share/assets';

import { faHotel, faIdBadge, faMapPin, faSearch, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BOOKING_ID_MATCHEDBY, ENTER_KEY, LEAD_NAME_MATCHEDBY, PROPERTY_NAME_MATCHEDBY } from '@share/constants';
import { ReservationsAPI } from '@share/api';

import './styles.scss';

const { Option } = Select;

const LIST_HEIGHT = 500;
const MIN_SEARCH_LENGTH = 3;
const ZERO = 0;
const ONE = 1;

const MatchedByIcons = {
  [PROPERTY_NAME_MATCHEDBY]: { icon: faHotel },
  [LEAD_NAME_MATCHEDBY]: { icon: faUser },
  [BOOKING_ID_MATCHEDBY]: { icon: faIdBadge },
};

interface IProps extends RouteComponentProps, WrappedComponentProps {
  store: IReservationsState;

  setTextSearch: (textSearch: ITextSearch) => void;
  setTextSearchLabel: (textSearchLabel: string) => void;
  onRefresh: () => void;
}

interface IState {
  textsSearch: ITextSearch[];
  loading: boolean;
  isDropdownOpen: boolean;
  isFocused: boolean;
}

class ReservationsAutocompleteTextSearchComponent extends React.Component<IProps, IState> {

  state: IState = {
    textsSearch: null,
    loading: false,
    isDropdownOpen: false,
    isFocused: false,
  };

  selectRef: React.RefObject<RefSelectProps> = React.createRef();
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  lastSearch = '';

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event: MouseEvent) => {
    if (
      (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target as Node)) ||
      (event.target as HTMLElement).classList.contains('locations-picker__fixed-bg')
    ) {
      this.setState({ isFocused: false, isDropdownOpen: false, textsSearch: [] });
      this.lastSearch = '';
    }
  };

  onFocus = () => {
    const { isFocused } = this.state;
    const { store } = this.props;
    const { textSearchLabel } = store;
    if (!isFocused) {
      this.onSearch(textSearchLabel || '');
    }
    this.setState({ isFocused: true });
  };

  onSearch = async (search: string) => {
    this.lastSearch = search;

    if (search.length >= MIN_SEARCH_LENGTH) {
      this.setState({ loading: true });
      let textsSearch: ITextSearch[] = null;
      try {
        const response = await ReservationsAPI.searchByText(search);  
        textsSearch = response.data.results;
      } finally {
        this.setState({ textsSearch , loading: false, isDropdownOpen: true });
      }
    } else {
      this.setState({ textsSearch: [], isDropdownOpen: false });
    }
  };

  getNotFoundContent = (): React.ReactNode => {
    const { loading } = this.state;
    const { store } = this.props;
    const { textSearchLabel } = store;

    let message: React.ReactNode;
    if (textSearchLabel?.length < MIN_SEARCH_LENGTH) {
      message = <FormattedMessage id="search.min.symbols" />;
    } else if (loading) {
      message = <FormattedMessage id="searching" />;
    } else {
      message = <FormattedMessage id="reservation.text.search.nothing.found" />;
    }

    return <div className="locations-dropdown__nothing-found">{message}</div>;
  };

  onChange = (value: SelectValue, option: any) => {
    const selectedTextLabel = value as string;
    if (isEmpty(selectedTextLabel) && !option) {
      this.props.setTextSearch(null);
      this.props.setTextSearchLabel(selectedTextLabel);
      this.props.onRefresh();
    } else {
      this.props.setTextSearch(null);
      this.props.setTextSearchLabel(selectedTextLabel);
    }
  };

  onKeyDown = (e: React.KeyboardEvent) => {
    const { isDropdownOpen, isFocused, textsSearch } = this.state;
    if (
      e.code === ENTER_KEY &&
      isDropdownOpen &&
      isFocused &&
      textsSearch?.length === ONE
    ) {
      const item = textsSearch[ZERO];
      this.setState({ isDropdownOpen: false, isFocused: true, textsSearch: [] }, () => {
        this.props.setTextSearch(item);
        this.props.setTextSearchLabel(item.value);
        this.props.onRefresh();
      });
    }
  };

  onSelect = (event: any) => {
    const { textsSearch } = this.state;
    const item = textsSearch[event as number];
    this.setState({ isDropdownOpen: false, isFocused: true, textsSearch: [] }, () => {
      this.props.setTextSearch(item);
      this.props.setTextSearchLabel(item.value);
      this.props.onRefresh();
    });
  }

  onClick = (event: React.MouseEvent<HTMLElement>) => {
    const { isFocused, isDropdownOpen } = this.state;
    const { store } = this.props;
    const { textSearchLabel } = store;

    if (
      textSearchLabel &&
      isFocused &&
      !isDropdownOpen &&
      event.target &&
      (event.target as HTMLElement).classList.contains('ant-select-selection-search-input')
    ) {
      this.onSearch(textSearchLabel);
    } else {
      this.setState({ isDropdownOpen: false, isFocused: true, textsSearch: [] });
    }
  };

  getPopupContainer = () => {
    return this.wrapperRef ? this.wrapperRef.current : document.body;
  };

  render(): React.ReactNode {
    const { textsSearch, isDropdownOpen } = this.state;
    const { store } = this.props;
    const { textSearchLabel } = store;

    return (
      <div className="reservations-autocomplete-search__text-search" ref={this.wrapperRef}>
        {isEmpty(textSearchLabel) ? (<Tooltip placement="top" title={this.props.intl.formatMessage({id: 'reservation.search.text'})}><FontAwesomeIcon className="search-icon" icon={faSearch} /></Tooltip>) : null }
        <AutoComplete
          onKeyDown={this.onKeyDown}
          virtual={false}
          showSearch={true}
          placeholder={<FormattedMessage id="reservation.search.enter.text" />}
          onChange={this.onChange}
          onSearch={this.onSearch}
          onFocus={this.onFocus}
          showArrow={false}
          ref={this.selectRef}
          open={isDropdownOpen}
          defaultActiveFirstOption={false}
          filterOption={() => true}
          autoClearSearchValue={false}
          getPopupContainer={this.getPopupContainer}
          listHeight={LIST_HEIGHT}
          allowClear={true}
          value={textSearchLabel}
          notFoundContent={this.getNotFoundContent()}
          onClick={this.onClick}
          onSelect={this.onSelect}
          clearIcon={
            <span>
              {this.state.loading && this.state.isDropdownOpen ? <Spin /> : <ClearSvg />}
            </span>
          }
        >
          {(textsSearch?.length && (
            textsSearch
              .map((text, index) => {
                const icon = get(MatchedByIcons, `${text.matchedBy}.icon`, faMapPin);
                const inner = (
                  <>
                    <span className="reservations-autocomplete-search__text-search-icon"><FontAwesomeIcon icon={icon} /></span>
                    <span>
                      <Highlighter
                        highlightClassName="bold-text"
                        searchWords={[this.lastSearch.trim()]}
                        autoEscape={true}
                        textToHighlight={text.value}
                      />
                    </span>
                  </>
                );

                return (
                  <Option key={index} value={index}>
                    {inner}
                  </Option>
                );
              })
          ))}
        </AutoComplete>
      </div>);
  }
}

const ComponentWithIntl = injectIntl(ReservationsAutocompleteTextSearchComponent);

export const ReservationsAutocompleteTextSearch = connect()(withRouter(ComponentWithIntl));
