import React from 'react';
import { DEFAULT_MEDIA_POINT } from '@share/constants';

interface IState {
  matches: boolean;
}

export class Responsive extends React.Component<React.ReactNode, IState> {
  state: IState = {
    matches: window.matchMedia(`(min-width: ${DEFAULT_MEDIA_POINT}px)`).matches,
  };

  componentDidMount(): void {
    const handler = (e: IState) => this.setState({ matches: e.matches });
    window.matchMedia(`(min-width: ${DEFAULT_MEDIA_POINT}px)`).addListener(handler);
  }

  render(): React.ReactNode {
    return React.Children.map(this.props.children, (child) => {
      return React.cloneElement(child as React.ReactElement, {
        matches: this.state.matches,
      });
    });
  }
}
