import moment from 'moment';
import axios, { Canceler } from 'axios';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import {
  IBounds,
  IWeek,
  IClientCash,
  ISessionKey,
  IWeeksCounters,
  SupplierType,
} from '@share/common-types';
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_MAP_LIMIT,
  WEEKS_RECENT_SEARCHES_LABEL,
  Urls,
  DEFAULT_PAGE_SIZE,
} from '@share/constants';
import {
  AppThunk,
  axiosInstance,
  getHeaders,
  getSelectedCurrency,
  GetWeeksFilters,
  hasChangedCurrency,
} from '@share/utils';
import { getTimeout } from '@share/utils';
import { weeksDatesActions, weeksLocationsActions, weeksFiltersActions } from '@share/store/slices';

import { SearchTypeEnum } from '@share/common-types';

const zero = 0;

export interface IWeekState {
  isSearch: boolean;
  isWidget: boolean;
  isCollapse: boolean;
  weeks: IWeek[];
  mapWeeks: IWeek[];
  selectedCompareWeeks: IWeek[];
  selectedWeeks: IWeek;

  counters: IWeeksCounters;

  loading: boolean;
  loadingMore: boolean;

  error: string;
  pageNumber: number;
  sessionKey: ISessionKey;
  isSessionExpired: boolean;
  isMapView: boolean;
  isMapLoading: boolean;
  bounds: IBounds;

  selectedWeeksSearchClientCash: IClientCash;
  suppliersList: string[];
}

const initialState: IWeekState = {
  isSearch: false,
  isWidget: false,
  isCollapse: false,
  weeks: null,
  mapWeeks: null,
  selectedCompareWeeks: [],
  loading: false,
  loadingMore: false,
  error: '',
  pageNumber: DEFAULT_PAGE_NUMBER,
  counters: null,
  sessionKey: null,
  isSessionExpired: false,
  bounds: null,

  selectedWeeks: null,
  isMapView: false,
  isMapLoading: false,
  selectedWeeksSearchClientCash: null,
  suppliersList: [
    SupplierType.Rci,
    SupplierType.Intervals,
    SupplierType.CondoWarehouse,
    SupplierType.CapitalVactions,
    SupplierType.Guesty,
    SupplierType.HolidaySystems,

    SupplierType.RESTEL,
    SupplierType.DID,
    SupplierType.GAR,
    SupplierType.HDO,
    SupplierType.HBD,
    SupplierType.TBO,
    SupplierType.PRLN,
    SupplierType.EXPP,
    SupplierType.CIR,
    SupplierType.TST,
  ],
};

const weeksSlice = createSlice({
  name: 'weeksSearch',
  initialState,
  reducers: {
    setIsSearch: (state: IWeekState, { payload }: PayloadAction<boolean>) => {
      state.isSearch = payload;
    },
    setIsCollapse: (state: IWeekState, { payload }: PayloadAction<boolean>) => {
      state.isCollapse = payload;
    },
    setLoading: (state: IWeekState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setLoadingMore: (state: IWeekState, { payload }: PayloadAction<boolean>) => {
      state.loadingMore = payload;
    },
    setError: (state: IWeekState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setPageNumber: (state: IWeekState, { payload }: PayloadAction<number>) => {
      state.pageNumber = payload;
    },
    setCounters: (state: IWeekState, { payload }: PayloadAction<IWeeksCounters>) => {
      state.counters = payload;
    },
    setSelectedWeeksSearchClientCash: (
      state: IWeekState,
      { payload }: PayloadAction<IClientCash>,
    ) => {
      state.selectedWeeksSearchClientCash = payload;
    },
    setSelectedCompareWeeks: (state: IWeekState, { payload }: PayloadAction<IWeek[]>) => {
      state.selectedCompareWeeks = payload;
    },
    setWeeksSessionKey: (state: IWeekState, { payload }: PayloadAction<ISessionKey>) => {
      state.sessionKey = payload;
    },
    setIsSessionExpired: (state: IWeekState, { payload }: PayloadAction<boolean>) => {
      state.isSessionExpired = payload;
    },
    setWeeks: (state: IWeekState, { payload }: PayloadAction<IWeek[]>) => {
      state.weeks = payload;
    },
    setBounds: (state: IWeekState, { payload }: PayloadAction<IBounds>) => {
      state.bounds = payload;
    },
    setIsWidget: (state: IWeekState, { payload }: PayloadAction<boolean>) => {
      state.isWidget = payload;
    },
    setIsMapView: (state: IWeekState, { payload }: PayloadAction<boolean>) => {
      state.isMapView = payload;
    },
    setMapLoading: (state: IWeekState, { payload }: PayloadAction<boolean>) => {
      state.isMapLoading = payload;
    },
    addWeeks: (state: IWeekState, { payload }: PayloadAction<IWeek[]>) => {
      state.weeks = [...(state.weeks || []), ...payload];
    },
    setSelectedWeeks: (state: IWeekState, { payload }: PayloadAction<IWeek>) => {
      state.selectedWeeks = payload;
    },
    setMapWeeks: (state: IWeekState, { payload }: PayloadAction<IWeek[]>) => {
      state.mapWeeks = payload;
    },
    addMapWeek: (state: IWeekState, { payload }: PayloadAction<IWeek>) => {
      state.mapWeeks.push(payload);
    },
    addMapWeeks: (state: IWeekState, { payload }: PayloadAction<IWeek[]>) => {
      state.mapWeeks = [...(state.mapWeeks || []), ...payload];
    },
    setDefaultValues: () => {
      return initialState;
    },
    resetWeeks: (state: IWeekState) => {
      state.weeks = [];
      state.selectedWeeks = null;
      state.error = '';
      state.pageNumber = DEFAULT_PAGE_NUMBER;
    },
  },
});

export const weeksActions = weeksSlice.actions;

export const weeksReducer = weeksSlice.reducer;

let cancelRequest: Canceler;
let expirationTimer: number;

export const GetWeeks = (searchType: SearchTypeEnum): AppThunk => {
  return async (dispatch) => {
    if (searchType === SearchTypeEnum.Pagination) {
      dispatch(weeksActions.setLoadingMore(true));
    } else {
      dispatch(weeksActions.setLoading(true));
    }

    await dispatch(ApplyWeekData());

    setTimeout(() => {
      dispatch(GetWeeksBase(searchType));
    }, 300);
  };
};

export const GetWeeksInitial = (searchType: SearchTypeEnum): AppThunk => {
  return async (dispatch) => {
    dispatch(GetWeeksBase(searchType));
  };
};

const ApplyWeekData = (): AppThunk => {
  return async (dispatch) => {
    dispatch(weeksLocationsActions.applyLocation());
    dispatch(weeksDatesActions.applyWeeksDates());
  };
};

export const RefreshWeekSearch = (): AppThunk => {
  return async (dispatch) => {
    dispatch(GetWeeksInitial(SearchTypeEnum.NewSearch));
  };
};

const GetWeeksBase = (searchType: SearchTypeEnum): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const {
        weeksStore,
        weeksLocationsStore,
        weeksDatesStore,
        weeksFiltersStore,
        loginStore,
      } = getState();

      const { location } = weeksLocationsStore;
      const { date, period } = weeksDatesStore;
      const { pageNumber, sessionKey } = weeksStore;
      const { sortBy } = weeksFiltersStore;
      const { account } = loginStore;

      dispatch(weeksActions.setSelectedWeeks(null));
      dispatch(weeksActions.setError(null));

      const number = searchType === SearchTypeEnum.Pagination ? pageNumber + 1 : pageNumber;
      if (searchType === SearchTypeEnum.Pagination) {
        dispatch(weeksActions.setLoadingMore(true));
        dispatch(weeksActions.setPageNumber(number));
      } else {
        dispatch(weeksActions.setLoading(true));
        dispatch(weeksActions.setBounds(null));
        dispatch(weeksActions.setCounters(null));
        dispatch(weeksActions.setMapWeeks([]));
        dispatch(weeksActions.setWeeks([]));
        dispatch(weeksActions.setSelectedCompareWeeks([]));
        dispatch(weeksActions.setPageNumber(DEFAULT_PAGE_NUMBER));
      }

      if (!weeksStore.weeks) {
        dispatch(weeksActions.setWeeks([]));
      }

      if (cancelRequest) {
        cancelRequest();
      }

      const requestBody: any = {
        propertyRequest: {
          location,
          residency: 'US',
          checkIn: moment(date, 'MM/DD/yyyy').format('yyyy-MM-DD'),
          dateRange: period,
        },
        filter: GetWeeksFilters(weeksFiltersStore),
        pageNumber: number,
        pageSize: DEFAULT_PAGE_SIZE,
        currency: getSelectedCurrency(account),
        sortBy,
      };

      const isReuseSessionKey = hasChangedCurrency()
        ? false
        : [SearchTypeEnum.Pagination, SearchTypeEnum.SortsFilters].includes(searchType);

      if (sessionKey && isReuseSessionKey) {
        requestBody.sessionKey = { ...sessionKey };
      }

      dispatch(weeksActions.setIsSearch(true));

      const { data } = await axiosInstance.post(Urls.WeeksSearch, requestBody, {
        ...getHeaders(),
        cancelToken: new axios.CancelToken((canceler: Canceler) => (cancelRequest = canceler)),
      });

      dispatch(weeksActions.setLoading(false));

      if (data.sessionKey) {
        const sessionKey = { ...data.sessionKey } as ISessionKey;
        dispatch(weeksActions.setWeeksSessionKey(sessionKey));
        dispatch(weeksActions.setIsSessionExpired(false));

        if (expirationTimer) {
          clearTimeout(expirationTimer);
        }

        // @ts-ignore
        expirationTimer = setTimeout(() => {
          dispatch(weeksActions.setIsSessionExpired(true));
        }, getTimeout(sessionKey?.expireDate));
      }

      dispatch(weeksActions.setLoadingMore(false));

      dispatch(weeksActions.setCounters(data.counters ? data.counters : null));

      if (searchType === SearchTypeEnum.Pagination) {
        dispatch(weeksActions.addWeeks(data.searchProperties));
        dispatch(weeksActions.addMapWeeks(data.searchProperties));
      } else {
        dispatch(weeksActions.setWeeks(data.searchProperties.slice(zero, 50)));
        dispatch(weeksActions.setMapWeeks(data.searchProperties.slice(zero, DEFAULT_MAP_LIMIT)));
      }

      if (searchType === SearchTypeEnum.NewSearch || searchType === SearchTypeEnum.SortsFilters) {
        dispatch(weeksActions.setBounds(data.bounds ? data.bounds : null));
      }

      if (searchType === SearchTypeEnum.NewSearch) {
        // Adding the search to the storage
        const recentSearchesStorage = localStorage.getItem(WEEKS_RECENT_SEARCHES_LABEL);
        const recentSearchesObject = !isEmpty(recentSearchesStorage)
          ? JSON.parse(recentSearchesStorage)
          : [];
        const recentSearchesFiltered = recentSearchesObject?.length
          ? recentSearchesObject.filter((search: any) =>
              moment()
                .startOf('day')
                .isSameOrBefore(moment(search?.propertyRequest?.checkIn, 'yyyy-MM-DD')),
            )
          : [];
        const recentSearchesValid =
          recentSearchesFiltered.length > 10
            ? recentSearchesFiltered.slice(0, 8)
            : recentSearchesFiltered;
        const currentSearchFinal = {
          ...requestBody,
          propertyRequest: {
            ...requestBody.propertyRequest,
            location: {
              ...requestBody.propertyRequest.location,
              type: undefined,
            },
          },
        };
        const currentSearchStr = JSON.stringify(currentSearchFinal);

        if (!recentSearchesFiltered.map((r: any) => JSON.stringify(r)).includes(currentSearchStr)) {
          localStorage.setItem(
            WEEKS_RECENT_SEARCHES_LABEL,
            JSON.stringify([currentSearchFinal, ...recentSearchesValid]),
          );
        }
      }
    } catch (error) {
      console.error(error);

      if (!(error instanceof axios.Cancel)) {
        const code = error?.response?.data?.code?.toString();

        const codeToSent = ['invalid_leadtime'];
        dispatch(weeksActions.setError(codeToSent.includes(code) ? code : 'weeks.search.error'));
        dispatch(weeksActions.setLoading(false));
        dispatch(weeksActions.setLoadingMore(false));
      }
    }
  };
};

export const ResetWeeksFull = (): AppThunk => {
  return async (dispatch) => {
    dispatch(weeksDatesActions.resetWeeksDates());
    dispatch(weeksLocationsActions.resetWeeksLocations());

    dispatch(weeksFiltersActions.resetFilters());

    dispatch(weeksActions.setIsSearch(false));
    dispatch(weeksActions.setError(''));
    dispatch(weeksActions.setBounds(null));
    dispatch(weeksActions.setCounters(null));
    dispatch(weeksActions.setWeeks(null));
    dispatch(weeksActions.setMapWeeks(null));
    dispatch(weeksActions.setPageNumber(DEFAULT_PAGE_NUMBER));
    dispatch(weeksActions.setIsCollapse(false));
  };
};

export const ResetWeeksFullWithParams = (): AppThunk => {
  return async (dispatch) => {
    dispatch(ResetWeeksFull());
    dispatch(ApplyWeekData());
  };
};
