export interface IBenefits {
  items: IBenefitsItems[];
  fromHome?: boolean;
}

export interface IBenefitsItems {
  title: string;
  titleForce?: string;
  imageUrl: string;
  description: string;
  description2?: string;
  description3?: string;
  description4?: string;
  description5?: string;
  buttonMessage?: string;
  errorMessage?: string;
  url: string;
  type: string;
  childItems?: IBenefitsItems[];
}

export enum BenefitsEnum {
  CarRentals = 'car-rentals',
  Getaways = 'getaways',
  Condos = 'condos',
  Homes = 'homes',
  Cruises = 'cruises',
}
