import * as H from 'history';
import { IntlShape } from 'react-intl';

import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import isUndefined from 'lodash/isUndefined';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import differenceInDays from 'date-fns/differenceInDays';

import {
  CondoRequestTypeEnum,
  ICondoSearchRequest,
  RatingEnum,
  ICondoUnit,
} from '@share/common-types';
import {
  ICondoStates,
  ICountriesCode,
  SupplierType,
} from '@share/common-types';
import { IDateRange } from '@share/common-types';
import {
  HOTEL_ID_ELEMENT,
  PACKAGE_ID_ELEMENT,
  GUEST_TYPE_MEMBER,
  VideoExtensionEnum,
} from '@constants';
import { CONDO_UNITS_SEARCH_LABEL, Routes, CONDO_UNITS_SESSION_KEY_LABEL } from '@share/constants';
import { ICondoFiltersState, condoFiltersInitialState } from '@share/store/slices';
import { UrlUtils, getCondoRoutePath, getFakeISODate, renderPhone } from '@share/utils';

const defaultDistance = 20;
const THREE = 3;
const ZERO = 0;
const FULL = 'Full';
const USA = 'USA';
const USA_FULL_NAME = 'United States of America';
const STAY_DURATION = 'week';

export const getCondoId = (): number => {
  return Number(location.pathname.split('/')[HOTEL_ID_ELEMENT]);
};

export const getAvailabilityId = (): string => {
  return location.pathname.split('/')[PACKAGE_ID_ELEMENT];
};

export const openCondoPage = (condoId: number, history: H.History<H.LocationState>): void => {
  history.push(getCondoRoutePath(condoId, history));
};

export const getCondoSearchBodyDetails = (
  condoRequest: any,
): ICondoSearchRequest => {
  const {
    location,
    adultsCount,
    bedroomsCount,
    childrenCount,
    requestType,
    checkIn,
    checkOut,
    months,
    stayFor
  } = condoRequest;

  const res: ICondoSearchRequest = {
    condosRequest: {
      location: {
        geoLocation: location?.geoLocation,
        id: location?.id,
        locationType: location?.locationType,
        name: location?.name,
      },
      adultsCount: adultsCount? adultsCount : 2,
      bedroomsCount: bedroomsCount ? bedroomsCount : 1,
      childrenCount: childrenCount ? childrenCount : 0,
    }
  } as ICondoSearchRequest;

  if (requestType === CondoRequestTypeEnum.Strict) {
    res.condosRequest.checkIn = checkIn;
    res.condosRequest.checkOut = checkOut;
  } else if (requestType === CondoRequestTypeEnum.Flexible) {
    res.condosRequest.stayDuration = stayFor ? stayFor : STAY_DURATION;
    res.condosRequest.months = months ? months.map((dateString: string) => getFakeISODate(dateString)) : [];
  }

  return res;
};

export const onCondoPage = (accountName: string, condoId: number, history: H.History<H.LocationState>): void => {
  UrlUtils.removeFromUrl(CONDO_UNITS_SEARCH_LABEL);
  UrlUtils.removeFromUrl(CONDO_UNITS_SESSION_KEY_LABEL);

  history.push(`/${accountName}${Routes.Condo}/${condoId}/${location.search}`);
};

export const getMemberPhone = (memberType: string, phone: string): string => {
  return memberType === GUEST_TYPE_MEMBER ? renderPhone(phone) : '';
};

export const getState = (
  country: string,
  countriesCode: ICountriesCode,
  supplierType: string,
): ICondoStates[] => {
  if (supplierType !== SupplierType.Intervals) {
    return country
      ? countriesCode.rciCountries.find(
        (item) => item.name === (country === USA_FULL_NAME ? USA : country),
      ).states
      : [];
  } else {
    return country
      ? countriesCode.intervalCountries.find((item) => item.name === country).states
      : [];
  }
};

export const isPostalCode = (
  country: string,
  countriesCode: ICountriesCode,
  supplierType: string,
): boolean => {
  if (supplierType !== SupplierType.Intervals) {
    return countriesCode.rciCountries?.some((item) => item.name === country && item.isZipRequired);
  } else {
    return countriesCode.intervalCountries?.some(
      (item) => item.name === country && item.isStateRequired
    );
  }
};

export const getNightsCount = (dateRange: IDateRange): number => {
  return differenceInDays(new Date(dateRange.to), new Date(dateRange.from));
};

export const getCondoTextRating = (rating: number): string => {
  if (rating >= RatingEnum.Excellent) {
    return 'rating.excellent';
  } else if (rating >= RatingEnum.Great) {
    return 'rating.great';
  } else if (rating >= RatingEnum.Good) {
    return 'rating.good';
  } else if (rating >= RatingEnum.Average) {
    return 'rating.average';
  } else if (rating >= RatingEnum.Poor) {
    return 'rating.poor';
  } else if (rating >= RatingEnum.Bad) {
    return 'rating.bad';
  }

  return 'rating.bad';
};

export const isVideo = (media: string): boolean => {
  return (
    media?.substr(media?.length - THREE) === VideoExtensionEnum.MOV ||
    media?.substr(media?.length - THREE) === VideoExtensionEnum.MP4
  );
};

export const hideBrowserAutoFill = (string: string, number: number): string => {
  return string.substr(ZERO, number) + '\u200e' + string.substr(number);
};

export const isCondoFiltersEmpty = (currentFilters: ICondoFiltersState): boolean => {
  return isEqual(omit(currentFilters, ['sortType']), omit(condoFiltersInitialState, ['sortType']));
};

export const getCondoFiltersCount = (currentFilters: ICondoFiltersState): number => {
  let res = ZERO;
  const objToCount: { [key: string]: (string | number | boolean)[] } = omit(currentFilters, [
    'sortType',
    'distanceInMiles',
    'propertyName',
    'isAllInclusiveOnly',
  ]);

  Object.keys(objToCount).forEach((key) => {
    if (objToCount[key] && objToCount[key].length) {
      res += objToCount[key].length;
    }
  });

  if (currentFilters.distanceInMiles !== defaultDistance) {
    res++;
  }

  if (currentFilters.isAllInclusiveOnly) {
    res++;
  }

  return res;
};

export const getCountryCode = (
  value: string,
  supplierType: string,
  condosCountriesCode: ICountriesCode,
): string => {
  return supplierType === SupplierType.Intervals
    ? condosCountriesCode.intervalCountries.find((item) => item.name === value)?.code
    : '';
};

export const getCountryIsoCode = (
  value: string,
  supplierType: string,
  condosCountriesCode: ICountriesCode,
): string => {
  return supplierType === SupplierType.Intervals
    ? condosCountriesCode.intervalCountries.find((item) => item.name === value)?.isoCode
    : condosCountriesCode.rciCountries.find((item) => item.name === value)?.isoCode;
};

export const getCountryByCode = (
  value: string,
  supplierType: string,
  condosCountriesCode: ICountriesCode,
): string => {
  return supplierType === SupplierType.Intervals
    ? condosCountriesCode.intervalCountries.find((item) => item.code === value).name
    : value;
};

export const getFullUnitName = (unit: ICondoUnit, intl: IntlShape): string => {
  return !isUndefined(unit.bedRoomType)
    ? `${unit.id} ${unit.bedRoomType} ${intl.formatMessage({ id: 'max.sleeps' })}:${unit.maxOccupancy
    }, ${intl.formatMessage({ id: 'private' })}:${unit.privacyOccupancy} ${!isUndefined(unit.bathroom)
      ? unit.bathroom === FULL
        ? `, ${startCase(toLower(unit.bathroom))} ${intl.formatMessage({ id: 'bathroom' })}`
        : `, ${unit.bathroom}`
      : ''
    }${!isUndefined(unit.kitchenType)
      ? `, ${startCase(toLower(unit.kitchenType))} ${intl.formatMessage({ id: 'kitchen' })}`
      : ''
    }`
    : `${unit.id} ${intl.formatMessage(
      { id: 'bedrooms.count' },
      { count: unit.bedroomsCount },
    )} ${intl.formatMessage({ id: 'max.sleeps' })}:${unit.maxOccupancy}, ${intl.formatMessage({
      id: 'private',
    })}:${unit.privacyOccupancy} ${!isUndefined(unit.bathroom) ? `, ${startCase(toLower(unit.bathroom))}` : ''
    }${!isUndefined(unit.kitchenType)
      ? `, ${startCase(toLower(unit.kitchenType))} ${intl.formatMessage({ id: 'kitchen' })}`
      : ''
    }`;
};
