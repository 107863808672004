import React from 'react';
import { List } from 'antd';
import { FormattedMessage } from 'react-intl';
import { CheckMarkSvg } from '@assets';
import { IAmenities } from '@common-types';
import './style.scss';
import { getAmenityImage } from '@utils';

interface IProps {
  amenities: IAmenities[];
  matches?: boolean;
}

interface IState {
  isFullAmenities: boolean;
}

const ZERO = 0;
const MAX_AMENITIES_COUNT = 10;

export class AmenityComponent extends React.Component<IProps, IState> {
  state: IState = {
    isFullAmenities: false,
  };

  toggleAmenities = (): void => {
    this.setState(({ isFullAmenities }) => ({
      isFullAmenities: !isFullAmenities,
    }));
  };

  resultAmenities = (): React.ReactNode => {
    const { amenities, matches } = this.props;

    if (!amenities.length) {
      return;
    }

    const amenitiesToShow: IAmenities[] = this.state.isFullAmenities
      ? amenities
      : amenities.slice(ZERO, MAX_AMENITIES_COUNT);

    return (
      <div className="amenities-list">
        <List
          header={<div>{amenities[ZERO].categoryName}</div>}
          bordered={false}
          dataSource={
            !matches && amenities.length > MAX_AMENITIES_COUNT ? amenitiesToShow : amenities
          }
          renderItem={(item) => {
            const amenity = getAmenityImage(item);
            return (
            <List.Item>
              {amenity ? <img src={amenity.image} alt="" width="30" /> : <CheckMarkSvg />} {item.name}
            </List.Item>
          )}}
        />
        <div className="amenities-list__show-link" onClick={this.toggleAmenities}>
          {!matches &&
            amenities.length > MAX_AMENITIES_COUNT &&
            (this.state.isFullAmenities ? (
              <FormattedMessage id="show.less" />
            ) : (
              <FormattedMessage id="show.more" />
            ))}
        </div>
      </div>
    );
  };

  render(): React.ReactNode {
    return <div className="amenity">{this.resultAmenities()}</div>;
  }
}
