import React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { IPopularDestinationsOffersState } from '@store/slices';
import { RootState } from '@share/utils';
import { ILoginState } from '@share/store/slices';

import ConciergeTeamImageLarge from '@assets/images/concierge-team-large.png';

import './style.scss';

interface IMapStateToProps {
  popularDestinationsOffersStore: IPopularDestinationsOffersState;
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps {}

class ContactComponent extends React.Component<IProps> {

  render() {
    const { loginStore } = this.props;
    const { account } = loginStore;

    let title = 'here.to.help';
    let message = 'here.to.help.message';
    let subMessage = 'here.to.help.subMessage';
    
    let titleChanged = false;
    let messageChanged = false;
    let subMessageChanged = false;

    const contactAssistants = account?.contactAssistants;
    let contacts: any[] = [];

    if (contactAssistants) {
      if (!isEmpty(contactAssistants?.title)) {
        title = contactAssistants?.title;
      }
      if (!isEmpty(contactAssistants?.mainMessage)) {
        message = contactAssistants?.mainMessage;
        subMessage = contactAssistants?.subMessage;
      }

      const contactList = contactAssistants?.contacts;
      if (contactList?.length) {
        contacts = contactList.map(c => ({ ...c, type: c?.type.toLowerCase(), changed: true }));
      }
    }

    return (
      <div className="contact__container">
        <div className="contact">
          <p className="contact__title secondary-font">
            {!titleChanged ? (<FormattedMessage id={title} />) : {title}}
          </p>
          <p className="contact__message secondary-font">
            {!messageChanged ? (<FormattedMessage id={message} />) : {message}}
          </p>
          <p className="contact__sub-message secondary-font">
            {!subMessageChanged ? (<FormattedMessage id={subMessage} />) : {subMessage}}
          </p>
          
          <div className="contact__wrapper">
            {contacts.map((c, i) => (
              <div key={i} className="contact-item">
                <div className="contact-item__messages">
                  <p className="title secondary-font">
                    {c.changed ? c.message : (<FormattedMessage id={c.message} />)}
                  </p>
                </div>
                {c.type === 'email' ? <div className="contact-item__arrow">
                  <a className="email" href={`mailto:${c.value}`}>
                    {c.value}
                  </a>
                </div> : null}
                {c.type === 'phone' ? <div className="contact-item__arrow">
                  {c.value}
                </div> : null}
              </div>
            ))}
          </div>

          <div className="concierge-services__img-wrapper">
            <img src={ConciergeTeamImageLarge} alt="Concierge team" />
          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    popularDestinationsOffersStore: state.popularDestinationsOffersStore,
    loginStore: state.loginStore
  };
};

export const Contact = connect(mapStateToProps)(injectIntl(ContactComponent));
