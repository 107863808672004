import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CalendarNotificationSvg } from '@assets';
import './style.scss';

interface IProps {}

export class TravelDatesNotification extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <div className="travel-dates-notification">
        <CalendarNotificationSvg />
        <p className="travel-dates-notification__title">
          <FormattedMessage id="specify.travel.dates" />
        </p>
      </div>
    );
  }
}
