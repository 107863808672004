import React, { Component } from 'react';
import { DailyBenefitsWrapper } from '@components';
import './style.scss';

export class DailyBenefitsPage extends Component {
  render(): React.ReactNode {
    return (
      <div className="daily-benefits-page">
        <DailyBenefitsWrapper />
      </div>
    );
  }
}
