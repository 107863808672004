import React from 'react';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import { CONDO_DETAILS_SORTING_BEBA_ASC, CONDO_DETAILS_SORTING_BEBA_DESC, CONDO_DETAILS_SORTING_DATES_ASC, CONDO_DETAILS_SORTING_DATES_DESC, CONDO_DETAILS_SORTING_GUESTS_ASC, CONDO_DETAILS_SORTING_GUESTS_DESC, CONDO_DETAILS_SORTING_NIGHTS_ASC, CONDO_DETAILS_SORTING_NIGHTS_DESC, CONDO_DETAILS_SORTING_POLICY_ASC, CONDO_DETAILS_SORTING_POLICY_DESC, CONDO_DETAILS_SORTING_PRICE_ASC, CONDO_DETAILS_SORTING_PRICE_DESC, CONDO_DETAILS_SORTING_SUITE_ASC, CONDO_DETAILS_SORTING_SUITE_DESC, CONDO_DETAILS_SORTING_WALLET_ASC, CONDO_DETAILS_SORTING_WALLET_DESC } from '@share/constants';

import './style.scss';

interface IProps {
  isOnlyAlternative: boolean;

  hideBedroomsBathroomsColumn?: boolean;

  onSortingSelected: (selectedSorting: string) => void;
}

interface IState {
  selectedSorting: string;
}

class CondoUnitRowHeaderComponent extends React.Component<IProps, IState> {

  state: IState = { selectedSorting: null }; 

  handleSorting = (upSorting: string, downSorting: string, disabled: boolean) => {
    if (!disabled) {
      const selectedSorting = (this.state.selectedSorting === upSorting) ? downSorting : (this.state.selectedSorting === downSorting) ? null : upSorting;
      this.setState({ selectedSorting });
      this.props.onSortingSelected(selectedSorting);
    }
  }

  getSelectedSortingClassName = (sorting: string): string => {
    return `sort ${(this.state.selectedSorting === sorting) ? 'selected' : null}`;
  }

  renderCell = (keyTitle: string, className: string, width: number, ascColumnName: string, descColumnName: string, disabled?: boolean) => {
    return (
      <div className={`${className} ${disabled ? 'disabled' : ''}`} style={{ width: `${width}px` }} onClick={() => this.handleSorting(ascColumnName, descColumnName, disabled)}>
        <FormattedMessage id={keyTitle} />
        {!disabled ? (
          <div className="sorting-container">
            <div className="sorting">
              {(this.state.selectedSorting === ascColumnName) ? (
                <div className="sort">
                  <FontAwesomeIcon icon={faAngleUp} />
                </div>) : null}
              {(this.state.selectedSorting === descColumnName) ? (
                <div className="sort">
                  <FontAwesomeIcon icon={faAngleDown} />
                </div>) : null}
            </div>
          </div>) : null}
      </div>
    );
  }

  render(): React.ReactNode {
    const baseClassName = 'unit-row__left-block-text header';
    const baseClassNameDateOnlyAlternative = `${baseClassName} dates`;
    const baseClassNameOnlyAlternative = `${baseClassName}`;

    return (
      <div className="unit-row header" style={{ borderTop: 'none' }}>
        <div className="unit-row__left-block">
          {this.renderCell('suite', baseClassName, 220, CONDO_DETAILS_SORTING_SUITE_ASC, CONDO_DETAILS_SORTING_SUITE_DESC)}
        </div>

        <div className="unit-row__left-block">
          {this.renderCell('dates', baseClassNameDateOnlyAlternative, 180, CONDO_DETAILS_SORTING_DATES_ASC, CONDO_DETAILS_SORTING_DATES_DESC)}
        </div>

        <div className="unit-row__left-block">
          {this.renderCell('nigths', baseClassNameOnlyAlternative, 90, CONDO_DETAILS_SORTING_NIGHTS_ASC, CONDO_DETAILS_SORTING_NIGHTS_DESC)}
        </div>

        {!this.props.hideBedroomsBathroomsColumn ?
          <div className="unit-row__left-block">
            {this.renderCell('be.ba', baseClassNameOnlyAlternative, 90, CONDO_DETAILS_SORTING_BEBA_ASC, CONDO_DETAILS_SORTING_BEBA_DESC)}
          </div>: null}

        <div className="unit-row__left-block">
          {this.renderCell('guests.title', baseClassNameOnlyAlternative, 70, CONDO_DETAILS_SORTING_GUESTS_ASC, CONDO_DETAILS_SORTING_GUESTS_DESC)}
        </div>
        
        <div className="unit-row__right-block">
          {this.renderCell('total.to.pay', baseClassName, 110, CONDO_DETAILS_SORTING_PRICE_ASC, CONDO_DETAILS_SORTING_PRICE_DESC)}
        </div>

        <div className="unit-row__right-block">
          {this.renderCell('policy', baseClassName, 130, CONDO_DETAILS_SORTING_POLICY_ASC, CONDO_DETAILS_SORTING_POLICY_DESC, true)}
        </div>

        <div className="unit-row__right-block">
          <div className="unit-row__action">
            &nbsp;
          </div>
        </div>
      </div>
    );  
  }
}

export const CondoUnitRowHeader = connect()(CondoUnitRowHeaderComponent);
