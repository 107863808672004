import React from 'react';
import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Select } from 'antd';
import { get, isEmpty, isNumber } from 'lodash';
import { connect } from 'react-redux';

import { WhiteButton, MenuItem } from '@components';
import { BlueButton } from '@share/components';
import { IMenuItem } from '@common-types';
import { NULL_VALUE } from '@constants';
import { ILoginState } from '@share/store/slices';
import { RootState } from '@share/utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import './style.scss';

const NO_PARENT_OPTION = 'No Parent';

interface IState {
  title: string;
  url: string;
  parent: string;
  items: IMenuItem[];
}

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {
  title: string;
  visible: boolean;
  maxLevel?: number;
  items: IMenuItem[];
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>, isScrollToRooms?: boolean) => void;
  onSave: (items: IMenuItem[], e: React.MouseEvent<HTMLElement, MouseEvent>, isScrollToRooms?: boolean) => void;
}

class ModalMenuComponent extends React.Component<IProps, IState> {

  state: IState = { title: '', url: '', parent: NO_PARENT_OPTION, items: ([] as IMenuItem[]) };

  componentDidUpdate(prevProps: IProps) {
    const prevItems = JSON.stringify(prevProps.items);
    const upcomingItems = JSON.stringify(this.props.items);

    if (prevItems !== upcomingItems) {
      if (this.props.items) {
        this.setState({ items: [...this.props.items] });
      }  
    }
  }
  
  handleSave = () => {
    const list = [...this.state.items];
    const items = [...get(this.state, 'items', [])];
    this.setState({ items, title: '', url: '', parent: NO_PARENT_OPTION });
    this.props.onSave(list, NULL_VALUE);
  }

  handleAdd = () => {
    if (this.isValid()) {
      const { title, url, parent } = this.state;
      let items: IMenuItem[] = [];
      const newMenu: IMenuItem = { Title: title, Url: url };
      if (parent !== NO_PARENT_OPTION) {
        const itemsToUpdate = [...this.state.items];
        this.updateParent(itemsToUpdate, parent, newMenu);
        items = itemsToUpdate;
      } else {
        items = [...this.state.items, newMenu];
      }
      this.setState({ items, title: '', url: '', parent: NO_PARENT_OPTION });
    }
  }

  handleTitleChange = (event: any) => {
    this.setState({ title: event.target.value });
  }
  handleURLChange = (event: any) => {
    this.setState({ url: event.target.value });
  }
  handleParentChange = (parent: any) => {
    this.setState({ parent });
  }

  handleCancel = () => {
    this.setState({ items: [...this.props.items], title: '', url: '', parent: NO_PARENT_OPTION });
    this.props.onCancel(NULL_VALUE);
  }

  handleMenuItemAction = (items: IMenuItem[]) => {
    this.setState({ items });
  }

  isValid = () => {
    return !isEmpty(this.state.title) && !isEmpty(this.state.url);
  }

  flatten = (destArray: any[], nodeList: IMenuItem[], level?: number) => {
    const lev = level ? level : 1;
    nodeList.forEach((node) => {
      destArray.push({ title: node.Title, level: lev });

      const childItemsLength = node.ChildItems?.length ? node.ChildItems?.length : 0;
      if (childItemsLength > 0) {
        this.flatten(destArray, node.ChildItems as IMenuItem[], lev + 1);
      }
    });
  }

  updateParent = (menuList: IMenuItem[], parent: string, newItem: IMenuItem) => {
    menuList.forEach((item) => {
      if (item.Title === parent) {
        if (!item.ChildItems) {
          item.ChildItems = [];
        }
        item.ChildItems.push(newItem);
        return;
      }

      const childItemsLength = item.ChildItems?.length ? item.ChildItems?.length : 0;
      if (childItemsLength > 0) {
        this.updateParent(item.ChildItems as IMenuItem[], parent, newItem);
      }
    });
  }
    
  getAllMenuItems = () => {
    if (this.state.items?.length > 0) {
      const destArray: any[] = [];
      this.flatten(destArray, this.state.items);

      const maxLevel = this.props.maxLevel ? this.props.maxLevel : 0;
      let response = destArray;
      if (isNumber(maxLevel)) {
        response = destArray.filter(item => item.level <= maxLevel);
      }
      return response.map(item => item.title);
    }
    return [];
  }

  renderTable = () => {
    return (
      <div className="table-container">
        <div className="container-fluid">
          <div className="row table-header">
            <div className="col-4">
              <span><FormattedMessage id="menu.item.title" /></span>
            </div>
            <div className="col-4">
              <span><FormattedMessage id="menu.item.url" /></span>
            </div>
            <div className="col-2">
              <span><FormattedMessage id="menu.table.header.order" /></span>
            </div>
            <div className="col-2">
              <span><FormattedMessage id="action" /></span>
            </div>
          </div>
          <div className="row table-content">
            {this.state.items?.length === 0 && (
              <div className="table-no-data">
                <span><FormattedMessage id="no.data.available" /></span>
              </div>
            )}
            {this.state.items?.length > 0 && (
              <MenuItem items={this.state.items} expand={true} onAction={this.handleMenuItemAction} />
            )}
          </div>

        </div>
      </div>
    );
  }

  render(): React.ReactNode {
    const {
      title,
      visible,
      loginStore
    } = this.props;
    const { account } = loginStore;

    const { parent } = this.state;

    const disable = !this.isValid();
    const allItems = [NO_PARENT_OPTION, ...this.getAllMenuItems()];
    const hasMaxLevelZero = (isNumber(this.props.maxLevel) && this.props.maxLevel === 0);

    const fontFamily = account?.fontFamily;
    const secondaryFontFamily = account?.secondaryFontFamily;

    return (
      <Modal
        className={`modal-menu ${fontFamily ? `${fontFamily}-font`: ''} ${secondaryFontFamily ? `secondary-${secondaryFontFamily}-font`: ''}`}
        open={visible}
        footer={null}
        onCancel={this.handleCancel}
        wrapClassName="modal-menu__wrapper"
      >
        <div>
          <h4 className="modal-menu__title secondary-font">
            <FormattedMessage id={title} />
          </h4>
          <div className="modal-menu__content">
            <hr />

            <div className="add-menu-container">
              <div className="add-menu-title">
                <FormattedMessage id="modal.menu.add" />
              </div>

              <div className="add-menu-fields">
              <div className="add-menu-field form-input-container">
                  <div className="add-menu-field-title">
                    Title:
                  </div>
                  <div className="add-menu-field-input form-input-content">
                    <input className="form-input" value={this.state.title} onChange={this.handleTitleChange} />
                  </div>
                </div>
                <div className="add-menu-field form-input-container">
                  <div className="add-menu-field-title">
                    URL:
                  </div>
                  <div className="add-menu-field-input form-input-content">
                    <input className="form-input" value={this.state.url} onChange={this.handleURLChange} />
                  </div>
                </div>
                {!hasMaxLevelZero && (<div className="add-menu-field form-input-container">
                  <div className="add-menu-field-title">
                    Parent:
                  </div>
                  <div className="add-menu-field-input">
                    <Select
                      showSearch={true}
                      placeholder={'Parent Menu Item'}
                      value={parent}
                      className={'form-input'}
                      style={{ width: '300px', zIndex: 5 }}
                      onChange={this.handleParentChange}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={node => node.parentNode}
      
                    >
                      {allItems.map((item, index) => <Select.Option key={index} value={item}>{item}</Select.Option>)}
                    </Select>
                  </div>
                </div>)}
              </div>

              <div className={`add-button ${disable ? 'disable' : ''}`}>
                <FontAwesomeIcon icon={faPlusCircle} size="2x" onClick={this.handleAdd} />
              </div>
            </div>

            <hr />

            <div className="modal-menu__table">
              {this.renderTable()}
            </div>

            <div className="modal-menu__buttons">
              <WhiteButton onClick={this.handleCancel}>
                <FormattedMessage id="cancel.button" />
              </WhiteButton>
              <BlueButton onClick={this.handleSave}>
                <FormattedMessage id="save.button" />
              </BlueButton>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

export const ModalMenu = connect(mapStateToProps)(ModalMenuComponent);
