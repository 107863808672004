
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

import { isEmpty } from 'lodash';
import { ILoginState } from '@share/store/slices';

import './style.scss';
import { connect } from 'react-redux';
import { RootState } from '@share/utils';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, ButtonProps {}

function WhiteButtonComponent(props: IProps) {

  const { loginStore } = props;

  const account = loginStore?.account;
  const buttonBorderRadius: any = account?.buttonBorderRadius ? account?.buttonBorderRadius : null;
  const styleButton = !isEmpty(buttonBorderRadius) ? { borderRadius: `${buttonBorderRadius}px` } : {};  

  return (
    <Button {...props} className={`white-button ${props.className ? props.className : ''}`} style={styleButton}>
      {props.children}
    </Button>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};


export const WhiteButton = connect(mapStateToProps)(WhiteButtonComponent);