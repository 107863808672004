
import SportsIllustrates from '@share/assets/images/profiles/SportsIllustrates.png';

export const SportsIllustratesProfile = {
    buttonColor: '#B0231C',
    buttonTextColor: '#FFFFFF',

    buttonColorReverse: '#FFFFFF',
    buttonTextColorReverse: '#B0231C',

    buttonBorderRadius: '0',

    generalBorderRadius: '0',
    generalBorderColor: '#0079C2',
    
    bogoColor: '#0079C2',
    bogoButtonTextColor: '#B0231C',
    getawaysColor: '#B0231C',
    getawaysButtonTextColor: '#B0231C',

    mainBackgroundColor: '#B0231C',
    subHeaderColor: '#B0231C',
    logo: SportsIllustrates,
    fontFamily: 'industry',
    secondaryFontFamily: 'industry',
    travelLeadersFontSize: '50',

    mainBorderColor: '#0079C2',
    mainBorderRadius: '0',
    mainHideLinkIcon: true,

    menuItemHideBorderBottom: true
}