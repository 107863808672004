import React from 'react';

import { Loading, LoadingSise } from '@share/components';

import './style.scss';

interface IProps {}

export class ThreeDS extends React.Component<IProps> {

  render(): React.ReactNode {
    window.parent.postMessage('3DS-authentication-complete', "*");

    return (
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <div className="loading-container" style={{ marginTop: '0px' }}>
          <Loading size={LoadingSise.Medium} />
        </div>
      </div>
    );
  }
}

