import React from 'react';

import './style.scss';

interface IProps {}

export class LuxuryCruises extends React.Component<IProps> {
  render(): React.ReactNode {
    const propsIframe = {
      src: 'https://secure.vacationport.net/?host=rsivacations.vacationport.net',
      scrolling: 'no',
      frameBorder: '0',
      id: 'iFrameResizer0',
      style: { overflow: 'hidden', width: '100%', height: '3009px' },
    };

    return (
      <div className="luxury-cruises">
        <iframe {...propsIframe} />
      </div>
    );
  }
}
