import React from 'react';

import { get, isEmpty } from 'lodash';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Input, Slider } from 'antd';
import { SliderMarks } from 'antd/lib/slider';
import { ILoginState } from '@share/store/slices';
import { GetWalletNumber, RootState, getWalletSliderMaxValuePosition } from '@share/utils';

import './style.scss';

const NUMBER_REGEX = /[0-9]/;
const NUMBER_DECIMAL_REGEX = /[0-9.]/;

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps {
  display: boolean;
  bottomBorder?: boolean;

  currency: string;

  maxWalletClientCash: number;
  price: number;
  selectedClientCash: number;
  selectedClientCashStr: string;

  onSelectClientCash: (selectedClientCash: string) => void;
}

class WalletInputComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { display, bottomBorder, maxWalletClientCash, selectedClientCash, selectedClientCashStr, price, currency, intl, loginStore, onSelectClientCash } = this.props;
    const { account, userWalletData } = loginStore;

    if (!display) {
      return null;
    }
    
    const walletNoPassEarnings = account?.walletNoPassEarnings;
    const walletUseSlider = account?.walletUseSlider;
    const walletNoDecimals = account?.walletNoDecimals;
    const background = account?.buttonColor ? account?.buttonColor : '#0081FE';

    const convertionRate = userWalletData?.convertionRate ? userWalletData?.convertionRate : 1;
    const maxWalletClientCashCalculated = maxWalletClientCash ? walletNoDecimals ? Math.floor(maxWalletClientCash) : maxWalletClientCash : null;
    const userWalletDataBalanceCalculated = (walletNoDecimals && userWalletData?.balance) ? Math.floor(userWalletData?.balance) : userWalletData?.balance;
    const priceCalculated = price / convertionRate;
    
    const maxWalletAmountIntermediated = walletNoPassEarnings ?
                                            maxWalletClientCashCalculated ?
                                              (maxWalletClientCashCalculated && userWalletDataBalanceCalculated > maxWalletClientCashCalculated) ? 
                                                maxWalletClientCashCalculated : 
                                                userWalletDataBalanceCalculated : 
                                              0 :
                                            userWalletDataBalanceCalculated > priceCalculated ? 
                                              priceCalculated :
                                              userWalletDataBalanceCalculated;
    const maxWalletAmount = (maxWalletClientCashCalculated && maxWalletAmountIntermediated > maxWalletClientCashCalculated) ? maxWalletClientCashCalculated : maxWalletAmountIntermediated;

    const maxSlider = maxWalletAmount ? 
                        walletNoDecimals ? 
                          Math.floor(maxWalletAmount) : 
                          maxWalletAmount ? Math.floor(maxWalletAmount * 100) / 100 : 0 : 
                        0;

    const marks: SliderMarks = {
      0: {
        style: {
          transform: 'translate(-4px, -40px)'
        },
        label: '0',
      }
    };

    if (maxSlider) {
      marks[maxSlider] = {
        style: {
          transform: `translate(${walletNoDecimals ? getWalletSliderMaxValuePosition(maxSlider) :'-27'}px, -40px)`
        },
        label: `${maxSlider?.toLocaleString('en-US')}`,
      };
    }

    const inputValue = (selectedClientCash && selectedClientCash >= 0) ? selectedClientCashStr : '';

    return (
      <div className={`wallet-input ${bottomBorder ? 'border-bm' : ''}`}>
        <div className="wallet-input__note">
          <p className="wallet-input__price-info-text-total">
            {isEmpty(account?.walletClientCashName) ? (<FormattedMessage id="wallet.client.cash" />) : account?.walletClientCashName}:
          </p>
        </div>

        <div className="wallet-input__note" style={{ marginBottom: '10px', whiteSpace: 'unset' }}>
          {maxWalletAmount ? (
            <FormattedMessage id="wallet.client.cash.message" values={{ amount: maxSlider?.toLocaleString('en-US'), amountActive: GetWalletNumber(userWalletData?.balance, account), clientCash: isEmpty(account?.walletClientCashName) ? 'Client Cash' : account?.walletClientCashName }} />) : (
            <FormattedMessage id="wallet.client.cash.no_client_cash.message" values={{ amountActive: GetWalletNumber(userWalletData?.balance, account), clientCash: isEmpty(account?.walletClientCashName) ? 'Client Cash' : account?.walletClientCashName }} />)}
        </div>

        <div className="wallet-input__note">
          {!walletUseSlider ? (
            <input type="number" value={(selectedClientCash && selectedClientCash >= 0) ? selectedClientCash : '' }
              className={`wallet-input__client-cash-input ${selectedClientCash > maxWalletAmount ? 'invalid' : ''}`}
              onChange={e => {
                const value = !isEmpty(e.target.value) ? e.target.value : null;
                onSelectClientCash(value);
              }}
              placeholder={intl.formatMessage({ id: 'wallet.client.cash.placeholder' }, { clientCash: !isEmpty(account?.walletClientCashName) ? account?.walletClientCashName : 'Client Cash'})}
              disabled={maxWalletAmount <= 0}
            />) : (
            <div className="room-info__slider">
              <Slider
                min={0}
                max={maxSlider}
                marks={marks}
                defaultValue={0}
                step={walletNoDecimals ? 1 : 0.01}
                tooltip={{ open: false }}
                value={selectedClientCash >= 0 ? selectedClientCash : 0}
                onChange={(value: any) => onSelectClientCash(value)}
                trackStyle={{ backgroundColor: background }}
                handleStyle={{ borderColor: background, backgroundColor: background }}
                disabled={maxWalletAmount <= 0}
              />
              <Input value={inputValue}
                className={`room-info__client-cash-input ${selectedClientCash > maxWalletAmount ? 'invalid' : ''}`}
                onChange={e => {
                  const value = !isEmpty(e.target.value) ? 
                                      walletNoDecimals ?
                                          e.target.value.replaceAll(',', '').replaceAll('.', '') :
                                          e.target.value.replaceAll(',', '') :
                                      null;

                  onSelectClientCash(value);
                }}
                onKeyPress={(event) => {
                  const REGEX = walletNoDecimals ? NUMBER_REGEX : NUMBER_DECIMAL_REGEX;

                  const decimals = get(inputValue?.split('.'), '[1]', '');

                  if (!REGEX.test(event.key) || (event.key === '.' && inputValue.includes('.')) || decimals?.length >= 2) {
                    event.preventDefault();
                  }
                }}
                disabled={maxWalletAmount <= 0}
              />
            </div>)}
        </div>

        {selectedClientCash > maxWalletAmount && (
          <div className="wallet-input__note invalid" style={{ marginBottom: '10px' }}>
            <FormattedMessage id="wallet.client.cash.invalid" values={{ amount: GetWalletNumber(maxWalletAmount, account), currency: currency, clientCash: isEmpty(account?.walletClientCashName) ? 'Client Cash' : account?.walletClientCashName }} />
          </div>)}

        {(!!selectedClientCash && walletNoDecimals && selectedClientCash <= maxWalletAmount && Math.floor(selectedClientCash) != selectedClientCash) && (
          <div className="wallet-input__note invalid" style={{ marginBottom: '10px' }}>
            <FormattedMessage id="wallet.client.cash.only_decimals" values={{ clientCash: isEmpty(account?.walletClientCashName) ? 'Client Cash' : account?.walletClientCashName }} />
          </div>)}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

export const WalletInput = connect(mapStateToProps)(injectIntl(WalletInputComponent));

