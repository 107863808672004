
import { LoadingSise } from '@common-types';

import LoadingImage from '@assets/images/loading.gif';

import './style.scss';

interface IProps {
  size: LoadingSise;
  className?: string;
}

export function Loading(props: IProps) {
  const className = `loading-image-container ${props.className ? props.className : ''} ${props.size ? props.size : LoadingSise.Normal}`;
  return (
    <img src={LoadingImage} className={className} alt="loading..." />
  );
}
