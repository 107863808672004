import React from 'react';
import ReactGA from 'react-ga4';

import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';
import { connect } from 'react-redux';
import { IReviewBookState, setGuests } from '@store/slices';
import { IMenuState, ILoginState, IMyProfileState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { GUEST_TYPE_MEMBER, HOTEL_BOOKING_GUEST_INFO_LABEL } from '@constants';
import { renderPhone, insertSpacesInPhone, checkForEmptyCharacter } from '@share/utils';
import { IGuestsBooking, IPackageRoom } from '@common-types';
import { GuestForm } from '../guest-form';
import { C_ADD_ALL_GUESTS } from '@share/constants';
import { MemberChangeValue, SavedMembers } from '@components';

import './style.scss';

interface IMapStateToProps {
  reviewBookStore: IReviewBookState;
  menuStore: IMenuState;
  loginStore: ILoginState;
  myProfileStore: IMyProfileState;
}

interface IMapDispatchToProps {
  setGuests: (guests: IGuestsBooking[]) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  room: IPackageRoom;
  indexRoom: number;
  isCheckMainGuest: boolean;
  onFocusPhone: () => void;
}

interface IState {
  displayOptionals: boolean[];
}

const ZERO = 0;
const ONE = 1;
const THREE = 3;

class RoomInfoComponent extends React.Component<IProps, IState> {
  
  state = { displayOptionals: [] as boolean[] };

  setGuestFields = (values: {value: string, key: string}[], index: number) => {
    const items = [...this.props.reviewBookStore.guests];
    items[index] = { ...items[index] };

    values.forEach(v => {
      items[index][v.key] = v.value
    });

    this.props.setGuests(items);
    localStorage.setItem(HOTEL_BOOKING_GUEST_INFO_LABEL, JSON.stringify(items));
  };

  setGuestData = (value: string, key: string, index: number) => {
    const items = [...this.props.reviewBookStore.guests];
    items[index] = {
      ...items[index],
      [key]: value,
    };

    this.props.setGuests(items);
    localStorage.setItem(HOTEL_BOOKING_GUEST_INFO_LABEL, JSON.stringify(items));
  };


  onChangeMainGuest = (
    e: { target: CheckboxChangeEventTarget },
    memberFirstName: string,
    memberLastName: string,
    memberPhone: string,
    memberEmail: string,
    index: number,
  ): void => {
    const { guests } = this.props.reviewBookStore;
    const updateGuest = guests.map((guest) => {
      const replaceMainGuest = typeof guest['isMainGuest'] !== 'undefined' && {
        isMainGuest: false,
      };
      return { ...guest, ...replaceMainGuest };
    });
    const items = [...updateGuest];
    items[index] = {
      ...items[index],
      isMainGuest: e.target.checked,
    };

    if (e.target.checked) {
      items[index] = {
        ...items[index],
        givenName: memberFirstName,
        surname: memberLastName,
        phone: memberPhone,
        email: memberEmail,
      };
    } else {
      items[index] = {
        ...items[index],
        givenName: '',
        surname: '',
        phone: '',
        email: '',
      };
    }
    this.props.setGuests(items);
    localStorage.setItem(HOTEL_BOOKING_GUEST_INFO_LABEL, JSON.stringify(items));
  };

  onChangeFirstName = (e: { target: HTMLInputElement }, index: number): void => {
    this.setGuestData(e.target.value, 'givenName', index);
  };

  onChangeLastName = (e: { target: HTMLInputElement }, index: number): void => {
    this.setGuestData(e.target.value, 'surname', index);
  };

  handlePhoneValue = (phone: string) => {
    const value = checkForEmptyCharacter(phone);
    const matchPhone = insertSpacesInPhone(value);

    if (value?.length > THREE && matchPhone !== null) {
      return matchPhone.join('-').trim();
    } else if (!Number(value)) {
      return '';
    } else {
      return value;
    }
  }

  handlePhoneChange = (phone: string, index: number) => {
    this.setGuestData(this.handlePhoneValue(phone), 'phone', index);
  }

  onChangePhone = (e: { target: HTMLInputElement }, index: number): void => {
    this.handlePhoneChange(e.target.value, index);
  };

  onChangeNamePrefix = (value: string, index: number) => {
    this.setGuestData(value, 'namePrefix', index);
  };

  getTitleGuest = (guest: IGuestsBooking) => {
    return guest.type === 'kid' ? 'kid.title' : 'guest.title';
  };

  onDisplayOptional = (indexRoom: number) => {
    const displayOptionals = this.state.displayOptionals;
    displayOptionals[indexRoom] = !displayOptionals[indexRoom];
    this.setState({ displayOptionals });

    const { reviewBookStore, loginStore, room } = this.props;
    const { guests } = reviewBookStore;
    const { account } = loginStore;

    if (displayOptionals[indexRoom]) {
      ReactGA.event({
        category: account?.name as string,
        action: `${C_ADD_ALL_GUESTS}_${account?.name.toUpperCase()}`,
        label: `User clicked Add all guests for this room on book`,
        nonInteraction: false,
      });
    }

    let count = 0;
    guests.forEach((guest, index) => {
      if (guest.allocation === room.id) {
        if (count !== 0) {
          const items = [...this.props.reviewBookStore.guests];
          items[index] = {
            ...items[index],
            namePrefix: 'Mr.',
            surname: '',
            givenName: '',
          };
          this.props.setGuests(items);
          localStorage.setItem(HOTEL_BOOKING_GUEST_INFO_LABEL, JSON.stringify(items));
      
        }
        count += 1;
      }
    });
  }

  isShowCheckbox = (guest: IGuestsBooking) => {
    const { reviewBookStore } = this.props;
    const { guests, bookingSummary } = reviewBookStore;
    const { rooms } = bookingSummary.bookingCard.package;
    const isActiveMainGuest = guests.some((guest) => {
      return guest.isMainGuest;
    });

    if (rooms.length === ONE) {
      return false;
    }

    if (typeof guest['isMainGuest'] !== 'undefined' && guest.isMainGuest) {
      return guest.isMainGuest;
    } else if (!isActiveMainGuest) {
      return typeof guest['isMainGuest'] !== 'undefined';
    }

    return false;
  };

  updateDefaultMainGuest = (
    memberFirstName: string,
    memberLastName: string,
    memberPhone: string,
    memberEmail: string,
  ) => {
    const { guests } = this.props.reviewBookStore;
    const items = [...guests];

    items[ZERO] = {
      ...items[ZERO],
      givenName: memberFirstName,
      surname: memberLastName,
      phone: memberPhone,
      email: memberEmail,
    };

    this.props.setGuests(items);
  };

  isMemberType = () => {
    const memberType = this.props.menuStore.items?.memberType;
    return memberType === GUEST_TYPE_MEMBER;
  };

  getGuestInfoUpdate = (guestData: IGuestsBooking[]) => {
    const { reviewBookStore } = this.props;

      return reviewBookStore.guests.map((item: IGuestsBooking, index: number) => {
        const g = guestData[index];

        return g.type === item.type
          ? {
              ...item,
              isMainGuest: g.isMainGuest,
              namePrefix: g.namePrefix,
              age: g.age,
              email: g.email,
              givenName: g.givenName,
              phone: g.phone,
              surname: g.surname
          }
          : item
      }
    );
  };

  componentDidMount() {
    const { reviewBookStore } = this.props;
    const { guests, bookingSummary } = reviewBookStore;
    const { rooms } = bookingSummary.bookingCard.package;

    const guestInfo = localStorage.getItem(HOTEL_BOOKING_GUEST_INFO_LABEL);
    const guestData = guestInfo ? JSON.parse(guestInfo) : guestInfo;

    this.setState({ displayOptionals: Array(rooms.length).fill(false) });

    if (
      guestInfo &&
      guests.length === guestData.length &&
      guests[0].allocation === guestData[0].allocation
    ) {
      try {
        this.props.setGuests(this.getGuestInfoUpdate(guestData) as IGuestsBooking[]);
      } catch (error) {
        console.error(error);
      }
    } else {
      localStorage.removeItem(HOTEL_BOOKING_GUEST_INFO_LABEL);
    }
  }

  onChangeSavedMmeber = (selection: MemberChangeValue) => {
    const { reviewBookStore, room } = this.props;
    const { guests } = reviewBookStore;

    const index = guests.findIndex(guest => guest.allocation === room.id);

    this.setGuestFields([
      { value: selection.firstName, key: 'givenName'},
      { value: selection.lastName, key: 'surname'},
      { value: this.handlePhoneValue(selection.phone), key: 'phone'},
      { value: selection.email, key: 'email'},
    ], index);
  }

  render(): React.ReactNode {
    const { reviewBookStore, room, indexRoom, menuStore } = this.props;
    const { adultsCount, kidsAges, roomBasis } = room;
    const { loadingBooking, bookingSummary, guests, errorsField } = reviewBookStore;

    const memberFirstName = this.isMemberType() ? bookingSummary.mainGuest.givenName : '';
    const memberLastName = this.isMemberType() ? bookingSummary.mainGuest.surname : '';
    const memberEmail = this.isMemberType() ? bookingSummary.mainGuest.email : '';
    const memberPhone = this.isMemberType() ? renderPhone(bookingSummary.mainGuest.phone) : '';

    let adultIndex = ZERO;
    let childIndex = ZERO;
    
    return (
      <div className={`room-info guest-info-room`}>
        <div className="room-info__room-info">
          <h4 className="room-info__room-title">
            <FormattedMessage id="room.title" /> {indexRoom + ONE}
          </h4>
          <p className="room-info__room-description">
            {adultsCount} <FormattedMessage id="adults" />
            {kidsAges.length !== ZERO ? (
              <>
                {', '}
                {kidsAges.length} <FormattedMessage id="children" />
              </>
            ) : (
              ''
            )}
            {roomBasis ? (
              <>
                {', '}
                {roomBasis}
              </>
            ) : (
              ''
            )}
          </p>
        </div>

        <SavedMembers onMemberChange={this.onChangeSavedMmeber} />

        {guests.map(
          (guest, index) =>
            guest.allocation === room.id && (
              <>
                <GuestForm
                  disabled={loadingBooking}
                  memberType={menuStore.items?.memberType as string}
                  guests={guests}
                  key={index}
                  guest={guest}
                  title={this.getTitleGuest(guest)}
                  memberFirstName={memberFirstName}
                  memberLastName={memberLastName}
                  memberPhone={memberPhone}
                  memberEmail={memberEmail}
                  childAge={guest.age}
                  index={index}
                  indexRoom={indexRoom}
                  roomGuestCount={guests.filter(guest => guest.allocation === room.id).length}
                  childIndex={(guest.type === 'kid' && ++childIndex) as number}
                  adultIndex={(guest.type !== 'kid' && ++adultIndex) as number}
                  displayOptional={this.state.displayOptionals[indexRoom]}
                  onChangeMainGuest={this.onChangeMainGuest}
                  onChangeFirstName={this.onChangeFirstName}
                  onChangeLastName={this.onChangeLastName}
                  onChangePhone={this.onChangePhone}
                  onChangeNamePrefix={this.onChangeNamePrefix}
                  isShowCheckbox={this.isShowCheckbox}
                  updateDefaultMainGuest={this.updateDefaultMainGuest}
                  errorsField={errorsField}
                  isCheckMainGuest={this.props.isCheckMainGuest}
                  onFocusPhone={this.props.onFocusPhone}
                  onDisplayOptional={this.onDisplayOptional}
                />
              </>
            ),
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    reviewBookStore: state.reviewBookStore,
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
    myProfileStore: state.myProfileStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = { setGuests };

export const RoomInfo = connect(mapStateToProps, mapDispatchToProps)(injectIntl(RoomInfoComponent));
