

import { axiosInstance, getHeaders } from "@share/utils";

export const getLocationByGeo = (latitude: number, longitude: number, isWidget?: boolean) => {
    return axiosInstance.get(`/locations/placegetbylonlat?longitude=${longitude}&latitude=${latitude}`, {
        ...getHeaders(isWidget),
    });
}

export const getLocationByCode = (code: number, isWidget?: boolean) => {
    return axiosInstance.get(`/locations/placegetbycode?code=${code}`, {
        ...getHeaders(isWidget),
    });
}

export const GetAccountFeaturedDestinations = (accountId: string) => {
    return axiosInstance.get(`/locations/account/destination?accountId=${accountId}`, {
        ...getHeaders(),
    });
}

