import React from 'react';
import { connect } from 'react-redux';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Modal } from 'antd';

import { RootState, UrlUtils } from '@share/utils';
import { ICarsReviewBookState, ICondoReviewBookState, IReviewBookState, getBookPackageWithCard3DS, getCarsBookPackageWithCard3DS, getCondoBookPackageWithCard3DS } from '@store/slices';

import { SESSION_KEY_LABEL, SESSION_KEY_LABEL_ROOMS } from '@share/constants';
import { ISessionKey } from '@share/common-types';

import './style.scss';

const MODAL_WIDTH = 400;

interface IMapStateToProps {
  reviewBookStore: IReviewBookState;
  carsReviewBookStore: ICarsReviewBookState;
}

interface IMapDispatchToProps {
  getBookPackageWithCard3DS: (sessionKey: ISessionKey) => void;
  getCondoBookPackageWithCard3DS: (sessionKey: ISessionKey) => void;
  getCarsBookPackageWithCard3DS: (sessionKey: ISessionKey) => void;
}

interface IProps extends WrappedComponentProps, IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  isCondo?: boolean;
  isCars?: boolean;
}

class ThreeDSModalComponent extends React.Component<IProps> {

  componentDidMount(): void {
    window.addEventListener('message', this.handleThreeDSAuthentication, false);
  }

  componentWillUnmount(): void {
    window.removeEventListener('message', this.handleThreeDSAuthentication);
  }


  handleThreeDSAuthentication = (event: any) => {
    const { reviewBookStore, isCondo, isCars, getBookPackageWithCard3DS, getCondoBookPackageWithCard3DS, getCarsBookPackageWithCard3DS } = this.props;
    const { threeDSLoading } = reviewBookStore;

    const values = UrlUtils.getValues();
    const roomsSession = values[SESSION_KEY_LABEL_ROOMS] as ISessionKey;
    const searchSession = values[SESSION_KEY_LABEL] as ISessionKey;

    const sessionKey = roomsSession && roomsSession.value ? roomsSession : searchSession;

    if (event.data === '3DS-authentication-complete') {
      if (!threeDSLoading) {
        if (isCondo) {
          getCondoBookPackageWithCard3DS(sessionKey);
        } else if (isCars) {
          getCarsBookPackageWithCard3DS(sessionKey);
        } else {
          getBookPackageWithCard3DS(sessionKey);
        }
      }
    }
  }

  render(): React.ReactNode {
    const { reviewBookStore, carsReviewBookStore, isCars } = this.props
    const threeDSModalVisibleHotelsCondos = reviewBookStore.threeDSModalVisible;
    const threeDSUrlHotelsCondos = reviewBookStore.threeDSUrl;
    const threeDSModalVisibleCars = carsReviewBookStore.threeDSModalVisible;
    const threeDSUrlCars = carsReviewBookStore.threeDSUrl;

    const threeDSModalVisible = isCars ? threeDSModalVisibleCars : threeDSModalVisibleHotelsCondos;
    const threeDSUrl = isCars ? threeDSUrlCars : threeDSUrlHotelsCondos;
    
    return (
      <Modal
        className="threeds-modal-wrapper"
        wrapClassName="threeds-modal-wrapper__wrap"
        visible={threeDSModalVisible}
        footer={null}
        title={null}
        closable={false}
        keyboard={false}
        destroyOnClose={true}
        width={MODAL_WIDTH}
      >
        <iframe width="100%" height="600px" src={threeDSUrl}></iframe>
      </Modal>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    reviewBookStore: state.reviewBookStore,
    carsReviewBookStore: state.carsReviewBookStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getBookPackageWithCard3DS,
  getCondoBookPackageWithCard3DS, 
  getCarsBookPackageWithCard3DS,
};

export const ThreeDSModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(ThreeDSModalComponent)));
