import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IImage } from '@share/common-types';
import { MobileSlider } from '@components';
import { FullScreenSvg, ImagesQuantitySvg } from '@assets';
import { ImagesSlider } from './image-slider';

import './style.scss';

interface IProps {
  images: IImage[];
  titleIntlId: string;
}

interface IState {
  isModalVisible: boolean;
  startIndex: number;
}

const FIRST_ELEMENT_ARRAY = 0;
const MIN_IMAGE = 0;
const MAX_IMAGE = 4;

export class ImagesGallery extends React.Component<IProps, IState> {
  state: IState = {
    isModalVisible: false,
    startIndex: 0,
  };

  showSlider = (index: number): void => {
    this.setState({ isModalVisible: true });
    this.setState({ startIndex: index });
  };

  handleCancel = (): void => {
    this.setState({ isModalVisible: false });
  };

  getOtherImages = (images: IImage[]): React.ReactNode =>
    images.map((img: IImage, i: React.Key) => {
      if (i === MIN_IMAGE || i > MAX_IMAGE) {
        return;
      }
      return (
        <div key={i} className="images__other-image-wrapper">
          <img className="images__image" src={img.url} alt={img.description} />
          <div className="images__middle">
            <div className="images__btn-hover-wrapper" onClick={() => this.showSlider(Number(i))}>
              <FullScreenSvg />
              <span className="images__btn-hover-text">
                <FormattedMessage id="full.view" />
              </span>
            </div>
          </div>
        </div>
      );
    });

  render(): React.ReactNode {
    const { images = [], titleIntlId } = this.props;
    const { isModalVisible, startIndex } = this.state;

    return (
      <div className="images">
        <div className="images__images-quantity">
          <ImagesQuantitySvg />
          <span className="images__images-quantity-text">{images.length}+</span>
        </div>
        <div className="images__wrapper">
          <div className="images__main-image-wrapper">
            <img
              className="images__image"
              src={images[FIRST_ELEMENT_ARRAY].url}
              width={images[FIRST_ELEMENT_ARRAY].width}
              alt={images[FIRST_ELEMENT_ARRAY].description}
            />
            <div className="images__middle">
              <div
                className="images__btn-hover-wrapper"
                onClick={() => this.showSlider(FIRST_ELEMENT_ARRAY)}
              >
                <FullScreenSvg />
                <span className="images__btn-hover-text">
                  <FormattedMessage id="full.view" />
                </span>
              </div>
            </div>
          </div>
          <div className="images__other-images-wrapper">{this.getOtherImages(images)}</div>
        </div>
        <ImagesSlider
          images={images}
          onCancel={this.handleCancel}
          visible={isModalVisible}
          titleIntlId={titleIntlId}
          startIndex={startIndex}
        />
        <MobileSlider images={images} />
      </div>
    );
  }
}
