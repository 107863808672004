import React, { Component } from 'react';
import { SubmitRequestHeading } from './submit-request-heading';
import { SubmitRequestMain } from './submit-request-main';
import './style.scss';

interface IProps {
  isCondoPage?: boolean;
}

export class SubmitRequestWrapper extends Component<IProps> {
  componentDidMount(): void {
    window.scrollTo(0, 0);
  }

  render(): React.ReactNode {
    const { isCondoPage } = this.props;

    return (
      <>
        <SubmitRequestHeading isCondoPage={isCondoPage} />
        <SubmitRequestMain isCondoPage={isCondoPage} />
      </>
    );
  }
}
