import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IGetawayDatesState {
  startDate: string;
  endDate: string;
  key: string;
  error: string;
  isFocused?: boolean;
}

const initialState: IGetawayDatesState = {
  startDate: undefined,
  endDate: undefined,
  key: 'selection',
  error: '',
  isFocused: false,
};

const getawayDatesSlice = createSlice({
  name: 'getawayDates',
  initialState,
  reducers: {
    setDates: (
      state: IGetawayDatesState,
      { payload }: PayloadAction<{ startDate: string; endDate: string }>,
    ) => {
      state.startDate = payload.startDate;
      state.endDate = payload.endDate;
    },
    setStartDate: (state: IGetawayDatesState, { payload }: PayloadAction<string>) => {
      state.startDate = payload;
    },
    setEndDate: (state: IGetawayDatesState, { payload }: PayloadAction<string>) => {
      state.endDate = payload;
    },
    setKey: (state: IGetawayDatesState, { payload }: PayloadAction<string>) => {
      state.key = payload;
    },
    setError: (state: IGetawayDatesState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setIsFocused: (state: IGetawayDatesState, { payload }: PayloadAction<boolean>) => {
      state.isFocused = payload;
    },
    resetDates: () => {
      return initialState;
    },
  },
});

export const getawayDatesActions = getawayDatesSlice.actions;

export const getawayDatesReducer = getawayDatesSlice.reducer;
