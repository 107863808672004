
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum WeeksErrorTypes {
  Date,
  Period,
}

export const DEFAULT_PERIOD = '14';

export interface IWeeksDatesState {
  dateSelected: string;
  date: string;

  periodSelected: string;
  period: string;

  errorDate: string;
  errorPeriod: string;
}

const initialState: IWeeksDatesState = {
  dateSelected: undefined,
  date: undefined,

  periodSelected: DEFAULT_PERIOD,
  period: undefined,

  errorDate: '',
  errorPeriod: '',
};

const weeksDatesSlice = createSlice({
  name: 'weeksDates',
  initialState,
  reducers: {
    setDateSelected: (state: IWeeksDatesState, { payload }: PayloadAction<string>) => {
      state.dateSelected = payload;
    },
    setPeriodSelected: (state: IWeeksDatesState, { payload }: PayloadAction<string>) => {
      state.periodSelected = payload;
    },

    setErrorDate: (state: IWeeksDatesState, { payload }: PayloadAction<string>) => {
      state.errorDate = payload;
    },
    setErrorPeriod: (state: IWeeksDatesState, { payload }: PayloadAction<string>) => {
      state.errorPeriod = payload;
    },

    resetWeeksDates: (state: IWeeksDatesState) => {
      state.dateSelected = undefined;
      state.date = undefined;

      state.periodSelected = DEFAULT_PERIOD;
      state.period = undefined;

      state.errorDate = '';
      state.errorDate = '';
    },
    applyWeeksDates: (state: IWeeksDatesState) => {
      state.date = state.dateSelected;
      state.period = state.periodSelected;
    },
    resetSelectedDates: (state: IWeeksDatesState) => {
      state.dateSelected = state.date;
      state.periodSelected = state.period;
    },
  },
});

export const weeksDatesActions = weeksDatesSlice.actions;

export const weeksDatesReducer = weeksDatesSlice.reducer;
