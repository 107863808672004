import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRedeemCode } from '@common-types';
import { ERROR_COUPON_STATUS } from '@constants';
import { ISessionKey } from '@share/common-types';
import { Urls } from '@share/constants';
import { AppThunk } from '@share/utils';
import { getHeaders, axiosInstance } from '@share/utils';

export interface ICouponState {
  redeemCode: IRedeemCode;
  loading: boolean;
  error: string;
  coupon: string;
  isGetCoupon: boolean;
  errorText: string;
}

const initialState: ICouponState = {
  redeemCode: null,
  loading: false,
  error: '',
  coupon: '',
  isGetCoupon: false,
  errorText: '',
};

const zeroItem = 0;

const redeemCodeSlice = createSlice({
  name: 'redeemCode',
  initialState,
  reducers: {
    setLoadingCoupon: (state: ICouponState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setErrorCoupon: (state: ICouponState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setRedeemCode: (state: ICouponState, { payload }: PayloadAction<IRedeemCode>) => {
      state.redeemCode = payload;
    },
    setCoupon: (state: ICouponState, { payload }: PayloadAction<string>) => {
      state.coupon = payload;
    },
    setUpdateCoupon: (state: ICouponState, { payload }: PayloadAction<boolean>) => {
      state.isGetCoupon = payload;
    },
    setErrorText: (state: ICouponState, { payload }: PayloadAction<string>) => {
      state.errorText = payload;
    },
  },
});

export const {
  setLoadingCoupon,
  setErrorCoupon,
  setRedeemCode,
  setCoupon,
  setUpdateCoupon,
  setErrorText,
} = redeemCodeSlice.actions;

export const redeemCodeReducer = redeemCodeSlice.reducer;

export const getRedeemCode = (code: string, sessionKey: ISessionKey): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setLoadingCoupon(true));

    try {
      const { loginStore } = getState();
      const { user } = loginStore;
      const res = await axiosInstance.post(
        Urls.HotelRedeemCode,
        { code, sessionKey, isCondo: false, isHotel: true },
        { ...getHeaders() },
      );

      dispatch(setLoadingCoupon(false));
      dispatch(setRedeemCode(res.data));

      if (res.data.success) {
        dispatch(setUpdateCoupon(true));
      }
    } catch (error) {
      console.error(error);
      dispatch(setErrorCoupon(error.toString()));
      dispatch(setLoadingCoupon(false));
      dispatch(setUpdateCoupon(false));

      if (error?.response?.status === ERROR_COUPON_STATUS && error?.response?.data) {
        dispatch(setErrorText(error?.response?.data[zeroItem]));
      }
    }
  };
};

export const getRemoveCode = (sessionKey: ISessionKey): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const { loginStore } = getState();
      const { user } = loginStore;
      const res = await axiosInstance.post(
        Urls.HotelRemoveCode,
        { sessionKey },
        { ...getHeaders() });

      dispatch(setRedeemCode(res.data));
      dispatch(setUpdateCoupon(false));
    } catch (error) {
      console.error(error);
    }
  };
};
