
import axios, { Canceler } from 'axios';

import { CombinedState, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ICondoUnitsSearch } from '@share/common-types';
import { ISessionKey } from '@share/common-types';
import { AppThunk, RootState, Toaster } from '@share/utils';
import { axiosInstance, getHeaders } from '@share/utils';
import { UrlUtils, getTimeout } from '@share/utils';
import { WEEKS_UNITS_SEARCH_LABEL, Urls, WEEKS_UNITS_SESSION_KEY_LABEL } from '@share/constants';
import { IWeekDetailsState } from './weeks-details';

export interface IWeeksUnitsSearchState {
  date: string;
  period: string;

  error: string;

  isUnitsLoading: boolean;
  condoInfo: ICondoUnitsSearch;
  sessionKeyUnits?: ISessionKey;
  timer: number;
  isSearchUnits: boolean;
}


const initialState: IWeeksUnitsSearchState = {
  date: undefined,
  period: undefined,

  error: '',
  
  isUnitsLoading: false,
  condoInfo: null,
  timer: null,
  isSearchUnits: false,
};

const weeksUnitsSearchSlice = createSlice({
  name: 'weeks-unit-search',
  initialState,
  reducers: {
    setDate: (state: IWeeksUnitsSearchState, { payload }: PayloadAction<string>) => {
      state.date = payload;
    },
    setPeriod: (state: IWeeksUnitsSearchState, { payload }: PayloadAction<string>) => {
      state.period = payload;
    },
    reset: (state: IWeeksUnitsSearchState) => {
      state.date = undefined;
      state.period = undefined;
      state.error = '';
    },

    setError: (state: IWeeksUnitsSearchState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setIsUnitsLoading: (state: IWeeksUnitsSearchState, { payload }: PayloadAction<boolean>) => {
      state.isUnitsLoading = payload;
    },
    setCondoInfo: (state: IWeeksUnitsSearchState, { payload }: PayloadAction<ICondoUnitsSearch>) => {
      state.condoInfo = payload;
    },
    setSessionKey: (state: IWeeksUnitsSearchState, { payload }: PayloadAction<ISessionKey>) => {
      state.sessionKeyUnits = payload;
    },
    setTimer: (state: IWeeksUnitsSearchState, { payload }: PayloadAction<number>) => {
      state.timer = payload;
    },
    setSearchUnits: (state: IWeeksUnitsSearchState, { payload }: PayloadAction<boolean>) => {
      state.isSearchUnits = payload;
    },

    resetState: () => {
      return initialState;
    },
  },
});

export const weeksUnitsSearchActions = weeksUnitsSearchSlice.actions;

export const weeksUnitsSearchReducer = weeksUnitsSearchSlice.reducer;

let cancelRequest: Canceler;
let expirationTimeout: number;

export const updateWeeksUnits = (
  expireDate: string,
  dispatch: ThunkDispatch<RootState, unknown, Action<string>>,
  getState: () => CombinedState<{ weeksDetailsStore: IWeekDetailsState }>,
): void => {
  // @ts-ignore
  expirationTimeout = setTimeout(() => {
    const { weeksDetailsStore } = getState();

    dispatch(getWeeksUnitsDetails(weeksDetailsStore.week.propertyDetails.id));
  }, getTimeout(expireDate));
  dispatch(weeksUnitsSearchActions.setTimer(expirationTimeout));
};

export const getWeeksUnitsDetails = (propertyId: number): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(weeksUnitsSearchActions.setIsUnitsLoading(true));

    if (!!expirationTimeout) {
      clearTimeout(expirationTimeout);
    }

    if (cancelRequest) {
      cancelRequest();
    }

    const { weeksUnitsSearchStore } = getState();
    const { date, period } = weeksUnitsSearchStore;

    try {
      UrlUtils.setUrl(WEEKS_UNITS_SEARCH_LABEL, {
        date,
        period,
      });

      const res = await axiosInstance.post(
        Urls.WeeksUnitSearch,
        {
          date,
          period,
          propertyId,
        },
        {
          ...getHeaders(),
          cancelToken: new axios.CancelToken((canceler: Canceler) => {
            cancelRequest = canceler;
          }),
        },
      );

      dispatch(weeksUnitsSearchActions.setSearchUnits(true));
      dispatch(weeksUnitsSearchActions.setCondoInfo(res.data));
      dispatch(weeksUnitsSearchActions.setIsUnitsLoading(false));
      dispatch(weeksUnitsSearchActions.setSessionKey(res.data.sessionKey));

      UrlUtils.setUrl(WEEKS_UNITS_SESSION_KEY_LABEL, res.data.sessionKey);
      dispatch(weeksUnitsSearchActions.setError(''));
    } catch (error) {
      console.error(error);
      dispatch(weeksUnitsSearchActions.setError('No Units availables for the criteria selected. Change the criteria to continue.'));

      if (!(error instanceof axios.Cancel)) {
        dispatch(weeksUnitsSearchActions.setIsUnitsLoading(false));
      }

      Toaster.error('No Units availables for the criteria selected. Change the criteria to continue.');
    }
  };
};
