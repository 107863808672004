
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Responsive, RootState } from '@share/utils';
import { ILoginState } from '@share/store/slices';
import { useEffect, useState } from 'react';
import { DISPLAY_HEADER_MESSAGE_LABEL } from '@constants';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, RouteComponentProps {}

function ApplicationHeaderMessageComponent(props: IProps) {

  const { loginStore } = props;
  const { loading, account, user } = loginStore;
  
  const [display, setDisplay] = useState(true);

  useEffect(() => {
    const displayHeaderMessageStorage = localStorage.getItem(DISPLAY_HEADER_MESSAGE_LABEL);
    if (!isEmpty(displayHeaderMessageStorage)) {
      setDisplay(displayHeaderMessageStorage === 'true');
    }
  }, []);

  if (!display || loading || !account || !user || !account?.displayHeaderMessage || isEmpty(account?.headerMessage)) {
    return null;
  }

  const handleCloseMessage = () => {
    localStorage.setItem(DISPLAY_HEADER_MESSAGE_LABEL, 'false');
    setDisplay(false);
  }

  const style: any = {};
  if (!isEmpty(account?.headerMessageColor)) {
    style.backgroundColor = account?.headerMessageColor;
  }

  return (
    <Responsive>
      <div className="header-message__container" style={style}>
        <label>{account?.headerMessage}</label>
        <div onClick={handleCloseMessage}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    </Responsive>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

const ApplicationComponentWithRouter = withRouter(ApplicationHeaderMessageComponent);

export const ApplicationHeaderMessage = connect(mapStateToProps)(ApplicationComponentWithRouter);
