import React from 'react';
import { GetawayBookingWrapper } from '@components';
import './style.scss';

interface IProps {}

export class GetawayBookingPage extends React.Component<IProps> {
  render(): React.ReactNode {
    return <GetawayBookingWrapper />;
  }
}
