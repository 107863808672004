import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { Routes } from '@share/constants';
import { ILoginState, IMenuState } from '@share/store/slices';
import { RootState } from '@share/utils';

import VacationHome from '@assets/images/sidekick/vacation-home.jpg';

import './style.scss';
import { isEmpty } from 'lodash';

interface IMapStateToProps {
	menuStore: IMenuState;
	loginStore: ILoginState;
}
  
interface IProps extends IMapStateToProps, RouteComponentProps, WrappedComponentProps { }

function SideKickRewardsTermsComponent(props: IProps) {	
	const { loginStore, menuStore } = props;
	const { account } = loginStore;
	const { items } = menuStore;

	const [scroll, setScroll] = useState(0);

	useEffect(() => window.addEventListener("scroll", handleScroll), []);

	const handleScroll = () => {
		const scrollPosition = window.scrollY;
		setScroll(scrollPosition / 3);
	}

	const sidekickName = !isEmpty(items?.sideKickName) ? items?.sideKickName : 'Sidekick Rewards';
	const sidekickNameShort = !isEmpty(items?.sideKickShortName) ? items?.sideKickShortName : 'Sidekick';
	
	return (
		<div className="sidekicks-terms-wrapper">
			<section className="image-bg overlay parallax">
				<div className="background-image-holder fadeIn" style={{ transform: `translate3d(0px, ${scroll}px, 0px)`, background: `url(${VacationHome})`, top: '-40px' }}>
				</div>
				<div className="container">
					<div className="col-lg-12">
						<h1 className="getaway-title secondary-font"><FormattedMessage id="sidekick.rewards.terms.title" /></h1>
					</div>
				</div>
			</section>

			<section className="search-results-section">
				<div className="container">
				<div className="col-md-12" style={{ marginBottom: '16px' }}>
						<div className="card">
							<div className="row">
								<div className="col-lg-12 ">
									<h2 className="text-center secondary-font"><FormattedMessage id="sidekick.rewards.terms.subtitle" values={{ name: sidekickName }} /></h2>
									<ol className="terms">
										<li><FormattedMessage id="sidekick.rewards.terms.message1" values={{ name: sidekickName }} /> <Link to={`/${account?.name}${Routes.SidekickRewardsPrivacy}`}><FormattedMessage id="privacy.policy" /></Link>.</li><br/>

										<li><FormattedMessage id="sidekick.rewards.terms.message2" values={{ name: sidekickName, nameShort: sidekickNameShort }} /></li><br/>

										<li><FormattedMessage id="sidekick.rewards.terms.message3" values={{ nameShort: sidekickNameShort }} /></li><br/>

										<li><FormattedMessage id="sidekick.rewards.terms.message4" values={{ name: sidekickName, nameShort: sidekickNameShort }} /></li><br/>

										<li><FormattedMessage id="sidekick.rewards.terms.message5" values={{ name: sidekickName }} /></li><br/>

										<li><FormattedMessage id="sidekick.rewards.terms.message6" values={{ name: sidekickName }} /></li><br/>

										<li><FormattedMessage id="sidekick.rewards.terms.message7" values={{ name: sidekickName, nameShort: sidekickNameShort }} /></li><br/>

										<li><FormattedMessage id="sidekick.rewards.terms.message8" values={{ name: sidekickName, nameShort: sidekickNameShort }} /></li><br/>

										<li><FormattedMessage id="sidekick.rewards.terms.message9" values={{ name: sidekickName }} /></li><br/>

										<li><FormattedMessage id="sidekick.rewards.terms.message10" values={{ name: sidekickName }} /></li><br/>
									</ol>

								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
	return {
		menuStore: state.navigationMenuStore,
		loginStore: state.loginStore,
	};
};
  
const ComponentIntl = injectIntl(SideKickRewardsTermsComponent);

export const SideKickRewardsTerms = connect(mapStateToProps)(withRouter(ComponentIntl));
