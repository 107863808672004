
import {
  ICondoRequest,
  SearchTypeEnum,
  CondoRequestTypeEnum,
  ICondoUnitsRequest,
} from '@share/common-types';
import {
  DateSearchTypeEnum,
  ICondoGuests,
} from '@share/common-types';
import { ICondoFlexibleDatePickerState, ICondoStrictDatesState } from '@share/store/slices';
import { DEFAULT_RESIDENCY, Routes, Urls } from '@share/constants';
import { formatDateCheckInOut } from '@share/utils';
import { LS_ANYTIME_WARNING_DISPLAYED_KEY } from '@share/constants';

const isoFormat = 'yyyy-MM-dd';
const startTime = 'T00:00:01.000Z';

export const getCondoRequest = (
  guests: ICondoGuests,
  startDate: string,
  endDate: string,
): ICondoRequest => {
  return {
    residency: DEFAULT_RESIDENCY,
    checkIn: formatDateCheckInOut(startDate, isoFormat) + startTime,
    checkOut: formatDateCheckInOut(endDate, isoFormat) + startTime,
    guests,
  };
};

export const getReserveUnitUrl = (accountName: string, condoId: number, id: string): string => {
  return `/${accountName}${Routes.UnitBook}/${condoId}/${id}${location.search}`;
};

export const getCondoRequestType = (
  condoStrictDatesStore: ICondoStrictDatesState
): CondoRequestTypeEnum => {
  const { searchType, endDate, startDate } = condoStrictDatesStore;
  const isStrict = searchType === DateSearchTypeEnum.Strict && startDate && endDate;

  if (isStrict) {
    return CondoRequestTypeEnum.Strict;
  } else {
    return CondoRequestTypeEnum.Flexible;
  }
};

export const getCondoRequestUrl = (
  requestType: SearchTypeEnum,
  condoStrictDatesStore: ICondoStrictDatesState,
  isReuse = false,
): Urls => {
  const condoRequestType: CondoRequestTypeEnum = getCondoRequestType(condoStrictDatesStore);
  const searchUrlMap: { [key: string]: Urls } = {
    [CondoRequestTypeEnum.Strict]: Urls.CondosSearchStrictPagination,
    [CondoRequestTypeEnum.Flexible]: Urls.CondosSearchFlexiblePagination,
    [CondoRequestTypeEnum.Anytime]: Urls.CondosSearchAnytimePagination,
  };
  const paginationUrlMap = {
    [CondoRequestTypeEnum.Strict]: Urls.CondosSearchStrictPagination,
    [CondoRequestTypeEnum.Flexible]: Urls.CondosSearchFlexiblePagination,
    [CondoRequestTypeEnum.Anytime]: Urls.CondosSearchAnytimePagination,
  };

  return requestType === SearchTypeEnum.NewSearch && !isReuse
    ? searchUrlMap[condoRequestType]
    : paginationUrlMap[condoRequestType];
};

export const getCondoUnitsRequest = (
  guests: ICondoGuests,
  startDate: string,
  endDate: string,
  searchType: DateSearchTypeEnum,
  selectedMonths: string[]
): ICondoUnitsRequest => {
  const request = {
    adultsCount: guests.adultsCount,
    childrenCount: guests.kidsCount,
    bedroomsCount: guests.includeStudio ? 0 : guests.bedroomsCount ? guests.bedroomsCount : 1,
  } as ICondoUnitsRequest;

  if (searchType === DateSearchTypeEnum.Strict) {
    request.checkIn =  formatDateCheckInOut(startDate, isoFormat) + startTime;
    request.checkOut = formatDateCheckInOut(endDate, isoFormat) + startTime;
  } else {
    request.months = selectedMonths;
  }

  return request;
};

export const shouldDisplayAnytimeWarning = (requestType: CondoRequestTypeEnum, state: ICondoFlexibleDatePickerState): boolean => {
  const s = localStorage.getItem(LS_ANYTIME_WARNING_DISPLAYED_KEY);

  if (!(s?.length > 0)) {
    return true;
  }

  const d = new Date(+s);
  const c = new Date();
  return d.getFullYear() !== c.getFullYear()
    || d.getMonth() !== c.getMonth()
    || d.getDay() !== c.getDay();
}

export const disableAnytimeSearchWarning = () => {
  localStorage.setItem(LS_ANYTIME_WARNING_DISPLAYED_KEY, new Date().getTime().toString());
}