
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Urls } from '@share/constants';
import { AppThunk, IsClientCashEnabled } from '@share/utils';
import { getHeaders, axiosInstance } from '@share/utils';
import { get, isEmpty } from 'lodash';

export interface IPasswordState {
  message: string;
  error: string;
  isSuccess: boolean;
  loading: boolean;
}

const initialState: IPasswordState = {
  message: '',
  error: '',
  isSuccess: false,
  loading: false
};

const passwordSlice = createSlice({
  name: 'password',
  initialState,
  reducers: {
    setSuccess: (state: IPasswordState, { payload }: PayloadAction<boolean>) => {
      state.isSuccess = payload;
    },
    setMessage: (state: IPasswordState, { payload }: PayloadAction<string>) => {
      state.message = payload;
    },
    setError: (state: IPasswordState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setLoading: (state: IPasswordState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    }
  },
});

export const passwordSliceActions = passwordSlice.actions;
export const passwordReducer = passwordSlice.reducer;

export const passwordSelector = (state: { passwordStore: IPasswordState }): IPasswordState => {
  return state.passwordStore;
};

export const verifyUser = (token: string): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(`${Urls.Users}/verify`, { token }, { ...getHeaders() });
      const { statusCode, message, data } = response.data;
      dispatch(passwordSliceActions.setSuccess(statusCode === 200));
      dispatch(passwordSliceActions.setError(data));
      dispatch(passwordSliceActions.setMessage(message));
    } catch (error) {
      let errorMessage = error?.response?.data ? get(JSON.parse(error?.response?.data), 'message', null) : null;
      if (isEmpty(errorMessage)) {
        errorMessage = error.toString();
      }
      dispatch(passwordSliceActions.setSuccess(false));
      dispatch(passwordSliceActions.setMessage(errorMessage.toString()));
    }
  };
};

export const sendVerifyEmail = (email: string): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(`${Urls.Users}/send-verify`, { email }, { ...getHeaders() });
      const { statusCode, message } = response.data;
      dispatch(passwordSliceActions.setSuccess(statusCode === 200));
      dispatch(passwordSliceActions.setMessage(message));
    } catch (error) {
      let errorMessage = error?.response?.data ? get(JSON.parse(error?.response?.data), 'message', null) : null;
      if (isEmpty(errorMessage)) {
        errorMessage = error.toString();
      }
      dispatch(passwordSliceActions.setSuccess(false));
      dispatch(passwordSliceActions.setMessage(errorMessage.toString()));
    }
  };
};

export const forgot = (email: string): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(`${Urls.Users}/forgot`, { email }, { ...getHeaders() });
      const { statusCode, message } = response.data;
      dispatch(passwordSliceActions.setSuccess(statusCode === 200));
      dispatch(passwordSliceActions.setMessage(message));
    } catch (error) {
      let errorMessage = error?.response?.data ? get(JSON.parse(error?.response?.data), 'message', null) : null;
      if (isEmpty(errorMessage)) {
        errorMessage = error.toString();
      }
      dispatch(passwordSliceActions.setSuccess(false));
      dispatch(passwordSliceActions.setMessage(errorMessage.toString()));
    }
  };
};

export const reset = (token: string, password: string, confirmPassword: string): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(`${Urls.Users}/reset`, { token, password, confirmPassword }, { ...getHeaders() });
      const { statusCode, message } = response.data;
      dispatch(passwordSliceActions.setSuccess(statusCode === 200));
      dispatch(passwordSliceActions.setMessage(message));
    } catch (error) {
      let errorMessage = error?.response?.data ? get(JSON.parse(error?.response?.data), 'message', null) : null;
      if (isEmpty(errorMessage)) {
        errorMessage = error.toString();
      }
      dispatch(passwordSliceActions.setSuccess(false));
      dispatch(passwordSliceActions.setMessage(errorMessage.toString()));
    }
  };
};

export const change = (currentPassword: string, newPassword: string): AppThunk => {
  return async (dispatch, getState) => {
    try {
      dispatch(passwordSliceActions.setLoading(true));
      const { loginStore } = getState();
      const { account, user, userWallet } = loginStore;
      const isClientCashEnabled = IsClientCashEnabled(account);

      const userId = isClientCashEnabled ? userWallet?.userId : user?.userId;

      const response = await axiosInstance.post(`${Urls.Users}/change`, { userId, currentPassword, newPassword }, { ...getHeaders() });
      const { statusCode, message } = response.data;
      dispatch(passwordSliceActions.setSuccess(statusCode === 200));
      dispatch(passwordSliceActions.setMessage(message));
      dispatch(passwordSliceActions.setLoading(false));
    } catch (error) {
      let errorMessage = error?.response?.data ? get(JSON.parse(error?.response?.data), 'message', null) : null;
      if (isEmpty(errorMessage)) {
        errorMessage = error.toString();
      }
      dispatch(passwordSliceActions.setSuccess(false));
      dispatch(passwordSliceActions.setMessage(errorMessage.toString()));
      dispatch(passwordSliceActions.setLoading(false));
    }
  };
};
