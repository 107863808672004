import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { Canceler } from 'axios';

import { CondoLocationsEnum, ICondoLocation } from '@share/common-types';
import { Urls } from '@share/constants';
import { getHeaders, axiosInstance, AppThunk } from '@share/utils';

export interface IWeeksLocationsState {
  locations: ICondoLocation[];

  loading: boolean;
  error: string;

  selectedLocationLabel: string;
  selectedLocation: ICondoLocation;
  
  locationLabel: string;
  location: ICondoLocation;
}

const initialState: IWeeksLocationsState = {
  locations: [],
  loading: false,
  error: '',

  selectedLocationLabel: '',
  selectedLocation: undefined,
  locationLabel: '',
  location: undefined
};

const weeksLocationsSlice = createSlice({
  name: 'weeksLocations',
  initialState,
  reducers: {
    setLoading: (state: IWeeksLocationsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: IWeeksLocationsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setLocations: (state: IWeeksLocationsState, { payload }: PayloadAction<ICondoLocation[]>) => {
      state.locations = payload;
    },

    setSelectLocation: (state: IWeeksLocationsState, { payload }: PayloadAction<ICondoLocation>) => {
      state.selectedLocation = payload;
    },
    setSelectLocationLabel: (state: IWeeksLocationsState, { payload }: PayloadAction<string>) => {
      state.selectedLocationLabel = payload;
    },
    resetSelectedpLocation: (state: IWeeksLocationsState) => {
      state.selectedLocationLabel = state.locationLabel;
      state.selectedLocation = state.location;
    },

    applyLocation: (state: IWeeksLocationsState) => {
      state.locationLabel = state.selectedLocationLabel;
      state.location = state.selectedLocation;
    },

    resetWeeksLocations: (state: IWeeksLocationsState) => {
      state.locations = [];
      state.loading = false;
      state.error = '';
      state.selectedLocationLabel = '';
      state.selectedLocation = null;
      state.locationLabel = '';
      state.location = null;
    },
  },
});

export const weeksLocationsActions = weeksLocationsSlice.actions;

export const weeksLocationsReducer = weeksLocationsSlice.reducer;

let cancelRequest: Canceler;

export const getWeeksLocations = (search: string, selectedLocation: ICondoLocation | undefined, onlyCities: boolean): AppThunk => {
  return async (dispatch) => {
    dispatch(weeksLocationsActions.setLoading(true));

    try {
      if (cancelRequest) {
        cancelRequest();
      }

      const res = await axiosInstance.get(Urls.Locations, {
        ...getHeaders(),
        params: {
          SearchString: search.trim(),
        },
        cancelToken: new axios.CancelToken((canceler: Canceler) => {
          cancelRequest = canceler;
        }),
      });
      let locations: ICondoLocation[] = [...res.data.locations?.map((l: any) => ({
          id: l.code,
          name: l.name,
          locationType: l.type === 'City' ? CondoLocationsEnum.GenericRegion : CondoLocationsEnum.GenericCondo,
          realType: l.type === 'City' ? CondoLocationsEnum.GenericRegion : CondoLocationsEnum.GenericCondo,
          geoLocation: l.geoLocation
        } as ICondoLocation))];

      if (selectedLocation && search === selectedLocation.name) {
        locations = locations.filter(({ id }) => id !== selectedLocation.id);
        locations.unshift(selectedLocation);
      }

      if (onlyCities) {
        locations = locations.filter(({ locationType }) => locationType === CondoLocationsEnum.GenericRegion);
      }

      dispatch(weeksLocationsActions.setLoading(false));
      dispatch(weeksLocationsActions.setLocations(locations));
    } catch (error) {
      if (!(error instanceof axios.Cancel)) {
        console.error(error);
        dispatch(weeksLocationsActions.setLoading(false));
      }
    }
  };
};
