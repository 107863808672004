import { AxiosPromise } from 'axios';
import { axiosInstance } from '@share/utils';
import { Urls } from '@share/constants';
import { IExternalWP } from '@common-types';

export const externalAPI = {
  getMemberStories(): AxiosPromise<IExternalWP[]> {
    return axiosInstance.get(Urls.MemberStories);
  },

  getMagazine(): AxiosPromise<IExternalWP[]> {
    return axiosInstance.get(Urls.Magazine);
  },
};
