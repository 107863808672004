import React from 'react';
import ReactGA from 'react-ga4';

import { connect } from 'react-redux';
import { Modal, Tooltip } from 'antd';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { getAccountUsernameFromPath, RootState, VACATIONS_PAGE } from '@share/utils';
import { C_ACCEPT_NEW_PRICE, C_DECLINE_NEW_PRICE, D_ACCEPT_NEW_PRICE, D_DECLINE_NEW_PRICE, Routes } from '@share/constants';
import { Currency } from '@components';
import { BlueButton } from '@share/components';

import Decrease from '@assets/images/price_decrease.png';
import Increase from '@assets/images/price_increase.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ILoginState } from '@share/store/slices';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps, RouteComponentProps {
  isIncrease: boolean;
  isBookPage?: boolean;
  diff: number;
  newPrice: number;
  currency: string;

  diffSavings?: number;
  newSavings?: number;

  isDeal?: boolean;
  isVacationRentals?: boolean;
}

interface IState {
  visible: boolean;
}

const MODAL_WIDTH = 400;
const MIN_DIFF_CHECK = 1;

class BasePriceChangeModalComponent extends React.Component<IProps, IState> {

  state: IState = { visible: true };

  handleAccept = () => {
    const { loginStore, isBookPage } = this.props;
    const { account } = loginStore;

    const key = isBookPage ? C_ACCEPT_NEW_PRICE : D_ACCEPT_NEW_PRICE;

    ReactGA.event({
      category: account?.name as string,
      action: `${key}_${account?.name.toUpperCase()}`,
      label: `User accepted price change`,
      nonInteraction: false,
    });

    this.setState({ visible: false });
  }

  handleGoToSearch = () => {
    const { loginStore, isBookPage } = this.props;
    const { account } = loginStore;

    const key = isBookPage ? C_DECLINE_NEW_PRICE : D_DECLINE_NEW_PRICE;

    ReactGA.event({
      category: account?.name as string,
      action: `${key}_${account?.name.toUpperCase()}`,
      label: `User declined price change`,
      nonInteraction: false,
    });

    const accountName = getAccountUsernameFromPath(this.props.history);

    if (this.props.isVacationRentals) {
      this.props.history.push(`/${accountName}${Routes.Search}/${VACATIONS_PAGE}${location.search}`);
    } else {
      this.props.history.push(`/${accountName}${Routes.Search}${location.search}`);
    }
  }

  render(): React.ReactNode {
    const { isIncrease, diff, newPrice, currency, diffSavings, newSavings, isDeal, loginStore } = this.props;
    const { account } = loginStore;
    const { visible } = this.state;

    const image = isIncrease ? Increase : Decrease;

    const isPriceChange = diff > MIN_DIFF_CHECK;
    const isSavingsChange = (diffSavings ? diffSavings : 0) > MIN_DIFF_CHECK;
    
    const fontFamily = account?.fontFamily;
    const secondaryFontFamily = account?.secondaryFontFamily;
    const generalBorderRadius = account?.generalBorderRadius;
    
    const style = !isEmpty(generalBorderRadius) ? { borderRadius: `${generalBorderRadius}px` } : {};  

    const background = account?.buttonColor;
    const border = account?.buttonBorder;
    const color = account?.buttonTextColor;
    const borderRadius = account?.buttonBorderRadius;
    const buttonTextTransform = account?.buttonTextTransform;
      
    const styleButtonBorderRadius = !isEmpty(borderRadius) ? { borderRadius: `${borderRadius}px` } : {};  
    const styleButtonnTextTransform = (buttonTextTransform && !isEmpty(buttonTextTransform)) ? { ...styleButtonBorderRadius, textTransform: buttonTextTransform as any } : { ...styleButtonBorderRadius };  
    const styleButtonBorder = !isEmpty(border)? { ...styleButtonnTextTransform, border } : { ...styleButtonnTextTransform };  
    const styleButtonColor = !isEmpty(color)? { ...styleButtonBorder, color } : { ...styleButtonBorder };  
    const styleButton = !isEmpty(background)? { ...styleButtonColor, background } : { ...styleButtonColor };  

    return (
      <div className="price-change-modal">
        <Modal
          className={`price-change-modal-wrapper ${fontFamily ? `${fontFamily}-font`: ''} ${secondaryFontFamily ? `secondary-${secondaryFontFamily}-font`: ''}`}
          wrapClassName="price-change-modal-wrapper__wrap"
          open={visible && (isPriceChange || isSavingsChange)}
          footer={null}
          title={null}
          closable={false}
          keyboard={false}
          destroyOnClose={true}
          width={MODAL_WIDTH}
        >
          <div className="price-change-modal-wrapper__wrapper">
            <img src={image} alt="" />

            {isPriceChange && (
              <span className="price-change-modal-wrapper__message"><FormattedMessage id="new.price.message" /></span>)}

            {isPriceChange && (
              <span className={`price-change-modal-wrapper__price-change ${isIncrease ? 'increased' : 'decreased'}`}>{isIncrease ? this.props.intl.formatMessage({ id: 'price.changed.increased' }) : this.props.intl.formatMessage({ id: 'price.changed.decreased' })} <Currency currency={currency} /> {diff.toFixed(2)}</span>)}

            {isSavingsChange && (
              <span style={{ marginBottom: '25px' }} className="price-change-modal-wrapper__message"><FormattedMessage id="updated.savings.message" /></span>)}

            {isPriceChange && (
              <div className="price-change-modal-wrapper__price" style={style}>
                <span className="price-type"><FormattedMessage id="new.price" /></span>
                <span className="price-value"><Currency currency={currency} /> {newPrice.toFixed(2)}</span>
              </div>)}

            {isSavingsChange && (
              <div className="price-change-modal-wrapper__price" style={style}>
                <span className="price-type"><FormattedMessage id="updated.savings" /></span>
                <span className="price-value">{newSavings?.toFixed(0)} %</span>
              </div>)}

            <div className={`price-change-modal-wrapper__buttons ${!isIncrease ? 'decrease' : ''}`}>
              {!isDeal && isIncrease && (<div style={styleButton} className="go-to-search-button" onClick={this.handleGoToSearch}><FormattedMessage id="availability.status.modal.search_again" /></div>)}
              <BlueButton className="accept-button" onClick={this.handleAccept}><FormattedMessage id="accept" /></BlueButton>
            </div>

            <span className="price-change-modal-wrapper__explanation">
              <FormattedMessage id="new.price.message.by_clicking" />
              <Tooltip placement="rightBottom" title={this.props.intl.formatMessage({ id: 'new.price.message.by_clicking.tooltip' })}>
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            </span>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

export const BasePriceChangeModal = connect(mapStateToProps)(injectIntl(withRouter(BasePriceChangeModalComponent)));
