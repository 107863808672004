import React from 'react';

export const BedRoomSvg: React.FunctionComponent = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5 11.6666V8.33325C17.5 7.89122 17.3244 7.4673 17.0118 7.15474C16.6993 6.84218 16.2754 6.66659 15.8333 6.66659H10.1667V11.6666"
      fill="#C5C5C5"
    />
    <path
      d="M2.5 5.83325V14.9999M2.5 11.6666H17.5M17.5 11.6666V8.33325C17.5 7.89122 17.3244 7.4673 17.0118 7.15474C16.6993 6.84218 16.2754 6.66659 15.8333 6.66659H10.1667V11.6666M17.5 11.6666V14.9999"
      stroke="#C5C5C5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 10C7.10457 10 8 9.10457 8 8C8 6.89543 7.10457 6 6 6C4.89543 6 4 6.89543 4 8C4 9.10457 4.89543 10 6 10Z"
      fill="#C5C5C5"
    />
  </svg>
);
