import { ICardBooking, IInsurance, IInsuranceRequestBase, IInsuranceRequestTravelers, } from '@common-types';
import { NULL_VALUE, USD_CURRENCY } from '@constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IReservation, IStatic } from '@share/common-types';
import { Urls } from '@share/constants';
import { Toaster, axiosInstance } from '@share/utils';
import { AppThunk } from '@share/utils';
import axios, { Canceler } from 'axios';
import { get, isEmpty, isString } from 'lodash';
import moment from 'moment';

export enum InsuranceSelection {
  YES,
  NO
}

export interface IInsuranceState {
  insurance: IInsurance | null;
  baseRequest: IInsuranceRequestBase | null;
  travelersRequest: IInsuranceRequestTravelers | null;
  selection: InsuranceSelection | null;
  loading: boolean;
  error: string;
}

const initialState: IInsuranceState = {
  insurance: null,
  baseRequest: null,
  travelersRequest: null,
  selection: null,
  loading: false,
  error: '',
};

const insuranceSlice = createSlice({
  name: 'insurance',
  initialState,
  reducers: {
    setLoading: (state: IInsuranceState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: IInsuranceState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setInsurance: (state: IInsuranceState, { payload }: PayloadAction<IInsurance>) => {
      state.insurance = payload;
    },
    setBaseRequest: (state: IInsuranceState, { payload }: PayloadAction<IInsuranceRequestBase>) => {
      state.baseRequest = payload;
    },
    setTravelersRequest: (state: IInsuranceState, { payload }: PayloadAction<IInsuranceRequestTravelers>) => {
      state.travelersRequest = payload;
    },
    setSelection: (state: IInsuranceState, { payload }: PayloadAction<InsuranceSelection>) => {
      state.selection = payload;
    },
    reset: (state: IInsuranceState) => {
      state.insurance = null;
      state.loading = false;
      state.error = '';
    }
  },
});

export const insuranceActions = insuranceSlice.actions;

export const insuranceReducer = insuranceSlice.reducer;

export const getInsuranceQuoteByReservation = (reservation: IReservation, staticData: IStatic): AppThunk => {
  return async (dispatch) => {

    const mainGuest: any = get(reservation?.rooms?.map(r => r.guests).flat().filter(g => g?.isMainGuest === true), '[0]');

    if (mainGuest?.countryCode === 'US') {
      const price = reservation?.priceDetail?.totalValue;
      const currency = reservation?.priceDetail?.totalCurrency;
      
      dispatch(getInsuranceQuote(price, currency, moment(reservation?.checkIn, 'MM/DD/yyyy').format('yyyy-MM-DD'), moment(reservation?.checkOut, 'MM/DD/yyyy').format('yyyy-MM-DD'), mainGuest?.stateCode, reservation?.numberGuest, staticData?.countryCode, reservation?.sessionId));
    }
  };
}

let cancelQuoteRequest: Canceler;

export const getInsuranceQuote = (
                  amount: number,
                  currency: string,
                  checkIn: string,
                  checkOut: string,
                  state: string,
                  travelers: number,
                  destinationCountry: string,
                  sessionId: string, 
                  priceChangeHandler?: () => void): AppThunk => {
  return async (dispatch, getState) => {

    if (cancelQuoteRequest) {
      cancelQuoteRequest();
    }

    const { insuranceStore, loginStore, navigationMenuStore } = getState();
    const insurance: IInsurance = { ...insuranceStore?.insurance };
    const travelersRequest: IInsuranceRequestTravelers = { ...insuranceStore?.travelersRequest };

    const prevQuotePrice = insurance?.productDetails?.price;
    const prevQuoteState = travelersRequest?.residency?.isoStateOrProvince;

    const { account } = loginStore;
    const { items } = navigationMenuStore;

    dispatch(insuranceActions.setLoading(true));
    dispatch(insuranceActions.setInsurance(NULL_VALUE));
    dispatch(insuranceActions.setError(NULL_VALUE));
    
    if (!account?.enableInsurance && !items?.isRemoveInsurance) {
      dispatch(insuranceActions.setLoading(false));
      return;
    }

    const requestBase: IInsuranceRequestBase = {
      sessionId,
      totalPrice: {
        isoCurrencyCode: currency ? currency : USD_CURRENCY,
        value: amount
      },
      booking: {
        startDateTime: checkIn,
        endDateTime: checkOut,
        destinationIsoCountryCode: destinationCountry
      }
    };
    const requestTravelers: IInsuranceRequestTravelers = {
      numberOfTravelers: travelers,
      residency: {
        isoStateOrProvince: state ? state : 'FL',
        isoCountry: 'US'
      }
    };

    try {
      const response = await axiosInstance.post(Urls.InsuranceGetQuote, {
        ...requestBase,
        travelers: {
          ...requestTravelers
        }
      },{
        cancelToken: new axios.CancelToken((canceler: Canceler) => {
          cancelQuoteRequest = canceler;
        }),
      });

      dispatch(insuranceActions.setInsurance(response.data));
      dispatch(insuranceActions.setBaseRequest(requestBase));
      dispatch(insuranceActions.setTravelersRequest(requestTravelers));
      dispatch(insuranceActions.setLoading(false));
      
      if (priceChangeHandler && requestTravelers?.residency?.isoStateOrProvince !== prevQuoteState && response.data?.productDetails?.price !== prevQuotePrice) {
        priceChangeHandler();
      }

    } catch (error: any) {
      console.error(error);

      const errorObject = isString(error?.response?.data) ? JSON.parse(error?.response?.data) : error?.response?.data;
      const errorReason = get(errorObject, 'reason', error.toString());

      dispatch(insuranceActions.setError(!isEmpty(errorReason) ? errorReason : error.toString()));
      dispatch(insuranceActions.setLoading(false));
    } finally {
      dispatch(insuranceActions.setLoading(false));
    }
  };
};

export const refreshInsuranceQuote = (state?: string, priceChangeHandler?: () => void): AppThunk => {
  return async (dispatch, getState) => {
    const { insuranceStore } = getState();
    const { baseRequest, travelersRequest } = insuranceStore;

    dispatch(getInsuranceQuote(
        baseRequest?.totalPrice?.value,
        baseRequest?.totalPrice?.isoCurrencyCode,
        baseRequest?.booking?.startDateTime,
        baseRequest?.booking?.endDateTime,
        state as string,
        travelersRequest?.numberOfTravelers,
        baseRequest?.booking?.destinationIsoCountryCode,
        baseRequest?.sessionId,
        priceChangeHandler));
  };
};

export const addInsuranceToReservation = (reservation: IReservation, card: ICardBooking, callBack: () => void): AppThunk => {
  return async (dispatch, getState) => {
    const { insuranceStore } = getState();
    const { insurance } = insuranceStore;
    
    dispatch(insuranceActions.setLoading(true));

    try {
      const mainGuest: any = get(reservation?.rooms?.map(r => r.guests).flat().filter(g => g?.isMainGuest === true), '[0]');
  
      await axiosInstance.post(Urls.InsuranceCheckout, {
        quoteId: insurance?.quoteId,
        BookingId: reservation?.bookingId,
        travelers: [
          {
              firstName: mainGuest?.givenName,
              middleName: '',
              lastName: mainGuest?.surName,
              addresses: [
                  {
                      type: 'Residency',
                      street1: mainGuest?.addressLine,
                      city: mainGuest?.city,
                      postalCode: mainGuest?.postalCode
                  }
              ],
              email: mainGuest?.email,
              phone: mainGuest?.phone
          }
        ],
        paymentDetail: {
          cardDetails: 
          {
              cardholderName: card?.holderName,
              cardholderAddress: {
                  type: 'Residency',
                  isoStateOrProvince: card?.state,
                  postalCode: card?.zipCode,
                  isoCountry: card?.country
              },
              cardType: card?.cardType,
              cardNumber: card?.cardNumber,
              expirationDate: `${card?.expireDate?.substring(0, 2)}${card?.expireDate?.substring(2)}`,
              securityCode: card?.cvv
          }      
        }
      });

      dispatch(insuranceActions.setLoading(false));

      Toaster.success('Insurance added successfuly');

      callBack();

    } catch (error: any) {
      console.error(error);
      
      const errorObject = isString(error?.response?.data) ? JSON.parse(error?.response?.data) : error?.response?.data;
      const errorReason = get(errorObject, 'reason', error.toString());

      dispatch(insuranceActions.setError(!isEmpty(errorReason) ? errorReason : error.toString()));
      dispatch(insuranceActions.setLoading(false));

      Toaster.error('There was an error creating your insurance. Please try again. If the problem persist contact system administrator.');
    }
  };
};
