import React from 'react';

export const GreyLoaderSvg: React.FunctionComponent = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 12C7 11.448 6.55225 11 6 11H2C1.4475 11 1 11.448 1 12C1 12.5522 1.44775 13 2 13H6C6.55225 13 7 12.5522 7 12ZM22 11H18C17.4478 11 17 11.448 17 12C17 12.5522 17.4478 13 18 13H22C22.5522 13 23 12.5522 23 12C23 11.448 22.5522 11 22 11ZM12 1C11.448 1 11 1.44775 11 2V6C11 6.552 11.448 7 12 7C12.5522 7 13 6.552 13 6V2C13 1.44775 12.5522 1 12 1ZM12 17C11.448 17 11 17.4478 11 18V22C11 22.5522 11.448 23 12 23C12.5522 23 13 22.5522 13 22V18C13 17.4478 12.5522 17 12 17ZM7.05 15.5358L4.22225 18.3638C3.83175 18.7542 3.83175 19.3878 4.22225 19.7782C4.6125 20.1687 5.24575 20.1687 5.63625 19.7782L8.4645 16.9498C8.855 16.5595 8.855 15.9263 8.4645 15.5358C8.074 15.1453 7.44075 15.145 7.05 15.5358ZM16.95 8.4645L19.778 5.6365C20.1685 5.246 20.1685 4.61275 19.778 4.22225C19.3878 3.83175 18.7545 3.83175 18.3645 4.22225L15.5358 7.05025C15.1453 7.44075 15.1453 8.074 15.5358 8.4645C15.9263 8.855 16.559 8.855 16.95 8.4645ZM5.636 4.22175C5.24575 3.83125 4.6125 3.83125 4.222 4.22175C3.8315 4.61225 3.8315 5.2455 4.222 5.636L7.05 8.4645C7.4405 8.855 8.074 8.855 8.4645 8.4645C8.855 8.074 8.855 7.44075 8.4645 7.05025L5.636 4.22175ZM16.95 15.5358C16.5595 15.1453 15.926 15.1453 15.5358 15.5358C15.1453 15.9263 15.1453 16.5593 15.5358 16.9498L18.364 19.7782C18.7545 20.1687 19.3875 20.1687 19.7778 19.7782C20.168 19.3878 20.168 18.7542 19.7778 18.3638L16.95 15.5358Z"
      fill="#BDBDBD"
    />
  </svg>
);
