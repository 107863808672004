import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { IsImpersonating, getLocalURI, isExternalUrl } from '@share/utils';
import { SkeletonItems } from '@components';
import { RootState } from '@share/utils';
import { ILoginState } from '@share/store/slices';

import CarsLogo from '@assets/images/cars-logo.jpg';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {}

export class CarsListComponent extends React.Component<IProps> {

  renderContent = (item: any) => {
    const account = this.props.loginStore?.account;
    const backgroundColorPreference: any = account? account?.buttonColor : null;
    const backgroundColor = !isEmpty(backgroundColorPreference)? backgroundColorPreference : 'rgb(46, 45, 235)';  

    return (
      <>
        <div className="cars-program-list__image-wrapper single">
          <img src={item.imageUrl} alt='car rentals' />
        </div>
        <div className="cars-program-list__info">
          <p className="cars-program-list__info-title">{item.title}</p>
          <p className="cars-program-list__info-content">
            <FormattedMessage id={item.description} />
          </p>
          <p className="cars-program-list__info-content">
            <FormattedMessage id={item.description2} />
          </p>
          <p className="cars-program-list__info-content" style={{ marginTop: '20px' }}>
            <FormattedMessage id={item.description3} />
          </p>
          <p className="cars-program-list__info-content">
            <FormattedMessage id={item.description4} />
          </p>
          <p className="cars-program-list__info-content">
            <FormattedMessage id={item.description5} />
          </p>

          {item.errorMessage ? (
            <p className="cars-program-list__info-content centered error">
              <FormattedMessage id={item.errorMessage} />
            </p>) : null}

          <div className="cars-program-list__info-button-container">
            <div className="cars-program-list__info-button" style={{ backgroundColor }}>
              {account?.isCapitalVacationsAgent ? <FormattedMessage id="check.out.cars" /> : 'Call: 844-777-2582'}
            </div>
          </div>
        </div>
      </>
    );
  }

  render(): React.ReactNode {
    const { loginStore } = this.props;
    const { account, user } = loginStore;

    const luxuryCruises = {
      url: (account?.isCapitalVacationsAgent && IsImpersonating()) ? `https://www.carrentalpro.com/sso/RSICAPITAL?membertoken=${user?.keyid}` : null,
      imageUrl: CarsLogo,
      title: 'We promise to exceed your expectations at every turn with each car rental',
      description: 'our.promise.2',
      description2: 'to.assist.you',
      description3: 'service.hours',
      description4: 'service.hours.2',
      description5: 'service.hours.3',
      errorMessage: (account?.isCapitalVacationsAgent && !IsImpersonating()) ? 'please.impersonate' : null,
    };
    const cruisesToDisplay = [luxuryCruises];
    const linkClassNames = `cars-program-list__item ${cruisesToDisplay.length === 1 ? 'single' : ''}`;

    return (
      <div className="cars-program-list">
        <h1 className="cars-program-list__header secondary-font">
          Enjoy Exciting Vacations
        </h1>
        {cruisesToDisplay?.length ? (
          <div className="cars-program-list__items-wrapper">
            {cruisesToDisplay?.map(
              (item, index) =>
                isExternalUrl(item.url as string) ? (
                  <a
                    href={item.url as string}
                    target="_blank"
                    rel="noreferrer"
                    key={`${index}${item.title}`}
                    className={linkClassNames}
                  >
                    {this.renderContent(item)}
                  </a>
                ) : (
                  <Link
                    to={`/${account?.name}${getLocalURI(item.url as string)}`}
                    key={`${index}${item.title}`}
                    className={linkClassNames}
                  >
                    {this.renderContent(item)}
                  </Link>
                ),
            )}
          </div>
        ) : (
          <SkeletonItems singleBlock={true} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

export const CarsList = connect(mapStateToProps)(CarsListComponent);
