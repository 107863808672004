import React from 'react';

import { connect } from 'react-redux';

import { IReservation } from '@share/common-types';
import { ReservationStatus, ReservationRefundability, ReservationCategory } from '@components';

import './style.scss';
import { isEmpty } from 'lodash';

interface IProps {
  reservation: IReservation;

  displayStatus?: boolean;
}

class ReservationInformationComponent extends React.Component<IProps> {
  
  render(): React.ReactNode {
    const { reservation, displayStatus } = this.props;
    const { propertyName, propertyCity, bookingType, checkIn, checkOut, leadName, bookingStatusEnum, refundable } = reservation;

    return (
      <div className="reservation-information">
        <div className="reservation-information__item">
          <strong>Description:</strong><label>{propertyName}</label>
        </div>
        {!isEmpty(propertyCity) ? (
          <div className="reservation-information__item">
            <strong>City:</strong><label>{propertyCity}</label>
          </div>) : null}
        <div className="reservation-information__item">
          <strong>Start date:</strong><label>{checkIn}</label>
        </div>
        <div className="reservation-information__item">
          <strong>End date:</strong><label>{checkOut}</label>
        </div>
        <div className="reservation-information__item">
          <strong>Lead Name:</strong><label>{leadName}</label>
        </div>
        <div className="reservation-information__item">
          <strong>Category:</strong><label><ReservationCategory category={bookingType} /></label>
        </div>
        {displayStatus ? (
          <div className="reservation-information__item">
            <strong>Status:</strong><label><ReservationStatus status={bookingStatusEnum} /></label>
          </div>) : null}
      </div>);
  }
}

export const ReservationInformation = connect()(ReservationInformationComponent);
