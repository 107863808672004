import React from 'react';

import Select from 'antd/lib/select';

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { ICarsDatesState, ILoginState } from '@share/store/slices';
import { RootState } from '@share/utils';

import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IMapStateToProps {
  carsDatesStore: ICarsDatesState;
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {
  timeSelected: string;
  type: string;

  setTimeSelected: (time: string) => void;
}

class CarsTimePickerComponent extends React.Component<IProps> {

  render(): React.ReactNode {
    const { carsDatesStore, loginStore, timeSelected, type } = this.props;
    const { timesDropOff, timesPickUp } = carsDatesStore;
    const { account } =  loginStore;

    const color: any = account?.searchIconsColor;
    const generalBorderRadius: any = account?.generalBorderRadius ? account?.generalBorderRadius : null;

    const style = !isEmpty(generalBorderRadius) ? { borderRadius: generalBorderRadius } : {};  
    const styleColor = !isEmpty(color)? { color } : {};
    
    const isPickup = type !== 'dropOff';
    const times = isPickup ? timesPickUp : timesDropOff;

    return (
      <div className="date-picker__wrapper cars-time disable-error" style={style}>
        <div className="date-picker__left">
          <div className="date-picker__icon" style={styleColor}>
            <FontAwesomeIcon icon={faClock} />
          </div>
        </div>
        <div className="date-picker__right">
          <div className="date-picker__label">
              <FormattedMessage id={isPickup ? 'cars.pickup' : 'cars.dropoff'} />
          </div>
          <div className="date-picker__header">
            <Select
              showSearch={true}
              placeholder={'Time'}
              value={timeSelected}
              onChange={this.props.setTimeSelected}
            >
              {times.map(t => (<Select.Option key={`${t}_${type}`} value={t}>{t}</Select.Option>))}
            </Select>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    carsDatesStore: state.carsDatesStore,
    loginStore: state.loginStore,
  };
};

export const CarsTimePicker = connect(mapStateToProps)(CarsTimePickerComponent);
