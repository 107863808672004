import { IWebinar } from '@common-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { axiosInstance } from '@share/utils';
import { AppThunk } from '@share/utils';

export interface IWebinarsState {
  webinars: IWebinar[];
  loading: boolean;
  error: string;
}

const initialState: IWebinarsState = {
  webinars: null,
  loading: false,
  error: '',
};

const webinarsSlice = createSlice({
  name: 'webinars',
  initialState,
  reducers: {
    setLoading: (state: IWebinarsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: IWebinarsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setWebinars: (state: IWebinarsState, { payload }: PayloadAction<IWebinar[]>) => {
      state.webinars = payload;
    },
  },
});

export const webinarsActions = webinarsSlice.actions;

export const webinarsReducer = webinarsSlice.reducer;

export const getWebinars = (): AppThunk => {
  return async (dispatch) => {
    dispatch(webinarsActions.setLoading(true));

    try {
      const response = await axiosInstance.get('https://resources.membergetaways.com/wp-json/wp/v2/posts?_embed&categories=12&per_page=100');

      dispatch(webinarsActions.setWebinars(response.data));
      dispatch(webinarsActions.setLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(webinarsActions.setError(error.toString()));
      dispatch(webinarsActions.setLoading(false));
    }
  };
};
