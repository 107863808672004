import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { BlueButton } from '@share/components';

import ExpiredSessionImgage from '@assets/images/expired_session.png';

import './style.scss';

interface IProps extends WrappedComponentProps, RouteComponentProps {
  visible: boolean;
  hasRedirect?: boolean;
  onHandleRedirect?: () => void;
}

interface IState {
  visible: boolean;
}
const modalWidth = 400;

class ExpiredSessionModalComponent extends React.Component<IProps, IState> {

  state: IState = { visible: this.props.visible };

  render(): React.ReactNode {
    const { hasRedirect, onHandleRedirect } = this.props;
    const { visible } = this.state;

    return (
      <div className="expired-session-modal">
        <Modal
          className="expired-session-modal-wrapper"
          wrapClassName="expired-session-modal-wrapper__wrap"
          visible={visible}
          footer={null}
          title={null}
          closable={false}
          keyboard={false}
          destroyOnClose={true}
          width={modalWidth}
        >
          <div className="session-expired-container">
            <img src={ExpiredSessionImgage} alt="" />
            <div className="session-expired-container__info">
              <p className="session-expired-container__title">
                <FormattedMessage id="session.has.been.expired" />
              </p>
              
              <p className="session-expired-container__text">
                <FormattedMessage id="session.expired.text.left" />{' '}
                <FormattedMessage id="session.refresh.page" />
                <FormattedMessage id="session.expired.text.right" />
              </p>

              {!hasRedirect ? (
                <BlueButton className="session-expired-container__refresh" onClick={() => window.location.reload()}>
                  <FormattedMessage id="session.refresh.page" />
                </BlueButton>) : (
                <BlueButton className="session-expired-container__refresh" onClick={onHandleRedirect}>
                  <FormattedMessage id="session.go_back.login.page" />
                </BlueButton>)}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export const ExpiredSessionModal = connect()(injectIntl(withRouter(ExpiredSessionModalComponent)));
