import { IInsuranceComplete } from '@common-types';
import { IFilterField, IGeoLocation } from '@share/common-types';

export const NO_AVAILABLE_VALUE = ' ';

export enum ReservationStatusEnum {
  NotAvailable = ' ',
  Confirmed = 'Confirmed',
  Cancelled = 'Cancelled',
  Pending = 'Pending',
  Unconfirmed = 'Unconfirmed',
  Error = 'Error',
  Deleted = 'Deleted',
  SoldOut = 'SoldOut',
  ToCheck = 'ToCheck',
  ToCancel = 'ToCancel',
  InProgress = 'InProgress',
  Prebooking = 'Prebooking',
}

export interface ITextSearch {
  value: string;
  matchedBy: string;
}

export interface IReservation {
  bookingId: string;
  sessionId: string;
  propertyId: number;
  propertyName: string;
  propertyCity: string;
  supplierConfirmation: string;
  customerLocator: string;
  createdAt: string;
  checkIn: string;
  checkOut: string; 
  refundable: boolean;
  cancellationPolicesText: string;

  leadName: string;

  passengerEmail: string;
  bookingStatusEnum: ReservationStatusEnum;

  bookingType: string;

  titleAddress: string;	
	subtitleAddress: string;
  numberOfNights: number;
  numberGuest: number;
  roomsCount: number;
  phone: string;
  email: string;
  location: IGeoLocation;
  rooms: IRoomInformation[];
  priceDetail: IPriceDetail;

  insurance: IInsuranceComplete;
}

export interface IReservationsParams {
  bookingId: number;
  propertyName: string;
  leadName: string;
  bookingType: string;
  
  status?: string;
  
  page: number;	
  pageSize: number;	
  results?: number;

  orderBy?: string;
  sortOrder?: string;
}

export interface IReservationsFilters {
  bookingId: IFilterField<number>;
  propertyName: IFilterField<string>;
  leadName: IFilterField<string>;
  bookingType: IFilterField<string>;
}

export interface IRoomInformation {
  count: number;
  name: string;
  basis: string;
  type: string;
  guests: IReservationGuest[];
}

export interface IReservationGuest {
  residency: string;
  phone: string;
  postalCode: string;
  givenName: string;
  surName: string;
  email: string;
  countryCode: string;
  stateCode: string;
  isMainGuest: boolean;
  addressLine:string;
  city: string;
}

export interface IPriceDetail {
  averageNightPrice: string;
  priceTitle: string;
  price: string;
  priceDifference: string;
  memberSavings: string;
  couponAmount: string;
  totalPaid: string;
  includingTaxes: string;
  cashbackEarned: string;
  payAtPropertyFees: ITotalFee[];

  totalCurrency: string;
  totalValue: number;
}

export interface ITotalFee {
  title: string;
  value: number | null;
  formattedValue: string;
}

export interface IStatic {
  imageUrl: string;
  countryCode: string;
  countryName: string;
  mandatory: string;
  optionalFeesMessage: string;
}