
import { FormattedMessage } from 'react-intl';
import {  Tooltip } from 'antd';

import { InfoGraySvg } from '@assets';

import './style.scss';

interface IProps {
  accountName: string;
}

function DisclaimerComponent(props: IProps) {
  const message = <FormattedMessage id="detail.disclaimer" values={{accountName: props.accountName}} />  
  return (
    <div className="disclaimer">
      <Tooltip title={message} placement="topLeft" overlayStyle={{maxWidth: '500px'}}>
        <span>
          <div className="disclaimer__title secondary-font">
              <InfoGraySvg />
              <span className='disclaimer__message'>
                <FormattedMessage id="detail.disclaimer.title" />
              </span>
          </div>
        </span>
      </Tooltip>
    </div>

  );
}

export const Disclaimer = DisclaimerComponent;
