import React from 'react';
import moment from 'moment';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { get, isEmpty } from 'lodash';

import { InfoColorSvg } from '@assets';
import { DATE_COMPLET_FORMAT_FULL_DATA } from '@constants';
import { Routes } from '@share/constants';
import { BlueButton } from '@share/components';
import { CallbackFunction, GetHomeParams, RootState } from '@share/utils';
import { ICarsDetailsState, ICarsReviewBookState } from '@store/slices';
import { ILoginState, IMenuState, ResetCarsFull } from '@share/store/slices';
import { getAccountUsernameFromPath } from '@share/utils';
import { setLoginRedirect } from '@share/store/slices';
import { voucherBooking, downloadVoucher } from '@share/store/slices';
import { VoucherModal } from '@components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import { CancellationPolicies } from '../cars-cancellation-policies';
import { CarsInfo } from '../cars-info';
import { CarsLocations } from '../cars-location';

import Logo from '@assets/images/cars_confirm.png';

import './style.scss';

interface IMapStateToProps {
  menuStore: IMenuState;
  loginStore: ILoginState;

  carsReviewBookStore: ICarsReviewBookState;
  carsDetailsStore: ICarsDetailsState;
}

interface IMapDispatchToProps {
  resetCarsFull: () => void;
  setLoginRedirect: (redirect: string) => void;
  voucherBooking: (bookingId: string, recepientEmail: string, additionalEmail: string, callback: CallbackFunction) => void;
  downloadVoucher: (bookingId: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {}

interface IState {
  showVoucherModal: boolean;
  emailResend: string;
  emailAdditional: string;
  isAdditionalEmailAdded: boolean;
}

class CarsBookingConfirmedComponent extends React.Component<IProps, IState> {
  state: IState = { showVoucherModal: false, emailAdditional: '', emailResend: '', isAdditionalEmailAdded: false };

  updateMenu = (): void => {
    const { loginStore, history } = this.props;
    const { account } = loginStore;

    if (account?.isDisableHomes) {
      if (!isEmpty(account?.disableHomesRedirect)) {
        window.location.replace(account?.disableHomesRedirect);
      } else {
        history.push(Routes.NotAuthorized);
      }
    } else {
      this.props.resetCarsFull();
  
      const accountName = getAccountUsernameFromPath(this.props.history);
  
      const homeParams = GetHomeParams(account);

      this.props.history.push(`/${accountName}${Routes.CarsSearch}${homeParams}`);
    }
  };

  hangleGoToLogin = () => {
    this.hangleGo(Routes.Login);
  }

  hangleGoToSignUp = () => {
    this.hangleGo(Routes.SignUp);
  }

  hangleGo = (route: Routes) => {
    const { loginStore, carsReviewBookStore, history } = this.props;
    const { account } = loginStore;
    const { booking } = carsReviewBookStore;

    const { pathname, search } = history.location;

    const bookingGuid = get(booking, 'id');

    this.props.setLoginRedirect(`${pathname}${isEmpty(search) ? '' : search}`);

    this.props.history.push(`/${account.name}${route}/${bookingGuid}`);
  }

  handleVoucherBooking = (recepientEmail: string, additionalEmail: string) => {
    const { carsReviewBookStore } = this.props;
    const { booking } = carsReviewBookStore;
    const bookingId = get(booking, 'id');

    this.props.voucherBooking(bookingId, recepientEmail, additionalEmail, () => this.setState({ showVoucherModal: false }));
  }

  handleDownloadVoucher = () => {
    const { carsReviewBookStore, downloadVoucher } = this.props;
    const { booking } = carsReviewBookStore;

    const bookingId = get(booking, 'id');

    downloadVoucher(bookingId);
  }

  render(): React.ReactNode {
    const { carsReviewBookStore, carsDetailsStore, loginStore } = this.props;
    const { showVoucherModal, emailResend, emailAdditional, isAdditionalEmailAdded } = this.state;
    const { account, userWallet } = loginStore;
    const { bookingComplete, booking, driver } = carsReviewBookStore;
    const { car } = carsDetailsStore;

    const email = driver?.email;
    const givenName = driver?.givenName;

    const startDate = car?.pickUpLocationDetail?.dateTime;

    const currency = car?.currency;
    const balance = car?.price;
    const vendorName = car?.vendor?.value;

    const isLogged = !!userWallet;

    const hasClientCash = account.hasClientCash;
    const clientCashPercentage = account.clientCashPercentage;
    const disableDownloadVoucherCheckout = account.disableDownloadVoucherCheckout;

    if (!bookingComplete) {
      return null;
    }

    const usedClientCash = booking ? get(booking, 'clientCash', 0) : 0;
    const clientCash = usedClientCash;
    const finalBalanceIntermediate = balance - clientCash;
    const finalBalanceCalculated = Math.floor(finalBalanceIntermediate * 100) / 100;
    const finalBalance = finalBalanceCalculated > 0 ? finalBalanceCalculated : 0;
    const eligibleClientCash = finalBalance > 0 ? finalBalance * (!isEmpty(clientCashPercentage) ? parseInt(clientCashPercentage) : 0) / 100 : 0;

    const confirmationCode = get(booking, 'reservation.voucher[0].identifier', '');
    //const voucherLink = get(booking, 'reservation.voucher[0].link');
    const warningMessage = get(booking, 'warningMessage');
    const memberId = get(booking, 'memberId');
    const customerServiceNumber = get(booking, 'customerServiceNumber');
    const phone = get(booking, 'pickUpLocationDetail.telephone[0].phoneNumber');
    
    return (
      booking && (
        <div className="booking-confirmed car-booking-confirmed">
          <div className="success-logo-container">
            <div className='col-md-3'>
              <img src={Logo} alt="confirm_image" style={{ width: '100%' }} />
            </div>
            <div className='col-md-8' style={{padding: '8px'}}>
              <p className='title-name'><FormattedMessage id="thank.you" />, {givenName}!</p>
              <p className='title-confirmed'>
                <FormattedMessage id="cars.reservation.confirmed" values={{ value: vendorName }} />.
              </p>
              <p className='title-email'><FormattedMessage id="email.sent" values={{ email: email }} /></p>
            </div>
          </div>

          {!isEmpty(warningMessage) ? (
            <div className="booking-hotel-reservation__warning">
              <InfoColorSvg /> {warningMessage}
            </div>) : null}
          
          <p className="booking-confirmed__description">
            <span className="booking-confirmed__description_item">{vendorName} </span>
            <FormattedMessage id="is.expecting.you.on" />{' '}
            <span className="booking-confirmed__description_item">
              {moment(startDate,'yyyy-MM-DDThh:mm:ss').format(DATE_COMPLET_FORMAT_FULL_DATA)}
            </span>
          </p>

          <p className="booking-confirmed__number-info">
            <FormattedMessage id="confirmation.number" />:{' '}
            <span className="booking-confirmed__number">
              {confirmationCode}
            </span>
          </p>

          <p className="booking-confirmed__instructional-text">
            <InfoColorSvg />
            <FormattedMessage id="cars.you.may.be.asked" />
          </p>

          <p className="booking-confirmed__instructional-text">
            <InfoColorSvg />
            <FormattedMessage id="you.may.need.this.number" />
          </p>
          
          <div className="cars-details-wrapper__price-container" style={{ marginBottom: '15px', marginTop: '15px' }}>
            <CarsInfo car={car} isBookingCompleted />
          </div>

          <div className="cars-details-wrapper__price-container" style={{ marginBottom: '0px' }}>
            <CarsLocations car={car} isCheckout />
          </div>

          {memberId ? (
            <p className="booking-confirmed__number-info">
              <FormattedMessage id="member.ID" />:{' '}
              <span className="booking-confirmed__number">{memberId}</span>
            </p>) : null}

          <CancellationPolicies />

          <p className="booking-confirmed__contact-property">
            <FormattedMessage id="cars.contact.car_rental" />:{' '}
            <span className="booking-confirmed__contact-property-phone">
              {phone}
            </span>
          </p>
          
          <p className="booking-confirmed__contact-property">
            <FormattedMessage id="support" />:{' '}
            <span className="booking-confirmed__contact-property-phone">
              {customerServiceNumber}
            </span>
          </p>

          <p className="condo-booking-confirmed__contact-property" style={{ display: 'flex' }}>
            <FormattedMessage id="confirmation.resend.email" />{' '}
            <span className="condo-booking-confirmed__contact-property-phone" style={{ marginLeft: '5px' }}>
              <div style={{ cursor: 'pointer', textDecoration: 'underline', color: '#40a9ff'}} onClick={() => this.setState({ showVoucherModal: true })}>
                <FormattedMessage id="confirmation.resend.email.resend" />
              </div>
            </span>
          </p>

          <VoucherModal
            visible={showVoucherModal}
            loading={carsReviewBookStore?.loading || loginStore?.loading}
            onCancel={() => this.setState({ showVoucherModal: false })}
            onVoucher={this.handleVoucherBooking}
            email={emailResend}
            emailAdditional={emailAdditional}
            isAdditionalEmailAdded={isAdditionalEmailAdded}
            setEmailModal={(emailResend) => this.setState({ emailResend })}
            setEmailAdditionalModal={(emailAdditional) => this.setState({ emailAdditional })}
            setIsAdditionalEmailAddedModal={(isAdditionalEmailAdded) => this.setState({ isAdditionalEmailAdded })}
          />

          {(hasClientCash && !isLogged && !usedClientCash) && (
            <div className="booking-confirmed__client-cash-login">
              <p className="booking-confirmed__contact-property" style={{ marginTop: '0px', fontSize: '15px', paddingTop: '0px' }}>
                <FontAwesomeIcon icon={faTriangleExclamation} />{' '}<FormattedMessage id="wallet.client.not_logged.thanks" values={{ clientCashPercentage, currency: currency, reward: eligibleClientCash.toFixed(2), clientCashName: account?.walletClientCashName }} />
              </p>
              <p className="booking-confirmed__contact-property" style={{ fontSize: '18px', marginTop: '10px', textAlign: 'right' }}>
                <BlueButton onClick={this.hangleGoToLogin}><FormattedMessage id="sign.in" /></BlueButton> <FormattedMessage id="or" /> <BlueButton onClick={this.hangleGoToSignUp}><FormattedMessage id="sign.up" /></BlueButton>
              </p>
            </div>)}
          
          <div className="booking-confirmed__btn-wrapper" style={{ justifyContent: (!disableDownloadVoucherCheckout ? 'space-between' : 'flex-end') }}>
            {!disableDownloadVoucherCheckout ? (
              <BlueButton onClick={this.handleDownloadVoucher}>
                <FormattedMessage id="download.voucher" />
              </BlueButton>) : null}
            <BlueButton onClick={this.updateMenu}>
              <FormattedMessage id="confirmation.go.back_home" />
            </BlueButton>
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
    carsReviewBookStore: state.carsReviewBookStore,
    carsDetailsStore: state.carsDetailsStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = { 
  resetCarsFull: ResetCarsFull,
  setLoginRedirect,
  voucherBooking,
  downloadVoucher
};

export const CarsBookingConfirmed = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CarsBookingConfirmedComponent));
