import React, { Component } from 'react';

import axios from 'axios';

import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { Cluster, Renderer } from '@googlemaps/markerclusterer';

import { Map } from '@utils';
import { Responsive } from '@share/utils';
import { Loading, LoadingSise } from '@share/components';

import { CondoCard } from './condo-card';

import GeneralError from '@assets/images/general-error.png';
import CondoMarker from '@assets/images/condo-marker.png';
import CondoCluster from '@assets/images/condo-cluster.png';

import './style.scss';

class ClusterRenderer implements Renderer {
  render(cluster: Cluster): google.maps.Marker {
    return new google.maps.Marker({
      position: cluster.position,
      icon: {
        url: CondoCluster,
      },
      label: {
        text: String(cluster.count),
        color: 'rgba(0, 0, 0)',
        fontSize: '12px',
      },
      title: `Cluster of ${cluster.count} Units`,
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + cluster.count,
    });
  }
}

const zoom = 4;

const condoMarketIcon = {
  url: CondoMarker,
};

export interface ICondoUnitMap {
  ID: number;
  ImageURL: string;
  Latitude: number;
  Longitude: number;
  Resort: string;
  ResortID: string;
  StateCode: string;
  UnitsAvailable: number;
  address: string;
  city: string;
  country: string;
}

interface IProps {
  counterOnParent?: boolean;
}

interface IState {
  selectedCondo: ICondoUnitMap;
  selectedMarker: google.maps.Marker;
  condos: ICondoUnitMap[];
  visibleCondos: ICondoUnitMap[];
  loading: boolean;
  errorMessage: string;
  totalUnits: number;
  totalWeeks: number;
}

export class CondoWorldMap extends Component<IProps, IState> {

  mapRef: React.RefObject<HTMLDivElement> = React.createRef();
  map: google.maps.Map;

  state: IState = { selectedCondo: null, condos: null, visibleCondos: null, selectedMarker: null, errorMessage: null, loading: true, totalUnits: null, totalWeeks: null };

  componentDidMount(): void {
    const center = { lat: 0, lng:0 };

    const params = new URLSearchParams()
    params.append('Username', import.meta.env.VITE_CONDO_MAP_USERNAME);
    params.append('Password', import.meta.env.VITE_CONDO_MAP_PASSWORD);
    params.append('grant_type', import.meta.env.VITE_CONDO_MAP_GRANT_TYPE);

    const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };

    this.map = Map.initializeMap(this.mapRef.current, center, { 
      disableDefaultUI: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoomControl: true,
      restriction: {
        latLngBounds: {north: 89, south: -89, west: -180, east: 180},
        strictBounds: true
      }
    }, 2);

    this.map.addListener('zoom_changed', this.onMapChange);
    this.map.addListener('center_changed', this.onMapChange);

    axios.post(import.meta.env.VITE_CONDO_MAP_TOKEN_URL, params, config)
      .then((result) => {
        const token = result?.data?.access_token;
        if (!isEmpty(token)) {
          const config = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization': `Bearer ${token}`
            }
          }
      
          axios.get(import.meta.env.VITE_CONDO_MAP_GET_LIST_URL, config)
            .then((result) => {
              const condos = result?.data;
              this.setState({
                loading: false,
                condos,
                visibleCondos: condos,
                totalUnits: condos?.length || 0,
                totalWeeks: condos?.map((condo: any) => condo.UnitsAvailable).reduce((p: number, c: number) => p + c, 0) || 0,
                selectedCondo: null,
                selectedMarker: null,
                errorMessage: null
              }, () => {

                if (this.mapRef && this.mapRef.current) {
            
                  this.setMarkers();

                  this.map.addListener('click', () => {
                    if (this.state.selectedCondo) {
                      this.setState({ selectedCondo: null, selectedMarker: null, errorMessage: null });
                    }
                  });
                }
              });
            }).catch((err) => {
              this.setState({ loading: false, selectedCondo: null, selectedMarker: null, errorMessage: err.toString() });
            });
        }
      }).catch((err) => {
        this.setState({ loading: false, selectedCondo: null, selectedMarker: null, errorMessage: err.toString() });
      });
  }

  setMarkers = (): void => {
    const { visibleCondos, selectedCondo } = this.state;

    Map.removeAllMarkers();
    Map.addMarkersParallel(this.map, visibleCondos.map(condo => ({
      location: {
        latitude: condo.Latitude,
        longitude: condo.Longitude      
      },
      label: {
        text: String(condo.UnitsAvailable),
        color: '#006579',
        fontSize: '12px',
      },
      id: condo.ID
    })), condoMarketIcon, null, selectedCondo?.ID, this.selectCondo);
    Map.addCluster(this.map, new ClusterRenderer());
  };

  selectCondo = (condosId: number, selectedMarker?: google.maps.Marker): void => {
    const { condos = [] } = this.state;
    const selectedCondo = condos.find((condo: ICondoUnitMap) => condo.ID === condosId);

    this.setState({ selectedMarker });

    if (selectedCondo) {
      this.setState({ selectedCondo });

      const offSetFromBottom = 50;
      const divHeightOfTheMap = this.mapRef.current.clientHeight;
      const position: google.maps.LatLngLiteral = {
        lat: selectedMarker.getPosition().lat(),
        lng: selectedMarker.getPosition().lng(),
      };
      const currentZoom = this.map.getZoom();

      this.map.panTo(position);

      if (currentZoom < zoom) {
        this.map.setZoom(zoom);
      }

      this.map.panBy(0, - (divHeightOfTheMap / 2 - offSetFromBottom));
    }
  };

  onMapChange = () => {
    const bounds = this.map.getBounds();
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();
    const mapBounds = {
      northEast: {
        longitude: ne.lng(),
        latitude: ne.lat(),
      },
      southWest: {
        longitude: sw.lng(),
        latitude: sw.lat(),
      },
    };
    
    const currentZoom = this.map.getZoom();
    const visibleCondos = this.state.condos.filter(({ Latitude, Longitude }) => Latitude >= mapBounds.southWest.latitude && Latitude <= mapBounds.northEast.latitude && Longitude >= mapBounds.southWest.longitude && Longitude <= mapBounds.northEast.longitude);
    this.setState({ visibleCondos: !visibleCondos?.length && currentZoom <= 2 ? this.state.condos : visibleCondos }, () => this.setMarkers());
  }

  render(): React.ReactNode {
    const { counterOnParent } = this.props;
    const { loading, selectedCondo, condos, errorMessage, totalUnits, totalWeeks } = this.state;

    const styleCounter: any = counterOnParent ? { position: 'absolute' } : {};

    return (
      <div className="condo-world-map">
        {loading ? (
          <div className="loading-container">
            <Loading size={LoadingSise.Medium} />
          </div>) : null}

        {!isEmpty(errorMessage) ? (
          <div className="condo-world-map__error">
            <img className="error-page__image" src={GeneralError} />
            <label className="error-page__title"><FormattedMessage id="error.custom.error.title" /></label>
            <label className="error-page__message"><FormattedMessage id="error.custom.error.retry" /></label>
            <label className="error-page__message-retry"><FormattedMessage id="error.custom.error.contact_support" /></label>
          </div>
        ) : (
          <>
            <div className="condo-world-map__map">
              <div className="condo-world-map__map-instance" ref={this.mapRef} />

              {condos?.length ? (
                <div className="condo-world-map__map-counters" style={styleCounter}>
                  <label><FormattedMessage id="condos.world.map.total_units_available" />: <strong>{totalUnits}</strong></label>
                  <label><FormattedMessage id="condos.world.map.total_weeks_available" />: <strong>{totalWeeks}</strong></label>
                </div>) : null}
            </div>

            {selectedCondo && (
              <div className="condo-world-map__condo-detail">
                <Responsive>
                  <CondoCard condo={selectedCondo} />
                </Responsive>
              </div>
            )}
          </>)}
      </div>
    );
  }
}
