import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import AgentImgLG from '@assets/images/concierge-team-large.png';
import AgentImgSM from '@assets/images/concierge-team-smaller.png';
import './style.scss';

export class ConciergeWelcome extends Component {
  render(): React.ReactNode {
    return (
      <div className="welcome-wrapper">
        <div className="welcome-wrapper__wrapper">
          <div className="welcome-wrapper__text-wrapper">
            <h2 className="welcome-wrapper__heading secondary-font">
              <FormattedMessage id="concierge.welcome.heading" />
            </h2>
            <p className="welcome-wrapper__paragraph">
              <FormattedMessage id="concierge.welcome.paragraph" />
            </p>
          </div>
          <picture className="welcome-wrapper__image">
            <source media="(min-width: 670px)" srcSet={AgentImgLG} />
            <source media="(max-width: 670px)" srcSet={AgentImgSM} />
            <img src={AgentImgLG} alt="A picture of a female travel agent" />
          </picture>
        </div>
      </div>
    );
  }
}
