import React, { useEffect, useRef } from 'react';

import { FunctionalRootState, scrollTop } from '@share/utils';

import './style.scss';
import ArrowDownBanner from '@assets/images/mlm-levalti/new-agents/arrow-down-banner.svg';
import QuoteConciergeImg from '@assets/images/mlm-levalti/exclusive-access/quote-img.jpg';
import GetawaysConcierge from '@assets/images/mlm-levalti/exclusive-access/getaways-concierge.jpg';
import GetawaysHotels from '@assets/images/mlm-levalti/exclusive-access/getaways-hotels.jpg';
import DuetChart from '@assets/images/mlm-levalti/exclusive-access/duet-chart.jpg';
import HotelLogo from '@assets/images/mlm-levalti/exclusive-access/hotel-logo-bar.jpg';
import LineImg from '@assets/images/mlm-levalti/exclusive-access/line.png';
import QuoteImg from '@assets/images/mlm-levalti/exclusive-access/quote.png';
import GetawayCruiseImg from '@assets/images/mlm-levalti/exclusive-access/getaways-cruise-liner.jpg';
import DuetCruiseImg from '@assets/images/mlm-levalti/exclusive-access/duet-cruise.jpg';
import CruiseLogoBar from '@assets/images/mlm-levalti/exclusive-access/cruise-logo-bar.jpg';
import DuetCondos from '@assets/images/mlm-levalti/exclusive-access/duet-condos.jpg';
import GetawaysVillas from '@assets/images/mlm-levalti/exclusive-access/getaways-villas.jpg';
import GetawayResort from '@assets/images/mlm-levalti/exclusive-access/getaways-resorts.jpg';
import ResortImg from '@assets/images/mlm-levalti/exclusive-access/resort-img.jpg';
import ResortLogobBar from '@assets/images/mlm-levalti/exclusive-access/resort-logo-bar.jpg';
import GetawayShopping from '@assets/images/mlm-levalti/exclusive-access/getaways-shopping.jpg';
import EatShopping from '@assets/images/mlm-levalti/exclusive-access/image-asset.jpg';
import ShoppingBrands from '@assets/images/mlm-levalti/exclusive-access/shopping-brands.jpg';
import CertifiedImg from '@assets/images/mlm-levalti/exclusive-access/certified.png';
import { FormattedMessage } from 'react-intl';
import { ILoginState } from '@share/store/slices';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function MLMExclusiveAccess() {
  useEffect(() => {
    scrollTop();
  }, []);

  const startedSectionRef = useRef<HTMLDivElement>(null);
  //Smooth Scroll Click
  const scrollToSection = () => {
    if (startedSectionRef.current) {
      startedSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const { account }: ILoginState = useSelector((state: FunctionalRootState) => state.loginStore);

  return (
    <div className="exclusive">
      {/*Header*/}
      <header className="exclusive__header">
        <section className="exclusive__header-title">
          <h1 className="exclusive__header-title-main">
            <span className="exclusive__header-title-main-subtitle1">
              <FormattedMessage id="exclusive.welcome1" />
            </span>{' '}
            <span className="exclusive__header-title-main-subtitle2">
              <FormattedMessage id="exclusive.welcome2" />
            </span>
          </h1>
        </section>
        <section className="exclusive__header-arrow">
          <img
            src={ArrowDownBanner}
            alt="arrow down"
            onClick={scrollToSection}
            className="move-down-on-hover"
          />
        </section>
      </header>
      <section className="exclusive__main-section" ref={startedSectionRef}>
        {/*First Section*/}
        <section className="exclusive__main-section-guaranteed">
          <section className="exclusive__main-section-guaranteed-first-section">
            <div className="exclusive__main-section-guaranteed-first-section-hotel-img">
              <img src={GetawaysHotels} alt="GetawaysHotels Img" />
            </div>
            <div className="exclusive__main-section-guaranteed-first-section-text">
              <h2 className="exclusive__main-section-guaranteed-first-section-text-title">
                <FormattedMessage id="exclusive.guaranteed.title" />
              </h2>
              <p className="exclusive__main-section-guaranteed-first-section-text-paragraph">
                <FormattedMessage id="exclusive.guaranteed.paragraph" />
              </p>
            </div>
            <div className="exclusive__main-section-guaranteed-first-section-pay-img">
              <img src={DuetChart} alt="GetawaysHotels Img" />
            </div>
          </section>
          <section className="exclusive__main-section-guaranteed-brands">
            <img src={HotelLogo} alt="Brands Img" />
          </section>
          <section className="exclusive__main-section-guaranteed-quote">
            <div className="exclusive__main-section-guaranteed-quote-separator">
              <img src={QuoteImg} alt="Quote Img" />
            </div>
            <p className="exclusive__main-section-guaranteed-quote-text">
              <FormattedMessage id="exclusive.guaranteed.paragraph2" />
            </p>
            <div className="exclusive__main-section-guaranteed-quote-separator">
              <img src={LineImg} alt="Line Img" />
            </div>
          </section>
        </section>
        {/*Second Section*/}
        <section className="exclusive__main-section-concierge">
          <section className="exclusive__main-section-concierge-container">
            <section className="exclusive__main-section-concierge-container-img">
              <img src={GetawaysConcierge} alt="Getaways Concierge Img" />
            </section>
            <section className="exclusive__main-section-concierge-container-text">
              <h2 className="exclusive__main-section-concierge-container-text-title">
                <FormattedMessage id="exclusive.concierge.title" />
              </h2>
              <p className="exclusive__main-section-concierge-container-text-paragraph">
                <FormattedMessage id="exclusive.concierge.paragraph" />
              </p>
              <p className="exclusive__main-section-concierge-container-text-paragraph">
                <FormattedMessage id="exclusive.concierge.paragraph2" />
              </p>
            </section>
            <section className="exclusive__main-section-concierge-container-quote">
              <img
                src={QuoteConciergeImg}
                alt="Quote Concierge Img"
                className="exclusive__main-section-concierge-container-quote-img"
              />
              <div className="exclusive__main-section-concierge-container-quote-paragraph">
                <p className="exclusive__main-section-concierge-container-quote-paragraph-quote">
                  <FormattedMessage id="exclusive.concierge.quote" />
                </p>
                <p className="exclusive__main-section-concierge-container-quote-paragraph-author">
                  {' '}
                  <FormattedMessage id="exclusive.concierge.author" />
                </p>
              </div>
            </section>
          </section>
        </section>
        {/*Third Section*/}
        <section className="exclusive__main-section-cruise">
          <section className="exclusive__main-section-cruise-container">
            <section className="exclusive__main-section-cruise-container-img">
              <img src={GetawayCruiseImg} alt="Getaways cruise Img" />
            </section>
            <section className="exclusive__main-section-cruise-container-text">
              <h2 className="exclusive__main-section-cruise-container-text-title">
                <FormattedMessage id="exclusive.cruise.title" />
              </h2>
              <p className="exclusive__main-section-cruise-container-text-paragraph">
                <FormattedMessage id="exclusive.cruise.paragraph" />
              </p>
            </section>
            <section className="exclusive__main-section-cruise-container-price">
              <img
                src={DuetCruiseImg}
                alt="Duet Cruise Img"
                className="exclusive__main-section-cruise-container-price-img"
              />
            </section>
            <section className="exclusive__main-section-cruise-container-bar">
              <img
                src={CruiseLogoBar}
                alt="Cruise Logo Bar"
                className="exclusive__main-section-cruise-container-bar-img"
              />
            </section>
          </section>
        </section>
        {/*Four Section*/}
        <section className="exclusive__main-section-villas">
          <section className="exclusive__main-section-villas-container">
            <section className="exclusive__main-section-villas-container-img">
              <img src={GetawaysVillas} alt="Getaways Villas Img" />
            </section>
            <section className="exclusive__main-section-villas-container-text">
              <h2 className="exclusive__main-section-villas-container-text-title">
                <FormattedMessage id="exclusive.villas.title" />
              </h2>
              <p className="exclusive__main-section-villas-container-text-paragraph">
                <FormattedMessage id="exclusive.villas.paragraph" />
              </p>
              <p className="exclusive__main-section-villas-container-text-paragraph">
                <FormattedMessage id="exclusive.villas.paragraph2" />
              </p>
            </section>
            <section className="exclusive__main-section-villas-container-rate">
              <img
                src={DuetCondos}
                alt="Quote Condos Img"
                className="exclusive__main-section-villas-container-rate-img"
              />
            </section>
          </section>
        </section>
        {/*Fifth Section*/}
        <section className="exclusive__main-section-resort">
          <section className="exclusive__main-section-resort-container">
            <section className="exclusive__main-section-resort-container-img">
              <img src={GetawayResort} alt="Getaways resort Img" />
            </section>
            <section className="exclusive__main-section-resort-container-text">
              <h2 className="exclusive__main-section-resort-container-text-title">
                <FormattedMessage id="exclusive.resort.title" />
              </h2>
              <p className="exclusive__main-section-resort-container-text-paragraph">
                <FormattedMessage id="exclusive.resort.paragraph" />
              </p>
            </section>
            <section className="exclusive__main-section-resort-container-quote">
              <img
                src={ResortImg}
                alt="quote Resort Img"
                className="exclusive__main-section-resort-container-quote-img"
              />
              <div className="exclusive__main-section-resort-container-quote-paragraph">
                <p className="exclusive__main-section-resort-container-quote-paragraph-quote">
                  <FormattedMessage id="exclusive.resort.quote" />
                </p>
                <p className="exclusive__main-section-resort-container-quote-paragraph-author">
                  {' '}
                  <FormattedMessage id="exclusive.resort.author" />
                </p>
              </div>
            </section>
            <section className="exclusive__main-section-resort-container-bar">
              <img
                src={ResortLogobBar}
                alt="Resort Logo Bar"
                className="exclusive__main-section-resort-container-bar-img"
              />
            </section>
          </section>
        </section>
        {/*Seventh Section*/}
        <section className="exclusive__main-section-deals">
          <section className="exclusive__main-section-deals-container">
            <section className="exclusive__main-section-deals-container-img">
              <img src={GetawayShopping} alt="Getaways deals Img" />
            </section>
            <section className="exclusive__main-section-deals-container-text">
              <h2 className="exclusive__main-section-deals-container-text-title">
                <FormattedMessage id="exclusive.deals.title" />
              </h2>
              <p className="exclusive__main-section-deals-container-text-paragraph">
                <FormattedMessage id="exclusive.deals.paragraph" />
              </p>
            </section>
            <section className="exclusive__main-section-deals-container-quote">
              <img
                src={EatShopping}
                alt="Eat deals Img"
                className="exclusive__main-section-deals-container-quote-img"
              />
              <div className="exclusive__main-section-deals-container-quote-paragraph">
                <p className="exclusive__main-section-deals-container-quote-paragraph-quote">
                  <FormattedMessage id="exclusive.deals.quote" />
                </p>
                <p className="exclusive__main-section-deals-container-quote-paragraph-author">
                  {' '}
                  <FormattedMessage id="exclusive.deals.author" />
                </p>
              </div>
            </section>
            <section className="exclusive__main-section-deals-container-bar">
              <img
                src={ShoppingBrands}
                alt="Brands Logo Bar"
                className="exclusive__main-section-deals-container-bar-img"
              />
            </section>
          </section>
        </section>
        <section className="exclusive__main-section-started">
          <section className="exclusive__main-section-started-container">
            <div className="exclusive__main-section-started-container-img">
              <img src={CertifiedImg} alt="Certified Img" />
            </div>
            <div className="exclusive__main-section-started-container-content">
              <h2>
                <FormattedMessage id="exclusive.started.title" />
              </h2>
              <p>
                <FormattedMessage id="exclusive.started.subtitle" />
              </p>
              <Link to={`/${account?.name}/become-agent`}>
                <button>
                  <FormattedMessage id="exclusive.started.btn" />
                </button>
              </Link>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}
