import React from 'react';
import debounce from 'lodash/debounce';

import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { ILoginState } from '@share/store/slices';
import { ICarsDetailsState, ICarsReviewBookState } from '@store/slices';
import { RootState } from '@share/utils';
import { IAnchor } from '@common-types';

import './style.scss';

interface IMapStateToProps {
  carsDetailsStore: ICarsDetailsState;
  carsReviewBookStore: ICarsReviewBookState;

  loginStore: ILoginState;
}

interface IParams {
  id: string;
}

interface IProps extends IMapStateToProps, RouteComponentProps<IParams> {
  overviewRef: React.RefObject<HTMLDivElement>;
  paymentRef: React.RefObject<HTMLDivElement>;
  driverRef: React.RefObject<HTMLDivElement>;
  emailConfirmationRef: React.RefObject<HTMLDivElement>;
  reserveRef: React.RefObject<HTMLDivElement>;
  locationRef: React.RefObject<HTMLDivElement>;
  policiesRef: React.RefObject<HTMLDivElement>;
  extrasRef: React.RefObject<HTMLDivElement>;

  isCheckout: boolean;

}

interface IState {
  active: number;
  itemsAnchor: IAnchor[];
}

const ZERO = 0;
const DEFAULT_ANCHOR = 0;
const DEBOUNCE_TIME = 100;

const TAB_OVERVIEW = 'tab.overview';
const TAB_DRIVER = 'tab.driver';
const TAB_PAYMENT = 'tab.payment';
const TAB_RESERVE = 'tab.reserve';
const TAB_EMAIL = 'tab.email_confirmation';
const TAB_LOCATION = 'tab.locations';
const TAB_POLICIES = 'tab.policies';
const TAB_EXTRAS = 'tab.extras';

const TAB_EXCLUDE_CHECKOUT = [TAB_EXTRAS, TAB_POLICIES, TAB_LOCATION];
const TAB_EXCLUDE_DETAIL = [TAB_PAYMENT, TAB_DRIVER, TAB_EMAIL, TAB_RESERVE];

class CarsDetailsAnchorsComponent extends React.Component<IProps, IState> {
  state: IState = {
    active: DEFAULT_ANCHOR,
    itemsAnchor: []
  };

  scrollView = (e: React.MouseEvent<HTMLParagraphElement>, ref: React.RefObject<HTMLElement>) => {
    this.setState({ active: +e.currentTarget.dataset.index });
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'end' });
  };

  getAnchors = (): IAnchor[] => {
    const { isCheckout } = this.props;
    const { itemsAnchor } = this.state;
    const { car } = this.props.carsDetailsStore;

    const extras = car?.pricedEquips;
    const hasExtras = !!extras?.length;

    return itemsAnchor
                  .filter(a => (isCheckout && !TAB_EXCLUDE_CHECKOUT.includes(a.id)) || (!isCheckout && !TAB_EXCLUDE_DETAIL.includes(a.id)))
                  .filter((item) => !([TAB_EXTRAS].includes(item.id) && !hasExtras));
  };

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);

    this.handleSetAnchors();
  }

  componentDidUpdate(): void {
    const { itemsAnchor } = this.state;

    if (!itemsAnchor?.length && this.props.overviewRef) {
      this.handleSetAnchors();
    }
  }
  
  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }
  
  handleSetAnchors = () => {
    this.setState({ itemsAnchor: [
      { id: TAB_OVERVIEW, ref: this.props.overviewRef },
      //{ id: TAB_PRICE, ref: this.props.priceDetailsRef, isMobile: true },
      { id: TAB_LOCATION, ref: this.props.locationRef },
      { id: TAB_DRIVER, ref: this.props.driverRef },
      { id: TAB_PAYMENT, ref: this.props.paymentRef },
      { id: TAB_EMAIL, ref: this.props.emailConfirmationRef },
      { id: TAB_RESERVE, ref: this.props.reserveRef },
      { id: TAB_POLICIES, ref: this.props.policiesRef },
      { id: TAB_EXTRAS, ref: this.props.extrasRef },
    ]});    
  }

  getActiveAnchor = (): IAnchor => {
    const anchors: IAnchor[] = this.getAnchors();
    const activeRef: IAnchor = anchors[this.state.active];
    return activeRef;
  };

  onScroll = debounce(() => {
    const { car, loading } = this.props.carsDetailsStore;

    if (car && !loading) {
      const anchor = this.getActiveAnchor();

      if (anchor && anchor.id) {
        const index = this.getAnchors().findIndex(({ id }) => id === anchor.id);

        if (index >= ZERO && this.state.active !== index) {
          this.setState({ active: index });
        }
      }
    }
  }, DEBOUNCE_TIME);

  render(): React.ReactNode {
    const { carsDetailsStore, carsReviewBookStore } = this.props;
    const { car } = carsDetailsStore;
    const { bookingComplete } = carsReviewBookStore;

    if (!car || bookingComplete) {
      return null;
    }

    return (
      <div className="hotel-details-wrapper__details-anchor">  
        {this.getAnchors().map((itemAnchor, i) => (
          <p
            key={i}
            className={`hotel-details-wrapper__tab-anchors ${i === this.state.active ? 'active' : ''} ${itemAnchor.isMobile ? 'is-mobile' : ''}`}
            onClick={(e) => this.scrollView(e, itemAnchor.ref)}
            data-index={i}
          >
            <FormattedMessage id={itemAnchor.id} />
          </p>
        ))}
      </div>);
  };
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    carsDetailsStore: state.carsDetailsStore,
    loginStore: state.loginStore,
    carsReviewBookStore: state.carsReviewBookStore,
  };
};

export const CarsDetailsAnchors = connect(mapStateToProps)(withRouter(CarsDetailsAnchorsComponent));

