import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  Membership,
  ConciergeTeam,
  Banner,
  AboutUs,
  SearchBenefits,
  Contact,
  CompanyLogos,
  CondoWeeksWrapper,
} from '@components';
import { IWeekState, weeksActions, weeksLocationsActions } from '@share/store/slices';
import { ILoginState } from '@share/store/slices';
import { Responsive } from '@share/utils';
import { getAccountUsernameFromPath, getTimeout, RootState, scrollTop, UrlUtils } from '@share/utils';
import { BackgroundColor } from '@constants';
import { HotelsCompanyLogos } from '@common-types';
import { ISessionKey, PRIMARY_BANNER_TYPE, SECONDARY_BANNER_TYPE } from '@share/common-types';
import { CARS_SESSION_KEY_LABEL, Routes } from '@share/constants';
import { WeeksSearch } from '@share/components';

import { WeeksRecentSearches } from '../weeks-recent-searches';
import { WeeksResultWrapper } from '../weeks-results-wrapper';

import './style.scss';

interface IMapStateToProps {
  weeksStore: IWeekState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setDefaultValues: () => void;
  resetLocations: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps { }

const ZERO = 0;

function WeeksResultsPageComponent(props: IProps) {

  const { weeksStore, loginStore, history, setDefaultValues, resetLocations } = props;
  const { weeks, loading } = weeksStore;
  const { user, account } = loginStore;

  useEffect(() => {
    const values = UrlUtils.getValues();
    const session: ISessionKey = values[CARS_SESSION_KEY_LABEL] as ISessionKey;
    const accountName = getAccountUsernameFromPath(history);

    if (session && session.expireDate && getTimeout(session.expireDate) === ZERO) {
      setDefaultValues();
    }

    if (isEmpty(values)) {
      setDefaultValues();
      resetLocations();
      history.push(`/${accountName}${Routes.WeeksSearch}${location.search}`);
    }

    scrollTop();
  }, []);

  const hasDisplayContactSection = account?.hasDisplayContactSection;
  const isRSITemplate = account?.isRSITemplate;
  const hasDisplayTrustedBrandsSection = account?.hasDisplayTrustedBrandsSection;

  return (
    <div className="cars-page">
      <WeeksSearch />

      {(!weeks && !loading) ? (
        <>
          {!!user ? (<WeeksRecentSearches />) : null}

          {isRSITemplate ? (
            <ConciergeTeam
              backgroundColor={BackgroundColor.light}
              isImageSizeSmaller={true}
            />) : null}

          {!isRSITemplate ? (
            <Banner type={PRIMARY_BANNER_TYPE} />) : null}

          {(!isRSITemplate && hasDisplayContactSection) ? (
            <Contact />) : null}

          {hasDisplayTrustedBrandsSection ? (<CompanyLogos logos={HotelsCompanyLogos} />) : null}

          {!isRSITemplate ? (
            <SearchBenefits />) : null}

          {!isRSITemplate ? (
            <AboutUs />) : null}

          {!isRSITemplate ? (
            <Banner type={SECONDARY_BANNER_TYPE} />) : null}

          {isRSITemplate ? (
            <Membership />) : null}

          <CondoWeeksWrapper isWeeks />
        </>) : (
        <Responsive>
          <WeeksResultWrapper />
        </Responsive>)}
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    weeksStore: state.weeksStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setDefaultValues: weeksActions.setDefaultValues,
  resetLocations: weeksLocationsActions.resetWeeksLocations,
};

export const WeeksResultsPage = connect(mapStateToProps, mapDispatchToProps)(withRouter(WeeksResultsPageComponent));
