import React from 'react';

export const ManDarkSvg: React.FunctionComponent = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.00002 6.99967C8.28868 6.99967 9.33335 5.95501 9.33335 4.66634C9.33335 3.37768 8.28868 2.33301 7.00002 2.33301C5.71136 2.33301 4.66669 3.37768 4.66669 4.66634C4.66669 5.95501 5.71136 6.99967 7.00002 6.99967Z"
      fill="#20274D"
    />
    <path
      d="M2.91669 11.6663V10.3052C2.91669 9.58325 3.20349 8.89084 3.71401 8.38033C4.22452 7.86981 4.91693 7.58301 5.63891 7.58301H8.36113C9.08311 7.58301 9.77552 7.86981 10.286 8.38033C10.7965 8.89084 11.0834 9.58325 11.0834 10.3052V11.6663"
      fill="#20274D"
    />
  </svg>
);
