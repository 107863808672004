
import { IMenuItem } from '@common-types';
import { faAngleDown, faAngleUp, faTrashAlt, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import './style.scss';

interface IState {
  expand: boolean;
}

interface IProps {
  items: IMenuItem[];
  parent?: IMenuItem;
  level?: number;
  expand: boolean;
  onAction: (items: IMenuItem[]) => void;
}

export class MenuItem extends React.Component<IProps, IState> {
  
  state: IState = { expand: false };

  handleMove = (currentIndex: number, toIndex: number) => {
    let childItems = [...this.props.items];
    const element = childItems[currentIndex];
    childItems.splice(currentIndex, 1);
    childItems.splice(toIndex, 0, element);
    if (this.props.parent) {
      const parent = { ...this.props.parent, childItems };
      childItems = [parent];
    }
    this.props.onAction(childItems);  
  }

  handleDelete = (selectedIndex: number) => {
    let childItems = this.props.items.filter((item, index) => index !== selectedIndex);
    if (this.props.parent) {
      const parent = { ...this.props.parent, childItems };
      childItems = [parent];
    }
    this.props.onAction(childItems);  
  }

  handleExpand = () => {
    this.setState({ expand: !this.state.expand });
  }

  handleAction = (parent: IMenuItem[]) => {
    let childItems = this.props.items.map(item => parent.find(parentItem => parentItem.Title === item.Title) || item);
    if (this.props.parent) {
      const item = { ...this.props.parent, childItems };
      childItems = [item];  
    }
    this.props.onAction(childItems);  
  }

  render(): React.ReactNode {
    if (this.props.items?.length > 0 && this.props.expand) {
      const level = this.props.level ? this.props.level : 1;
      const classNameContent = `menu-item-content level${level}`;
      return (
        <div className="menu-item-container">
          {this.props.items.map((item, index) => {
            return (
              <div key={index} className={classNameContent}>
                <div className="menu-item">
                  <div className="menu-item-row">
                    <div className="col-4">
                      <span className="menu-item-plus">
                        {item.ChildItems?.length > 0 &&
                          <FontAwesomeIcon 
                            className="plus-action" 
                            icon={faPlusSquare}
                            size="2x"
                            onClick={() => this.handleExpand()} 
                          />}
                      </span>
                      <span>{item.Title}</span>
                    </div>
                    <div className="col-4">
                      <span>{item.Url}</span>
                    </div>
                    <div className="col-2">
                      <div className="menu-item-actions">
                        { (index < this.props.items.length - 1) && 
                          <FontAwesomeIcon 
                            className="down-action" 
                            icon={faAngleDown} 
                            size="2x"
                            onClick={() => this.handleMove(index, index + 1)} 
                          />}
                        { (index > 0) && 
                          <FontAwesomeIcon 
                            className="up-action" 
                            icon={faAngleUp} 
                            size="2x"
                            onClick={() => this.handleMove(index, index - 1)} 
                          />}
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="menu-item-actions">
                        <FontAwesomeIcon 
                          className="delete-action"
                          icon={faTrashAlt}
                          size="lg"
                          onClick={() => this.handleDelete(index)} />
                      </div>
                    </div>
                  </div>
                </div>
                <MenuItem
                  items={item.ChildItems}
                  expand={this.state.expand}
                  onAction={this.handleAction}
                  parent={item}
                  level={level + 1}
                />
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  }
}
