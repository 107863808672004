import React from 'react';

import { connect } from 'react-redux';
import { Responsive } from '@share/utils';

import { WeeksResultsPage } from '@components';

import './style.scss';

function WeeksSearchPageComponent() {
  return (
    <div className="weeks-results-page">
      <Responsive>
        <WeeksResultsPage />
      </Responsive>
    </div>
  );
}

export const WeeksSearchPage = connect()(WeeksSearchPageComponent);
