
import React from 'react';

import NotFound from '@assets/images/404_design.png';

import './style.scss';
import { FormattedMessage } from 'react-intl';

interface IProps {}

export class GenericNotFoundPage extends React.Component<IProps> {

  render(): React.ReactNode {
    return (
      <div className="generic-not-found-page">
          <img className="generic-not-found-page__image" src={NotFound} alt="" />
          <div className="generic-not-found-page__message"><FormattedMessage id="error.custom.error.page_not_available" /></div>
      </div>
    );
  }
}
