import React from 'react';

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { BlueButton } from '@share/components';
import { Routes } from '@share/constants';

import ConciergeTeamImageLarge from '@assets/images/concierge-team-large.png';

import './style.scss';
import { ILoginState } from '@share/store/slices';
import { connect } from 'react-redux';
import { RootState } from '@share/utils';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {}

class GetawayUniqueCollectionComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { loginStore } = this.props;
    const { account } = loginStore;
    return (
      <div className="getaway__unique">
        <div className="getaway__container getaway__unique-wrapper">
          <div className="getaway__unique-info">
            <p className="getaway__unique-info_title">
              <FormattedMessage id="getaway.unique.title" />
            </p>
            <p className="getaway__unique-info_text">
              <FormattedMessage id="getaway.unique.text" />
            </p>
          </div>
          <Link to={`/${account?.name}${Routes.ConciergeTeam}`} className="getaway__unique-link">
            <BlueButton>
              <FormattedMessage id="talk.to.our.team" />
            </BlueButton>
          </Link>

          <img className="getaway__unique-img" src={ConciergeTeamImageLarge} alt="Concierge team" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

export const GetawayUniqueCollection = connect(mapStateToProps)(GetawayUniqueCollectionComponent);
