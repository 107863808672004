
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Tooltip } from 'antd';
import { isEmpty } from 'lodash';

import { MOBILE_MEDIA_POINT } from '@share/constants';
import { RootState } from '@share/utils';
import { ILoginState, IMenuState, getUserSidekick } from '@share/store/slices';
import { Loading, LoadingSise } from '@share/components';
import { SearchTable } from '@components';
import { IColumnMenu, NULL_VALUE } from '@constants';
import { InputFilterType } from '@share/common-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
}

interface IMapDispatchToProps {
  getUserSidekick: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps { }

function MySideKickRewardsComponent(props: IProps) {
  const { menuStore, intl, getUserSidekick } = props;
  const { userSidekick, loading, items } = menuStore;

  const isMobile = document.body.offsetWidth <= MOBILE_MEDIA_POINT;
  
  const sidekickName = !isEmpty(items?.sideKickName) ? items?.sideKickName : 'Sidekick Rewards';
	const sidekickNameShort = !isEmpty(items?.sideKickShortName) ? items?.sideKickShortName : 'Sidekick';

  const columns: IColumnMenu[] = [
    { description: (items?.sideKickShortName && !isEmpty(items?.sideKickShortName)) ? items?.sideKickShortName : 'Sidekick Name', key: 'name', width: 140, display: true, order: 1, type: InputFilterType.Text, displayConditions: false },
    { description: 'Email', key: 'email', width: 160, display: true, order: 2, type: InputFilterType.Text, displayConditions: false },
    { description: 'Date Added', key: 'dateAdded', width: 160, incomingDateFormat: 'YYYY-MM-DDThh:mm:ss', display: true, order: 3, type: InputFilterType.DateTime, displayConditions: true },
    { description: 'Pending Travel', key: 'pendingTravel', width: 120, display: true, order: 4, type: InputFilterType.Currency, displayConditions: true },
    { description: 'Available Rewards', key: 'availableRewards', width: 120, display: true, order: 5, type: InputFilterType.Currency, displayConditions: true },
    { description: 'Status', key: 'status', width: 100, display: true, order: 6, type: InputFilterType.Text, displayConditions: false },
  ];

  if (loading) {
    return (
      <div className="my-sidekicks-wrapper" style={{ minHeight: '400px' }}>
        <div className="loading-container">
          <Loading size={LoadingSise.Medium} />
        </div>
      </div>);
  }

  if (!userSidekick) {
    return null;
  }

  const handleOnRefresh = (isClick?: boolean) => {
    if (isClick) {
      getUserSidekick();
    }
  }

  return (
    <div className={`my-sidekicks-wrapper ${isMobile ? 'mobile' : ''}`}>
      <div className="table-responsive">
        <div className="row rewards-container">
          <div className="col-lg-8">
            <h4 className="title secondary-font"><FormattedMessage id="sidekick.rewards.title" values={{ name: sidekickName }} /></h4>
            <p className="message"><FormattedMessage id="sidekick.rewards.message" values={{ name: sidekickName, strong: (message: string) => (<strong>{message}</strong>) }} /></p>
          </div>
          <div className="col-lg-4 rewards">
            <Tooltip title={<FormattedMessage id="my.sidekick.rewards.pending.message" values={{ name: sidekickNameShort }} />} placement="top"><FontAwesomeIcon icon={faInfoCircle} /></Tooltip> <strong><FormattedMessage id="my.sidekick.rewards.pending" />:</strong> <span> ${userSidekick?.pending?.toFixed(2)}</span> <br />
            <strong className="available"><FormattedMessage id="my.sidekick.rewards.available" />:</strong> ${userSidekick?.available?.toFixed(2)}
          </div>
        </div>

        {userSidekick?.sidekicks?.length ? (
          <SearchTable
            title={intl.formatMessage({ id: 'my.sidekick.rewards.history' }, { name: sidekickNameShort })}
            loading={loading}
            actions={NULL_VALUE}
            allColumns={[...columns]}
            dataSource={userSidekick.sidekicks}
            pagination={{
              showSizeChanger: false,
              showQuickJumper: false,
              hideOnSinglePage: true
            }}
            filters={NULL_VALUE}
            totalOrderCount={userSidekick.sidekicks.length}
            rowKey={(row: any) => row.key}
            isFilterApplied={false}
            onFiltersChange={NULL_VALUE}
            onTableChange={NULL_VALUE}
            onResetFilters={NULL_VALUE}
            onApplyFilters={NULL_VALUE}
            onRefresh={handleOnRefresh}
          ></SearchTable>) : (
          <div>
            <FormattedMessage id="my.sidekick.rewards.no_sidekicks" values={{ name: sidekickNameShort }} />
          </div>)}
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    menuStore: state.navigationMenuStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getUserSidekick,
};

const ComponentIntl = injectIntl(MySideKickRewardsComponent);

export const MySideKickRewards = connect(mapStateToProps, mapDispatchToProps)(withRouter(ComponentIntl));
