import React from 'react';
import { FormattedMessage, } from 'react-intl';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { Button, Form, Input, Alert } from 'antd';

import { GetHomeParams, RootState, getAccountUsernameFromPath } from '@share/utils';
import { ILoginState, login, logout } from '@share/store/slices';
import { LoginType } from '@share/utils';
import { Routes } from '@share/constants';
import { NULL_VALUE } from '@constants';
import { IAccount } from '@share/common-types';

import './style.scss';

interface IState {
  username: string;
  password: string;
  accountName: string;
}

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  login: (username: string, password: string, bookingId: string) => void;
  logout: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps { }

class SignInComponent extends React.Component<IProps, IState> {

  state: IState = { username: NULL_VALUE, password: NULL_VALUE, accountName: NULL_VALUE };

  componentDidMount() {
    const accountName = getAccountUsernameFromPath(this.props.history);
    this.setState({ accountName });
  }

  handleUsernameChange = (event: any) => {
    const username = event.target.value;
    this.setState({ username });
  }

  handlePasswordChange = (event: any) => {
    const password = event.target.value;
    this.setState({ password });
  }

  handleOnKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      this.handleLogin()
    }
  }

  handleLogin = () => {
    const { username, password } = this.state;
    if (!isEmpty(username) && !isEmpty(password)) {
      const params: any = this.props.match.params;
      this.props.login(username, password, params?.bookingID);
    }
  }

  onFinish = (values: any) => {
    this.setState({ username: values.email, password: values.password });
    this.handleLogin();
  };

  render(): React.ReactNode {
    const { loginStore } = this.props;
    const { account } = loginStore;
    const hasClientCash = account?.hasClientCash;

    const isPrivateWithLoginAccountType = account?.type === LoginType.PrivateWithLogin;

    const homeParams = GetHomeParams(account as IAccount);

    return (
      <div className="login-page row">
        <div className="login-box col-sm-5 col-xs-12">
          <Form
            name="login-form"
            className="login-form"
            layout="vertical"
            onFinish={this.onFinish}
          >
            <p className="form-title"><FormattedMessage id="login" /></p>

            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                { type: 'email', message: <FormattedMessage id="the.input.is.not.valid.email" /> },
                { required: true, message: <FormattedMessage id="please.input.your.email" /> },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="password"
              label={<FormattedMessage id="password" />}
              rules={[{ required: true, message: <FormattedMessage id="please.input.your.password" /> },
              ]}
            >
              <Input.Password />
            </Form.Item>

            {this.props.loginStore.error ?
              <Form.Item>
                <Alert message="" description={this.props.loginStore.error} type="error" showIcon />
              </Form.Item>: null}

            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button">
                <FormattedMessage id="login" />
              </Button>
            </Form.Item>

            {!isPrivateWithLoginAccountType ? (
              <Form.Item>
                <a className="login-form-forgot" href={`${this.state.accountName}${Routes.ForgotPassword}`}><FormattedMessage id="forgot.password" /></a>
              </Form.Item>) : null}
            
            {(hasClientCash && !isPrivateWithLoginAccountType) && (
              <Form.Item>
                <a className="login-form-forgot" href={`/${this.state.accountName}${Routes.Search}${homeParams}`}><FormattedMessage id="confirmation.go.back_home" /></a>
              </Form.Item>)}

            {!isPrivateWithLoginAccountType ? (
              <Form.Item>
                <FormattedMessage id="dont.have.account" /> <a className="login-form-forgot" href={`${this.state.accountName}${Routes.SignUp}`}><FormattedMessage id="sign.up" /></a>
              </Form.Item>) : null}
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  login,
  logout
};

const SignInComponentRouter = withRouter(SignInComponent);

export const SignIn = connect(mapStateToProps, mapDispatchToProps)(SignInComponentRouter);
