
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Routes } from '@share/constants';
import { BlueButton } from '@share/components';

import ConciergeTeamImageLarge from '@assets/images/concierge-team-large.jpg';

import { connect } from 'react-redux';
import { RootState } from '@share/utils';
import { ILoginState } from '@share/store/slices';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {}

function ConciergeServicesComponent(props: IProps) {
  const { loginStore } = props;
  const { account } = loginStore;

  return (
    <div className="concierge-services-rsi-upgrade">
      <div className="concierge-services-rsi-upgrade__wrapper">
        <div className="concierge-services-rsi-upgrade__img-wrapper" style={{ backgroundImage: `url(${ConciergeTeamImageLarge})`}}></div>

        <div className="concierge-services-rsi-upgrade__info-wrapper">
          <div className="concierge-services-rsi-upgrade__text-wrapper">
            <p className="concierge-services-rsi-upgrade__title">
              <FormattedMessage id="concierge.services.title" />
            </p>
            <p className="concierge-services-rsi-upgrade__description">
              <FormattedMessage id="you.are.now.member" />
            </p>
          </div>
          <Link to={`/${account?.name}${Routes.ConciergeTeam}`} className="concierge-services-rsi-upgrade__btn-wrapper">
            <BlueButton>
              <FormattedMessage id="talk.to.our.team" />
            </BlueButton>
          </Link>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

export const ConciergeServices = connect(mapStateToProps)(ConciergeServicesComponent);
