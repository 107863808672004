import React from 'react';
import { Select } from 'antd';
import { LabeledValue, RefSelectProps, SelectValue } from 'antd/lib/select';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { DEFAULT_MEDIA_POINT } from '@share/constants';
import { UNDEFINED_VALUE } from '@constants';
import { ILoginState } from '@share/store/slices';
import { RootState } from '@share/utils';

import { ChevronSvg } from '@share/assets';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {
  label?: React.ReactNode;
  options: LabeledValue[];
  onChange: (value: SelectValue) => void;
  value: number | string;
  placeHolder?: React.ReactNode;
  disabled?: boolean;
  closeOnScrollParentSelector?: string;
}

const defaultValue = 0;

export class CustomSelectComponent extends React.Component<IProps> {
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  instance: RefSelectProps = UNDEFINED_VALUE;

  isDesktop = (): boolean => {
    return window.matchMedia(`(min-width: ${DEFAULT_MEDIA_POINT}px)`).matches;
  };

  getPopupContainer = (): HTMLElement => {
    const { closeOnScrollParentSelector } = this.props;

    const element = this.wrapperRef && (!closeOnScrollParentSelector || !this.isDesktop())
      ? this.wrapperRef.current
      : document.body;
    return element as HTMLElement;
  };

  setInstance = (instance: RefSelectProps): void => {
    this.instance = instance;
  };

  setScrollHandler = (): void => {
    if (this.instance && this.isDesktop()) {
      this.instance?.blur();
    }
  };

  componentDidMount(): void {
    const { closeOnScrollParentSelector } = this.props;

    if (closeOnScrollParentSelector) {
      document
        .querySelector(closeOnScrollParentSelector)
        ?.addEventListener('scroll', this.setScrollHandler);
      window.addEventListener('scroll', this.setScrollHandler);
    }
  }

  componentWillUnmount(): void {
    const { closeOnScrollParentSelector } = this.props;

    if (closeOnScrollParentSelector) {
      document
        .querySelector(closeOnScrollParentSelector)
        ?.removeEventListener('scroll', this.setScrollHandler);
      window.removeEventListener('scroll', this.setScrollHandler);
    }
  }

  render(): React.ReactNode {
    const { label, value, onChange, options, placeHolder = '', disabled = false, loginStore } = this.props;
    const { account } = loginStore;

    const generalBorderRadius: any = account?.generalBorderRadius ? account?.generalBorderRadius : null;

    return (
      <div className="custom-select" ref={this.wrapperRef}>
        {label && <div className="custom-select__label">{label}</div>}
        <Select
          ref={this.setInstance}
          disabled={disabled}
          className={`custom-select__select ${!isEmpty(generalBorderRadius) ? `border-radius-${generalBorderRadius}` : ''}`}
          value={value || defaultValue}
          onChange={onChange}
          virtual={false}
          defaultValue={defaultValue}
          suffixIcon={ChevronSvg}
          getPopupContainer={this.getPopupContainer}
          placeholder={placeHolder}
          defaultActiveFirstOption={false}
        >
          {options.map(({ label, value }) => {
            return (
              <Select.Option value={value} key={value}>
                {label}
              </Select.Option>
            );
          })}
        </Select>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

export const CustomSelect = connect(mapStateToProps)(CustomSelectComponent);
