import { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchMemberStories } from '@store/slices';

import { TravelLeader } from '../travel-leader';
import { ListClickableCards } from '../list-clickable-cards';
import { TutorialVideos } from '../tutorial-videos';
import { MasterClasses } from '../master-classes';
import { Benefits } from '../benefits';
import { ConciergeServices } from '../concierge-services';
import { HeroVideo } from '../hero-video';
import { TreatYourself } from '../treat-yourself';
import { ExploreGetaways } from '../explore-getaways';

import './style.scss';

interface IMapDispatchToProps {
  getMemberStories: () => void;
}

interface IProps extends IMapDispatchToProps {}

function HomeRsiUpgradeComponent(props: IProps) {

  const { getMemberStories } = props;

  useEffect(() => {
    getMemberStories();
  }, []);

  return (
    <div className="home-rsi-wrapper">
      <HeroVideo />

      <TravelLeader />

      <ExploreGetaways />

      <Benefits />

      <ConciergeServices />

      <TutorialVideos />

      <ListClickableCards />

      <TreatYourself />

      <MasterClasses />
    </div>
  );
}

const mapDispatchToProps: IMapDispatchToProps = { getMemberStories: fetchMemberStories };

export const HomeRsiUpgrade = connect(null, mapDispatchToProps)(HomeRsiUpgradeComponent);
