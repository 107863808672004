import React, { ChangeEvent, RefObject } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { FormInstance } from 'antd/lib/form';
import { SelectValue } from 'antd/lib/select';
import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Checkbox, Form, Input, Select } from 'antd';
import { number } from 'card-validator';

import { RootState } from '@share/utils';
import {
  insuranceActions,
  InsuranceSelection,
  IWeeksReviewBookState,
  weeksReviewBookAction,
} from '@store/slices';
import { ICondosStaticState, ILoginState } from '@share/store/slices';
import {
  ICondoCardBooking, ICondoGuestBooking,
} from '@common-types';
import {
  IIntervalCountriesCodes,
  IRciCountriesCodes,
  IUserCard,
  SupplierType,
} from '@share/common-types';
import {
  checkForEmptyCharacter,
  checkInLatinLetters,
  creatingArrayWithNumber,
  hideNumbers,
  insertSpacesInNumber,
  onlyNumsRegExp,
  getOptionsYear,
} from '@share/utils';
import {
  setValidator,
  detectCardType,
  showCardNumber,
  getHiddenFields,
  updateWeeksGuest,
  getState,
  getCountryCode,
  getCountryByCode,
  getCountryIsoCode,
  getCondoAddressFromStorage,
  GetCreditCardImage,
} from '@utils';
import {
  isPostalCode,
  hideBrowserAutoFill,
} from '@utils';
import {
  AmexCardSvg,
  CheckMarkSvg,
  MaestroCardSvg,
  MasterCardSvg,
  VisaCardSvg,
} from '@assets';
import {
  SECOND_CHARACTER_POSITION,
  TENTH_CHARACTER_POSITION,
  WEEKS_BOOKING_SAME_ADDRESS_INFO_LABEL,
} from '@constants';
import {
  MONTHS,
} from '@share/constants';
import { CreditCardsList } from '@components';

import './style.scss';

interface IMapStateToProps {
  weeksReviewBookStore: IWeeksReviewBookState;

  condosStaticStore: ICondosStaticState;

  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setWeeksCard: (card: ICondoCardBooking) => void;
  setWeeksGuest: (guest: ICondoGuestBooking) => void;
  setErrorMessageExpirationDate: (value: boolean) => void;
  setErrorMessageCardNumber: (value: boolean) => void;
  setErrorMessageZipCode: (value: boolean) => void;
  setSelection: (selection: InsuranceSelection) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  formRef: RefObject<FormInstance>;
  isRequired: boolean;
  display: boolean;
}

interface IState {
  creditCard: string;
  codeCVV: string;
  expirationMonth: string;
  expirationYear: string;
  isAddressAsPrimary: boolean;
}

const ZERO = 0;
const THREE = 3;
const FOUR = 4;
const MAX_LENGTH = 100;
const MAX_LENGTH_INTERVALS = 25;
const MAX_LENGTH_CITY_FOR_INTERVALS = 15;
const MAX_POSTAL_CODE = 50;
const MIN_LENGTH_CARD_NUMBER = 13;
const MAX_LENGTH_CARD_NUMBER = 23;
const START_HIDE_NUMBER = 5;
const LENGTH_HIDE_NUMBER = 8;

class WeeksPaymentMethodComponent extends React.Component<IProps, IState> {
  state: IState = {
    creditCard: '',
    codeCVV: '',
    expirationMonth: undefined,
    expirationYear: undefined,
    isAddressAsPrimary: false,
  };

  onChangeCardName = (e: { target: HTMLInputElement }): void => {
    this.setWeeksCard({ holderName: e.target.value });
  };

  onChangeSave = (e: { target: CheckboxChangeEventTarget }): void => {
    this.setWeeksCard({ addPaymentMethod: e.target.checked });
  };

  onChangeCardNumber = (e: { target: HTMLInputElement }): void => {
    const { card } = this.props.weeksReviewBookStore;
    const { cardNumber } = card;
    const value = checkForEmptyCharacter(e.target.value);
    const numberCard = insertSpacesInNumber(value);

    if (this.props.weeksReviewBookStore.errorsField) {
      this.props.setErrorMessageCardNumber(false);
    }

    if (numberCard !== null) {
      this.setWeeksCard({ cardNumber: numberCard.join('').trim() });
      this.setState({ creditCard: numberCard.join(' ').trim() });
    } else if (value.length === ZERO) {
      this.setWeeksCard({ cardNumber: '' });
      this.setState({ creditCard: value });
    } else if (!Number(value)) {
      this.setWeeksCard({ cardNumber: cardNumber });
      this.setState({ creditCard: cardNumber });
    }
  };

  hideCardNumber = () => {
    const matches = creatingArrayWithNumber(LENGTH_HIDE_NUMBER, START_HIDE_NUMBER);
    const cardType = detectCardType(this.props.weeksReviewBookStore.card.cardNumber);

    this.setWeeksCard({ cardType: cardType });
    this.setState({ creditCard: hideNumbers(this.state.creditCard, matches) });
  };

  onFocusCardNumber = () => {
    this.setState({ creditCard: showCardNumber(this.props.weeksReviewBookStore) });
  };

  onChangeMonth = (value: SelectValue) => {
    this.setState({ expirationMonth: value.toString() as string }, () => {
      this.setExpireDate(this.state.expirationYear);
      this.props.formRef.current.setFieldsValue({ months: this.state.expirationMonth });
    });

    if (this.props.weeksReviewBookStore.errorsField) {
      this.props.setErrorMessageExpirationDate(false);
    }
  };

  onChangeYear = (value: string) => {
    this.setState({ expirationYear: value }, () => {
      this.setExpireDate(this.state.expirationMonth);
      this.props.formRef.current.setFieldsValue({ years: this.state.expirationYear });
    });

    if (this.props.weeksReviewBookStore.errorsField) {
      this.props.setErrorMessageExpirationDate(false);
    }
  };

  setExpireDate = (date: string) => {
    if (!date) {
      return;
    }

    this.setWeeksCard({ expireDate: `${this.state.expirationMonth}${this.state.expirationYear}` });
  };

  setWeeksCard = (data: any, excludeId?: boolean) => {
    const { card } = this.props.weeksReviewBookStore;

    const info = { ...data };
    if (!excludeId) {
      info.id = null;
    }

    this.props.setWeeksCard(
      updateWeeksGuest(card, info, false) as ICondoCardBooking,
    );
  }

  onChangeCVV = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;

    if (onlyNumsRegExp.test(value) || value === '') {
      const value = checkForEmptyCharacter(e.target.value);
      this.setWeeksCard({ cvv: value }, true);
      this.setState({ codeCVV: value });
    } else {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  hideCVVCode = () => {
    const { codeCVV } = this.state;
    this.setState({ codeCVV: getHiddenFields(codeCVV) });
  };

  showCVVCode = (): void => {
    const { card } = this.props.weeksReviewBookStore;

    if (!card.cvv) {
      return;
    }

    this.setState({ codeCVV: card.cvv });
  };

  onChangeAddressLine1 = (e: { target: HTMLInputElement }): void => {
    this.setWeeksCard({ addressLine1: e.target.value });
  };

  onChangeAddressLine2 = (e: { target: HTMLInputElement }): void => {
    this.setWeeksCard({ addressLine2: e.target.value });
  };

  onChangeCity = (e: { target: HTMLInputElement }): void => {
    this.setWeeksCard({ city: e.target.value });
  };

  onChangeCountry = (value: string): void => {
    const { card, weeksBookingSummary } = this.props.weeksReviewBookStore;
    const { condosCountriesCode } = this.props.condosStaticStore;
    const { supplierType } = weeksBookingSummary;

    const country = supplierType !== SupplierType.Intervals ? value : getCountryCode(value, supplierType, condosCountriesCode);
    const countryCode = getCountryIsoCode(value, supplierType, condosCountriesCode);
    
    this.setWeeksCard({
      country,
      countryCode,
      stateCode: !isEmpty(getState(value, condosCountriesCode, supplierType))
        ? card.stateCode
        : '',
      zipCode: isPostalCode(value, condosCountriesCode, supplierType) ? card.zipCode : '',
    });
    this.props.formRef.current.setFieldsValue({ country: value });
  };

  onChangePostalCode = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    const { card } = this.props.weeksReviewBookStore;

    if (this.props.weeksReviewBookStore.errorsField) {
      this.props.setErrorMessageZipCode(false);
    }

    if (onlyNumsRegExp.test(value) || value === '') {
      this.setWeeksCard({ zipCode: value });
    } else {
      e.preventDefault();
      e.stopPropagation();
      this.setWeeksCard({
        zipCode: card && card.zipCode ? card.zipCode : '',
      });
    }
  };

  onChangeStateInput = (e: { target: HTMLInputElement }): void => {
    this.onChangeState(e.target.value);
  };

  onChangeState = (value: SelectValue): void => {
    this.setWeeksCard({ stateCode: value as string });
    this.props.formRef.current.setFieldsValue({ state: value });
  };

  onChangeBillingAddressAsPrimary = (e: { target: CheckboxChangeEventTarget }) => {
    const { card, guest, weeksBookingSummary } = this.props.weeksReviewBookStore;
    const { condosCountriesCode } = this.props.condosStaticStore;
    const { supplierType } = weeksBookingSummary;

    const country = supplierType !== SupplierType.Intervals ? guest.country : getCountryCode(guest.country, supplierType, condosCountriesCode);
    const countryCode = getCountryIsoCode(e.target.checked ? guest.country : card.country, supplierType, condosCountriesCode);

    this.setWeeksCard({
      addressLine1: e.target.checked ? guest.addressLine1 : '',
      addressLine2: e.target.checked ? guest.addressLine2 : '',
      city: e.target.checked ? guest.city : '',
      country: e.target.checked ? country : '',
      stateCode: e.target.checked ? guest.stateCode : '',
      zipCode: e.target.checked ? guest.zipCode : '',
      countryCode
    });

    this.setState({ isAddressAsPrimary: e.target.checked });

    if (e.target.checked) {
      this.props.formRef.current.setFieldsValue({ addressLine1: guest.addressLine1 });
      this.props.formRef.current.setFieldsValue({ addressLine2: guest.addressLine2 });
      this.props.formRef.current.setFieldsValue({ city: guest.city });
      this.props.formRef.current.setFieldsValue({ country: guest.country });
      this.props.formRef.current.setFieldsValue({ state: guest.stateCode });
      this.props.formRef.current.setFieldsValue({ postalCode: guest.zipCode });
      localStorage.setItem(WEEKS_BOOKING_SAME_ADDRESS_INFO_LABEL, 'true');
    } else {
      localStorage.setItem(WEEKS_BOOKING_SAME_ADDRESS_INFO_LABEL, null);
    }
  };

  componentDidMount() {
    const { card } = this.props.weeksReviewBookStore;
    const defaultData: ICondoCardBooking = {
      addressLine1: '',
      addressLine2: '',
      cvv: '',
      cardNumber: '',
      cardType: null,
      city: '',
      country: '',
      stateCode: '',
      expireDate: '',
      holderName: '',
      phone: '',
      zipCode: '',
    };
    const isAddressAsPrimary = localStorage.getItem(WEEKS_BOOKING_SAME_ADDRESS_INFO_LABEL);
    this.setState({ isAddressAsPrimary: !!isAddressAsPrimary });

    const cardFromStorage = getCondoAddressFromStorage();
    const resultData = cardFromStorage ? { ...defaultData, ...cardFromStorage } : defaultData;

    this.props.setWeeksCard(updateWeeksGuest(card, resultData, false) as ICondoCardBooking);

    if (cardFromStorage) {
      setTimeout(() => {
        this.props.formRef?.current?.setFieldsValue({
          addressLine1: resultData.addressLine1,
          addressLine2: resultData.addressLine2,
          city: resultData.city,
          country: resultData.country,
          state: resultData.stateCode,
          postalCode: resultData.zipCode,
        });
      });
    }
  }

  handleCardSelect = async (selectedCard: IUserCard) => {
    const { weeksReviewBookStore, condosStaticStore } = this.props;
    const { guest, weeksBookingSummary } = weeksReviewBookStore;
    const { supplierType } = weeksBookingSummary;
    const { condosCountriesCode } = condosStaticStore;

    const isIntevarls = supplierType !== SupplierType.Intervals;

    const country = !isIntevarls ?
                      condosCountriesCode.rciCountries.find((item) => item.isoCode === selectedCard.country) :
                      condosCountriesCode.intervalCountries.find((item) => item.isoCode === selectedCard.country);

    const expirationMonth = selectedCard.expireDate?.substring(0, 2);
    const expirationYear = selectedCard.expireDate?.substring(selectedCard.expireDate?.length - 4);
                  
    this.setWeeksCard({
      id: selectedCard?.id,
      cardNumber: selectedCard.cardNumber,
      holderName: selectedCard.holderName,
      cardType: selectedCard.cardType,
      months: expirationMonth,
      years: expirationYear,
      cvv: null,
      addressLine1: selectedCard.addressLine,
      addressLine2: '',
      city: selectedCard.city,
      country: country?.name,
      stateCode: selectedCard.state,
      zipCode: selectedCard.zipCode,
      countryCode: country?.name,
    }, true);

    const countryCodeCard = getCountryIsoCode(country?.name, supplierType, condosCountriesCode);
    const countryCode = getCountryCode(country?.name, supplierType, condosCountriesCode);

    this.props.setWeeksGuest(
      updateWeeksGuest(guest, {
        addressLine1: selectedCard?.addressLine,
        addressLine2: '',
        city: selectedCard?.city,
        countryCode: countryCodeCard,
        country: supplierType !== SupplierType.Intervals ? country?.name : countryCode,
        stateCode: selectedCard.state,
        zipCode: selectedCard.zipCode,
      }) as ICondoGuestBooking
    );

    this.props.formRef?.current.setFieldsValue({ 
      holderName: selectedCard?.holderName,
      cardNumber: selectedCard?.cardNumber,
      code: null,
      months: expirationMonth,
      years: expirationYear,
      saveCard: false,

      addressLine1: selectedCard.addressLine,
      addressLine2: '',
      city: selectedCard?.city,
      country: country?.name,
      state: selectedCard?.state,
      postalCode: selectedCard?.zipCode,

      // Guest informatio
      primaryAddressLine1: selectedCard.addressLine,
      primaryAddressLine2: '',
      primaryCity: selectedCard.city,
      primaryCountry: country?.name,
      primaryState: selectedCard.state,
      primaryPostalCode: selectedCard.zipCode,
    });


    this.setState({ codeCVV: '', creditCard: selectedCard.cardNumber, expirationMonth, expirationYear });

    this.props.setSelection(null);
  }

  render(): React.ReactNode {
    const { Option } = Select;
    const { intl, isRequired, display, weeksReviewBookStore, condosStaticStore, loginStore } = this.props;
    const {
      card,
      errorsField,
      weeksBookingSummary,
      isErrorMessageExpirationDate,
      isErrorMessageCardNumber,
      isErrorMessageZipCode,
      loadingBooking
    } = weeksReviewBookStore;
    const { condosCountriesCode } = condosStaticStore;
    const { supplierType } = weeksBookingSummary;
    const { creditCard, codeCVV, expirationMonth, expirationYear, isAddressAsPrimary } = this.state;
    const { account } = loginStore;

    const cardNumber = (card?.cardNumber)? card?.cardNumber : null;
    const cardValidation = cardNumber ? number(cardNumber) : null;

    const countryName = !isEmpty(card?.country) ? getCountryByCode(card.country, supplierType, condosCountriesCode) : null;
    const countryStates = !isEmpty(card?.country) ? getState(countryName, condosCountriesCode, supplierType): null;

    const displayCard = account?.isPrivateLoginAccountType || account?.isPrivateTokenAccountType;

    const isSavedCard = !isEmpty(card?.id);
    const cardIsValid = isSavedCard ? 
                            true : 
                            !isEmpty(cardNumber) ? 
                                true :
                                false;
    const cardType = isSavedCard ? 
                          card?.cardType :
                          cardValidation ? 
                              cardValidation.card?.type :
                              null;

    const cardNumberValidators = [{
        required: isRequired,
        message: intl.formatMessage({ id: 'error.message.card.number' }),
      }
    ];

    if (!isSavedCard) {
      cardNumberValidators.push({
        min: MIN_LENGTH_CARD_NUMBER,
        message: intl.formatMessage({ id: 'error.message.card.number.long' }),
      } as any);
      cardNumberValidators.push({
        validator: (rule: any, value: string, callback: (msg?: string) => void) => {
          if (isEmpty(value)) {
            callback();
          } else {
            if (!number(value).isValid) {
              callback(intl.formatMessage({ id: 'error.message.card.number' }));
            } else {
              callback();
            }
          }
        }
      } as any);
      cardNumberValidators.push({
        validator: async () => {
          await setValidator(errorsField, 'card.cardNumber', isErrorMessageCardNumber);
        },
      } as any);
    };
       
    return (
      card && (
        <>
          <CreditCardsList
            selectedCardId={card?.id}
            onSelectCard={this.handleCardSelect}
            selectable
            hideIfNoData
            isCheckout
          />

          <div className={`condo-payment ${!display ? 'hide' : ''}`}>
            <div className="condo-payment__header">
              <h4 className="condo-payment__title secondary-font">
                <FormattedMessage id="payment.method" />
              </h4>
              <div className="condo-payment__description-wrapper">
                <CheckMarkSvg />
                <p className="condo-payment__description">
                  <FormattedMessage id="secure.transmission" />
                </p>
              </div>
              <div className="condo-payment__description-wrapper">
                <CheckMarkSvg />
                <p className="condo-payment__description">
                  <FormattedMessage id="personal.information" />
                </p>
              </div>
              <div className="condo-payment__cards">
                <MaestroCardSvg />
                <MasterCardSvg />
                <VisaCardSvg />
                <AmexCardSvg />
              </div>
            </div>
            <div className="condo-payment__rule">
              {(displayCard && !isSavedCard) ? (
                <Form.Item name="saveCard" >
                  <div className="payment-method__input-wrapper no-border no-heigth checkbox-save">
                    <div className="payment-method__input">
                      <Checkbox
                        checked={card.addPaymentMethod}
                        onChange={this.onChangeSave}
                        disabled={loadingBooking}
                      >
                        <FormattedMessage id="save.card" />
                      </Checkbox>
                    </div>
                  </div>
                </Form.Item>) : null}
              <Form.Item
                name="holderName"
                rules={[
                  {
                    required: isRequired,
                    message: intl.formatMessage({ id: 'error.message.name.card' }),
                  },
                  {
                    pattern: checkInLatinLetters,
                    message: intl.formatMessage({ id: 'error.message.name.card.latin.letters' }),
                  },
                ]}
              >
                <div className="condo-payment__input-wrapper">
                  <span className="condo-payment__input-label">
                    <FormattedMessage id="name.on.card" />
                  </span>
                  <div className="condo-payment__input">
                    <Input
                      placeholder={intl.formatMessage({ id: 'first.last.name' })}
                      value={card.holderName}
                      disabled={loadingBooking}
                      onChange={this.onChangeCardName}
                    />
                  </div>
                </div>
              </Form.Item>
              <Form.Item
                name="cardNumber"
                validateTrigger="onBlur"
                rules={cardNumberValidators}
              >
                <div className="condo-payment__input-wrapper">
                  <span className="condo-payment__input-label">
                    <FormattedMessage id="debit.credit.card" />
                  </span>
                  <div className="condo-payment__input">
                    <Input
                      placeholder={intl.formatMessage({ id: 'card.number' })}
                      maxLength={MAX_LENGTH_CARD_NUMBER}
                      value={creditCard}
                      disabled={loadingBooking}
                      onChange={this.onChangeCardNumber}
                      onBlur={this.hideCardNumber}
                      onFocus={this.onFocusCardNumber}
                    />
                    <div className="input-card">
                      {GetCreditCardImage(cardType, cardIsValid)}
                    </div>
                  </div>
                </div>
              </Form.Item>
              <div className={`condo-payment__cards-info ${isSavedCard ? 'saved-card' : ''}`}>
                <Form.Item
                  name="months"
                  rules={[
                    {
                      required: isRequired,
                      message: intl.formatMessage({ id: 'error.message.expiration.date' }),
                    },
                    {
                      validator: async () => {
                        await setValidator(
                          errorsField,
                          'card.expireDate',
                          isErrorMessageExpirationDate,
                        );
                      },
                    },
                  ]}
                >
                  <div className="condo-payment__input-wrapper">
                    <span className="condo-payment__input-label">
                      <FormattedMessage id="expiration.months" />
                    </span>
                    <div className="condo-payment__input">
                      <Select
                        placeholder={intl.formatMessage({ id: 'months' })}
                        value={expirationMonth}
                        onChange={this.onChangeMonth}
                        disabled={loadingBooking}
                        optionFilterProp="label"
                        showSearch={true}
                        options={MONTHS.map(month => ({
                          value: month.number,
                          label: month.number
                        }))}
                      >
                      </Select>
                    </div>
                  </div>
                </Form.Item>
                <div className={`condo-payment__cards-info-items ${isSavedCard ? 'saved-card' : ''}`}>
                  <Form.Item
                    name="years"
                    rules={[
                      {
                        required: isRequired,
                        message: intl.formatMessage({ id: 'error.message.expiration.date' }),
                      },
                      {
                        validator: async () => {
                          await setValidator(
                            errorsField,
                            'card.expireDate',
                            isErrorMessageExpirationDate,
                          );
                        },
                      },
                    ]}
                  >
                    <div className="condo-payment__input-wrapper">
                      <span className="condo-payment__input-label">
                        <FormattedMessage id="expiration.year" />
                      </span>
                      <div className="condo-payment__input">
                        <Select
                          placeholder={intl.formatMessage({ id: 'year' })}
                          value={expirationYear}
                          onChange={this.onChangeYear}
                          disabled={loadingBooking}
                          optionFilterProp="label"
                          showSearch={true}
                          options={getOptionsYear().map(year => ({
                            value: year,
                            label: year
                          }))}
                        >
                        </Select>
                      </div>
                    </div>
                  </Form.Item>
                  <Form.Item
                    name="code"
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: isRequired,
                        message: intl.formatMessage({ id: 'error.message.security.code' }),
                      },
                      {
                        min: THREE,
                        message: intl.formatMessage({ id: 'error.message.security.code.long' }),
                      },
                    ]}
                  >
                    <div className="condo-payment__input-wrapper">
                      <span className="condo-payment__input-label">
                        <FormattedMessage id="security.code" />
                      </span>
                      <div className="condo-payment__input">
                        <Input
                          maxLength={FOUR}
                          value={codeCVV}
                          onChange={this.onChangeCVV}
                          onBlur={this.hideCVVCode}
                          onFocus={this.showCVVCode}
                          disabled={loadingBooking}
                        />
                      </div>
                    </div>
                  </Form.Item>
                </div>
              </div>
              <div className="condo-payment__billing-address-wrapper">
                <p className="condo-payment__address-title">
                  <FormattedMessage id="billing.address" />:
                </p>
                <Checkbox
                  onChange={this.onChangeBillingAddressAsPrimary}
                  checked={isAddressAsPrimary}
                  disabled={loadingBooking}
                >
                  <FormattedMessage id="billing.address.same.primary" />
                </Checkbox>
                <Form.Item
                  name="addressLine1"
                  rules={[
                    { required: isRequired, message: intl.formatMessage({ id: 'error.message.address' }) },
                  ]}
                >
                  <div className="condo-payment__input-wrapper">
                    <span className="condo-payment__input-label">
                      <FormattedMessage id="address.line" values={{ number: 1 }} />
                    </span>
                    <div className="condo-payment__input">
                      <Input
                        placeholder={intl.formatMessage({ id: 'street.and.number' })}
                        maxLength={
                          supplierType !== SupplierType.Intervals ? MAX_LENGTH : MAX_LENGTH_INTERVALS
                        }
                        value={card.addressLine1}
                        onChange={this.onChangeAddressLine1}
                        disabled={loadingBooking || isAddressAsPrimary}
                      />
                    </div>
                  </div>
                </Form.Item>
                <Form.Item name="addressLine2" rules={[{ required: false }]}>
                  <div className="condo-payment__input-wrapper">
                    <span className="condo-payment__input-label">
                      <FormattedMessage id="address.line" values={{ number: 2 }} />
                    </span>
                    <div className="condo-payment__input">
                      <Input
                        placeholder={intl.formatMessage({ id: 'address.optional' })}
                        maxLength={
                          supplierType !== SupplierType.Intervals ? MAX_LENGTH : MAX_LENGTH_INTERVALS
                        }
                        value={card.addressLine2}
                        onChange={this.onChangeAddressLine2}
                        disabled={loadingBooking || isAddressAsPrimary}
                      />
                    </div>
                  </div>
                </Form.Item>
                <Form.Item
                  name="city"
                  rules={[
                    {
                      required: isRequired,
                      message: intl.formatMessage({ id: 'error.message.city' }),
                      pattern: checkInLatinLetters,
                    },
                  ]}
                >
                  <div className="condo-payment__input-wrapper">
                    <span className="condo-payment__input-label">
                      <FormattedMessage id="city" />
                    </span>
                    <div className="condo-payment__input">
                      <Input
                        placeholder={intl.formatMessage({ id: 'city' })}
                        maxLength={
                          supplierType !== SupplierType.Intervals
                            ? MAX_LENGTH
                            : MAX_LENGTH_CITY_FOR_INTERVALS
                        }
                        value={card.city}
                        onChange={this.onChangeCity}
                        disabled={loadingBooking || isAddressAsPrimary}
                      />
                    </div>
                  </div>
                </Form.Item>
                <Form.Item
                  name="country"
                  rules={[
                    {
                      required: isRequired,
                      message: intl.formatMessage({ id: 'error.message.country' }),
                    },
                  ]}
                >
                  <div className="condo-payment__input-wrapper">
                    <span className="condo-payment__input-label">
                      <FormattedMessage id="country" />
                    </span>
                    <div className="condo-payment__input">
                      <Select
                        placeholder={hideBrowserAutoFill(
                          intl.formatMessage({ id: 'country' }),
                          SECOND_CHARACTER_POSITION,
                        )}
                        value={countryName}
                        onChange={this.onChangeCountry}
                        disabled={loadingBooking || isAddressAsPrimary}

                        optionFilterProp="label"
                        showSearch={true}
                        options={supplierType !== SupplierType.Intervals ?
                          condosCountriesCode?.rciCountries?.map(
                            (item: IRciCountriesCodes) => ({
                              value: item.name,
                              label: item.name,
                            })) : 
                          condosCountriesCode?.intervalCountries?.map(
                            (item: IIntervalCountriesCodes) => ({
                              value: item.name,
                              label: item.name,
                            }))}
                      >
                      </Select>
                    </div>
                  </div>
                </Form.Item>

                <Form.Item
                  name="state"
                  rules={[
                    {
                      required: isRequired,
                      message: intl.formatMessage({ id: 'error.message.simple.state' }),
                      pattern: checkInLatinLetters,
                    },
                  ]}
                >
                  <div className="condo-payment__input-wrapper">
                    <span className="condo-payment__input-label">
                      <FormattedMessage id="state" />
                    </span>
                    <div className="condo-payment__input">
                      {!isEmpty(countryStates) ? (
                        <Select
                          showSearch
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          value={card?.stateCode || null}
                          placeholder={hideBrowserAutoFill(
                            intl.formatMessage({ id: 'select.state' }),
                            TENTH_CHARACTER_POSITION,
                          )}
                          onChange={this.onChangeState}
                          disabled={loadingBooking || isAddressAsPrimary}
                        >
                          {countryStates.map(
                            (item, index) => {
                              return (
                                <Select.Option key={index} value={item.code}>
                                  {item.name}
                                </Select.Option>
                              );
                            },
                          )}
                        </Select>) : (
                        <Input
                          placeholder={intl.formatMessage({ id: 'error.message.simple.state' })}
                          value={card?.stateCode || null}
                          onChange={this.onChangeStateInput}
                          disabled={loadingBooking || isAddressAsPrimary}
                        />)}
                    </div>
                  </div>
                </Form.Item>
                
                <Form.Item
                  name="postalCode"
                  rules={[
                    {
                      required: isRequired,
                      message: intl.formatMessage({ id: 'error.message.postal.code' }),
                    },
                    {
                      validator: async () => {
                        await setValidator(errorsField, 'guests[0]', isErrorMessageZipCode);
                      },
                    },
                  ]}
                >
                  <div className="condo-payment__input-wrapper postal-code-input">
                    <span className="condo-payment__input-label">
                      <FormattedMessage id="postal-code" />
                    </span>
                    <div className="condo-payment__input">
                      <Input
                        placeholder={intl.formatMessage({ id: 'postal-code' })}
                        maxLength={MAX_POSTAL_CODE}
                        onChange={this.onChangePostalCode}
                        value={card.zipCode}
                        disabled={loadingBooking || isAddressAsPrimary}
                      />
                    </div>
                  </div>
                </Form.Item>
              </div>
            </div>
          </div>
        </>
      )
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    weeksReviewBookStore: state.weeksReviewBookStore,
    condosStaticStore: state.condosStaticStore,
    loginStore: state.loginStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setWeeksCard: weeksReviewBookAction.setWeeksCard,
  setWeeksGuest: weeksReviewBookAction.setWeeksGuest,
  setErrorMessageExpirationDate: weeksReviewBookAction.setErrorMessageExpirationDate,
  setErrorMessageCardNumber: weeksReviewBookAction.setErrorMessageCardNumber,
  setErrorMessageZipCode: weeksReviewBookAction.setErrorMessageZipCode,
  
  setSelection: insuranceActions.setSelection,
};

export const WeeksPaymentMethod = connect(mapStateToProps, mapDispatchToProps)(injectIntl(WeeksPaymentMethodComponent));
