import React from 'react';

import { Reservations } from '@components';

import './style.scss';

interface IProps {}

export class ReservationsPage extends React.Component<IProps> {
  render(): React.ReactNode {
    return <Reservations />;
  }
}
