import React from 'react';
import { Skeleton } from 'antd';

interface IProps {}

const NUMBER = 1;
const NUMBER_OF_BLOCKS = [NUMBER, NUMBER, NUMBER];
const ONE_ROW = 1;
const TWO_ROWS = 2;
const THREE_ROWS = 3;

export class CondoCardsSkeleton extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <div className="condo-skeleton">
        <div className="condo-skeleton__items">
          {NUMBER_OF_BLOCKS.map((item, index) => (
            <div key={`${index}-${item}`} className="condo-skeleton__item">
              <Skeleton
                className="condo-skeleton__item-img"
                active
                title={false}
                paragraph={{ rows: ONE_ROW }}
              />
              <div className="condo-skeleton__item-blocks">
                <Skeleton
                  className="condo-skeleton__item-block-top"
                  active
                  title={false}
                  paragraph={{ rows: ONE_ROW }}
                />
                <Skeleton
                  className="condo-skeleton__item-block-middle"
                  active
                  title={false}
                  paragraph={{ rows: THREE_ROWS }}
                />
                <div className="condo-skeleton__item-block-bottom">
                  <Skeleton
                    className="condo-skeleton__item-block-bottom-left"
                    active
                    title={false}
                    paragraph={{ rows: TWO_ROWS }}
                  />
                  <Skeleton
                    className="condo-skeleton__item-block-bottom-right"
                    active
                    title={false}
                    paragraph={{ rows: ONE_ROW }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
