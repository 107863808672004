import React, { useMemo, useState } from 'react';
import parse from 'html-react-parser';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { IClientCash, ICondoUnit } from '@share/common-types';
import { DATE_FORMAT_UNIT } from '@constants';
import { getSelectedCurrency } from '@share/utils';
import { renderNumber, getNights, formatDateCheckInOut, formatDateCheckInOutWithLabel, creatingArrayWithNumber } from '@share/utils';
import { BlueButton } from '@share/components';
import { Currency, ModalCancellationPolicies, WalletMessage } from '@components';
import { RootState } from '@share/utils';
import { IUnitsSearchState, IWeekDetailsState, weeksReviewBookAction } from '@store/slices';
import { IWeekState, weeksActions } from '@share/store/slices';
import { ILoginState } from '@share/store/slices';
import { Routes } from '@share/constants';
import { WalletPriceType, getWalletPrices } from '@utils';

import { InfoSvg, ManDarkSvg } from '@assets';

import { ModalUnitDetails } from '../../../../../condo/condo-details/condo-units/modal-unit-details';

import './style.scss';
import { RefundableEnum } from '@common-types';

interface IMapStateToProps {
  weeksStore: IWeekState;
  weeksDetailsStore: IWeekDetailsState;
  unitsSearchStore: IUnitsSearchState;

  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setSelectedWeeksSearchClientCash: (selectedClientCahs: IClientCash) => void;
  setSelectedWeeksReviewClientCash: (selectedClientCash: number) => void;
  setSelectedWeeksReviewClientCashStr: (clientCash: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  unit: ICondoUnit;
  condoId: number;
  isWalletSearch: boolean;
  isHotel: boolean;
}

const FACTOR = Math.pow(10, 0);

function WeeksUnitRowComponent(props: IProps) {

  const { unit, condoId, isWalletSearch, isHotel, loginStore, weeksDetailsStore, weeksStore, history } = props;
  const { selectedWeeksSearchClientCash } = weeksStore;
  const { refundability, dateRange, price, publicPrice, unitId, name, cancellationPolicyText, bathroomsCount, bedroomsCount, maxOccupancy, maxWalletClientCash } = unit;
  const { account, userWalletData } = loginStore;

  const [isModalCancellationPoliciesVisible, setIsModalCancellationPoliciesVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showCancellationPolicies = (): void => {
    setIsModalCancellationPoliciesVisible(true);
  };

  const handleCancelModalCancellationPolicies = (): void => {
    setIsModalCancellationPoliciesVisible(false);
  };

  const handleCancel = (): void => {
    setIsModalVisible(!isModalVisible);
  };

  const handleReviewCondo = async (accountName: string, condoId: number, unitId: string, walletPrices: any) => {
    const displayWalletSavings = account?.walletWalletSavings && (walletPrices?.priceSavings > 0 || walletPrices?.maxClientCashAmountAllow > 0) && !!walletPrices?.maxClientCashAmountAllow;

    const selectedPropertyClientCash = displayWalletSavings ? walletPrices?.maxClientCashPermited : selectedWeeksSearchClientCash?.selectedPropertyClientCash;

    await props.setSelectedWeeksReviewClientCash(selectedPropertyClientCash);
    await props.setSelectedWeeksReviewClientCashStr(selectedPropertyClientCash ? selectedPropertyClientCash.toString() : '');
    await props.setSelectedWeeksSearchClientCash({ ...selectedWeeksSearchClientCash, selectedPropertyReviewClientCash: selectedPropertyClientCash });

    history.push(`/${accountName}${Routes.WeeksBook}/${condoId}/${unitId}${location.search}`);
  }

  const week = weeksDetailsStore?.week;

  const baseClassName = 'unit-row__left-block-text';
  const baseClassNameMobile = `${baseClassName}-mobile`;
  const baseClassNameDateOnlyAlternative = `${baseClassName} dates}`;
  const baseClassNameOnlyAlternative = `${baseClassName}`;
  const baseClassNameOnlyAlternativeMobile = `${baseClassNameMobile}`;

  const savings = (!!price && !!publicPrice && publicPrice - price >= 0) ? publicPrice - price : null;
  const savingsPercent = !!savings && !!publicPrice ? Math.round(((savings / publicPrice) * 100) * FACTOR) / FACTOR : 0;

  const priceInt = price;
  const maxWalletClientCashInt = maxWalletClientCash;

  const currency = useMemo(() => getSelectedCurrency(account), [account]);
  const walletPrices = useMemo(() => getWalletPrices(account, userWalletData, selectedWeeksSearchClientCash, priceInt, maxWalletClientCashInt, WalletPriceType.Details), [account, userWalletData, selectedWeeksSearchClientCash, priceInt, maxWalletClientCashInt]);

  const finalPriceCalculated = priceInt - (walletPrices?.clientCashAmountApplied ? walletPrices?.clientCashAmountApplied : 0);
  const finalPriceMax = priceInt - (walletPrices?.maxClientCashAmountAllow ? walletPrices?.maxClientCashAmountAllow : 0);

  const displayWalletSavings = account?.walletWalletSavings && (walletPrices?.priceSavings > 0 || walletPrices?.maxClientCashAmountAllow > 0) && !!walletPrices?.maxClientCashAmountAllow;

  const finalPrice = displayWalletSavings ? finalPriceMax : finalPriceCalculated;
    
  const isRefundable = refundability ===  RefundableEnum.Refundable;

  return (
    <div className="unit-row">
      <div className="unit-row__left-block">
        <p className={baseClassName} style={{ width: '220px' }}>
          <label style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{name?.replaceAll('<[^>]+>', '')}</label>
          <label>
            <span className="units-search-unit__unit-details-text" style={{ fontWeight: 'normal' }} onClick={handleCancel}>
              <FormattedMessage id="more.info" />
            </span>
          </label>
        </p>
        <p className={baseClassNameMobile} style={{ marginBottom: '20px' }}>
          <label style={{ fontSize: '14px', fontWeight: 'bold' }}>{name}</label>
          <label>
            <span className="units-search-unit__unit-details-text" style={{ fontWeight: 'normal' }} onClick={handleCancel}>
              <FormattedMessage id="more.info" />
            </span>
          </label>
        </p>
        <ModalUnitDetails
            condo={week?.propertyDetails}
            unit={unit}
            visible={isModalVisible}
            onCancel={handleCancel}
            walletPrices={walletPrices}
            adultsCount={null}
            childrenCount={null}
          />
      </div>

      <div className="unit-row__left-block">
        <p
          className={baseClassNameDateOnlyAlternative}
          style={{ minWidth: '180px' }}
        >
          <label>
            {parse(formatDateCheckInOutWithLabel(dateRange.from))} -{' '}
          </label>
          <label>
            {parse(formatDateCheckInOutWithLabel(dateRange.to))}
          </label>
        </p>
        <div className={baseClassNameOnlyAlternativeMobile}>
          <p className="unit-row__date-title">
            <FormattedMessage id="check.in" />:
          </p>
          <p className="unit-row__date-info">
            {formatDateCheckInOut(dateRange.from, DATE_FORMAT_UNIT)}
          </p>
        </div>
        <div className={baseClassNameOnlyAlternativeMobile} >
          <p className="unit-row__date-title">
            <FormattedMessage id="check.out" />:
          </p>
          <p className="unit-row__date-info">
            {formatDateCheckInOut(dateRange.to, DATE_FORMAT_UNIT)}
          </p>
        </div>
      </div>

      <div className="unit-row__left-block">
        <p
          className={baseClassNameOnlyAlternative}
          style={{ width: '90px' }}
        >
          <label>
            {getNights(dateRange.from, dateRange.to)}
          </label>
        </p>
        <div className={baseClassNameOnlyAlternativeMobile}>
          <p className="unit-row__date-title">
            <FormattedMessage id="nights.title" />:
          </p>
          <p className="unit-row__date-info">
            <FormattedMessage
              id="nights"
              values={{ count: getNights(dateRange.from, dateRange.to) }}
            />
          </p>
        </div>
      </div>

      {!isHotel ?
        <div className="unit-row__left-block">
          <p className={baseClassNameOnlyAlternative} style={{ width: '90px' }}>
            <label>
              {bedroomsCount} / {bathroomsCount}
            </label>
          </p>
          <div className={baseClassNameOnlyAlternativeMobile} >
            <p className="unit-row__date-title">
              <FormattedMessage id="bedrooms.bathrooms" />:
            </p>
            <p className="unit-row__date-info">
                {bedroomsCount} / {bathroomsCount}
            </p>
          </div>
        </div>: null}

      <div className="unit-row__left-block">
        <p className={baseClassNameOnlyAlternative} style={{ width: '70px' }}>
          <label>
            {maxOccupancy}
          </label>
        </p>
        <div className={baseClassNameOnlyAlternativeMobile}>
          <p className="unit-row__date-title">
            <FormattedMessage id="guests" />:
          </p>
          <p className="unit-row__date-info">
            {maxOccupancy}{'  '}
            {maxOccupancy > 0 && (
              <span className="unit-main-info__guests">
                {creatingArrayWithNumber(maxOccupancy, 1).map((value, i) => (
                  <span className="unit-main-info__icon-wrapper" key={i}>
                    <ManDarkSvg />
                  </span>))}
              </span>)}
          </p>
        </div>
      </div>
      
      <div className="unit-row__price-total-wallet mobile">
        <WalletMessage
          price={priceInt}
          currency={currency}
          type={WalletPriceType.Details}
          isCondo
          isOneLine
        />
      </div>

      <div className="unit-row__right-block">
        <div className="unit-row__right-block-wrapper">
          <div className="unit-row__price">
            <div className="unit-row__price-total">
              {isWalletSearch ? (
                <div className="unit-row__price-total-wallet desktop">
                  <WalletMessage
                    price={priceInt}
                    currency={currency}
                    type={WalletPriceType.Details}
                    isCondo
                    isShort
                    style={{ marginBottom: '10px' }}
                  />
                </div>) : null}

              {(displayWalletSavings && walletPrices?.priceSavings > 0) ? (
                <div className="unit-row__for-info">
                  <p className="unit-row__for-info-text public">
                    <Currency currency={currency} />{renderNumber(Math.floor(priceInt))}
                  </p>
                </div>) : null}

              <p className="unit-row__price-total-text"><Currency currency={currency} />{renderNumber(Math.floor(finalPrice))}</p>
              <div className="unit-row__for-info">
                <p className="unit-row__for-info-text">
                  <span className="unit-row__price-includes-taxes">
                    <FormattedMessage id="abbreviated.price.includes.taxes" />
                  </span>
                </p>
              </div>
              {(!!savingsPercent && savingsPercent > 0 && !displayWalletSavings) ? (
                <div className="unit-row__for-info">
                  <p className="unit-row__for-info-text savings">
                    <FormattedMessage id={account?.forceMemberWording ? 'member.save' : 'save'} /> {savingsPercent}%
                  </p>
                </div>) : null}

              {(displayWalletSavings && walletPrices?.priceSavings > 0) ? (
                <div className="unit-row__for-info">
                  <p className="unit-row__for-info-text savings">
                    <FormattedMessage id="save.up.to" values={{ number: walletPrices?.priceSavings ? walletPrices?.priceSavings : '<1' }} />
                  </p>
                </div>) : null}
            </div>
          </div>
        </div>

        <div className="unit-row__right-block-wrapper refundable">
          <div className="unit-row__right-block">
            <div className="unit-row__refundable" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div
                className={isRefundable ? 'unit-row__refundable-ref' : 'unit-row__refundable-nonref'}
                onClick={showCancellationPolicies}
              >
                <span>
                  <FormattedMessage id={isRefundable ? 'refundable' : 'non.refundable'} />
                </span>
                <InfoSvg />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="unit-row__right-block refundable">
        <div className="unit-row__refundable">
          <div
            className={isRefundable ? 'unit-row__refundable-ref' : 'unit-row__refundable-nonref'}
            onClick={showCancellationPolicies}
          >
            <span>
              <FormattedMessage id={isRefundable ? 'refundable' : 'non.refundable'} />
            </span>
            <InfoSvg />
          </div>
        </div>
      </div>

      <div className="unit-row__right-block">
        <div className="unit-row__action">
          <BlueButton onClick={() => handleReviewCondo(account?.name, condoId, unitId, walletPrices)}>
            <FormattedMessage id="reserve.unit" />
          </BlueButton>
        </div>
      </div>

      <ModalCancellationPolicies
        isForCondo={true}
        cancellationPoliciesText={cancellationPolicyText}
        visible={isModalCancellationPoliciesVisible}
        onCancel={handleCancelModalCancellationPolicies}
      />
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    weeksStore: state.weeksStore,
    weeksDetailsStore: state.weeksDetailsStore,

    unitsSearchStore: state.unitsSearchStore,

    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setSelectedWeeksSearchClientCash: weeksActions.setSelectedWeeksSearchClientCash,
  setSelectedWeeksReviewClientCash: weeksReviewBookAction.setSelectedWeeksReviewClientCash,
  setSelectedWeeksReviewClientCashStr: weeksReviewBookAction.setSelectedWeeksReviewClientCashStr,
};


export const WeeksUnitRow = connect(mapStateToProps, mapDispatchToProps)(withRouter(WeeksUnitRowComponent));
