import React from 'react';
import { Skeleton } from 'antd';
import './style.scss';

interface IProps {}

export class PriceSkeleton extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <div className="price-skeleton">
        <Skeleton className="price-skeleton__top" active title={false} paragraph={{ rows: 2 }} />
        <div className="price-skeleton__mid-wrapper">
          <Skeleton className="price-skeleton__left" active title={false} paragraph={{ rows: 2 }} />
          <Skeleton
            className="price-skeleton__right"
            active
            title={false}
            paragraph={{ rows: 2 }}
          />
        </div>
      </div>
    );
  }
}
