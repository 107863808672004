import { v4 as createID } from 'uuid';
import { IAwardWinner, IOffice } from '@common-types';

import Office1 from '@assets/images/concierge-team-travel/team1.jpg';
import Office2 from '@assets/images/concierge-team-travel/team2.jpg';
import Office3 from '@assets/images/concierge-team-travel/team3.jpg';
import AwardWinner1 from '@assets/images/treat-yourself/apple-leisure-group.png';
import AwardWinner2 from '@assets/images/treat-yourself/viking-river-cruises.png';
import AwardWinner3 from '@assets/images/treat-yourself/princess-cruises.png';
import AwardWinner4 from '@assets/images/treat-yourself/care.png';
import AwardWinner5 from '@assets/images/treat-yourself/travel-leaders-network.png';
import AwardWinner6 from '@assets/images/company-logos/funjet.png';

export const OfficesList: IOffice[] = [
  { id: createID(), src: Office1, alt: 'Florida office entrance' },
  { id: createID(), src: Office2, alt: 'Georgia office team members' },
  { id: createID(), src: Office3, alt: 'North Carolina office team members' },
];

export const AwardWinnersList: IAwardWinner[] = [
  {
    id: createID(),
    src: AwardWinner1,
    company: 'concierge.booking.awardWinners.first.company',
    award: 'concierge.booking.awardWinners.first.award',
  },
  {
    id: createID(),
    src: AwardWinner2,
    company: 'concierge.booking.awardWinners.second.company',
    award: 'concierge.booking.awardWinners.second.award',
  },
  {
    id: createID(),
    src: AwardWinner3,
    company: 'concierge.booking.awardWinners.third.company',
    award: 'concierge.booking.awardWinners.third.award',
  },
  {
    id: createID(),
    src: AwardWinner4,
    company: 'concierge.booking.awardWinners.fourth.company',
    award: 'concierge.booking.awardWinners.fourth.award',
  },
  {
    id: createID(),
    src: AwardWinner5,
    company: 'concierge.booking.awardWinners.fifth.company',
    award: 'concierge.booking.awardWinners.fifth.award',
  },
  {
    id: createID(),
    src: AwardWinner6,
    company: 'concierge.booking.awardWinners.sixth.company',
    award: 'concierge.booking.awardWinners.sixth.award',
  },
];

export const LUCKY_POINT_TRANSPARENT = 'rgba(32, 39, 77, 0.8)';
