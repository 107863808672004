import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { useParams } from "react-router-dom";
import { Button, Form, Input, Alert } from 'antd';
import { RootState, getAccountUsernameFromPath } from '@share/utils';
import { IPasswordState, reset } from '@share/store/slices';

import './style.scss';

interface IMapStateToProps {
  passwordStore: IPasswordState;
}

interface IMapDispatchToProps {
  resetPassword: (token: string, password: string, confirmPassword: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps { }

function ResetPasswordComponent(props: IProps) {
  const [accountName, setAccountName] = useState(null);
  const params: any = useParams();
  const { isSuccess, message } = props.passwordStore;

  useEffect(() => {
    const accountName = getAccountUsernameFromPath(props.history);
    setAccountName(accountName);
  }, [])

  const onFinish = (values: any) => {
    props.resetPassword(params.token, values.password, values.confirm);
  };

  return (
    <div className="password-page">
      <div className="login-box col-sm-5 col-xs-12">
        <Form
          name="normal_password"
          className="password-form"
          layout="vertical"
          onFinish={onFinish}
        >
          <p className="form-title"><FormattedMessage id="reset.password" /></p>

          <Form.Item
            name="password"
            label={<FormattedMessage id="new.password" />}
            rules={[
              { required: true, message: <FormattedMessage id="please.input.your.password" /> },
              { min: 5, message: <FormattedMessage id="password.must.be.minimum.characters" /> }
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label={<FormattedMessage id="confirm.new.password" />}
            rules={[
              { required: true, message: <FormattedMessage id="please.confirm.your.password" /> },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(props.intl.formatMessage({ id: 'the.two.passwords.that.you.entered.do.not.match' })));
                }
              })
            ]}
          >
            <Input.Password />
          </Form.Item>

          {message ?
            <Form.Item>
              {isSuccess
                ? <Alert message="" description={message} type="success" showIcon />
                : <Alert message="" description={message} type="error" showIcon />
              }
            </Form.Item> : null}

          <Form.Item className='mt-4'>
            <Button type="primary" htmlType="submit" className="password-form-button" disabled={isSuccess}>
              <FormattedMessage id="save.changes" />
            </Button>
          </Form.Item>

          <Form.Item>
            <Button className="password-form-button cancel" href={`${accountName}/signin`}><FormattedMessage id="back.to.login" /></Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    passwordStore: state.passwordStore
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  resetPassword: (token: string, password: string, confirmPassword: string) => {
    dispatch(reset(token, password, confirmPassword));
  }
});

const ResetPasswordComponentRouter = withRouter(ResetPasswordComponent);

export const ResetPassword = connect(mapStateToProps, mapDispatchToProps)(injectIntl(ResetPasswordComponentRouter));
