import React from 'react';

import Select from 'antd/lib/select';

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { CarsErrorTypes, ICarsDriverState, ILoginState, carsDriverActions } from '@share/store/slices';
import { RootState } from '@share/utils';

import { ThunkDispatch } from 'redux-thunk';
import { Action } from '@reduxjs/toolkit';
import { countriesCode } from '@share/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import { faGlobeAmericas, faUserCircle } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IMapStateToProps {
  carsDriverStore: ICarsDriverState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setSelectedDriverAge: (age: string) => void;
  setSelectedDriverCountry: (country: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {
  isExperiences: boolean;
  disable: boolean;
}

class CarsDriverComponent extends React.Component<IProps> {

  render(): React.ReactNode {
    const { carsDriverStore, loginStore } = this.props;
    const { driverAgeSelected, driverCountrySelected, ages, error, errorType } = carsDriverStore;
    const { account } =  loginStore;

    const color: any = account?.searchIconsColor;
    const generalBorderRadius: any = account?.generalBorderRadius ? account?.generalBorderRadius : null;

    const style = !isEmpty(generalBorderRadius) ? { borderRadius: generalBorderRadius } : {};  
    const styleColor = !isEmpty(color)? { color } : {};  

    return (
      <>
        <div className={`date-picker cars-dates cars-driver ${!!error ? 'cars-error' : ''}`}>
          <div className="date-picker__time-selection">
            <div className="date-picker__wrapper cars-time disable-error" style={style}>
              <div className="date-picker__left">
                <div className="date-picker__icon" style={styleColor}>
                  <FontAwesomeIcon icon={faUserCircle} />
                </div>
              </div>
              <div className="date-picker__right">
                <div className="date-picker__label">
                  <FormattedMessage id="cars.driver.age" />
                </div>
                <div className="date-picker__header">
                  <Select
                    showSearch={true}
                    placeholder={'Age'}
                    value={driverAgeSelected}
                    onChange={this.props.setSelectedDriverAge}
                  >
                    {ages.map((t, i) => (<Select.Option key={`${t}_${i}`} value={t}>{t}</Select.Option>))}
                  </Select>
                </div>
              </div>
            </div>
            <div className={`date-picker__wrapper cars-time bordered country-selection ${!!error ? 'error' : ''} ${errorType === CarsErrorTypes.DriverCountry ? 'error-date' : ''}`} style={style}>
              <div className="date-picker__left">
                <div className="date-picker__icon" style={styleColor}>
                  <FontAwesomeIcon icon={faGlobeAmericas} />
                </div>
              </div>
              <div className="date-picker__right">
                <div className="date-picker__label">
                  <FormattedMessage id="cars.driver.residency" />
                </div>
                <div className="date-picker__header">
                  <Select
                    showSearch={true}
                    placeholder={'Country'}
                    value={driverCountrySelected}
                    onChange={this.props.setSelectedDriverCountry}
                  >
                    {countriesCode.map((c, i) => (<Select.Option key={`${c.isoCode}_${i}`} value={c.isoCode}>{c.name}</Select.Option>))}
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    carsDriverStore: state.carsDriverStore,
    loginStore: state.loginStore,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  setSelectedDriverAge: (age: string) => dispatch(carsDriverActions.setDriverAgeSelected(age)),
  setSelectedDriverCountry: (country: string) => dispatch(carsDriverActions.setDriverCountrySelected(country)),  
});

export const CarsDriver = connect(mapStateToProps, mapDispatchToProps)(CarsDriverComponent);
