
import React from 'react';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import NoResults from '@assets/images/no_all_results.png';

import './style.scss';

function DashboardReportEmptyComponent() {
  return (
    <div className="dasshboard-reports-empty-wrapper" style={{ backgroundImage: `url(${NoResults})`}}>
      <h1><FormattedMessage id="no.data.available" /></h1>
      <h3><FormattedMessage id="change.search.criteria" /></h3>
    </div>
  );
}

export const DashboardReportEmpty = connect()(DashboardReportEmptyComponent);
