import React, { ReactNode } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { SelectValue } from 'antd/lib/select';
import { Checkbox, Select } from 'antd';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';

import { ICondoUnit } from '@share/common-types';
import { getFullUnitName } from '@utils';
import { ErrorAlertSvg } from '@assets';
import { getFullUnitNameWithOutId, getNights, RootState } from '@share/utils';
import { CONDO_DETAILS_SORTING_BEBA_ASC, CONDO_DETAILS_SORTING_BEBA_DESC, CONDO_DETAILS_SORTING_DATES_ASC, CONDO_DETAILS_SORTING_DATES_DESC, CONDO_DETAILS_SORTING_GUESTS_ASC, CONDO_DETAILS_SORTING_GUESTS_DESC, CONDO_DETAILS_SORTING_NIGHTS_ASC, CONDO_DETAILS_SORTING_NIGHTS_DESC, CONDO_DETAILS_SORTING_POLICY_ASC, CONDO_DETAILS_SORTING_POLICY_DESC, CONDO_DETAILS_SORTING_PRICE_ASC, CONDO_DETAILS_SORTING_PRICE_DESC, CONDO_DETAILS_SORTING_SUITE_ASC, CONDO_DETAILS_SORTING_SUITE_DESC, Routes } from '@share/constants';
import { ILoginState } from '@share/store/slices';
import { CondoMatchTypeEnum } from '@share/common-types';

import { WeeksUnitRow } from '../weeks-unit-row';
import { CondoUnitRowHeader } from '../../../../../condo/condo-details/condo-units/condo-list-units/condo-unit-row-header';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends WrappedComponentProps, IMapStateToProps {
  title: React.ReactNode;
  alternativeUnits: ICondoUnit[];
  condoId: number;
  currentUnitName: string;
  baseUrl: string;
  isHotel: boolean;

  getCurrentUnit: (value: string, index?: number) => void;
}

interface IState {
  isFullUnits: boolean;
  showExactDatesUnit: boolean;
  selectedSorting: string;
}

const ZERO = 0;
const ONE = 1;
const TWO = 2;
const MAX_UNITS_COUNT = 5;

class ListUnitsComponent extends React.Component<IProps, IState> {
  state: IState;

  constructor(props: IProps) {
    super(props);

    let maxCount = ZERO;
    const counts: { [key: string]: number } = {};

    props.alternativeUnits.forEach((unit) => {
      const type = getFullUnitName(unit, props.intl);
      if (counts[type]) {
        counts[type]++;
      } else {
        counts[type] = ONE;
      }
    });

    Object.keys(counts).forEach((key: string) => {
      if (counts[key] > maxCount) {
        maxCount = counts[key];
      }
    });

    this.state = {
      isFullUnits: false,
      showExactDatesUnit: false,
      selectedSorting: null
    };
  }

  getAlternativeUnits = (value: SelectValue): ICondoUnit[] => {
    const { intl } = this.props;
    const { selectedSorting, showExactDatesUnit } = this.state;
    let alternativeUnits = this.props.alternativeUnits.slice();

    if (selectedSorting) {
      alternativeUnits.sort((a: ICondoUnit, b: ICondoUnit) => {
        if (selectedSorting === CONDO_DETAILS_SORTING_SUITE_ASC) {
          return a?.name.localeCompare(b?.name);
        } else if (selectedSorting === CONDO_DETAILS_SORTING_SUITE_DESC) {
          return b?.name.localeCompare(a?.name);
        } else if (selectedSorting === CONDO_DETAILS_SORTING_DATES_ASC) {
          return new Date(a?.dateRange?.from).getTime() - new Date(b?.dateRange?.from).getTime();
        } else if (selectedSorting === CONDO_DETAILS_SORTING_DATES_DESC) {
          return new Date(b?.dateRange?.from).getTime() - new Date(a?.dateRange?.from).getTime();
        } else if (selectedSorting === CONDO_DETAILS_SORTING_NIGHTS_ASC) {
          return getNights(a?.dateRange?.from, a?.dateRange?.to) - getNights(b?.dateRange?.from, b?.dateRange?.to);
        } else if (selectedSorting === CONDO_DETAILS_SORTING_NIGHTS_DESC) {
          return getNights(b?.dateRange?.from, b?.dateRange?.to) - getNights(a?.dateRange?.from, a?.dateRange?.to);
        } else if (selectedSorting === CONDO_DETAILS_SORTING_GUESTS_ASC) {
          return a?.maxOccupancy - b?.maxOccupancy;
        } else if (selectedSorting === CONDO_DETAILS_SORTING_GUESTS_DESC) {
          return b?.maxOccupancy - a?.maxOccupancy;
        } else if (selectedSorting === CONDO_DETAILS_SORTING_BEBA_ASC) {
          return a?.bedroomsCount - b?.bedroomsCount;
        } else if (selectedSorting === CONDO_DETAILS_SORTING_BEBA_DESC) {
          return b?.bedroomsCount - a?.bedroomsCount;
        } else if (selectedSorting === CONDO_DETAILS_SORTING_PRICE_ASC) {
          return Math.floor(a?.price) - Math.floor(b?.price);
        } else if (selectedSorting === CONDO_DETAILS_SORTING_PRICE_DESC) {
          return Math.floor(b?.price) - Math.floor(a?.price);
        }
        return 1;
      });
    }

    if (!isEmpty(value)) {
      alternativeUnits = alternativeUnits.filter(item => getFullUnitName(item, intl) === value);
    }

    if (showExactDatesUnit) {
      alternativeUnits = alternativeUnits.filter(item => item.matchType === CondoMatchTypeEnum.Exact);
    }

    return alternativeUnits;
  };

  handleUnitNameChange = (data: { key: string; label: ReactNode; value: string }): void => {
    const { getCurrentUnit } = this.props;

    getCurrentUnit(data.value? data.value.toString() : null);
  };

  getNamesUnit = (units: ICondoUnit[]): string[] => {
    const { intl } = this.props;
    return units
      .filter((item, i, unit) => unit.findIndex((currentUnit) => currentUnit.id === item.id) === i)
      .map((item) => {
        return getFullUnitName(item, intl);
      });
  };

  toggleAlternativeUnits = (): void => {
    this.setState(({ isFullUnits }) => ({
      isFullUnits: !isFullUnits,
    }));
  };

  getUnitsToShow = (): ICondoUnit[] => {
    const { currentUnitName } = this.props;
    const { isFullUnits } = this.state;

    return isFullUnits
      ? this.getAlternativeUnits(currentUnitName)
      : this.getAlternativeUnits(currentUnitName).slice(ZERO, MAX_UNITS_COUNT);
  };

  getStyleUnitName = (value: string): React.ReactNode => {
    const name = value.split(' ').splice(ZERO, TWO).join(' ');
    const params = value.replace(name, '');

    return (
      <p>
        <span className="list-units__unit-name bold">{name}</span>
        {params}
      </p>
    );
  };

  onToggleShowOnlyExactUnits = (e: { target: CheckboxChangeEventTarget }): void => {
    this.setState({ showExactDatesUnit: e.target.checked });
  };

  render(): React.ReactNode {
    const { title, condoId, loginStore, alternativeUnits, currentUnitName, baseUrl, isHotel, intl } = this.props;
    const { account } = loginStore;
    const { isFullUnits, showExactDatesUnit } = this.state;
    const unitsToShow = this.getUnitsToShow();

    const walletHasSliderOnSearch = account?.walletHasSliderOnSearch;

    return (
      <div className="list-units">
        {alternativeUnits.length > ONE ? (
          <>
            <h3 className="list-units__title" style={{ marginTop: '0px', marginBottom: '15px' }}><FormattedMessage id="filter.by" /></h3>
            <div className="list-units__filters">
              <Select
                labelInValue
                value={{
                  key: currentUnitName,
                  label: !isEmpty(currentUnitName) ? this.getStyleUnitName(getFullUnitNameWithOutId(currentUnitName)) : intl.formatMessage({ id: 'all' }),
                  value: currentUnitName,
                }}
                onChange={this.handleUnitNameChange}
              >
                <Select.Option value={''}>
                  <FormattedMessage id="all" />
                </Select.Option>
                {this.getNamesUnit(alternativeUnits).map((name, index) => (
                  <Select.Option key={index} value={name}>
                    {getFullUnitNameWithOutId(name)}
                  </Select.Option>
                ))}
              </Select>

              <Checkbox
                onChange={this.onToggleShowOnlyExactUnits}
                checked={showExactDatesUnit}
              >
                <FormattedMessage id="show.only.exact.units" />
              </Checkbox>
            </div>
          </>) : null}

        <h3 className="list-units__title">{title}</h3>

        <div className="list-units__wrapper">
          <CondoUnitRowHeader
            isOnlyAlternative={false}
            onSortingSelected={(selectedSorting: string) => this.setState({ selectedSorting })}
            hideBedroomsBathroomsColumn={isHotel}
          />
          {unitsToShow.map((unit, index) => (
            <WeeksUnitRow
              key={index}
              unit={unit}
              condoId={condoId}
              isWalletSearch={walletHasSliderOnSearch}
              isHotel={isHotel}
            />
          ))}
        </div>

        {unitsToShow.length === 0 ? (
          <div className="list-units__alert-banner">
            <div className="list-units__alert-icon">
              <ErrorAlertSvg />
            </div>
            <div className="list-units__alert-banner-text-wrapper">
              <div className="list-units__alert-banner-header">
                <FormattedMessage id="demand.is.quite.high.for.this.area" />
              </div>
              <div className="list-units__alert-banner-text">
                <FormattedMessage id="request.a.custom.search" />
                {baseUrl ? (
                  <a
                    href={`/${this.props.loginStore?.account?.name}${Routes.CondoSearch}/submit-request`}
                    className="list-units__support-link"
                  >
                    <FormattedMessage id="click.this.link" />
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        <div className="list-units__show-link" onClick={this.toggleAlternativeUnits}>
          {this.getAlternativeUnits(currentUnitName).length > MAX_UNITS_COUNT &&
            (isFullUnits ? (
              <FormattedMessage id="show.less" />
            ) : (
              <FormattedMessage id="show.more" />
            ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

export const ListUnits = connect(mapStateToProps)(injectIntl(ListUnitsComponent));


