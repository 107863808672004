import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ICondoBookingSummary,
  ICondoGuestBooking,
  IErrorField,
  ICondoBookPackageWithCard,
  ICondoBookingComplete,
  ICondoCardBooking,
  ICardBooking,
  IInsuranceRequestTravelers,
} from '@common-types';
import {
  ISessionKey,
  BookingErrorsEnum,
} from '@share/common-types';
import { get, isString } from 'lodash';
import { getHeaders, axiosInstance, AppThunk, UrlUtils } from '@share/utils';
import ReactGA from 'react-ga4';
import {
  ERROR,
  EXTERNAL_ERROR,
  PRICE_CHANGED,
  STATUS_SUCCEEDED,
  VALIDATION_ERROR,
  STATUS_FAILED,
  STATUS_PENDING,
  WEEKS_BOOKING_SAME_ADDRESS_INFO_LABEL,
  SAVING_MISMATCH,
  CHECK_OUT_PAYMENT_ERROR,
  POSTAL_CODE_PAYMENT_ERROR,
  STATE_PAYMENT_ERROR,
  DECLINE_PAYMENT_ERROR,
  USD_CURRENCY,
  USA_STATES,
  WEEKS_BOOKING_GUEST_INFO_LABEL,
  WEEKS_BOOKING_CARD_INFO_LABEL,
} from '@constants';
import {
  SESSION_EXPIRED_STATUS,
  SESSION_SEARCH_EXPIRED_MESSAGE,
  Urls,
  C_C_BOOKING_CONFIRMED,
  WEEKS_UNITS_SEARCH_LABEL,
  WEEKS_UNITS_SESSION_KEY_LABEL,
  WEEKS_SESSION_KEY_LABEL, 
} from '@share/constants';
import { setThreeDSId, setThreeDSLoading, setThreeDSModalVisible, setThreeDSUrl } from '../review-book';
import { getUserWallet, condosActions } from '@share/store/slices';
import { getInsuranceQuote, insuranceActions, InsuranceSelection } from '../insurance';
import moment from 'moment';
import { getCondoAddressFromStorage } from '@utils';

export interface IWeeksReviewBookState {
  weeksBookingSummary: ICondoBookingSummary;

  loading: boolean;
  error: string;
  isUpdatePrice: boolean;
  guest: ICondoGuestBooking;
  card: ICondoCardBooking;
  errorsField: IErrorField[];
  loadingBooking: boolean;
  bookingComplete: boolean;
  isBookingInProgress: boolean;
  booking: ICondoBookingComplete;
  errorBooking: string;
  isExternalError: boolean;
  isPriceChangedError: boolean;
  bookingErrorCode: BookingErrorsEnum;
  errorsBookingMessage: IErrorField[];
  errorCountries: string;
  expiredSession: boolean;
  isBookingPending: boolean;

  isErrorMessageExpirationDate: boolean;
  isErrorMessageCardNumber: boolean;
  isErrorMessageZipCode: boolean;
  isSavingsMismatch: boolean;
  selectedWeeksReviewClientCash?: number;
  selectedWeeksReviewClientCashStr?: string;
}

const initialState: IWeeksReviewBookState = {
  weeksBookingSummary: null,
  loading: false,
  error: '',
  isUpdatePrice: false,
  guest: null,
  errorsField: null,
  card: null,
  loadingBooking: false,
  bookingComplete: false,
  isBookingInProgress: false,
  booking: null,
  errorBooking: '',
  isExternalError: false,
  isPriceChangedError: false,
  errorsBookingMessage: null,
  errorCountries: '',
  expiredSession: false,
  bookingErrorCode: null,
  isBookingPending: false,

  isErrorMessageExpirationDate: true,
  isErrorMessageCardNumber: true,
  isErrorMessageZipCode: true,
  isSavingsMismatch: false,
  selectedWeeksReviewClientCash: null,
  selectedWeeksReviewClientCashStr: ''
};

const zeroItem = 0;

const weeksReviewBookSlice = createSlice({
  name: 'weeks-review-book',
  initialState,
  reducers: {
    setWeeksReviewBook: (state: IWeeksReviewBookState, { payload }: PayloadAction<ICondoBookingSummary>) => {
      state.weeksBookingSummary = payload;
    },
    setLoadingWeeksBook: (state: IWeeksReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setIsWeeksBookingPending: (state: IWeeksReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.isBookingPending = payload;
    },
    setErrorWeeksBook: (state: IWeeksReviewBookState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setUpdateWeeksPriceReview: (
      state: IWeeksReviewBookState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isUpdatePrice = payload;
    },
    setWeeksGuest: (
      state: IWeeksReviewBookState,
      { payload }: PayloadAction<ICondoGuestBooking>,
    ) => {
      state.guest = payload;
    },
    setWeeksCard: (state: IWeeksReviewBookState, { payload }: PayloadAction<ICondoCardBooking>) => {
      state.card = payload;
    },
    setLoadingWeeksBooking: (state: IWeeksReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.loadingBooking = payload;
    },
    setWeeksBookingComplete: (
      state: IWeeksReviewBookState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.bookingComplete = payload;
    },
    setWeeksBookingInProgress: (
      state: IWeeksReviewBookState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isBookingInProgress = payload;
    },
    setWeeksBooking: (
      state: IWeeksReviewBookState,
      { payload }: PayloadAction<ICondoBookingComplete>,
    ) => {
      state.booking = payload;
    },
    setWeeksErrorBooking: (state: IWeeksReviewBookState, { payload }: PayloadAction<string>) => {
      state.errorBooking = payload;
    },
    setErrorsWeeksField: (
      state: IWeeksReviewBookState,
      { payload }: PayloadAction<IErrorField[]>,
    ) => {
      state.errorsField = payload;
    },
    setWeeksExternalError: (state: IWeeksReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.isExternalError = payload;
    },
    setWeeksSavingsMismatch: (
      state: IWeeksReviewBookState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isSavingsMismatch = payload;
    },
    setWeeksPriceChangedError: (
      state: IWeeksReviewBookState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isPriceChangedError = payload;
    },
    setBookingErrorCodeError: (
      state: IWeeksReviewBookState,
      { payload }: PayloadAction<BookingErrorsEnum>,
    ) => {
      state.bookingErrorCode = payload;
    },
    setWeeksErrorsBookingMessage: (
      state: IWeeksReviewBookState,
      { payload }: PayloadAction<IErrorField[]>,
    ) => {
      state.errorsBookingMessage = payload;
    },
    setErrorCountries: (state: IWeeksReviewBookState, { payload }: PayloadAction<string>) => {
      state.errorCountries = payload;
    },
    setWeeksExpiredSession: (state: IWeeksReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.expiredSession = payload;
    },
    setSelectedWeeksReviewClientCash: (state: IWeeksReviewBookState, { payload }: PayloadAction<number>) => {
      state.selectedWeeksReviewClientCash = payload;
    },
    setSelectedWeeksReviewClientCashStr: (state: IWeeksReviewBookState, { payload }: PayloadAction<string>) => {
      state.selectedWeeksReviewClientCashStr = payload;
    },
    weekBookingResetState: () => {
      return initialState;
    },
    setErrorMessageExpirationDate: (
      state: IWeeksReviewBookState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isErrorMessageExpirationDate = payload;
    },
    setErrorMessageCardNumber: (
      state: IWeeksReviewBookState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isErrorMessageCardNumber = payload;
    },
    setErrorMessageZipCode: (state: IWeeksReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.isErrorMessageZipCode = payload;
    },
  },
});

export const weeksReviewBookAction = weeksReviewBookSlice.actions;

export const weeksReviewBookReducer = weeksReviewBookSlice.reducer;

export const getWeeksReviewBook = (
    propertyId: number,
    availabilityId: string,
    sessionKey: ISessionKey,
    isGetCoupon?: false,
    quote?: string): AppThunk => {
  return async (dispatch, getState) => {
    const { condoRedeemCodeStore, weeksReviewBookStore, loginStore } = getState();
    const { account } = loginStore;
    const { card } = weeksReviewBookStore;

    if (!(condoRedeemCodeStore.isGetCoupon || isGetCoupon)) {
      dispatch(weeksReviewBookAction.setLoadingWeeksBook(true));
    }

    dispatch(weeksReviewBookAction.setUpdateWeeksPriceReview(true));
    dispatch(weeksReviewBookAction.setWeeksExpiredSession(false));

    try {
      const res = await axiosInstance.post(
        Urls.WeeksBookingSummary,
        { propertyId, availabilityId, sessionKey, quote },
        { ...getHeaders() },
      );

      const { loginStore, weeksReviewBookStore, condosStore, navigationMenuStore } = getState();
      const { userWalletData } = loginStore;
      const { selectedWeeksReviewClientCash } = weeksReviewBookStore;
      const { selectedCondoSearchClientCash } = condosStore;
      const { items } = navigationMenuStore;

      if (!!selectedWeeksReviewClientCash) {
        const walletNoDecimals = account?.walletNoDecimals;
        const maxWalletClientCashAmount = items?.isMLM ? res.data?.bookingCard?.bookingPrice?.savings : res.data?.bookingCard?.bookingPrice?.maxWalletClientCash;
        const convertionRate = userWalletData?.convertionRate ? userWalletData?.convertionRate : 1;
        const maxWalletClientCashCalculated = maxWalletClientCashAmount / convertionRate;
        const maxWalletClientCash = walletNoDecimals ? Math.floor(maxWalletClientCashCalculated) : maxWalletClientCashCalculated;
        
        if (maxWalletClientCash < selectedWeeksReviewClientCash) {
          dispatch(weeksReviewBookAction.setSelectedWeeksReviewClientCash(maxWalletClientCash));
          dispatch(weeksReviewBookAction.setSelectedWeeksReviewClientCashStr(maxWalletClientCash ? maxWalletClientCash.toString() : ''));
          dispatch(condosActions.setSelectedCondoSearchClientCash({ ...selectedCondoSearchClientCash, selectedPropertyReviewClientCash: maxWalletClientCash }));
        }
      }

      dispatch(weeksReviewBookAction.setWeeksReviewBook({ ...res.data }));
      
      dispatch(weeksReviewBookAction.setUpdateWeeksPriceReview(false));
      dispatch(weeksReviewBookAction.setLoadingWeeksBook(false));

      const balance = res.data?.bookingCard?.balance;
      const checkIn = res.data?.bookingCard?.checkIn ? moment(res.data?.bookingCard?.checkIn, 'yyyy-MM-DD').format('yyyy-MM-DD') : null;
      const checkOut = res.data?.bookingCard?.checkOut ? moment(res.data?.bookingCard?.checkOut, 'yyyy-MM-DD').format('yyyy-MM-DD') : null;
      const travelers = res.data?.bookingCard?.totalGuests ? res.data?.bookingCard?.totalGuests : 1;
      const destinationCountry = res.data?.bookingCard?.countryCode ? res.data?.bookingCard?.countryCode : null;

      const addressFromStorage: any = getCondoAddressFromStorage();
      const country = addressFromStorage ? addressFromStorage.country ? addressFromStorage.country : card?.country : card?.country;
      const state = addressFromStorage ? addressFromStorage.state ? addressFromStorage.state : card?.state : card?.state;

      dispatch(insuranceActions.setBaseRequest({
        sessionId: sessionKey?.value,
        totalPrice: {
          isoCurrencyCode: USD_CURRENCY,
          value: balance
        },
        booking: {
          startDateTime: checkIn,
          endDateTime: checkOut,
          destinationIsoCountryCode: 'US'
        }  
      }));
      dispatch(insuranceActions.setTravelersRequest({ numberOfTravelers: 1 } as IInsuranceRequestTravelers));
  
      if (country === 'US' && !items?.isRemoveInsurance) {
        const isUSState = USA_STATES.map(s => s.postalCode).includes(state);

        dispatch(getInsuranceQuote(balance, USD_CURRENCY, checkIn, checkOut, isUSState ? state : null, travelers, destinationCountry, sessionKey?.value));
      }


    } catch (error: any) {
      console.error(error);
      const errorObject = JSON.parse(error?.response?.data);
      const errorCode = get(errorObject, 'code', null);
      const errorReason = get(errorObject, 'reason', error.toString());

      dispatch(weeksReviewBookAction.setErrorWeeksBook(errorReason));
      dispatch(weeksReviewBookAction.setLoadingWeeksBook(false));
      dispatch(weeksReviewBookAction.setUpdateWeeksPriceReview(false));

      dispatch(weeksReviewBookAction.setWeeksExternalError(errorCode === 'error'));

      if (BookingErrorsEnum.SoldOut === errorCode) {
        dispatch(weeksReviewBookAction.setBookingErrorCodeError(BookingErrorsEnum.SoldOut));
      }
      if (BookingErrorsEnum.RoomsUnavailable === errorCode) {
        dispatch(weeksReviewBookAction.setBookingErrorCodeError(BookingErrorsEnum.RoomsUnavailable));
      }

      if (
        error?.response?.status === SESSION_EXPIRED_STATUS &&
        error?.response?.data[zeroItem] === SESSION_SEARCH_EXPIRED_MESSAGE
      ) {
        dispatch(weeksReviewBookAction.setWeeksExpiredSession(true));
      }
    }
  };
};

export const getWeeksBookPackageWithCard = (bookWithCard: ICondoBookPackageWithCard, onErrorCallback: (errorCode: string) => void): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(weeksReviewBookAction.setLoadingWeeksBooking(true));
    dispatch(weeksReviewBookAction.setBookingErrorCodeError(null));
    dispatch(weeksReviewBookAction.setIsWeeksBookingPending(false));
    dispatch(weeksReviewBookAction.setWeeksExpiredSession(false));
    dispatch(setThreeDSModalVisible(false));
    dispatch(setThreeDSUrl(null));
    dispatch(setThreeDSId(null));
    dispatch(setThreeDSLoading(false));

    const { insuranceStore, weeksReviewBookStore } = getState();
    const { selection, insurance } = insuranceStore;
    const allocation = weeksReviewBookStore?.weeksBookingSummary?.bookingCard?.allocation;

    const postData: ICondoBookPackageWithCard = {
      ...bookWithCard,
      guests: bookWithCard.guests.map((guest: ICondoGuestBooking) => {
        return {
          ...guest,
          allocation,
          givenName: guest.givenName.trim(),
          surname: guest.surname.trim(),
        };
      }),
    };

    if (selection === InsuranceSelection.YES && insurance?.quoteId) {
      postData.insuranceQuoteId = insurance?.quoteId;
    }

    try {
      const res = await axiosInstance.post(Urls.WeeksBook, postData, {
        ...getHeaders(),
      });
      dispatch(weeksReviewBookAction.setLoadingWeeksBooking(false));

      const bookingResponse = res?.data;
      if (bookingResponse?.chargeStatus === 'Pending3DS') {
        dispatch(weeksReviewBookAction.setLoadingWeeksBooking(false));
        dispatch(setThreeDSModalVisible(true));
        dispatch(setThreeDSId(bookingResponse?.bookingGuid));
        dispatch(setThreeDSUrl(bookingResponse?.urlRedirectTo3DS));
        dispatch(setThreeDSLoading(false));
        return;
      }

      dispatch(handleProcessBookingComplete(bookingResponse));

    } catch (error) {
      dispatch(handleErrorBookingComplete(error, onErrorCallback));
    }
  };
};

export const getWeeksBookPackageWithCard3DS = (sessionKey: ISessionKey): AppThunk => {
  return async (dispatch, getState) => {
    const { reviewBookStore } = getState();
    const { threeDSId } = reviewBookStore;

    dispatch(setThreeDSLoading(true));

    try {
      const res = await axiosInstance.post(Urls.WeeksBookResume, { bookingId: threeDSId, sessionKey }, { ...getHeaders() });
      dispatch(handleProcessBookingComplete(res?.data));

      dispatch(setThreeDSModalVisible(false));
      dispatch(setThreeDSUrl(null));
      dispatch(setThreeDSId(null));
      dispatch(setThreeDSLoading(false));
    } catch (error) {
      dispatch(handleErrorBookingComplete(error, null));
    } finally {
      dispatch(setThreeDSModalVisible(false));
      dispatch(setThreeDSUrl(null));
      dispatch(setThreeDSId(null));
      dispatch(setThreeDSLoading(false));

      window.scrollTo(0, 0);
    }
  };
};

const handleProcessBookingComplete = (data: any): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(weeksReviewBookAction.setLoadingWeeksBooking(true));

    const { navigationMenuStore, loginStore, weeksReviewBookStore } = getState();
    const selectedClientCash = weeksReviewBookStore?.selectedClientCash;

    if (data.status === STATUS_SUCCEEDED) {
      dispatch(weeksReviewBookAction.setWeeksBookingComplete(true));
      localStorage.setItem(WEEKS_BOOKING_GUEST_INFO_LABEL, '');
      localStorage.setItem(WEEKS_BOOKING_CARD_INFO_LABEL, '');
      localStorage.setItem(WEEKS_BOOKING_SAME_ADDRESS_INFO_LABEL, '');

      const { account, userWallet } = loginStore;

      if (navigationMenuStore?.items?.promo) {
        UrlUtils.removeFromUrl(WEEKS_UNITS_SESSION_KEY_LABEL);
        UrlUtils.removeFromUrl(WEEKS_SESSION_KEY_LABEL);
        UrlUtils.removeFromUrl(WEEKS_UNITS_SEARCH_LABEL);
      }

      dispatch(weeksReviewBookAction.setSelectedWeeksReviewClientCash(null));
      dispatch(weeksReviewBookAction.setSelectedWeeksReviewClientCashStr(''));
      dispatch(condosActions.setSelectedCondoSearchClientCash(null));
      dispatch(getUserWallet(userWallet));

      ReactGA.event({
        category: account.name,
        action: `${C_C_BOOKING_CONFIRMED}_${account.name.toUpperCase()}`,
        label: `Condo Booking confirmed on book`,
        nonInteraction: false,
      });

    } else if (data.status === STATUS_FAILED) {
      if (data.error?.errorCode) {
        const { errorCode } = data.error;

        dispatch(weeksReviewBookAction.setBookingErrorCodeError(errorCode));
      } else {
        dispatch(weeksReviewBookAction.setBookingErrorCodeError(BookingErrorsEnum.UnexpectedResponse));
      }
    } else if (data.status === STATUS_PENDING) {
      dispatch(weeksReviewBookAction.setIsWeeksBookingPending(true));
    } else {
      dispatch(weeksReviewBookAction.setWeeksBookingInProgress(true));
    }

    const booking = data;
    if (booking) {
      booking.clientCash = selectedClientCash;
    }
    dispatch(weeksReviewBookAction.setWeeksBooking(booking));
    dispatch(weeksReviewBookAction.setLoadingWeeksBooking(false));

    window.scrollTo(0, 0);
  }
}

const handleErrorBookingComplete = (error: any, onErrorCallback: any): AppThunk => {
  return async (dispatch) => {
    dispatch(weeksReviewBookAction.setLoadingWeeksBooking(false));
    dispatch(setThreeDSModalVisible(false));
    dispatch(setThreeDSUrl(null));
    dispatch(setThreeDSId(null));
    dispatch(setThreeDSLoading(false));

    if (error?.response?.data && error?.response?.data[zeroItem].errorType === VALIDATION_ERROR) {
      dispatch(weeksReviewBookAction.setErrorsWeeksField(error.response.data));
    } else if (
      error?.response?.data &&
      error?.response?.data[zeroItem].errorType === EXTERNAL_ERROR
    ) {
      dispatch(weeksReviewBookAction.setWeeksExternalError(true));
    } else if (
      error?.response?.data &&
      error?.response?.data[zeroItem].errorCode === PRICE_CHANGED
    ) {
      dispatch(weeksReviewBookAction.setWeeksPriceChangedError(true));
    } else if (error?.response?.data && error?.response?.data[zeroItem].errorType === ERROR) {
      dispatch(weeksReviewBookAction.setWeeksErrorsBookingMessage(error.response.data));
    } else if (error?.response?.data[zeroItem].errorCode === SAVING_MISMATCH) {
      dispatch(weeksReviewBookAction.setWeeksExpiredSession(true));
      dispatch(weeksReviewBookAction.setWeeksSavingsMismatch(true));
    } else {
      const errorObject = isString(error?.response?.data) ? JSON.parse(error?.response?.data) : error?.response?.data;
      const errorMessage = get(errorObject, 'code', null);
      const errorReason = get(errorObject, 'reason', error.toString());

      const paymentErrors = [CHECK_OUT_PAYMENT_ERROR, DECLINE_PAYMENT_ERROR, STATE_PAYMENT_ERROR, POSTAL_CODE_PAYMENT_ERROR];
      const headerError = [BookingErrorsEnum.General, BookingErrorsEnum.RoomsUnavailable, BookingErrorsEnum.SoldOut];
      const errors = [BookingErrorsEnum.InvalidPayload, BookingErrorsEnum.CheckoutError]; 
      if (paymentErrors.includes(errorMessage)) {
        dispatch(weeksReviewBookAction.setBookingErrorCodeError(errorReason));
        onErrorCallback ? onErrorCallback(errorMessage) : null;
      } else if (headerError.includes(errorMessage)) {
        dispatch(weeksReviewBookAction.setBookingErrorCodeError(errorMessage));
        onErrorCallback ? onErrorCallback(errorMessage) : null;
      } else if (errors.includes(errorMessage)) {
          dispatch(weeksReviewBookAction.setBookingErrorCodeError(errorReason));
      } else {
        dispatch(weeksReviewBookAction.setWeeksErrorBooking(errorMessage ? `error.code.${errorMessage}` : errorReason));
      }
    }
  }
}