export const namesPrefix = [
  {
    id: 1,
    namePrefix: 'Mr.',
  },
  {
    id: 2,
    namePrefix: 'Mrs.',
  },
  {
    id: 3,
    namePrefix: 'Ms.',
  },
];

export enum CardType {
  Electron = 'electron',
  Maestro = 'maestro',
  Dankort = 'dankort',
  Interpayment = 'interpayment',
  Unionpay = 'unionpay',
  Visa = 'visa',
  Mastercard = 'mastercard',
  Amex = 'AmericanExpress',
  Diners = 'diners',
  Discover = 'discover',
  Jcb = 'jcb',
}
