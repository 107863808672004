import React from 'react';
import { FormattedMessage } from 'react-intl';

import { BlueButton } from '@share/components';

import './style.scss';

interface IProps {
  isGetCancellationLink: boolean;
  onClickCancellationLink?: () => void;
}

export class CancellationLink extends React.Component<IProps> {
  render(): React.ReactNode {
    const { onClickCancellationLink, isGetCancellationLink } = this.props;

    return (
      <div className={`cancellation-link ${!isGetCancellationLink ? 'position-center' : ''}`}>
        <div className="cancellation-link__info-wrapper">
          <p className="cancellation-link__title secondary-font">
            {isGetCancellationLink ? (
              <FormattedMessage id="cancellation.link.has.been.sent" />
            ) : (
              <FormattedMessage id="get.cancellation.link.via.email" />
            )}
          </p>
          <p className="cancellation-link__description">
            {isGetCancellationLink ? (
              <FormattedMessage id="temporary.link.has.been.sent" />
            ) : (
              <FormattedMessage id="request.temporary.cancellation.link" />
            )}
          </p>
          {!isGetCancellationLink ? (
            <div className="cancellation-link__btn-wrapper">
              <BlueButton onClick={onClickCancellationLink}>
                <FormattedMessage id="get.cancellation.link" />
              </BlueButton>
            </div>
          ) : null}
        </div>
        <div className="cancellation-link__footer">
          <p className="cancellation-link__footer-text">
            <FormattedMessage id="case.link.has.expired" />
          </p>
        </div>
      </div>
    );
  }
}
