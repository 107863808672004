import React from 'react';
import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { ILoginState } from '@share/store/slices';
import { RootState } from '@share/utils';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {
  visible: boolean;
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>, isScrollToRooms?: boolean) => void;
}

class ModalPricesComponent extends React.Component<IProps> {

  render(): React.ReactNode {
    const { visible, loginStore, onCancel } = this.props;
    const { account } = loginStore;

    const fontFamily = account?.fontFamily;
    const secondaryFontFamily = account?.secondaryFontFamily;
  
      return (
      <Modal
        className={`modal-prices ${fontFamily ? `${fontFamily}-font`: ''} ${secondaryFontFamily ? `secondary-${secondaryFontFamily}-font`: ''}`}
        open={visible}
        footer={null}
        onCancel={onCancel}
        wrapClassName="modal-prices__wrapper"
      >
        <div>
          <h4 className="modal-prices__title secondary-font">
            <FormattedMessage id="price.and.taxes" />
          </h4>
          <div className="modal-prices__price">
            <p className="modal-prices__price-description">
              <FormattedMessage id="price.and.taxes.description" />
            </p>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

export const ModalPrices = connect(mapStateToProps)(ModalPricesComponent);
