import React, { Component } from 'react';

import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { LinkImage } from '@components';

import { ModalExperiencesSearch, SearchType } from './modal-experiences-search';

import Exp1 from '@assets/images/experiences/exp1.jpg';
import Exp2 from '@assets/images/experiences/exp2.jpg';
import Exp3 from '@assets/images/experiences/exp3.jpg';
import Exp5 from '@assets/images/experiences/exp5.jpg';
import Exp6 from '@assets/images/experiences/exp6.jpg';
import ExpMain from '@assets/images/experiences/expMain.jpg';
import ExpMain2 from '@assets/images/experiences/expMain2.jpg';
import ExpMain3 from '@assets/images/experiences/expMain3.jpg';

import './style.scss';

interface IProps extends WrappedComponentProps, RouteComponentProps {}

interface IState {
  visible: boolean;
  searchType: SearchType;
}

class ExperiencesComponent extends Component<IProps, IState> {

  state: IState = { visible: false, searchType: null };

  render(): React.ReactNode {
    const { intl } = this.props;
    const { visible, searchType } = this.state;

    return (
      <div className="experiences">

        <div className="experiences__main-banner" style={{ backgroundImage: `linear-gradient(to bottom, rgba(46, 45, 235, 0.59) 0%, rgba(46, 45, 235, 0.59) 100%), url(${ExpMain})`}}>
          <span><FormattedMessage id="experience.main.title" /></span>
        </div>

        <div className="experiences__condos">
          <div className="experiences__condos-title"><FormattedMessage id="experience.condos.title" /></div>

          <div className="separator"></div>

          <div className="experiences__condos-images">
            <LinkImage
              text={intl.formatMessage({ id: 'experience.condos.message1' })}
              text2={intl.formatMessage({ id: 'experience.condos.message1.price' })}
              image={Exp1} secondaryText={intl.formatMessage({ id: 'experience.condos.message' })}
              onClick={() => this.setState({ visible: true, searchType: SearchType.Condos1 })}
            />
            <LinkImage
              text={intl.formatMessage({ id: 'experience.condos.message2' })}
              text2={intl.formatMessage({ id: 'experience.condos.message2.price' })}
              image={Exp2} secondaryText={intl.formatMessage({ id: 'experience.condos.message' })}
              onClick={() => this.setState({ visible: true, searchType: SearchType.Condos2 })}
            />
            <LinkImage
              text={intl.formatMessage({ id: 'experience.condos.message3' })}
              text2={intl.formatMessage({ id: 'experience.condos.message3.price' })}
              image={Exp3} secondaryText={intl.formatMessage({ id: 'experience.condos.message' })}
              onClick={() => this.setState({ visible: true, searchType: SearchType.Condos3 })}
            />
          </div>
        </div>

        <div className="experiences__banner">
          <img src={ExpMain2} alt="" />
        </div>

        <div className="experiences__hotels-banner" style={{ backgroundImage: `url(${ExpMain3})` }}>
          <div className="background"></div>
          <div className="text"><FormattedMessage id="experience.hotels.message" /></div>
        </div>

        <div className="experiences__stars">
          <div className="experiences__stars-title"><FormattedMessage id="experience.hotels.title" /></div>

          <div className="separator"></div>

          <div className="experiences__stars-images">
            <LinkImage
              text={intl.formatMessage({ id: 'experience.hotels.message1.title' })}
              text2={intl.formatMessage({ id: 'experience.hotels.message1' })}
              image={Exp5} secondaryText={intl.formatMessage({ id: 'experience.hotels.message1.message' })}
              onClick={() => this.setState({ visible: true, searchType: SearchType.Hotels1 })}
            />
            <LinkImage
              text={intl.formatMessage({ id: 'experience.hotels.message2.title' })}
              text2={intl.formatMessage({ id: 'experience.hotels.message2' })}
              image={Exp5} secondaryText={intl.formatMessage({ id: 'experience.hotels.message2.message' })}
              onClick={() => this.setState({ visible: true, searchType: SearchType.Hotels2 })}
            />
            <LinkImage
              text={intl.formatMessage({ id: 'experience.hotels.message3.title' })} 
              text2={intl.formatMessage({ id: 'experience.hotels.message3' })} 
              image={Exp6} secondaryText={intl.formatMessage({ id: 'experience.hotels.message3.message' })}
              onClick={() => this.setState({ visible: true, searchType: SearchType.Hotels3 })}
            />
          </div>
        </div>

        <ModalExperiencesSearch
          visible={visible}
          type={searchType}
          onCancel={() => this.setState({ visible: false, searchType: null })}
        />
      </div>
    );
  }
}

export const Experiences = connect()(injectIntl(withRouter(ExperiencesComponent)));