import React from 'react';

export const WifiSvg: React.FunctionComponent = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 9C5.79573 8.3597 6.87483 8 8 8C9.12517 8 10.2043 8.3597 11 9"
      stroke="#292929"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 7C3.6566 6.36592 4.4361 5.86295 5.29401 5.51978C6.15191 5.17662 7.07141 5 8 5C8.92859 5 9.84809 5.17662 10.706 5.51978C11.5639 5.86295 12.3434 6.36592 13 7"
      stroke="#292929"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="8" cy="11" r="1" fill="#292929" />
  </svg>
);
