import React from 'react';

import { Checkbox } from 'antd';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { StarSvg } from '@assets';
import { RootState } from '@share/utils';
import { ILoginState } from '@share/store/slices';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IOptions {
  value: number;
}

interface IProps extends IMapStateToProps {
  title: string;
  options: IOptions[];
  onChange: (checkedValues: any) => void;
  value: number[];
  disabled?: boolean;
}

class FilterStarsComponent extends React.Component<IProps> {

  render(): React.ReactNode {
    const { title, options, onChange, value, disabled = false, loginStore } = this.props;
    const { account } = loginStore;

    const generalBorderRadius: any = account?.generalBorderRadius ? account?.generalBorderRadius : null;
    const style = !isEmpty(generalBorderRadius) ? { borderRadius: generalBorderRadius } : {};  

    return (
      <div className="filter-starts">
        <p className="filter-starts__title secondary-font">
          <FormattedMessage id={title} />
        </p>
        <Checkbox.Group value={value} onChange={onChange} disabled={disabled}>
          {options.map((item: IOptions) => (
            <div key={item.value}>
              <Checkbox value={item.value} disabled={disabled}>
                <span className="filter-starts__option-name" style={style}>
                  {item.value} <StarSvg />
                </span>
              </Checkbox>
            </div>
          ))}
        </Checkbox.Group>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

export const FilterStars = connect(mapStateToProps)(FilterStarsComponent);
