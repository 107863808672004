import React from 'react';
import ReactGA from 'react-ga4';
import moment from 'moment';

import { Action } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import { SearchTypeEnum, ILocation, IAccount } from '@share/common-types';
import { RootState, UrlUtils, LoginType } from '@share/utils';
import {
  carsFiltersActions,
  carsActions,
  carsDatesActions,
  GetCars,
  carsDriverActions,
} from '@share/store/slices';
import { ILoginState } from '@share/store/slices';
import { CARS_FILTERS_LABEL, CAR_RECENT_SEARCHES_LABEL, C_H_CLICK_RECENT_SEARCH, DEFAULT_PAGE_NUMBER } from '@share/constants';
import { carsLocationsActions } from '@share/store/slices';
import { NULL_VALUE } from '@constants';

import { CarsRecentSearchesItem } from '../cars-recent-searches/component';

import './style.scss';

export type CarsSearchHandlerArgs = (
  pickUpLocation: ILocation,
  dropOffLocation: ILocation,
  startDate?: string,
  endDate?: string,
  startDateTime?: string,
  endDateTime?: string,
  driverAge?: number,
  residency?: string,
  simpleType?: string[],
  rateDistance?: string[],
  rentalCompany?: string[],
  fuel?: string[],
) => void;

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getCars: () => void;

  setIsSearch: () => void;
  resetFilters: () => void;
  setPageNumber: () => void;

  setDates: (dates: { startDate: string; endDate: string }) => void;
  setTimes: (dates: { startDateTime: string; endDateTime: string; }) => void;

  setSelectPickUpLocation: (location: ILocation) => void;
  setSelectPickUpLocationLabel: (label: string) => void;
  setSelectDropOffLocation: (location: ILocation) => void;
  setSelectDropOffLocationLabel: (label: string) => void;

  setDriverCountry: (country: string) => void;
  setDriverAge: (age: number) => void;

  setSimpleTypeQuick: (values: string[]) => void;
  setRateDistanceQuick: (values: string[]) => void;
  setRentalCompanyQuick: (values: string[]) => void;
  setFuelQuick: (values: string[]) => void;

  resetLocationError: () => void;
  resetDatesError: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {}

class CarsRecentSearchesComponent extends React.Component<IProps> {

  onSearchHandler: CarsSearchHandlerArgs = (
    pickUpLocation,
    dropOffLocation,
    startDate,
    endDate,
    startDateTime,
    endDateTime,
    driverAge,
    countryISO,
    simpleType,
    rateDistance,
    rentalCompany,
    fuel,
  ) => {
    const {
      loginStore,
      getCars,
      setIsSearch,
      resetFilters,
      setPageNumber,
      setDates,
      setTimes,
      setSelectPickUpLocation,
      setSelectPickUpLocationLabel,
      setSelectDropOffLocation,
      setSelectDropOffLocationLabel,
      resetLocationError,
      resetDatesError,
      setDriverCountry,
      setDriverAge,

      setSimpleTypeQuick,
      setRateDistanceQuick,
      setRentalCompanyQuick,
      setFuelQuick,
    } = this.props;

    setPageNumber();
    setIsSearch();

    resetFilters();
    resetLocationError();
    resetDatesError();

    UrlUtils.setUrl(CARS_FILTERS_LABEL, null);

    setSelectPickUpLocationLabel(pickUpLocation.name);
    setSelectPickUpLocation(pickUpLocation);

    const isSmaeLocation = dropOffLocation?.code === pickUpLocation?.code;

    setSelectDropOffLocationLabel(!isSmaeLocation ? dropOffLocation.name : '');
    setSelectDropOffLocation(!isSmaeLocation ? dropOffLocation : NULL_VALUE);

    setDates({ startDate: startDate as string, endDate: endDate as string });
    setTimes({ startDateTime: startDateTime as string, endDateTime: endDateTime as string });

    setDriverAge(driverAge as number);
    setDriverCountry(countryISO as string);

    setSimpleTypeQuick(simpleType as string[]);
    setRateDistanceQuick(rateDistance as string[]);
    setRentalCompanyQuick(rentalCompany as string[]);
    setFuelQuick(fuel as string[]);

    getCars();

    const { account } = loginStore;

    ReactGA.event({
      category: account?.name as string,
      action: `${C_H_CLICK_RECENT_SEARCH}_${account?.name.toUpperCase()}`,
      label: `User clicked recent search`,
      nonInteraction: false,
    });
  };

  render(): React.ReactNode {
    const { loginStore } = this.props;
    const { loading, account } = loginStore;

    const recentSearchesStorage = localStorage.getItem(CAR_RECENT_SEARCHES_LABEL);
    let recentSearches = (recentSearchesStorage && !isEmpty(recentSearchesStorage)) ? JSON.parse(recentSearchesStorage) : [];
    if (recentSearches?.length) {

      const leadTime = account?.leadTime;
      if (leadTime) {
        recentSearches = recentSearches.filter((s: any) => {
          return (moment(s?.carRequest?.pickUpDate, 'yyyy-MM-DD')).isSameOrAfter(moment().add(leadTime, 'days'))
        });
      }
      recentSearches = recentSearches.slice(0, 4);
    }

    const hasPublicAccessToken = account?.hasPublicAccessToken;
    const hasPublicValidToken = account?.type !== LoginType.Public || !hasPublicAccessToken || !isEmpty(loginStore.accessToken);

    if (!hasPublicValidToken) {
      return null;
    }

    return recentSearches.length ? (
      <div className="cars-recents">
        <h2 className="cars-recents__title secondary-font">
          <FormattedMessage id="recents.title" />
        </h2>
        <div className="cars-recents__wrapper">
          {recentSearches?.map((item: any, index: number) => (
            <CarsRecentSearchesItem
              data={item}
              account={account as IAccount}
              disabled={loading || !hasPublicValidToken}
              key={`${item.pickUpLocation.code} ${index}`}
              onSearchHandler={this.onSearchHandler}
            />
          ))}
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  resetFilters: () => {
    dispatch(carsFiltersActions.resetFilters(false));
  },

  setSelectPickUpLocation: (location: ILocation) => {
    dispatch(carsLocationsActions.setSelectPickUpLocation(location));
  },
  setSelectPickUpLocationLabel: (label: string) => {
    dispatch(carsLocationsActions.setSelectPickUpLocationLabel(label));
  },
  setSelectDropOffLocation: (location: ILocation) => {
    dispatch(carsLocationsActions.setSelectDropOffLocation(location));
  },
  setSelectDropOffLocationLabel: (label: string) => {
    dispatch(carsLocationsActions.setSelectDropOffLocationLabel(label));
  },
  resetLocationError: () => {
    dispatch(carsLocationsActions.setError(''));    
  },

  setDates: (dates: { startDate: string; endDate: string }) => {
    dispatch(carsDatesActions.setDatesSelected(dates));
  },
  setTimes: (dates: { startDateTime: string; endDateTime: string; }) => {
    dispatch(carsDatesActions.setTimesSelected(dates));
  },
  resetDatesError: () => {
    dispatch(carsDatesActions.setError(''));
  },

  setSimpleTypeQuick: (values: string[]) => {
    dispatch(carsFiltersActions.setSimpleTypeQuick(values));
  },
  setRateDistanceQuick: (values: string[]) => {
    dispatch(carsFiltersActions.setRateDistanceQuick(values));
  },
  setRentalCompanyQuick: (values: string[]) => {
    dispatch(carsFiltersActions.setRentalCompanyQuick(values));
  },
  setFuelQuick: (values: string[]) => {
    dispatch(carsFiltersActions.setFuelQuick(values));
  },

  setDriverCountry: (country: string) => {
    dispatch(carsDriverActions.setDriverCountrySelected(country));
  },
  setDriverAge: (age: number) => {
    dispatch(carsDriverActions.setDriverAgeSelected(age?.toString()));
  },

  getCars: () => {
    dispatch(GetCars(SearchTypeEnum.NewSearch));
  },
  setPageNumber: () => {
    dispatch(carsActions.setPageNumber(DEFAULT_PAGE_NUMBER));
  },
  setIsSearch: () => {
    dispatch(carsActions.setIsSearch(true));
  },
});

export const CarsRecentSearches = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarsRecentSearchesComponent);
