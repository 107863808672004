
import { SortTypes } from '@share/constants';
import {
  IImage,
  IRating,
  IRange,
  IGeoLocation,
  ISessionKey,
  ICounterItem,
  IBudgetRange,
  ICondoDateRange,
  CondoMatchTypeEnum,
  ICondoGuests,
  ICondoLocation,
  IBounds,
  DateSearchTypeEnum
} from '@share/common-types';

const ZERO = 0;
const TWO = 2;
const THREE = 3;
const THREE_HALF = 3.5;
const FOUR = 4;
const FOUR_HALF = 4.5;

export interface ICondoUnitWeekDates {
  checkIn: string;
  checkOut: string;
  daysOfStay: number;
  price: number;
}

export const EQUAL_FILTER = 'EQ';
export const LOWER_FILTER = 'LT';
export const GREATER_FILTER = 'GT';
export const RANGE_FILTER = 'BT';

export interface ICondoWeeksFilters {
  checkInCondition: string;
  fromCheckIn: string;
  toCheckIn: string;
  
  checkOutCondition: string;
  fromCheckOut: string;
  toCheckOut: string;
  
  locationName: string;
  lengthOfStay: number;
  occupancy: number;
  bedrooms: number;
  bathrooms: number;
  
  priceCondition: string;
  startPrice: number;
  endPrice: number;

  applied: boolean;
  loading: boolean;
  locations: string[];
}

export interface ICondoUnitWeek {
  ID: number;
  dates: ICondoUnitWeekDates[];
  locationId: number;
  locationName: string;
  propertyId: number;
  propertyName: string;
  roomDescription: string;
  image: string;

  bedrooms: number;
  bathrooms: number;
  maxOccupancy: number;
}

export interface ICondo {
  condoId: number;
  displayName: string;
  rating: IRating;
  fullAddress: string;
  location: IGeoLocation;
  images: IImage[];
  condoType: string;
  sleepsCount: number;
  price?: number;
  pricePerNight?: number;
  savings: number;
  publicPrice?: number;
  availableDates?: ICondoDateRange[];
  matchType: CondoMatchTypeEnum;
  totalNights: number;
  apartmentType: string;
  bathroomsCount: number;
  daysCount: number;
  monthsCount: number;
  maxOccupancy: number;
  privacyOccupancy: number;
  supplierIdentifier?: string;
  isAllInclusive?: boolean;
  supplierType?: string;
}

export interface CondoDestination {
  country: string;
  state: string;
  city: string;
  destination: string;
  area: boolean;
  totalAmount: number;
  location: ICondoLocation;
}

export enum RatingEnum {
  Excellent = FOUR_HALF,
  Great = FOUR,
  Good = THREE_HALF,
  Average = THREE,
  Poor = TWO,
  Bad = ZERO,
}

export enum StayForEnum {
  Week = 'Week',
  TwoWeeks = 'TwoWeeks',
  Month = 'Month',
}

export interface ICondoRequestFilters {
  propertyName: string;
  budgetRanges: IRange[];
  guestRatings: IRange[];
  distanceInMiles: number;
  unitAmenities: string[];
  facilities: string[];
  activities: string[];
  condoTypes: string[];
  policies: string[];
  accessibilities: string[];
  suppliers: string[];
  neighbourhoods: string[];
  places: IPlace[];
  kitchenTypes: string[];
  bathrooms: number[];
  days: number[];
  months: number[];
  isAllInclusiveOnly: boolean;
}

export interface ICondoSearchRequest {
  bounds?: IBounds;
  pageNumber?: number;
  sessionKey?: ISessionKey;
  condosRequest?: {
    location: ICondoLocation;
    checkIn?: string;
    checkOut?: string;
    adultsCount: number;
    childrenCount: number;
    bedroomsCount: number;
    stayDuration?: string;
    months?: string[];
  };
  filter: ICondoRequestFilters;
  sortBy: SortTypes;
  pageSize: number;

  residency?: string;
  currency?: string;
}

export interface IGustRating {
  key: {
    title: string;
    from: number;
    to: number;
  };
  count: number;
}

export interface ICounter<T> {
  key: T;
  count: number;
}

export interface IPlace {
  city: string;
  state?: string;
  country: string;
  title: string;
}

export interface ICondoCounters {
  unitAmenities: ICounterItem[];
  budgetRanges: IBudgetRange[];
  neighbourhoods: ICounterItem[];
  places: ICounter<IPlace>[];
  facilities: ICounterItem[];
  activities: ICounterItem[];
  accessibilities: ICounterItem[];
  bathrooms: ICounterItem[];
  days: ICounterItem[];
  months: ICounterItem[];
  condoTypes: ICounterItem[];
  kitchenTypes: ICounterItem[];
  totalCondos: number;
  totalFilteredExactCondos: number;
  totalFilteredCondos: number;
  totalAlternativeCondos: number;
  totalFilteredAlternativeCondos: number;
  totalOptions: number;
  totalFilteredOptions: number;
  totalAlternativeOptions: number;
  totalFilteredExactOptions: number;
  totalFilteredAlternativeOptions: number;
  guestRatings: IGustRating[];
  allInclusiveCount: number;
}

export interface ICondoSearchResponse {
  searchCondos: ICondo[];
  sessionKey: ISessionKey;
  bounds: IBounds;
  counters: ICondoCounters;
}

export enum SearchTypeEnum {
  NewSearch = 'NewSearch',
  Pagination = 'Pagination',
  MapSearch = 'MapSearch',
  SortsFilters = 'SortsFilters',
}

export interface ICondoRequest {
  residency?: string; // hardcode 'US' for now
  condoIds?: (number | string)[]; // if LocationsTypes.Hotel
  location?: IGeoLocation; // if LocationsTypes.Airport or LocationsTypes.Destination
  checkIn: string; // date in ISO format
  checkOut: string; // date in ISO format
  radiusInMeters?: number;
  guests: ICondoGuests;
}

export interface ICondoRecentSearches {
  months?: string[];
  checkIn?: string;
  checkOut?: string;
  residency: string;
  adultsCount: number;
  childrenCount: number;
  bedroomsCount: number;
  location: ICondoLocation;
  stayDuration?: StayForEnum;
  searchType: DateSearchTypeEnum;
}

export enum CondoRequestTypeEnum {
  Strict = 'Strict',
  Flexible = 'Flexible',
  Anytime = 'Anytime',
}

export interface ICondoUnitsRequest {
  checkIn?: string;
  checkOut?: string;
  months?: string[];
  adultsCount: number;
  childrenCount: number;
  bedroomsCount: number;
}

export enum ZipRuleTypeEnum {
  startsWith = 'startsWith',
  range = 'range',
  none = 'none',
}

export enum SurroundingType {
  whatsNearby = 'whatsNearby',
  topAttractions = 'topAttractions',
  closestAirports = 'closestAirports',
}

export enum ProximityType {
  onsite = 'onsite',
  nearby = 'nearby',
  offsite = 'offsite',
}
