import React, { useState } from 'react';
import moment from 'moment';

import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { get, isEmpty } from 'lodash';
import { Dropdown, Input, Menu, Select } from 'antd';

import { EQUAL_FILTER, GREATER_FILTER, ICondoWeeksFilters, LOWER_FILTER, RANGE_FILTER } from '@common-types';
import { BlueButton } from '@share/components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

const CHECKIN_TYPE = 'checkin';
const CHECKOUT_TYPE = 'checkout';
const LOCATION_TYPE = 'locationName';
const LENGTHOFSTAY_TYPE = 'lengthOfStay';
const OCCUPANCY_TYPE = 'occupancy';
const BEDROOMS_TYPE = 'bedrooms';
const BATHROOMS_TYPE = 'bathrooms';
const PRICE_TYPE = 'price';

const NUMBER_TYPE = 'number';
const DROPDOWN_TYPE = 'dropdown';
const DATE_TYPE = 'date';
const TEXT_TYPE = 'text';

const FITLER_LIST = [CHECKIN_TYPE, CHECKOUT_TYPE, LOCATION_TYPE, LENGTHOFSTAY_TYPE, OCCUPANCY_TYPE, BEDROOMS_TYPE, BATHROOMS_TYPE, PRICE_TYPE];

interface IProps extends RouteComponentProps {
  filters: ICondoWeeksFilters;

  onFiltersChange: (filters: ICondoWeeksFilters) => void;
}

function CondoWeeksFiltersComponent(props: IProps) {
  const [showCheckIn, setShowCheckIn] = useState(false);
  const [showCheckOkut, setShowCheckOut] = useState(false);
  const [showLocationName, setShowLocationName] = useState(false);
  const [showLengthOfStay, setShowLengthOfStay] = useState(false);
  const [showOccupancy, setShowOccupancy] = useState(false);
  const [showBedrooms, setShowBedrooms] = useState(false);
  const [showBathrooms, setShowBathrooms] = useState(false);
  const [showPrice, setShowPrice] = useState(false);
  const [condition, setCondition] = useState(EQUAL_FILTER);
  const [conditionValue, setConditionValue] = useState('');
  const [conditionValue2, setConditionValue2] = useState('');

  const { filters, onFiltersChange } = props;
  const { checkInCondition, fromCheckIn, toCheckIn, checkOutCondition, fromCheckOut, toCheckOut, locationName, lengthOfStay, priceCondition, startPrice, endPrice, occupancy, bedrooms, bathrooms } = filters;
  
  const getShowCheckIn = () => showCheckIn;
  const getShowCheckOkut = () => showCheckOkut;
  const getShowLocationName = () => showLocationName;
  const getShowLengthOfStay = () => showLengthOfStay;
  const getShowOccupancy = () => showOccupancy;
  const getShowBedrooms = () => showBedrooms;
  const getShowBathrooms = () => showBathrooms;
  const getShowPrice = () => showPrice;

  const getCheckIn = () => (!isEmpty(fromCheckIn) && !isEmpty(toCheckIn) && fromCheckIn !== toCheckIn) ? `${fromCheckIn} - ${toCheckIn}` : (!isEmpty(toCheckIn)) ? toCheckIn : fromCheckIn;
  const getCheckOkut = () => (!isEmpty(fromCheckOut) && !isEmpty(toCheckOut) && fromCheckOut !== toCheckOut) ? `${fromCheckOut} - ${toCheckOut}` : (!isEmpty(toCheckOut)) ? toCheckOut : fromCheckOut;
  const getLocationName = () => locationName;
  const getLengthOfStay = () => lengthOfStay;
  const getOccupancy = () => occupancy;
  const getBedrooms = () => bedrooms;
  const getBathrooms = () => bathrooms;
  const getPrice = () => (!isEmpty(startPrice) && !isEmpty(endPrice) && startPrice !== endPrice) ? `${startPrice} - ${endPrice}` : (!isEmpty(endPrice)) ? endPrice?.toString() : startPrice?.toString();

  const MAPPING_TYPE = {
    [CHECKIN_TYPE]: { isRange: true, title: 'check.in', inputType: DATE_TYPE, placeholder: 'Check-In' },
    [CHECKOUT_TYPE]: { isRange: true, title: 'check.out', inputType: DATE_TYPE, placeholder: 'Check-Out' },
    [LOCATION_TYPE]: { isRange: false, title: 'location', inputType: TEXT_TYPE, placeholder: 'Location Name' },
    [LENGTHOFSTAY_TYPE]: { isRange: false, title: 'length.stay', inputType: NUMBER_TYPE, placeholder: 'Length of Stay' },
    [OCCUPANCY_TYPE]: { isRange: false, title: 'max.occupancy', inputType: NUMBER_TYPE, placeholder: 'Occupancy' },
    [BEDROOMS_TYPE]: { isRange: false, title: 'bedrooms', inputType: NUMBER_TYPE, placeholder: 'Bedrooms' },
    [BATHROOMS_TYPE]: { isRange: false, title: 'bathrooms', inputType: NUMBER_TYPE, placeholder: 'Bathrooms' },
    [PRICE_TYPE]: { isRange: true, title: 'price', inputType: NUMBER_TYPE, placeholder: 'Price' },
  }

  const MAPPING_VALUE_TYPE = {
    [CHECKIN_TYPE]: { setVisible: setShowCheckIn, getVisible: getShowCheckIn, getValueStr: getCheckIn, getValue: () => fromCheckIn, getValue2: () => toCheckIn, getCondition: () => checkInCondition },
    [CHECKOUT_TYPE]: { setVisible: setShowCheckOut, getVisible: getShowCheckOkut, getValueStr: getCheckOkut, getValue: () => fromCheckOut, getValue2: () => toCheckOut, getCondition: () => checkOutCondition },
    [LOCATION_TYPE]: { setVisible: setShowLocationName, getVisible: getShowLocationName, getValueStr: getLocationName, getValue: () => locationName },
    [LENGTHOFSTAY_TYPE]: { setVisible: setShowLengthOfStay, getVisible: getShowLengthOfStay, getValueStr: getLengthOfStay, getValue: () => lengthOfStay },
    [OCCUPANCY_TYPE]: { setVisible: setShowOccupancy, getVisible: getShowOccupancy, getValueStr: getOccupancy, getValue: () => occupancy },
    [BEDROOMS_TYPE]: { setVisible: setShowBedrooms, getVisible: getShowBedrooms, getValueStr: getBedrooms, getValue: () => bedrooms },
    [BATHROOMS_TYPE]: { setVisible: setShowBathrooms, getVisible: getShowBathrooms, getValueStr: getBathrooms, getValue: () => bathrooms },
    [PRICE_TYPE]: { setVisible: setShowPrice, getVisible: getShowPrice, getValueStr: getPrice, getValue: () => startPrice, getValue2: () => endPrice, getCondition: () => priceCondition },
  }

  const resetFlags = (condition: string, value: string, value2: string) => {
    setShowCheckIn(false);
    setShowCheckOut(false);
    setShowLocationName(false);
    setShowLengthOfStay(false);
    setShowPrice(false);
    setShowOccupancy(false);
    setShowBedrooms(false);
    setShowBathrooms(false);
    setCondition(!isEmpty(condition) ? condition : EQUAL_FILTER);
    setConditionValue(!isEmpty(value) ? value : '');
    setConditionValue2(!isEmpty(value2) ? value2 : '');
  }

  const handleApply = (type: string) => {
    const newFilters = { ...filters };

    if (type === CHECKIN_TYPE) {
      newFilters.checkInCondition = condition;
      newFilters.fromCheckIn = conditionValue;
      newFilters.toCheckIn = conditionValue2;
    }
    if (type === CHECKOUT_TYPE) {
      newFilters.checkOutCondition = condition;
      newFilters.fromCheckOut = conditionValue;
      newFilters.toCheckOut = conditionValue2;
    }
    if (type === LOCATION_TYPE) {
      newFilters.locationName = conditionValue;
    }
    if (type === LENGTHOFSTAY_TYPE) {
      newFilters.lengthOfStay = Number(conditionValue);
    }
    if (type === OCCUPANCY_TYPE) {
      newFilters.occupancy = Number(conditionValue);
    }
    if (type === BEDROOMS_TYPE) {
      newFilters.bedrooms = Number(conditionValue);
    }
    if (type === BATHROOMS_TYPE) {
      newFilters.bathrooms = Number(conditionValue);
    }
    if (type === PRICE_TYPE) {
      newFilters.priceCondition = condition;
      newFilters.startPrice = Number(conditionValue);
      newFilters.endPrice = Number(conditionValue2);
    }

    onFiltersChange(newFilters);
    resetFlags(null, null, null);
  }

  const renderFilterInput = (display: boolean, inputType: string, value: string, placeholder: string, options: string[], setValue: (value: string) => void) => {
    if (!display) {
      return null;
    }

    if (inputType === DROPDOWN_TYPE) {
      return (
        <Select value={value} onChange={e => setValue(e)} allowClear>
          {options?.map((o, i) => <Select.Option key={`index_${i}`} value={o}>{o}</Select.Option>)}
        </Select>);
    }

    return (
      <Input
        value={value ? value : ''}
        placeholder={`${placeholder}`}
        type={inputType}
        allowClear
        onChange={(e) => {
          setValue(e.target.value?.toString());
        }}
      ></Input>
    );
  }

  const renderCondoFilterItem = (type: string) => {
    const { isRange, inputType, placeholder, title } = get(MAPPING_TYPE, `${type}`);
    const { setVisible, getVisible, getValueStr, getValue, getValue2, getCondition } = get(MAPPING_VALUE_TYPE, `${type}`);
    
    const valueStr = getValueStr();
    const value = getValue();
    const value2 = getValue2 ? getValue2() : null;
    const conditionSelected = getCondition ? getCondition() : null;
    const visible = getVisible();

    const menu = (
      <Menu>
        <Menu.Item>
          <div className="condo-weeks-filters__item-content">
            {isRange ? (
              <Select value={condition} onChange={(e) => {
                setCondition(e);
              }}>
                <Select.Option value={EQUAL_FILTER}>Equal</Select.Option>
                <Select.Option value={LOWER_FILTER}>Lower than</Select.Option>
                <Select.Option value={GREATER_FILTER}>Greater than</Select.Option>
                <Select.Option value={RANGE_FILTER}>Between</Select.Option>
              </Select>) : null}
            
            {renderFilterInput(true, inputType, conditionValue, condition === RANGE_FILTER ? `${placeholder} from` : placeholder, null, setConditionValue)}

            {renderFilterInput(condition === RANGE_FILTER, inputType, conditionValue2, `${placeholder} to`, null, setConditionValue2)}

            <BlueButton style={{ marginTop: '10px' }} onClick={() => handleApply(type)}><FormattedMessage id="apply" /></BlueButton>
          </div>
        </Menu.Item>
      </Menu>
    );
    
    return (
      <Dropdown overlay={menu} trigger={['click']} visible={visible} overlayClassName="condo-weeks-filters__dropdown">
        <div className="condo-weeks-filters__item" onClick={() => {
          resetFlags(conditionSelected, value?.toString(), value2?.toString());
          setVisible(!visible);
        }}>
          <div className="item-title">
            <FormattedMessage id={title} />
            <div className="item-arrow">
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
          <div style={{ color: '#000000' }}>{valueStr}</div>
        </div>
      </Dropdown>);
  }

  return (
    <div className="condo-weeks-filters">
      <div className="container-fluid">
        <div className="row">
          {FITLER_LIST.map((f, i) => (
            <div key={`index_table_${i}`} className="col-lg-3">
              {renderCondoFilterItem(f)}
            </div>))}
        </div>
      </div>
    </div>
  );
}

const CondoWeeksFiltersComponentRouter = withRouter(CondoWeeksFiltersComponent);

export const CondoWeeksFilters = connect()(CondoWeeksFiltersComponentRouter);
