import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ReactGA from 'react-ga4';

import {
  IBookPackageWithCard,
  ICardBooking,
  IErrorField,
  ICarsDriver,
} from '@common-types';
import { ICarDetails, ISessionKey } from '@share/common-types';
import {
  VALIDATION_ERROR,
  EXTERNAL_ERROR,
  PRICE_CHANGED,
  SOLD_OUT,
  ERROR,
  SAVING_MISMATCH,
  ROOMS_UNAVAILABLE_ERROR,
  CHECK_OUT_PAYMENT_ERROR,
  INVALID_PAYLOAD,
  CHECKOUT_ERROR,
  STATUS_CONFIRMED
} from '@constants';
import { Urls, SESSION_KEY_LABEL, C_BOOKING_CONFIRMED } from '@share/constants';
import { getHeaders, axiosInstance, AppThunk, UrlUtils } from '@share/utils';
import { get, isString } from 'lodash';
import { scrollTop } from '@share/utils';
import { carsActions, getUserCards, getUserWallet } from '@share/store/slices';

export interface ICarsReviewBookState {
  loading: boolean;
  error: string;
  expiredSession: boolean;
  loadingBooking: boolean;
  errorBooking: string;
  bookingComplete: boolean;
  booking: ICarDetails;
  card: ICardBooking;
  driver: ICarsDriver;
  errorsField: IErrorField[];
  isExternalError: boolean;
  isPriceChangedError: boolean;
  isSoldOutError: boolean;
  errorsBookingMessage: IErrorField[];
  isBookingInProgress: boolean;
  isSavingsMismatch: boolean;
  threeDSModalVisible: boolean;
  threeDSUrl: string;
  threeDSId: string;
  threeDSLoading: boolean;
  selectedCarsReviewClientCash: number;
  selectedCarsReviewClientCashStr: string;
}

const initialCarDState: any = {
  addressLine: '',
  cvv: '',
  cardNumber: '',
  cardType: null,
  city: '',
  country: 'US',
  state: '',
  expireDate: '',
  holderName: '',
  phone: '',
  zipCode: '',
  addPaymentMethod: false
};

const initialState: ICarsReviewBookState = {
  loading: false,
  error: '',
  expiredSession: false,
  loadingBooking: false,
  errorBooking: '',
  bookingComplete: false,
  booking: null,
  card: { ...initialCarDState },
  driver: {
    namePrefix: '',
    givenName: '',
    surname: '',
    country: 'US',
    phone: '',
    email: '',
    birthDate: ''
  },
  errorsField: null,
  isExternalError: false,
  isPriceChangedError: false,
  isSoldOutError: false,
  errorsBookingMessage: null,
  isBookingInProgress: false,
  isSavingsMismatch: false,
  threeDSModalVisible: false,
  threeDSUrl: null,
  threeDSId: null,
  threeDSLoading: null,
  selectedCarsReviewClientCash: null,
  selectedCarsReviewClientCashStr: ''
};

const zeroItem = 0;

const carsReviewBookSlice = createSlice({
  name: 'cars-review-book',
  initialState,
  reducers: {
    setCarsLoadingBook: (state: ICarsReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setCarsErrorBook: (state: ICarsReviewBookState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setCarsExpiredSession: (state: ICarsReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.expiredSession = payload;
    },
    setCarsSavingsMismatch: (state: ICarsReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.isSavingsMismatch = payload;
    },
    setCarsThreeDSModalVisible: (state: ICarsReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.threeDSModalVisible = payload;
    },
    setCarsThreeDSUrl: (state: ICarsReviewBookState, { payload }: PayloadAction<string>) => {
      state.threeDSUrl = payload;
    },
    setCarsThreeDSId: (state: ICarsReviewBookState, { payload }: PayloadAction<string>) => {
      state.threeDSId = payload;
    },
    setCarsThreeDSLoading: (state: ICarsReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.threeDSLoading = payload;
    },
    setCarsLoadingBooking: (state: ICarsReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.loadingBooking = payload;
    },
    setCarsErrorBooking: (state: ICarsReviewBookState, { payload }: PayloadAction<string>) => {
      state.errorBooking = payload;
    },
    setCarsBookingComplete: (state: ICarsReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.bookingComplete = payload;
    },
    setCarsBooking: (state: ICarsReviewBookState, { payload }: PayloadAction<ICarDetails>) => {
      state.booking = payload;
    },
    setCarsCard: (state: ICarsReviewBookState, { payload }: PayloadAction<ICardBooking>) => {
      state.card = payload;
    },
    setCarsDriver: (state: ICarsReviewBookState, { payload }: PayloadAction<ICarsDriver>) => {
      state.driver = payload;
    },
    setCarsErrorsField: (state: ICarsReviewBookState, { payload }: PayloadAction<IErrorField[]>) => {
      state.errorsField = payload;
    },
    setCarsExternalError: (state: ICarsReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.isExternalError = payload;
    },
    setCarsPriceChangedError: (state: ICarsReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.isPriceChangedError = payload;
    },
    setCarsSoldOutError: (state: ICarsReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.isSoldOutError = payload;
    },
    setCarsErrorsBookingMessage: (state: ICarsReviewBookState, { payload }: PayloadAction<IErrorField[]>) => {
      state.errorsBookingMessage = payload;
    },
    setCarsBookingInProgress: (state: ICarsReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.isBookingInProgress = payload;
    },
    setSelectedCarsReviewClientCash: (state: ICarsReviewBookState, { payload }: PayloadAction<number>) => {
      state.selectedCarsReviewClientCash = payload;
    },
    setSelectedCarsReviewClientCashStr: (state: ICarsReviewBookState, { payload }: PayloadAction<string>) => {
      state.selectedCarsReviewClientCashStr = payload;
    },
    
    resetCarsState: (state: ICarsReviewBookState) => {
      return { ...initialState, selectedCarsReviewClientCash: state.selectedCarsReviewClientCash };
    },
  },
});

export const {
  setCarsLoadingBook,
  setCarsErrorBook,
  setCarsExpiredSession,
  setCarsLoadingBooking,
  setCarsErrorBooking,
  setCarsBookingComplete,
  setCarsBooking,
  setCarsCard,
  setCarsDriver,
  setCarsErrorsField,
  setCarsExternalError,
  setCarsPriceChangedError,
  setCarsSoldOutError,
  setCarsErrorsBookingMessage,
  setCarsBookingInProgress,
  resetCarsState,
  setCarsSavingsMismatch,
  setCarsThreeDSModalVisible,
  setCarsThreeDSUrl,
  setCarsThreeDSId,
  setCarsThreeDSLoading,
  setSelectedCarsReviewClientCash,
  setSelectedCarsReviewClientCashStr
} = carsReviewBookSlice.actions;

export const carsReviewBookReducer = carsReviewBookSlice.reducer;

export const resetCreditCard = (): AppThunk => {
  return async (dispatch) => {
    dispatch(setCarsCard({ ...initialCarDState }));
  }
}

export const getCarsBookPackageWithCard = (bookWithCard: IBookPackageWithCard): AppThunk => {
  return async (dispatch) => {
    dispatch(setCarsLoadingBooking(true));
    dispatch(setCarsExpiredSession(false));
    dispatch(setCarsThreeDSModalVisible(false));
    dispatch(setCarsThreeDSUrl(null));
    dispatch(setCarsThreeDSId(null));
    dispatch(setCarsThreeDSLoading(false));
    
    try {
      const res = await axiosInstance.post(Urls.CarsCheckout, bookWithCard, {
        ...getHeaders(),
      });

      const bookingResponse = res?.data;
      if (bookingResponse?.chargeStatus === 'Pending3DS') {
        dispatch(setCarsLoadingBooking(false));
        dispatch(setCarsThreeDSModalVisible(true));
        dispatch(setCarsThreeDSId(bookingResponse?.id));
        dispatch(setCarsThreeDSUrl(bookingResponse?.urlRedirectTo3DS));
        return;
      }
      dispatch(handleProcessBookingComplete(bookingResponse));
    } catch (error) {
      dispatch(handleErrorBookingComplete(error));
    } finally {
      scrollTop();
    }
  };
};

export const getCarsBookPackageWithCard3DS = (sessionKey: ISessionKey): AppThunk => {
  return async (dispatch, getState) => {
    const { carsReviewBookStore } = getState();
    const { threeDSId } = carsReviewBookStore;

    dispatch(setCarsThreeDSModalVisible(false));
    dispatch(setCarsThreeDSLoading(true));

    try {
      const res = await axiosInstance.post(Urls.CarsCheckoutResume, { bookingId: threeDSId, sessionKey }, { ...getHeaders() });
      dispatch(handleProcessBookingComplete(res?.data));

      dispatch(setCarsThreeDSUrl(null));
      dispatch(setCarsThreeDSId(null));
      dispatch(setCarsThreeDSLoading(false));

    } catch (error) {
      dispatch(handleErrorBookingComplete(error));
    } finally {
      dispatch(setCarsThreeDSModalVisible(false));
      dispatch(setCarsThreeDSUrl(null));
      dispatch(setCarsThreeDSId(null));
      dispatch(setCarsThreeDSLoading(false));

      scrollTop();
    }
  };
};

const handleProcessBookingComplete = (data: any): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setCarsLoadingBooking(true));

    const { navigationMenuStore, loginStore } = getState();

    const status = data?.bookingStatusEnum;
    if (status === SOLD_OUT) {
      dispatch(setCarsErrorBook(ROOMS_UNAVAILABLE_ERROR));
      dispatch(setCarsExternalError(true));
    }

    if (status === STATUS_CONFIRMED) {
      dispatch(setCarsBookingComplete(true));

      const { account, userWallet } = loginStore;

      if (navigationMenuStore?.items?.promo) {
        UrlUtils.removeFromUrl(SESSION_KEY_LABEL);
      }

      dispatch(resetCreditCard());
      dispatch(setSelectedCarsReviewClientCash(null));
      dispatch(setSelectedCarsReviewClientCashStr(''));
      dispatch(carsActions.setSelectedCarsSearchClientCash(null));
      dispatch(getUserWallet(userWallet));
      dispatch(getUserCards());
      dispatch(setCarsCard({ ...initialCarDState }));

      ReactGA.event({
        category: account.name,
        action: `${C_BOOKING_CONFIRMED}_${account.name.toUpperCase()}`,
        label: `Booking confirmed on book`,
        nonInteraction: false,
      });
  
    } else {
      dispatch(setCarsBookingInProgress(true));
    }

    dispatch(setCarsLoadingBooking(false));

    dispatch(setCarsBooking(data));
  }
}

const handleErrorBookingComplete = (error: any): AppThunk => {
  return async (dispatch) => {

    const errorData = error?.response?.data;

    if (errorData) {
      // TODO to check
      const errorType = get(errorData, `[${zeroItem}].errorType`);
      const errorCode = get(errorData, `[${zeroItem}].errorCode`);

      if (errorType === VALIDATION_ERROR) {
        dispatch(setCarsErrorsField(error.response.data));
      } else if (errorType === EXTERNAL_ERROR) {
        dispatch(setCarsExternalError(true));
      } else if (errorCode === PRICE_CHANGED) {
        dispatch(setCarsPriceChangedError(true));
      } else if (errorCode === SOLD_OUT) {
        dispatch(setCarsSoldOutError(true));
      } else if (errorType === ERROR) {
        dispatch(setCarsErrorsBookingMessage(error.response.data));
      } else if (errorCode === SAVING_MISMATCH) {
        dispatch(setCarsExpiredSession(true));
        dispatch(setCarsSavingsMismatch(true));
      } else {
        const errorObject = isString(errorData) ? JSON.parse(errorData) : errorData;
        const errorMessage = get(errorObject, 'code', null);
        const errorReason = get(errorObject, 'reason', error.toString());

        const errorReasonInsufficientZbucks = errorReason === 'Insufficient Zbucks';
        const errorReasonSessionKeyNotValid = errorReason === 'SessionKey not valid';
        
        const externalErrors = [ROOMS_UNAVAILABLE_ERROR];
        const paymentErrors = [CHECK_OUT_PAYMENT_ERROR];
        const errors = [INVALID_PAYLOAD, CHECKOUT_ERROR]; 
        if (externalErrors.includes(errorMessage)) {
          dispatch(setCarsErrorBook(errorMessage));
          dispatch(setCarsExternalError(true));
        } else if (errorReasonSessionKeyNotValid) {
          dispatch(setCarsErrorBook('INSUFFICIENT_ZBUCKS_ERROR'));
          dispatch(setCarsExternalError(true));
        } else if (paymentErrors.includes(errorMessage)) {
          dispatch(setCarsErrorBooking(errorReason));
        } else if (errors.includes(errorMessage)) {
          dispatch(setCarsErrorBooking(errorReason));
        } else {
          dispatch(setCarsErrorBooking(errorMessage ? `error.code.${errorReasonInsufficientZbucks ? 'INSUFFICIENT_ZBUCKS_ERROR' : errorMessage}` : errorReason));
        }
      }

    } else {
      dispatch(setCarsErrorBooking(error.toString()));
    }
    dispatch(setCarsLoadingBooking(false));  
  }
}