import React from 'react';

import { ReviewBookWrapper } from '@components';
import { scrollTop } from '@share/utils';

import './style.scss';

interface IProps {
  isVacationRentals?: boolean;
}

export default class ReviewBookPage extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);

    scrollTop();
  }

  render(): React.ReactNode {
    return (
      <div className="review-book-page">
        <ReviewBookWrapper isVacationRentals={this.props.isVacationRentals as boolean} />
      </div>
    );
  }
}
