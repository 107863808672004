import React from 'react';

export const ClockSvg: React.FunctionComponent = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
      stroke="#666666"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 4.66602V7.99935L10 9.99935"
      stroke="#666666"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

type SizeProps = {
  size?: string;
}

export const HomeClockSvg: React.FunctionComponent<SizeProps> = ({ size }: SizeProps) => (
  <svg width={size || 16} height={size || 16} viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.1888 14.4115L19.168 16.1719L18.3867 17.4427L14.6263 15.1927V9.98438H16.1888V14.4115ZM22.9596 14.151C22.9596 18.1823 19.6992 21.4427 15.668 21.4427C13.1888 21.4427 11.0117 20.2031 9.6888 18.3177H3.16797V9.98438H0.0429688L10.4596 0.609375L17.7513 7.16146C20.7617 8.05729 22.9596 10.849 22.9596 14.151ZM20.8763 14.151C20.8763 11.276 18.543 8.94271 15.668 8.94271C12.793 8.94271 10.4596 11.276 10.4596 14.151C10.4596 17.026 12.793 19.3594 15.668 19.3594C18.543 19.3594 20.8763 17.026 20.8763 14.151Z"
      fill="#333333"
    />
  </svg>
);