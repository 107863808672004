import React, { useMemo } from 'react';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  IWeekState,
  IWeeksFiltersState,
  IWeeksLocationsState,
  weeksActions,
  weeksFiltersActions,
} from '@share/store/slices';
import { ILoginState } from '@share/store/slices';
import { IClientCash, IWeek } from '@share/common-types';
import { RootState } from '@share/utils';
import { SEARCH_BANNER_TYPE } from '@share/common-types';
import { Banner, WalletSearchSlider, NoAvailableCondosFilterNotification } from '@components';
import { Map, isWeeksFiltersEmpty } from '@utils';

import { WeekHotelCard } from '../../week-card';
import { CondoResultsEmpty } from '../../../../../condo/condo-result/condo-results-empty';
import { CondoCardsSkeleton } from '../../../../../condo/condo-result/condo-card-skeleton';

import MapMarker from '@assets/images/map-price-icon.png';
import MapMarkerSelected from '@assets/images/map-price-selected-icon.png';
import MapMultipleDoubleMarker from '@assets/images/map-multiple-double-price-icon.png';
import MapMultipleMarker from '@assets/images/map-multiple-price-icon.png';
import MapMultipleMarkerSelected from '@assets/images/map-multiple-price-selected-icon.png';

import { WeeksResultCompare } from '../week-result-compare';

import './style.scss';

interface IMapStateToProps {
  weeksStore: IWeekState;
  weeksFiltersStore: IWeeksFiltersState;
  weeksLocationsStore: IWeeksLocationsState;

  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setSelectedCompareWeeks: (selectedCompareWeeks: IWeek[]) => void;
  setSelectedWeekSearchClientCash: (selectedClientCash: IClientCash) => void;

  resetFilters: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  matches: boolean;
  isNoAvailableCondosNotification: boolean;

  onFiltersOrSortChange: () => void;
  selectWeek: (week: IWeek) => void;
  updateMarker: (marker: HTMLElement, zIndex: number, background1: string, background2: string, background3: string) => void;
}

const maxCompareCondos = 5;

function WeeksResultComponent(props: IProps) {
  const { weeksLocationsStore, loginStore, weeksStore, weeksFiltersStore, matches, isNoAvailableCondosNotification } = props;

  const { account } = loginStore;
  const {
    weeks,
    loading,
    isSessionExpired,
    loadingMore,
    counters,
    selectedWeeks,
    selectedCompareWeeks,
    selectedWeeksSearchClientCash,
  } = weeksStore;
  const { location } = weeksLocationsStore;

  const onMouseEnterWeekCard = (id: number): void => {
    onMouseEvent(id, 10, MapMultipleMarkerSelected, MapMultipleMarkerSelected, MapMarkerSelected);
  };

  const onMouseLeaveWeekCard = (id: number): void => {
    onMouseEvent(id, 5, MapMultipleDoubleMarker, MapMultipleMarker, MapMarker);
  };

  const onMouseEvent = (id: number, zIndex: number, background1: string, background2: string, background3: string): void => {
    if (selectedWeeks?.propertyId !== id) {
      const marker: HTMLElement = document.querySelector(`[data-item-id="${id}"]`);
      if (marker && !marker.dataset.itemNotChange) {
        props.updateMarker(marker, zIndex, background1, background2, background3);
      } else {
        Map.refreshMarkers(selectedWeeks?.propertyId, true);
      }
    }
  };

  const resetFilters = () => {
    props.resetFilters();
    props.onFiltersOrSortChange();
  };

  const handleCompare = (week: IWeek) => {
    const { weeksStore } = props;
    const { selectedCompareWeeks } = weeksStore;
    const weekIndex = selectedCompareWeeks.findIndex(h => h.propertyId === week.propertyId);
    if (weekIndex !== -1) {
      const selectedCompareWeeksFiltered = [...selectedCompareWeeks];
      selectedCompareWeeksFiltered.splice(weekIndex, 1);
      props.setSelectedCompareWeeks([...selectedCompareWeeksFiltered]);
    } else {
      props.setSelectedCompareWeeks([...selectedCompareWeeks, week]);
    }
  }

  const handleOnSelectClientCash = (selectedSearchClientCash: number) => {
    props.setSelectedWeekSearchClientCash({ ...selectedWeeksSearchClientCash, selectedSearchClientCash });
  }

  const isFiltersEmpty = useMemo(() => isWeeksFiltersEmpty(weeksFiltersStore), [weeksFiltersStore]);

  const totalCondos = counters?.totalFiltered;

  const walletHasSliderOnSearch = account?.walletHasSliderOnSearch;

  if (!weeks || loading) {
    return null;
  }

  return (
    <>
      <WeeksResultCompare handleCompare={handleCompare} />

      {walletHasSliderOnSearch ? (
        <div className="condo-result-wrapper__condos-top-section wallet">
          <WalletSearchSlider
            display={true}
            selectedClientCash={selectedWeeksSearchClientCash?.selectedSearchClientCash}
            onSelectClientCash={handleOnSelectClientCash}
          />
        </div>) : null}

      <div className="condo-result-wrapper__condos-top-section">
        <div className={`condo-result-wrapper__condos-count ${isSessionExpired ? 'disabled' : ''}`}>
          {location?.name}
          {': '}
          <FormattedMessage values={{ count: totalCondos ? totalCondos : 0 }} id="properties.found" />
        </div>
      </div>

      {weeks?.length ? (
        weeks.map((week: IWeek, index) => {
          const isInCompareList = selectedCompareWeeks?.map(c => c.propertyId).includes(week.propertyId);
          return (
            <React.Fragment key={`${week.propertyId}-${index}-container`}>
              <WeekHotelCard
                onSelect={() => props.selectWeek(week)}
                disabled={isSessionExpired}
                key={`${week.propertyId}-${index}`}
                week={week}
                onMouseLeave={() => onMouseLeaveWeekCard(week.propertyId)}
                onMouseEnter={() => onMouseEnterWeekCard(week.propertyId)}
                matches={matches}

                compared={isInCompareList}
                disableCompare={!isInCompareList && selectedCompareWeeks?.length >= maxCompareCondos}
                displayCompare={true}
                onCompareSelect={handleCompare}
              />
              {(index === 1 || weeks.length < 2) && (<Banner type={SEARCH_BANNER_TYPE}></Banner>)}
            </React.Fragment>
          );
        })
      ) : isFiltersEmpty && isNoAvailableCondosNotification ? 
        <CondoResultsEmpty /> : 
        <NoAvailableCondosFilterNotification resetFilters={resetFilters} altOptionsCount={0} />}

      {loadingMore ? <CondoCardsSkeleton /> : null}
    </>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    weeksStore: state.weeksStore,
    weeksFiltersStore: state.weeksFiltersStore,
    weeksLocationsStore: state.weeksLocationsStore,

    loginStore: state.loginStore,    
  };
};

const mapDispatchToProps:IMapDispatchToProps = {
  resetFilters: weeksFiltersActions.resetFilters,

  setSelectedCompareWeeks: weeksActions.setSelectedCompareWeeks,
  setSelectedWeekSearchClientCash: weeksActions.setSelectedWeeksSearchClientCash,
};

export const WeeksResult = connect(mapStateToProps, mapDispatchToProps)(withRouter(WeeksResultComponent));
