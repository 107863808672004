import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LabeledValue, SelectValue } from 'antd/lib/select';

import { IGuest, IRoom } from '@share/common-types';
import { getEmptyKid } from '@share/utils';
import { CustomSelect } from '@share/components';

import { AdultSvg, ChildSvg } from '@share/assets';

import { Counts } from '../counts';

import './style.scss';

interface IProps {
  room: IRoom;
  index: number;
  haveScroll: boolean;
  updateAdultsCount: (roomId: string, count: number) => void;
  addKid: (roomId: string, kid: IGuest) => void;
  removeKid: (roomId: string, kidId: string) => void;
  removeRoom: (roomId: string) => void;
  updateKidAge: (roomId: string, kidId: string, age: number) => void;
}

const KID_MAX_AGE = 17;
const GUEST_MAX_COUNT = 8;
const ONE = 1;

export class Room extends React.Component<IProps> {
  getAgeSelectOptions = (): LabeledValue[] => {
    const res: LabeledValue[] = [];

    for (let i = 0; i <= KID_MAX_AGE; i++) {
      res.push({ label: i, value: i });
    }

    return res;
  };

  render(): React.ReactNode {
    const {
      room,
      index,
      addKid,
      removeKid,
      updateAdultsCount,
      removeRoom,
      updateKidAge,
      haveScroll,
    } = this.props;
    const { id, kids, adultsCount } = room;
    const lastKid: IGuest = kids[kids.length - ONE];
    const maxAdultsRemaining = GUEST_MAX_COUNT - kids.length;
    const maxChildRemaining = GUEST_MAX_COUNT - adultsCount;
    
    return (
      <div className="room" key={id}>
        <div className="room__count">
          <FormattedMessage values={{ count: 1 }} id="room" /> {index + ONE}
          {index >= ONE && (
            <div className="room__remove" onClick={() => removeRoom(id)}>
              <FormattedMessage id="remove" />
            </div>
          )}
        </div>
        <div className="room__adults">
          <div className="room__field">
            <div className="room__user-icon">
              <AdultSvg />
            </div>
            <div className="room__description">
              <div className="room__user-label">
                <FormattedMessage values={{ count: 2 }} id="adult" />
              </div>
              <div className="room__user-description">
                <FormattedMessage id="over.years" values={{ years: '17' }} />
              </div>
            </div>
            <div className="room__counts">
              <Counts
                count={adultsCount}
                add={() => updateAdultsCount(id, adultsCount + ONE)}
                minus={() => updateAdultsCount(id, adultsCount - ONE)}
                min={1}
                max={maxAdultsRemaining}
              />
            </div>
          </div>
        </div>
        <div className="room__kids">
          <div className="room__field">
            <div className="room__user-icon">
              <ChildSvg />
            </div>
            <div className="room__description">
              <div className="room__user-label">
                <FormattedMessage values={{ count: 2 }} id="child" />
              </div>
              <div className="room__user-description">
                <FormattedMessage id="less.years" values={{ years: '17' }} />
              </div>
            </div>
            <div className="room__counts">
              <Counts
                count={kids.length}
                add={() => addKid(id, getEmptyKid())}
                minus={() => removeKid(id, lastKid.id)}
                disabled={!adultsCount}
                min={0}
                max={maxChildRemaining}
              />
            </div>
          </div>
        </div>
        {kids.length > 0 && (<div className="room__kids-selects-title"><FormattedMessage id={'room.picker.age_at_checking'} /></div>)}
        {kids.length ? (
          <div className="room__kids-selects" style={{ marginRight: haveScroll ? '-15px' : '0' }}>
            {kids.map((kid: IGuest, index: number) => {
              return (
                <CustomSelect
                  closeOnScrollParentSelector=".room-picker__list-wrapper"
                  placeHolder={<FormattedMessage id="age" />}
                  key={kid.id}
                  value={kid.age}
                  onChange={(value: SelectValue) => updateKidAge(id, kid.id, +value as number)}
                  options={this.getAgeSelectOptions()}
                  label={
                    <div
                      className="room__kids-select-label"
                      style={{ marginBottom: '10px' }}
                    >
                      <FormattedMessage values={{ count: 1 }} id="child" /> {index + ONE}
                    </div>
                  }
                />
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
}
