import { createSelector } from '@reduxjs/toolkit';
import orderBy from 'lodash/orderBy';
import { IMemberStories } from '@common-types';
import { RootState } from '@share/utils';
import { IMemberStoriesState } from './slice';

export const selectMemberStoriesState = (state: RootState): IMemberStoriesState =>
  state.memberStory;

export const selectMemberStories = (state: RootState): IMemberStories[] =>
  selectMemberStoriesState(state).memberStories;

export const selectMemberStoriesIsLoading = (state: RootState): boolean =>
  selectMemberStoriesState(state).loading;

export const selectMemberStoriesError = (state: RootState): string =>
  selectMemberStoriesState(state).error;

export const selectMemberStoriesActiveStoryId = (state: RootState): number =>
  selectMemberStoriesState(state).activeStoryId;

export const selectMemberStoriesOrderedByDate = (state: RootState): IMemberStories[] => {
  const memberStories = selectMemberStoriesState(state).memberStories;

  return orderBy(memberStories, ['modifiedDate'], 'desc');
};

export const selectMemberStoriesById = createSelector(
  selectMemberStories,
  selectMemberStoriesActiveStoryId,
  (memberStories, activeStoryId) => memberStories.find(({ id }) => id === activeStoryId),
);
