import React from 'react';

export const LightSvg: React.FunctionComponent = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.3333 4.66667L14.75 5.25M2.5 10H3.33333H2.5ZM10 2.5V3.33333V2.5ZM16.6667 10H17.5H16.6667ZM4.66667 4.66667L5.25 5.25L4.66667 4.66667Z"
      stroke="#666666"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.50029 13.3334C6.80068 12.8087 6.2839 12.0771 6.02315 11.2424C5.76239 10.4077 5.77089 9.51206 6.04744 8.68243C6.32398 7.85279 6.85455 7.1312 7.56399 6.61986C8.27343 6.10853 9.12578 5.83337 10.0003 5.83337C10.8748 5.83337 11.7271 6.10853 12.4366 6.61986C13.146 7.1312 13.6766 7.85279 13.9531 8.68243C14.2297 9.51206 14.2382 10.4077 13.9774 11.2424C13.7167 12.0771 13.1999 12.8087 12.5003 13.3334C12.1749 13.6554 11.93 14.0496 11.7852 14.4839C11.6404 14.9182 11.5999 15.3805 11.667 15.8334C11.667 16.2754 11.4914 16.6993 11.1788 17.0119C10.8662 17.3244 10.4423 17.5 10.0003 17.5C9.55826 17.5 9.13434 17.3244 8.82177 17.0119C8.50921 16.6993 8.33362 16.2754 8.33362 15.8334C8.40067 15.3805 8.36016 14.9182 8.21539 14.4839C8.07062 14.0496 7.82565 13.6554 7.50029 13.3334"
      stroke="#666666"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.08344 14.1666H11.9168"
      stroke="#666666"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
