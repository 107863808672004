import React from 'react';

export const ManBlueSvg: React.FunctionComponent = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 12C11.2091 12 13 10.2091 13 8C13 5.79086 11.2091 4 9 4C6.79086 4 5 5.79086 5 8C5 10.2091 6.79086 12 9 12Z"
      fill="#0D99D6"
    />
    <path
      d="M2 20V17.6667C2 16.429 2.49167 15.242 3.36683 14.3668C4.242 13.4917 5.42899 13 6.66667 13H11.3333C12.571 13 13.758 13.4917 14.6332 14.3668C15.5083 15.242 16 16.429 16 17.6667V20"
      fill="#0D99D6"
    />
    <path
      d="M19 8V14M16 11H22H16Z"
      stroke="#0D99D6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
