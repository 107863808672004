import { RefObject, useEffect } from 'react';

import { Form, Input } from 'antd';
import { connect } from 'react-redux';
import { FormInstance } from 'antd/lib/form';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RootState } from '@share/utils';
import { IWeeksReviewBookState, weeksReviewBookAction } from '@store/slices';
import { ICondoGuestBooking } from '@common-types';
import { updateWeeksGuest } from '@utils';
import { mailFormat } from '@share/utils';

import './style.scss';

interface IMapStateToProps {
  weeksReviewBookStore: IWeeksReviewBookState;
}

interface IMapDispatchToProps {
  setWeeksGuest: (guest: ICondoGuestBooking) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  formRef: RefObject<FormInstance>;
  isSomeoneElse: boolean;
}

const MAX_LENGTH = 100;

function WeeksConfirmationEmailComponent(props: IProps) {
  const { intl, isSomeoneElse, formRef, weeksReviewBookStore, setWeeksGuest } = props;
  const { guest, loadingBooking, errorsField } = weeksReviewBookStore;

  const validateEmail = (e: { target: HTMLInputElement }) => {
    setWeeksGuest(updateWeeksGuest(guest, { email: e.target.value }) as ICondoGuestBooking);
  };

  const validateAdditionalEmail = (e: { target: HTMLInputElement }) => {
    setWeeksGuest(updateWeeksGuest(guest, { additionalEmail: e.target.value }) as ICondoGuestBooking);
  };

  const onFocus = (e: { target: HTMLInputElement }) => {
    formRef.current?.setFieldsValue({ email: e.target.value });
  };

  useEffect(() => {
    if (errorsField && formRef.current) {
      formRef.current.validateFields().then();
    }
  }, []);

  const email = guest?.email;
  const additionalEmail = guest?.additionalEmail;

  return (
    <div className="condo-email">
      <div className="condo-email__header">
        <h4 className="condo-email__title secondary-font">
          <FormattedMessage id="confirmation.email" />
        </h4>
        <p className="condo-email__description">
          <FormattedMessage id="enter.email.address" />
        </p>
      </div>
      <div className="condo-email__rule">
        <Form.Item
          name="email"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              pattern: new RegExp(mailFormat),
              message: intl.formatMessage({ id: 'error.message.email.validation' }),
            },
          ]}
        >
          <div className="condo-email__name-input-wrapper">
            <span className="condo-email__input-label">
              <FormattedMessage id="email" />
            </span>
            <div className="condo-email__input">
              <Input
                placeholder={intl.formatMessage({ id: 'email.address' })}
                value={email}
                maxLength={MAX_LENGTH}
                onChange={validateEmail}
                onFocus={onFocus}
                disabled={loadingBooking}
              />
            </div>
          </div>
        </Form.Item>
        {isSomeoneElse && (
          <Form.Item
            name="additionalEmail"
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                pattern: new RegExp(mailFormat),
                message: intl.formatMessage({ id: 'error.message.email.validation' }),
              },
            ]}
          >
            <div className="condo-email__name-input-wrapper">
              <span className="condo-email__input-label">
                <FormattedMessage id="additional.email" />
              </span>
              <div className="condo-email__input">
                <Input
                  placeholder={intl.formatMessage({ id: 'additional.email.address' })}
                  value={additionalEmail}
                  maxLength={MAX_LENGTH}
                  onChange={validateAdditionalEmail}
                  onFocus={onFocus}
                  disabled={loadingBooking}
                />
              </div>
            </div>
          </Form.Item>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    weeksReviewBookStore: state.weeksReviewBookStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setWeeksGuest: weeksReviewBookAction.setWeeksGuest,
};

export const WeeksConfirmationEmail = connect(mapStateToProps, mapDispatchToProps)(injectIntl(WeeksConfirmationEmailComponent));
