import React from 'react';
import moment from 'moment';

import { connect } from 'react-redux';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { get } from 'lodash';
import { Checkbox } from 'antd';
import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';

import { RootState, renderNumber } from '@share/utils';
import { ICarsDetailsState, ICarsReviewBookState } from '@store/slices';
import { ILoginState } from '@share/store/slices';
import { Currency } from '@components';
import { GetPreferenceValueParsed } from '@utils';
import { IAccount, WALLET_EXTERNAL_FIELD_KEY } from '@share/common-types';
import { DATE_FORMAT_UNIT_ROW } from '@share/constants';

import { CarsCardRefundability } from '../../cars-card/cars-card-refundability';

import './style.scss';

const INCOMING_DATE_FORMAT = 'yyyy-MM-DDTHH:mm:ss';

interface IMapStateToProps {
  carsDetailsStore: ICarsDetailsState;
  carsReviewBookStore: ICarsReviewBookState;
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps {
  displayCheckbox?: boolean;
  disable?: boolean;
  
  onChangeAcceptPolicy?: (e: { target: CheckboxChangeEventTarget }) => void;
}

class CancellationPoliciesComponent extends React.Component<IProps> {
  
  wrapperRef: React.RefObject<HTMLParagraphElement> = React.createRef();

  render(): React.ReactNode {
    const { carsReviewBookStore, loginStore, carsDetailsStore, disable, displayCheckbox, intl, onChangeAcceptPolicy } = this.props;
    const { account, userWallet } = loginStore;
    const { selectedCarsReviewClientCash, bookingComplete, booking } = carsReviewBookStore;
    const { car } = carsDetailsStore;

    const refundable = car?.refundable;
    const currency = car?.currency;
    const cancellationPolicies = !refundable ? [{ message: 'cancellation.not_refundable.message.policy' }] : car?.cancellationPolicies;

    const hasClientCash = account?.hasClientCash;
    const isLogged = !!userWallet;

    const walletIsExternal = GetPreferenceValueParsed(account as IAccount, WALLET_EXTERNAL_FIELD_KEY, 'false');

    const cancellationPolicyText = 
      <>
        <p><FormattedMessage id="following.cancellation.policies" /></p>

        <ul>
          {cancellationPolicies.map((c: any, i: number) => 
            <li key={`cp_cars_${i}`}>
              <p style={{ marginTop: '8px' }}>
                {c?.message ?
                  <FormattedMessage id={c?.message} /> :
                  <FormattedMessage id="cancellation.policy.after" values={{ date: moment(c.from, INCOMING_DATE_FORMAT).format(DATE_FORMAT_UNIT_ROW), value: <><Currency currency={currency} />{renderNumber(c.amount, 2)}</> }} />}
              </p>
            </li>)}
        </ul>
      </>;

    const usedClientCash = (bookingComplete && booking) ? get(booking, 'clientCash', 0) : 0;
    const cancellationPolicyTextFinal = (!walletIsExternal && hasClientCash && ((isLogged && selectedCarsReviewClientCash > 0) || usedClientCash > 0)) ?
        intl.formatMessage({ id: 'cancellation.not_refundable.message.policy' }) :
        cancellationPolicyText;
    
    return (
      <div className="cancellation-policies">
        <div className="cancellation-policies__header policy">
          <h4 className="cancellation-policies__title secondary-font">
            <FormattedMessage id="cancellation.policy" />:
          </h4>

          <CarsCardRefundability car={car} />
        </div>

        <div className="cancellation-policies__info">
          <span className="cancellation-policies__info-description">{cancellationPolicyTextFinal}</span>
        </div>

        {displayCheckbox ? (
          <div className="review-book__wrapper">
            <Checkbox onChange={onChangeAcceptPolicy} disabled={disable} />
            <p className="review-book__accept-text">
              <FormattedMessage id="by.checking.this.box.you.acknowledge.cancellation.policy" />
            </p>
          </div>) : null}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    carsDetailsStore: state.carsDetailsStore,
    carsReviewBookStore: state.carsReviewBookStore,
    loginStore: state.loginStore
  };
};

export const CancellationPolicies = connect(mapStateToProps)(injectIntl(CancellationPoliciesComponent));
