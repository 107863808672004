import React from 'react';

import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { ILoginState, ResetCarsFull } from '@share/store/slices';
import { getAccountUsernameFromPath, RootState, GetHomeParams } from '@share/utils';
import { Routes } from '@share/constants';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import './style.scss';
import { resetCarsState } from '@store/slices';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  resetCarsFull: () => void;
  resetCarsReview: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps, RouteComponentProps {
}

class CarsGoHomeComponent extends React.Component<IProps> {

  render(): React.ReactNode {
    const { loginStore } = this.props;
    const { account } = loginStore;

    const accountName = getAccountUsernameFromPath(this.props.history);
    const isDisableHomes = account?.isDisableHomes;

    const homeParams = GetHomeParams(account);
        
    if (isDisableHomes) {
      return null;
    }

    return (
      <div className="back-home" onClick={() => {
        this.props.resetCarsFull();
        this.props.resetCarsReview();
        this.props.history.push(`/${accountName}${Routes.CarsSearch}${homeParams}`);
      }}>
        <FormattedMessage id="back.home" />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,    
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  resetCarsFull: () => {
    dispatch(ResetCarsFull());
  },
  resetCarsReview: () => {
    dispatch(resetCarsState());
  },
});

export const CarsGoHome = connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(CarsGoHomeComponent)));
