import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { IPopularDestinationsOffersState } from '@store/slices';
import { getPreferenceFileUrlFromAccount, getPreferenceFromAccount, RootState } from '@share/utils';
import { ILoginState } from '@share/store/slices';
import { BANNER_PRIMARY_PHOTO_FIELD_KEY, BANNER_PRIMARY_PHOTO_URL_FIELD_KEY, BANNER_SEARCH_RESULT_PHOTO_FIELD_KEY, BANNER_SEARCH_RESULT_PHOTO_URL_FIELD_KEY, BANNER_SECONDARY_PHOTO_FIELD_KEY, BANNER_SECONDARY_PHOTO_URL_FIELD_KEY, PRIMARY_BANNER_TYPE, SEARCH_BANNER_TYPE, SECONDARY_BANNER_TYPE } from '@share/common-types';

import './style.scss';

interface IMapStateToProps {
  popularDestinationsOffersStore: IPopularDestinationsOffersState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {}

interface IProps extends IMapStateToProps, IMapDispatchToProps {
  type: string;
}

class BannerComponent extends React.Component<IProps> {

  anchorRef: React.RefObject<HTMLAnchorElement> = React.createRef();

  handleClick = () => {
    const { loginStore, type } = this.props;
    const { account } = loginStore;

    const keyPhotoUrl = type === PRIMARY_BANNER_TYPE ? BANNER_PRIMARY_PHOTO_URL_FIELD_KEY :
                        type === SECONDARY_BANNER_TYPE ? BANNER_SECONDARY_PHOTO_URL_FIELD_KEY :
                        type === SEARCH_BANNER_TYPE ? BANNER_SEARCH_RESULT_PHOTO_URL_FIELD_KEY : null;
                        
    const imageUrl = getPreferenceFromAccount(account, keyPhotoUrl);

    if (!isEmpty(imageUrl)) {
      this.anchorRef.current.click();
    }
  }

  render() {
    const { loginStore, type } = this.props;
    const { account } = loginStore;

    const keyPhoto = type === PRIMARY_BANNER_TYPE ? BANNER_PRIMARY_PHOTO_FIELD_KEY :
                     type === SECONDARY_BANNER_TYPE ? BANNER_SECONDARY_PHOTO_FIELD_KEY :
                     type === SEARCH_BANNER_TYPE ? BANNER_SEARCH_RESULT_PHOTO_FIELD_KEY : null;
          
    const image = getPreferenceFileUrlFromAccount(account, keyPhoto);

    if (!image) {
      return null;
    }

    const keyPhotoUrl = type === PRIMARY_BANNER_TYPE ? BANNER_PRIMARY_PHOTO_URL_FIELD_KEY :
                        type === SECONDARY_BANNER_TYPE ? BANNER_SECONDARY_PHOTO_URL_FIELD_KEY :
                        type === SEARCH_BANNER_TYPE ? BANNER_SEARCH_RESULT_PHOTO_URL_FIELD_KEY : null;

    const imageUrl = getPreferenceFromAccount(account, keyPhotoUrl);

    return (
      <div className={`banner ${type === SEARCH_BANNER_TYPE ? 'search-result' : ''}`} onClick={this.handleClick} style={{ cursor: !isEmpty(imageUrl)? 'pointer' : 'auto'}}>
        <img src={image} alt="" width="100%" />
        {!isEmpty(imageUrl) && (<a href={imageUrl} target="_blank" rel="noreferrer" style={{ display: 'none'}} ref={this.anchorRef} />)}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    popularDestinationsOffersStore: state.popularDestinationsOffersStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {};

export const Banner = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BannerComponent);
