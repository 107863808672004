import React from 'react';
import { CarsWrapper } from '@components';

import './style.scss';

interface IProps {}

export class CarsPage extends React.Component<IProps> {
  render(): React.ReactNode {
    return <CarsWrapper />;
  }
}
