
import { getCurrencyText } from '@share/utils';

import './style.scss';

interface IProps {
  currency: string;
}

export function Currency(props: IProps) {
  const { currency } = props;

  return <>{getCurrencyText(currency)}</>;
}
