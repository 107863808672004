import React, { Component } from 'react';
import { Concierge } from '@components';
import { scrollTop } from '@share/utils';
import './style.scss';

interface IProps {}

export class ConciergeTravelTeamPage extends Component<IProps> {
  constructor(props: IProps) {
    super(props);

    scrollTop();
  }

  render(): React.ReactNode {
    return (
      <div className="concierge-team-page">
        <Concierge />
      </div>
    );
  }
}
