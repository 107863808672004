
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '@share/utils';
import { ILoginState, IMenuState } from '@share/store/slices';

import './style.scss';

interface IMapStateToProps {
  menuStore: IMenuState;
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {}

export function CondoSpecialsComponent(props: IProps) {
  const { menuStore, loginStore } = props;
  const { account } = loginStore;
  const { items } = menuStore;

  const customerServiceNumber = items?.customerServiceNumber;
  
  const stylesTell: any = {};
  if (account?.mainBackgroundColor) {
    stylesTell.background = account?.mainBackgroundColor;
  }

  return (
    <div className="condo-specials" style={stylesTell}>
      <div className="condo-specials__wrapper">
        <p className="condo-specials__title secondary-font">
          <FormattedMessage id="condo.specials" />
        </p>
        <div className="condo-specials__descriptions-wrapper">
          <p className="condo-specials__description">
            <FormattedMessage id="every.week.we.will.attempt.to.find.deeply.discounted" />
          </p>
          <p className="condo-specials__description">
            <FormattedMessage id="these.are.call-in.and.they.go.fast" />{' '}
            <a href={`tel:${customerServiceNumber}`} className="condo-specials__contact-phone">
              {customerServiceNumber}
            </a>{' '}
            <FormattedMessage id="to.book.today" />
          </p>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return { 
    menuStore: state.navigationMenuStore, 
    loginStore: state.loginStore 
  };
};

export const CondoSpecials = connect(mapStateToProps)(CondoSpecialsComponent);
