import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ITrustYouReview } from '@share/common-types';

export interface ITrustYouReviewState {
  reviewModule: ITrustYouReview;
}

const initialState: ITrustYouReviewState = {
  reviewModule: null,
};

const trustYouReviewSlice = createSlice({
  name: 'review-book',
  initialState,
  reducers: {
    setReviewModule: (state: ITrustYouReviewState, { payload }: PayloadAction<ITrustYouReview>) => {
      state.reviewModule = payload;
    },
  },
});

export const trustYouReviewActions = trustYouReviewSlice.actions;

export const trustYouReviewReducer = trustYouReviewSlice.reducer;
