import { AxiosPromise } from 'axios';
import { axiosInstance } from '@share/utils';
import { Urls } from '@share/constants';

export interface IVideoMedia {
  title: string;
  fileName: string;
  filePath: string;
  description: string;
}

export interface IVideoData {
  videos: IVideoMedia[];
}

export const staticContentAPI = {
  getMemberVideos(): AxiosPromise<IVideoData> {
    return axiosInstance.get(`${Urls.MemberVideos}/data.1.json`);
  },
};
