import GaleryOne from '@assets/images/inclusives/galleryOne.png';
import GaleryTwo from '@assets/images/inclusives/galleryTwo.png';
import GaleryFour from '@assets/images/inclusives/galleryFour.png';
import GaleryFive from '@assets/images/inclusives/galleryFive.png';
import GaleryThree from '@assets/images/inclusives/galleryThree.png';

export const inclusivesGalery = [
  {
    url: GaleryOne,
  },
  {
    url: GaleryTwo,
    alt: '',
  },
  {
    url: GaleryThree,
    alt: '',
  },
  {
    url: GaleryFour,
    alt: '',
  },
  {
    url: GaleryFive,
    alt: '',
  },
];
