import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { LUCKY_POINT_TRANSPARENT } from '@constants';
import BackgroundImage from '@assets/images/resources-page/bg-heading.jpg';
import './style.scss';

export class ResourcesHeading extends Component {
  render(): React.ReactNode {
    return (
      <div
        className="heading-wrapper"
        style={{
          background: `url(${BackgroundImage}) 50% 65% / cover no-repeat ${LUCKY_POINT_TRANSPARENT}`,
        }}
      >
        <div className="heading-wrapper__wrapper">
          <h1 className="heading-wrapper__heading secondary-font">
            <FormattedMessage id="resources.heading" />
          </h1>
        </div>
      </div>
    );
  }
}
