import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { connect } from 'react-redux';
import { Action } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import {
  Membership,
  ConciergeTeam,
  Banner,
  AboutUs,
  SearchBenefits,
  Contact,
  CarsLogos,
  CarsRecentSearches,
} from '@components';
import { ICarsState, carsActions, carsLocationsActions } from '@share/store/slices';
import { ILoginState } from '@share/store/slices';
import { Responsive } from '@share/utils';
import { getAccountUsernameFromPath, getTimeout, RootState, scrollTop, UrlUtils } from '@share/utils';
import { BackgroundColor } from '@constants';
import { CarRentalLogos } from '@common-types';
import { ICar } from '@share/common-types';
import { ISessionKey, PRIMARY_BANNER_TYPE, SECONDARY_BANNER_TYPE } from '@share/common-types';
import { CARS_SESSION_KEY_LABEL, Routes } from '@share/constants';
import { CarsSearch } from '@share/components';
import { CarsResultWrapper } from '@components';
import { LifeStyleMessage } from '@components';

import './style.scss';

interface IMapStateToProps {
  carsStore: ICarsState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setDefaultValues: () => void;
  setCars: (cars: ICar[]) => void;
  setIsSearch: (value: boolean) => void;
  resetLocations: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps { }

interface IState {
  isGotoBack: boolean;
}

const ZERO = 0;

class CarsSearchPageComponent extends React.Component<IProps, IState> {
  state = { isGotoBack: false };

  componentDidMount(): void {
    const values = UrlUtils.getValues();
    const session: ISessionKey = values[CARS_SESSION_KEY_LABEL] as ISessionKey;
    const accountName = getAccountUsernameFromPath(this.props.history);

    if (session && session.expireDate && getTimeout(session.expireDate) === ZERO) {
      this.props.setDefaultValues();
    }

    if (isEmpty(values)) {
      this.props.setDefaultValues();
      this.props.resetLocations();
      this.props.history.push(`/${accountName}${Routes.CarsSearch}${location.search}`);
    }

    scrollTop();
  }

  render(): React.ReactNode {
    const { carsStore, loginStore } = this.props;
    const { cars } = carsStore;
    const { user, account } = loginStore;

    const hasDisplayContactSection = account?.hasDisplayContactSection;
    const isRSITemplate = account?.isRSITemplate;
    const carsDisplayTrustedBrands = account?.carsDisplayTrustedBrands;

    return (
      <div className="cars-page">
        <CarsSearch />

        {!cars ? (
          <>
            {!!user ? (<CarsRecentSearches />) : null}

            {isRSITemplate ? (
              <ConciergeTeam
                backgroundColor={BackgroundColor.light}
                isImageSizeSmaller={true}
              />) : null}

            {!isRSITemplate ? (
              <Banner type={PRIMARY_BANNER_TYPE} />) : null}

            <LifeStyleMessage />

            {(!isRSITemplate && hasDisplayContactSection) ? (
              <Contact />) : null}

            {carsDisplayTrustedBrands ? (
              <CarsLogos logos={CarRentalLogos} />) : null}

            {!isRSITemplate ? (
              <SearchBenefits />) : null}

            {!isRSITemplate ? (
              <AboutUs />) : null}

            {!isRSITemplate ? (
              <Banner type={SECONDARY_BANNER_TYPE} />) : null}

            {isRSITemplate ? (
              <Membership />) : null}
          </>) : (
          <Responsive>
            <CarsResultWrapper />
          </Responsive>)}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    carsStore: state.carsStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  setDefaultValues: () => {
    dispatch(carsActions.setDefaultValues());
  },
  setCars: (cars: ICar[]) => {
    dispatch(carsActions.setCars(cars));
  },
  setIsSearch: (value: boolean) => {
    dispatch(carsActions.setIsSearch(value));
  },
  resetLocations: () => {
    dispatch(carsLocationsActions.resetCarsLocations());
  },
});

export const CarsSearchPage = connect(mapStateToProps, mapDispatchToProps)(withRouter(CarsSearchPageComponent));
