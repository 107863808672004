
import React from 'react';
import ReactGA from 'react-ga4';
import moment from 'moment';

import { Tooltip } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { IAccount, ICar, ICarDetails, IClientCash, IUserWallet, LOGOUT_FIELD_KEY } from '@share/common-types';
import { getWalletPrices, WalletPriceType, openCarPage, getMinPublicPrice } from '@utils';
import { Currency, ExpiredSessionModal, WalletMessage } from '@components';
import { renderNumber, getPreferenceFromAccount, getRankingClass, RootState, getToken, LoginType, GetDisableNewTabOpen } from '@share/utils';
import { ILoginState, IMarginatorState, marginatorActions, IMenuState, carsActions, ICarsState, ICarsDatesState } from '@share/store/slices';
import { AVAILABILITY_SOLDOUT, R_SELECT_ROOM, USER_BASE_URL } from '@share/constants';
import { carsDetailsActions, resetCreditCard } from '@store/slices';
import { NULL_VALUE } from '@constants';

import { InfoSvg } from '@assets';

import { CarsCardItems } from '../cars-card-items';
import { CarsCardRefundability } from '../cars-card-refundability';
import { CarsCardLocation } from '../cars-card-location';
import { CarsCardImage } from '../cars-card-image';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

const PERCENTS = 100;

interface IMapStateToProps {
  menuStore: IMenuState;
  loginStore: ILoginState;
  marginatorStore: IMarginatorState;

  carsStore: ICarsState;
  carsDatesStore: ICarsDatesState;
}

interface IMapDispatchToProps {
  setCommission: (value: number) => void;
  setSelectedCar: (car: ICar) => void;
  setSelectedCarSearchClientCash: (selectedClientCash: IClientCash) => void;

  resetCreditCard: () => void;

  setCarsDetails: (detail: ICarDetails) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  car: ICar;
  disabled?: boolean;
  compared?: boolean;
  displayCompare?: boolean;
  disableCompare?: boolean;

  onCompareSelect?: (car: ICar) => void;
}

interface IState {
  isAllSuppliersShown: boolean;
  marginatorCommission: number;
  isSessionExpired: boolean;
}

const zero = 0;
const pricesPaddings = 30;
const priceWidth = 164;

class CarsCardWrapper extends React.Component<IProps, IState> {
  cardRef: React.RefObject<HTMLDivElement> = React.createRef();
  state = {
    isAllSuppliersShown: false,
    isSessionExpired: false,
    marginatorCommission: 0
  };

  toggleSuppliers = () => {
    this.setState(({ isAllSuppliersShown }) => ({
      isAllSuppliersShown: !isAllSuppliersShown,
    }));
  };

  getMaxPrices = (): number => {
    if (this.cardRef && this.cardRef.current) {
      return Math.floor((this.cardRef.current.offsetWidth - pricesPaddings) / priceWidth);
    }

    return zero;
  };

  onCarPage = (marginatorCommission: number, selectedPropertyClientCash: number) => {
    const { car, history, loginStore, carsStore, menuStore } = this.props;

    const { account } = loginStore;
    const { items } = menuStore;

    this.props.setCommission(marginatorCommission);

    const token = getToken(account as IAccount);
    if (account?.type === LoginType.PrivateWithToken && !token) {
      this.setState({ isSessionExpired: true }); 
    } else {
      this.props.setCarsDetails(NULL_VALUE);
      this.props.setSelectedCar(car);
      
      const { selectedCarsSearchClientCash } = carsStore;
  
      const clientCash: any = { ...selectedCarsSearchClientCash, selectedPropertyClientCash, selectedPropertyReviewClientCash: null };
      this.props.setSelectedCarSearchClientCash(clientCash);
      this.props.resetCreditCard();

      const disableNewTabOpen = GetDisableNewTabOpen(account, items);

      openCarPage(car?.id, history, disableNewTabOpen as boolean, account?.forceIframe);
    
      ReactGA.event({
        category: account?.name as string,
        action: `${R_SELECT_ROOM}_${account?.name.toUpperCase()}`,
        label: `User selected a room on resuls`,
        nonInteraction: false,
      });
    }
  };

  handleRedirect = () => {
    const { loginStore } = this.props;
    const { account } = loginStore;

    const logoutUrlStorage = localStorage.getItem(USER_BASE_URL);
    const logoutPref = getPreferenceFromAccount(account as IAccount, LOGOUT_FIELD_KEY);
    const logoutUrl = !isEmpty(logoutUrlStorage) ? logoutUrlStorage : logoutPref;
    if (!isEmpty(logoutUrl)) {
      window.location.replace(logoutUrl);
    }
  }

  render(): React.ReactNode {
    const { isAllSuppliersShown, isSessionExpired } = this.state;
    const { car, loginStore, carsStore, carsDatesStore, menuStore, disabled = false } = this.props;
    const {
      vehicle,
      status,
      vendor,
      price,
      currency,
      saving,
      maxWalletClientCash,
    } = car;

    const displayName = vehicle?.vehMakeModel?.name;
    const category = vehicle?.category;
    
    const supplierId = vendor?.value;

    const rating: any = null;

    const { account, userWalletData } = loginStore;
    const { items } = menuStore;
    const { selectedCarsSearchClientCash } = carsStore;
    const { startDate, startDateTime, endDate, endDateTime } = carsDatesStore;

    const isTravelAgentPro = account?.isTravelAgentPro;

    const pubPrice = getMinPublicPrice(car);
    const background = account?.buttonColor;
    const minSavings = account?.savingsPercentageMin;
    const styleButton = !isEmpty(background)? { background } : {};

    const isSoldout = status?.toUpperCase() === AVAILABILITY_SOLDOUT.toUpperCase();

    const { value, showCommission, showSupplier } = this.props.marginatorStore;
    const marginatorPrice = price / (1 - (value / 100));
    const marginatorSavings = pubPrice - marginatorPrice;
    const marginatorCommission = marginatorPrice - price;
    
    const msave = pubPrice ? (marginatorPrice * PERCENTS) / pubPrice : 100;
    const marginatorsavePercents = Math.floor(PERCENTS - msave);

    const walletHasSliderOnSearch = account?.walletHasSliderOnSearch;
    
    const carsPrices = getWalletPrices(account as IAccount, userWalletData as IUserWallet, selectedCarsSearchClientCash, marginatorPrice, maxWalletClientCash as number, WalletPriceType.Search);

    const displayWalletSavings = account?.walletWalletSavings && (carsPrices?.priceSavings > 0 || carsPrices?.maxClientCashAmountAllow > 0) && !!carsPrices?.maxClientCashAmountAllow;

    const clientCashApplied = carsPrices?.clientCashApplied;
    const marginatorPriceFinal = displayWalletSavings ? carsPrices?.priceFinalMax : carsPrices?.priceFinal;

    const days = Math.ceil(moment(`${endDate}T${endDateTime}:00`, 'yyyy-MM-DDTHH:mm:ss').diff(moment(`${startDate}T${startDateTime}:00`, 'yyyy-MM-DDTHH:mm:ss'), 'hours') / 24);

    const displayNormalSavings = !isSoldout && !disabled && !!isFinite(marginatorsavePercents) && marginatorsavePercents > 0 && !displayWalletSavings;

    return (
      <div className={`hotel-card cars-content ${disabled ? 'disabled' : ''}`}>
        {disabled && <div className="hotel-card__disabled-wrapper" />}
        <div className={`hotel-card-wrapper cars-content ${isAllSuppliersShown ? 'prices-open' : ''}`} ref={this.cardRef}>
          <div className={`hotel-card-wrapper__slider cars-content ${(displayNormalSavings || displayWalletSavings) ? 'with-savings' : ''}`} onClick={() => this.onCarPage(marginatorCommission, clientCashApplied)}>
            
            {displayNormalSavings ? (
              <div className="hotel-card-wrapper__label-container">
                <div className="hotel-card-wrapper__label">
                  <p className="hotel-card-wrapper__label-title">
                    <FormattedMessage id={account?.forceMemberWording ? 'member.save' : 'save'} /> {marginatorsavePercents}%
                  </p>
                </div>
                <div className="hotel-card-wrapper__label-corner"></div>
              </div>) : null}

            {displayWalletSavings ? (
              <div className="hotel-card-wrapper__label-container">
                <div className="hotel-card-wrapper__label">
                  <p className="hotel-card-wrapper__label-title">
                    <FormattedMessage id="save.up.to" values={{ number: carsPrices?.priceSavings ? carsPrices?.priceSavings : '<1' }} />
                  </p>
                </div>
                <div className="hotel-card-wrapper__label-corner"></div>
              </div>) : null}

            <CarsCardImage car={car} />
          </div>

          <div className="hotel-card-wrapper__content cars-content cars-main-content">
            <div className={`hotel-card-wrapper__hotel-info ${isSoldout? 'sold-out' : ''}`}>
              <div className="hotel-card-wrapper__hotel-info-items">
                <div className="hotel-card-wrapper__hotel-info-item">
                  {!isEmpty(category) ? (
                    <h4 className="hotel-card-wrapper__hotel-info-title header-title" style={{ marginBottom: '0px' }}>
                      {category}
                    </h4>) : null}
                  <h4 className="hotel-card-wrapper__hotel-info-title header-title header-cars-title" style={{ marginBottom: '30px' }}>
                    {displayName}<label style={{ marginLeft: '6px' }}><FormattedMessage id="cars.similar" /></label>{vehicle?.fuelType === 'Electric' ? (<label className="cars-content__electric">(<FontAwesomeIcon icon={faBolt} /><FormattedMessage id="cars.electric" />)</label>) : null}
                  </h4>

                  <CarsCardItems car={car} />

                  <div className="cars-content__card-location">
                    <CarsCardLocation car={car} />
                  </div>

                  {(rating && rating?.value !== zero) ? (
                    <div className={`hotel-card-wrapper__trust-you-info ${rating.value === zero ? 'hide-trust-you-info' : ''}`}>
                      <p className="hotel-card-wrapper__trust-you-text">
                        <span className={`hotel-card-wrapper__bold ${getRankingClass(rating.value)}`}>{renderNumber(rating.value, 2)}</span>(
                        {renderNumber(rating.reviewsCount)} <FormattedMessage id="reviews" />)
                      </p>
                    </div>) : null}
                </div>
              </div>
            </div>
          </div>

          <div className={`hotel-card-wrapper__price-content cars-content no-border ${isSoldout? 'sold-out' : ''}`}>
            {!isSoldout ? (
              <>
                <div className="hotel-card-wrapper__price-content-top">
                  {(((isTravelAgentPro || minSavings || pubPrice >= marginatorPrice) && (pubPrice > zero || saving > zero)) || walletHasSliderOnSearch || displayWalletSavings) ? (
                    <div className="hotel-card-wrapper__price-info" style={{ minHeight: '73px', paddingBottom: '6px' }}>
                      {(pubPrice > zero && !displayWalletSavings && !account?.isCapitalVacations) ? (
                        <div className="hotel-card-wrapper__price-public">
                          <p className="hotel-card-wrapper__public-text">
                            <FormattedMessage id={account?.forceMemberWording ? 'result.hotel.card.public.rate' : 'result.hotel.card.public'} />
                          </p>
                          <p className="hotel-card-wrapper__public-text price">
                            <Currency currency={currency} />{renderNumber(pubPrice, 2)}
                          </p>
                        </div>) : null}

                      {displayWalletSavings ? (
                        <div className="hotel-card-wrapper__price-public">
                          <p className="hotel-card-wrapper__public-text">
                            <FormattedMessage id="result.hotel.card.public" />
                          </p>
                          <p className="hotel-card-wrapper__public-text price">
                            <Currency currency={currency} />{renderNumber(marginatorPrice, 2)}
                          </p>
                        </div>) : null}
                                          
                      {(saving > zero && !displayWalletSavings && !account?.isCapitalVacations) ? (
                        <div className="hotel-card-wrapper__price-savings">
                          <p className="hotel-card-wrapper__savings-text" style={{ marginRight: '6px' }}>
                            <FormattedMessage id={items?.isPromoSite ? 'points' : account?.forceMemberWording ? 'member.savings' : 'savings'} />
                          </p>
                          {marginatorSavings >= 1 ? (
                            <p className="hotel-card-wrapper__savings-text" style={{ whiteSpace: 'nowrap' }}>
                              <Currency currency={currency} />{renderNumber(marginatorSavings, 2)}
                            </p>) : (
                            <p className="hotel-card-wrapper__savings-text" style={{ whiteSpace: 'nowrap' }}>
                              &lt; <Currency currency={currency} />{renderNumber(1)}
                            </p>)}
                        </div>) : null}

                      {displayWalletSavings ? (
                        <div className="hotel-card-wrapper__price-savings">
                          <p className="hotel-card-wrapper__savings-text" style={{ marginRight: '6px' }}>
                            <FormattedMessage id="wallet.save.up.to.max" values={{ clientCashName: account?.walletClientCashName }} />
                          </p>
                          {carsPrices?.maxClientCashAmountAllow >= 1 ? (
                            <p className="hotel-card-wrapper__savings-text value-amount" style={{ whiteSpace: 'nowrap' }}>
                              {renderNumber(carsPrices?.maxClientCashAmountAllow)}
                            </p>) : (
                            <p className="hotel-card-wrapper__savings-text value-amount" style={{ whiteSpace: 'nowrap' }}>
                              &lt;{renderNumber(1)}
                            </p>)}
                        </div>) : null}

                      {!account?.walletWalletSavings ? (
                        <WalletMessage
                          price={marginatorPrice}
                          maxClientCash={maxWalletClientCash}
                          currency={currency}
                          type={WalletPriceType.Search}
                          isCars
                        />) : null}

                      {showCommission ? (
                        <div className="hotel-card-wrapper__price-public">
                          <p className="hotel-card-wrapper__commission-text">
                            <FormattedMessage id="commission.price" />
                          </p>
                          <p className="hotel-card-wrapper__commission-text">
                            <Currency currency={currency} />{renderNumber(marginatorCommission, 2)}
                          </p>
                        </div>) : null}

                      {showSupplier ? (
                        <div className="hotel-card-wrapper__price-public">
                          <p className="hotel-card-wrapper__supplier-text"><FormattedMessage id="supplier" />:</p>
                          <p className="hotel-card-wrapper__supplier-text">{supplierId}</p>
                        </div>) : null}
                    </div>) : null}

                  <CarsCardRefundability car={car} />

                  <div className="hotel-card-wrapper__price-total" style={{ marginTop: '10px' }}>
                    <div className="hotel-card-wrapper__for-info">
                      <p className="hotel-card-wrapper__for-info-text">
                        <FormattedMessage id="for" />{' '}
                        <FormattedMessage id="days" values={{ count: days }} />
                      </p>
                      <Tooltip title={<FormattedMessage id={account?.breakdownTaxes ? 'price.without.taxes.fees' : 'price.includes.taxes.fees'} />}>
                        <span className="hotel-card-wrapper__price-info-icon">
                          <InfoSvg />
                        </span>
                      </Tooltip>
                    </div>

                    <p className="hotel-card-wrapper__price-total-text">
                      <span style={{fontSize: '13px', marginRight: '4px'}}><Currency currency={currency} /></span>{renderNumber(marginatorPriceFinal, 2)}
                    </p>
                  </div>
                </div>
                
                <div className="hotel-card-wrapper__price-content-bottom" onClick={() => this.onCarPage(marginatorCommission, clientCashApplied)}>
                  <a className="hotel-card-wrapper__price-btn" style={styleButton}>
                    <FormattedMessage id="cars.view.deal" />
                  </a>
                </div>
              </>) : (
              <div className="hotel-card-wrapper__price-content-bottom">
                <a className="hotel-card-wrapper__price-btn sold-out">
                  <FormattedMessage id="sold.out" />
                </a>
              </div>)}
          </div>
        </div>

        {isSessionExpired && (
          <div className="result-wrapper__expired-message">
            <ExpiredSessionModal visible={isSessionExpired} hasRedirect={true} onHandleRedirect={this.handleRedirect} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
    marginatorStore: state.marginatorStore,

    carsStore: state.carsStore,
    carsDatesStore: state.carsDatesStore,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  setCommission: (value: number) => {
    dispatch(marginatorActions.setCommission(value));
  },
  setSelectedCar: (car: ICar) => {
    dispatch(carsActions.setSelectedCar(car));
  },
  setSelectedCarSearchClientCash: (selectedClientCash: IClientCash) => {
    dispatch(carsActions.setSelectedCarsSearchClientCash(selectedClientCash));
  },

  resetCreditCard: () => {
    dispatch(resetCreditCard());
  },

  setCarsDetails: (detail: ICarDetails) => {
    dispatch(carsDetailsActions.setCarsDetails(detail));
  },
});

const CarsCardWrapperRouter = withRouter(CarsCardWrapper);

export const CarsCard = connect(mapStateToProps, mapDispatchToProps)(CarsCardWrapperRouter);
