import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MAX_SELECTED_MONTH_COUNT } from '@share/constants';
import { StayForEnum } from '@share/common-types';

export interface ICondoFlexibleDatePickerState {
  stayFor: StayForEnum;
  selectedMonths: string[];
}

const initialState: ICondoFlexibleDatePickerState = {
  stayFor: StayForEnum.Week,
  selectedMonths: [],
};

const THREE_CHARACTERS = 3;

const condoFlexibleDatePickerSlice = createSlice({
  name: 'condo-flexible-date-picker',
  initialState,
  reducers: {
    setStayFor: (state: ICondoFlexibleDatePickerState, { payload }: PayloadAction<StayForEnum>) => {
      state.stayFor = payload;
    },
    setStayForExperiences: (state: ICondoFlexibleDatePickerState, { payload }: PayloadAction<StayForEnum>) => {
      state.stayFor = payload;
    },
    setMonths: (state: ICondoFlexibleDatePickerState, { payload }: PayloadAction<string[]>) => {
      state.selectedMonths = payload;
    },
    selectMonths: (state: ICondoFlexibleDatePickerState, { payload }: PayloadAction<string>) => {
      const payloadDateWithoutDay = payload.toString().substr(THREE_CHARACTERS);
      const datesStringWithoutDay = state.selectedMonths.map((date) =>
        date.toString().substr(THREE_CHARACTERS),
      );
      if (datesStringWithoutDay.includes(payloadDateWithoutDay)) {
        state.selectedMonths = state.selectedMonths.filter(
          (month) =>
            month.toString().substr(THREE_CHARACTERS) !==
            payload.toString().substr(THREE_CHARACTERS),
        );
      } else {
        if (state.selectedMonths.length < MAX_SELECTED_MONTH_COUNT) {
          state.selectedMonths.push(payload);
        }
      }
    },
    selectMonthsExperiences: (state: ICondoFlexibleDatePickerState, { payload }: PayloadAction<string>) => {
      const payloadDateWithoutDay = payload.toString().substr(THREE_CHARACTERS);
      const datesStringWithoutDay = state.selectedMonths.map((date) =>
        date.toString().substr(THREE_CHARACTERS),
      );
      if (datesStringWithoutDay.includes(payloadDateWithoutDay)) {
        state.selectedMonths = state.selectedMonths.filter(
          (month) =>
            month.toString().substr(THREE_CHARACTERS) !==
            payload.toString().substr(THREE_CHARACTERS),
        );
      } else {
        if (state.selectedMonths.length < MAX_SELECTED_MONTH_COUNT) {
          state.selectedMonths.push(payload);
        }
      }
    },
    resetState: () => {
      return initialState;
    },
    setState: (
      state: ICondoFlexibleDatePickerState,
      { payload }: PayloadAction<ICondoFlexibleDatePickerState>,
    ) => {
      return payload;
    },
  },
});

export const condoFlexibleDatePickerActions = condoFlexibleDatePickerSlice.actions;

export const condoFlexibleDatePickerReducer = condoFlexibleDatePickerSlice.reducer;
