
import RSIUpgradeWhite from '@share/assets/images/profiles/rsi-upgrade-white.png';
import RSIUpgradeBlack from '@share/assets/images/profiles/rsi-upgrade-black.png';
import RSIUpgradeFooterLogo from '@share/assets/images/profiles/footer-rsi-upgrade.png';

export const RSIUpgradeProfileHome = {
    logo: RSIUpgradeWhite,
    logoSkipWidth: true,

    headerFontColor: '#FFFFFF',
    headerBackgroundColor: 'transparent',
    headerSubMenuWhiteBackground: true,
    headerSubMenuRoundedCorners: true,
    headerHideSubHeader: true,
    headerFloating: true,
    headerHideMemberId: true,
    headerHideMemberUserIdDrawer: true,
    headerTextUpperCase: true,
    headerMainMenu: true,
    headerFullDrawer: true,

    buttonColor: '#42626C',
    buttonTextColor: '#FFFFFF',
    buttonBorderRadius: '2',
    buttonTextTransform: 'uppercase',

    buttonColorReverse: 'transparent',
    buttonTextColorReverse: '#FFFFFF',
    buttonBorderReverse: '1px solid #FFFFFF',
    buttonBorderRadiusReverse: '2',

    footerBackgroundColorDB: '#42626C',
    footerFontsColorDB: '#FFFFFF',
    footerLogo: RSIUpgradeFooterLogo,
    footerLogoPosition: 'top',
    footerTextUpperCase: true,
    footerMainMenu: true,
    footerDisplaySubMenuData: true,
}

export const RSIUpgradeProfile = {
    logo: RSIUpgradeBlack,
    logoSkipWidth: true,

    headerTextTransform: 'uppercase',

    footerTextTransform: 'uppercase',
}