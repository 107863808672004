import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMagazine } from '@common-types';
import { Urls } from '@share/constants';
import { axiosInstance } from '@share/utils';
import { AppThunk } from '@share/utils';

export interface IMagazineState {
  magazine: IMagazine[];
  loading: boolean;
  error: string;
}

const initialState: IMagazineState = {
  magazine: null,
  loading: false,
  error: '',
};

const magazineSlice = createSlice({
  name: 'magazine',
  initialState,
  reducers: {
    setLoading: (state: IMagazineState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: IMagazineState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setMagazine: (state: IMagazineState, { payload }: PayloadAction<IMagazine[]>) => {
      state.magazine = payload;
    },
  },
});

export const magazineActions = magazineSlice.actions;

export const magazineReducer = magazineSlice.reducer;

export const getMagazine = (): AppThunk => {
  return async (dispatch) => {
    dispatch(magazineActions.setLoading(true));

    try {
      const res = await axiosInstance.get(Urls.Magazine);

      dispatch(magazineActions.setLoading(false));
      dispatch(magazineActions.setMagazine(res.data));
    } catch (error) {
      console.error(error);
      dispatch(magazineActions.setError(error.toString()));
      dispatch(magazineActions.setLoading(false));
    }
  };
};
