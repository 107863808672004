import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IMarginatorState {
  value: number;
  percentage: number;
  showCommission: boolean;
  commission: number;
  supplier: string;
  showSupplier: boolean;
  impersonator: IImpersonatorState;
  error: string;
}

export interface IImpersonatorState {
  memberId: number;
  storeType: string;
}

const initialState: IMarginatorState = {
  value: 0,
  percentage: 0,
  showCommission: false,
  commission: 0,
  supplier: '',
  showSupplier: false,
  impersonator: {
    memberId: 0,
    storeType: null
  },
  error: '',
};

const marginatorSlice = createSlice({
  name: 'marginator',
  initialState,
  reducers: {
    setValue: (state: IMarginatorState, { payload }: PayloadAction<number>) => {
      state.value = payload;
    },
    setPercentage: (state: IMarginatorState, { payload }: PayloadAction<number>) => {
      state.percentage = payload / 100;
    },
    showCommission: (state: IMarginatorState, { payload }: PayloadAction<boolean>) => {
      state.showCommission = payload;
    },
    setCommission: (state: IMarginatorState, { payload }: PayloadAction<number>) => {
      state.commission = payload;
    },
    setSupplier: (state: IMarginatorState, { payload }: PayloadAction<string>) => {
      state.supplier = payload;
    },
    showSupplier: (state: IMarginatorState, { payload }: PayloadAction<boolean>) => {
      state.showSupplier = payload;
    },
    setMemberId: (state: IMarginatorState, { payload }: PayloadAction<number>) => {
      state.impersonator.memberId = payload;
    },
    setStoreType: (state: IMarginatorState, { payload }: PayloadAction<string>) => {
      state.impersonator.storeType = payload;
    },
    setError: (state: IMarginatorState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    }
  },
});

export const marginatorActions = marginatorSlice.actions;
export const marginatorReducer = marginatorSlice.reducer;