import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatMoneyCurrency } from '@share/utils';
import { renderPriceBasedOn } from '@utils';
import { GetawaysLocationEnum, IGetawayInfo } from '@share/common-types';

import './style.scss';

interface IProps {
  getawayInfo: IGetawayInfo;
  currency: string;
  activeGetaway?: string;
}

export class GetawayInfo extends React.Component<IProps> {
  render(): React.ReactNode {
    const { getawayInfo, currency, activeGetaway } = this.props;
    const { imageUrl, title, promoId, youPayPrice, specialType } = getawayInfo;
    const { specialTypeName } = specialType;
    const toLowercaseSpecialTypeName = specialTypeName.toLowerCase();

    const isTypeNameCondo = activeGetaway === GetawaysLocationEnum.Condo;

    return (
      <div className="getaway-info">
        <div className="getaway-info__wrapper">
          <div className="getaway-info__image-wrapper">
            {isTypeNameCondo ? (
              <div
                className="hotel-card-wrapper__label-container"
              >
                <div className="hotel-card-wrapper__label">
                  <p className="hotel-card-wrapper__label-title">
                    <FormattedMessage id="over.50.percentage" />
                  </p>
                </div>
                <div className="hotel-card-wrapper__label-corner"></div>
              </div>) : null}

            <img src={imageUrl} alt={title} />
          </div>
          <div className="getaway-info__content">
            <p className="getaway-info__city secondary-font">{title}</p>
            <p className="getaway-info__promo-id">
              <FormattedMessage id="promo.id" />
              {': '}
              {promoId}
            </p>
          </div>
          <div className="getaway-info__price-wrapper">
            <div className="getaway-info__price-info-wrapper">
              <p className="getaway-info__price">
                <FormattedMessage id="price" />
                {':'}
              </p>
              <p className="getaway-info__price-amount">{formatMoneyCurrency(currency).format(youPayPrice)}</p>
            </div>
            <div className="getaway-info__price-info-wrapper">
              <p className="getaway-info__per-person">
                {renderPriceBasedOn(toLowercaseSpecialTypeName)}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
