import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AlertThinSvg } from '@assets';
import './style.scss';

interface IProps {
  onRefresh: () => void;
}

export class CondoExpiredSessionMessage extends React.Component<IProps> {
  render(): React.ReactNode {
    const { onRefresh } = this.props;

    return (
      <div className="condo-expired-session-message">
        <div className="condo-expired-session-message__icon-wrapper">
          <AlertThinSvg />
        </div>
        <div className="condo-expired-session-message__text">
          <FormattedMessage id="condo.session.expired.left" />{' '}
          <a onClick={onRefresh} className="condo-expired-session-message__link">
            <FormattedMessage id="condo.session.expired.refresh" />
          </a>{' '}
          <FormattedMessage id="condo.session.expired.right" />
        </div>
      </div>
    );
  }
}
