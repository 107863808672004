import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Key, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';

import { Routes } from '@share/constants';
import { GetAllReservationsColumns, GetAllReservationsMobileColumns } from '@constants';
import { IReservationsState } from '@share/store/slices';
import { Filters, IFilterField, IReservation, IReservationsFilters, ITextSearch } from '@share/common-types';
import { RootState, LoginType } from '@share/utils';
import { ILoginState } from '@share/store/slices';
import { Action, ReservationAction, SearchTable } from '@components';

import { ReservationsAutocompleteTextSearch } from '../reservations-text-search';

import './style.scss';
import { SetFilterField } from '@utils';
import { Button } from 'antd';
import { IInsuranceState } from '@store/slices';

interface IMapStateToProps {
  loginStore: ILoginState;
  insuranceStore: IInsuranceState;
}

interface IProps extends IMapStateToProps, RouteComponentProps, WrappedComponentProps {
  title: string;

  store: IReservationsState;

  getReservations: (status: string) => void;
  getStatic: (id: number) => void;
  setFilters: (filters: IReservationsFilters) => void;
  setPagination: (pagination: TablePaginationConfig) => void;
  setSort: (sort: SorterResult<IReservation>) => void;
  setBookingType: (bookingType: IFilterField<string>) => void;

  resetFilters: () => void;

  setTextSearch: (textSearch: ITextSearch) => void;
  setTextSearchLabel: (textSearchLabel: string) => void;
}

interface IState {
  active: string;
  record: IReservation;
  showMarkAsDetailModal: boolean;
}

const DEFAULT_MEDIA_POINT = 768;

class ReservationsBaseComponent extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = { active: 'active', record: null, showMarkAsDetailModal: false };
    props.setSort({
      columnKey: 'createdAt',
      field: 'createdAt',
      order: 'descend',
      column: null
    });
  }

  filterStatus = (event: any) => {
    const { store  } = this.props;
    const { pagination } = store;
    const active = event.currentTarget.id;
    this.setState({ active });
    if (pagination) {
      this.props.setPagination({...pagination, current: 1 });
    }
}

  handleOnTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, (Key | boolean)[] | null>,
    sorter: SorterResult<any>,
  ) => {
    if (sorter) {
      this.props.setSort({ ...sorter, column: null });
    }
    if (pagination) {
      this.props.setPagination(pagination);
    }

    this.handleApplyFilters(filters);
  }

  handleApplyFilters = (filters: any) => {
    if (filters) {
      SetFilterField(filters.bookingType, this.props.setBookingType);
    }
  };

  handleOnRefresh = () => {
    const { loginStore } = this.props;
    const { user } = loginStore;
    if (user) {
      this.props.getReservations(this.state.active);
    }
  };

  handleOnResetFilters = () => {
    this.props.resetFilters();
  };

  handleOnFiltersChange = (filters: Filters) => {
    this.props.setFilters(filters);
  };

  handleTextRefresh = () => {
    this.handleOnRefresh();
  }

  handleOnRowClick = (row: any) => {
    if (row?.bookingType !== 'Car') {
      this.props.getStatic(row.propertyId)
    }
    this.setState({ record: row });
    this.setState({ showMarkAsDetailModal: true });
  }

  closeDetailModal = () => {
    this.setState({ showMarkAsDetailModal: false });
  }

  render(): React.ReactNode {
    const { active } = this.state;
    const { loginStore, insuranceStore, store, setTextSearch, setTextSearchLabel } = this.props;
    const { account } = loginStore;
    const { loading, result, pagination, filters } = store;
    const { reservations, totalOrderCount, staticData } = result;

    if (!account || account.type === LoginType.Public) {
      this.props.history.push(Routes.NotAuthorized);
    }

    const isFilterApplied = false;
    const isMobile = document.body.offsetWidth <= DEFAULT_MEDIA_POINT;

    const actions: Action[] = [{
      renderAction: () => (
        <>
          <div className='container-action'>
            <div className="button-action">
              <Button ghost className={`button-filter button-effect ${active === "active" ? "active" : ''}`} id="active" onClick={this.filterStatus}>Active</Button>
              <Button ghost className={`button-filter button-effect ${active === "cancelled" ? "active" : ''}`} id="cancelled" onClick={this.filterStatus}>Cancelled</Button>
              <Button ghost className={`button-filter button-effect ${active === "past" ? "active" : ''}`} id="past" onClick={this.filterStatus}>Past</Button>
              <Button ghost className={`button-filter button-effect ${active === "all" ? "active" : ''}`} id="all" onClick={this.filterStatus}>All</Button>
            </div>

            <div className="text-search desktop">
              <ReservationsAutocompleteTextSearch
                store={store}
                setTextSearch={setTextSearch}
                setTextSearchLabel={setTextSearchLabel}
                onRefresh={this.handleTextRefresh}
              />
            </div>
          </div>
          <div className="button-base-line"></div>
        </>)
    }];

    const columns = isMobile ? GetAllReservationsMobileColumns(active) : GetAllReservationsColumns(active);
    const dataSource = reservations;

    return (
      <>
        <div className="reservations-wrapper">
          <SearchTable
            key={active}
            title={''}
            loading={loading}
            actions={actions}
            allColumns={[...columns]}
            dataSource={dataSource}
            pagination={pagination}
            filters={filters}
            totalOrderCount={totalOrderCount}
            rowKey={(row: any) => row.key ? `${row.key}` : `${row.bookingId}`}
            isFilterApplied={isFilterApplied}
            onFiltersChange={this.handleOnFiltersChange}
            onTableChange={this.handleOnTableChange}
            onRowClick={this.handleOnRowClick}
            onResetFilters={this.handleOnResetFilters}
            onApplyFilters={this.handleApplyFilters}
            onRefresh={this.handleOnRefresh}
          ></SearchTable>
        </div>
        
        <ReservationAction
          loading={loading || insuranceStore?.loading}
          error={insuranceStore?.error}
          reservation={this.state.record}
          staticData={staticData}
          visible={this.state.showMarkAsDetailModal}
          onClose={this.closeDetailModal}
          onRefresh={this.handleOnRefresh}
        />
      </>);
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    insuranceStore: state.insuranceStore,
  };
};

const ComponentIntl = injectIntl(ReservationsBaseComponent);

export const ReservationsBase = connect(mapStateToProps)(withRouter(ComponentIntl));
