import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { Action } from '@reduxjs/toolkit';
import { RootState } from '@share/utils';
import { SubCategory, getawaysActions } from '@store/slices';

import { ConciergeBooking } from '../concierge-booking';
import { ConciergeContact } from '../concierge-contact';
import { ConciergeHeading } from '../concierge-heading';
import { ConciergeOffices } from '../concierge-offices';
import { ConciergeWelcome } from '../concierge-welcome';

interface IMapDispatchToProps {
  setGetawaysListPage: (isGetawayListPage: boolean, subCategory: SubCategory) => void;
}

interface IProps extends IMapDispatchToProps {}

class ConciergeWrapperComponent extends Component<IProps> {
  componentDidMount() {
    this.props.setGetawaysListPage(true, 'premierescapes' as SubCategory);
  }

  render(): React.ReactNode {
    return (
      <>
        <ConciergeHeading />
        <ConciergeWelcome />
        <ConciergeOffices />
        <ConciergeContact />
        <ConciergeBooking />
      </>
    );
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  setGetawaysListPage: (listPage, subCategory) => {
    dispatch(getawaysActions.setGetawaysListPage({ listPage, subCategory }));
  },
});

export const Concierge = connect(null, mapDispatchToProps)(ConciergeWrapperComponent);
