import { useMemo, useState } from 'react';
import moment from 'moment';
import format from 'date-fns/format';

import { Modal, Spin, Tooltip, Typography } from 'antd';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { DateSearchTypeEnum, IAccount, ICondoLocation, ISessionKey, IUser, IWeek } from '@share/common-types';
import { RefundableEnum } from '@common-types';
import { IMarginatorState,  ILoginState } from '@share/store/slices';
import { RootState, renderNumber, getSelectedCurrency, LoginType } from '@share/utils';
import { HasPermission, ViewQuotesSharePermission, openCondoPage } from '@utils';
import { Currency, ModalShare, } from '@components';
import { BlueButton } from '@share/components';
import { DATE_FORMAT_CHECK_IN_OUT } from '@constants';
import { DownLoadShareType, HandleDownload } from '@share/api';

import './style.scss';

const modalWidth = 900;
const percents = 100;
const ONE = 1;
const DATE_FORMAT = 'd MMM';

interface IMapStateToProps {
  marginatorStore: IMarginatorState;
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps, RouteComponentProps {
  visible: boolean;
  date?: string;
  period?: string;
  locationObject: ICondoLocation;
  sessionKey: ISessionKey;
  weeks: IWeek[];
  onCancel: () => void;
  onRemove: (week: IWeek) => void;
}

function ModalCompareComponent(props: IProps) {

  const [loading, setLoading] = useState(false);
  const [visibleAction, setVisibleAction] = useState(false);
  const [isShare, setIsShare] = useState(false);
  
  const {
    sessionKey,
    locationObject,
    visible,
    weeks,
    date,
    period,
    marginatorStore, 
    loginStore,
    history, 
    onCancel,
    onRemove
  } = props;
  const { account, user } = loginStore;
  const { value } = marginatorStore;

  const currency = useMemo(() => getSelectedCurrency(account as IAccount), [account]);

  const handleView = (week: IWeek) => {
    openCondoPage(week.propertyId, history);
  }

  const handleData = (members?: string[], comment?: string) => {
    const data: any = {
      quotes: weeks.map(week => {
        const marginatorPrice = (week?.price ? week?.price : 0) / (1 - (value / 100));
        const savingsNunmber = week.savings / marginatorPrice * 100;
        const discount = savingsNunmber ? Math.floor(savingsNunmber) : 0;

        return ({
          condoId: week.propertyId,
          displayName: week.displayName,
          fullAddress: `${week.address}${!isEmpty(week?.state) ? `, ${week?.state}` : ''}`,
          refundability: week.refundability,
          reviewsCount: week.rating?.reviewsCount,
          rating: week.rating?.value,
          currency,
          price: marginatorPrice,
          publicPrice: week.publicPrice,
          availableDates: week.availableDates?.length ? [...week.availableDates?.map(ad => ({
            matchType: ad.matchType,
            totalNights: moment(ad.to, 'yyyy-MM-DD').diff(moment(ad.from, 'yyyy-MM-DD'), 'days'),
            from: ad.from,
            to: ad.to
          }))] : [],
          discount: discount.toString(),
          image: get(week, 'images[0].url'),
        });
      }),
      comment,
      members,
      location: locationObject,
      searchType: DateSearchTypeEnum.Strict,
      propertyRequest: {
        location: locationObject,
      },
      sessionKey
    };

    data.propertyRequest.checkIn = moment(date, 'MM/DD/yyyy').format('yyyy-MM-DD');
    data.propertyRequest.dateRange = period;
    data.propertyRequest.residency = 'US';

    return data;
  }

  const handleDownload = () => {
    setLoading(true);
    HandleDownload(DownLoadShareType.WEEK, loading, handleData(), props.intl, () => setLoading(false), () => setVisibleAction(false));
  }

  const fontFamily = account?.fontFamily;
  const secondaryFontFamily = account?.secondaryFontFamily;

  const background = account?.buttonColor;
  const border = account?.buttonBorder;
  const color = account?.buttonTextColor;
  const borderRadius = account?.buttonBorderRadius;
  const buttonTextTransform = account?.buttonTextTransform;
    
  const styleButtonBorderRadius = !isEmpty(borderRadius) ? { borderRadius: `${borderRadius}px` } : {};  
  const styleButtonnTextTransform = (buttonTextTransform && !isEmpty(buttonTextTransform)) ? { ...styleButtonBorderRadius, textTransform: buttonTextTransform as any } : { ...styleButtonBorderRadius };  
  const styleButtonBorder = !isEmpty(border)? { ...styleButtonnTextTransform, border } : { ...styleButtonnTextTransform };  
  const styleButtonColor = !isEmpty(color)? { ...styleButtonBorder, color } : { ...styleButtonBorder };  
  const styleButton = !isEmpty(background)? { ...styleButtonColor, background } : { ...styleButtonColor };  

  return (
    <Modal
      className={`condo-modal-compare ${fontFamily ? `${fontFamily}-font`: ''} ${secondaryFontFamily ? `secondary-${secondaryFontFamily}-font`: ''}`}
      open={visible}
      footer={null}
      onCancel={onCancel}
      wrapClassName="condo-modal-compare__wrapper"
      width={modalWidth}
    >
      <div>
        <h4 className="condo-modal-compare__title secondary-font">
          <FormattedMessage id="results.condos.card.compare.modal.title" />
        </h4>

        <div className="condo-modal-compare__hotels" style={{ flexDirection: 'column' }}>
          <div className="hotels-dates">
            <><FormattedMessage id="specific.dates" /> - {moment(date, 'MM/DD/yyyy').format(DATE_FORMAT_CHECK_IN_OUT)}</>
            <><FormattedMessage id="within" /> - <FormattedMessage id="days" values={{ count: period}} /></>
          </div>
        </div>

        <div className="condo-modal-compare__hotels">
          {weeks.map((week, index) => {
            const { price, availableDates } = week;

            const { value } = props.marginatorStore;
            const marginatorPrice = (price ? price : 0) / (1 - (value / 100));

            const msave = week.publicPrice ? (marginatorPrice * percents) / week.publicPrice : 100;
            const savings = Math.floor(percents - msave);

            return (
              <div key={index} className={`condo-modal-compare__hotel ${index === 0 ? 'display-border' : ''} hotel_${weeks.length}`}>
                <div className="condo-images-container">
                  <div className="condo-image" style={{ backgroundImage: `url(${get(week, 'images[0].url')})` }}>
                  </div>
                </div>

                <div className="condo-text-container" style={{ height: '86px' }}>
                  <p
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        lineClamp: 3,
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                    }}>{week.displayName}</p>
                </div>

                <div className="condo-text-container">
                  <p
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        lineClamp: 4,
                        WebkitLineClamp: 4,
                        WebkitBoxOrient: 'vertical',
                    }}>{`${week.address}${!isEmpty(week?.state) ? `, ${week?.state}` : ''}`}</p>
                </div>

                <div className="condo-text-container" style={{ height: '40px' }}>
                  {availableDates?.length ? (
                    <div className="condo-modal-compare__date-label">
                      {availableDates.length >= ONE && (
                        <div className="condo-modal-compare__date-more">
                          <p className="condo-modal-compare__date-more-text">
                            <Tooltip className="condo-compare-tooltip" overlayStyle={{ whiteSpace: 'pre-line' }} placement="top" title={
                              <>
                                {availableDates.map((d, i) => <Typography key={i} style={{ color: '#FFFFFF' }}>{format(new Date(d.from), DATE_FORMAT)} - {format(new Date(d.to), DATE_FORMAT)}</Typography>)}
                              </>
                            }>
                              {availableDates.length} <FormattedMessage id="available.dates" />
                            </Tooltip>
                          </p>
                        </div>)}
                    </div>) : null}
                </div>

                {week.refundability && week.refundability !== RefundableEnum.Unknown ? (
                  <div className="condo-text-container" style={{ height: '43px' }}>
                    <div className="hotel-card-wrapper__refundable">
                      {week.refundability === RefundableEnum.Refundable && (
                        <div className="hotel-card-wrapper__refundable-ref">
                          <FormattedMessage id="refundable" />
                        </div>)}
                      {week.refundability === RefundableEnum.Nonrefundable && (
                        <div className="hotel-card-wrapper__refundable-nonref">
                          <FormattedMessage id="non.refundable" />
                        </div>)}
                    </div>
                  </div>) : null}

                <div className="condo-amenities-container" style={{ height: '43px' }}>
                  {(week.rating?.value || week.rating?.reviewsCount) ? (
                    <label>{week.rating?.value ? (<><strong>{week.rating?.value.toFixed(1)}</strong> / 5</>) : null} {week.rating?.reviewsCount ? (<>({week.rating?.reviewsCount} <FormattedMessage id="reviews" />)</>) : null}</label>) : (
                    <label><FormattedMessage id="reviews.guest.no.ratings" /></label>)}
                </div>

                <div className="condo-text-container" style={{ height: '90px' }}>
                  {week.publicPrice ? (<p style={{ textDecoration: 'line-through' }}><Currency currency={currency} />{renderNumber(week.publicPrice, 2)}</p>) : null}
                  <p><strong style={{ fontSize: '20px', fontWeight: 'bold' }}><Currency currency={currency} />{renderNumber(marginatorPrice, 2)}</strong></p>
                  {savings > 0 ? (<p>{savings.toFixed(0)} % <FormattedMessage id="results.hotel.card.compare.modal.off" /></p>) : null}
                </div>

                <div className="condo-view-container">
                  <div className="view-button" style={styleButton} onClick={() => handleView(week)}>
                    <FormattedMessage id="results.hotel.card.compare.modal.view" />
                  </div>
                </div>

                <div className="condo-remove-container">
                  <label className={`${weeks.length <= 2 ? 'disabled' : ''}`} onClick={() => {
                    if (weeks.length > 2) {
                      onRemove(week);
                    }
                  }}><FormattedMessage id="remove" /></label>
                </div>
              </div>)
          })}
        </div>

        <div className="condo-modal-compare__buttons">
          {(!account || account.type !== LoginType.PrivateWithToken) ?
            <BlueButton disabled={loading} onClick={handleDownload}>
              {loading ? (<Spin />) : <FormattedMessage id="results.hotel.card.compare.modal.download" />}
            </BlueButton> :
            <>
              <BlueButton disabled={loading} onClick={() => {
                setVisibleAction(true);
                setIsShare(false);
              }}>
                {loading ? (<Spin />) : <FormattedMessage id="results.hotel.card.compare.modal.download" />}
              </BlueButton>

              {HasPermission(user as IUser, ViewQuotesSharePermission) ?
                <BlueButton disabled={loading} onClick={() => {
                  setVisibleAction(true);
                  setIsShare(true);
                }}>
                  {loading ? (<Spin />) : <FormattedMessage id="results.hotel.card.compare.modal.share" />}
                </BlueButton> : null}
            </>}
        </div>
      </div>

      <ModalShare
        visible={visibleAction}
        isShare={isShare}
        type={DownLoadShareType.WEEK}
      
        onCancel={() => setVisibleAction(false)}
        onLoading={(loading) => setLoading(loading)}
        convertData={handleData}
      />
    </Modal>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    marginatorStore: state.marginatorStore,
    loginStore: state.loginStore,
  };
};

const ModalCompareComponentRouter = withRouter(ModalCompareComponent);

export const ModalCompare = connect(mapStateToProps)(injectIntl(ModalCompareComponentRouter));