import { useEffect, useMemo } from 'react';
import parse from 'html-react-parser';

import { connect } from 'react-redux';

import WebinarsBackground from '@assets/images/webinars.jpg';

import { RootState } from '@share/utils';
import { getWebinars, IWebinarsState } from '@store/slices';

import { BlueButton, Loading, LoadingSise } from '@share/components';
import { ILoginState } from '@share/store/slices';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Routes } from '@share/constants';

import './style.scss';

interface IMapStateToProps {
  webinarsStore: IWebinarsState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getWebinars: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {}

function WebinarsComponent(props: IProps) {

  const { loginStore, webinarsStore, getWebinars } = props;
  const { account } = loginStore;
  const { loading, webinars } = webinarsStore;

  const masterClassesRegistration = account?.masterClassesRegistration;
  const isEmptyMasterClassesRegistration = useMemo(() => isEmpty(masterClassesRegistration), [masterClassesRegistration]);

  useEffect(() => getWebinars(), []);

  const parseMessage = (message: string) => {
    return message ? message?.match(/<p>(.*?)<\/p>/g)?.map((val) => val.replace(/<\/?p>/g,''))[0] : null;
  }

  return (
    <div className="webinars">
      <section className="image-bg overlay parallax pt120 pb120 pt-xs-40 pb-xs-40">
        <div className="background-image-holder fadeIn" style={{'transform': 'translate3d(0px, 0px, 0px)', 'background': `url('${WebinarsBackground}')`, 'top': '-40px'}}>
          <img alt="image" className="background-image" src="/assets/BWhiteLabel/img/bg-beach-1.jpg" style={{'display': 'none'}} />
        </div>
        <div className="container">
          <div className="row mb48 mb-xs-24">
            <div className="col-md-12 text-left">
              <h1 className="getaway-title secondary-font">Webinars</h1>
            </div>
          </div>
        </div>
        <div className="webinars__masterclass-registration">
          {!isEmptyMasterClassesRegistration ? (
            <a href={masterClassesRegistration} target="_blank" rel="noreferrer" className="master-classes__btn-link">
              <BlueButton>
                <FormattedMessage id="register.today" />
              </BlueButton>
            </a>) : null}
          {isEmptyMasterClassesRegistration ? (
            <Link to={`/${account?.name}${Routes.MasterClassesRegister}`} className="master-classes__btn-link">
              <BlueButton>
                <FormattedMessage id="register.today" />
              </BlueButton>
            </Link>) : null}
        </div>
      </section>

      <section className="pt-xs-32 pb-xs-24 pb8 pt32" style={{ 'background' : 'WhiteSmoke' }}>
        <div className="container text-center">
          {(!loading && webinars?.length)? webinars?.map((webinar, index) => (
            <div key={index} className="webinars__video-container">
              <h4 className="secondary-font">{parse(webinar?.title?.rendered)}</h4>
              <iframe src={`https://player.vimeo.com/video/${parseMessage(webinar?.excerpt?.rendered)}`} width="960" height="540" allow="autoplay; fullscreen"></iframe>
            </div>
          )) : null}

          {loading ? (<Loading size={LoadingSise.Medium} />) : null}

        </div>
      </section>
  </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    webinarsStore: state.webinarsStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getWebinars
};

export const Webinars = connect(mapStateToProps, mapDispatchToProps)(WebinarsComponent);