import React, { useState, useEffect } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { useParams } from "react-router-dom";
import { Alert, Space, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { RootState, getAccountUsernameFromPath } from '@share/utils';
import { IPasswordState, verifyUser } from '@share/store/slices';

import './style.scss';

interface IMapStateToProps {
  passwordStore: IPasswordState;
}

interface IMapDispatchToProps {
  verify: (token: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps { }

function VerifyUserComponent(props: IProps) {
  const [load, setLoad] = useState(false);
  const [accountName, setAccountName] = useState(null);
  const params: any = useParams();
  const { isSuccess, message, error } = props.passwordStore;

  useEffect(() => {
    setLoad(!load);
  }, [])

  useEffect(() => {
    if (load) {
      setAccountName(getAccountUsernameFromPath(props.history));
      handleVerify(params.token);
    }
  }, [load])

  const handleVerify = (token: string) => {
    props.verify(token);
  }

  return (
    <div className="password-page">
      <div className="container-fluid">
        <div className="row content">
          <div className="col-6">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <h1><FormattedMessage id="verify.your.user" /></h1>
                </div>
              </div>

              {message ?
                <div className="row pt-4">
                  {isSuccess
                    ? <Alert message={<FormattedMessage id="thanks.for.signing.up" />} description={message} type="success" showIcon />
                    : error.includes("is-verified")
                      ? <Alert message="" description={message} type="info" showIcon />
                      : <Alert message="" description={message} type="error" showIcon
                        action={<Space direction="vertical"><Button size="small" danger type="ghost" href={`${accountName}/send-verify`}><FormattedMessage id="send.verification.email" /></Button></Space>} />
                  }
                </div>
                : null
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    passwordStore: state.passwordStore
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  verify: (token: string) => {
    dispatch(verifyUser(token));
  }
});

const VerifyUserComponentRouter = withRouter(VerifyUserComponent);

export const VerifyUser = connect(mapStateToProps, mapDispatchToProps)(VerifyUserComponentRouter);
