import React, { Component } from 'react';

import { ResourcesWrapper } from '@components';

export class ResourcesPage extends Component {
  render(): React.ReactNode {
    return (
      <div className="resources-page">
        <ResourcesWrapper />
      </div>
    );
  }
}
