
import { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Modal, Tabs } from 'antd';

import { ILoginState } from '@share/store/slices';
import { getAccountLocale, getFlag, Locales } from '@i18n';
import { getSelectedCurrency, isCondoEngineFromPath, RootState } from '@share/utils';
import { IAccount } from '@share/common-types';

import { Languages } from './languages';
import { MultiCurrency } from './multi-currency';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps, RouteComponentProps {
  forceWhite?: boolean;
  isDrawer?: boolean;

  onOpen?: () => void;
}

function ModalInternalizationComponent(props: IProps) {
  const [show, setShow] = useState(false);
  const [selectedKey, setSelectedKey] = useState('2');

  const isCondo = isCondoEngineFromPath(props.history);
  const selectedCurrency = getSelectedCurrency(props.loginStore.account as IAccount);
  const locale = isCondo ? Locales.English : getAccountLocale(props?.loginStore?.account as IAccount);

  const { forceWhite, isDrawer, loginStore } = props;
  const { account } = loginStore;

  const getHeaderFontColorStyle = () => {
    const styleBackground: any = {};

    const headerFontsColorDB = account?.headerFontColor;
    if (!isDrawer && !isEmpty(headerFontsColorDB)) {
      styleBackground.color = headerFontsColorDB;
    }
    return styleBackground;
  }

  const getHeaderBackgroundColorStyle = () => {
    const styleBackground: any = {};

    if (isDrawer) {
      styleBackground.backgroundColor = '#000000';
    } else if (forceWhite) {
      styleBackground.backgroundColor = '#FFFFFF';
    } else {
      const headerFontsColorDB = account?.headerFontColor;
      if (!isEmpty(headerFontsColorDB)) {
        styleBackground.backgroundColor = headerFontsColorDB;
      }
    }
    return styleBackground;
  }

  const onSelectOption = (option: string) => {
    setShow(true);
    setSelectedKey(option);

    if (props.onOpen) {
      props.onOpen();
    }
  }

  if (account?.disableLanguages && account?.disableCurrencies) {
    return null;
  }

  return (
    <>
      <div className="internalization-container">
        {!account?.disableLanguages ? (
          <div 
            className={`modal-internalization__languages ${isCondo ? 'is-condo' : ''}`}
            style={{ backgroundImage: `url(${getFlag(locale, true)})` }}
            onClick={() => {if (!isCondo) { onSelectOption('1') }}}
          ></div>) : null}
        {(!account?.disableLanguages && !account?.disableCurrencies) ? (<i className="internalization-container__divider" style={getHeaderBackgroundColorStyle()}></i>) : null }

        {!account?.disableCurrencies ? (<div className={`selected_currency`} style={getHeaderFontColorStyle()} onClick={() => onSelectOption('2')}>{selectedCurrency}</div>) : null}
      </div>


      <Modal
        title=""
        centered
        open={show}
        wrapClassName="modal-internalization__wrapper"
        className="modal-internalization"
        onOk={() => setShow(false)}
        onCancel={() => setShow(false)}
        footer={null}
      >
        <Tabs activeKey={selectedKey} onChange={activeKey => setSelectedKey(activeKey)}>
          {!account?.disableLanguages ? (
            <Tabs.TabPane tab="Language" key="1">
              <Languages onLanguageSelected={() => setShow(false)} />
            </Tabs.TabPane>) : null}
          {!account?.disableCurrencies ? (
            <Tabs.TabPane tab="Currency" key="2">
              <MultiCurrency onCurrencySelected={() => setShow(false)} /> 
            </Tabs.TabPane>) : null}
        </Tabs>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

export const ModalInternalization = connect(mapStateToProps)(withRouter(injectIntl(ModalInternalizationComponent)));
