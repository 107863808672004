import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INotificationBar } from '@common-types';
import { Urls } from '@share/constants';
import { axiosInstance } from '@share/utils';
import { AppThunk } from '@share/utils';

export interface INotificationBarState {
  notificationBar: INotificationBar[];
  loading: boolean;
  error: string;
}

const initialState: INotificationBarState = {
  notificationBar: null,
  loading: false,
  error: '',
};

const notificationBarSlice = createSlice({
  name: 'notificationBar',
  initialState,
  reducers: {
    setLoading: (state: INotificationBarState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: INotificationBarState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setNotificationBar: (
      state: INotificationBarState,
      { payload }: PayloadAction<INotificationBar[]>,
    ) => {
      state.notificationBar = payload;
    },
  },
});

export const notificationBarActions = notificationBarSlice.actions;

export const notificationBarReducer = notificationBarSlice.reducer;

export const getNotificationBar = (): AppThunk => {
  return async (dispatch) => {
    dispatch(notificationBarActions.setLoading(true));

    try {
      const res = await axiosInstance.get(Urls.NotificationBar);

      dispatch(notificationBarActions.setLoading(false));
      dispatch(notificationBarActions.setNotificationBar(res.data));
    } catch (error) {
      console.error(error);
      dispatch(notificationBarActions.setError(error.toString()));
      dispatch(notificationBarActions.setLoading(false));
    }
  };
};
