import React from 'react';

export const ImagesQuantitySvg: React.FunctionComponent = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.25 6H11.2575"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.75 3H5.25C4.00736 3 3 4.00736 3 5.25V12.75C3 13.9926 4.00736 15 5.25 15H12.75C13.9926 15 15 13.9926 15 12.75V5.25C15 4.00736 13.9926 3 12.75 3Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 11.2505L6 8.25045C6.34204 7.92132 6.73004 7.74805 7.125 7.74805C7.51996 7.74805 7.90796 7.92132 8.25 8.25045L12 12.0005"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 10.5005L11.25 9.75045C11.592 9.42132 11.98 9.24805 12.375 9.24805C12.77 9.24805 13.158 9.42132 13.5 9.75045L15 11.2505"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
