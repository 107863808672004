import React, { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';

import { get } from 'lodash';
import { Modal, Tooltip } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';

import { ICondoUnit, IDetails } from '@share/common-types';
import { getNightsCount } from '@utils';
import { creatingArrayWithNumber } from '@share/utils';
import { UnitMainInfo } from '@components';

import { KitchenSvg, ManDarkSvg, CheckMarkSvg, InfoSvg, ExclamationGreySvg } from '@assets';

import './style.scss';

interface IProps extends WrappedComponentProps {
  condo: IDetails;
  unit: ICondoUnit;
  visible: boolean;
  adultsCount: number;
  childrenCount: number;

  walletPrices: any;

  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>, isScrollToRooms?: boolean) => void;
}

const ZERO = 0;
const ONE = 1;
const NO = 'No';
const YES = 'Yes';

function ModalUnitDetailsComponent(props: IProps) {
  
  const { visible, onCancel, condo = {} as IDetails, adultsCount, childrenCount, walletPrices, intl, unit = {} as ICondoUnit } = props;
  const { fullAddress, title, media, id, checkInCheckOutInfo } = condo;
  const {
    bedroomsCount,
    bathroomsCount,
    dateRange,
    price,
    unitId,
    bedRoomType,
    maxOccupancy,
    privacyOccupancy,
    kitchenType,
    babyFacilities,
    nonSmokingRoomsByRequest,
    safebox,
    safetyEquipment,
    housekeeping,
    housekeepingFeeDescription,
    cancellationPolicyText,
    name,
    coolingSystems, 
    accessibility,
    rollInShower,
    bathroomAccessible,
    bathroom,
    bathroomDescription,
    bedroomBathLocation,
    kitchenEquipments,
    roomAmenities,
    roomFurnishing,
    televisionServiceType,
    televisionType,
    numberOfTVs,
    televisionLocations,
    refundability
  } = unit;
  
  const getBathRoomInfo = useCallback(() => {
    const bathRoomInfo = [];
    if (bathroom) {
      bathRoomInfo.push(bathroom);
    }
    if (bathroomDescription) {
      bathRoomInfo.push(bathroomDescription);
    }
    if (bedroomBathLocation) {
      bathRoomInfo.push(bedroomBathLocation);
    }

    return bathRoomInfo;
  }, [bathroom, bathroomDescription, bedroomBathLocation]);

  const getBathRoomLabels = useCallback(() => {
    const bathRoomLabel = [];
    if (rollInShower) {
      bathRoomLabel.push(intl.formatMessage({ id: 'roll.in.shower' }));
    }
    if (bathroomAccessible) {
      bathRoomLabel.push(intl.formatMessage({ id: 'bathroom.accessible' }));
    }

    return bathRoomLabel;
  }, [rollInShower, bathroomAccessible]);

  const getKitchenEquipments = useCallback(() => {
    return (!isEmpty(kitchenEquipments) || !isEmpty(kitchenEquipments))  ? kitchenEquipments.filter((item) => item.details[ZERO] !== NO) : [];
  }, [kitchenEquipments]);

  const getRoomAmenities = useCallback(() => {
    return (!isEmpty(roomAmenities) || !isEmpty(roomAmenities)) ? roomAmenities.filter((item) => item.details[ZERO] !== NO) : [];
  }, [roomAmenities]);

  const getRoomFurnishing = useCallback(() => {
    return (!isEmpty(roomFurnishing) || !isEmpty(roomFurnishing)) ? roomFurnishing.filter((item) => item.details[ZERO] !== NO) : [];
  }, [roomFurnishing]);

  const getRoomEntertainment = useCallback(() => {
    const roomEntertainment = [];

    if (televisionServiceType) {
      roomEntertainment.push(televisionServiceType);
    }

    if (televisionType) {
      roomEntertainment.concat(televisionType);
    }

    if (numberOfTVs) {
      roomEntertainment.push(`${intl.formatMessage({ id: 'number.of.tvs' })} ${numberOfTVs}`);
    }

    if (televisionLocations) {
      roomEntertainment.concat(televisionLocations);
    }

    return roomEntertainment;
  }, [televisionServiceType, televisionType, numberOfTVs, televisionLocations]);

  const getAccessibility = useCallback(() => {
    return accessibility ? [accessibility, ...getBathRoomLabels()] : getBathRoomLabels();
  }, [accessibility]);

  const isShowAmenities = useCallback(() => {
    return (
      !isEmpty(getRoomAmenities()) ||
      !isEmpty(getAccessibility()) ||
      !isEmpty(coolingSystems) ||
      !isEmpty(getRoomFurnishing()) ||
      !isEmpty(getRoomEntertainment())
    );
  }, [coolingSystems]);

  const highlights = getBathRoomInfo();

  return (
    <Modal
      className="modal-unit-details"
      visible={visible}
      footer={null}
      onCancel={onCancel}
      wrapClassName="modal-unit-details__wrapper"
    >
      <div className="modal-unit-details__block">
        <div className="modal-unit-details__left-block">
          <h4 className="modal-unit-details__info-title capitalize">{name}</h4>
          <div className="modal-unit-details__guests">
            {creatingArrayWithNumber(maxOccupancy, ONE).map((value, i) => {
              return (
                <span className="modal-unit-details__icon-wrapper" key={i}>
                  <ManDarkSvg />
                </span>
              );
            })}
          </div>
          <div className="modal-unit-details__capacity">
            <div className="modal-unit-details__capacity-text">
              <FormattedMessage id="max.sleeps" />: {maxOccupancy}
            </div>
            <div className="modal-unit-details__capacity-text">
              <FormattedMessage id="private" />: {privacyOccupancy}
            </div>
          </div>
          {!isEmpty(highlights) && (
            <h4 className="modal-unit-details__info-title mt15">
              <FormattedMessage id="bathroom" />
            </h4>
          )}
          {highlights && highlights.length ? (
            <div className="modal-unit-details__highlights">
              {highlights.map((item, index) => (
                <p key={index} className="modal-unit-details__highlight">
                  {item}
                </p>
              ))}
            </div>
          ) : null}
          <div className="modal-unit-details__default-banner">
            <div className="modal-unit-details__default-banner-icon">
              <ExclamationGreySvg />
            </div>
            <div className="modal-unit-details__default-banner-text">
              <div className="modal-unit-details__default-banner-header">
                <FormattedMessage id="please.note.that.resort.will.assign" />
              </div>
              <div className="modal-unit-details__default-banner-description">
                <FormattedMessage id="selected.unit.type.may.have.amenities" />
              </div>
            </div>
          </div>
          <div className="modal-unit-details__info">
            {!isEmpty(getBathRoomLabels()) && (
              <div className="modal-unit-details__highlights pt0">
                {getBathRoomLabels().map((item, index) => (
                  <p key={index} className="modal-unit-details__highlight">
                    <CheckMarkSvg />
                    {item}
                  </p>
                ))}
              </div>
            )}
          </div>
          {!isEmpty(getKitchenEquipments()) ? (
            <div className="modal-unit-details__info">
              <h4 className="modal-unit-details__info-title">
                <FormattedMessage id="kitchen" />
              </h4>
              {kitchenType ? (
                <div className="modal-unit-details__block-info kitchen-icon-block">
                  <KitchenSvg />
                  <p className="modal-unit-details__block-info-text capitalize">{kitchenType}</p>
                </div>) : null}
              {getKitchenEquipments().map((equipment, index) => (
                <div key={index}>
                  <p className="modal-unit-details__description-info-text">{equipment.name}:</p>
                  {equipment.details.map((item, i) => (
                    <span className="modal-unit-details__description-info-text" key={i}>
                      {(i ? ', ' : '') + item}
                    </span>
                  ))}
                </div>
              ))}
            </div>) : null}
          {babyFacilities && !isEmpty(babyFacilities) ? (
            <div className="modal-unit-details__info">
              <h4 className="modal-unit-details__info-title">
                <FormattedMessage id="baby.facilities" />
              </h4>
              {babyFacilities.map((item, index) => (
                <p className="modal-unit-details__description-info-text" key={index}>
                  {(index ? ', ' : '') + item}
                </p>
              ))}
            </div>) : null}
          {isShowAmenities() ? (
            <div className="modal-unit-details__info amenities-wrapper">
              <h4 className="modal-unit-details__info-title">
                <FormattedMessage id="tab.amenities" />
              </h4>
              {!isEmpty(getRoomAmenities()) ? (
                <>
                  <p className="modal-unit-details__info-list-title">
                    <FormattedMessage id="general" />
                  </p>
                  <div className="modal-unit-details__info-items">
                    {getRoomAmenities().map((amenity, index) => (
                      <p key={index} className="modal-unit-details__info-list-item">
                        <CheckMarkSvg />
                        {amenity.name}
                        {amenity.details.map(
                          (item, i) =>
                            item !== YES && (
                              <span
                                className="modal-unit-details__info-list-item-description"
                                key={i}
                              >
                                {(i ? ', ' : '') + item}
                              </span>
                            ),
                        )}
                      </p>
                    ))}
                  </div>
                </>) : null}
              <div className="modal-unit-details__info-list-wrapper">
                <div className="modal-unit-details__info-list">
                  {(!isEmpty(getRoomFurnishing()) || !isEmpty(getRoomEntertainment())) ? (
                    <p className="modal-unit-details__info-list-title">
                      <FormattedMessage id="entertainment" />
                    </p>) : null}
                  <div className="modal-unit-details__info-items">
                    {!isEmpty(getRoomFurnishing()) ?
                      getRoomFurnishing().map((furnishing, index) => (
                        <p key={index} className="modal-unit-details__info-list-item one-colonm">
                          <CheckMarkSvg />
                          {furnishing.name}
                          {furnishing?.details.map(
                            (item, i) =>
                              item !== YES && (
                                <span
                                  className="modal-unit-details__info-list-item-description"
                                  key={i}
                                >
                                  {(i ? ', ' : '') + item}
                                </span>
                              ),
                          )}
                        </p>)) : null}
                    {!isEmpty(getRoomEntertainment()) ?
                      getRoomEntertainment().map((item, index) => (
                        <p key={index} className="modal-unit-details__info-list-item one-colonm">
                          <CheckMarkSvg />
                          {item}
                        </p>)) : null}
                  </div>
                </div>
                {coolingSystems?.length && (
                  <div className="modal-unit-details__info-list">
                    <p className="modal-unit-details__info-list-title">
                      <FormattedMessage id="heating.venting.cooling" />
                    </p>
                    {coolingSystems.map((item, index) => (
                      <p key={index} className="modal-unit-details__info-list-item one-colonm">
                        <CheckMarkSvg />
                        {item}
                      </p>
                    ))}
                  </div>
                )}
              </div>
              {!isEmpty(getAccessibility()) ? (
                <div>
                  <p className="modal-unit-details__info-list-title">
                    <FormattedMessage id="accessibility" />
                  </p>
                  {getAccessibility().map((item, index) => (
                    <p key={index} className="modal-unit-details__info-list-item one-colonm">
                      <CheckMarkSvg />
                      {item}
                    </p>
                  ))}
                </div>) : null}
            </div>) : null}
          <div className="modal-unit-details__info-bottom">
            {nonSmokingRoomsByRequest ? (
              <p className="modal-unit-details__description-info-text">
                <FormattedMessage id="non.smoking.rooms" />
                {':'}
                <span className="modal-unit-details__property-info-text">
                  {nonSmokingRoomsByRequest ? YES : NO}
                </span>
              </p>) : null}
            {safebox ? (
              <p className="modal-unit-details__description-info-text">
                <FormattedMessage id="safebox" />
                {':'}
                <span className="modal-unit-details__property-info-text">
                  {safebox ? YES : NO}
                </span>
              </p>) : null}
            {safetyEquipment ? (
              <p className="modal-unit-details__description-info-text">
                <FormattedMessage id="safety.equipment" />
                {':'}
                <span className="modal-unit-details__property-info-text">
                  {safetyEquipment ? YES : NO}
                </span>
              </p>
            ) : null}
            {housekeeping ? (
              <p className="modal-unit-details__description-info-text">
                <FormattedMessage id="housekeeping" />
                {':'}
                <span className="modal-unit-details__property-info-text">{housekeeping}</span>
                <Tooltip placement="bottom" title={housekeepingFeeDescription}>
                  <span className="modal-unit-details__price-info-icon">
                    <InfoSvg />
                  </span>
                </Tooltip>
              </p>) : null}
          </div>
          {!isEmpty(cancellationPolicyText) ? (
            <div className="modal-unit-details__cancellation-policy-wrapper">
              <h4 className="modal-unit-details__info-title">
                <FormattedMessage id="cancellation.policy" />
              </h4>
              <p className="modal-unit-details__cancellation-policy-text">
                {cancellationPolicyText}
              </p>
            </div>
          ) : null}
        </div>

        <div className="modal-unit-details__right-block">
          <UnitMainInfo
            imageUrl={get(media, `[${ZERO}].url`)}
            data={{
              title: title,
              address: fullAddress,
              unitName: bedRoomType,
              maxOccupancy: maxOccupancy,
              adultsCount,
              childrenCount,
              bedroomsCount: bedroomsCount,
              bathroomsCount: bathroomsCount,
              checkIn: dateRange.from,
              checkOut: dateRange.to,
              nightsCount: getNightsCount(unit.dateRange),
              condoId: id,
              unitId: unitId,
              price: price,
              checkInTime: checkInCheckOutInfo?.checkInTime,
              checkOutTime: checkInCheckOutInfo?.checkOutTime,
              isModalInfo: true,
              refundability,
              walletPrices
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

export const ModalUnitDetails = connect()(injectIntl(ModalUnitDetailsComponent));

