import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import {
  IDatesState,
  IRoomsState,
  ILocationsState,
  locationActions,
  datesActions,
  getHotels,
  filtersActions,
  IHotelsState,
  ILoginState,
  initializeSearch,
  IFiltersState,
  VACATIONS_FILTERED,
  IMenuState,
} from '@share/store/slices';
import { BlueButton } from '@share/components';
import {
  DATES_LABEL,
  LOCATION_LABEL,
  LOCATION_OBJECT_LABEL,
  ROOMS_LABEL,
  FILTERS_LABEL,
  SESSION_KEY_LABEL,
  REFERENCE_NUMBER_LABEL,
  AGENCY_NUMBER_LABEL,
  HomesTypes,
  ACCESS_TOKEN_LABEL,
  Routes,
  DEAL_HOME_LABEL,
  ALL_INCLUSIVE_KEY,
} from '@share/constants';
import { IAccount, IRoom, ISessionKey, LocationsTypes } from '@share/common-types';
import { UrlUtils, isDataValid, RootState, getTimeout, VACATIONS_PAGE, CanImpersonate, IsImpersonating, isAllInclusiveFromPath, LoginType, GetDisableNewTabOpen } from '@share/utils';

import { SearchSvg, UpdateSvg } from '@share/assets';

import { LocationsPicker } from '../locations-picker';
import { RoomPicker } from '../room-picker';
import { DatePicker } from '../date-picker';

import './style.scss';
import { NULL_VALUE } from '@constants';

interface IMapStateToProps {
  datesStore: IDatesState;
  roomsStore: IRoomsState;
  locationsStore: ILocationsState;
  hotelsStore: IHotelsState;
  loginStore: ILoginState;
  filtersStore: IFiltersState;
  menuStore: IMenuState;
}

interface IMapDispatchToProps {
  validateLocation: (error: string) => void;
  validateDates: (error: string) => void;
  getHotels: (
    isLoadMore: boolean,
    isFiltersChange: boolean,
    isReuseSessionKey: boolean,
  ) => void;
  initializeSearch: () => void;
  resetFilters: () => void;
  setSearchHomes: (value: string) => void;
  setAccommodationType: (value: string[]) => void;
}

interface IProps extends IMapDispatchToProps, IMapStateToProps, WrappedComponentProps, RouteComponentProps {
  isWidget?: boolean;
  isExperiences?: boolean;
  isAfterSearchLoad?: boolean;
  isVacationRentals?: boolean;
  disableLocationSearch?: boolean;
  openOnClick: boolean;
  onSearch: () => void;
  onExperience?: () => void;
}

interface IState {
  initialized: boolean;
}

const ZERO = 0;

class SearchHotelComponent extends React.Component<IProps, IState> {
  state: IState = { initialized: false };

  onSearch = (isInitial = false, isReuse = false) => {
    const {
      locationsStore,
      datesStore,
      loginStore,
      isWidget,
      isExperiences,
      isVacationRentals,
      isAfterSearchLoad,
      intl,
      validateDates,
      validateLocation,
      getHotels,
      resetFilters,
      setSearchHomes,
      setAccommodationType,
      onSearch,
      onExperience,
    } = this.props;
    const { isDatesValid, isLocationValid, isValid, isDatesMaxError } = isDataValid(
      locationsStore,
      datesStore,
    );

    const { account } = loginStore;

    if (!isLocationValid) {
      validateLocation(intl.formatMessage({ id: 'location.error' }));
    }

    if (!isDatesValid) {
      validateDates(
        intl.formatMessage({ id: isDatesMaxError ? 'maximum.date.range.search' : 'dates.error' }),
      );
    }

    if (isValid) {
      const forceDisableVacationRentals = account?.forceDisableVacationRentals;
      const isAllInclusivePage = isAllInclusiveFromPath(this.props.history);

      if (isWidget || isAllInclusivePage) {
        const { locationsStore, hotelsStore, roomsStore, menuStore } = this.props;
        const { selectedLocation, selectedLocationLabel } = locationsStore;
        const { sessionKey, referenceNumber, agencyNumber } = hotelsStore;
        const { account } = loginStore;
        const { roomsSelected } = roomsStore;
        const { items } = menuStore;

        const locationObject = UrlUtils.getUrl(LOCATION_OBJECT_LABEL, selectedLocation);
        const location = UrlUtils.getUrl(LOCATION_LABEL, selectedLocationLabel);
        const { startDateSelected, endDateSelected } = datesStore;
        const datesLabel = UrlUtils.getUrl(DATES_LABEL, {
          startDate: startDateSelected,
          endDate: endDateSelected,
        } as Record<string, unknown>);
        const sessionKeyParam = isWidget ? `&${UrlUtils.getUrl(SESSION_KEY_LABEL, sessionKey)}` : '';
        const rooms = UrlUtils.getUrl(
          ROOMS_LABEL,
          roomsSelected.map((room: IRoom) => {
            return {
              adultsCount: room.adultsCount,
              kids: room?.kids?.map(({ age }) => age),
            };
          }),
        );

        const accessToken = account?.accessTokenDb;
        const hasPublicAccessToken = account?.hasPublicAccessToken;
        const hasPublicValidToken = account?.type !== LoginType.Public || !hasPublicAccessToken || !isEmpty(accessToken);

        const baseAddressSite = account?.baseAddressSite;
        const accountName = account?.name;

        const disableNewTabOpen = GetDisableNewTabOpen(account, items);

        var host = isAllInclusivePage ? `${window.location.origin}/` : import.meta.env.VITE_WIDGET_REDIRECT;
        const path = !isEmpty(baseAddressSite) && !isAllInclusivePage ? baseAddressSite : host;
        const referenceNumberParam = !isEmpty(referenceNumber) ? `${REFERENCE_NUMBER_LABEL}=${referenceNumber}` : '';
        const agencyNumberParam = !isEmpty(agencyNumber) ? `${AGENCY_NUMBER_LABEL}=${agencyNumber}` : '';
        const tokenParam = hasPublicValidToken ? `${ACCESS_TOKEN_LABEL}=${accessToken}` : '';

        const isHotelLocation = selectedLocation.type === LocationsTypes.Hotel;

        if (isAllInclusivePage) {
          const filters = UrlUtils.getUrl(FILTERS_LABEL, this.props.filtersStore);
          window.open(`${path}${accountName}${Routes.Search}?${locationObject}&${location}&${datesLabel}&${rooms}&${filters}&${tokenParam}${sessionKeyParam}&${referenceNumberParam}&${agencyNumberParam}&${DEAL_HOME_LABEL}=${ALL_INCLUSIVE_KEY}`, '_blank');
        }
        else {
          if (isHotelLocation) {
            window.open(`${path}${accountName}${Routes.Hotel}/${selectedLocation.code}${isVacationRentals ? `/${VACATIONS_PAGE}` : ''}?${locationObject}&${tokenParam}&${location}&${datesLabel}${sessionKeyParam}&${rooms}&${referenceNumberParam}&${agencyNumberParam}`, disableNewTabOpen ? '_self' : '_blank');
          } else {
            window.open(`${path}${accountName}${Routes.Search}${(isVacationRentals || forceDisableVacationRentals) ? `/${VACATIONS_PAGE}` : ''}?${locationObject}&${tokenParam}&${location}&${datesLabel}${sessionKeyParam}&${rooms}&${referenceNumberParam}&${agencyNumberParam}`, '_blank');
          }
        }
      } else if (isExperiences) {
        if (onExperience) {
          onExperience();
        }
      } else {
        onSearch();

        if (!isInitial && isAfterSearchLoad) {
          resetFilters();
          UrlUtils.setUrl(FILTERS_LABEL, null);
        }

        if (isVacationRentals) {
          setSearchHomes(HomesTypes.VacationRentalOnly);

          if (account?.restrictedVacationRentals) {
            const restrictedVacationRentalsList = account?.restrictedVacationRentalsList;
            const accomodationTypes = restrictedVacationRentalsList?.length ? restrictedVacationRentalsList : VACATIONS_FILTERED;
            setAccommodationType(accomodationTypes);
          }
        } else if (forceDisableVacationRentals) {
          setSearchHomes(HomesTypes.HotelOnly);
        }
  
        getHotels(false, false, isReuse);
      }
    }
  };

  componentDidMount(): void {
    if (!this.props.isWidget && !this.props.isExperiences) {
      if (this.props?.loginStore?.user?.accessToken) {
        this.search();
      } else {
        setTimeout(() => {
          const { locationsStore, datesStore } = this.props;
          const { isValid } = isDataValid(locationsStore, datesStore);
  
          if (isValid) {
            this.props.initializeSearch();
          }
        });
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (!prevProps?.loginStore?.user?.accessToken && this.props?.loginStore?.user?.accessToken && !this.props.isWidget && !this.props.isExperiences) {
      this.search();
    }
  }

  search = () => {
    setTimeout(() => {
      const { isWidget, locationsStore, datesStore, history, loginStore } = this.props;
      const { isValid } = isDataValid(locationsStore, datesStore);
      const { account } = loginStore;

      if (isValid) {
        const session: ISessionKey = UrlUtils.getValues()[SESSION_KEY_LABEL] as ISessionKey;
        const isReuse = (session && session.expireDate && getTimeout(session.expireDate) > ZERO);
          
        this.onSearch(true, isReuse as boolean);
      } else {    
        if (account?.isDisableHomes && !isWidget) {
          if (isEmpty(account?.disableHomesRedirect)) {
            history.push(Routes.NotAuthorized);
          } else {
            if (account?.disableHomesRedirect) {
              window.location.replace(account?.disableHomesRedirect);
            }
          }
        }
      }
    });
  }

  handleSelect = () => {
    const { isAfterSearchLoad } = this.props;
    if (isAfterSearchLoad) {
      this.onSearch();
    }
  }

  render(): React.ReactNode {
    const { isExperiences, isAfterSearchLoad, isVacationRentals, loginStore, hotelsStore, disableLocationSearch, openOnClick } = this.props;
    const { loading, account } = loginStore;
    const { mapLoading } = hotelsStore;

    const loadingSearch = hotelsStore.loading;
    const isAllInclusivePage = isAllInclusiveFromPath(this.props.history);
    const showImpersonate = CanImpersonate(account as IAccount) && !IsImpersonating() && !isAfterSearchLoad && !account?.disableImpersonate && account?.forceImpersonate;

    return (
      <>
        <LocationsPicker disable={disableLocationSearch} isExperiences={isExperiences} openOnClick={openOnClick} />
        <DatePicker onSelect={this.handleSelect} isUpdateSearch={isAfterSearchLoad} />
        <RoomPicker onSelect={this.handleSelect} isUpdateSearch={isAfterSearchLoad} />
        <BlueButton className="search-button" disabled={loading || loadingSearch || mapLoading || showImpersonate} onClick={() => this.onSearch()}>
          {isAfterSearchLoad ? (
            <>
              <UpdateSvg />
              <FormattedMessage id="update.search.your.hotel" />
            </>
          ) : (
            <>
              <SearchSvg />
              <FormattedMessage id={isVacationRentals ? 'search' : isAllInclusivePage ? 'find.all.inclusive' : 'find.your.hotel'} />
            </>
          )}
        </BlueButton>
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    datesStore: state.datesStore,
    roomsStore: state.roomsStore,
    locationsStore: state.locationsStore,
    hotelsStore: state.hotelsStore,
    loginStore: state.loginStore,
    filtersStore: state.filtersStore,
    menuStore: state.navigationMenuStore,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  validateLocation: (error: string) => {
    dispatch(locationActions.setError(error));
  },
  validateDates: (error: string) => {
    dispatch(datesActions.setError(error));
  },
  initializeSearch: () => {
    dispatch(initializeSearch());
  },
  getHotels: (
    isLoadMore: boolean,
    isFiltersChange: boolean,
    isReuseSessionKey: boolean,
  ) => {
    dispatch(
      getHotels(
        NULL_VALUE,
        isLoadMore,
        isFiltersChange,
        isReuseSessionKey,
      ),
    );
  },
  resetFilters: () => {
    dispatch(filtersActions.resetFilters());
  },
  setSearchHomes: (value: string) => {
    dispatch(filtersActions.setSearchHomes(value as HomesTypes));
  },
  setAccommodationType: (value: string[]) => {
    dispatch(filtersActions.setAccommodationType(value));
  }
});

export const SearchHotel = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(SearchHotelComponent)));
