import React from 'react';
import { AllInclusivesWrapper } from '@components';
import { scrollTop } from '@share/utils';

import './style.scss';

interface IProps {}

export default class AllInclusivesPage extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);

    scrollTop();
  }

  render(): React.ReactNode {
    return <AllInclusivesWrapper />;
  }
}
