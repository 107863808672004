import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ICondoAmenitiesFacilities, IDetails } from '@share/common-types';

import './style.scss';

interface IProps {
  condoDetails: IDetails;
  refAnchor: React.RefObject<HTMLDivElement>;
}

interface IState {
  isFullAmenities: boolean;
}

const ZERO = 0;
const MAX_AMENITIES_COUNT = 9;

export class CondoAmenities extends React.Component<IProps, IState> {
  state: IState = {
    isFullAmenities: false,
  };

  toggleAmenities = (): void => {
    this.setState(({ isFullAmenities }) => ({
      isFullAmenities: !isFullAmenities,
    }));
  };

  getDistance = (amenity: ICondoAmenitiesFacilities): React.ReactNode => {
    return (
      (amenity.distanceInKms > ZERO || amenity.distanceInMiles > ZERO) && (
        <p className="condo-amenities__distance">
          (
          {amenity.distanceInKms > ZERO ? (
            <FormattedMessage id="distance.km" values={{ distance: amenity.distanceInKms }} />
          ) : null}
          {amenity.distanceInKms > ZERO && amenity.distanceInMiles > ZERO && '/'}
          {amenity.distanceInMiles > ZERO ? (
            <FormattedMessage id="distance.miles" values={{ distance: amenity.distanceInMiles }} />
          ) : null}
          )
        </p>
      )
    );
  };

  render(): React.ReactNode {
    const { condoDetails, refAnchor } = this.props;
    const { isFullAmenities } = this.state;

    const amenitiesFacilities = [...condoDetails.amenities, ...condoDetails.facilities];
    const amenitiesToShow: ICondoAmenitiesFacilities[] = isFullAmenities
      ? amenitiesFacilities
      : amenitiesFacilities.slice(ZERO, MAX_AMENITIES_COUNT);

    return (
      <div className="condo-amenities" ref={refAnchor}>
        <h2 className="condo-amenities__title secondary-font">
          <FormattedMessage id="tab.amenities.facilities" />
        </h2>
        <div className="condo-amenities__items">
          {amenitiesToShow.map((amenity, index) => {
            return (
              <div className="condo-amenities__item" key={index}>
                <span className="condo-amenities__text">{amenity.name}</span>
                {amenity.value ? (
                  <span className="condo-amenities__value">{amenity.value}</span>
                ) : null}
                <div className="condo-amenities__proximity">
                  {amenity.proximity ? (
                    <FormattedMessage id={`proximity.${amenity.proximity}.title`} />
                  ) : null}
                  {this.getDistance(amenity)}
                </div>
              </div>
            );
          })}
        </div>
        {amenitiesFacilities.length > MAX_AMENITIES_COUNT && (
          <div className="condo-amenities__show-link" onClick={this.toggleAmenities}>
            {isFullAmenities ? (
              <FormattedMessage id="show.less.amenities" />
            ) : (
              <FormattedMessage
                id="show.all.amenities"
                values={{ count: amenitiesFacilities.length }}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

