import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SortTypes } from '@share/constants';

const DEFAULT_CONDO_DISTANCE_IN_MILES = 20;

export interface ICondoFiltersState {
  propertyName: string;
  budgetRanges: string[];
  guestRatings: string[];
  distanceInMiles: number;
  unitAmenities: string[];
  facilities: string[];
  activities: string[];
  condoTypes: string[];
  policies: string[];
  accessibilities: string[];
  suppliers: string[];
  neighbourhoods: string[];
  places: string[];
  kitchenTypes: string[];
  bathrooms: number[];
  days: number[];
  months: number[];
  sortType: SortTypes;
  isAllInclusiveOnly: boolean;
}

export const condoFiltersInitialState: ICondoFiltersState = {
  propertyName: '',
  budgetRanges: [],
  guestRatings: [],
  distanceInMiles: DEFAULT_CONDO_DISTANCE_IN_MILES,
  unitAmenities: [],
  facilities: [],
  activities: [],
  condoTypes: [],
  policies: [],
  accessibilities: [],
  suppliers: [],
  neighbourhoods: [],
  places: [],
  kitchenTypes: [],
  bathrooms: [],
  days: [],
  months: [],
  sortType: SortTypes.PriceAsc,
  isAllInclusiveOnly: false,
};

const condoFiltersSlice = createSlice({
  name: 'condoFilters',
  initialState: condoFiltersInitialState,
  reducers: {
    setSortType: (state: ICondoFiltersState, { payload }: PayloadAction<SortTypes>) => {
      state.sortType = payload;
    },
    setBudgetRanges: (state: ICondoFiltersState, { payload }: PayloadAction<string[]>) => {
      state.budgetRanges = payload;
    },
    setGuestRatings: (state: ICondoFiltersState, { payload }: PayloadAction<string[]>) => {
      state.guestRatings = payload;
    },
    setDistanceInMiles: (state: ICondoFiltersState, { payload }: PayloadAction<number>) => {
      state.distanceInMiles = payload;
    },
    setUnitAmenities: (state: ICondoFiltersState, { payload }: PayloadAction<string[]>) => {
      state.unitAmenities = payload;
    },
    setFacilities: (state: ICondoFiltersState, { payload }: PayloadAction<string[]>) => {
      state.facilities = payload;
    },
    setActivities: (state: ICondoFiltersState, { payload }: PayloadAction<string[]>) => {
      state.activities = payload;
    },
    setCondoTypes: (state: ICondoFiltersState, { payload }: PayloadAction<string[]>) => {
      state.condoTypes = payload;
    },
    setPolicies: (state: ICondoFiltersState, { payload }: PayloadAction<string[]>) => {
      state.policies = payload;
    },
    setAccessibilities: (state: ICondoFiltersState, { payload }: PayloadAction<string[]>) => {
      state.accessibilities = payload;
    },
    setSuppliers: (state: ICondoFiltersState, { payload }: PayloadAction<string[]>) => {
      state.suppliers = payload;
    },
    setNeighbourhoods: (state: ICondoFiltersState, { payload }: PayloadAction<string[]>) => {
      state.neighbourhoods = payload;
    },
    setPlaces: (state: ICondoFiltersState, { payload }: PayloadAction<string[]>) => {
      state.places = payload;
    },
    setKitchenTypes: (state: ICondoFiltersState, { payload }: PayloadAction<string[]>) => {
      state.kitchenTypes = payload;
    },
    setAllInclusive: (state: ICondoFiltersState, { payload }: PayloadAction<boolean>) => {
      state.isAllInclusiveOnly = payload;
    },
    setBathrooms: (state: ICondoFiltersState, { payload }: PayloadAction<number[]>) => {
      state.bathrooms = payload;
    },
    setDays: (state: ICondoFiltersState, { payload }: PayloadAction<number[]>) => {
      state.days = payload;
    },
    setMonths: (state: ICondoFiltersState, { payload }: PayloadAction<number[]>) => {
      state.months = payload;
    },
    setPropertyName: (state: ICondoFiltersState, { payload }: PayloadAction<string>) => {
      state.propertyName = payload;
    },
    resetFilters: () => {
      return condoFiltersInitialState;
    },
    setFilters: (state: ICondoFiltersState, { payload }: PayloadAction<ICondoFiltersState>) => {
      return payload;
    },
  },
});

export const condoFiltersActions = condoFiltersSlice.actions;

export const condoFiltersReducer = condoFiltersSlice.reducer;
