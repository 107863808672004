import React from 'react';

import { IHotelDetails } from '@common-types';

import { ReviewsBase } from '@components';

import './style.scss';

interface IProps {
  hotel: IHotelDetails;
  refAnchor: React.RefObject<HTMLDivElement>;
}

export function Reviews(props: IProps) {

  const { hotel, refAnchor } = props;

  return <ReviewsBase guestRatings={hotel?.guestRatings} refAnchor={refAnchor} />;
}
