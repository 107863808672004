
import React, { useMemo } from 'react';
import moment from 'moment';

import { connect } from 'react-redux';
import { Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

import { RootState } from '@share/utils';

import { IDashboardReportState } from '@store/slices';

import { FormattedMessage } from 'react-intl';
import { Loading, LoadingSise } from '@share/components';

import { MONTHLY } from '../component';

import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { DashboardReportEmpty } from '../dashboard-report-empty';

interface IMapStateToProps {
  dashboardReportsStore: IDashboardReportState;
}

interface IProps extends IMapStateToProps {
  period: string;
}

function DashboardReportBookingsTotalPieComponent(props: IProps) {
  const { period, dashboardReportsStore } = props;
  const { loading } = dashboardReportsStore;

  const bookingsTotalDays = dashboardReportsStore.dashBoardData?.bookingsTotalDays;
  const bookingsTotalMonths = dashboardReportsStore.dashBoardData?.bookingsTotalMonths;

  const isMonthlyPeriod = useMemo(() => period === MONTHLY, [period]);
  const roomsBooked = useMemo(() => period === MONTHLY ? bookingsTotalMonths : bookingsTotalDays, [period, bookingsTotalMonths, bookingsTotalDays]);
  const roomsBookedProcessed = useMemo(() => roomsBooked?.map(r => ({ type: (isMonthlyPeriod ? moment(r?.date, 'yyyy-MM-DD').format('MMM') : moment(r?.date, 'yyyy-MM-DD').format('MM/DD')), savings: r?.savings, amount: r?.grossAmount })), [roomsBooked, isMonthlyPeriod]);
  const totalSavings = useMemo(() => roomsBookedProcessed?.map(r => r.savings).reduce((acc, curr) => acc + curr, 0), [roomsBookedProcessed]);
  const totalAmount = useMemo(() => roomsBookedProcessed?.map(r => r.amount).reduce((acc, curr) => acc + curr, 0), [roomsBookedProcessed]);

  const data = [
    { name: 'Amount', value: totalAmount },
    { name: 'Savings', value: totalSavings },
  ];
  
  return (
    <div className="dasshboard-reports-bookings-total-wrapper pie-chart">
      <div className="dasshboard-reports-bookings-total-wrapper__title">
        <h1><FormattedMessage id="bookings.total" /></h1>
      </div>
      <div className="dasshboard-reports-bookings-total-wrapper__content centered">
        {loading ?
          <Loading size={LoadingSise.Normal} /> : 
          (totalAmount || totalSavings) ?
            <>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart width={800} height={400}>
                  <Pie
                    data={data}
                    innerRadius={100}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                  >
                    <Tooltip />
                    <Legend />
                    <Cell key="cell-0" fill="#1d2e67" />
                    <Cell key="cell-1" fill="#00d062" />
                  </Pie>
                </PieChart>
              </ResponsiveContainer>

              <div className="content-summary amount">
                <span>
                  <FontAwesomeIcon icon={faCircle} />
                  <FormattedMessage id="amount" />
                </span>
                <label>$ {totalAmount}</label>
              </div>

              <div className="content-summary savings">
                <span>
                  <FontAwesomeIcon icon={faCircle} />
                  <FormattedMessage id="savings" />
                </span>
                <label>$ {totalSavings}</label>
              </div>
            </> :
            <DashboardReportEmpty />}

      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    dashboardReportsStore: state.dashboardReportsStore
  };
};

export const DashboardReportBookingsTotalPie = connect(mapStateToProps)(DashboardReportBookingsTotalPieComponent);
