import { useMemo } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { BlueButton } from '@share/components';
import { Routes } from '@share/constants';
import { ErrorAlertSvg } from '@assets';
import { connect } from 'react-redux';
import { getAccountUsernameFromPath } from '@share/utils';

import './style.scss';

interface IProps extends RouteComponentProps {
  isWeek?: boolean;
}

function CondoDetailsServerErrorComponent(props: IProps) {
  const { isWeek, history } = props;

  const refreshPage = (): void => {
    window.location.reload();
  };

  const accountName = useMemo(() => getAccountUsernameFromPath(history), [history]);

  return (
    <div className="condo-details-server-error">
      <div className="condo-details-server-error__wrapper">
        <div className="condo-details-server-error__icon">
          <ErrorAlertSvg />
        </div>
        <div className="condo-details-server-error__header secondary-font">
          <FormattedMessage id="problem.with.our.system" />
        </div>
        <div className="condo-details-server-error__description">
          <FormattedMessage id="we.are.working.on.the.problem.try" />{' '}
          <a onClick={refreshPage}>
            <FormattedMessage id="refresh" />
          </a>{' '}
          <FormattedMessage id="this.page" />
        </div>
        <div className="condo-details-server-error__footer">
          <Link to={`/${accountName}${isWeek ? Routes.WeeksSearch : Routes.CondoSearch}${location.search}`}>
            <BlueButton>
              <FormattedMessage id="back" />
            </BlueButton>
          </Link>
        </div>
      </div>
    </div>
  );
}

export const CondoDetailsServerError = connect()(withRouter(CondoDetailsServerErrorComponent));
