import React from 'react';

import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

import { ILoginState, ResetCarsFullWithParams } from '@share/store/slices';
import { getAccountUsernameFromPath, GetHomeParams, RootState } from '@share/utils';
import { GetawaysLocationEnum } from '@share/common-types';
import { Routes } from '@share/constants';

import './style.scss';
import { ICarsReviewBookState, resetCarsState } from '@store/slices';

interface IMapStateToProps {
  loginStore: ILoginState;
  carsReviewBookStore: ICarsReviewBookState;
}

interface IMapDispatchToProps {
  resetCarsFull: () => void;
  resetCarsReview: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  dealId: number;
  backHomeType: string;

  isCheckout: boolean;
  isMobile: boolean;

  onBack: () => void;
}

class CarsDetailsReturnComponent extends React.Component<IProps> {
  
  render(): React.ReactNode {
    const { carsReviewBookStore, loginStore, dealId, backHomeType, isCheckout, isMobile, onBack } = this.props;
    const { account } = loginStore;
    const { bookingComplete } = carsReviewBookStore;

    const accountName = getAccountUsernameFromPath(this.props.history);
    
    if (bookingComplete) {
      return null;
    }

    if (isCheckout) {
      return (
        <div className={`hotel-details-wrapper__back-link ${isMobile ? 'mobile' : 'desktop'}`}>
          <div style={{ color: 'var(--pacific-blue)', cursor: 'pointer', fontSize: '13px' }} onClick={onBack}>
            <FormattedMessage id="availability.status.modal.back_detail" />
          </div>
        </div>
      );
    }

    return (
      <div className={`hotel-details-wrapper__back-link ${isMobile ? 'mobile' : 'desktop'}`}>
          {!dealId ? (
              <Link to={`/${accountName}${Routes.CarsSearch}${location.search}`}>
                <FormattedMessage id="back.to.list" />
              </Link>) : 
              !isEmpty(backHomeType) ? 
                backHomeType === 'HOME' ? (
                  <Link to={`/${accountName}${Routes.Home}`}>
                    <FormattedMessage id="confirmation.go.back_home" />
                  </Link>) : (
                  <Link to={null} onClick={() => {
                    const homeParams = GetHomeParams(account);
                    this.props.resetCarsFull();
                    this.props.resetCarsReview();
                    this.props.history.push(`/${accountName}${Routes.CarsSearch}${homeParams}`);
                  }}>
                    <FormattedMessage id="confirmation.go.back_home" />
                  </Link>) : (
                <Link to={`/${accountName}${Routes.Getaway}/${GetawaysLocationEnum.Staycation}`}>
                  <FormattedMessage id="back.to.staycations" />
                </Link>)}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    carsReviewBookStore: state.carsReviewBookStore,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  resetCarsFull: () => {
    dispatch(ResetCarsFullWithParams())
  },
  resetCarsReview: () => {
    dispatch(resetCarsState());
  },
});

export const CarsDetailsReturn = connect(mapStateToProps, mapDispatchToProps)(withRouter(CarsDetailsReturnComponent));

