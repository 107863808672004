import React, { useCallback, useEffect } from 'react';
import Modal from 'antd/es/modal';
import Tabs from 'antd/es/tabs';

import uniq from 'lodash/uniq';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { isVideo } from '@utils';
import { IMedia, IImagesForCarousel } from '@share/common-types';

import './style.scss';

interface IProps {
  media: IMedia[];
  visible: boolean;
  title: string;
  startIndex: number;
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>, isScrollToRooms?: boolean) => void;
  isVideoError: boolean;
}

const ZERO = 0;
const ONE = 1;

export function CondoImagesSlider(props: IProps) {
  const { TabPane } = Tabs;
  const { media, isVideoError, visible, title, startIndex, onCancel } = props;

  const getImagesByTags = useCallback(() => {
    const mediaFiltered = media? media.filter((m: any) => !!m.tags) : [];
    const imageTags = uniq(mediaFiltered.reduce((r: any, e: any) => (r.push(...e.tags), r), []));

    return imageTags.map((item) => {
      return media
        .map((image: any) => {
          if (image.tags.some((tags: any) => tags === item)) {
            return {
              original: isVideo(image.url) ? media[ZERO].url : image.url,
              thumbnail: isVideo(image.url) ? media[ZERO].url : image.url,
              embedUrl: isVideo(image.url) ? image.url : '',
              tag: item,
            };
          }
        })
        .filter((item: any) => item !== undefined);
    });
  }, [media]);

  const getAllImages = (): IImagesForCarousel[] => {
    return media.map((image: any) => {
      return {
        original: isVideo(image.url) ? media[ZERO].url : image.url,
        thumbnail: isVideo(image.url) ? media[ZERO].url : image.url,
        embedUrl: isVideo(image.url) ? image.url : '',
      };
    });
  };

  const renderVideo = (item: any): React.ReactNode => {
    return !isVideoError ? (
      <div className="image-gallery-image">
        <video width="100%" height="100%" controls loop>
          <source src={item.embedUrl} />
        </video>
      </div>
    ) : (
      <div>
        <img className="image-gallery-image" src={item.original} alt={item.original} />
      </div>
    );
  };

  useEffect(() => {
    getImagesByTags();
  }, []);

  return (
    <Modal
      className="condo-images-slider"
      visible={visible}
      footer={null}
      onCancel={onCancel}
      wrapClassName="condo-images-slider-wrapper"
    >
      <div className="condo-images-slider__header">
        <h4 className="condo-images-slider__title">{title}</h4>
      </div>
      <Tabs defaultActiveKey={`${ZERO}`}>
        <TabPane tab="All photos" key={ZERO}>
          <ImageGallery
            startIndex={startIndex}
            showIndex
            items={getAllImages()}
            showPlayButton
            renderItem={renderVideo}
          />
        </TabPane>
        {getImagesByTags().map((image, index) => {
          return (
            <TabPane tab={image[ZERO]?.tag} key={index + ONE}>
              <ImageGallery
                showIndex
                items={image as ReactImageGalleryItem[]}
                showPlayButton
                renderItem={renderVideo}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </Modal>
  );
}

