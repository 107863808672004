import React from 'react';
import { Skeleton } from 'antd';
import './style.scss';

const ONE_ROW = 1;
const NUMBER_OF_BLOCKS = [ONE_ROW, ONE_ROW, ONE_ROW, ONE_ROW];

interface IProps {}

export class GetawayDetailsSkeleton extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <div className="getaway__container getaway__details-skeleton">
        <Skeleton
          className="getaway__details-skeleton_link"
          active
          title={false}
          paragraph={{ rows: ONE_ROW }}
        />
        <div className="getaway__details-skeleton_wrapper">
          <div className="getaway__details-skeleton_header">
            <div>
              <Skeleton
                className="getaway__details-skeleton_header_title"
                active
                title={false}
                paragraph={{ rows: ONE_ROW }}
              />
              <Skeleton
                className="getaway__details-skeleton_header_promo"
                active
                title={false}
                paragraph={{ rows: ONE_ROW }}
              />
            </div>
            <div>
              <Skeleton
                className="getaway__details-skeleton_header_price"
                active
                title={false}
                paragraph={{ rows: ONE_ROW }}
              />
              <Skeleton
                className="getaway__details-skeleton_header_book"
                active
                title={false}
                paragraph={{ rows: ONE_ROW }}
              />
            </div>
          </div>
          <Skeleton
            className="getaway__details-skeleton_media"
            active
            title={false}
            paragraph={{ rows: ONE_ROW }}
          />
          <div className="getaway__details-skeleton_content">
            <div className="getaway__details-skeleton_info">
              <div className="getaway__details-skeleton_info_wrapper">
                <Skeleton
                  className="getaway__details-skeleton_info_title"
                  active
                  title={false}
                  paragraph={{ rows: ONE_ROW }}
                />
                {NUMBER_OF_BLOCKS.map((item, index) => (
                  <Skeleton
                    key={`${index}-${item}`}
                    className="getaway__details-skeleton_info_text"
                    active
                    title={false}
                    paragraph={{ rows: ONE_ROW }}
                  />
                ))}
              </div>
              <div className="getaway__details-skeleton_info_wrapper">
                <Skeleton
                  className="getaway__details-skeleton_info_title"
                  active
                  title={false}
                  paragraph={{ rows: ONE_ROW }}
                />
                {NUMBER_OF_BLOCKS.map((item, index) => (
                  <Skeleton
                    key={`${index}-${item}`}
                    className="getaway__details-skeleton_info_text"
                    active
                    title={false}
                    paragraph={{ rows: ONE_ROW }}
                  />
                ))}
              </div>
            </div>
            <Skeleton
              className="getaway__details-skeleton_sidebar"
              active
              title={false}
              paragraph={{ rows: ONE_ROW }}
            />
          </div>
        </div>
      </div>
    );
  }
}
