import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SortTypes } from '@share/constants';

export interface ICarsFiltersState {
  sortBy: SortTypes;

  simpleTypeQuick: string[];
  rateDistanceQuick: string[];
  rentalCompanyQuick: string[];
  fuelQuick: string[];

  airCondition: string[];
  budget: string[];
  carType: string[];
  simpleType: string[];
  category: string[];
  doors: string[];
  driveType: string[];
  fuel: string[];
  rateDistance: string[];
  rentalCompany: string[];
  seats: string[];
  transmissionType: string[];
  refundable: string[];
  whereLocation: string[];
}

export const CarsFiltersInitialState: ICarsFiltersState = {
  sortBy: SortTypes.BrandNames,

  simpleTypeQuick: [],
  rateDistanceQuick: [],
  rentalCompanyQuick: [],
  fuelQuick: [],

  airCondition: [],
  budget: [],
  carType: [],
  simpleType: [],
  category: [],
  doors: [],
  driveType: [],
  fuel: [],
  rateDistance: [],
  rentalCompany: [],
  seats: [],
  transmissionType: [],
  refundable: [],
  whereLocation: [],
};

const carsFiltersSlice = createSlice({
  name: 'carsFilters',
  initialState: CarsFiltersInitialState,
  reducers: {
    setSortBy: (state: ICarsFiltersState, { payload }: PayloadAction<SortTypes>) => {
      state.sortBy = payload;
    },

    setSimpleTypeQuick: (state: ICarsFiltersState, { payload }: PayloadAction<string[]>) => {
      state.simpleTypeQuick = payload;
    },
    setRateDistanceQuick: (state: ICarsFiltersState, { payload }: PayloadAction<string[]>) => {
      state.rateDistanceQuick = payload;
    },
    setRentalCompanyQuick: (state: ICarsFiltersState, { payload }: PayloadAction<string[]>) => {
      state.rentalCompanyQuick = payload;
    },
    setFuelQuick: (state: ICarsFiltersState, { payload }: PayloadAction<string[]>) => {
      state.fuelQuick = payload;
    },

    setAirCondition: (state: ICarsFiltersState, { payload }: PayloadAction<string[]>) => {
      state.airCondition = payload;
    },
    setBudget: (state: ICarsFiltersState, { payload }: PayloadAction<string[]>) => {
      state.budget = payload;
    },
    setCarType: (state: ICarsFiltersState, { payload }: PayloadAction<string[]>) => {
      state.carType = payload;
    },
    setSimpleType: (state: ICarsFiltersState, { payload }: PayloadAction<string[]>) => {
      state.simpleType = payload;
    },
    setCategory: (state: ICarsFiltersState, { payload }: PayloadAction<string[]>) => {
      state.category = payload;
    },
    setDoors: (state: ICarsFiltersState, { payload }: PayloadAction<string[]>) => {
      state.doors = payload;
    },
    setDriveType: (state: ICarsFiltersState, { payload }: PayloadAction<string[]>) => {
      state.driveType = payload;
    },
    setFuel: (state: ICarsFiltersState, { payload }: PayloadAction<string[]>) => {
      state.fuel = payload;
    },
    setRateDistance: (state: ICarsFiltersState, { payload }: PayloadAction<string[]>) => {
      state.rateDistance = payload;
    },
    setRentalCompany: (state: ICarsFiltersState, { payload }: PayloadAction<string[]>) => {
      state.rentalCompany = payload;
    },
    setSeats: (state: ICarsFiltersState, { payload }: PayloadAction<string[]>) => {
      state.seats = payload;
    },
    setTransmissionType: (state: ICarsFiltersState, { payload }: PayloadAction<string[]>) => {
      state.transmissionType = payload;
    },
    setRefundable: (state: ICarsFiltersState, { payload }: PayloadAction<string[]>) => {
      state.refundable = payload;
    },
    setWhereLocation: (state: ICarsFiltersState, { payload }: PayloadAction<string[]>) => {
      state.whereLocation = payload;
    },

    applyCarsFiltersQuicks: (state: ICarsFiltersState) => {
      state.simpleType = state.simpleTypeQuick;
      state.rateDistance = state.rateDistanceQuick;
      state.rentalCompany = state.rentalCompanyQuick;
      state.fuel = state.fuelQuick;
    },

    resetFilters: (state: ICarsFiltersState, { payload }: PayloadAction<boolean>) => {
      const simpleTypeQuick = state.simpleTypeQuick;
      const rateDistanceQuick = state.rateDistanceQuick;
      const rentalCompanyQuick = state.rentalCompanyQuick;
      const fuelQuick = state.fuelQuick;

      const response = { ...CarsFiltersInitialState };

      if (payload) {
        response.simpleTypeQuick = simpleTypeQuick;
        response.rateDistanceQuick = rateDistanceQuick;
        response.rentalCompanyQuick = rentalCompanyQuick;
        response.fuelQuick = fuelQuick;
      }

      return response;
    },
    setFilters: (state: ICarsFiltersState, { payload }: PayloadAction<ICarsFiltersState>) => {
      return payload;
    },
  },
});

export const carsFiltersActions = carsFiltersSlice.actions;

export const carsFiltersReducer = carsFiltersSlice.reducer;
