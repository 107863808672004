import React, { useEffect, useMemo, useState } from 'react';

import debounce from 'lodash/debounce';
import Select from 'antd/lib/select';

import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { ILoginState, IWeeksDatesState, weeksDatesActions } from '@share/store/slices';
import { RootState } from '@share/utils';
import { DEFAULT_MEDIA_POINT } from '@share/constants';

import { faClockRotateLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.scss';
import { Tooltip } from 'antd';

const focusZIndex = 4;
const blurZIndex = 1;

interface IMapStateToProps {
  weeksDatesStore: IWeeksDatesState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setPeriodSelected: (period: string) => void;

  clearError: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  isAfterSearchLoad: boolean;

  displayPeriods?: boolean;
}

const resizeDebounceTime = 300;

export function WeeksPeriodPickerComponent(props: IProps) {
  const [isFocused, setIsFocused] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const { isAfterSearchLoad, displayPeriods, weeksDatesStore, loginStore, intl, setPeriodSelected, clearError } = props;

  const { account } = loginStore;

  const color: any = account?.searchIconsColor;

  const styleColor = useMemo(() => !isEmpty(color)? { color } : {},[color]);

  const period = weeksDatesStore.periodSelected;

  useEffect(() => {
    setIsMobile(document.body.offsetWidth <= DEFAULT_MEDIA_POINT);

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, []);

  const onFocus = (open: boolean): void => {
    setIsFocused(open);
  };

  const onResize = debounce(() => {
    if (document.body.offsetWidth <= DEFAULT_MEDIA_POINT && !isMobile) {
      setIsMobile(true);
    } else if (document.body.offsetWidth > DEFAULT_MEDIA_POINT && isMobile) {
      setIsMobile(false);
    }
  }, resizeDebounceTime);

  const onSelect = (period: string): void => {
    clearError();
    setPeriodSelected(period);
  };

  if (!displayPeriods) {
    return (
      <div className="date-picker weeks-period-container only-text">
        <Tooltip placement="top" title={intl.formatMessage({ id: 'weeks.dynamic.date.message' })}><FormattedMessage id="weeks.dynamic.date" />&#8482;<FontAwesomeIcon icon={faInfoCircle} /></Tooltip>
      </div>);
  }

  const generalBorderRadius: any = account?.generalBorderRadius ? account?.generalBorderRadius : null;
  const style = !isEmpty(generalBorderRadius) ? { borderRadius: generalBorderRadius } : {};  

  return (
    <div className="date-picker weeks-period-container">
      <div
        className={`date-picker__wrapper dates-selection ${isAfterSearchLoad ? 'after-search' : ''} ${isFocused ? 'open' : ''}`}
        style={{ ...style, zIndex: isFocused ? focusZIndex : blurZIndex }}
      >
        {!isAfterSearchLoad ? (
          <div className="date-picker__left">
            <div className="date-picker__icon" style={styleColor}>
              <FontAwesomeIcon icon={faClockRotateLeft} />
            </div>
          </div>) : null}

        <div className={`date-picker__right ${isFocused ? 'dates-focused' : ''}`}>
          <div className="date-picker__label">
            <FormattedMessage id="within" />
          </div>
          <div className="date-picker__header">
            <Select
              placeholder={intl.formatMessage({ id: 'within' })}
              value={period}
              onChange={onSelect}
              onDropdownVisibleChange={onFocus}
              dropdownStyle={{ top: '25px' }}
              popupClassName="date-picker__weeks-dropdown"
            >
              <Select.Option value={'7'}><FormattedMessage id="days" values={{ count: 7 }} /></Select.Option>
              <Select.Option value={'10'}><FormattedMessage id="days" values={{ count: 10 }} /></Select.Option>
              <Select.Option value={'14'}><FormattedMessage id="days" values={{ count: 14 }} /></Select.Option>
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    weeksDatesStore: state.weeksDatesStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  setPeriodSelected: (period: string) => dispatch(weeksDatesActions.setPeriodSelected(period)),
  clearError: () => dispatch(weeksDatesActions.setErrorPeriod('')),
});

export const WeeksPeriodPicker = connect(mapStateToProps, mapDispatchToProps)(injectIntl(WeeksPeriodPickerComponent));
