import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITravelHistory } from '@share/common-types';
import { AppThunk, Toaster } from '@share/utils';
import { TravelHistoryAPI } from '@share/api';

export interface ITravelHistoryState {
  loading: boolean;
  error: string;
  result: {
    histories: ITravelHistory[];
  }
}

const initialState: ITravelHistoryState = {
  loading: true,
  error: '',
  result: {
    histories: []
  }
};

const travelHistorySlice = createSlice({
  name: 'travelHistory',
  initialState,
  reducers: {
    setLoading: (state: ITravelHistoryState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: ITravelHistoryState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setTravelHistory: (state: ITravelHistoryState, { payload }: PayloadAction<ITravelHistory[]>) => {
      state.result.histories = payload;
    }
  },
});

export const travelHistoryActions = travelHistorySlice.actions;

export const travelHistoryReducer = travelHistorySlice.reducer;

export const getTravelHistory = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(travelHistoryActions.setLoading(true));
    dispatch(travelHistoryActions.setTravelHistory([]));

    try {
      const { loginStore } = getState();
      const { user } = loginStore;

      const response = await TravelHistoryAPI.get(user.keyid);

      dispatch(travelHistoryActions.setLoading(false));

      if (response.status === 200) {
        const { data } = response;

        dispatch(travelHistoryActions.setTravelHistory(data));
      } else {
        dispatch(travelHistoryActions.setError(`[${response.status}] ${response.statusText}`));
        Toaster.error(`An error occurred. [${response.status}] ${response.statusText}. If the problem persists, please contact the system administrator.`);
      }
    } catch (error: any) {
      console.error(error);
      dispatch(travelHistoryActions.setError(error.toString()));
      dispatch(travelHistoryActions.setLoading(false));
      Toaster.error(`An error occurred: ${error.toString()}. If the problem persists, please contact the system administrator.`);
    }
  };
};