import React from 'react';

export const CalendarNotificationSvg: React.FunctionComponent = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.2" cx="18" cy="18" r="18" fill="#F2994A" />
    <path
      d="M18.8086 21.7969C18.8086 22.5723 19.4394 23.2031 20.2148 23.2031H21.8672C22.6426 23.2031 23.2734 22.5723 23.2734 21.7969V20.1445C23.2734 19.3691 22.6426 18.7383 21.8672 18.7383H20.2148C19.4394 18.7383 18.8086 19.3691 18.8086 20.1445V21.7969ZM20.2148 20.1445H21.8672L21.8681 21.7968C21.8681 21.7968 21.8678 21.7969 21.8672 21.7969H20.2148V20.1445Z"
      fill="#F2994A"
    />
    <path
      d="M22.5703 17.0859C22.9586 17.0859 23.2734 16.7711 23.2734 16.3828C23.2734 15.9945 22.9586 15.6797 22.5703 15.6797C22.182 15.6797 21.8672 15.9945 21.8672 16.3828C21.8672 16.7711 22.182 17.0859 22.5703 17.0859Z"
      fill="#F2994A"
    />
    <path
      d="M19.5117 17.0859C19.9 17.0859 20.2148 16.7711 20.2148 16.3828C20.2148 15.9945 19.9 15.6797 19.5117 15.6797C19.1234 15.6797 18.8086 15.9945 18.8086 16.3828C18.8086 16.7711 19.1234 17.0859 19.5117 17.0859Z"
      fill="#F2994A"
    />
    <path
      d="M26.2969 21.375C26.6852 21.375 27 21.0602 27 20.6719V13.2188C27 11.6679 25.7383 10.4062 24.1875 10.4062H23.2734V9.70312C23.2734 9.31479 22.9586 9 22.5703 9C22.182 9 21.8672 9.31479 21.8672 9.70312V10.4062H18.668V9.70312C18.668 9.31479 18.3532 9 17.9648 9C17.5765 9 17.2617 9.31479 17.2617 9.70312V10.4062H14.0977V9.70312C14.0977 9.31479 13.7829 9 13.3945 9C13.0062 9 12.6914 9.31479 12.6914 9.70312V10.4062H11.8125C10.2617 10.4062 9 11.6679 9 13.2188V24.1875C9 25.7383 10.2617 27 11.8125 27H24.1875C25.7383 27 27 25.7383 27 24.1875C27 23.7992 26.6852 23.4844 26.2969 23.4844C25.9085 23.4844 25.5938 23.7992 25.5938 24.1875C25.5938 24.9629 24.9629 25.5938 24.1875 25.5938H11.8125C11.0371 25.5938 10.4062 24.9629 10.4062 24.1875V13.2188C10.4062 12.4433 11.0371 11.8125 11.8125 11.8125H12.6914V12.5156C12.6914 12.904 13.0062 13.2188 13.3945 13.2188C13.7829 13.2188 14.0977 12.904 14.0977 12.5156V11.8125H17.2617V12.5156C17.2617 12.904 17.5765 13.2188 17.9648 13.2188C18.3532 13.2188 18.668 12.904 18.668 12.5156V11.8125H21.8672V12.5156C21.8672 12.904 22.182 13.2188 22.5703 13.2188C22.9586 13.2188 23.2734 12.904 23.2734 12.5156V11.8125H24.1875C24.9629 11.8125 25.5938 12.4433 25.5938 13.2188V20.6719C25.5938 21.0602 25.9085 21.375 26.2969 21.375Z"
      fill="#F2994A"
    />
    <path
      d="M13.3945 23.2031C13.7829 23.2031 14.0977 22.8883 14.0977 22.5C14.0977 22.1117 13.7829 21.7969 13.3945 21.7969C13.0062 21.7969 12.6914 22.1117 12.6914 22.5C12.6914 22.8883 13.0062 23.2031 13.3945 23.2031Z"
      fill="#F2994A"
    />
    <path
      d="M13.3945 17.0859C13.7829 17.0859 14.0977 16.7711 14.0977 16.3828C14.0977 15.9945 13.7829 15.6797 13.3945 15.6797C13.0062 15.6797 12.6914 15.9945 12.6914 16.3828C12.6914 16.7711 13.0062 17.0859 13.3945 17.0859Z"
      fill="#F2994A"
    />
    <path
      d="M13.3945 20.1445C13.7829 20.1445 14.0977 19.8297 14.0977 19.4414C14.0977 19.0531 13.7829 18.7383 13.3945 18.7383C13.0062 18.7383 12.6914 19.0531 12.6914 19.4414C12.6914 19.8297 13.0062 20.1445 13.3945 20.1445Z"
      fill="#F2994A"
    />
    <path
      d="M16.4531 20.1445C16.8415 20.1445 17.1562 19.8297 17.1562 19.4414C17.1562 19.0531 16.8415 18.7383 16.4531 18.7383C16.0648 18.7383 15.75 19.0531 15.75 19.4414C15.75 19.8297 16.0648 20.1445 16.4531 20.1445Z"
      fill="#F2994A"
    />
    <path
      d="M16.4531 17.0859C16.8415 17.0859 17.1562 16.7711 17.1562 16.3828C17.1562 15.9945 16.8415 15.6797 16.4531 15.6797C16.0648 15.6797 15.75 15.9945 15.75 16.3828C15.75 16.7711 16.0648 17.0859 16.4531 17.0859Z"
      fill="#F2994A"
    />
    <path
      d="M16.4531 23.2031C16.8415 23.2031 17.1562 22.8883 17.1562 22.5C17.1562 22.1117 16.8415 21.7969 16.4531 21.7969C16.0648 21.7969 15.75 22.1117 15.75 22.5C15.75 22.8883 16.0648 23.2031 16.4531 23.2031Z"
      fill="#F2994A"
    />
  </svg>
);
