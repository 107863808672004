import React from 'react';
import ReactGA from 'react-ga4';

import { Action } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { FormattedMessage } from 'react-intl';
import { UrlUtils, getNormalizedRoomsResponse } from '@share/utils';
import { RootState } from '@share/utils';
import { FILTERS_LABEL, H_CLICK_RECENT_SEARCH, HomesTypes } from '@share/constants';
import {
  getHotels,
  datesActions,
  roomsActions,
  filtersActions,
  locationActions,
  getHotelsRecentSearches,
  ILoginState,
  VACATIONS_FILTERED,
} from '@share/store/slices';
import { HotelSearchArgs } from '@common-types';
import { IRoom, ILocation, IHotelsRecentSearches, IAccount } from '@share/common-types';
import { RecentSearches } from '@components';

import './style.scss';

interface IMapStateToProps {
  recentSearches: IHotelsRecentSearches[];
  loadingRecentSearches: boolean;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setRooms: (rooms: IRoom[]) => void;
  setDatesSelected: (dates: { startDate: string; endDate: string }) => void;
  setSearchHomes: (value: string) => void;
  setAccommodationType: (value: string[]) => void;
  getHotels: () => void;
  resetFilters: () => void;
  clearDatesError: () => void;
  clearLocationError: () => void;
  setSelectLocation: (location: ILocation) => void;
  setSelectLocationLabel: (label: string) => void;
  getHotelsRecentSearches: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {
  isVacationRentals: boolean;
}

class RecentSearchesWrapperComponent extends React.Component<IProps> {
  componentDidMount() {
    this.props.getHotelsRecentSearches();
  }

  onSearchHandler: HotelSearchArgs = (selectedLocation, startDate, endDate, rooms) => {
    const {
      setDatesSelected,
      setRooms,
      getHotels,
      resetFilters,
      clearDatesError,
      clearLocationError,
      setSelectLocation,
      setSelectLocationLabel,
      setSearchHomes,
      setAccommodationType,

      isVacationRentals,
      loginStore
    } = this.props;
    const { loading, account } = loginStore;

    if (!loading) {
      clearDatesError();
      clearLocationError();
      setSelectLocationLabel(selectedLocation.name);
      setSelectLocation(selectedLocation);
  
      resetFilters();
      
      UrlUtils.setUrl(FILTERS_LABEL, null);
  
      setDatesSelected({ startDate, endDate });
      
      setRooms(getNormalizedRoomsResponse(rooms));
  
      if (isVacationRentals) {
        setSearchHomes(HomesTypes.VacationRentalOnly);

        if (account?.restrictedVacationRentals) {
          const restrictedVacationRentalsList = account?.restrictedVacationRentalsList;
          const accomodationTypes = restrictedVacationRentalsList?.length ? restrictedVacationRentalsList : VACATIONS_FILTERED;
          setAccommodationType(accomodationTypes);
        }
      }

      getHotels();

      ReactGA.event({
        category: account?.name as string,
        action: `${H_CLICK_RECENT_SEARCH}_${account?.name.toUpperCase()}`,
        label: `User clicked recent search`,
        nonInteraction: false,
      });
    }
  };

  render(): React.ReactNode {
    const { loadingRecentSearches, recentSearches, isVacationRentals, loginStore } = this.props;
    const { loading, account } = loginStore;

    const recentSearchesFiltered = isVacationRentals ? recentSearches?.filter(s => s.type === HomesTypes.VacationRentalOnly) : [...recentSearches];

    return !loadingRecentSearches && recentSearchesFiltered.length ? (
      <div className="search-recents">
        <h2 className="search-recents__title secondary-font">
          <FormattedMessage id="recents.title" />
        </h2>
        <div className="search-recents__wrapper">
          {recentSearchesFiltered?.slice(0, 3).map((item, index) => (
            <RecentSearches
              data={item}
              account={account as IAccount}
              disabled={loading}
              key={`${item.location.code} ${index}`}
              onSearchHandler={this.onSearchHandler}
            />
          ))}
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    recentSearches: state.hotelsStore.recentSearches,
    loadingRecentSearches: state.hotelsStore.loadingRecentSearches,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  getHotelsRecentSearches: () => {
    dispatch(getHotelsRecentSearches());
  },
  getHotels: () => {
    dispatch(
      getHotels(),
    );
  },
  resetFilters: () => {
    dispatch(filtersActions.resetFilters());
  },
  setSelectLocation: (location: ILocation) => {
    dispatch(locationActions.setSelectLocation(location));
  },
  setSelectLocationLabel: (label: string) => {
    dispatch(locationActions.setSelectLocationLabel(label));
  },
  setDatesSelected: (dates: { startDate: string; endDate: string }) => {
    dispatch(datesActions.setDatesSelected(dates));
  },
  setRooms: (rooms: IRoom[]) => {
    dispatch(roomsActions.setRooms(rooms));
  },
  clearLocationError: () => {
    dispatch(locationActions.setError(''));
  },
  clearDatesError: () => {
    dispatch(datesActions.setError(''));
  },
  setSearchHomes: (value: string) => {
    dispatch(filtersActions.setSearchHomes(value as HomesTypes));
  },
  setAccommodationType: (value: string[]) => {
    dispatch(filtersActions.setAccommodationType(value));
  },
});

export const RecentSearchesWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecentSearchesWrapperComponent);
