import React from 'react';

type SizeProps = {
  size?: string;
}

export const BedSmallSvg: React.FunctionComponent<SizeProps> = ({ size }: SizeProps) => (
  <svg width={size || 16} height={size || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 4.66699V12.0003M2 9.33366H14M14 12.0003V6.66699C14 6.31337 13.8595 5.97423 13.6095 5.72418C13.3594 5.47413 13.0203 5.33366 12.6667 5.33366H7.33333V9.33366"
      stroke="#292929"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66667 7.33333C5.03486 7.33333 5.33333 7.03486 5.33333 6.66667C5.33333 6.29848 5.03486 6 4.66667 6C4.29848 6 4 6.29848 4 6.66667C4 7.03486 4.29848 7.33333 4.66667 7.33333Z"
      fill="#292929"
      stroke="#292929"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
