import React from 'react';
import { FormattedMessage } from 'react-intl';
import parse from 'html-react-parser';
import { IHotelDetails, IRoomsContent } from '@common-types';
import { EntireHome } from '../entire-home';
import { Properties } from '../properties';
import './style.scss';

interface IProps {
  hotel: IHotelDetails;
  rooms: IRoomsContent[];
  refAnchor: React.RefObject<HTMLDivElement>;
}

class VacationRentalInfoComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { hotel, rooms, refAnchor } = this.props;
    const show = rooms.length === 1;
    const room = rooms[0];

    return (show || hotel.facilities?.length || hotel.specialInstructions?.length || hotel.importantInformation?.length || hotel.other
      ?
        <>
          <div className="vacation-rental pb-0" ref={refAnchor}>
            <div className="rooms-search__list-text secondary-font vacation">
              <div className="rooms-search__header secondary-font" style={{ fontSize: '24px' }}><FormattedMessage id="tab.overview" /></div>
            </div>
          </div>

          {show ? <EntireHome hotel={hotel} room={room} /> : null}

          {hotel.facilities?.length
            ?
              <div className="vacation-rental">
                <h4 className="vacation-rental__title secondary-font"><FormattedMessage id="tab.vacation.facilities" /></h4>
                <p className="vacation-rental__description secondary-font">{hotel.facilities}</p>
              </div>
            : null
          }

          {hotel.specialInstructions?.length
            ?
              <div className="vacation-rental">
                <h4 className="vacation-rental__title secondary-font"><FormattedMessage id="tab.special.instructions" /></h4>
                <p className="vacation-rental__description secondary-font">{parse(hotel.specialInstructions)}</p>
              </div>
            : null
          }

          {hotel.importantInformation?.length
            ?
              <div className="vacation-rental">
                <h4 className="vacation-rental__title secondary-font"><FormattedMessage id="important.information" /></h4>
                <ul className="vacation-rental__description secondary-font">{hotel.importantInformation.map((info, i) => <li key={i}>{parse(info)}</li>)}</ul>
              </div>
            : null
	      }

          {hotel.other
            ?
              <div className="vacation-rental">
                <h4 className="vacation-rental__title secondary-font"><FormattedMessage id="tab.vacation.other" /></h4>
                <p className="vacation-rental__description secondary-font">{parse(hotel.other)}</p>
              </div>
            : null
          }

          {show ? <Properties room={room} /> : null}
        </>
      : null
    );
  }
}

export const VacationRentalInfo = VacationRentalInfoComponent;