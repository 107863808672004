import { isEmpty } from "lodash";
import { toast, ToastOptions } from "react-toastify";

const TOASTER_CONFIG: ToastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const TOASTER_CONFIG_ERROR: ToastOptions = {
  position: "top-right",
  autoClose: 20000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const TOASTER_INFO_CONFIG: ToastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

export class Toaster {
  static success = (message: string) => {
    toast.success(message, TOASTER_CONFIG);
  }
  
  static error = (message: string) => {
    toast.error(message, TOASTER_CONFIG_ERROR);
  }

  static info = (message: string, configExtra?: any) => {
    let config: any = TOASTER_INFO_CONFIG;
    if (configExtra) {
      config = {
        ...TOASTER_INFO_CONFIG,
        ...configExtra
      };
    }

    toast.info(message, config);
  }

  static warning = (message: string, configExtra?: any) => {
    let config: any = TOASTER_INFO_CONFIG;
    if (configExtra) {
      config = {
        ...TOASTER_INFO_CONFIG,
        ...configExtra
      };
    }

    toast.warning(message, config);
  }
}