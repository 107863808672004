
import { RefObject } from 'react';
import { connect } from 'react-redux';

import { FormattedMessage } from 'react-intl';

import './style.scss';

interface IProps {
  display: boolean;
  classNameContainer: string;

  insuranceContainerRef: RefObject<HTMLDivElement>;
}

function InsuranceRequiredMessageComponent(props: IProps) {

  const { display, classNameContainer, insuranceContainerRef } = props;
  
  if (!display) {
    return null;
  }
  
  return (
    <div className={classNameContainer} style={{ paddingTop: '6px' }}>
      <label className="insurance-required-container"><FormattedMessage id="please.select.insurance" values={{
        anchor: (msg: string) => (
          <div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
            insuranceContainerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }}>
            {msg}
          </div>
        ),
      }} /></label>
    </div>
  );
}

export const InsuranceRequiredMessage = connect()(InsuranceRequiredMessageComponent);
