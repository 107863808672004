
import { useEffect, useMemo } from 'react';

import ReactGA from 'react-ga4';
import Cookies from 'js-cookie';

import { IntlProvider } from 'react-intl';
import { get, isEmpty, toNumber } from 'lodash';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { ToastContainer } from 'react-toastify';
import { CookiesProvider, withCookies, ReactCookieProps } from 'react-cookie';

import { Locales, getAntLocale, getLocale, getMessages } from '@i18n';
import { ExpiredSessionModal, ApplicationHeader, ApplicationFooter, ApplicationHelmet, ApplicationHeaderMessage } from '@components';
import { Loading, LoadingSise } from '@share/components';
import { DecodeTokenData, getCancelIdCancellationFromPath, getNormalizedRooms, getPreferenceFromAccount, getTempKeyCancellationFromPath, isAnyReservationCancelFromPath, UrlUtils, isCondoWorldFromPath, isThreeDSFromPath, isCondoEngineFromPath, isCondoWeeksFromPath, isReservationCancellationFromPath, LoginType } from '@share/utils';
import { ACCESS_TOKEN_LABEL, ADULTS_LABEL, CHECKIN_LABEL, CHECKOUT_LABEL, CHILDS_LABEL, COOKIE1_TOKEN_NAME, COOKIE_TOKEN_NAME, DATES_LABEL, LATITUDE_LABEL, LOCATION_LABEL, LOCATION_OBJECT_LABEL, LONGITUDE_LABEL, REFERENCE_NUMBER_LABEL, ROOMS_LABEL, Routes, SESSION_KEY_LABEL, USER_BASE_URL, AGENCY_NUMBER_LABEL, FILTERS_LABEL, CONDO_FILTERS_LABEL, CLIENT_CASH_LABEL, CLIENT_CASH_CONDO_LABEL, LIFE_STYLE_NAME_PARAM, CLIENT_CASH_CARS_LABEL, CARS_PICKUP_LOCATION_OBJECT_LABEL, CARS_PICKUP_LOCATION_LABEL, CARS_DROPOFF_LOCATION_OBJECT_LABEL, CARS_DROPOFF_LOCATION_LABEL, CARS_SESSION_KEY_LABEL, CARS_DATES_LABEL, CARS_DRIVER_LABEL, CARS_FILTERS_QUICK_LABEL, CLIENT_CASH_WEEKS_LABEL, WEEKS_FILTERS_LABEL, WEEKS_DATES_LABEL, WEEKS_LOCATION_LABEL, WEEKS_LOCATION_OBJECT_LABEL } from '@share/constants';
import { getAccountUsernameFromPath, isErrorFromPath, isSignInFromPath, isSignOutFromPath, isWidgetFromPath, RootState } from '@share/utils';
import { ILoginState, logout, getLoginUserAccount, setLoginRedirect, locationActions, datesActions, roomsActions, hotelsActions, loginActions, IMenuState, IFiltersState, ICondoFiltersState, filtersActions, condoFiltersActions, condosActions, carsActions, carsLocationsActions, carsDatesActions, carsDriverActions, carsFiltersActions, IWeeksFiltersState, weeksFiltersActions, weeksDatesActions, weeksLocationsActions, weeksActions } from '@share/store/slices';
import { getAllCondoDestinations, getCountries } from '@share/store/slices';
import { IClientCash, ICondoLocation, IGuest, ILocation, IRoom, ISessionKey, IUrlRoom, LOGOUT_FIELD_KEY } from '@share/common-types';
import { getLocationByGeo } from '@share/services';
import { setSelectedCarsReviewClientCashStr, setSelectedCondoReviewClientCash, setSelectedCondoReviewClientCashStr, setSelectedHotelReviewClientCash, setSelectedHotelReviewClientCashStr, weeksReviewBookAction } from '@store/slices';
import { NULL_VALUE } from '@constants';

import { ApplicationRoutes } from '../app-routes';

import './style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
}

interface IMapDispatchToProps {
  getLoginUserAccount: (accountName: string, isWidget: boolean, isCondo: boolean, isCancelRoute: boolean, cancelId?: string, tempKey?: string) => void;
  setLoginRedirect: (redirect: string) => void;
  logout: () => void;

  setDatesSelected: (dates: { startDate: string; endDate: string }) => void;
  applyDates: () => void;

  setRooms: (rooms: IRoom[]) => void;
  applyRooms: () => void;

  setSelectLocation: (location: ILocation) => void;
  setSelectLocationLabel: (locationLabel: string) => void;
  applyLocation: () => void;

  setSessionKey: (session: ISessionKey) => void;
  setReferenceNumber: (referenceNumber: string) => void;
  setAccessToken: (accessToken: string) => void;
  setAgencyNumber: (agencyNumber: string) => void;

  setSelectPickUpLocation: (location: ILocation) => void;
  setSelectPickUpLocationLabel: (locationLabel: string) => void;
  setSelectDropOffLocation: (location: ILocation) => void;
  setSelectDropOffLocationLabel: (locationLabel: string) => void;
  applyCarsLocation: () => void;

  setCarsDatesSelected: (dateSelected: { startDate: string; endDate: string; }) => void;
  setCarsTimesSelected: (timeSelected: { startDateTime: string; endDateTime: string; }) => void;
  applyCarsDates: () => void;

  setCarsSessionKey: (session: ISessionKey) => void;

  setDriverAgeSelected: (age: string) => void;
  setDriverCountrySelected: (country: string) => void;
  applyCarsDriver: () => void;

  setSimpleTypeQuick: (values: string[]) => void;
  setRateDistanceQuick: (values: string[]) => void;
  setRentalCompanyQuick: (values: string[]) => void;
  setFuelQuick: (values: string[]) => void;
  applyCarsFiltersQuicks: () => void;

  setLifeStyle: (lifeStyle: string) => void;

  setWeeksSelectLocation: (location: ICondoLocation) => void;
  setWeeksSelectLocationLabel: (locationLabel: string) => void;
  applyWeeksLocation: () => void;

  setDateSelected: (date: string) => void;
  setPeriodSelected: (period: string) => void;
  applyWeeksDates: () => void;

  setFilters: (filters: IFiltersState) => void;
  setCondoFilters: (filters: ICondoFiltersState) => void;
  setWeeksFilters: (filters: IWeeksFiltersState) => void;

  setSelectedHotelSearchClientCash: (selectedClientCahs: IClientCash) => void;
  setSelectedHotelReviewClientCash: (selectedClientCash: number) => void;
  setSelectedHotelReviewClientCashStr: (selectedClientCash: string) => void;

  setSelectedCondoSearchClientCash: (selectedClientCahs: IClientCash) => void;
  setSelectedCondoReviewClientCash: (selectedClientCash: number) => void;
  setSelectedCondoReviewClientCashStr: (selectedClientCash: string) => void;

  setSelectedCarsSearchClientCash: (selectedClientCahs: IClientCash) => void;
  setSelectedCarsReviewClientCash: (selectedClientCash: number) => void;
  setSelectedCarsReviewClientCashStr: (selectedClientCash: string) => void;

  setSelectedWeeksSearchClientCash: (selectedClientCahs: IClientCash) => void;
  setSelectedWeeksReviewClientCash: (selectedClientCash: number) => void;
  setSelectedWeeksReviewClientCashStr: (selectedClientCash: string) => void;

  getAllCondoDestinations: () => void;
  getCountries: () => void;
}

export interface IAccountParams {
  accountUsername: string;
  id: string;
}

interface IProps extends ReactCookieProps, IMapStateToProps, IMapDispatchToProps, RouteComponentProps<IAccountParams> {
  isWidget?: boolean;
}

function ApplicationComponent(props: IProps) {

  const { history, loginStore, menuStore } = props;
  const { account, userLanguage, isTokenExpired } = loginStore;
  const { items } = menuStore;

  const isPublicAccount = account?.isPublicAccountType;
  const isPrivateWithLoginAccount = account?.isPrivateLoginAccountType;
  const isPrivateWithTokenAccount = account?.isPrivateTokenAccountType;
  const fontFamily = account?.fontFamily;
  const secondaryFontFamily = account?.secondaryFontFamily;
  const generalBorderRadius = account?.generalBorderRadius;
  
  const pagesToExcludeAccountCheck = useMemo(() => [
    isWidgetFromPath(history),
    isCondoWeeksFromPath(history),
    isCondoWorldFromPath(history),
    isErrorFromPath(history),
    isThreeDSFromPath(history),
  ], [history]);
  
  const isWidgetPage = useMemo(() => isWidgetFromPath(history), [history]);
  const isReservationCancellation = useMemo(() => isReservationCancellationFromPath(history), [history]);
  const isCondo = useMemo(() => isCondoEngineFromPath(history), [history]);
  const isSignIn = useMemo(() => isSignInFromPath(history), [history]);
  
  const locale = isCondo ? Locales.English : getLocale(userLanguage);

  useEffect(() => {
    const processData = async () => {
      const { history } = props;

      const values = UrlUtils.getValues();

      if (values[DATES_LABEL]) {
        props.setDatesSelected(values[DATES_LABEL] as { startDate: string; endDate: string });
        props.applyDates();
      } else if (!isEmpty(values[CHECKIN_LABEL]) || !isEmpty(values[CHECKOUT_LABEL])) {
        props.setDatesSelected({
          startDate: !isEmpty(values[CHECKIN_LABEL]) ? values[CHECKIN_LABEL] as string : NULL_VALUE,
          endDate: !isEmpty(values[CHECKOUT_LABEL]) ? values[CHECKOUT_LABEL] as string : NULL_VALUE
        });

        if (!isEmpty(values[CHECKIN_LABEL]) && !isEmpty(values[CHECKOUT_LABEL])) {
          props.applyDates();
        }

        UrlUtils.removeFromUrl(CHECKIN_LABEL);
        UrlUtils.removeFromUrl(CHECKOUT_LABEL);
      }

      const hasLatitudeLongitude = !isEmpty(values[LATITUDE_LABEL]) && !isEmpty(values[LONGITUDE_LABEL]);

      if (!hasLatitudeLongitude && values[LOCATION_LABEL]) {
        props.setSelectLocationLabel(values[LOCATION_LABEL] as string);
        props.applyLocation();
      }

      if (values[REFERENCE_NUMBER_LABEL]) {
        props.setReferenceNumber(values[REFERENCE_NUMBER_LABEL] as string);
      }

      if (values[CLIENT_CASH_LABEL]) {
        const clientCash = values[CLIENT_CASH_LABEL] as IClientCash;
        await props.setSelectedHotelSearchClientCash(clientCash);
        await props.setSelectedHotelReviewClientCash(clientCash?.selectedPropertyReviewClientCash as number);
        await props.setSelectedHotelReviewClientCashStr(clientCash?.selectedPropertyReviewClientCash ? clientCash?.selectedPropertyReviewClientCash.toString() : '');
      }

      if (values[CLIENT_CASH_CONDO_LABEL]) {
        const clientCash = values[CLIENT_CASH_CONDO_LABEL] as IClientCash;
        await props.setSelectedCondoSearchClientCash(clientCash);
        await props.setSelectedCondoReviewClientCash(clientCash?.selectedPropertyReviewClientCash as number);
        await props.setSelectedCondoReviewClientCashStr(clientCash?.selectedPropertyReviewClientCash ? clientCash?.selectedPropertyReviewClientCash.toString() : '');
      }
      
      if (values[CLIENT_CASH_CARS_LABEL]) {
        const clientCash = values[CLIENT_CASH_CARS_LABEL] as IClientCash;
        await props.setSelectedCarsSearchClientCash(clientCash);
        await props.setSelectedCarsReviewClientCash(clientCash?.selectedPropertyReviewClientCash as number);
        await props.setSelectedCarsReviewClientCashStr(clientCash?.selectedPropertyReviewClientCash?.toString() as string);
      }

      if (values[CLIENT_CASH_WEEKS_LABEL]) {
        const clientCash = values[CLIENT_CASH_WEEKS_LABEL] as IClientCash;
        await props.setSelectedWeeksSearchClientCash(clientCash);
        await props.setSelectedWeeksReviewClientCash(clientCash?.selectedPropertyReviewClientCash as number);
        await props.setSelectedWeeksReviewClientCashStr(clientCash?.selectedPropertyReviewClientCash?.toString() as string);
      }

      if (values[AGENCY_NUMBER_LABEL]) {
        props.setAgencyNumber(values[AGENCY_NUMBER_LABEL] as string);
      }

      if (values[LIFE_STYLE_NAME_PARAM]) {
        await props.setLifeStyle(values[LIFE_STYLE_NAME_PARAM] as string);
      }

      if (values[ACCESS_TOKEN_LABEL]) {
        props.setAccessToken(values[ACCESS_TOKEN_LABEL] as string);
        localStorage.setItem(ACCESS_TOKEN_LABEL, values[ACCESS_TOKEN_LABEL] as string);
        UrlUtils.removeFromUrl(ACCESS_TOKEN_LABEL);
      } else {
        const accessTokenStorage = localStorage.getItem(ACCESS_TOKEN_LABEL);
        if (!isEmpty(accessTokenStorage)) {
          props.setAccessToken(accessTokenStorage as string);
        }
      }

      if (values[LOCATION_OBJECT_LABEL]) {
        props.setSelectLocation(values[LOCATION_OBJECT_LABEL] as ILocation);
        props.applyLocation();
      } else if (hasLatitudeLongitude) {
        const latitude = toNumber(values[LATITUDE_LABEL] as string);
        const longitude = toNumber(values[LONGITUDE_LABEL] as string);
        try {
          const location = await getLocationByGeo(latitude, longitude, true);

          props.setSelectLocationLabel(location?.data?.name);
          props.setSelectLocation(location?.data);
          props.applyLocation();
    
          UrlUtils.removeFromUrl(LATITUDE_LABEL);
          UrlUtils.removeFromUrl(LONGITUDE_LABEL);
        } catch(e) {
          console.error(e);
        }
      }

      if (values[ROOMS_LABEL]) {
        props.setRooms(getNormalizedRooms(values[ROOMS_LABEL] as Array<IUrlRoom>));
        props.applyRooms();
      } else if (!isEmpty(values[ADULTS_LABEL]) || !isEmpty(values[CHILDS_LABEL])) {
        props.setRooms([{
          adultsCount: values[ADULTS_LABEL] ? toNumber(values[ADULTS_LABEL] as string) : 2,
          kids: Array(values[CHILDS_LABEL] ? toNumber(values[CHILDS_LABEL] as string) : 0).fill(0) as IGuest[]
        }]);
        props.applyRooms();

        UrlUtils.removeFromUrl(ADULTS_LABEL);
        UrlUtils.removeFromUrl(CHILDS_LABEL);
      }

      const pickUpValue = values[CARS_PICKUP_LOCATION_OBJECT_LABEL];
      const isPickUp = pickUpValue && Object.keys(pickUpValue).length > 0;
      if (isPickUp) {
        props.setSelectPickUpLocation(values[CARS_PICKUP_LOCATION_OBJECT_LABEL] as ILocation);
        props.applyCarsLocation();
      }

      if (values[CARS_PICKUP_LOCATION_LABEL] && isPickUp) {
        props.setSelectPickUpLocationLabel(values[CARS_PICKUP_LOCATION_LABEL] as string);
        props.applyCarsLocation();
      }

      const dropOffValue = values[CARS_DROPOFF_LOCATION_OBJECT_LABEL];
      const isDropOff = dropOffValue && Object.keys(dropOffValue).length > 0;
      if (isDropOff) {
        props.setSelectDropOffLocation(values[CARS_DROPOFF_LOCATION_OBJECT_LABEL] as ILocation);
        props.applyCarsLocation();
      }

      if (values[CARS_DROPOFF_LOCATION_LABEL] && isDropOff) {
        props.setSelectDropOffLocationLabel(values[CARS_DROPOFF_LOCATION_LABEL] as string);
        props.applyCarsLocation();
      }

      if (values[SESSION_KEY_LABEL]) {
        props.setSessionKey(values[SESSION_KEY_LABEL] as ISessionKey);
      }

      if (values[CARS_SESSION_KEY_LABEL]) {
        props.setCarsSessionKey(values[CARS_SESSION_KEY_LABEL] as ISessionKey);
      }

      if (values[FILTERS_LABEL]) {
        props.setFilters(values[FILTERS_LABEL] as IFiltersState);
      }

      if (values[CONDO_FILTERS_LABEL]) {
        props.setCondoFilters(values[CONDO_FILTERS_LABEL] as ICondoFiltersState);
      }

      if (values[WEEKS_FILTERS_LABEL]) {
        props.setWeeksFilters(values[WEEKS_FILTERS_LABEL] as IWeeksFiltersState);
      }

      
      if (values[CARS_DATES_LABEL]) {
        const carsDates = values[CARS_DATES_LABEL] as Record<string, string>;
        props.setCarsDatesSelected({ startDate: carsDates.startDate, endDate: carsDates.endDate });
        props.setCarsTimesSelected({ startDateTime: carsDates.startDateTime, endDateTime: carsDates.endDateTime });
        props.applyCarsDates();
      }
    
      if (values[CARS_DRIVER_LABEL]) {
        const carsDriver = values[CARS_DRIVER_LABEL] as Record<string, string>;
        props.setDriverAgeSelected(carsDriver?.driverAge);
        props.setDriverCountrySelected(carsDriver?.driverCountry);
        props.applyCarsDriver();
      }

      if (values[WEEKS_DATES_LABEL]) {
        const dates = values[WEEKS_DATES_LABEL] as Record<string, string>;

        props.setDateSelected(dates?.date);
        props.setPeriodSelected(dates?.period);
        props.applyWeeksDates();
      }
    
      if (!hasLatitudeLongitude && values[WEEKS_LOCATION_LABEL]) {
        props.setWeeksSelectLocationLabel(values[WEEKS_LOCATION_LABEL] as string);
        props.applyWeeksLocation();
      }

      if (values[WEEKS_LOCATION_OBJECT_LABEL]) {
        props.setWeeksSelectLocation(values[WEEKS_LOCATION_OBJECT_LABEL] as ICondoLocation);
        props.applyLocation();
      } else if (hasLatitudeLongitude) {
        const latitude = toNumber(values[LATITUDE_LABEL] as string);
        const longitude = toNumber(values[LONGITUDE_LABEL] as string);
        try {
          const location = await getLocationByGeo(latitude, longitude, true);

          props.setWeeksSelectLocationLabel(location?.data?.name);
          props.setWeeksSelectLocation(location?.data);
          props.applyWeeksLocation();
    
          UrlUtils.removeFromUrl(LATITUDE_LABEL);
          UrlUtils.removeFromUrl(LONGITUDE_LABEL);
        } catch(e) {
          console.error(e);
        }
      }
      
      if (values[CARS_FILTERS_QUICK_LABEL]) {
        const carsFiltersQuick = values[CARS_FILTERS_QUICK_LABEL] as Record<string, string[]>;
        props.setSimpleTypeQuick(carsFiltersQuick?.simpleTypeQuick);
        props.setRateDistanceQuick(carsFiltersQuick?.rateDistanceQuick);
        props.setRentalCompanyQuick(carsFiltersQuick?.rentalCompanyQuick);
        props.setFuelQuick(carsFiltersQuick?.fuelQuick);
        props.applyCarsFiltersQuicks();
      }

      if (!pagesToExcludeAccountCheck.includes(true)) {
        const pathAccountName = getAccountUsernameFromPath(history);
        if (!isSignInFromPath(history) && !isSignOutFromPath(history)) {
          const { pathname, search } = history.location;
          props.setLoginRedirect(`${pathname}${isEmpty(search) ? '' : search}`);
        }
        
        props.getLoginUserAccount(
          pathAccountName,
          isWidgetPage,
          isCondoEngineFromPath(props.history),
          isAnyReservationCancelFromPath(history),
          getCancelIdCancellationFromPath(history),
          getTempKeyCancellationFromPath(history));
      }
    }
    
    processData();
  }, []);

  const handleExpireRedirect = () => {
    if (isPublicAccount) {
      window.location.reload();
    } else if (isPrivateWithLoginAccount) {
      props.logout();
      props.history.push(`/${account.name}${Routes.Login}`);
    } else if (isPrivateWithTokenAccount) {
      const logoutUrlMenu = menuStore?.items?.logoutUrl;
      const logoutUrlStorage = localStorage.getItem(USER_BASE_URL);
      const logoutUrlToken = get(DecodeTokenData(), 'logoutUrl', null);
      const logoutPref = getPreferenceFromAccount(account, LOGOUT_FIELD_KEY);
      const logoutUrl = !isEmpty(logoutUrlStorage) ? logoutUrlStorage : !isEmpty(logoutUrlMenu) ? logoutUrlMenu : !isEmpty(logoutUrlToken) ? logoutUrlToken : logoutPref;

      Cookies.remove(COOKIE_TOKEN_NAME);
      Cookies.remove(COOKIE1_TOKEN_NAME);

      if (!isEmpty(logoutUrl)) {
        window.location.replace(logoutUrl);
      }
    }
  }

  const renderContent = () => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
    
    return (
      <CookiesProvider>
        <ConfigProvider locale={getAntLocale(locale)}>
          <IntlProvider
            messages={getMessages(locale)}
            locale={locale}
            defaultLocale={locale}
          >

            <div className={`app ${generalBorderRadius ? `border-radius-${generalBorderRadius}` : ''} ${isReservationCancellation ? 'cancellation' : ''}${fontFamily ? `${fontFamily}-font`: ''} ${secondaryFontFamily ? `secondary-${secondaryFontFamily}-font`: ''}`}>
              <ApplicationHelmet />

              <ApplicationHeader />

              <ApplicationHeaderMessage />
              
              <ApplicationRoutes />

              <ApplicationFooter />

              {!isReservationCancellation ? (
                <ToastContainer
                  position="top-right"
                  autoClose={6000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                ></ToastContainer>) : null}

              {(!isSignIn && isTokenExpired) ? (
                <ExpiredSessionModal visible={true} hasRedirect={!isPublicAccount} onHandleRedirect={handleExpireRedirect} />) : null}

            </div>
          </IntlProvider>
        </ConfigProvider>
      </CookiesProvider>
    );
  }

  if ((account?.type === LoginType.PrivateWithLogin && loginStore?.loading) || 
      (account?.type !== LoginType.PrivateWithLogin && !items && !pagesToExcludeAccountCheck.includes(true))) {
    return (
      <div className="loading-container">
        <Loading size={LoadingSise.Medium} />
      </div>);
  } else {
    return renderContent();
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    menuStore: state.navigationMenuStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setSelectLocation: locationActions.setSelectLocation,
  setSelectLocationLabel: locationActions.setSelectLocationLabel,
  setDatesSelected: datesActions.setDatesSelected,
  applyDates: datesActions.applyDates,
  applyRooms: roomsActions.applyRooms,
  applyLocation: locationActions.applyLocation,
  setRooms: roomsActions.setRooms,
  setSessionKey: hotelsActions.setSessionKey,
  setReferenceNumber: hotelsActions.setReferenceNumber,
  setAgencyNumber: hotelsActions.setAgencyNumber,

  setCondoFilters: condoFiltersActions.setFilters,
  setFilters: filtersActions.setFilters,
  setWeeksFilters: weeksFiltersActions.setFilters,

  setCarsSessionKey: carsActions.setCarsSessionKey,
  setSelectedCarsSearchClientCash: carsActions.setSelectedCarsSearchClientCash,
  setSelectedCarsReviewClientCash: setSelectedHotelReviewClientCash,
  setSelectedCarsReviewClientCashStr: setSelectedCarsReviewClientCashStr,
  setSelectPickUpLocation: carsLocationsActions.setSelectPickUpLocation,
  setSelectPickUpLocationLabel: carsLocationsActions.setSelectPickUpLocationLabel,
  setSelectDropOffLocation: carsLocationsActions.setSelectDropOffLocation,
  setSelectDropOffLocationLabel: carsLocationsActions.setSelectDropOffLocationLabel,
  applyCarsLocation: carsLocationsActions.applyLocation,

  setCarsDatesSelected: carsDatesActions.setDatesSelected,
  setCarsTimesSelected: carsDatesActions.setTimesSelected,
  applyCarsDates: carsDatesActions.applyCarsDates,

  setDriverAgeSelected: carsDriverActions.setDriverAgeSelected,
  setDriverCountrySelected: carsDriverActions.setDriverCountrySelected,
  applyCarsDriver: carsDriverActions.applyCarsDriver,

  setDateSelected: weeksDatesActions.setDateSelected,
  setPeriodSelected: weeksDatesActions.setPeriodSelected,
  applyWeeksDates: weeksDatesActions.applyWeeksDates,

  setWeeksSelectLocation: weeksLocationsActions.setSelectLocation,
  setWeeksSelectLocationLabel: weeksLocationsActions.setSelectLocationLabel,
  applyWeeksLocation: weeksLocationsActions.applyLocation,

  setSimpleTypeQuick: carsFiltersActions.setSimpleTypeQuick,
  setRateDistanceQuick: carsFiltersActions.setRateDistanceQuick,
  setRentalCompanyQuick: carsFiltersActions.setRentalCompanyQuick,
  setFuelQuick: carsFiltersActions.setFuelQuick,
  applyCarsFiltersQuicks: carsFiltersActions.applyCarsFiltersQuicks,

  setAccessToken: loginActions.setAccessToken,
  setLifeStyle: loginActions.setLifeStyle,

  setSelectedHotelSearchClientCash: hotelsActions.setSelectedHotelSearchClientCash,
  setSelectedHotelReviewClientCash,
  setSelectedHotelReviewClientCashStr,

  setSelectedCondoSearchClientCash: condosActions.setSelectedCondoSearchClientCash,
  setSelectedCondoReviewClientCash,
  setSelectedCondoReviewClientCashStr,
  
  setSelectedWeeksSearchClientCash: weeksActions.setSelectedWeeksSearchClientCash,
  setSelectedWeeksReviewClientCash: weeksReviewBookAction.setSelectedWeeksReviewClientCash,
  setSelectedWeeksReviewClientCashStr: weeksReviewBookAction.setSelectedWeeksReviewClientCashStr,

  logout,
  getLoginUserAccount,
  setLoginRedirect,
  getCountries,
  getAllCondoDestinations
};

const ApplicationComponentWithRouter = withRouter(ApplicationComponent);
const ApplicationComponentCookies = withCookies(ApplicationComponentWithRouter);

export const App = connect(mapStateToProps, mapDispatchToProps)(ApplicationComponentCookies);
