import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RootState } from '@share/utils';
import { IHotelDetailsState, IReviewBookState } from '@store/slices';
import { ILoginState, IMarginatorState } from '@share/store/slices';
import { getPriceDiffDetails } from '@utils';
import React from 'react';
import { connect } from 'react-redux';
import { Currency } from '@components';

import './style.scss';
import { FormattedMessage } from 'react-intl';

interface IMapStateToProps {
  reviewBookStore: IReviewBookState;
  hotelDetailsStore: IHotelDetailsState;
  marginatorStore: IMarginatorState;
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {}

interface IState {
  display: boolean;
}

class PriceChangeMessageComponent extends React.Component<IProps, IState> {

  state: IState = { display: true };

  handleClose = () => {
    this.setState({ display: false });
  }

  render(): React.ReactNode {
    const { display } = this.state;

    if (!display) {
      return null;
    }

    const { reviewBookStore, hotelDetailsStore, marginatorStore, loginStore } = this.props;
    const { bookingSummary, bookingComplete } = reviewBookStore;
    const { hotel } = hotelDetailsStore;
    const { account } = loginStore;

    const { isIncrease, diff } = getPriceDiffDetails(bookingSummary, hotel, marginatorStore.percentage, account?.breakdownTaxes);
    const currency = bookingSummary?.bookingCard?.package?.currency;

    if (bookingComplete || diff <= 0.1) {
      return null;
    }


    const className = `price-change-message-container ${isIncrease ? 'increase' : 'decrease'}`
    return (
      <div className={className}>
        <FontAwesomeIcon icon={faInfoCircle} />
        <FormattedMessage id={'price.changed.message.1'} /> {isIncrease ? <FormattedMessage id={'price.changed.increased'} /> : <FormattedMessage id={'price.changed.decreased'} />} <FormattedMessage id={'price.changed.by'} /> <span><Currency currency={currency} /> {diff.toFixed(2)}</span>
        <label className="close-button" onClick={this.handleClose}>X</label>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    reviewBookStore: state.reviewBookStore,
    hotelDetailsStore: state.hotelDetailsStore,
    marginatorStore: state.marginatorStore,
    loginStore: state.loginStore,
  };
};

export const PriceChangeMessage = connect(mapStateToProps)(PriceChangeMessageComponent);
