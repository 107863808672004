import React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import NoCondoResutls from '@assets/images/no_condos_results.png';

import './style.scss';

interface IProps extends RouteComponentProps {
  filtersApplied?: boolean;
}

function CondoWeeksEmptyComponent(props: IProps) {
  const { filtersApplied } = props;

  return (
    <div className="condo-results-empty">
      <img className="no-result weeks" src={NoCondoResutls} alt="" />

      <h3><FormattedMessage id={filtersApplied ? 'no.properties' : 'no.result.oops'} /></h3>

      {!filtersApplied && <h4><FormattedMessage id="no.available.unit" /></h4>}
    </div>);
}

const ApplicationComponentWithRouter = withRouter(CondoWeeksEmptyComponent);

export const CondoWeeksEmpty = connect()(ApplicationComponentWithRouter);
