import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ICondoGuestsState {
  adultsCount: number;
  kidsCount: number;
  bedroomsCount: number;
  includeStudio: boolean;
  error?: string;
}

export const CondoGuestInitialState: ICondoGuestsState = {
  adultsCount: 2,
  kidsCount: 0,
  bedroomsCount: 1,
  includeStudio: false,
  error: ''
};

const condoGuestsSlice = createSlice({
  name: 'condo-guests',
  initialState: CondoGuestInitialState,
  reducers: {
    updateAdultsCount: (state: ICondoGuestsState, { payload }: PayloadAction<number>) => {
      state.adultsCount = payload;
    },
    updateKidsCount: (state: ICondoGuestsState, { payload }: PayloadAction<number>) => {
      state.kidsCount = payload;
    },
    updateBedroomCount: (state: ICondoGuestsState, { payload }: PayloadAction<number>) => {
      state.bedroomsCount = payload;
    },
    updateIncludeStudio: (state: ICondoGuestsState, { payload }: PayloadAction<boolean>) => {
      state.includeStudio = payload;
    },
    updateAdultsCountExperiences: (state: ICondoGuestsState, { payload }: PayloadAction<number>) => {
      state.adultsCount = payload;
    },
    updateKidsCountExperiences: (state: ICondoGuestsState, { payload }: PayloadAction<number>) => {
      state.kidsCount = payload;
    },
    updateBedroomCountExperiences: (state: ICondoGuestsState, { payload }: PayloadAction<number>) => {
      state.bedroomsCount = payload;
    },
    updateIncludeStudioExperiences: (state: ICondoGuestsState, { payload }: PayloadAction<boolean>) => {
      state.includeStudio = payload;
    },
    setState: (state: ICondoGuestsState, { payload }: PayloadAction<ICondoGuestsState>) => {
      return payload;
    },
    resetGuest: () => {
      return CondoGuestInitialState;
    },
  },
});

export const { updateAdultsCount, updateKidsCount, updateBedroomCount, updateIncludeStudio, updateAdultsCountExperiences, updateKidsCountExperiences, updateBedroomCountExperiences, updateIncludeStudioExperiences } =
  condoGuestsSlice.actions;

export const condoGuestsActions = condoGuestsSlice.actions;

export const condoGuestsReducer = condoGuestsSlice.reducer;
