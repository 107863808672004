import { ContactUsWrapper } from '@components';
import React, { Component } from 'react';
import './style.scss';

export class ContactUsPage extends Component {
  render(): React.ReactNode {
    return (
      <div className="contact-us-page">
        <ContactUsWrapper />
      </div>
    );
  }
}
