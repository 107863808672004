import React, { useState } from 'react';
import moment from 'moment';

import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { ICondoUnitWeek } from '@common-types';

import { CondoWeeksDatesModal } from '../condo-week-dates-modal';

import { Tooltip } from 'antd';
import { USA, VICINITY } from '@share/constants';

import './style.scss';

interface IProps extends RouteComponentProps {
  condo: ICondoUnitWeek;

  displayReduced?: boolean;
}

function CondoWeeksDatesTableComponent(props: IProps) {

  const [displayModal, setDisplayModal] = useState(false);

  const { condo, displayReduced } = props;
  const { propertyName, dates, locationName, roomDescription, bedrooms, bathrooms, maxOccupancy } = condo;

  const dateReduced = displayReduced ? dates?.slice(0, 1) : dates;
  const datesAvailables = dates?.length;

  const locationNameDesc = locationName.replaceAll(VICINITY, '').replaceAll(`, ${USA}`, '').trim();
  
  return (
    <>
      <h6 className="card-title mb-1" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '100%', textAlign: 'center' }}><Tooltip title={propertyName} placement="top">{propertyName}</Tooltip></h6>

      <small style={{ marginBottom: '6px' }}>{roomDescription}</small>

      <small style={{ marginBottom: '6px' }}>{locationNameDesc}</small>

      <small style={{ marginBottom: '6px' }}>
        {bedrooms ? <label><FormattedMessage id="bedrooms.count" values={{ count: bedrooms }} /></label> : null}
        {bathrooms ? <label>{bedrooms ? ' | ' : null}<FormattedMessage id="bathrooms.count" values={{ count: bathrooms }} /></label> : null}
        {maxOccupancy ? <label>{(bedrooms || bathrooms) ? ' | ' : null}<FormattedMessage id="max.occupancy" /> {maxOccupancy}</label> : null}
      </small>

      <div style={{ marginTop: '8px' }}>
        <table className="table table-sm table-bordered align-start p-0 m-0" style={{ fontSize: '0.8em' }}>
          <thead>
              <tr>
                  <td className="fw-bold" style={{ width: '105px' }}>Check-In</td>
                  <td className="fw-bold" style={{ width: '105px' }}>Check-Out</td>
                  <td className="fw-bold">Length</td>
                  <td className="fw-bold">Member Price</td>
              </tr>
          </thead>
          <tbody>
              {dateReduced?.map((d, i) => (
                <tr key={`index_dates_${i}`}>
                  <td>{moment(d.checkIn, 'yyyy-MM-DD').format('MM-DD-yyyy')}</td>
                  <td>{moment(d.checkOut, 'yyyy-MM-DD').format('MM-DD-yyyy')}</td>
                  <td>{d.daysOfStay} days</td>
                  <td>USD {d.price}</td>
                </tr>))}
          </tbody>
        </table>
      </div>


      {(displayReduced && dateReduced?.length < dates?.length) ? (
        <>
          <div style={{ display: 'flex', marginTop: '10px', fontSize: '12px', justifyContent: 'center' }}>
            {datesAvailables} dates available{datesAvailables > 1 ? 's' : ''} <label style={{ color: '#40a9ff', cursor: 'pointer', textDecoration: 'underline', marginLeft: '5px' }} onClick={() => setDisplayModal(true)}>See more</label>
          </div>

          <CondoWeeksDatesModal condo={condo} visible={displayModal} onCancel={() => setDisplayModal(false)} />
        </>) : (
        <div style={{ height: '22px' }}>&nbsp;</div>)}
    </>
  );
}

const CondoWeeksDatesTableRouter = withRouter(CondoWeeksDatesTableComponent);

export const CondoWeeksDatesTable = connect()(CondoWeeksDatesTableRouter);
