import React from 'react';
import { Carousel, Spin } from 'antd';
import { ImageLoadingObject } from '@common-types';
import { IImage } from '@share/common-types';

import './style.scss';

interface IProps {
  images: IImage[];

  keyValue?: string;

  isNoCoverFit?: boolean;
}

interface IState {
  imagesMap: ImageLoadingObject;
}

const speed = 500;
const slidesToShow = 1;
const slidesToScroll = 1;

export class CarouselImages extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      imagesMap: this.generateImagesMap(),
    };
  }

  generateImagesMap = (): ImageLoadingObject => {
    return this.props.images.reduce((res: ImageLoadingObject, img: IImage, index: number) => {
      res[index] = {
        img,
        loading: true,
      };

      return res;
    }, {} as ImageLoadingObject);
  };

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (prevProps.images !== this.props.images) {
      this.setState({ imagesMap: this.generateImagesMap() });
    }
  }

  onImageLoad = (index: number): void => {
    this.setState((prevState: IState) => {
      return {
        ...prevState,
        imagesMap: {
          ...prevState.imagesMap,
          [index]: {
            ...prevState.imagesMap[index],
            loading: false,
          },
        },
      };
    });
  };

  render(): React.ReactNode {
    const propsCarousel = {
      dots: false,
      infinite: true,
      arrows: true,
      speed,
      slidesToShow,
      slidesToScroll,
    };
    const { images, isNoCoverFit, keyValue } = this.props;
    const { imagesMap } = this.state;

    return (
      <Carousel {...propsCarousel} lazyLoad="ondemand">
        {images.map((image: IImage, i: number) => {
          return (
            <div className="custom-carousel-image" key={i}>
              <div className="custom-carousel-image__spinner" style={{ height: 'inherit' }}>
                <Spin />
              </div>
              <img
                style={{ display: imagesMap[i]?.loading ? 'none' : 'block' }}
                key={`carrousel_image${keyValue ? `_${keyValue}` : ''}_${i}`}
                className={`custom-carousel-image__item ${isNoCoverFit ? 'no-cover' : ''}`}
                src={image.url}
                alt={image.description}
                onLoad={() => this.onImageLoad(i)}
              />
            </div>
          );
        })}
      </Carousel>
    );
  }
}
