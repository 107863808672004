import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Urls } from '@share/constants';
import { getHeaders, axiosInstance } from '@share/utils';
import { AppThunk } from '@share/utils';

export interface ISupportPhoneState {
  supportPhone: string;
  loading: boolean;
  error: string;
}

const initialState: ISupportPhoneState = {
  supportPhone: null,
  loading: false,
  error: '',
};

const supportPhoneSlice = createSlice({
  name: 'supportPhone',
  initialState,
  reducers: {
    setLoading: (state: ISupportPhoneState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: ISupportPhoneState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setSupportPhone: (state: ISupportPhoneState, { payload }: PayloadAction<string>) => {
      state.supportPhone = payload;
    },
  },
});

export const supportPhoneActions = supportPhoneSlice.actions;

export const supportPhoneReducer = supportPhoneSlice.reducer;

export const getSupportPhone = (): AppThunk => {
  return async (dispatch) => {
    dispatch(supportPhoneActions.setLoading(true));

    try {
      const res = await axiosInstance.get(Urls.supportPhone, {
        ...getHeaders(),
      });

      dispatch(supportPhoneActions.setLoading(false));
      dispatch(supportPhoneActions.setSupportPhone(res.data));
    } catch (error) {
      console.error(error);
      dispatch(supportPhoneActions.setError(error.toString()));
      dispatch(supportPhoneActions.setLoading(false));
    }
  };
};
