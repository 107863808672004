import React, { Component } from 'react';

import { isEmpty } from 'lodash';

import './style.scss';

interface IProps {
  image: any;
  text: string;
  text2?: string;
  secondaryText?: string;

  onClick?: () => void;
}

export class LinkImage extends Component<IProps> {

  render(): React.ReactNode {
    const { text, text2, secondaryText, image, onClick } = this.props;

    return (
      <div className="link-image" onClick={onClick ? onClick : () => {}}>
        <div className="link-image__container">
          <div className="background-image" style={{ backgroundImage: `url(${image})` }}></div>

          <div className="background"></div>

          <div className="main-content">
            <div className="main-text">{text}</div>
            {!isEmpty(text2) ? (
              <div className="main-text text2">{text2}</div>) : null}
          </div>

          {!isEmpty(secondaryText) ? (
            <div className="content">
              <div className="text">{secondaryText}</div>
            </div>) : null}
        </div>
      </div>
    );
  }
}
