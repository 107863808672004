import isEmpty from 'lodash/isEmpty';
import { IMemberStories, IExternalWP } from '@common-types';
import { IImage } from '@share/common-types';

type MapExternalData = (externalData: IExternalWP[]) => IMemberStories[];

export const mapExternalData: MapExternalData = (externalMemberStories) => {
  const domParser = new DOMParser();

  return externalMemberStories.map((externalData) => {
    const document = domParser.parseFromString(
      externalData.content.rendered as string,
      'text/html',
    );

    return {
      id: externalData.id,
      author: getAuthorByDocument(document),
      images: getImgListByDocument(document),
      paragraphs: getParagraphListSrcListByDocument(document),
      modifiedDate: externalData.modified,
      title: externalData.title.rendered,
    };
  });
};

const getImgListByDocument = (document: Document): IImage[] => {
  const imgNodeList = document.querySelector('.wp-block-gallery ').querySelectorAll('img');
  const imgList = Array.from(imgNodeList);

  return imgList.map((node) => ({
    url: node.src,
    height: node.height,
    width: node.width,
  }));
};

const getAuthorByDocument = (document: Document): string =>
  document.querySelector('.wp-block-post-author .wp-block-post-author__byline')?.textContent || '';

const getParagraphListSrcListByDocument = (document: Document): string[] => {
  const pNodeList = document.querySelectorAll('p:not([class])');

  return (
    Array.from(pNodeList)
      .map((node) => node.textContent)
      .filter((text) => !isEmpty(text)) || []
  );
};
