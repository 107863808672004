import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IVideoMedia, staticContentAPI } from '@api';
import { Urls } from '@share/constants';
import { AppThunk } from '@share/utils';

export interface IMemberVideosState {
  videos: IVideoMedia[];
  loading: boolean;
  error: string;
  activeVideo: string;
}

const initialState: IMemberVideosState = {
  activeVideo: '',
  videos: [],
  loading: false,
  error: '',
};

const memberVideosSlice = createSlice({
  name: 'memberVideos',
  initialState,
  reducers: {
    setLoading: (state: IMemberVideosState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setActiveVideoByFileName: (state: IMemberVideosState, { payload }: PayloadAction<string>) => {
      state.activeVideo = payload;
    },
    setError: (state: IMemberVideosState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setMemberVideos: (state: IMemberVideosState, { payload }: PayloadAction<IVideoMedia[]>) => {
      state.videos = payload;
    },
  },
});

export const memberVideosActions = memberVideosSlice.actions;
export const memberVideosReducer = memberVideosSlice.reducer;

export const fetchMemberVideos = (): AppThunk => {
  return async (dispatch) => {
    dispatch(memberVideosActions.setLoading(true));

    try {
      const { data } = await staticContentAPI.getMemberVideos();

      dispatch(memberVideosActions.setLoading(false));

      const fullPathVideos = data.videos.map((video: IVideoMedia) => ({
        ...video,
        fileName: video.filePath,
        filePath: `${Urls.MemberVideos}/${video.filePath}`,
      }));

      dispatch(memberVideosActions.setMemberVideos(fullPathVideos));
    } catch (error) {
      console.error(error);
      dispatch(memberVideosActions.setError(error.toString()));
      dispatch(memberVideosActions.setLoading(false));
    }
  };
};
