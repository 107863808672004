import { useEffect, useMemo } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Carousel } from 'antd';
import { ITravelLeaderState, getTravelLeader } from '@store/slices';
import { ILoginState, IMenuState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { BlueButton } from '@share/components';
import { TRAVEL_LEADERS_DOMAIN } from '@constants';
import { ITravelLeader } from '@common-types';
import { Routes } from '@share/constants';

import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

interface IMapStateToProps {
  travelLeaderStore: ITravelLeaderState;
  loginStore: ILoginState;
  menuStore: IMenuState;
}

interface IMapDispatchToProps {
  getTravelLeader: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  matches?: boolean;
}

const SPEED = 1000;
const SLIDES_TO_SHOW = 1;
const SLIDES_TO_SCROLL = 1;

function TravelLeaderComponent(props: IProps) {

  const propsCarousel = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: SPEED,
    slidesToShow: SLIDES_TO_SHOW,
    slidesToScroll: SLIDES_TO_SCROLL,
    autoplay: true,
  };
  const { travelLeaderStore, loginStore, menuStore, getTravelLeader } = props;
  const { travelLeader, loading } = travelLeaderStore;
  const { account } = loginStore;
  const { items } = menuStore;

  const isDataAvailable = travelLeader && travelLeader && travelLeader.length > 0;

  useEffect(() => {
    getTravelLeader();
  }, []);

  const siteId = items?.siteId;
  const organizationId = items?.organizationId;

  const displayTravelLeaderBackgroundRsi = account?.displayTravelLeaderBackgroundRsi;
  const displayTravelLeaderBackgroundRsiOrgidList = account?.displayTravelLeaderBackgroundRsiOrgidList;
  const displayTravelLeaderBackgroundRsiSiteidList = account?.displayTravelLeaderBackgroundRsiSiteidList;

  const displayTravelLeadersBackground = useMemo(() => displayTravelLeaderBackgroundRsi && ((!displayTravelLeaderBackgroundRsiOrgidList?.length && !displayTravelLeaderBackgroundRsiSiteidList?.length) || 
                                                                                              displayTravelLeaderBackgroundRsiOrgidList?.includes(organizationId?.toString() as string) || 
                                                                                              displayTravelLeaderBackgroundRsiSiteidList?.includes(siteId?.toString() as string)),[account, items]);

  let styles: any = account?.fontFamily ? { fontFamily: account?.fontFamily } : {};
  styles = account?.travelLeadersFontSize ? { ...styles, fontSize: `${account?.travelLeadersFontSize}px`, lineHeight: `${+account?.travelLeadersFontSize + 6}px` } : { ...styles };
  
  return isDataAvailable && !loading && !menuStore?.loading ? (
    <div className="travel-leader">
      <Carousel {...propsCarousel} lazyLoad="ondemand">
        {travelLeader.map((item: ITravelLeader, i: number) => {
          const image = `${TRAVEL_LEADERS_DOMAIN}${item.fileName}`;
          const backgroundImage = displayTravelLeadersBackground ? `url(${image})` : `linear-gradient(rgba(32, 39, 77, 0.3), rgba(32, 39, 77, 0.3)),url(${image})`;

          return (
            <div className="travel-leader__carousel-wrapper" key={i + item.promotionId}>
              <div
                className="travel-leader__image-wrapper"
                style={{ backgroundImage }}
              ></div>
              <div
                className="travel-leader__info-wrapper"
                style={{ background: 'transparent', width: `${window.innerWidth}px` }}
              >
                <div
                  className={`travel-leader__info-title secondary-font ${displayTravelLeadersBackground ? 'background-filter' : ''}`}
                  dangerouslySetInnerHTML={{ __html: item.headline }}
                  style={styles}
                />
                <Link
                  to={`/${account?.name}${Routes.PromotionDetails}/${item?.promotionId}`}
                  className="travel-leader__info-link"
                >
                  <BlueButton reverse>
                    <FormattedMessage id="view.details" />
                    <FontAwesomeIcon icon={faArrowRight} />
                  </BlueButton>
                </Link>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  ) : null;
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
    travelLeaderStore: state.travelLeaderStore,
    loginStore: state.loginStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = { getTravelLeader };

export const TravelLeader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(TravelLeaderComponent));
