
import React, { useEffect } from 'react';

import { scrollTop } from '@share/utils';
import { ContactUsForm } from '../contact-us-form';
import { ContactUsHeading } from '../contact-us-heading';
import { ContactUsChat } from '../contact-us-chat';

import './style.scss';

export function ContactUsWrapper() {
  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <>
      <ContactUsHeading />
      <ContactUsChat />
      <ContactUsForm />
    </>
  );
}
