import React, { useEffect, useRef } from 'react';

import { FunctionalRootState, scrollTop } from '@share/utils';

import './style.scss';
import ArrowDownBanner from '@assets/images/mlm-levalti/new-agents/arrow-down-banner.svg';
import HomeTravelImg from '@assets/images/mlm-levalti/become-travel-agent/home-based-travel-agent-at-desk.jpg';
import CoupleVacationImg from '@assets/images/mlm-levalti/become-travel-agent/couple-on-vacatio-holding-map.png';
import BlueOutfitImg from '@assets/images/mlm-levalti/become-travel-agent/blue-outfit-woman-at-white-desk.png';
import NewAgentImg from '@assets/images/mlm-levalti/become-travel-agent/new-agent-starting.jpg';
import SupplierImg from '@assets/images/mlm-levalti/become-travel-agent/supplier-list-of-logos.png';
import ResortImg from '@assets/images/mlm-levalti/become-travel-agent/resort-logo-bar.jpg';
import CruiseImg from '@assets/images/mlm-levalti/become-travel-agent/cruise-logo-bar.jpg';
import HotelImg from '@assets/images/mlm-levalti/become-travel-agent/hotel-logo-bar.jpg';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ILoginState } from '@share/store/slices';
import { useSelector } from 'react-redux';

export default function MLMBecomeTravelAgent() {
  useEffect(() => {
    scrollTop();
  }, []);

  const startedSectionRef = useRef<HTMLDivElement>(null);
  //Smooth Scroll Click
  const scrollToSection = () => {
    if (startedSectionRef.current) {
      startedSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const { account }: ILoginState = useSelector((state: FunctionalRootState) => state.loginStore);

  const intl = useIntl();

  // Obtén el mensaje como un string
  const paragraph: string[] = intl
    .formatMessage({ id: 'technology.supplier.paragraph' })
    .split('.');

  return (
    <div className="become">
      {/*Header*/}
      <header className="become__header">
        <section className="become__header-title">
          <h1 className="become__header-title-main">
            <span className="become__header-title-main-subtitle1">
              <span className="become__header-title-main-subtitle1-first">
                <FormattedMessage id="become.header.title" />{' '}
              </span>
              <span>
                <FormattedMessage id="become.header.become" />
              </span>
            </span>{' '}
            <span className="become__header-title-main-subtitle2">
              <FormattedMessage id="become.header.subtitle" />
            </span>
          </h1>
        </section>
        <section className="become__header-arrow">
          <img
            src={ArrowDownBanner}
            alt="arrow down"
            onClick={scrollToSection}
            className="move-down-on-hover"
          />
        </section>
      </header>
      <section className="become__main-section" ref={startedSectionRef}>
        {/*First Section*/}
        <section className="become__main-section-travel">
          <section className="become__main-section-travel-one">
            <h2 className="become__main-section-travel-one-title">
              <strong>
                <FormattedMessage id="become.travel.title1" />
              </strong>{' '}
              <FormattedMessage id="become.travel.title2" />
            </h2>
            <p className="become__main-section-travel-one-paragraph">
              <FormattedMessage id="become.travel.paragraph1" />{' '}
              <strong>
                <FormattedMessage id="become.travel.paragraph1.bold" />
              </strong>{' '}
              <FormattedMessage id="become.travel.paragraph1.end" />.
            </p>
            <p>
              <FormattedMessage id="become.travel.paragraph2" />{' '}
              <strong>
                {' '}
                <FormattedMessage id="become.travel.paragraph3" />
              </strong>
              .
            </p>
          </section>
          <section className="become__main-section-travel-two">
            <h2>
              <span className="become__main-section-travel-two-reach">
                <FormattedMessage id="become.travel.reach" />
              </span>
              <span className="become__main-section-travel-two-tel">
                <FormattedMessage id="become.travel.phone" />
              </span>
            </h2>
          </section>
        </section>
        {/*Second Section*/}
        <section className="become__main-section-main">
          <section className="become__main-section-main-container">
            <section className="become__main-section-main-container-img">
              <img src={HomeTravelImg} alt="Home Travel Img" />
            </section>
            <section className="become__main-section-main-container-content">
              <h2 className="become__main-section-main-container-content-title">
                <span>
                  <FormattedMessage id="become.content.title" />
                </span>{' '}
                <span className="become__main-section-main-container-content-title-bold">
                  <FormattedMessage id="become.content.subtitle" />
                </span>
              </h2>
              <p className="become__main-section-main-container-content-paragraph">
                <FormattedMessage id="become.content.paragraph" />
              </p>
              <ul className="become__main-section-main-container-content-list">
                <li>
                  <FormattedMessage id="become.content.list1" />
                </li>
                <li>
                  <FormattedMessage id="become.content.list2" />
                </li>
                <li>
                  <FormattedMessage id="become.content.list3" />
                </li>
                <li>
                  <FormattedMessage id="become.content.list4" />
                </li>
                <li>
                  <FormattedMessage id="become.content.list5" />
                </li>
              </ul>
              <section className="become__main-section-main-container-content-btns">
                <Link to={`/${account?.name}/new-agents`}>
                  <button>
                    <FormattedMessage id="become.content.btn1" />
                  </button>
                </Link>
                <Link to={`/${account?.name}/experienced-agents`}>
                  <button>
                    <FormattedMessage id="become.content.btn2" />
                  </button>
                </Link>
              </section>
            </section>
          </section>
        </section>
        {/*Third Section*/}
        <section className="become__main-section-benefits">
          <section className="become__main-section-benefits-container">
            <section className="become__main-section-benefits-container-content">
              <h2 className="become__main-section-benefits-container-content-title">
                <span>
                  <FormattedMessage id="become.benefits.title" />
                </span>{' '}
                <span className="become__main-section-benefits-container-content-title-bold">
                  <FormattedMessage id="become.benefits.subtitle" />
                </span>
              </h2>
              <p className="become__main-section-benefits-container-content-paragraph">
                <FormattedMessage id="become.benefits.paragraph" />
              </p>
              <ul className="become__main-section-benefits-container-content-list">
                <li>
                  <FormattedMessage id="become.benefits.list1" />
                </li>
                <li>
                  <FormattedMessage id="become.benefits.list2" />
                </li>
                <li>
                  <FormattedMessage id="become.benefits.list3" />
                </li>
              </ul>
              <section className="become__main-section-benefits-container-content-btns">
                <Link to={`/${account?.name}/exclusive-access`}>
                  <button>
                    <FormattedMessage id="become.benefits.learnmore" />
                  </button>
                </Link>
              </section>
            </section>
            <section className="become__main-section-benefits-container-img">
              <img src={CoupleVacationImg} alt="Couple Vacation Img" />
            </section>
          </section>
        </section>
        {/*Fourth Section*/}
        <section className="become__main-section-experienced">
          <section className="become__main-section-experienced-container">
            <section className="become__main-section-experienced-container-started">
              <section className="become__main-section-experienced-container-started-img">
                <img
                  src={NewAgentImg}
                  alt="New Agent Img"
                  className="become__main-section-experienced-container-started-img-one"
                />
                <Link to={`/${account?.name}/new-agents`}>
                  <button>
                    <FormattedMessage id="become.experienced.btn1" />
                  </button>
                </Link>
              </section>
              <section className="become__main-section-experienced-container-started-content">
                <h2>
                  <FormattedMessage id="become.experienced.title" />
                </h2>
                <p>
                  <FormattedMessage id="become.experienced.paragraph" />
                </p>
              </section>
            </section>
            <section className="become__main-section-experienced-container-agent">
              <section className="become__main-section-experienced-container-agent-img">
                <img
                  src={BlueOutfitImg}
                  alt="Blue Outfit Img"
                  className="become__main-section-experienced-container-started-img-two"
                />
                <Link to={`/${account?.name}/experienced-agents`}>
                  <button>
                    {' '}
                    <FormattedMessage id="become.experienced.btn2" />
                  </button>
                </Link>
              </section>
              <section className="become__main-section-experienced-container-agent-content">
                <h2>
                  <FormattedMessage id="become.experienced.title2" />
                </h2>
                <p>
                  <FormattedMessage id="become.experienced.paragraph2" />
                </p>
              </section>
            </section>
          </section>
        </section>
        {/*Fifth Section*/}
        <section className="become__main-section-change">
          <section className="become__main-section-change-container">
            <section className="become__main-section-change-container-video">
              <iframe
                width="640"
                height="360"
                src="https://www.youtube.com/embed/IGxQBVmGB0w?si=aBTO5AMoQ9Zmjqwc"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="become__main-section-change-container-video-title">
                <FormattedMessage id="become.change.tap" />
              </p>
            </section>
            <section className="become__main-section-change-container-text">
              <h2 className="become__main-section-change-container-text-title">
                <FormattedMessage id="become.change.title" />{' '}
                <strong>
                  <FormattedMessage id="become.change.title.bold" />
                </strong>
              </h2>
              <h3 className="become__main-section-change-container-text-subtitle">
                <FormattedMessage id="become.change.subtitle1" />{' '}
                <strong>
                  <FormattedMessage id="become.change.subtitle2" />
                </strong>
              </h3>
              <h5 className="become__main-section-change-container-text-subtitle">
                <FormattedMessage id="become.change.list1" />
              </h5>
              <h5 className="become__main-section-change-container-text-subtitle">
                <FormattedMessage id="become.change.list2" />
              </h5>
              <h5 className="become__main-section-change-container-text-subtitle">
                <FormattedMessage id="become.change.list3" />
              </h5>
            </section>
          </section>
        </section>
        {/*Sixfth Section*/}
        <section className="become__main-section-suppliers">
          <section className="become__main-section-suppliers-container">
            <section className="become__main-section-suppliers-container-text">
              <h4>
                <FormattedMessage id="become.suppliers.title" />
              </h4>
              <h2>
                <FormattedMessage id="become.suppliers.subtitle" />
              </h2>
              <p>
                <FormattedMessage id="become.suppliers.paragraph" />
              </p>
            </section>
            <section className="become__main-section-suppliers-container-imgs">
              <img src={ResortImg} alt="Resort Img" />
              <img src={CruiseImg} alt="Cruise Img" />
              <img src={HotelImg} alt="Hotel Img" />
            </section>
          </section>
        </section>
        {/*Seventh Section*/}
        <section className="become__main-section-agent">
          <section className="become__main-section-agent-container">
            <section className="become__main-section-agent-container-text">
              <h3>
                <FormattedMessage id="become.agent.paragraph" />
              </h3>
              <p>
                <FormattedMessage id="become.agent.quote" />
              </p>
            </section>
          </section>
        </section>
        {/*Eight Section*/}
        <section className="become__main-section-brands">
          <section className="become__main-section-brands-container">
            <section className="become__main-section-brands-container-paragraph">
              <h2>
                <FormattedMessage id="become.certified.title" />
              </h2>
              <p>
                <FormattedMessage id="agents.brands.subtitle" />
              </p>
            </section>
            <section className="become__main-section-brands-container-imgs">
              <div className="become__main-section-brands-container-imgs-brand">
                <img src={SupplierImg} alt="Supplier Img" />
              </div>
              {/* <div className="become__main-section-brands-container-imgs-btn">
                <Link to={`/${account?.name}/training`}>
                  <button>
                    <FormattedMessage id="agents.brands.learnmore" />
                  </button>
                </Link>
              </div> */}
            </section>
          </section>
        </section>
      </section>
    </div>
  );
}
