
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '@share/utils';
import { IMenuState } from '@share/store/slices';
import { AwardWinnersList } from '@constants';

import './style.scss';

interface IMapStateToProps {
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps {}

const FOUR = 4;
const FIVE = 5;

function ConciergeBookingComponent(props: IProps) {
  const phone = props.menuStore.items?.phone;

  return (
    <div className="booking-wrapper">
      <div className="booking-wrapper__wrapper">
        <h2 className="booking-wrapper__heading secondary-font">
          <FormattedMessage id="treat.yourself.book.with.our.team" />:
          {phone ? (
            <a className="booking-wrapper__phone" href={`tel:${phone}`}>
              {` ${phone}`}
            </a>
          ) : null}
        </h2>
        <h3 className="booking-wrapper__subheading">
          <FormattedMessage id="concierge.booking.subheading" />
        </h3>
        <p className="booking-wrapper__paragraph">
          <FormattedMessage id="concierge.booking.paragraph" />
        </p>
        <div className="booking-wrapper__companies-wrapper">
          {AwardWinnersList.map(({ id, src, company, award }, i) => (
            <div key={id} className="booking-wrapper__company">
              <img
                src={src}
                className={`booking-wrapper__image ${i === FOUR || i === FIVE ? 'mt-2' : ''}`}
              />
              <div>
                <div className="booking-wrapper__name">
                  <FormattedMessage id={company} />
                </div>
                <div className="booking-wrapper__award">
                  <FormattedMessage id={award} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
  };
};

export const ConciergeBooking = connect(mapStateToProps)(ConciergeBookingComponent);
