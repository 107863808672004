import React, { useMemo } from 'react';

import { connect } from 'react-redux';

import { DATE_FORMAT } from '@share/constants';
import { RootState } from '@share/utils';
import { SearchSvg } from '@share/assets';
import { IWeeksDatesState, ILoginState, IWeeksLocationsState } from '@share/store/slices';

import './style.scss';
import moment from 'moment';

interface IMapStateToProps {
  weeksLocationsStore: IWeeksLocationsState;
  weeksDatesStore: IWeeksDatesState;
  
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {
  onToggle: (e: React.MouseEvent<HTMLParagraphElement>) => void;
}

function WeeksSearchInfoComponent(props: IProps) {
  const { weeksLocationsStore, weeksDatesStore, loginStore, onToggle } = props;
  const { account } = loginStore;

  const locale = account?.locale;

  const selectedLocationLabel = weeksLocationsStore?.selectedLocationLabel;

  const checkInFormated = useMemo(() => weeksDatesStore.date ? moment(weeksDatesStore.date, 'MM/DD/yyyy').format(DATE_FORMAT) : '', [weeksDatesStore.date, locale]);
  const isValidDates = useMemo(() => weeksDatesStore.date, [weeksDatesStore.date]);

  if (!isValidDates) {
    return null;
  }

  return (
    <div className="search-info" onClick={onToggle}>
      <SearchSvg />
      <div className="search-info__info">
        <p className="search-info__locations">{selectedLocationLabel}</p>
        <p className="search-info__description">
          {checkInFormated}
          {weeksDatesStore.period}
        </p>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    weeksLocationsStore: state.weeksLocationsStore,
    weeksDatesStore: state.weeksDatesStore,
    loginStore: state.loginStore,
  };
};

export const WeeksSearchInfo = connect(mapStateToProps)(WeeksSearchInfoComponent);
