import React from 'react';
import { Skeleton } from 'antd';
import './style.scss';

interface IProps {}

const NUMBER = 1;
const NUMBER_OF_BLOCKS = [NUMBER, NUMBER];

export class SkeletonGetawaysCondo extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <div className="skeleton-getaways-condo">
        {NUMBER_OF_BLOCKS.map((item, index) => (
          <div key={`${index}-${item}`} className="skeleton-getaways-condo__wrapper">
            <Skeleton
              className="skeleton-getaways-condo__img-skeleton"
              active
              title={false}
              paragraph={{ rows: 1 }}
            />
            <Skeleton
              className="skeleton-getaways-condo__text-skeleton"
              active
              title={false}
              paragraph={{ rows: 2 }}
            />
            <Skeleton
              className="skeleton-getaways-condo__text-skeleton"
              active
              title={false}
              paragraph={{ rows: 3 }}
            />
          </div>
        ))}
      </div>
    );
  }
}
