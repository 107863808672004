import { AxiosPromise } from 'axios';
import { IReservationsParams } from '@share/common-types';
import { axiosInstance, axiosInstanceB2B, getHeaders } from '@share/utils';
import { Urls } from '@share/constants';
import { isEmpty } from 'lodash';

export const ReservationsAPI = {
  getReservations(params: IReservationsParams): AxiosPromise<any> {
    const { orderBy, sortOrder, page, pageSize, bookingId, propertyName, leadName, bookingType, status } =  params;

    const bookingIdParam = `${bookingId? `&bookingId=${bookingId}`:''}`;
    const propertyNameParam = `${propertyName? `&propertyName=${propertyName}`:''}`;
    const leadNameParam = `${leadName? `&leadName=${leadName}`:''}`;
    const bookingTypeParam = `${bookingType ? `&bookingType=${bookingType}` : ''}`;
    const statusParam = `${status ? `&status=${status}` : ''}`;

    const orderParam = `${(orderBy && sortOrder)? `&orderBy=${orderBy}`:''}${sortOrder ? `&sortOrder=${sortOrder}`:''}`;
    const queryString = `?page=${page}&results=${pageSize}${bookingTypeParam}${orderParam}${bookingIdParam}${propertyNameParam}${leadNameParam}${statusParam}`;

    return axiosInstance.get(`${Urls.getReservations}${queryString}`, {
      ...getHeaders(),
    });
  },

  getStatic(id: number): AxiosPromise<any> {
    return axiosInstance.get(`${Urls.getContentByPropertyId}${`?propertyId=${id}`}`, {
      ...getHeaders(),
    });
  },

  searchByText(queryString: string, type?: string): AxiosPromise<any> {
    const params: any = { queryString };
    if (!isEmpty(type)) {
      params.bookingType = type;
    }
    return axiosInstance.get(Urls.reservationsSearchText, {
      params,
      ...getHeaders(),
    });
  },

  cancelBookingAsync(bookingId: string, comment: string): AxiosPromise<any> {
    return axiosInstance.post(Urls.reservationsCancel, { id: bookingId, comment }, {
      ...getHeaders(),
    });
  },

  voucherBookingAsync(bookingId: string, recepientEmail: string, additionalEmail: string): AxiosPromise<any> {
    return axiosInstance.post(Urls.reservationsSendVoucher, { bookingId, recepientEmail, additionalEmail }, {
      ...getHeaders(),
    });
  },

  downloadbookingVoucherAsync(bookingId: string) {  
    return axiosInstanceB2B
      .get(`${Urls.DownloadVoucher}/${bookingId}/voucher`, {responseType: 'blob'})
      .then((response) => {   
  
        const headerLine = response.headers['content-disposition'];
        let filename;
        if (headerLine) {
          let startFileNameIndex = headerLine.indexOf('"') + 1
          let endFileNameIndex = headerLine.lastIndexOf('"');
          filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
        } else {
          filename = `booking_${bookingId}_voucher.pdf`;
        }
  
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      });
  },
};
