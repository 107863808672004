import { createSlice, PayloadAction, CombinedState } from '@reduxjs/toolkit';
import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { IRoom, IGuest, ISessionKey } from '@share/common-types';
import { IHotelInfo } from '@common-types';
import {
  getHeaders,
  axiosInstance,
  Toaster,
} from '@share/utils';
import {
  getHotelsRequest,
  getTimeout,
  UrlUtils,
  getSelectedCurrency
} from '@share/utils';
import { AppThunk, RootState } from '@share/utils';
import {
  EMPTY_ROOMS_ARRAY_MESSAGE,
} from '@constants';
import {
  ROOMS_SEARCH_LABEL,
  Urls,
  SESSION_EXPIRED_STATUS,
} from '@share/constants';
import { hotelDetailsActions, IHotelDetailsState } from './hotels-details';
import { getEmptyRoom, datesActions, IHotelsState, roomsActions } from '@share/store/slices';

export interface IRoomsSearchState {
  rooms: IRoom[];
  startDate: string;
  endDate: string;
  key: string;
  error: string;
  isRoomsLoading: boolean;
  hotel: IHotelInfo;
  sessionKeyRooms?: ISessionKey;
  timer: number;
  showUpdatedBanner: boolean;
}

const initialState: IRoomsSearchState = {
  rooms: [getEmptyRoom()],
  startDate: undefined,
  endDate: undefined,
  key: 'selection',
  error: '',
  isRoomsLoading: false,
  hotel: null,
  timer: null,
  showUpdatedBanner: false,
};

const zero = 0;
const newRoomAdultsCount = 1;

const roomsSearchSlice = createSlice({
  name: 'hotel-room-search',
  initialState,
  reducers: {
    addEmptyRoom: (state: IRoomsSearchState) => {
      state.rooms.push(getEmptyRoom(newRoomAdultsCount));
    },
    updateAdultsCount: (
      state: IRoomsSearchState,
      { payload }: PayloadAction<{ roomId: string; count: number }>,
    ) => {
      const room = state.rooms.find(({ id }) => id === payload.roomId);

      if (room) {
        room.adultsCount = payload.count;

        if (!payload.count) {
          room.kids = [];
        }
      }
    },
    addKid: (
      state: IRoomsSearchState,
      { payload }: PayloadAction<{ roomId: string; kid: IGuest }>,
    ) => {
      const room = state.rooms.find(({ id }) => id === payload.roomId);

      if (room) {
        room.kids.push(payload.kid);
      }
    },
    updateKidAge: (
      state: IRoomsSearchState,
      { payload }: PayloadAction<{ roomId: string; kidId: string; age: number }>,
    ) => {
      const room = state.rooms.find(({ id }) => id === payload.roomId);

      if (room) {
        const kid = room.kids.find(({ id }) => id === payload.kidId);

        if (kid) {
          kid.age = payload.age;
        }
      }
    },
    removeRoom: (state: IRoomsSearchState, { payload }: PayloadAction<string>) => {
      state.rooms = state.rooms.filter(({ id }) => id !== payload);
    },
    removeKid: (
      state: IRoomsSearchState,
      { payload }: PayloadAction<{ roomId: string; kidId: string }>,
    ) => {
      const room = state.rooms.find(({ id }) => id === payload.roomId);

      if (room) {
        room.kids = room.kids.filter(({ id }) => id !== payload.kidId);
      }
    },
    setRooms: (state: IRoomsSearchState, { payload }: PayloadAction<IRoom[]>) => {
      state.rooms = payload;
    },
    setDates: (
      state: IRoomsSearchState,
      { payload }: PayloadAction<{ startDate: string; endDate: string }>,
    ) => {
      state.startDate = payload.startDate;
      state.endDate = payload.endDate;
    },
    setStartDate: (state: IRoomsSearchState, { payload }: PayloadAction<string>) => {
      state.startDate = payload;
    },
    setEndDate: (state: IRoomsSearchState, { payload }: PayloadAction<string>) => {
      state.endDate = payload;
    },
    setKey: (state: IRoomsSearchState, { payload }: PayloadAction<string>) => {
      state.key = payload;
    },
    setError: (state: IRoomsSearchState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setIsRoomsLoading: (state: IRoomsSearchState, { payload }: PayloadAction<boolean>) => {
      state.isRoomsLoading = payload;
    },
    setHotelInfo: (state: IRoomsSearchState, { payload }: PayloadAction<IHotelInfo>) => {
      state.hotel = payload;
    },
    setTimer: (state: IRoomsSearchState, { payload }: PayloadAction<number>) => {
      state.timer = payload;
    },
    setSessionKey: (state: IRoomsSearchState, { payload }: PayloadAction<ISessionKey>) => {
      state.sessionKeyRooms = payload;
    },
    setShowUpdatedBanner: (state: IRoomsSearchState, { payload }: PayloadAction<boolean>) => {
      state.showUpdatedBanner = payload;
    },
    resetState: () => {
      return initialState;
    },
  },
});

export const roomsSearchActions = roomsSearchSlice.actions;

export const roomsSearchReducer = roomsSearchSlice.reducer;

//let cancelRequest: Canceler;
let expirationTimeout: number;

export const updateRooms = (
  expireDate: string,
  dispatch: ThunkDispatch<RootState, unknown, Action<any>>,
  getState: () => CombinedState<{
    roomsSearchStore: IRoomsSearchState;
    hotelDetailsStore: IHotelDetailsState;
    hotelsStore: IHotelsState;
  }>,
): void => {
  // @ts-ignore
  expirationTimeout = setTimeout(() => {
    const { roomsSearchStore, hotelDetailsStore, hotelsStore } = getState();

    dispatch(
      getRoomsDetails(
        roomsSearchStore.hotel?.hotelDetails?.id || hotelDetailsStore.hotel?.hotelDetails?.id,
        hotelsStore.sessionKey
      ),
    );
    dispatch(roomsSearchActions.setShowUpdatedBanner(true));
  }, getTimeout(expireDate));
  dispatch(roomsSearchActions.setTimer(expirationTimeout));
};

export const getRoomsDetails = (hotelId: number, sessionKey: ISessionKey, isFullLoading?: boolean): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(roomsSearchActions.setIsRoomsLoading(true));

    if (!!expirationTimeout) {
      clearTimeout(expirationTimeout);
    }

    if (isFullLoading) {
      dispatch(hotelDetailsActions.setLoading(true));
    }

    const { roomsSearchStore, locationsStore } = getState();
    const { startDate, endDate, rooms } = roomsSearchStore;
    const { selectedLocation } = locationsStore;

    dispatch(datesActions.setDatesSelected({ startDate, endDate }));
    dispatch(roomsActions.setRooms(rooms));

    dispatch(datesActions.applyDates());
    dispatch(roomsActions.applyRooms());

    try {
      UrlUtils.setUrl(ROOMS_SEARCH_LABEL, {
        startDate,
        endDate,
        rooms: rooms.map((room: IRoom) => {
          return {
            adultsCount: room.adultsCount,
            kids: room.kids.map(({ age }) => age),
          };
        }),
      });

      const { loginStore } = getState();
      const { lifeStyle } = loginStore;

      const res = await axiosInstance.post(
        Urls.HotelRooms,
        {
          marginator: {percentage: 0},
          sessionKey,
          hotelRequest: {
            ...getHotelsRequest(rooms, startDate, endDate, selectedLocation?.code),
            hotelId,
          },
          currency: getSelectedCurrency(loginStore.account),
          lifeStyle
        },
        {
          ...getHeaders(),
        },
      );

      dispatch(roomsSearchActions.setHotelInfo(res.data));
      dispatch(hotelDetailsActions.setHotelDetails(res.data));
      dispatch(hotelDetailsActions.setLoading(false));
      dispatch(roomsSearchActions.setIsRoomsLoading(false));
      dispatch(roomsSearchActions.setSessionKey(res.data.sessionKey));
      dispatch(roomsSearchActions.setError(''));

      // @ts-ignore
      updateRooms(res.data.sessionKey?.expireDate, dispatch, getState);

    } catch (error) {
      console.error(error);

      dispatch(roomsSearchActions.setError('No rooms availables for the criteria selected. Change the criteria to continue.'));

      if (
        error?.response?.status === SESSION_EXPIRED_STATUS &&
        error?.response?.data &&
        error?.response?.data[zero] === EMPTY_ROOMS_ARRAY_MESSAGE
      ) {
        dispatch(
          roomsSearchActions.setHotelInfo({
            roomsContent: [],
            packages: [],
            hotelDetails: null,
            trustYouData: null,
            adjustedPackageGroups: [],
          }),
        );
      }

      if (!(error instanceof axios.Cancel)) {
        dispatch(roomsSearchActions.setIsRoomsLoading(false));
      }

      Toaster.error('No rooms availables for the criteria selected. Change the criteria to continue.');
    }
  };
};
