import React from 'react';
import { Skeleton } from 'antd';
import './style.scss';

interface IProps {
  skipRightTop?: boolean;
}

export class SkeletonReviewBook extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <div className="skeleton-review-book">
        <div className="skeleton-review-book__wrapper">
          <div className="skeleton-review-book__left-block">
            <Skeleton
              className="skeleton-review-book__left-block-top"
              active
              title
              paragraph={{ rows: 4 }}
            />
            <div className="skeleton-review-book__center-block">
              <Skeleton
                className="skeleton-review-book__center-block-top"
                active
                title={false}
                paragraph={{ rows: 2 }}
              />
              <Skeleton
                className="skeleton-review-book__center-block-bottom"
                active
                title={false}
                paragraph={{ rows: 4 }}
              />
            </div>
            <Skeleton
              className="skeleton-review-book__left-block-bottom"
              active
              title={false}
              paragraph={{ rows: 1 }}
            />
          </div>
          <div className="skeleton-review-book__right-block">
            {!this.props.skipRightTop ?(
              <Skeleton
                className="skeleton-review-book__right-block-top"
                active
                title={false}
                paragraph={{ rows: 1 }}
              />) : null}
            <Skeleton
              className="skeleton-review-book__right-block-center"
              active
              title
              paragraph={{ rows: 3 }}
            />
            <div className="skeleton-review-book__right-block-bottom-wrapper">
              <Skeleton
                className="skeleton-review-book__right-block-bottom"
                active
                title={false}
                paragraph={{ rows: 3 }}
              />
              <Skeleton
                className="skeleton-review-book__right-block-bottom"
                active
                title={false}
                paragraph={{ rows: 3 }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
