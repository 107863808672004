import { useEffect, useMemo } from 'react';
import isBefore from 'date-fns/isBefore';
import moment from 'moment';

import { connect } from 'react-redux';
import { Button } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { isEmpty } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { v4 } from 'uuid';
import { Base64 } from 'js-base64';

import {
  weeksActions,
  weeksDatesActions,
  weeksFiltersActions,
  weeksLocationsActions,
  GetWeeks,
  IWeeksDatesState,
  IWeeksFiltersState,
  IWeekState,
  IHotelsState,
  IWeeksLocationsState,
  IMenuState,
} from '@share/store/slices';
import { getDateCheckInOut, GetDisableNewTabOpen, GetStyles, RootState } from '@share/utils';
import { AccessToken, BlueButton } from '@share/components';
import { getTimeout, UrlUtils } from '@share/utils';
import {
  WEEKS_FILTERS_LABEL,
  DEFAULT_PAGE_NUMBER,
  REFERENCE_NUMBER_LABEL,
  AGENCY_NUMBER_LABEL,
  Routes,
  ACCESS_TOKEN_LABEL,
  WEEKS_SESSION_KEY_LABEL,
  WEEKS_DATES_LABEL,
  WEEKS_LOCATION_OBJECT_LABEL,
  WEEKS_LOCATION_LABEL,
} from '@share/constants';
import {
  IAccount,
  IBounds,
  ICondoLocation,
  ISessionKey,
} from '@share/common-types';
import { SearchTypeEnum } from '@share/common-types';
import { ILoginState } from '@share/store/slices';
import { isWeeksDataValid, LoginType } from '@share/utils';

import { SearchSvg, UpdateSvg } from '@share/assets';

import Background from '@share/assets/images/condo-search.jpg';

import { WeeksSearchInfo } from '../weeks-search-info';
import { WeeksLocationPicker } from '../weeks-location-picker';
import { WeeksDatePicker } from '../weeks-date-picker';

import { WeeksPeriodPicker } from '../weeks-period-picker';

import './style.scss';

interface IMapStateToProps {
  hotelsStore: IHotelsState;

  weeksLocationsStore: IWeeksLocationsState;
  weeksDatesStore: IWeeksDatesState;
  weeksStore: IWeekState;

  loginStore: ILoginState;
  menuStore: IMenuState;
}

interface IMapDispatchToProps {
  setLocationError: (error: string) => void;

  setDatesError: (error: string) => void;
  setPeriodError: (error: string) => void;

  setSelectLocationLabel: (label: string) => void;
  setSelectLocation: (location: ICondoLocation) => void;

  setDate: (date: string) => void;
  setPeriod: (period: string) => void;
  
  setIsCollapse: (isCollapse: boolean) => void;

  getWeeks: () => void;

  setIsWidget: (isWidget: boolean) => void;
  setIsSearch: () => void;
  setPageNumber: () => void;
  setSessionKey: (sessionKey: ISessionKey) => void;
  setBounds: (bounds: IBounds) => void;
  setFilters: (filters: IWeeksFiltersState) => void;
  resetFilters: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps {
  isWidget?: boolean;
  isExperiences?: boolean;
  forceVertical?: boolean;

  title?: string;
  message?: string;
  hideTitle?: boolean;
  hideMessage?: boolean;
  hideSearchSelection?: boolean;
  widgetBackgroundColor?: string;
  widgetOriginalType?: string;
  borderRadius?: string;
  
  disableLocationSearch?: boolean;

  onExperience?: () => void;

  onWidgetSelectionChange?: (value: string) => void;
}

const zero = 0;

function WeeksSearchComponent(props: IProps) {

  const { 
    isWidget,
    isExperiences,
    loginStore,
    weeksLocationsStore,
    weeksDatesStore,
    weeksStore,
    hotelsStore,
    menuStore,

    hideTitle,
    title,
    hideMessage,
    message,
    widgetBackgroundColor,
    forceVertical,
    borderRadius,
    disableLocationSearch,

    intl,
    history,

    setIsSearch,
    getWeeks,
    setPageNumber,
    resetFilters,
    onExperience,
    setLocationError,
    setDatesError,
    setPeriodError,
    setIsCollapse,

    setDate,
    setPeriod,
    setSelectLocation,
    setSelectLocationLabel,
    setSessionKey,
    setFilters,
    setIsWidget,
  } = props;

  const { account, accessToken } = loginStore;
  const { date, period } = weeksDatesStore;
  const { isSearch, weeks, isCollapse } = weeksStore;
  const { selectedLocation, selectedLocationLabel } = weeksLocationsStore;
  const { items } = menuStore;

  const datesError = weeksDatesStore?.errorDate;
  const periodError = weeksDatesStore?.errorPeriod;
  const locationError = weeksLocationsStore?.error;

  const hasPublicAccessToken = account?.hasPublicAccessToken;
  const isRSITemplate = account?.isRSITemplate;  
  const searchTitle = account?.carsSearchTitle;
  const searchMessage = account?.carsSearchMessage;
  const accessTokenDb = account?.accessTokenDb;
  const baseAddressSite = account?.baseAddressSite;
  const accountName = account?.name;

  
  const disableNewTabOpen = GetDisableNewTabOpen(account, items);

  const { styleAccount, styleMain, withImage } = useMemo(() => GetStyles(account as IAccount, null, borderRadius as string, Background, Background, isSearch, isExperiences as boolean, isWidget as boolean, widgetBackgroundColor as string, weeks?.length), [account, borderRadius, Background, isSearch, isExperiences, isWidget, widgetBackgroundColor, weeks?.length]);

  const locationParams =  useMemo(() => selectedLocation ? `${WEEKS_LOCATION_OBJECT_LABEL}=${Base64.encode(JSON.stringify(selectedLocation))}` : '', [selectedLocation]);
  const locationLabelParams = useMemo(() => UrlUtils.getUrl(WEEKS_LOCATION_OBJECT_LABEL, selectedLocationLabel), [selectedLocationLabel]);
  const datesParams = useMemo(() => UrlUtils.getUrl(WEEKS_DATES_LABEL, { date, period }), [date, period]);
  const values = useMemo(() => UrlUtils.getValues(), [location.search]);
  const searchTitleFinal = useMemo(() => isWidget && !isEmpty(title) ? title : searchTitle, [isWidget, title, searchTitle]);
  const searchMessageFinal = useMemo(() => isWidget && !isEmpty(message) ? message : searchMessage, [isWidget, message, searchMessage]);
  const hasPublicValidToken = useMemo(() => account?.type !== LoginType.Public || !hasPublicAccessToken || !isEmpty(accessToken), [account?.type, hasPublicAccessToken, accessToken]);

  const isAfterSearchLoad = (!isWidget && !isExperiences) && ((isSearch && !(!!weeksLocationsStore.error || !!weeksDatesStore.errorDate || !!weeksDatesStore.errorPeriod)) || !!weeks?.length);

  const hasLocationDateError = useMemo(() => !isEmpty(locationError) && !isEmpty(datesError), [locationError, datesError]);
  const hasLocationOrDateError = useMemo(() => ((!isEmpty(locationError) || !isEmpty(datesError)) && !isEmpty(periodError)), [locationError, datesError, periodError]);
  const locationErrorMessage = useMemo(() => !isEmpty(locationError) ? locationError : '', [locationError]);
  const dateErrorMessage = useMemo(() => !isEmpty(datesError) ? datesError : '', [datesError]);
  const periodErrorMessage = useMemo(() => !isEmpty(periodError) ? periodError : '', [periodError]);
  const hasLocationDateErrorMessage = useMemo(() => hasLocationDateError ? ' / ' : '', [hasLocationDateError]);
  const hasLocationOrDateErrorMessage = useMemo(() => hasLocationOrDateError ? ' / ' : '', [hasLocationOrDateError]);

  const errorMessage = `${locationErrorMessage}${hasLocationDateErrorMessage}${dateErrorMessage}${hasLocationOrDateErrorMessage}${periodErrorMessage}`

  useEffect(() => {
    if (isWidget) {
      setIsWidget(isWidget);
    }

    if (values[WEEKS_FILTERS_LABEL]) {
      setFilters(values[WEEKS_FILTERS_LABEL] as IWeeksFiltersState);
    }

    const locationValue = values[WEEKS_LOCATION_OBJECT_LABEL];
    const isLocation = locationValue && Object.keys(locationValue).length > 0;
    if (isLocation) {
      setSelectLocation(locationValue as ICondoLocation);
    }

    if (values[WEEKS_LOCATION_LABEL] && isLocation) {
      setSelectLocationLabel(values[WEEKS_LOCATION_LABEL] as string);
    }

    if (values[WEEKS_DATES_LABEL]) {
      const dates: { date: string; period: string; } = values[WEEKS_DATES_LABEL] as { date: string; period: string; };
      const today = new Date();
      today.setHours(zero, zero, zero, zero);

      setDate(dates?.date);
      setPeriod(dates?.period);
      setPeriod(dates?.period);

      if (dates?.date && isBefore(getDateCheckInOut(dates?.date, 'MM/DD/yyyy'), today)) {
        const date: any = undefined;
        setDate(date);
      }
    }
    
    const session: ISessionKey = values[WEEKS_SESSION_KEY_LABEL] as ISessionKey;

    if (session && session.expireDate && getTimeout(session.expireDate) > zero) {
      setSessionKey(session);
    }

    setTimeout(() => {
      if (!weeksStore.weeks && session) {
        onSearch();
      } else {
        const isDisableHomes = account?.isDisableHomes;
        const disableHomesRedirect = account?.disableHomesRedirect;
    
        if (isDisableHomes && !isWidget) {
          if (isEmpty(disableHomesRedirect)) {
            history.push(Routes.NotAuthorized);
          } else {
            window.location.replace(disableHomesRedirect as string);
          }
        }
      }
    }, 200);
  }, []);

  const onSearch = ({ isInit = true } = {}) => {
    const { isDatesValid, isLocationValid, isPeriodValid, isValid } = isWeeksDataValid(weeksLocationsStore, weeksDatesStore);

    if (!isLocationValid) {
      setLocationError(intl.formatMessage({ id: 'weeks.location.error' }));
    }

    if (!isDatesValid) {
      setDatesError(intl.formatMessage({ id: 'weeks.dates.error' }));
    }

    if (!isPeriodValid) {
      setPeriodError(intl.formatMessage({ id: 'weeks.period.error' }));
    }

    if (isValid) {
      if (isWidget) {
        const sessionParams = UrlUtils.getUrl(WEEKS_SESSION_KEY_LABEL, {
          value: v4(),
          isInvalid: true,
          expireDate: moment().format('yyyy-MM-DDTHH:mm:ss.SSSZ')
        } as ISessionKey);
        
        const hasPublicValidToken = loginStore.account?.type !== LoginType.Public || !hasPublicAccessToken || !isEmpty(accessTokenDb);

        const path = !isEmpty(baseAddressSite) ? baseAddressSite : import.meta.env.VITE_WIDGET_REDIRECT;
        const referenceNumberParam = !isEmpty(hotelsStore.referenceNumber) ? `${REFERENCE_NUMBER_LABEL}=${hotelsStore.referenceNumber}` : '';
        const agencyNumberParam = !isEmpty(hotelsStore.agencyNumber) ? `${AGENCY_NUMBER_LABEL}=${hotelsStore.agencyNumber}` : '';
        const tokenParam = hasPublicValidToken ? `${ACCESS_TOKEN_LABEL}=${accessTokenDb}` : '';

        const url = `${path}${accountName}${Routes.WeeksSearch}?${tokenParam}&${locationParams}&${locationLabelParams}&${datesParams}&${sessionParams}&${referenceNumberParam}&${agencyNumberParam}`;

        if (disableNewTabOpen) {
          window.location.replace(url);
        } else {
          window.open(url, '_blank');
        }
      } else {    
        if (selectedLocation) {
          setPageNumber();
          setIsSearch();
          setIsCollapse(true);
    
          if (!isInit) {
            resetFilters();
            UrlUtils.setUrl(WEEKS_FILTERS_LABEL, null);
          }
    
          if (isWidget) {
    
          } else if (isExperiences) {
            if (onExperience) {
              onExperience();
            }
          } else {
            getWeeks();

            const contentWrapper = document.querySelector('.condo-result-wrapper__content');
    
            if (contentWrapper) {
              contentWrapper.scroll(zero, zero);
              window.scrollTo(zero, zero);
            }
          }
        }  
      }
    }

  };

  const onToggle = () => setIsCollapse(!isCollapse);

  return (
    <div
      className={`condo-search weeks ${(isWidget || isExperiences) ? 'widget' : ''}  ${(!isRSITemplate && !weeks) ? 'travcoding-template-main' : ''} ${(isSearch && !(isWidget || isExperiences)) ? 'after-search' : ''} ${isCollapse ? 'toggle-condo-search-info' : ''}`}
      style={styleMain}
    >
      <div className={`search-wrapper__image ${withImage ? 'with-image' : ''} ${isWidget ? 'widget' : ''}`} style={styleAccount}></div>

      <div className={`condo-search__wrapper background-image ${(!isRSITemplate && !weeks) ? 'travcoding-template' : ''} ${(isWidget || isExperiences) ? 'widget' : ''}`}>
        {!isExperiences ? (
          <>
            {(!isWidget || (isWidget && !hideTitle)) ? (
              <h1 className={`condo-search__header secondary-font ${isWidget ? 'widget' : ''} ${(isWidget && hideMessage)? 'widget-hide' : ''}`} style={{ textAlign: 'center' }}>
                {isEmpty(searchTitleFinal) ? (<FormattedMessage id="weeks.header" />) : searchTitleFinal}
              </h1>) : null}

            {(!isWidget || (isWidget && !hideMessage)) ? (
              <div className={`condo-search__header-small ${(!isRSITemplate && !weeks) ? 'travcoding-template' : ''} ${isWidget ? 'widget' : ''} ${(isWidget && hideTitle) ? 'widget-hide' : ''}`} style={{ textAlign: 'center' }}>
                {isEmpty(searchMessageFinal) ? (<FormattedMessage id="weeks.header.small" />) : searchMessageFinal}
              </div>) : null}
          </>) : null}

        <div className={`condo-search__search-section ${(isWidget || isExperiences) ? 'widget' : ''}`}>
          {(hasPublicValidToken || isWidget) && (
            <>
              <div className={`condo-search__search-top ${forceVertical? 'vertical' : ''} ${(isWidget || isExperiences) ? 'widget' : ''}`}>
                <WeeksLocationPicker isAfterSearchLoad={isAfterSearchLoad} isExperiences={isExperiences} disable={disableLocationSearch} display />
                
                <WeeksDatePicker isAfterSearchLoad={isAfterSearchLoad} />

                <WeeksPeriodPicker isAfterSearchLoad={isAfterSearchLoad} />

                <BlueButton onClick={() => onSearch({ isInit: false })}>
                  {isSearch ? (<UpdateSvg />) : (<SearchSvg />)}
                  <FormattedMessage id="search" />
                </BlueButton>
              </div>

              {(locationError || datesError || periodError) ? (
                <div className={`condo-search__search-top ${forceVertical? 'vertical' : ''} ${(isWidget || isExperiences) ? 'widget' : ''}`} style={{ marginTop: isAfterSearchLoad ? '0px' : '10px' }}>
                  <div className="locations-picker__error">
                    {errorMessage}
                  </div>
                </div>) : null}

            </>)}
          {(!hasPublicValidToken && !isWidget) && (<AccessToken />)}          
        </div>

        {isCollapse && <WeeksSearchInfo onToggle={onToggle} />}

        {isSearch && !isCollapse && !isWidget && (
          <Button
            className="condo-search__toggle-condo-search-info"
            onClick={onToggle}
            type="text"
          >
            <FormattedMessage id="cancel" />
          </Button>)}
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    hotelsStore: state.hotelsStore,

    weeksLocationsStore: state.weeksLocationsStore,
    weeksDatesStore: state.weeksDatesStore,
    weeksStore: state.weeksStore,

    loginStore: state.loginStore,
    menuStore: state.navigationMenuStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setLocationError: weeksLocationsActions.setError,
  setSelectLocationLabel: weeksLocationsActions.setSelectLocationLabel,
  setSelectLocation: weeksLocationsActions.setSelectLocation,

  setDatesError: weeksDatesActions.setErrorDate,
  setPeriodError: weeksDatesActions.setErrorPeriod,
  setDate: weeksDatesActions.setDateSelected,
  setPeriod: weeksDatesActions.setPeriodSelected,
  
  setSessionKey: weeksActions.setWeeksSessionKey,
  setBounds: weeksActions.setBounds,
  setIsWidget: weeksActions.setIsWidget,

  setIsCollapse: weeksActions.setIsCollapse,

  setFilters: weeksFiltersActions.setFilters,
  resetFilters: weeksFiltersActions.resetFilters,

  setIsSearch: () => weeksActions.setIsSearch(true),
  setPageNumber: () => weeksActions.setPageNumber(DEFAULT_PAGE_NUMBER),
  getWeeks: () => GetWeeks(SearchTypeEnum.NewSearch),
};

export const WeeksSearch = connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(WeeksSearchComponent)));
