import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Routes } from '@share/constants';
import { BenefitsEnum } from '@share/common-types';
import { IBenefitsState } from '@share/store/slices';
import { getLocalURI, isExternalUrl, RootState } from '@share/utils';
import { getBenefitInfo } from '@share/utils';
import { SkeletonGetawaysCondo } from '@components';

import './style.scss';
import { ILoginState } from '@share/store/slices';
import { get } from 'lodash';

interface IMapStateToProps{
  benefitsStore: IBenefitsState;
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {
  isInternal: boolean;

  isHome?: boolean;
}

const ZERO = 0;

export class GetawayCondoComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { isHome, benefitsStore } = this.props;
    const { loginStore } = this.props;
    const { account } = loginStore;

    const fromHome = benefitsStore.benefits?.fromHome;

    return (
      <div className={`getaway__condo ${isHome ? 'home-page' : ''}`}>
        <div className={`getaway__container ${isHome ? 'home-page' : ''}`}>
          {!isHome ? (
            <Link className="getaway__condo__back-link" to={`/${account?.name}${(fromHome || isHome) ? Routes.Search : Routes.Getaway}`}>
              <FormattedMessage id={(fromHome || isHome) ? 'confirmation.go.back_home' : 'getaways.back'} />
            </Link>) : null}

          <h1 className={`getaway__condo__title secondary-font ${isHome ? 'home-page' : ''}`}>
            <FormattedMessage id="getaways.condo.title" />
          </h1>
          <p className="getaway__condo__subtitle secondary-font">
            <FormattedMessage id="getaways.condo.subtitle" />
          </p>
          {benefitsStore.benefits?.items ? (
            <div className={`getaway__condo__wrapper ${isHome ? 'home-page' : ''}`}>
              {(get(getBenefitInfo(benefitsStore.benefits, BenefitsEnum.Getaways), `[${ZERO}].childItems[${ZERO}].childItems`) as any[])?.map(({ imageUrl, title, description, url }, index) => (
                <Link
                  key={`${index}-${title}`}
                  to={isExternalUrl(url) && !this.props.isInternal  ? url : !this.props.isInternal?  `/${account?.name}${getLocalURI(url)}` : `/${account?.name}${Routes.GetawayCondos}/${url.split('/').pop() }` }
                  className="getaway__condo__item"
                >
                  <img className="getaway__condo__item_img" src={imageUrl} alt={title} />
                  <div className="getaway__condo__item_info">
                    <p className="getaway__condo__item_info_title"><FormattedMessage id={title} /></p>
                    <p className="getaway__condo__item_info_text"><FormattedMessage id={description} /></p>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <SkeletonGetawaysCondo />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    benefitsStore: state.benefitsStore,
    loginStore: state.loginStore
  };
};

export const GetawayCondo = connect(mapStateToProps)(GetawayCondoComponent);
