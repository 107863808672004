import React from 'react';

export const BookingConfirmedSvg: React.FunctionComponent = () => (
  <svg width="129" height="76" viewBox="0 0 129 76" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="54.0859" y="1.7666" width="33.0589" height="66.7886" rx="1.72481" fill="#CFDFFF" />
    <rect x="82.2583" y="1.7666" width="4.88697" height="66.7886" rx="1.72481" fill="#B7CBF3" />
    <path d="M54.0376 16.7629V30.7051M54.0376 11.0135C54.0376 9.79974 54.0376 6.75896 54.0376 4.30589C54.0376 1.85282 56.2415 1.07985 57.3435 1C65.2968 1 81.9125 1 84.7489 1C87.5852 1 88.1346 3.20393 88.0548 4.30589V30.7051M54.0376 30.7051V65.2014C54.0376 66.3034 54.6988 68.5073 57.3435 68.5073C59.9882 68.5073 76.7157 68.5073 84.7489 68.5073C85.8508 68.5552 88.0548 67.9611 88.0548 65.2014V30.7051M54.0376 30.7051H88.0548" stroke="black" strokeWidth="1.82064" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M73.1545 16.6195C74.3034 15.9223 75.071 14.6595 75.071 13.2174C75.071 11.0212 73.2906 9.24078 71.0943 9.24078C68.8981 9.24078 67.1177 11.0212 67.1177 13.2174C67.1177 14.6595 67.8852 15.9222 69.0341 16.6195V22.129C69.0341 23.2668 69.9565 24.1892 71.0943 24.1892C72.2321 24.1892 73.1545 23.2668 73.1545 22.129V16.6195Z" fill="#454545" />
    <path d="M73.1545 16.6195L72.6822 15.8413L72.2442 16.1071V16.6195H73.1545ZM69.0341 16.6195H69.9444V16.1071L69.5064 15.8413L69.0341 16.6195ZM74.1607 13.2174C74.1607 14.3283 73.5705 15.3022 72.6822 15.8413L73.6267 17.3978C75.0363 16.5424 75.9813 14.9907 75.9813 13.2174H74.1607ZM71.0943 10.1511C72.7878 10.1511 74.1607 11.5239 74.1607 13.2174H75.9813C75.9813 10.5184 73.7933 8.33047 71.0943 8.33047V10.1511ZM68.028 13.2174C68.028 11.5239 69.4008 10.1511 71.0943 10.1511V8.33047C68.3953 8.33047 66.2074 10.5184 66.2074 13.2174H68.028ZM69.5064 15.8413C68.6181 15.3022 68.028 14.3283 68.028 13.2174H66.2074C66.2074 14.9906 67.1523 16.5423 68.5618 17.3977L69.5064 15.8413ZM69.9444 22.129V16.6195H68.1238V22.129H69.9444ZM71.0943 23.2788C70.4592 23.2788 69.9444 22.764 69.9444 22.129H68.1238C68.1238 23.7695 69.4537 25.0995 71.0943 25.0995V23.2788ZM72.2442 22.129C72.2442 22.764 71.7293 23.2788 71.0943 23.2788V25.0995C72.7349 25.0995 74.0648 23.7695 74.0648 22.129H72.2442ZM72.2442 16.6195V22.129H74.0648V16.6195H72.2442Z" fill="black" />
    <path d="M75.6343 52.3672C75.0248 51.7577 74.3012 51.2742 73.5048 50.9443C72.7084 50.6145 71.8549 50.4447 70.9929 50.4447C70.1309 50.4447 69.2774 50.6145 68.481 50.9443C67.6847 51.2742 66.9611 51.7577 66.3516 52.3672" stroke="black" strokeWidth="1.82064" strokeLinecap="round" />
    <path d="M78.533 47.7676C77.5381 46.7727 76.3569 45.9835 75.057 45.4451C73.7571 44.9067 72.3639 44.6295 70.9569 44.6295C69.5499 44.6295 68.1567 44.9067 66.8568 45.4451C65.5569 45.9835 64.3758 46.7727 63.3809 47.7676" stroke="black" strokeWidth="1.82064" strokeLinecap="round" />
    <circle cx="71.0462" cy="55.9064" r="1.1497" fill="black" />
    <rect x="80.7246" y="53.7023" width="45.1435" height="29.6799" rx="3.92874" transform="rotate(-60 80.7246 53.7023)" fill="#9ACAFF" stroke="black" strokeWidth="1.82064" strokeLinecap="round" />
    <rect x="85.7036" y="56.577" width="45.1326" height="5.55773" transform="rotate(-60 85.7036 56.577)" fill="#0081FE" stroke="black" strokeWidth="1.82064" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M110.851 41.0001C109.797 40.3915 109.436 39.0436 110.045 37.9895C110.653 36.9353 112.001 36.5742 113.055 37.1828C114.11 37.7914 114.471 39.1393 113.862 40.1934C113.253 41.2475 111.906 41.6087 110.851 41.0001ZM110.55 42.864L106.879 49.2219L108.58 50.2041C109.016 50.4555 109.165 51.0122 108.913 51.4476C108.662 51.883 108.105 52.0322 107.67 51.7808L105.969 50.7987L105.324 51.9158C105.072 52.3512 104.516 52.5004 104.08 52.249C103.645 51.9976 103.496 51.4409 103.747 51.0055L109.029 41.8575C107.836 40.5978 107.556 38.658 108.468 37.0791C109.579 35.1542 112.041 34.4947 113.966 35.606C115.891 36.7174 116.55 39.1788 115.439 41.1037C114.442 42.8307 112.358 43.5392 110.55 42.864Z" fill="black" />
    <rect x="31.7588" y="21.7936" width="4.5995" height="7.85748" fill="#D9D9D9" stroke="black" strokeWidth="1.82064" strokeLinecap="round" />
    <g filter="url(#filter0_i_774_408)">
      <path d="M58.9723 50.6842C58.9723 44.0385 56.3323 37.665 51.6331 32.9657C46.9339 28.2665 40.5604 25.6265 33.9146 25.6265C27.2689 25.6265 20.8954 28.2665 16.1962 32.9657C11.4969 37.665 8.85693 44.0385 8.85693 50.6842L33.9146 50.6842H58.9723Z" fill="#FFE07D" />
    </g>
    <path d="M58.9723 50.6842C58.9723 44.0385 56.3323 37.665 51.6331 32.9657C46.9339 28.2665 40.5604 25.6265 33.9146 25.6265C27.2689 25.6265 20.8954 28.2665 16.1962 32.9657C11.4969 37.665 8.85693 44.0385 8.85693 50.6842L33.9146 50.6842H58.9723Z" stroke="black" strokeWidth="1.82064" strokeLinecap="round" />
    <g filter="url(#filter1_i_774_408)">
      <rect x="27.3511" y="16.0442" width="13.4152" height="5.74938" rx="2.87469" fill="#FFE07D" />
    </g>
    <rect x="27.3511" y="16.0442" width="13.4152" height="5.74938" rx="2.87469" stroke="black" strokeWidth="1.82064" strokeLinecap="round" />
    <g filter="url(#filter2_i_774_408)">
      <rect width="66.1178" height="8.04913" rx="0.95823" transform="matrix(1 0 0 -1 1 68.4594)" fill="#B78B7A" />
    </g>
    <rect width="66.1178" height="8.04913" rx="0.95823" transform="matrix(1 0 0 -1 1 68.4594)" stroke="black" strokeWidth="1.82064" strokeLinecap="round" />
    <g filter="url(#filter3_i_774_408)">
      <rect x="8.47412" y="55.6191" width="51.1695" height="4.79115" rx="0.95823" fill="#FFE07D" />
    </g>
    <rect x="8.47412" y="55.6191" width="51.1695" height="4.79115" rx="0.95823" stroke="black" strokeWidth="1.82064" strokeLinecap="round" />
    <rect width="40.4373" height="4.88697" rx="0.95823" transform="matrix(1 0 0 -1 13.8403 55.6191)" stroke="black" strokeWidth="1.82064" strokeLinecap="round" />
    <defs>
      <filter id="filter0_i_774_408" x="7.94678" y="24.7162" width="51.936" height="26.8783" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="-3.83292" dy="5.17444" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.815686 0 0 0 0 0.392157 0 0 0 1 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_774_408" />
      </filter>
      <filter id="filter1_i_774_408" x="26.4409" y="15.1339" width="15.2358" height="7.57001" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="-2.87469" dy="2.87469" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.815686 0 0 0 0 0.392157 0 0 0 1 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_774_408" />
      </filter>
      <filter id="filter2_i_774_408" x="0.0898438" y="59.4999" width="67.9385" height="9.86975" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="-2.87469" dy="2.87469" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.666667 0 0 0 0 0.478431 0 0 0 0 0.388235 0 0 0 1 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_774_408" />
      </filter>
      <filter id="filter3_i_774_408" x="7.56396" y="54.7088" width="52.9897" height="6.61179" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="-2.87469" dy="2.87469" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.815686 0 0 0 0 0.392157 0 0 0 1 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_774_408" />
      </filter>
    </defs>
  </svg>
);
