import React from 'react';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import uniq from 'lodash/uniq';

import { FormattedMessage, IntlShape } from 'react-intl';
import { DATE_FORMAT } from '@share/constants';

const zero = 0;
const one = 1;

export const getStayForText = (): React.ReactNode => {
  return (
    <span className="condo-flexible-date-picker__label-bold">
      <FormattedMessage id="anytime" />
    </span>
  );
};

export const getSelectedMonthText = (selectedMonths: string[], intl: IntlShape): string => {
  const parsedDates: Date[] = selectedMonths.map((date) => {
    return parse(date, DATE_FORMAT, new Date());
  });

  if (parsedDates.length === one) {
    return format(parsedDates[zero], 'MMMM yyyy');
  } else {
    const components: string[] = [];
    const groupedByYear: { [key: string]: Date[] } = {};
    const years = uniq(parsedDates.map((date: Date) => date.getFullYear()));
    years.sort();
    years.forEach((year: number) => (groupedByYear[year] = []));
    parsedDates.forEach((date: Date) => {
      const year = date.getFullYear();
      groupedByYear[year].push(date);
    });
    years.forEach((year: number) => {
      const datesByYear = groupedByYear[year];

      if (datesByYear.length === one) {
        components.push(format(datesByYear[zero], 'MMMM yyyy'));
      } else {
        const months: string[] = [];

        datesByYear.forEach((date: Date) => {
          months.push(format(date, 'MMMM'));
        });

        components.push(`${months.join(', ')} ${year}`);
      }
    });

    return components.join(` ${intl.formatMessage({ id: 'and' })} `);
  }
};

export const getEndDateNextMonth = (): Date => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const nextMonth = currentMonth + 2;
  const nextMonthFirstDay = new Date(currentDate.getFullYear(), nextMonth, 1);
  const oneDay = 1 * 24 * 3600 * 1000;
  // @ts-ignore
  return new Date(nextMonthFirstDay - oneDay);
};
