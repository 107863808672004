
import React, { useState } from 'react';
import IframeResizer from 'iframe-resizer-react';

import { FormattedMessage } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocketchat } from '@fortawesome/free-brands-svg-icons';

import { Modal } from 'antd';

import { RootState } from '@share/utils';
import { connect } from 'react-redux';
import { IMenuState } from '@share/store/slices';

import './style.scss';

interface IMapStateToProps {
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps {}

const ORG_ID: number[] = [221];

function ContactUsChatComponent(props: IProps) {
  const { menuStore } = props;

  const items = menuStore?.items;
  const orgId = items?.organizationId;

  if (!ORG_ID.includes(orgId)) {
    return null;
  }
  const [visible, setVisible] = useState(false);

  return (
    <div className="contact-us-chat">
      <div className="contact-us-chat__wrapper" onClick={() => setVisible(true)}>
        <FormattedMessage id="contact.us.chat" /><FontAwesomeIcon icon={faRocketchat} />
      </div>

      <Modal
        className="modal-contact-us-chat"
        visible={visible}
        maskClosable={false}
        footer={null}
        onCancel={() => setVisible(false)}
        wrapClassName="modal-contact-us-chat__wrapper"
      >
        <IframeResizer
          heightCalculationMethod="lowestElement"
          src={'https://rsichat.streamlit.app/?embed=true'}
          style={{ width: '1px', minWidth: '100%'}}
          sandbox="allow-scripts allow-forms allow-same-origin allow-downloads allow-popups"
          key={new Date().getTime()}
        />
      </Modal>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
  };
};

export const ContactUsChat = connect(mapStateToProps)(ContactUsChatComponent);
