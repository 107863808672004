import React from 'react';

export const VisaCardSvg: React.FunctionComponent = () => (
  <svg width="42" height="29" viewBox="0 0 42 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="41" height="28" rx="3.5" fill="white" stroke="#C5C5C5" />
    <path d="M18.6478 19.8888H15.8516L17.6005 9.73828H20.3966L18.6478 19.8888Z" fill="#15195A" />
    <path
      d="M28.7842 9.98684C28.2327 9.78145 27.3579 9.55469 26.2762 9.55469C23.5148 9.55469 21.5703 10.9368 21.5583 12.9128C21.5354 14.3707 22.9506 15.1805 24.009 15.6666C25.0909 16.1633 25.4586 16.4876 25.4586 16.9303C25.4476 17.6103 24.5844 17.9238 23.7794 17.9238C22.663 17.9238 22.0648 17.7622 21.1557 17.3839L20.7875 17.2217L20.3962 19.5003C21.0521 19.7807 22.2604 20.0296 23.5148 20.0405C26.4488 20.0405 28.359 18.6797 28.3816 16.574C28.3928 15.4185 27.6455 14.5331 26.0345 13.8096C25.0564 13.3451 24.4575 13.032 24.4575 12.5568C24.469 12.1248 24.9641 11.6823 26.0681 11.6823C26.9771 11.6606 27.645 11.8657 28.151 12.0709L28.4039 12.1787L28.7842 9.98684Z"
      fill="#15195A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.7898 9.73828H35.9527L38.2085 19.8886H35.6195C35.6195 19.8886 35.3661 18.7224 35.2858 18.366H31.6957C31.5919 18.6358 31.1088 19.8886 31.1088 19.8886H28.1748L32.3283 10.5805C32.616 9.9217 33.1228 9.73828 33.7898 9.73828ZM33.6175 13.4526C33.6175 13.4526 32.7314 15.7095 32.5011 16.2926H34.8254C34.7104 15.7851 34.1808 13.3554 34.1808 13.3554L33.9854 12.4808C33.9031 12.706 33.7841 13.0157 33.7039 13.2245C33.6494 13.3661 33.6128 13.4614 33.6175 13.4526Z"
      fill="#15195A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.73572 9.73828H8.23461C8.84442 9.75967 9.33923 9.94323 9.50024 10.5915L10.4779 15.2549C10.478 15.2553 10.4782 15.2557 10.4783 15.2562L10.7775 16.66L13.516 9.73828H16.4728L12.0776 19.878H9.12061L6.62832 11.0582C5.7684 10.5863 4.78698 10.2067 3.6897 9.94338L3.73572 9.73828Z"
      fill="#15195A"
    />
  </svg>
);
