import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Urls } from '@share/constants';
import { AppThunk, axiosInstance, getHeaders } from '@share/utils';
import { IPersonalDetails, IUpdatePersonalDetails } from '@common-types';

export interface IPersonalDetailsState {
  personalDetails: IPersonalDetails;
  loading: boolean;
  error: string;
  updateDetailsLoading: boolean;
  updateDetailsError: string;
  updateSuccess: IUpdatePersonalDetails;
  updatePasswordLoading: boolean;
  updatePasswordError: string;
  updatePasswordSuccess: IUpdatePersonalDetails;
}

const initialState: IPersonalDetailsState = {
  personalDetails: null,
  loading: false,
  error: '',
  updateDetailsLoading: false,
  updateDetailsError: '',
  updateSuccess: null,
  updatePasswordLoading: false,
  updatePasswordError: '',
  updatePasswordSuccess: null,
};

const personalDetailsSlice = createSlice({
  name: 'personal-details',
  initialState,
  reducers: {
    setLoading: (state: IPersonalDetailsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: IPersonalDetailsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setUpdateDetailsLoading: (
      state: IPersonalDetailsState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.updateDetailsLoading = payload;
    },
    setUpdateDetailsError: (state: IPersonalDetailsState, { payload }: PayloadAction<string>) => {
      state.updateDetailsError = payload;
    },
    setUpdateSuccess: (
      state: IPersonalDetailsState,
      { payload }: PayloadAction<IUpdatePersonalDetails>,
    ) => {
      state.updateSuccess = payload;
    },
    setPersonalDetails: (
      state: IPersonalDetailsState,
      { payload }: PayloadAction<IPersonalDetails>,
    ) => {
      state.personalDetails = payload;
    },
    setUpdatePasswordLoading: (
      state: IPersonalDetailsState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.updatePasswordLoading = payload;
    },
    setUpdatePasswordError: (state: IPersonalDetailsState, { payload }: PayloadAction<string>) => {
      state.updatePasswordError = payload;
    },
    setUpdatePasswordSuccess: (
      state: IPersonalDetailsState,
      { payload }: PayloadAction<IUpdatePersonalDetails>,
    ) => {
      state.updatePasswordSuccess = payload;
    },
  },
});

export const personalDetailsActions = personalDetailsSlice.actions;

export const personalDetailsReducer = personalDetailsSlice.reducer;

export const getPersonalDetails = (): AppThunk => {
  return async (dispatch) => {
    dispatch(personalDetailsActions.setLoading(true));

    try {
      const res = await axiosInstance.get(`${Urls.CurrentUser}`, {
        ...getHeaders(),
      });

      dispatch(personalDetailsActions.setLoading(false));
      dispatch(personalDetailsActions.setPersonalDetails(res.data));
    } catch (error) {
      console.error(error);
      dispatch(personalDetailsActions.setError(error.toString()));
      dispatch(personalDetailsActions.setLoading(false));
    }
  };
};

export const updatePersonalDetails = (personalDetails: IPersonalDetails): AppThunk => {
  return async (dispatch) => {
    dispatch(personalDetailsActions.setUpdateDetailsLoading(true));

    try {
      const res = await axiosInstance.put(`${Urls.CurrentUser}`, personalDetails, {
        ...getHeaders(),
      });

      dispatch(personalDetailsActions.setUpdateDetailsLoading(false));
      dispatch(personalDetailsActions.setUpdateSuccess(res.data));
    } catch (error) {
      console.error(error);
      dispatch(personalDetailsActions.setUpdateDetailsError(error.toString()));
      dispatch(personalDetailsActions.setUpdateDetailsLoading(false));
    }
  };
};

export const updatePassword = (password: string): AppThunk => {
  return async (dispatch) => {
    dispatch(personalDetailsActions.setUpdatePasswordLoading(true));

    try {
      const res = await axiosInstance.put(
        `${Urls.CurrentUser}/password`,
        { password },
        {
          ...getHeaders(),
        },
      );

      dispatch(personalDetailsActions.setUpdatePasswordLoading(false));
      dispatch(personalDetailsActions.setUpdatePasswordSuccess(res.data));
    } catch (error) {
      console.error(error);
      dispatch(personalDetailsActions.setUpdatePasswordError(error.toString()));
      dispatch(personalDetailsActions.setUpdatePasswordLoading(false));
    }
  };
};
