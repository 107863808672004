
import { FormattedMessage } from 'react-intl';
import { TravelResources } from '@constants';
import { TravelItemLinkIcon } from '@assets';

import './style.scss';

export function ResourcesOtherResources() {
  return (
    <div className="other-resources-wrapper">
      <div className="other-resources-wrapper__wrapper">
        <h2 className="other-resources-wrapper__heading secondary-font">
          <FormattedMessage id="resources.travel.resources.heading" />
        </h2>
        <div className="other-resources-wrapper__items">
          {TravelResources.map(({ id, src, alt, heading, text, link }) => (
            <a
              key={id}
              className="other-resources-wrapper__item"
              href={link}
              target="__blank"
              rel="noreferrer"
            >
              <img className="other-resources-wrapper__image" src={src} alt={alt} />
              <div className="other-resources-wrapper__text-wrapper">
                <h3 className="other-resources-wrapper__subheading">
                  <FormattedMessage id={heading} />{' '}
                </h3>
                <p className="other-resources-wrapper__paragraph">
                  <FormattedMessage id={text} />
                </p>
              </div>

              <i className="other-resources-wrapper__icon">
                <TravelItemLinkIcon />
              </i>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
