import React from 'react';

export const CalendarSvg: React.FunctionComponent = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5 10.5H6V15H10.5V10.5Z" fill="#0D99D6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 0.5C8.05228 0.5 8.5 0.947715 8.5 1.5V3H15.5V1.5C15.5 0.947715 15.9477 0.5 16.5 0.5C17.0523 0.5 17.5 0.947715 17.5 1.5V3H18.75H20C20.5523 3 21 3.44772 21 4V5.25V9V18.75C21 19.9926 19.9926 21 18.75 21H5.25C4.00736 21 3 19.9926 3 18.75V9V5.25V4C3 3.44772 3.44772 3 4 3H5.25H6.5V1.5C6.5 0.947715 6.94772 0.5 7.5 0.5ZM5 9H19V18.75C19 18.8881 18.8881 19 18.75 19H5.25C5.11193 19 5 18.8881 5 18.75V9Z"
      fill="#0D99D6"
    />
  </svg>
);
