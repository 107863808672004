import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMemberStories } from '@common-types';
import { externalAPI } from '@api';
import { mapExternalData } from '@store/slices';
import { AppThunk } from '@share/utils';

export interface IMemberStoriesState {
  memberStories: IMemberStories[];
  loading: boolean;
  error: string;
  activeStoryId: number;
}

const initialState: IMemberStoriesState = {
  activeStoryId: 0,
  memberStories: [],
  loading: false,
  error: '',
};

const memberStoriesSlice = createSlice({
  name: 'memberStories',
  initialState,
  reducers: {
    setLoading: (state: IMemberStoriesState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setActiveStoryById: (state: IMemberStoriesState, { payload }: PayloadAction<number>) => {
      state.activeStoryId = payload;
    },
    setError: (state: IMemberStoriesState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setMemberStories: (
      state: IMemberStoriesState,
      { payload }: PayloadAction<IMemberStories[]>,
    ) => {
      state.memberStories = payload;
    },
  },
});

export const memberStoriesActions = memberStoriesSlice.actions;

export const memberStoriesReducer = memberStoriesSlice.reducer;

export const fetchMemberStories = (): AppThunk => {
  return async (dispatch) => {
    dispatch(memberStoriesActions.setLoading(true));

    try {
      const { data } = await externalAPI.getMemberStories();

      dispatch(memberStoriesActions.setLoading(false));

      const memberStories = mapExternalData(data);
      dispatch(memberStoriesActions.setMemberStories(memberStories));
    } catch (error) {
      console.error(error);
      dispatch(memberStoriesActions.setError(error.toString()));
      dispatch(memberStoriesActions.setLoading(false));
    }
  };
};
