
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button } from 'antd';

import { ILoginState } from '@share/store/slices';
import { RootState, Toaster } from '@share/utils';
import { BlueButton } from '@share/components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faSearch, faSignIn } from '@fortawesome/free-solid-svg-icons';

import Enjoy from '@share/assets/images/enjoy.jpg';
import VIP from '@share/assets/images/vip.jpg';
import Office3 from '@assets/images/concierge-team-travel/team3.jpg';
import Membership from '@assets/images/list-clickable-cards/membership.png';
import Family from '@assets/images/family.jpg';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps {}


function DashboardFamilyFriendsComponent(props: IProps) {
  const { loginStore, intl } = props;
  const { user } = loginStore;

  const friendsandfamily = user?.childAccountName ?? '';

  const URL = `${import.meta.env.VITE_LEVARTI_REDIRECT}${friendsandfamily}/search`;

  const handleShare = () => {
		navigator.clipboard.writeText(URL);
    Toaster.success(intl.formatMessage({ id: 'sidekick.rewards.clipboard.message' }));
  }
  
  const handleOpen = () => {
    window.open(URL, '_blank');
  }

  return (
    <div className="dashboard-site-wrapper family-friends">

      <div className="dashboard-site-wrapper__header" style={{ backgroundImage: `linear-gradient(rgba(32, 39, 77, 0.3), rgba(32, 39, 77, 0.3)), url(${Family})` }}>
        <div className="dashboard-site-wrapper__title">
          <FormattedMessage id="dashboard.family.title" />
        </div>
      </div>

      <div className="dashboard-site-wrapper__message">
        <FormattedMessage id="dashboard.family.message" />
      </div>

      <div className="dashboard-site-wrapper__subtitle" style={{ marginTop: '30px' }}>
        <FormattedMessage id="dashboard.family.why" />
      </div>

      <div className="search-benefits__wrapper">
        <div className="search-benefits-item__container">
          <div className="search-benefits-item">
            <div className="image">
              <img src={VIP} alt="" />
            </div>
            <p className="title"><FormattedMessage id="dashboard.exclusive" /></p>
            <p className="message"><FormattedMessage id="dashboard.exclusive.message" /></p>
          </div>
        </div>
        <div className="search-benefits-item__container">
          <div className="search-benefits-item">
            <div className="image">
              <img src={Office3} alt="" />
            </div>
            <p className="title"><FormattedMessage id="dashboard.personalized" /></p>
            <p className="message"><FormattedMessage id="dashboard.personalized.message" /></p>
          </div>
        </div>
        <div className="search-benefits-item__container">
          <div className="search-benefits-item">
            <div className="image">
              <img src={Membership} alt="" />
            </div>
            <p className="title"><FormattedMessage id="dashboard.wide" /></p>
            <p className="message"><FormattedMessage id="dashboard.wide.message" /></p>
          </div>
        </div>
        <div className="search-benefits-item__container">
          <div className="search-benefits-item">
            <div className="image">
              <img src={Enjoy} alt="" />
            </div>
            <p className="title"><FormattedMessage id="dashboard.simple" /></p>
            <p className="message"><FormattedMessage id="dashboard.simple.message" /></p>
          </div>
        </div>
      </div>

      <div className="dashboard-site-wrapper__subtitle" style={{ marginTop: '20px' }}>
        <FormattedMessage id="dashboard.family.works" />
      </div>

      <div className="dashboard-site-wrapper__how-works">
        <div className="dashboard-site-wrapper__how-works-item">
          <FontAwesomeIcon icon={faSignIn} />
          <label className="title"><FormattedMessage id="dashboard.login" /></label>
          <label className="message"><FormattedMessage id="dashboard.login.message" /></label>
        </div>
        <div className="dashboard-site-wrapper__how-works-item">
          <FontAwesomeIcon icon={faSearch} />
          <label className="title"><FormattedMessage id="dashboard.explore" /></label>
          <label className="message"><FormattedMessage id="dashboard.explore.message" /></label>
        </div>
        <div className="dashboard-site-wrapper__how-works-item">
          <FontAwesomeIcon icon={faCalendarCheck} />
          <label className="title"><FormattedMessage id="dashboard.book" /></label>
          <label className="message"><FormattedMessage id="dashboard.book.message" /></label>
        </div>
      </div>

      <div className="dashboard-site-wrapper__contact">
        <FormattedMessage id="new.url" />: <strong className="link">{URL}</strong>
        <div className="dashboard-site-wrapper__contact-buttons">
          <Button className="white-button" onClick={handleOpen}>
            <FormattedMessage id="open" />
          </Button>
          <BlueButton style={{ marginLeft: '12px' }} onClick={handleShare}><FormattedMessage id="results.hotel.card.compare.modal.share" /></BlueButton>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

export const DashboardFamilyFriends = connect(mapStateToProps)(injectIntl(DashboardFamilyFriendsComponent));
