import React, { RefObject } from 'react';
import { Form, Input } from 'antd';
import { connect } from 'react-redux';
import { FormInstance } from 'antd/lib/form';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RootState } from '@share/utils';
import { setCondoGuest, ICondoReviewBookState } from '@store/slices';
import { ICondoGuestBooking } from '@common-types';
import { updateCondoGuest } from '@utils';
import { mailFormat } from '@share/utils';
import './style.scss';

interface IMapStateToProps {
  condoReviewBookStore: ICondoReviewBookState;
}

interface IMapDispatchToProps {
  setCondoGuest: (guest: ICondoGuestBooking) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  formRef: RefObject<FormInstance>;
  isSomeoneElse: boolean;
}

const MAX_LENGTH = 100;

class CondoConfirmationEmailComponent extends React.Component<IProps> {
  validateEmail = (e: { target: HTMLInputElement }) => {
    const { guest } = this.props.condoReviewBookStore;
    this.props.setCondoGuest(
      updateCondoGuest(guest, { email: e.target.value }) as ICondoGuestBooking,
    );
  };

  validateAdditionalEmail = (e: { target: HTMLInputElement }) => {
    const { guest } = this.props.condoReviewBookStore;
    this.props.setCondoGuest(
      updateCondoGuest(guest, { additionalEmail: e.target.value }) as ICondoGuestBooking,
    );
  };

  onFocus = (e: { target: HTMLInputElement }) => {
    this.props.formRef.current?.setFieldsValue({ email: e.target.value });
  };

  componentDidUpdate() {
    if (this.props.condoReviewBookStore.errorsField && this.props.formRef.current) {
      this.props.formRef.current.validateFields().then();
    }
  }

  render(): React.ReactNode {
    const { intl, isSomeoneElse, condoReviewBookStore } = this.props;
    const { guest, loadingBooking } = condoReviewBookStore;
    const { email, additionalEmail } = guest;

    return (
      <div className="condo-email">
        <div className="condo-email__header">
          <h4 className="condo-email__title secondary-font">
            <FormattedMessage id="confirmation.email" />
          </h4>
          <p className="condo-email__description">
            <FormattedMessage id="enter.email.address" />
          </p>
        </div>
        <div className="condo-email__rule">
          <Form.Item
            name="email"
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                pattern: new RegExp(mailFormat),
                message: intl.formatMessage({ id: 'error.message.email.validation' }),
              },
            ]}
          >
            <div className="condo-email__name-input-wrapper">
              <span className="condo-email__input-label">
                <FormattedMessage id="email" />
              </span>
              <div className="condo-email__input">
                <Input
                  placeholder={intl.formatMessage({ id: 'email.address' })}
                  value={email}
                  maxLength={MAX_LENGTH}
                  onChange={this.validateEmail}
                  onFocus={this.onFocus}
                  disabled={loadingBooking}
                />
              </div>
            </div>
          </Form.Item>
          {isSomeoneElse && (
            <Form.Item
              name="additionalEmail"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  pattern: new RegExp(mailFormat),
                  message: intl.formatMessage({ id: 'error.message.email.validation' }),
                },
              ]}
            >
              <div className="condo-email__name-input-wrapper">
                <span className="condo-email__input-label">
                  <FormattedMessage id="additional.email" />
                </span>
                <div className="condo-email__input">
                  <Input
                    placeholder={intl.formatMessage({ id: 'additional.email.address' })}
                    value={additionalEmail}
                    maxLength={MAX_LENGTH}
                    onChange={this.validateAdditionalEmail}
                    onFocus={this.onFocus}
                    disabled={loadingBooking}
                  />
                </div>
              </div>
            </Form.Item>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condoReviewBookStore: state.condoReviewBookStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = { setCondoGuest };

export const CondoConfirmationEmail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CondoConfirmationEmailComponent));
