import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { BlueButton } from '@share/components';
import { Routes } from '@share/constants';
import { ErrorAlertSvg } from '@assets';
import './style.scss';
import { connect } from 'react-redux';
import { getAccountUsernameFromPath } from '@share/utils';

interface IProps extends RouteComponentProps {}

class ErrorRequestCancelComponent extends React.Component<IProps> {
  reloadPage = (): void => {
    location.reload();
  };

  render(): React.ReactNode {
    const accountName = getAccountUsernameFromPath(this.props.history);
    const url = `/${accountName}${Routes.Search}${location.search}`;

    return (
      <div className="error-request-cancel">
        <div>
          <ErrorAlertSvg />
          <p className="error-request-cancel__title secondary-font">
            <FormattedMessage id="gone.wrong" />
          </p>
          <p className="error-request-cancel__description">
            <FormattedMessage id="we.are.working.on.the.problem" />
            {', '}
            <span className="error-request-cancel__link-text" onClick={this.reloadPage}>
              <FormattedMessage id="click.here" />
            </span>{' '}
            <FormattedMessage id="refresh.page" />.
          </p>
        </div>
        <div className="error-request-cancel__footer">
          <div className="error-request-cancel__btn-wrapper">
            <Link to={url}>
              <BlueButton>
                <FormattedMessage id="search.hotel" />
              </BlueButton>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export const ErrorRequestCancel = connect()(withRouter(ErrorRequestCancelComponent));
