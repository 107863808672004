import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { get } from 'lodash';

import { VoucherModal, CancelModal, DetailModal, InsuranceModal } from '@components';
import { IReservation, IStatic } from '@share/common-types';
import { cancelBooking, voucherBooking } from '@share/store/slices';
import { getInsuranceQuoteByReservation, addInsuranceToReservation } from '@store/slices';
import { CallbackFunction } from '@share/utils';
import { ICardBooking } from '@common-types';

import './style.scss';

interface IMapDispatchToProps {
  cancelBooking: (rateKey: string, comment: string, callback: CallbackFunction) => void;
  voucherBooking: (bookingId: string, recepientEmail: string, additionalEmail: string, callback: CallbackFunction) => void;

  getInsuranceQuoteByReservation: (reservation: IReservation, staticData: IStatic) => void;
  addInsuranceToReservation: (reservation: IReservation, card: ICardBooking, callBack: () => void) => void;
}

interface IProps extends IMapDispatchToProps {
  reservation: IReservation;
  staticData: IStatic;
  visible: boolean;
  loading: boolean;
  error: string;
  onClose: () => void;
  onRefresh: () => void;
}

function ReservationActionComponent(props: IProps) {

  const [showMarkAsCanceledModal, setShowMarkAsCanceledModal] = useState(false);
  const [showVoucherModal, setShowVoucherModal] = useState(false);
  const [showProtectionModal, setShowProtectionModal] = useState(false);
  const [email, setEmail] = useState(get(props.reservation, 'passengerEmail', ''));
  const [emailAdditional, setEmailAdditional] = useState('');
  const [isAdditionalEmailAdded, setIsAdditionalEmailAdded] = useState(false);

  const { reservation, staticData, visible, loading, error, onClose, getInsuranceQuoteByReservation, cancelBooking, voucherBooking, addInsuranceToReservation, onRefresh } = props;

  useEffect(() => {
    if (props.reservation) {
      setEmail(get(props.reservation, 'passengerEmail', ''));
    }
  }, [props.reservation])

  const handleCancelBooking = (comment: string) => {
    const { bookingId } = reservation;

    cancelBooking(bookingId, comment, () => {setShowMarkAsCanceledModal(false), onClose()});
  }

  const setEmailModal = (emailParam: string) => {
    setEmail(emailParam);
  };

  const setEmailAdditionalModal = (emailAdditionalParam: string) => {
    setEmailAdditional(emailAdditionalParam);
  };

  const setIsAdditionalEmailAddedModal = (isAdditionalEmailAddedParam: boolean) => {
    setIsAdditionalEmailAdded(isAdditionalEmailAddedParam);
  };

  const handleVoucherBooking = (recepientEmail: string, additionalEmail: string) => {
    const { bookingId } = reservation;

    voucherBooking(bookingId, recepientEmail, additionalEmail, () => setShowVoucherModal(false));
  }

  const handleProtectBooking = (card: ICardBooking, callBack: () => void) => {
    const { reservation } = props;

    addInsuranceToReservation(reservation, card, () => {
      setShowProtectionModal(false);
      callBack();
      onRefresh();
      onClose();
    });
  }

  if (!reservation) {
    return null;
  }

  const handleOpenInsuranceModal = () => {
    getInsuranceQuoteByReservation(reservation, staticData);
    setShowProtectionModal(true);
  }
  
  const { refundable, cancellationPolicesText, bookingStatusEnum, bookingType } = reservation;

  return (
    <div className="reservation-action">
      <DetailModal
        visible={visible}
        reservation={reservation}
        staticData={staticData}
        bookingStatusEnum={bookingStatusEnum}
        showCancel={(refundable && bookingType !== 'condo')}
        onCancel={() => setShowMarkAsCanceledModal(true)}
        onVoucher={() => setShowVoucherModal(true)}
        onProtect={handleOpenInsuranceModal}
        onClose={onClose}
      />

      <VoucherModal
        visible={showVoucherModal}
        loading={loading}
        onCancel={() => setShowVoucherModal(false)}
        onVoucher={handleVoucherBooking}
        email={email}
        emailAdditional={emailAdditional}
        isAdditionalEmailAdded={isAdditionalEmailAdded}
        setEmailModal={setEmailModal}
        setEmailAdditionalModal={setEmailAdditionalModal}
        setIsAdditionalEmailAddedModal={setIsAdditionalEmailAddedModal}
      />

      <InsuranceModal
        visible={showProtectionModal}
        loading={loading}
        error={error}
        onCancel={() => setShowProtectionModal(false)}
        onProtect={handleProtectBooking}
        reservation={reservation}
        staticData={staticData}
      />

      {bookingType !== 'condo' ? (
        <CancelModal
          visible={showMarkAsCanceledModal}
          refundable={refundable}
          cancellationPolicesText={cancellationPolicesText}
          onClose={() => setShowMarkAsCanceledModal(false)}
          onCancel={(handleCancelBooking)}
        />) : null}
    </div>);
}

const mapDispatchToProps: IMapDispatchToProps = {
  cancelBooking,
  voucherBooking,
  getInsuranceQuoteByReservation,
  addInsuranceToReservation,
};

export const ReservationAction = connect(null, mapDispatchToProps)(ReservationActionComponent);
