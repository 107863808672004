import React from 'react';

import { scrollTop } from '@share/utils';
import { GetawayList } from '@components';

import './style.scss';
import { GetawaysLocationEnum } from '@share/common-types';

interface IProps {
  isInternal: boolean;
}

export class GetawayListPage extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);

    scrollTop();
  }

  render(): React.ReactNode {
    const loc = location.pathname.split('/');
    const gateway =
      loc[3] === GetawaysLocationEnum.Condo || loc[3] === GetawaysLocationEnum.Condos
        ? loc[4]
        : loc[3];
    return <GetawayList isInternal={this.props.isInternal} condoSubcategory={gateway} />;
  }
}
