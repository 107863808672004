import React from 'react';

import { CarsDetailsWrapper } from '@components';
import { Responsive } from '@share/utils';
import { getTimeout, UrlUtils, scrollTop, getAccountUsernameFromPath } from '@share/utils';
import { ISessionKey } from '@share/common-types';
import { CARS_SESSION_KEY_LABEL, DEALID_LABEL, QUOTE_LABEL } from '@share/constants';
import { Routes } from '@share/constants';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import './style.scss';

interface IProps extends RouteComponentProps {}

const zero = 0;

class CarsDetailPageComponent extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);

    scrollTop();

    const values = UrlUtils.getValues();
    const session: ISessionKey = values[CARS_SESSION_KEY_LABEL] as ISessionKey;

    const isDealOrQuote = (values[DEALID_LABEL] as string) || (values[QUOTE_LABEL] as string);

    if (!!isDealOrQuote && (session && session.expireDate && getTimeout(session.expireDate) <= zero) || !session) {
      const accountName = getAccountUsernameFromPath(this.props.history);
      //history.replaceState(null, null, `/${accountName}${Routes.CarsSearch}${location.search}`);
      //location.reload();
    }
  }

  render(): React.ReactNode {
    return (
      <div className="cars-details-page">
        <Responsive>
          <CarsDetailsWrapper />
        </Responsive>
      </div>
    );
  }
}

export const CarsDetailPage = connect()(withRouter(CarsDetailPageComponent));
