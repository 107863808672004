import React from 'react';

export const FilterSvg: React.FunctionComponent = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.99992 9.99984C5.92039 9.99984 6.66659 9.25365 6.66659 8.33317C6.66659 7.4127 5.92039 6.6665 4.99992 6.6665C4.07944 6.6665 3.33325 7.4127 3.33325 8.33317C3.33325 9.25365 4.07944 9.99984 4.99992 9.99984Z"
      stroke="#0D99D6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 3.3335V6.66683"
      stroke="#0D99D6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 10V16.6667"
      stroke="#0D99D6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99992 14.9998C10.9204 14.9998 11.6666 14.2536 11.6666 13.3332C11.6666 12.4127 10.9204 11.6665 9.99992 11.6665C9.07944 11.6665 8.33325 12.4127 8.33325 13.3332C8.33325 14.2536 9.07944 14.9998 9.99992 14.9998Z"
      stroke="#0D99D6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 3.3335V11.6668"
      stroke="#0D99D6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 15V16.6667"
      stroke="#0D99D6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9999 7.49984C15.9204 7.49984 16.6666 6.75365 16.6666 5.83317C16.6666 4.9127 15.9204 4.1665 14.9999 4.1665C14.0794 4.1665 13.3333 4.9127 13.3333 5.83317C13.3333 6.75365 14.0794 7.49984 14.9999 7.49984Z"
      stroke="#0D99D6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 3.3335V4.16683"
      stroke="#0D99D6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 7.5V16.6667"
      stroke="#0D99D6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
