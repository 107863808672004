import React from 'react';
import { connect } from 'react-redux';

import { SearchRsi, SearchTravcoding } from '@components';
import { ILoginState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { TRAVCODING_HOME_TEMPLATE } from '@share/common-types';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  isVacationRentals?: boolean;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {}

class SearchPageComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { loginStore, isVacationRentals } = this.props;
    const { account } = loginStore;
    
    const homePageTemplate = account?.homePageTemplate;
    const isRSITemplate = account?.isRSITemplate;

    return (
      <div className="search-page">
        {(!homePageTemplate || homePageTemplate === TRAVCODING_HOME_TEMPLATE) && (<SearchTravcoding isVacationRentals={isVacationRentals} />)}
        {isRSITemplate && (<SearchRsi isVacationRentals={isVacationRentals} />)}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

export const SearchPage = connect(mapStateToProps)(SearchPageComponent);
