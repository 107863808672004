
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { IClientCash, IWeek } from '@share/common-types';
import { IBounds, IGeoLocation } from '@share/common-types';
import { Routes } from '@share/constants';
import { ILoginState, IMenuState, IWeekState, weeksActions } from '@share/store/slices';
import { RootState, Responsive, getSelectedCurrency } from '@share/utils';
import { Map, WalletPriceType, getWalletPrices } from '@utils';

import { WeekHotelCard } from '../week-card';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
  weeksStore: IWeekState;
}

interface IMapDispatchToProps {
  setSelectedWeeksSearchClientCash: (clientCash: IClientCash) => void;
}

interface IProps extends IMapDispatchToProps, RouteComponentProps, IMapStateToProps {
  bounds?: IBounds;
  locations: {
    location: IGeoLocation;
    id: number;
    isExact?: boolean;
    isDisabled?: boolean;
  }[];
  icon?: string;
  mapOptions?: google.maps.MapOptions;
  weeks?: IWeek[];
  nights?: number;
  selectedWeek?: IWeek;
  disabled?: boolean;
  hasCompareSelected: boolean;

  setBounds: (bounds: IBounds) => void;
  selectWeek: (week: IWeek) => void;
}

const padding = 20;
const debounceResizeTime = 400;
const debounceTime = 1000;

class WeeksMapWrapperComponent extends React.Component<IProps> {
  mapRef: React.RefObject<HTMLDivElement> = React.createRef();
  map: google.maps.Map;

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (!isEqual(prevProps.bounds, this.props.bounds)) {
      this.fitMapToBounds();
    }

    if (!isEqual(prevProps.weeks, this.props.weeks)) {
      Map.removeAllMarkers();
      this.setMarkers();
    }
  }

  fitMapToBounds = () => {
    const bounds = this.map.getBounds();
    let mapBounds = null;

    if (bounds) {
      const sw = bounds.getSouthWest();
      const ne = bounds.getNorthEast();
      mapBounds = {
        northEast: {
          longitude: ne.lng(),
          latitude: ne.lat(),
        },
        southWest: {
          longitude: sw.lng(),
          latitude: sw.lat(),
        },
      };
    }

    if (!isEqual(this.props.bounds, mapBounds)) {
      const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds(
        Map.getGoogleLocation(this.props.bounds.southWest),
        Map.getGoogleLocation(this.props.bounds.northEast),
      );

      this.map.fitBounds(bounds, padding);
    }
  };

  selectWeek = (propertyId: number): void => {
    const { weeks = [] } = this.props;
    const selectedWeek = weeks.find((week: IWeek) => week.propertyId === propertyId) || null;

    this.props.selectWeek(selectedWeek);
  };

  setMarkers = (): void => {
    const { locations, icon, selectedWeek, loginStore } = this.props;
    const { account } = loginStore;
    const currency = getSelectedCurrency(account);

    Map.addMarkers(this.map, locations.map(l => {
      const newLocation = { ...l };
      if (l.location?.latitude || l.location?.longitude) {
        const latitude = l.location?.latitude ? parseFloat(l.location?.latitude?.toFixed(3)) : 0;
        const longitude = l.location?.longitude ? parseFloat(l.location?.longitude?.toFixed(3)) : 0;
        newLocation.location = {
          latitude,
          longitude
        }
      }

      return newLocation;
    }), icon ? icon : '', currency, selectedWeek?.propertyId, true, this.selectWeek);
  };

  componentDidMount(): void {
    const { mapOptions = {} } = this.props;

    if (this.mapRef && this.mapRef.current) {
      this.map = Map.initializeMap(this.mapRef.current, undefined, mapOptions);

      this.fitMapToBounds();
      this.setMarkers();

      this.map.addListener('click', () => {
        this.props.selectWeek(null);
        Map.refreshMarkers(null);
      });
    }

    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = debounce(() => {
    const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds(
      Map.getGoogleLocation(this.props.bounds.southWest),
      Map.getGoogleLocation(this.props.bounds.northEast),
    );

    this.map.fitBounds(bounds, padding);
  }, debounceResizeTime);

  onCenterChange = debounce(() => {
    const bounds = this.map.getBounds();
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();

    this.props.setBounds({
      northEast: {
        longitude: ne.lng(),
        latitude: ne.lat(),
      },
      southWest: {
        longitude: sw.lng(),
        latitude: sw.lat(),
      },
    });
  }, debounceTime);

  onWeekPage = (id: number) => {
    const { loginStore, weeksStore, selectedWeek } = this.props;
    const { account, userWalletData } = loginStore;
    const { selectedWeeksSearchClientCash } = weeksStore;

    const hotelPrices = getWalletPrices(account, userWalletData, selectedWeeksSearchClientCash, selectedWeek?.price, selectedWeek?.maxWalletClientCash, WalletPriceType.Search);
    const clientCashApplied = hotelPrices?.clientCashApplied;

    this.props.setSelectedWeeksSearchClientCash({ ...selectedWeeksSearchClientCash, selectedPropertyClientCash: clientCashApplied, selectedPropertyReviewClientCash: null });

    this.props.history.push(`/${account?.name}${Routes.WeeksDetails}/${id}/${location.search}`);
  };

  render(): React.ReactNode {
    const { disabled = false, selectedWeek, hasCompareSelected, menuStore } = this.props;

    const hasFooterMenu = menuStore?.items?.footer?.length > 0;

    return (
      <div className="condo-map-wrapper">
        {disabled && <div className="condo-map-wrapper__disabled-overlay" />}
        <div className="condo-map-wrapper__map">
          <div className="condo-map-wrapper__map-instance" ref={this.mapRef} />
        </div>
        {selectedWeek && (
          <div
            className={`condo-map-wrapper__hotel-detail ${(hasCompareSelected && !hasFooterMenu) ? 'compare-open' : ''}`}
            onClick={() => this.onWeekPage(selectedWeek.propertyId)}
          >
            <Responsive>
              <WeekHotelCard
                week={selectedWeek}
                disabled={disabled}
                isMap={true}
              />
            </Responsive>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    weeksStore: state.weeksStore,
    loginStore: state.loginStore,
    menuStore: state.navigationMenuStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setSelectedWeeksSearchClientCash: weeksActions.setSelectedWeeksSearchClientCash
};

export const WeeksMapWrapper = connect(mapStateToProps, mapDispatchToProps)(withRouter(WeeksMapWrapperComponent));
