import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { FunctionalRootState } from '@share/utils';
import { AllGetaway } from '@components';
import {
  defaultLocationValue,
  GetawaysCondoSubCategoryEnum,
  GetawaysLocationEnum,
} from '@share/common-types';
import { GetawayHeader } from '../getaway-header';
import { debounce, isEmpty } from 'lodash';
import { IGateway, IGetawaysState } from '@store/slices';

import NotGetawayToShow from '../../getaway-list/not-getaways-to-show/component';
import { GetawayFilters } from '../../getaway-list/getaway-filters/component';

import './style.scss';

const EXCLUDE_VALUES = [
  GetawaysLocationEnum.AllInclusive,
  GetawaysCondoSubCategoryEnum.PremierEscapes,
  GetawaysCondoSubCategoryEnum.ValueGetaways,
];

export interface GetawayItem {
  getaway: GetawaysLocationEnum;
  subCategory: GetawaysCondoSubCategoryEnum | null;
  show: boolean;
  isInternal: boolean;
}

export interface IGetawayFilters {
  sort: string;
  location: string;
  locationState: { city: string; state: string; country: string; getaway: string | null };
  promoId: string;
}

const AllGetawayWrapperComponent: React.FC = () => {
  const getawayStore: IGetawaysState = useSelector(
    (state: FunctionalRootState) => state.getawaysStore,
  );

  const condosStore: IGateway = getawayStore.newCondos;
  const staycationStore: IGateway = getawayStore.staycation;
  const cruiseStore: IGateway = getawayStore.cruise;
  const fantasyStore: IGateway = getawayStore.fantasy;

  const initialFilterState: IGetawayFilters = {
    sort: 'PriceAsc',
    location: defaultLocationValue,
    locationState: { city: '', state: '', country: '', getaway: null },
    promoId: '',
  };

  const getawayTotalCount = Object.entries(getawayStore)
    .map(([_key, value]) => value)
    .filter((item) => item.isRequestPopulated)
    .reduce((total, item) => {
      return total + (item.getawaysTotalCount || 0);
    }, 0);

  const [getawaysFilter, setGetawaysFilters] = useState<GetawayItem[]>([
    {
      getaway: GetawaysLocationEnum.NewCondos,
      subCategory: null,
      show: true,
      isInternal: true,
    },
    {
      getaway: GetawaysLocationEnum.Staycation,
      subCategory: null,
      show: true,
      isInternal: true,
    },
    {
      getaway: GetawaysLocationEnum.Cruise,
      subCategory: null,
      show: true,
      isInternal: true,
    },
    {
      getaway: GetawaysLocationEnum.Fantasy,
      subCategory: null,
      show: true,
      isInternal: true,
    },
  ]);

  const [sortGetawayFilters, setSortGetawayFilters] = useState<IGetawayFilters>(initialFilterState);

  const [notGatewaysToShow, setNotGatewaysToShow] = useState({
    countToShow: 0,
    countRequestData: 0,
    getawaysToShow: 0,
  });

  const notPromosToShow = getawaysFilter.filter(
    (getaway) =>
      getaway.show &&
      (getaway.getaway === GetawaysLocationEnum.Fantasy ||
        getaway.getaway === GetawaysLocationEnum.Cruise ||
        getaway.getaway === GetawaysLocationEnum.NewCondos),
  ).length;

  useEffect(() => {
    const handleDebounce = debounce(() => {
      const countToShow = getawaysFilter.filter((getaway) => !getaway.show).length;

      const countRequestData = Object.entries(getawayStore)
        .map(([_key, value]) => value)
        .filter(
          (item) =>
            item &&
            !item.isRequestPopulated &&
            !EXCLUDE_VALUES.includes(item.activeGetawaySubcategory) &&
            !isEmpty(item.activeGetawaySubcategory),
        ).length;

      const getawaysToShow = Object.entries(getawayStore)
        .map(([_key, value]) => value)
        .filter(
          (item) => item &&
                    item.isRequestPopulated &&
                    !EXCLUDE_VALUES.includes(item.activeGetawaySubcategory) &&
                    !isEmpty(item.activeGetawaySubcategory),
        ).length;

      setNotGatewaysToShow({ countToShow, countRequestData, getawaysToShow });
    }, 500);

    handleDebounce();

    // Cleanup function to cancel the debounce if the component unmounts or dependencies change
    return () => {
      handleDebounce.cancel();
    };
  }, [getawayStore, condosStore, staycationStore, cruiseStore, fantasyStore, getawaysFilter]);

  return (
    <>
      <section style={{ marginBottom: 80 }}>
        <GetawayHeader />

        <GetawayFilters
          getawaysFilter={getawaysFilter}
          setGetawaysFilters={setGetawaysFilters}
          getawayTotalCount={getawayTotalCount}
          setSortGetawayFilters={setSortGetawayFilters}
          sortGetawayFilters={sortGetawayFilters}
          getawayStore={getawayStore}
          notGatewaysToShowResult={notGatewaysToShow.countToShow}
          notPromosToShow={notPromosToShow}
          condosStore={condosStore}
          staycationStore={staycationStore}
          cruiseStore={cruiseStore}
          fantasyStore={fantasyStore}
          getawaysToShow={notGatewaysToShow.getawaysToShow}
          countRequestData={notGatewaysToShow.countRequestData}
          initialFilterState={initialFilterState}
        />

        {/* Rendering all getaways */}
        {getawaysFilter.map(
          (getaway, i) =>
            getaway.show && (
              <AllGetaway
                key={i}
                isInternal={getaway.isInternal}
                getaway={getaway.getaway}
                condoSubcategory={getaway.subCategory as string}
                
                getawaysFilter={getawaysFilter}
                setGetawaysFilters={setGetawaysFilters}
                sortGetawayFilters={sortGetawayFilters}
                setSortGetawayFilters={setSortGetawayFilters}
              />
            ),
        )}

        {notGatewaysToShow.countToShow === getawaysFilter.length && (
          <NotGetawayToShow title="There are no getaways to display" />
        )}

        {notGatewaysToShow.countRequestData === getawaysFilter.length && (
          <NotGetawayToShow title="There are no getaways to display" />
        )}
      </section>
    </>
  );
};

export const AllGetawayWrapper = connect()(AllGetawayWrapperComponent);
