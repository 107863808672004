
// @ts-ignore
import JotformEmbed from 'react-jotform-embed';

import { ILoginState, IMenuState } from '@share/store/slices';
import { connect } from 'react-redux';

import { RootState } from '@share/utils';

import './style.scss';
import { Loading, LoadingSise } from '@share/components';

const MLM_SUPPORT_FORM = 'https://form.jotform.com/232994817051260';
const MLM_CONCIERGE_FORM = 'https://form.jotform.com/232994740137260';
const MLM_CUSTOM_REQUEST_FORM = 'https://form.jotform.com/232994575391268';
const MLM_PHONE_CHATE_FORM = 'https://form.jotform.com/232994489229272';

interface IMapStateToProps {
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps {}

function ContactUsFormComponent(props: IProps) {

  const { menuStore } = props;

  if (menuStore?.loading || !menuStore?.items) {
    return (
      <div className="contact-us-form loading-form">
        <Loading size={LoadingSise.Medium} />
      </div>);
  }

  return (
    <div className="contact-us-form">
      {!menuStore?.items?.isMLM ? (
        <JotformEmbed {...{ src: 'https://form.jotform.com/220953995609167', frameBorder: '0' }} />) : (
        <>
          <div className="contact-us-form__support">
            <JotformEmbed {...{ src: `${MLM_SUPPORT_FORM}?memberid=${menuStore?.items?.rsiId}&fullName[first]=${menuStore?.items?.firstName}&fullName[last]=${menuStore?.items?.lastName}&email=${menuStore?.items?.email}&country50=${menuStore?.items?.country}&input55=${menuStore?.items?.phone}`, frameBorder: '0' }} />
          </div>
          <div className="contact-us-form__concierge">
            <JotformEmbed {...{ src: `${MLM_CONCIERGE_FORM}?memberid=${menuStore?.items?.rsiId}&fullName[first]=${menuStore?.items?.firstName}&fullName[last]=${menuStore?.items?.lastName}&email=${menuStore?.items?.email}&email51=${menuStore?.items?.country}&phoneNumber=${menuStore?.items?.phone}`, frameBorder: '0' }} />
          </div>
          <div className="contact-us-form__custom-request">
            <JotformEmbed {...{ src: `${MLM_CUSTOM_REQUEST_FORM}?memberid=${menuStore?.items?.rsiId}&fullName[first]=${menuStore?.items?.firstName}&fullName[last]=${menuStore?.items?.lastName}&email=${menuStore?.items?.email}&email51=${menuStore?.items?.country}&phoneNumber52=${menuStore?.items?.phone}`, frameBorder: '0' }} />
          </div>
          <div className="contact-us-form__phone-chat">
            <JotformEmbed {...{ src: MLM_PHONE_CHATE_FORM, frameBorder: '0' }} />
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
  };
};

export const ContactUsForm = connect(mapStateToProps)(ContactUsFormComponent);
