import { useMemo } from 'react';
import { connect } from 'react-redux';
import { Modal, Tooltip } from 'antd';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { getAccountUsernameFromPath, RootState, VACATIONS_PAGE } from '@share/utils';
import { Routes } from '@share/constants';
import { ILoginState } from '@share/store/slices';

import Image from '@assets/images/availability-status.png';

import './style.scss';

export enum Type {
  CONDOS,
  HOTELS,
  VACATION_RENTALS,
  WEEKS,
  GETAWAYS,
}

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps, RouteComponentProps {
  hasSoldout: boolean;
  isUnavailable: boolean;

  propertyId: string;
  bookingErrorCode: string;
  type: Type;
}

const modalWidth = 400;

function ModalAvailabilityStatusComponent(props: IProps) {
  const { hasSoldout, isUnavailable, propertyId, bookingErrorCode, type, history, loginStore, intl } = props;
  const { account } = loginStore;

  const accountName = useMemo(() => getAccountUsernameFromPath(history), [history]);

  const routeDetails = useMemo(() => type === Type.WEEKS ? 
                                        Routes.WeeksDetails :
                                        type === Type.CONDOS ?
                                            Routes.Condo :
                                            Routes.Hotel, [type]);

  const routeSearch =  useMemo(() => type === Type.GETAWAYS ? 
                                        Routes.Getaways :
                                        type === Type.WEEKS ? 
                                            Routes.WeeksSearch :
                                            type === Type.CONDOS ?
                                                Routes.CondoSearch :
                                                Routes.Search, [type]);
    

  const vacationRentalsRoute = useMemo(() => type === Type.VACATION_RENTALS ? `/${VACATIONS_PAGE}` : '',[type]);

  const handleGoToDetails = () => {
    history.push(`/${accountName}${routeDetails}/${propertyId}${vacationRentalsRoute}${location.search}`);
  }
  const handleGoToSearch = () => {
    history.push(`/${accountName}${routeSearch}${vacationRentalsRoute}${location.search}`);
  }

  const background = account?.buttonColor;
  const border = account?.buttonBorder;
  const color = account?.buttonTextColor;
  const borderRadius = account?.buttonBorderRadius;
  const buttonTextTransform = account?.buttonTextTransform;
    
  const styleButtonBorderRadius = !isEmpty(borderRadius) ? { borderRadius: `${borderRadius}px` } : {};  
  const styleButtonnTextTransform = (buttonTextTransform && !isEmpty(buttonTextTransform)) ? { ...styleButtonBorderRadius, textTransform: buttonTextTransform as any } : { ...styleButtonBorderRadius };  
  const styleButtonBorder = !isEmpty(border)? { ...styleButtonnTextTransform, border } : { ...styleButtonnTextTransform };  
  const styleButtonColor = !isEmpty(color)? { ...styleButtonBorder, color } : { ...styleButtonBorder };  
  const styleButton = !isEmpty(background)? { ...styleButtonColor, background } : { ...styleButtonColor };  

  return (
    <div className="price-change-modal">
      <Modal
        className="price-change-modal-wrapper"
        wrapClassName="price-change-modal-wrapper__wrap"
        open={hasSoldout || isUnavailable}
        footer={null}
        title={null}
        closable={false}
        keyboard={false}
        destroyOnClose={true}
        width={modalWidth}
      >
        <div className="price-change-modal-wrapper__wrapper">
          <img src={Image} alt="" />
          <span className="price-change-modal-wrapper__title"><FormattedMessage id={'unit.is.no.available'} /></span>
          <span className="price-change-modal-wrapper__message">
            <FormattedMessage id={'condo.availability.status.modal.message'} />
            <Tooltip placement="rightBottom" title={intl.formatMessage({ id: 'condo.availability.status.modal.tooltip' })}>
              <FontAwesomeIcon icon={faInfoCircle} />
            </Tooltip>
          </span>
          <div className={`price-change-modal-wrapper__buttons`} style={{ justifyContent: (bookingErrorCode ? 'space-between' : 'center') }}>
            <div className="accept-button" style={styleButton} onClick={handleGoToSearch}><FormattedMessage id={type === Type.GETAWAYS ? 'back.to.list' : 'availability.status.modal.search_again'} /></div>
            {bookingErrorCode && <div style={styleButton} className="go-to-search-button" onClick={handleGoToDetails}><FormattedMessage id={'availability.status.modal.back_detail'} /></div>}
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

export const ModalAvailabilityStatus = connect(mapStateToProps)(injectIntl(withRouter(ModalAvailabilityStatusComponent)));
