import React from 'react';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import { NoImageSvg } from '@assets';
import { CarouselImages } from '@components';
import { ICarDetails, IImage } from '@share/common-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/free-solid-svg-icons';

import { CarsCardItems } from '../../cars-card/cars-card-items';
import { CarsCardRefundability } from '../../cars-card/cars-card-refundability';

import './style.scss';

interface IProps {
  car: ICarDetails;

  isBookingCompleted?: boolean;
  hideImage?: boolean;
}

class CarsInfoComponent extends React.Component<IProps> {

  render(): React.ReactNode {
    const { car, isBookingCompleted, hideImage } = this.props;
    
    const vehicle = car?.vehicle;
    const info = car?.info;

    const displayName = vehicle?.vehMakeModel?.name;
    const category = vehicle?.category;

    const image = vehicle?.pictureURL;

    const vendorImage =  info?.tpA_Extensions?.supplierLogo?.url;

    const filterdImages = image ? [{ url: image } as IImage] : [];

    const styles: any = {};
    if (isBookingCompleted) {
      styles.justifyContent = 'flex-end';
    }

    return (
      <div className="hotel-card cars-card-items cars-info container-fluid">
        <div className="row">
          <div className={hideImage ? 'col-md-12' : 'col-md-8'}>
            <div className="hotel-info">
              <div className="hotel-info__item">
                <div className="hotel-info__item-content">
                  {!isEmpty(category) ? (
                    <h4 className="hotel-info__title" style={{ marginBottom: '3px' }}>
                      {category}
                    </h4>) : null}
                  <h4 className="hotel-info__title">
                    {displayName}<label style={{ marginLeft: '6px' }}><FormattedMessage id="cars.similar" /></label>{vehicle?.fuelType === 'Electric' ? (<label className="cars-content__electric">(<FontAwesomeIcon icon={faBolt} /><FormattedMessage id="cars.electric" />)</label>) : null}
                  </h4>

                  <CarsCardItems car={car} chunks={hideImage ? 2 : null} />

                  <div className="cars-info__line" style={styles}>
                    {!isBookingCompleted ? (<CarsCardRefundability car={car} />) : null}

                    {!isEmpty(vendorImage) ? (
                      <div className="cars-content-location__vendor" style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <img alt="" src={vendorImage} width="100%" />
                      </div>) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!hideImage ? (
            <div className="col-md-4">
              {filterdImages?.length ? (
                <CarouselImages images={filterdImages} isNoCoverFit />) : (
                <div className="hotel-card-wrapper__no-image">
                  <NoImageSvg />
                </div>)}
            </div>) : null}
        </div>
      </div>
    );
  }
}

export const CarsInfo = connect()(CarsInfoComponent);

