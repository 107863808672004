import React, { useEffect, useMemo, useState } from 'react';

import moment from 'moment';
import debounce from 'lodash/debounce';

import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { ILoginState, IWeeksDatesState, weeksDatesActions } from '@share/store/slices';
import { RootState } from '@share/utils';
import { DATE_FORMAT_FILTER, DEFAULT_MEDIA_POINT } from '@share/constants';
import { CustomDatePicker } from '@components';

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.scss';

const focusZIndex = 4;
const blurZIndex = 1;

interface IMapStateToProps {
  weeksDatesStore: IWeeksDatesState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setDateSelected: (date: string) => void;

  clearError: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  isAfterSearchLoad: boolean;
}

const resizeDebounceTime = 300;

export function WeeksDatePickerComponent(props: IProps) {

  const [isFocused, setIsFocused] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const { isAfterSearchLoad, weeksDatesStore, loginStore, intl, clearError, setDateSelected } = props;

  const { account } = loginStore;

  const color: any = account?.searchIconsColor;

  const leadTime = account?.leadTime ? account?.leadTime : 0;

  const styleColor = useMemo(() => !isEmpty(color)? { color } : {}, [color]);
  const date = useMemo(() => !isEmpty(weeksDatesStore.dateSelected) ? moment(weeksDatesStore.dateSelected, 'MM/DD/yyyy').format('MM/DD/yyyy') : '', [weeksDatesStore.dateSelected]);

  useEffect(() => {
    setIsMobile(document.body.offsetWidth <= DEFAULT_MEDIA_POINT);

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, []);

  const focus = (): void => {
    if (!isFocused) {
      setIsFocused(true);
    }
  };

  const onResize = debounce(() => {
    if (document.body.offsetWidth <= DEFAULT_MEDIA_POINT && !isMobile) {
      setIsMobile(true);

      setDateSelected(moment().format('MM/DD/yyyy'));
      setIsMobile(true);

      setTimeout(() => {
        setDateSelected(date);
      });
    } else if (document.body.offsetWidth > DEFAULT_MEDIA_POINT && isMobile) {
      setIsMobile(false);
    }
  }, resizeDebounceTime);

  const onSelect = (date: string): void => {
    clearError();

    setDateSelected(date);
    setIsFocused(false);
  };

  const generalBorderRadius: any = account?.generalBorderRadius ? account?.generalBorderRadius : null;
  const style = !isEmpty(generalBorderRadius) ? { borderRadius: generalBorderRadius } : {};  

  return (
    <div className={`date-picker weeks-dates-container`}>
      <div
        className={`date-picker__wrapper dates-selection ${isAfterSearchLoad ? 'after-search' : ''}`}
        style={{ ...style, zIndex: isFocused ? focusZIndex : blurZIndex }}
      >
        {!isAfterSearchLoad ? (
          <div className="date-picker__left">
            <div className="date-picker__icon" style={styleColor}>
              <FontAwesomeIcon icon={faCalendarAlt} />
            </div>
          </div>) : null}

        <div className={`date-picker__right ${isFocused ? 'dates-focused' : ''}`}>
          <div className="date-picker__label">
            <FormattedMessage id="cars.dates" />
          </div>
          <div className="date-picker__header">
            <CustomDatePicker
              value={date}
              placeholder={intl.formatMessage({ id: 'select.date' })}
              format={DATE_FORMAT_FILTER}
              disabled={false}
              onDateChange={onSelect}
              onCalendarOpen={focus}
              minDate={moment().add(leadTime, 'days').toDate()}
              allowClear
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    weeksDatesStore: state.weeksDatesStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  setDateSelected: (date: string) => {
    dispatch(weeksDatesActions.setDateSelected(date));
  },
  clearError: () => {
    dispatch(weeksDatesActions.setErrorDate(''));
  },
});

export const WeeksDatePicker = connect(mapStateToProps, mapDispatchToProps)(injectIntl(WeeksDatePickerComponent));
