import React from 'react';

import { SideKickRewards } from '@components';
import { scrollTop } from '@share/utils';

import './style.scss';

interface IProps {}

class SidekickPageComponent extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);

    scrollTop();
  }

  render(): React.ReactNode {
    return (
      <div className="sidekick-page">
        <SideKickRewards />
      </div>
    );
  }
}

export const SidekickPage = SidekickPageComponent;
