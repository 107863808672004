import React from 'react';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { DATE_FORMAT } from '@share/constants';
import { formatDateCheckInOut, RootState } from '@share/utils';

import { SearchSvg } from '@share/assets';

import './style.scss';
import { ICarLocationsState, ICarsDatesState, ILoginState } from '@share/store/slices';
import { isEmpty } from 'lodash';

interface IMapStateToProps {
  carsLocationsStore: ICarLocationsState;
  carsDatesStore: ICarsDatesState;
  
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {
  onToggle: (e: React.MouseEvent<HTMLParagraphElement>) => void;
}

class CarsSearchInfoComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { carsLocationsStore, carsDatesStore, loginStore } = this.props;
    const { account } = loginStore;

    const locale = account?.locale;

    const pickUp = carsLocationsStore?.pickUp;

    const pickUpLocation = pickUp?.location;
    const dropOffLocation = carsLocationsStore?.dropOff?.location;

    return carsDatesStore.startDate && carsDatesStore.endDate ? (
      <div className="search-info" onClick={this.props.onToggle}>
        <SearchSvg />
        <div className="search-info__info">
          <p className="search-info__locations">{pickUp?.selectedLocationLabel}</p>
          <p className="search-info__locations">{(!isEmpty(dropOffLocation?.name) && dropOffLocation?.code !== pickUpLocation?.code) ? dropOffLocation?.name : <FormattedMessage id="cars.dropoff.location.placeholder" />}</p>
          <p className="search-info__description">
            {formatDateCheckInOut(carsDatesStore.startDate, DATE_FORMAT, locale)}:{carsDatesStore.startDateTime} -{' '}
            {formatDateCheckInOut(carsDatesStore.endDate, DATE_FORMAT, locale)}:{carsDatesStore.endDateTime}
          </p>
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    carsLocationsStore: state.carsLocationsStore,
    carsDatesStore: state.carsDatesStore,
    loginStore: state.loginStore,
  };
};

export const CarsSearchInfo = connect(mapStateToProps)(CarsSearchInfoComponent);
