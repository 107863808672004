import React, { ChangeEvent, useState } from 'react';
import { Modal, Input } from 'antd';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { Insurance, PaymentMethodBase, WhiteButton } from '@components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { BlueButton } from '@share/components';

import './style.scss';
import { IReservation, IReservationGuest, IStatic } from '@share/common-types';
import { get, isEmpty } from 'lodash';
import { USA_STATES } from '@constants';
import { FormInstance } from 'antd/lib/form';
import { ICardBooking, IFormErrors } from '@common-types';

const INITIAL_CARD = {
  addressLine: '',
  cvv: '',
  cardNumber: '',
  cardType: null as string,
  city: '',
  country: 'US',
  state: '',
  expireDate: '',
  holderName: '',
  phone: '',
  zipCode: '',
  addPaymentMethod: false
};

interface IProps extends WrappedComponentProps {
  visible: boolean;
  loading: boolean;
  error: string;

  reservation: IReservation;
  staticData: IStatic;

  onCancel: () => void;
  onProtect: (card: ICardBooking, callBack: () => void) => void;
}

const modalWidth = 600;

function InsuranceModalComponent(props: IProps) {

  const [card, setCard] = useState(INITIAL_CARD);
  
  const { visible, loading, error, reservation, staticData, onCancel, onProtect } = props;

  const formPaymentRef = React.createRef<FormInstance>();

  const mainGuest: IReservationGuest = get(reservation?.rooms?.map(r => r.guests).flat().filter(g => g?.isMainGuest === true), '[0]');
  const mainGuestState = get(USA_STATES.filter(s => s.postalCode === mainGuest?.stateCode), '[0].name');

  const reset = () => {
    setCard(INITIAL_CARD);
    formPaymentRef.current?.resetFields();
  }

  const handleAddInsurance = () => {
    if (!loading && !error) {
      formPaymentRef.current.setFieldsValue({ address: card.addressLine });
      formPaymentRef.current.setFieldsValue({ city: card.city });
      formPaymentRef.current.setFieldsValue({ state: card.state });
      formPaymentRef.current.setFieldsValue({ 'postal-code': card.zipCode });
  
      const formRef = formPaymentRef.current.validateFields();
  
      Promise.all([formRef])
      .then(() => {
        onProtect(card, () => {
          reset();
        });
      })
      .catch((error: IFormErrors) => {
        const { errorFields } = error;
        console.error(errorFields);
      });
    }
  }


  return (
    <div className="insurance-modal">
      <Modal
        className="insurance-modal-wrapper"
        wrapClassName="insurance-modal-wrapper__wrap"
        visible={visible}
        footer={null}
        onCancel={() => {
          if (!loading) {
            reset();
            onCancel();
          }
        }}
        closable={true}
        destroyOnClose={true}
        closeIcon={<FontAwesomeIcon icon={faTimes} />}
        width={modalWidth}
        title={<FormattedMessage id="insurance.policy.add" />}
      >
        <div className="insurance-modal-wrapper__body">
          <div className="item-field"><label>Lead name:</label>{`${mainGuest?.givenName} ${mainGuest?.surName}`}</div>
          <div className="item-field"><label>Lead Country:</label>{mainGuest?.countryCode}</div>
          <div className="item-field"><label>Lead State:</label>{mainGuestState}</div>
          <div className="item-field"><label>Check-In:</label>{reservation?.checkIn}</div>
          <div className="item-field"><label>Check-Out:</label>{reservation?.checkOut}</div>
          <div className="item-field" style={{ marginBottom: '25px' }}><label>Destination Country:</label>{staticData?.countryName}</div>
          
          <Insurance textOnly />

          {!isEmpty(error) ? <label style={{ color: 'red', fontWeight: 'bold' }}>{error}</label> : null}

          <PaymentMethodBase
            form={null}
            formRef={formPaymentRef}

            onDataRefreshed={() => this.setState({ refreshPayment:  false })}

            card={card}
            errorsField={null}
            loading={loading}
            setCard={(c: ICardBooking) => setCard(c)}

            display
            isRequired
            skipStorage
            disableCountrySelection
          />

        </div>
        <div className="insurance-modal-wrapper__footer">
          <WhiteButton disabled={loading || !isEmpty(error)} onClick={() => {
            reset();
            onCancel();
          }}>
            <FormattedMessage id="cancel" />
          </WhiteButton>
          <BlueButton disabled={loading || !isEmpty(error)} onClick={handleAddInsurance}>
            <FormattedMessage id="insurance.policy.add_protection" />
          </BlueButton>
        </div>
      </Modal>
    </div>
  );
}

export const InsuranceModal = injectIntl(InsuranceModalComponent);
