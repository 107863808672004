
import { isEmpty } from "lodash";

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const SPECIAL_CHARACTERS_REGEX = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

const SPECIAL_CHARACTERS_WITHOUT_SPACE_DASHES_REGEX = /[`!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;

const WHITESPACE_REGEX = /\s/;

const NUMBER_REGEX = /^\d+$/;

const URL_REGEX = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

const DOMAIN_REGEX = /^([a-zA-Z0-9._-]+)$/;

const NAMES_REGEX = /^([A-Z0-9_-]+)$/;

export const validateEmpty = (value: string): boolean => !isEmpty(value);

export const validateLength = (value: string, maxLength: number): boolean => isEmpty(value) || value.length <= maxLength;

export const validateWhiteSpacesBasic = (value: string): boolean => !WHITESPACE_REGEX.test(String(value));

export const validateSpecialCharactersBasic = (value: string): boolean => !SPECIAL_CHARACTERS_REGEX.test(String(value));

export const validateSpecialCharactersBasicWithSpacesDashes = (value: string): boolean => !SPECIAL_CHARACTERS_WITHOUT_SPACE_DASHES_REGEX.test(String(value));

export const validateWhiteSpaces = (value: string): boolean => validateEmpty(value) && validateWhiteSpacesBasic(String(value));

export const validateSpecialCharacters = (value: string): boolean => validateEmpty(value) && validateSpecialCharactersBasic(String(value));

export const validateBasicWithoutSpaces = (value: string, maxLength: number): boolean => validateSpecialCharacters(value) && validateWhiteSpaces(value) && validateLength(value, maxLength);

export const validateNameBasic = (value: string, maxLength: number): boolean => validateEmpty(value) && NAMES_REGEX.test(value) && validateLength(value, maxLength);

export const validateBasic = (value: string, maxLength: number): boolean => validateEmpty(value) && validateSpecialCharactersBasicWithSpacesDashes(value) && validateLength(value, maxLength);

export const validateEmail = (value: string): boolean => validateWhiteSpaces(value) && EMAIL_REGEX.test(String(value));

export const validateNumber = (value: string): boolean => validateSpecialCharactersBasic(value) && validateWhiteSpacesBasic(value) && NUMBER_REGEX.test(String(value));

export const validateUrl = (value: string): boolean => validateWhiteSpaces(value) && URL_REGEX.test(String(value));

export const validateDomain = (value: string): boolean => validateWhiteSpaces(value) && DOMAIN_REGEX.test(String(value));
