import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { ILoginState, ICurrencyState, IHotelsState, getAllCurrencies, getHotels } from '@share/store/slices';
import { RootState, getSelectedCurrency, setLocalCurrency, isDetailFromPath, removeChangedCurrency } from '@share/utils';
import { Button } from 'antd';
import { chunk } from 'lodash';
import { ISessionKey, } from '@share/common-types';
import { getRoomsDetails } from '@store/slices';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
  hotelsStore: IHotelsState;
  currencyStore: ICurrencyState;
}

interface IMapDispatchToProps {
  getAllCurrencies: () => void;
  getHotels: () => void;
  getRoomsDetails: (hotelId: number, sessionKey: ISessionKey) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  onCurrencySelected: () => void;
}

function MultiCurrencyComponent(props: IProps) {
  const [selected, setSelected] = useState("USD");
  const history = useHistory();

  useEffect(() => {
    setSelected(getSelectedCurrency(props.loginStore.account));
    if (!props.currencyStore.currencies.length) {
      props.getAllCurrencies();
    }
  }, [])

  const setCurrency = (currency: string) => {
    setSelected(currency);
    setLocalCurrency(currency);
    props.onCurrencySelected();

    if (!props.hotelsStore.isSearch || isDetailFromPath(history)) {
      history.go(0);
    } else {
      removeChangedCurrency();
      props.getHotels();
    }
  }

  const currencies = chunk(props.currencyStore.currencies, 4);

  return (
    <>
      <div className="modal-currency">
        <p className='title'><FormattedMessage id="choose.currency" /></p>
        <div className="root">
          {
            currencies.map((el, ix) => (
              <div key={`l_${ix}`} className="root-child">
                {el.map((c: any) => {
                  return (
                    <Button
                      key={c.code} type="text"
                      className={`root-btn btn-text ${c.code === selected ? 'btn-text-selected' : ''}`}
                      onClick={() => setCurrency(c.code)}>{`${c.code} - ${c.symbol}`}
                    </Button>
                  )
                })}
              </div>
            ))
          }
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    hotelsStore: state.hotelsStore,
    currencyStore: state.currencyStore
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  getAllCurrencies: () => {
    dispatch(getAllCurrencies());
  },
  getHotels: () => {
    dispatch(getHotels());
  },
  getRoomsDetails: (hotelId: number, sessionKey: ISessionKey) => {
    dispatch(getRoomsDetails(hotelId, sessionKey));
  },
});

export const MultiCurrency = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(MultiCurrencyComponent));
