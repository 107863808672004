
import rehypeRaw from 'rehype-raw';
import ReactMarkdown from 'react-markdown';

import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ILoginState, IMenuState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { faqMarkDownContent, MarkDownEnum } from '@common-types';

import './style.scss';
import { isEmpty } from 'lodash';

interface IMapStateToProps {
  menuStore: IMenuState;
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps {
  messageId: MarkDownEnum;
}

function MarkdownComponent(props: IProps) {
  const { intl, messageId, menuStore, loginStore } = props;
  const { account } = loginStore;

  const phone = menuStore.items?.phone;
  const isMLM = menuStore.items?.isMLM;

  const id = (messageId === MarkDownEnum.PriceMatch && menuStore.items?.isRemovePriceMatch) ? MarkDownEnum.PriceMatchNoData : messageId;

  const mainBackgroundColor = account?.mainBackgroundColor;

  let message = intl.formatMessage({ id });

  message = message.replace('{phone}', (phone || isMLM) ? faqMarkDownContent(phone as string, mainBackgroundColor as string) : '')
  message = message.replace('{styleobject}', !isEmpty(mainBackgroundColor) ? `style="background: ${mainBackgroundColor}"` : '')

  return (
    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
      {message}
    </ReactMarkdown>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore
  };
};

export const Markdown = connect(mapStateToProps)(injectIntl(MarkdownComponent));

