import { combineReducers } from '@reduxjs/toolkit';
import { routerReducer } from 'react-router-redux';

import {
  hotelsReducer,
  locationsReducer,
  roomsReducer,
  datesReducer,
  loginReducer,
  filtersReducer,
  condoFiltersReducer,
  navigationMenuReducer,
  benefitsReducer,
  marginatorReducer,
  currencyReducer,
  reservationsReducer,
  passwordReducer,
  travelHistoryReducer,
  myProfileReducer,
  trustYouReviewReducer,
  condoGuestsReducer,
  condosReducer,
  condosLocationPickerReducer,
  condoStrictDatesReducer,
  condoFlexibleDatePickerReducer,
  condoDetailsReducer,
  quotesReducer,
  reservationCancellationReducer,
  condosStaticReducer,
  carsReducer,
  carsLocationsReducer,
  carsDatesReducer,
  carsFiltersReducer,
  carsDriverReducer,
  weeksReducer,
  weeksLocationsReducer,
  weeksDatesReducer,
  weeksFiltersReducer,
} from '@share/store/slices';

import {
  getawaysReducer,
  getawaysDetailsReducer,
  hotelDetailsReducer,
  reviewBookReducer,
  roomsSearchReducer,
  redeemCodeReducer,
  unitsSearchReducer,
  condoReviewBookReducer,
  condoRedeemCodeReducer,
  accountsReducer,
  accountSaveReducer,
  accountUsersReducer,
  accountUserSaveReducer,
  supportPhoneReducer,
  magazineReducer,
  travelLeaderReducer,
  condoPopularDestinationsReducer,
  popularDestinationsReducer,
  popularDestinationsOffersReducer,
  notificationBarReducer,
  getawayBookingReducer,
  currentUserReducer,
  getawayDatesReducer,
  personalDetailsReducer,
  webinarsReducer,
  carsDetailsReducer,
  carsReviewBookReducer,
  insuranceReducer,
  dashboardReportsReducer,
  weekDetailsReducer,
  weeksReviewBookReducer,
  weeksUnitsSearchReducer,
} from '../slices';

import * as slices from '../slices';

export const rootReducer = combineReducers({
  hotelsStore: hotelsReducer,
  locationsStore: locationsReducer,
  roomsStore: roomsReducer,
  datesStore: datesReducer,
  getawaysStore: getawaysReducer,
  getawaysDetailsStore: getawaysDetailsReducer,
  navigationMenuStore: navigationMenuReducer,
  filtersStore: filtersReducer,
  hotelDetailsStore: hotelDetailsReducer,
  reviewBookStore: reviewBookReducer,
  roomsSearchStore: roomsSearchReducer,
  trustYouReviewStore: trustYouReviewReducer,
  redeemCodeStore: redeemCodeReducer,
  condoGuestsStore: condoGuestsReducer,
  condosStore: condosReducer,
  condosStaticStore: condosStaticReducer,
  condosLocationPickerStore: condosLocationPickerReducer,
  condoStrictDatesStore: condoStrictDatesReducer,
  condoFlexibleDatePickerStore: condoFlexibleDatePickerReducer,
  condoDetailsStore: condoDetailsReducer,
  unitsSearchStore: unitsSearchReducer,
  condoReviewBookStore: condoReviewBookReducer,
  condoRedeemCodeStore: condoRedeemCodeReducer,
  accountsStore: accountsReducer,
  accountSaveStore: accountSaveReducer,
  accountUsersStore: accountUsersReducer,
  condoFiltersStore: condoFiltersReducer,
  loginStore: loginReducer,
  accountUserSaveStore: accountUserSaveReducer,
  routing: routerReducer,
  reservationCancellationStore: reservationCancellationReducer,
  supportPhoneStore: supportPhoneReducer,
  benefitsStore: benefitsReducer,
  magazineStore: magazineReducer,
  travelLeaderStore: travelLeaderReducer,
  condoPopularDestinationsStore: condoPopularDestinationsReducer,
  popularDestinationsStore: popularDestinationsReducer,
  popularDestinationsOffersStore: popularDestinationsOffersReducer,
  notificationBarStore: notificationBarReducer,
  getawayBookingStore: getawayBookingReducer,
  currentUserStore: currentUserReducer,
  getawayDatesStore: getawayDatesReducer,
  memberStory: slices.memberStoriesReducer,
  memberVideos: slices.memberVideosReducer,
  personalDetailsStore: personalDetailsReducer,
  marginatorStore: marginatorReducer,
  webinarsStore: webinarsReducer,
  insuranceStore: insuranceReducer,
  currencyStore: currencyReducer,
  reservationsStore: reservationsReducer,
  passwordStore: passwordReducer,
  travelHistoryStore: travelHistoryReducer,
  myProfileStore: myProfileReducer,
  quoteStore: quotesReducer,

  carsStore: carsReducer,
  carsLocationsStore: carsLocationsReducer,
  carsDatesStore: carsDatesReducer,
  carsFiltersStore: carsFiltersReducer,
  carsDriverStore: carsDriverReducer,
  carsDetailsStore: carsDetailsReducer,
  carsReviewBookStore: carsReviewBookReducer,
  dashboardReportsStore: dashboardReportsReducer,

  weeksStore: weeksReducer,
  weeksLocationsStore: weeksLocationsReducer,
  weeksDatesStore: weeksDatesReducer,
  weeksFiltersStore: weeksFiltersReducer,
  weeksDetailsStore: weekDetailsReducer,
  weeksReviewBookStore: weeksReviewBookReducer,
  weeksUnitsSearchStore: weeksUnitsSearchReducer,

});
