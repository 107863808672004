
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TablePaginationConfig } from 'antd/lib/table';

import { IUser } from '@share/common-types';
import { Urls } from '@share/constants';
import { getHeaders, axiosInstance, Toaster, AppThunk } from '@share/utils';
import { TableSortingConfig } from '.';

const PAGE_SIZE = 10;

export interface IUsersState {
  selectedUser: IUser;
  users: IUser[];
  pagination: TablePaginationConfig;
  sorting: TableSortingConfig;
  retrieveDeleted: boolean;
  loading: boolean;
  keyword: string;
  error: string;
}

const initialState: IUsersState = {
  selectedUser: null,
  users: null,
  loading: false,
  sorting: null,
  pagination: {
    total: null,
    current: 1,
    pageSize: PAGE_SIZE,
  },
  retrieveDeleted: false,
  keyword: '',
  error: '',
};

const accountUsersSlice = createSlice({
  name: 'account-users',
  initialState,
  reducers: {
    setAccountUsersLoading: (state: IUsersState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setAccountUsersError: (state: IUsersState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setAccountUsersKeyword: (state: IUsersState, { payload }: PayloadAction<string>) => {
      state.keyword = payload;
    },
    setUsers: (state: IUsersState, { payload }: PayloadAction<IUser[]>) => {
      state.users = payload;
    },
    setSelectedUser: (state: IUsersState, { payload }: PayloadAction<IUser>) => {
      state.selectedUser = payload;
    },
    setAccountUsersPagination: (state: IUsersState, { payload }: PayloadAction<TablePaginationConfig>) => {
      state.pagination = payload;
    },
    setAccountUsersSorting: (state: IUsersState, { payload }: PayloadAction<TableSortingConfig>) => {
      state.sorting = payload;
    },
    setAccountUsersRetrieveDeleted: (state: IUsersState, { payload }: PayloadAction<boolean>) => {
      state.retrieveDeleted = payload;
    },
  },
});

export const {
  setAccountUsersLoading, 
  setAccountUsersError, 
  setUsers, 
  setSelectedUser,
  setAccountUsersPagination,
  setAccountUsersSorting,
  setAccountUsersKeyword,
  setAccountUsersRetrieveDeleted
} = accountUsersSlice.actions;

export const accountUsersReducer = accountUsersSlice.reducer;

export const accountUsersSelector = (state: { accountUsersStore: IUsersState }): IUsersState => {
  return state.accountUsersStore;
};

export const getUsers = (accountId: number): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setAccountUsersLoading(true));
    dispatch(setSelectedUser(null));

    try {
      const { accountUsersStore, loginStore } = getState();
      const { user } = loginStore;
      const { pagination, sorting, keyword, retrieveDeleted } = accountUsersStore;
      const { current, pageSize } = pagination;
      const criteria = {
        page: current,
        pageSize,
        sorting,
        retrieveDeleted,
        accountId,
        keyword
      }

      const res = await axiosInstance.post(`${Urls.Users}/search`, criteria, {
        ...getHeaders(),
      });

      const { users, total } = res.data;
      const paginationUpdated = {
        total,
        current,
        pageSize
      };

      dispatch(setAccountUsersLoading(false));
      dispatch(setUsers(users));
      dispatch(setAccountUsersPagination(paginationUpdated));
    } catch (error) {
      console.error(error);
      dispatch(setAccountUsersError(error.toString()));
      dispatch(setAccountUsersLoading(false));
    }
  };
};

export const deleteAccountUser = (accountId: number, userId: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setAccountUsersLoading(true));

    try {
      await axiosInstance.delete(`${Urls.Users}/${userId}`, {
        ...getHeaders(),
      });

      dispatch(setAccountUsersLoading(false));
      dispatch(setSelectedUser(null));

      Toaster.success(`User successfully Deleted!`);

      dispatch(getUsers(accountId));

    } catch (error) {
      console.error(error);
      dispatch(setAccountUsersError(error.toString()));
      dispatch(setAccountUsersLoading(false));

      Toaster.error(`Error occured. If the problem persist, please contact system administrator`);
    }
  };
};

export const activateAccountUser = (accountId: number, userId: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setAccountUsersLoading(true));

    try {
      const { loginStore } = getState();
      const { user } = loginStore;
      await axiosInstance.post(`${Urls.Users}/${userId}/activate`, {}, {
        ...getHeaders(),
      });

      dispatch(setAccountUsersLoading(false));
      dispatch(setSelectedUser(null));

      Toaster.success(`User successfully Activated!`);

      dispatch(getUsers(accountId));

    } catch (error) {
      console.error(error);
      dispatch(setAccountUsersError(error.toString()));
      dispatch(setAccountUsersLoading(false));

      Toaster.error(`Error occured. If the problem persist, please contact system administrator`);
    }
  };
};

export const getSelectedAccountUserDetails = (userId: string): AppThunk => {
  return async (dispatch, getState) => {

    if (!userId) {
      dispatch(setSelectedUser(null));
      return
    }

    dispatch(setAccountUsersLoading(true));

    try {
      const res = await axiosInstance.get(`${Urls.Users}/${userId}`, {
        ...getHeaders(),
      });

      dispatch(setAccountUsersLoading(false));
      if (res?.status === 204) {
        dispatch(setAccountUsersError('Invalid User'));
      }  else {
        dispatch(setSelectedUser(res.data));
      }
  } catch (error) {
      console.error(error);
      dispatch(setAccountUsersError(error.toString()));
      dispatch(setAccountUsersLoading(false));
    }
  };
};
