import React from 'react';
import { List } from 'antd';
import { FormattedMessage } from 'react-intl';
import { CheckSvg } from '@assets';
import { IAmenities } from '@common-types';
import './style.scss';

interface IProps {
  activities: IAmenities[];
  matches?: boolean;
}

interface IState {
  isFullActivities: boolean;
}

const ZERO = 0;
const MAX_ACTIVITIES_COUNT = 10;

export class ActivityComponent extends React.Component<IProps, IState> {
  state: IState = {
    isFullActivities: false,
  };

  toggleActivities = (): void => {
    this.setState(({ isFullActivities }) => ({
      isFullActivities: !isFullActivities,
    }));
  };

  resultActivities = (): React.ReactNode => {
    const { activities, matches } = this.props;

    if (!activities.length) {
      return;
    }

    const activitiesToShow: IAmenities[] = this.state.isFullActivities
      ? activities
      : activities.slice(ZERO, MAX_ACTIVITIES_COUNT);

    return (
      <div className="activities-list">
        <List
          header={<div>{activities[ZERO].categoryName}</div>}
          bordered={false}
          dataSource={!matches && activities.length > MAX_ACTIVITIES_COUNT ? activitiesToShow : activities}
          renderItem={(item) => { return (<List.Item><CheckSvg /> {item.name}</List.Item>) }}
        />
        <div className="activities-list__show-link" onClick={this.toggleActivities}>
          {!matches && activities.length > MAX_ACTIVITIES_COUNT &&
            (this.state.isFullActivities ? (<FormattedMessage id="show.less" />) : (<FormattedMessage id="show.more" />))
          }
        </div>
      </div>
    );
  };

  render(): React.ReactNode {
    return <div>{this.resultActivities()}</div>;
  }
}
