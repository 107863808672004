import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Typography } from 'antd';
import first from 'lodash/first';
import { selectMemberStoriesOrderedByDate } from '@store/slices';
import { IMemberStories } from '@common-types';
import { RootState } from '@share/utils';
import { Routes } from '@share/constants';
import { CarouselSlider } from '@components';
import './style.scss';
import { ILoginState } from '@share/store/slices';

const { Paragraph } = Typography;
const ROWS = 3;
const EXPANDABLE = false;

interface IMapStateToProps {
  recentMemberStories: IMemberStories[];
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {}

const paragraphConfig = { rows: ROWS, expandable: EXPANDABLE, suffix: '”' };

class MemberStoriesCarouselComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { recentMemberStories } = this.props;
    const { loginStore } = this.props;
    const { account } = loginStore;

    return (
      <CarouselSlider titleId="memberStories.recentMemberStories">
        {recentMemberStories.map(({ images, paragraphs, author, id }) => {
          const firstParagraph = first(paragraphs);
          return (
            <div className="member-stories-carousel__item custom-carousel-image" key={id}>
              <Link
                className="member-stories-carousel__link"
                style={{ display: 'block' }}
                to={`/${account?.name}${Routes.MemberStories}/${id}`}
              >
                <img
                  className="member-stories-carousel__image custom-carousel-image__item"
                  src={first(images)?.url}
                  alt={firstParagraph}
                />
              </Link>
              <p className="member-stories-carousel__title custom-carousel-image__title">
                {author}:
              </p>
              <Paragraph
                className="member-stories-carousel__description custom-carousel-image__description"
                ellipsis={paragraphConfig}
              >
                “{firstParagraph}
              </Paragraph>
            </div>
          );
        })}
      </CarouselSlider>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    recentMemberStories: selectMemberStoriesOrderedByDate(state),
    loginStore: state.loginStore
  };
};

export const MemberStoriesCarousel = connect(mapStateToProps)(MemberStoriesCarouselComponent);
