
import { useMemo } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Footer } from '@components';
import { isErrorFromPath, isWidgetFromPath } from '@share/utils';
import { isCondoWeeksFromPath, isCondoWorldFromPath, isThreeDSFromPath, isVacationRentalsFromPath } from '@share/utils';

interface IProps extends RouteComponentProps {
}

function ApplicationFooterComponent(props: IProps) {
  const { history } = props;

  const isThreeDS = useMemo(() => isThreeDSFromPath(history), [history]);
  const isErrorPage = useMemo(() => isErrorFromPath(history), [history]);
  const isWidgetPage = useMemo(() => isWidgetFromPath(history), [history]);
  const isCondoWorldMap = useMemo(() => isCondoWorldFromPath(history), [history]);
  const isCondoWeeks = useMemo(() => isCondoWeeksFromPath(history), [history]);
  const isVacationRentals = useMemo(() => isVacationRentalsFromPath(history), [history]);

  if (!isErrorPage && !isWidgetPage && !isCondoWorldMap && !isThreeDS && !isCondoWeeks) {
    return <Footer isVacationRentals={isVacationRentals} />;
  }

  return null;
}

const ApplicationComponentWithRouter = withRouter(ApplicationFooterComponent);

export const ApplicationFooter = connect()(ApplicationComponentWithRouter);
