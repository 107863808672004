import React from 'react';

import { Modal, } from 'antd';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { IQuote } from '@share/common-types';
import { RefundableEnum } from '@common-types';
import { ILoginState, IQuoteState } from '@share/store/slices';
import { RootState, formatDateCheckInOut, renderNumber } from '@share/utils';
import { getAmenityImageByName } from '@utils';
import { Currency, HotelStars } from '@components';
import { DATE_FORMAT_CHECK_IN_OUT } from '@constants';

import './style.scss';

const modalWidth = 900;

interface IMapStateToProps {
  quoteStore: IQuoteState;
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, RouteComponentProps {
  visible: boolean;
  quoteBase: string;

  isHistorical: boolean;

  onCancel: () => void;
}

class QuoteModalCompareComponent extends React.Component<IProps> {

  handleView = (quote: IQuote) => {
    window.open(`${quote.link}&quote=${quote.userWhoShared}`, '_blank');
  }

  render(): React.ReactNode {
    const {
      visible,
      quoteBase,
      isHistorical,
      loginStore,
      onCancel
    } = this.props;
    const { result } = this.props.quoteStore;
    const { account } = loginStore;
    const { quotesHotels, quotesHistorical } = result;
    let myQuotes = isHistorical ? quotesHistorical : quotesHotels;

    if (quoteBase) {
      myQuotes = myQuotes.filter(x => x.quoteBaseId === quoteBase);
    }

    const fontFamily = account?.fontFamily;
    const secondaryFontFamily = account?.secondaryFontFamily;

    const background = account?.buttonColor;
    const border = account?.buttonBorder;
    const color = account?.buttonTextColor;
    const borderRadius = account?.buttonBorderRadius;
    const buttonTextTransform = account?.buttonTextTransform;
      
    const styleButtonBorderRadius = !isEmpty(borderRadius) ? { borderRadius: `${borderRadius}px` } : {};  
    const styleButtonnTextTransform = (buttonTextTransform && !isEmpty(buttonTextTransform)) ? { ...styleButtonBorderRadius, textTransform: buttonTextTransform as any } : { ...styleButtonBorderRadius };  
    const styleButtonBorder = !isEmpty(border)? { ...styleButtonnTextTransform, border } : { ...styleButtonnTextTransform };  
    const styleButtonColor = !isEmpty(color)? { ...styleButtonBorder, color } : { ...styleButtonBorder };  
    const styleButton = !isEmpty(background)? { ...styleButtonColor, background } : { ...styleButtonColor };  

    return (
      <Modal
        className={`quote-modal-compare ${fontFamily ? `${fontFamily}-font`: ''} ${secondaryFontFamily ? `secondary-${secondaryFontFamily}-font`: ''}`}
        open={visible}
        footer={null}
        onCancel={onCancel}
        wrapClassName="quote-modal-compare__wrapper"
        width={modalWidth}
      >
        <div>
          <h4 className="quote-modal-compare__title secondary-font">
            <FormattedMessage id="results.hotel.card.compare.modal.title" />
          </h4>

          <div className="quote-modal-compare__hotels" style={{ flexDirection: 'column' }}>
            <div className="hotels-dates">
              {myQuotes[0]?.checkIn ? formatDateCheckInOut(myQuotes[0]?.checkIn, DATE_FORMAT_CHECK_IN_OUT): 'NA'} - {myQuotes[0]?.checkOut ? formatDateCheckInOut(myQuotes[0]?.checkOut, DATE_FORMAT_CHECK_IN_OUT) : 'NA'}
            </div>
            <div className="hotels-dates">
              {myQuotes[0]?.occupancy}
            </div>
          </div>

          <div className="quote-modal-compare__hotels">
            {myQuotes.map((quote, index) => {
              return (
                <div key={index} className={`quote-modal-compare__hotel ${index === 0 ? 'display-border' : ''} hotel_${myQuotes.length}`}>
                  <div className="hotel-images-container">
                    <div className="hotel-image" style={{ backgroundImage: `url(${quote.image})` }}>
                    </div>
                  </div>

                  <div className="hotel-text-container" style={{ height: '125px' }}>
                    {quote.displayName}
                  </div>

                  <div className="hotel-text-container">{quote.fullAddress}</div>

                  <div className="hotel-text-container" style={{ height: 'auto ' }}>{quote.mealPLan}</div>

                  {quote.refundability && quote.refundability !== RefundableEnum.Unknown ? (
                    <div className="hotel-text-container" style={{ height: 'auto ' }}>
                      <div className="hotel-card-wrapper__refundable">
                        {quote.refundability === RefundableEnum.Refundable && (
                          <div className="hotel-card-wrapper__refundable-ref">
                            <FormattedMessage id="refundable" />
                          </div>
                        )}
                        {quote.refundability === RefundableEnum.Nonrefundable && (
                          <div className="hotel-card-wrapper__refundable-nonref">
                            <FormattedMessage id="non.refundable" />
                          </div>
                        )}
                      </div>
                    </div>) : null}

                  {(quote.starRating >= 0 || quote.rating || quote.reviewsCount) ? (
                    <div className="hotel-amenities-container">
                      {quote.starRating >= 0 ? (<HotelStars stars={quote.starRating} />) : null}
                      <label>{quote.rating ? (<><strong>{quote.rating}</strong>/10</>) : null} {quote.reviewsCount ? (<>({quote.reviewsCount} <FormattedMessage id="reviews" />)</>) : null}</label>
                    </div>) : null}

                  <div className="hotel-text-container">
                    {quote.publicPrice ? (<p style={{ textDecoration: 'line-through' }}><Currency currency={quote.currency} />{renderNumber(quote.publicPrice, 2)}</p>) : null }
                    <p><strong style={{ fontSize: '20px', fontWeight: 'bold' }}><Currency currency={quote.currency} />{renderNumber(quote.price, 2)}</strong></p>
                    {quote.discount > 0 ? (<p>{quote.discount} % <FormattedMessage id="results.hotel.card.compare.modal.off" /></p>) : null}
                  </div>

                  <div className="hotel-text-container" style={{ height: '87px' }}>
                    {quote?.amenities?.filter(a => !isEmpty(a))?.length > 0 ? quote?.amenities.map((a: string) => ({ ...(getAmenityImageByName(a) as any), a })).filter(a => !!a).slice(0, 2).map((a, i) => <div key={i} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><img src={a.image} alt={a.name} width="33" /> {a.name}</div>) : <FormattedMessage id="results.hotel.card.compare.modal.no_amenities" />}
                  </div>

                  {!isHistorical ? (
                    <div className="hotel-view-container">
                      <div className="view-button" style={styleButton} onClick={() => this.handleView(quote)}>
                        <FormattedMessage id="results.hotel.card.compare.modal.view" />
                      </div>
                    </div>) : null}
                </div>)
            })}
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    quoteStore: state.quoteStore,
    loginStore: state.loginStore
  };
};

const ModalCompareComponentRouter = withRouter(QuoteModalCompareComponent);

export const QuoteModalCompare = connect(mapStateToProps)(ModalCompareComponentRouter);