import React from 'react';

export const PlaneSvg: React.FunctionComponent = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6667 6.66667H13.3334C13.687 6.66667 14.0261 6.80714 14.2762 7.05719C14.5262 7.30724 14.6667 7.64638 14.6667 8C14.6667 8.35362 14.5262 8.69276 14.2762 8.94281C14.0261 9.19286 13.687 9.33333 13.3334 9.33333H10.6667L8.00004 14H6.00004L7.33337 9.33333H4.66671L3.33337 10.6667H1.33337L2.66671 8L1.33337 5.33333H3.33337L4.66671 6.66667H7.33337L6.00004 2H8.00004L10.6667 6.66667Z"
      fill="#C5C5C5"
    />
  </svg>
);
